import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Button, Collapse, Empty, Flex, Typography } from 'antd'

import { ReactComponent as ArrowMenu } from 'assets/svgs/arrow-menu.svg'
import { LIST_DRAG_AND_DROP } from 'configs/constant'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import { uuid } from 'utilities/helpers'
import Item from '../dnd/Item'
import RenderItems from '../dnd/RenderItems'

const { Panel } = Collapse

export default function ContentCategoryList({
  categories,
  setEdit
}: {
  categories: DataItemMaster[]
  setEdit: () => void
}) {
  const { t } = useTranslation()

  return (
    <div className="bg-white w-full pr-[2px] rounded-r-[10px] py-2.5">
      <Flex className="pr-[18px] pl-2.5 justify-between">
        <Typography className="font-bold tracking-[.7px]">
          {t('setInspection')}
        </Typography>
        <Button
          autoInsertSpace={false}
          type="primary"
          className="w-[120px]"
          onClick={setEdit}
        >
          {t('button.confirm')}
        </Button>
      </Flex>
      <div className="pl-2.5 mt-2.5 max-h-[900px] overflow-y-scroll">
        <Droppable
          key={LIST_DRAG_AND_DROP.CATEGORY}
          droppableId={`${LIST_DRAG_AND_DROP.LIST1}`}
          type={LIST_DRAG_AND_DROP.CATEGORY}
        >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories?.length ? (
                categories.map((category, index) => (
                  <Draggable
                    key={uuid()}
                    draggableId={category.refId ?? uuid()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Collapse
                          defaultActiveKey={[category.refId]}
                          expandIconPosition="end"
                          className="in-course-inspection-items"
                          expandIcon={({ isActive }) => (
                            <ArrowMenu
                              className={`w-[15px] mr-3 ml-2 ${isActive ? '' : 'rotate-[-90deg]'}`}
                            />
                          )}
                        >
                          <Panel
                            id={category.refId}
                            key={category.refId}
                            header={
                              <Item
                                item={category}
                                showCode
                                allowCheckbox={false}
                                isCategory
                              />
                            }
                          >
                            <RenderItems
                              position={LIST_DRAG_AND_DROP.LIST1}
                              items={category?.items || []}
                              parentRefId={category.refId}
                              showCode
                              showIcon
                            />
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}
