import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Pagination, Table } from 'antd'

import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import { ReactComponent as ActiveIcon } from 'assets/svgs/checked.svg'
import { ReactComponent as PenIcon } from 'assets/svgs/pen.svg'
import { ReactComponent as SortIcon } from 'assets/svgs/sort.svg'
import { ReactComponent as TrashIcon } from 'assets/svgs/trash.svg'
import styles from 'components/elements/CustomPagination.module.scss'
import { RECORD_COUNT } from 'models/customer'
import {
  PaginationProps,
  ScenarioData,
  TableProps
} from 'pages/Scenario/types/types'

const getColumns = (
  t: (key: string) => string,
  onSortTable: (key: string) => void,
  onEditScenario: (id: string) => void,
  onDeleteScenario: (id: string) => void
): ColumnsType<ScenarioData> => [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    width: '5%',
    render: (_, record) => <span className="text-default">{record.no}</span>
  },
  {
    title: (
      <div
        role="presentation"
        className="flex items-center cursor-pointer justify-center"
        onClick={() => onSortTable('name')}
      >
        <span className="text-default mr-3 font-light">
          {t('scenarioList.templateName')}
        </span>
        <SortIcon />
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    render: (text, record) => (
      <div className="flex items-center">
        <p className="text-default truncate max-w-full">{text}</p>
        {record.isActive && (
          <div className="ml-2 flex items-center gap-1">
            <ActiveIcon />
            <span className="text-xs text-primary font-light">
              {t('scenarioList.appSetting')}
            </span>
          </div>
        )}
      </div>
    )
  },
  {
    title: (
      <div role="presentation" className="flex items-center justify-center">
        <span className="text-default mr-3 font-light">
          {t('scenarioList.chatCategory')}
        </span>
      </div>
    ),
    dataIndex: 'chatCategory',
    key: 'chatCategory'
  },
  {
    title: (
      <div role="presentation" className="flex items-center justify-center">
        <span className="text-default mr-3 font-light">
          {t('scenarioList.createdDate')}
        </span>
      </div>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center'
  },
  {
    title: (
      <div
        role="presentation"
        className="flex items-center cursor-pointer justify-center"
        onClick={() => onSortTable('modifiedDate')}
      >
        <span className="text-default mr-3 font-light">
          {t('scenarioList.lastUpdated')}
        </span>
        <SortIcon />
      </div>
    ),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center'
  },
  {
    title: (
      <div
        role="presentation"
        className="flex items-center cursor-pointer justify-center"
      >
        <span className="text-default mr-3 font-light">
          {t('scenarioList.actions')}
        </span>
      </div>
    ),
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    render: (id) => {
      return (
        <div className="w-full flex gap-2 items-center justify-center">
          <Button
            className="outline-none border-none"
            onClick={() => onEditScenario(id)}
          >
            <PenIcon width={16} height={16} />
          </Button>
          <Button
            className="outline-none border-none"
            onClick={() => onDeleteScenario(id)}
          >
            <TrashIcon width={16} height={16} />
          </Button>
        </div>
      )
    }
  }
]

function TableCustom({
  elmNumbers,
  onSortTable,
  onEditScenario,
  onDeleteScenario,
  dataSource,
  loading,
  pagination
}: TableProps) {
  const { t } = useTranslation()
  const columns = React.useMemo(
    () => getColumns(t, onSortTable, onEditScenario, onDeleteScenario),
    [t, onSortTable, onEditScenario, onDeleteScenario]
  )

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-end">{elmNumbers}</div>

      <Table
        className="scenario-list-table"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
      />

      {pagination && dataSource && dataSource.length > 0 && (
        <div className="mb-3">
          <CustomPagination {...pagination} />
        </div>
      )}
    </div>
  )
}

export default TableCustom

/**
 *
 * @param
 * currentPage: page current
 * onChangePage: function to change page
 * totalRecords: total records in all pages
 * pageSize: size of page
 * onFirstPage: function to go to first page
 * onLastPage: function to go to last page
 * @returns
 */
const CustomPagination = ({
  currentPage,
  onChangePage,
  totalRecords,
  pageSize,
  onFirstPage,
  onLastPage
}: PaginationProps) => {
  const totalPages = Math.ceil(totalRecords / pageSize)

  return (
    <Flex justify="center">
      <Button
        style={{ background: 'none' }}
        className="rounded-none"
        onClick={onFirstPage}
        disabled={currentPage === 0 || currentPage === 1}
        icon={
          <DoubleLeftOutlined
            style={{
              color:
                currentPage === 0 || currentPage === 1 ? '#bdcbd5' : '#137695'
            }}
          />
        }
      />

      <Pagination
        className={`${styles.pagination_wrapper}`}
        total={totalRecords}
        pageSize={pageSize}
        onChange={onChangePage}
        current={currentPage}
      />

      <Button
        style={{ background: 'none' }}
        className="rounded-none"
        onClick={onLastPage}
        disabled={currentPage === totalPages || totalPages === 0}
        icon={
          <DoubleRightOutlined
            style={{
              color:
                currentPage === totalPages || totalPages === 0
                  ? '#bdcbd5'
                  : '#137695'
            }}
          />
        }
      />
    </Flex>
  )
}
