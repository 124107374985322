import axios from 'axios'

import { CHAT_API_URL } from '../configs/api'
import {
  ERROR_AUTHORIZE_CODE,
  ERROR_PATH,
  REQUEST_TIMEOUT
} from '../configs/constant'
import { getLocalStorage } from '../hooks/useLocalStorage'

export const axiosInstance: any = axios.create({
  baseURL: `${CHAT_API_URL}/`,
  timeout: 60000
})

/**
 * Config request common
 *
 * @param {String} method Request method
 * @param {String} url Request URL
 * @param {Object} data Request params
 * @param {Object} options Config options
 */
const request = async (
  method = 'GET',
  url = '',
  data = {},
  headers = {},
  options: any = {}
) => {
  const {
    successCallback = () => {},
    errorCallback = () => {},
    ...rest
  } = options

  // config params
  const defaultParams = {
    method,
    url,
    headers,
    timeout: REQUEST_TIMEOUT,
    ...rest,
    validateStatus: () => true
  }

  const { rocket_chat_token: rocketchatToken, rocketchat_user_id: userId } =
    getLocalStorage('@rocketchat_user') || {}

  if (defaultParams.headers.chatToken && defaultParams.headers.chatUserId) {
    defaultParams.headers = {
      'X-Auth-Token': defaultParams.headers.chatToken,
      'X-User-Id': defaultParams.headers.chatUserId
    }
  } else if (rocketchatToken && userId) {
    defaultParams.headers = {
      'X-Auth-Token': rocketchatToken,
      'X-User-Id': userId
    }
  }

  const paramConfigs =
    method === 'GET'
      ? { ...defaultParams, params: data }
      : { ...defaultParams, data }

  return new Promise((resolve, reject) => {
    axiosInstance(paramConfigs)
      .then((res: any) => {
        const { data } = res
        resolve(data)
        successCallback(data)
      })
      .catch((error: any) => {
        reject(error)
        errorCallback(error)
      })
  })
}

/**
 * Request process callback with method GET
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Object} headers Request headers
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiGet = (
  url = '',
  params = {},
  headers = {},
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return request('GET', url, params, headers, {
    successCallback,
    errorCallback
  })
}

/**
 * Request process callback with method POST
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiPost = (
  url = '',
  params = {},
  headers = {},
  successCalback = () => {},
  errorCallback = () => {}
) => {
  return request('POST', url, params, headers, {
    successCalback,
    errorCallback
  })
}

/**
 * Request process callback with method PUT
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiPut = (
  url = '',
  params = {},
  successCalback = () => {},
  errorCallback = () => {}
) => {
  return request('PUT', url, params, { successCalback, errorCallback })
}

/**
 * Request process callback with method DELETE
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiDelete = (
  url = '',
  params = {},
  successCalback = () => {},
  errorCallback = () => {}
) => {
  return request('DELETE', url, params, { successCalback, errorCallback })
}

// Interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error
    // if error with unauthorize => redirect to /login
    if (
      error?.message?.includes(ERROR_AUTHORIZE_CODE) ||
      response?.status === ERROR_AUTHORIZE_CODE
    ) {
      window.location.href = ERROR_PATH
      return
    }

    return Promise.reject(error)
  }
)

export default {
  get: apiGet,
  post: apiPost,
  put: apiPut,
  delete: apiDelete,
  request
}
