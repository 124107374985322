import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_AVAILABLE_SLOTS: DocumentNode = gql`
  mutation SettingAvailableSlotsProductMutation(
    $availableSlots: [AvailableSlotSetting!]!
  ) {
    settingAvailableSlotsProduct(availableSlots: $availableSlots) {
      status
    }
  }
`
