import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'

import { IAdminRegister } from '../AdminRegister'
import InformationConfirmationBlock from './InformationConfirmationBlock'
import ModalRegistrationSuccessful from './ModalRegistrationSuccessful'
import ModalSaveSuccessful from './ModalSaveSuccessful'
import ShowAdminsRegistrationError from './ShowAdminsRegistrationError'
import { openNotification } from 'components/widgets/Notification'
import { useUserInfo } from 'hooks/role-permission/usePermissions'
import { IAdmin } from 'types/Admin'
import { Options } from 'types/common'

export default function AdminRegistrationConfirmation({
  data,
  setConfirmRegister,
  genderOptions,
  roleOptions,
  departmentOptions,
  assignmentOptions,
  positionOptions,
  adminOptions,
  handleCreateAdmins,
  handleUpdateAdmins,
  user,
  dataErrors,
  setDataErrors
}: {
  data: IAdminRegister[]
  setConfirmRegister: (value: IAdminRegister[] | null) => void
  genderOptions: Options[]
  roleOptions: Options[]
  departmentOptions: Options[]
  assignmentOptions: Options[]
  positionOptions: Options[]
  adminOptions: Options[]
  handleCreateAdmins: Function
  handleUpdateAdmins: Function
  user: IAdmin | null
  dataErrors: IAdminRegister[] | null
  setDataErrors: (value: IAdminRegister[] | null) => void
}) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [dataSuccess, setDataSuccess] = useState<IAdminRegister[] | null>(null)
  const [save, setSave] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const { setValue } = useFormContext()
  const { refreshUserInfo } = useUserInfo()

  const saveAdmins = async () => {
    let response = !user
      ? await handleCreateAdmins(data)
      : await handleUpdateAdmins(data, user)
    if (!Array.isArray(response)) return
    if (response.length > 0) {
      const errors = data.filter((item: IAdminRegister) =>
        response.includes(item.key)
      )

      const success = data.filter(
        (item: IAdminRegister) => !response.includes(item.key)
      )

      setDataErrors(errors)
      setValue('blocks', errors)
      setDataSuccess(success)

      if (success.length > 0) {
        openNotification({
          type: 'success',
          title: success.length + t('content.staffInfoRegistered'),
          message: ''
        })
      }
      return
    }

    if (user) {
      if (data?.[0]?.ownership_change) refreshUserInfo()

      setUpdate(true)
      return
    }

    setSave(true)
  }

  return (
    <>
      {!dataErrors && (
        <Flex vertical>
          <Typography className="text-base font-bold tracking-[1.6px] mb-5">
            {user ? t('editStaffInfo') : t('staffRegistration')}
            <span className="ml-3 font-normal">
              {user
                ? t('confirmChanges')
                : t('content.confirmRegistrationWithContent')}
            </span>
          </Typography>
          {data?.map((item: IAdminRegister) => (
            <div
              key={item.key}
              className="bg-white px-2.5 pt-3 pb-3 mb-4 rounded-md shadow-md"
            >
              <InformationConfirmationBlock
                item={item}
                assignmentOptions={assignmentOptions}
                roleOptions={roleOptions}
                departmentOptions={departmentOptions}
                positionOptions={positionOptions}
                genderOptions={genderOptions}
                adminOptions={adminOptions}
              />
            </div>
          ))}
          <Flex className="gap-3 mt-1">
            <Button
              type="primary"
              autoInsertSpace={false}
              className="rounded-[3px] min-w-[180px] font-bold"
              onClick={() => saveAdmins()}
            >
              {user ? t('button.keep') : t('button.registration')}
            </Button>
            <Button
              autoInsertSpace={false}
              className="rounded-[3px] min-w-[180px] font-bold text-primary border-primary bg-white"
              onClick={() => {
                setConfirmRegister(null)
              }}
            >
              {user ? t('button.return') : t('button.fixes')}
            </Button>
          </Flex>
        </Flex>
      )}
      {dataErrors && (
        <ShowAdminsRegistrationError
          dataErrors={dataErrors}
          dataSuccess={dataSuccess}
          assignmentOptions={assignmentOptions}
          departmentOptions={departmentOptions}
          genderOptions={genderOptions}
          positionOptions={positionOptions}
          roleOptions={roleOptions}
          adminOptions={adminOptions}
          user={user}
        />
      )}
      <ModalRegistrationSuccessful
        isModalOpen={save}
        onCancel={() => {
          setSave(false)
          navigate('/admin-list')
        }}
      />
      <ModalSaveSuccessful
        isModalOpen={update}
        onCancel={() => {
          setUpdate(false)
          navigate('/admin-list')
        }}
      />
    </>
  )
}
