import { DocumentNode, gql } from '@apollo/client'

export const FILTER_RESERVATION: DocumentNode = gql`
  query FilterReservationQuery(
    $filter: String!
    $paginationInput: PaginationInput!
    $sortBy: String
  ) {
    filterReservation(
      filter: $filter
      paginationInput: $paginationInput
      sortBy: $sortBy
    ) {
      payload {
        refId
        checkupUserRefId
        medicalCheckupMasterRefId
        reservationStatus
        reservationDate
        reservationTime
        optionRefIds
        desiredDatetime {
          priority
          date
          timeOfDay
        }
      }
    }
  }
`
