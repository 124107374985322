import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { InputLabel } from 'components/elements/InputLabel'

type Props = {
  courseName: string
}

const CourseInformation: FC<Props> = ({ courseName }) => {
  const { t } = useTranslation()

  return (
    <div className="my-[10px] rounded-[5px] bg-[#FFFFFF] p-[10px] drop-shadow-md">
      <Flex>
        <InputLabel
          label={t('productManagement.content.lesson')}
          className="h-[30px] min-w-40 px-3 text-base"
        />
        <Flex align="center" className="ml-[14px] text-base">
          <Typography>{courseName}</Typography>
        </Flex>
      </Flex>
    </div>
  )
}

export default CourseInformation
