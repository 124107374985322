import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import dayjs from 'dayjs'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  filterInfoAtom,
  loadMoreMessagesAtom,
  messageListAtom,
  roomsAtomRooms,
  roomsSortedAtom,
  typingMessageAtom
} from './atoms'
import {
  FilterDataType,
  Message,
  MessageRealtime,
  SubscriptionRealtime,
  TypingMessage
} from './types'
import { STORAGE_KEYS } from 'configs/constant'
import emitter from 'models/emitter'
import { RoomsDataPayload, Subscription } from 'pages/Chat/types/types'
import { ChatMessageType } from 'pages/CustomerDetail/components/controlPanel/ChatContainer'
import chatMessageApi from 'services/chatMessageApi'
import { filterRooms, handleGetMessages } from 'utilities/chatHelpers'
import { getLastMsgForRoom, getMessageType } from 'utilities/helpers'

const useMessageList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const rId = searchParams.get('rId')

  const rIdRef = useRef<string | null>(rId)

  useEffect(() => {
    rIdRef.current = rId
  }, [rId])

  const [filterInfo, setFilterInfo] = useRecoilState(filterInfoAtom)
  const [roomHistory, setRoomHistory] = useRecoilState(roomsAtomRooms)
  const setRoomsSortedAtom = useSetRecoilState(roomsSortedAtom)

  const [loadMoreMessagesState, setLoadMoreMessagesState] =
    useRecoilState(loadMoreMessagesAtom)

  const [messageList, setMessageList] = useRecoilState(messageListAtom)

  const setTypingMessage = useSetRecoilState(typingMessageAtom)

  const methods = useForm<FilterDataType>({
    defaultValues: filterInfo
  })

  const roomHistoryRef = useRef(roomHistory)
  const methodsRef = useRef(methods)

  useEffect(() => {
    methodsRef.current = methods
  }, [methods])

  useEffect(() => {
    roomHistoryRef.current = roomHistory
  }, [roomHistory])

  const subscribeEmitterForChatRoom = useCallback(() => {
    const events = [
      {
        name: 'SUB_CHAT_ROOM',
        handler: (chatRoomSubscription: SubscriptionRealtime) =>
          onChatRoomSubscription(chatRoomSubscription, rIdRef.current)
      },
      {
        name: 'UPDATE_CHAT_ROOM',
        handler: (chatRoomUpdated: MessageRealtime) =>
          onUpdateChatRooms(rIdRef.current, chatRoomUpdated)
      },
      {
        name: 'TYPING_MESSAGE',
        handler: (typingMessage: TypingMessage) =>
          onTypingMessage(typingMessage)
      },
      {
        name: 'ADD_CHAT_ROOM',
        handler: (chatRoomAdded: RoomsDataPayload) =>
          onAddChatRoom(chatRoomAdded)
      }
    ]
    events.forEach(({ name, handler }) => {
      const registeredHandlers = emitter.all.get(name) || []
      const isAlreadyRegistered = registeredHandlers.some(
        (h: Function) => h === handler
      )

      if (!isAlreadyRegistered) {
        emitter.on(name as any, handler as any)
        console.log(`Event '${name}' registered.`)
      } else {
        console.log(`Event '${name}' is already registered.`)
      }
    })
  }, [])

  const pushFilterInfoToParams = useCallback(() => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)

      // Add non-empty filter values
      Object.entries(filterInfo).forEach(([key, value]) => {
        if (value || value === 0) {
          newParams.set(key, String(value))
        } else {
          newParams.delete(key)
        }
      })

      return newParams
    })
  }, [filterInfo, setSearchParams])

  const handleSubmit = useCallback(() => {
    pushFilterInfoToParams()

    // handle filter rooms
    const newRooms = filterRooms(filterInfo, roomHistory)

    setRoomsSortedAtom(newRooms)
  }, [filterInfo, roomHistory])

  const setFormValue = (
    type: keyof FilterDataType,
    value: FilterDataType[typeof type]
  ) => {
    setFilterInfo((prev: FilterDataType) => ({
      ...prev,
      [type]: value
    }))
  }

  // TODO: Implement actual load more room logic
  const handleLoadMoreRoom = useCallback(
    async (e: React.UIEvent<HTMLDivElement>) => {
      const elm = e.target as HTMLDivElement
      if (elm.scrollTop === 0) {
        console.log('load more room')
      }
    },
    []
  )

  const handleLoadMoreMessages = useCallback(
    async (
      fetchMessagesInRoom: (
        roomId: string,
        timestamp: number | null
      ) => Promise<any>
    ) => {
      const timestampCurrent = String(loadMoreMessagesState.timestamp)
      const timestampLastMessage = String(
        messageList[messageList.length - 1].timestamp
      )

      if (timestampCurrent !== timestampLastMessage) {
        await handleGetMessages({
          roomId: rIdRef.current ?? '',
          timestamp: Number(timestampLastMessage),
          loadMoreMessagesState,
          setLoadMoreMessagesState,
          fetchMessagesInRoom,
          convertMessage,
          setMessageList,
          isUpdate: true
        }).finally(() => {
          setLoadMoreMessagesState((prev) => ({
            ...prev,
            isLoadingLoadMore: false,
            timestamp: timestampCurrent
          }))
        })
      }
    },
    [messageList, loadMoreMessagesState]
  )

  const convertMessage = useCallback(
    (messages: Message[]): ChatMessageType[] => {
      if (!messages) return []

      const { adminUserId } = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
      )

      return messages.map((message) => ({
        id: message?._id || '',
        uId: message?.u?._id || '',
        imgId: message?.file?._id || '',
        message: message.msg,
        isSent: message.u._id === adminUserId,
        timestamp: message.ts['$date'].toString(),
        typeMessage: getMessageType(message?.attachments),
        attachments: message?.attachments,
        isRead: false,
        updateAt: message.ts['$date'].toString()
      }))
    },
    []
  )

  const onUpdateChatRooms = useCallback(
    async (rIdParam: string | null, chatRoomUpdated?: MessageRealtime) => {
      if (!chatRoomUpdated) return

      const { adminUserId } = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
      )

      updateLastMsgInRoom(chatRoomUpdated, rIdParam)

      const isCurrentRoom = chatRoomUpdated?.lastMessage?.rid === rIdParam
      const isAdminSendMessage =
        chatRoomUpdated?.lastMessage?.u?._id === adminUserId
      if (isCurrentRoom) {
        if (!isAdminSendMessage) {
          chatMessageApi.readMessages({ roomId: rIdParam })
        }

        updateMessageList(chatRoomUpdated, adminUserId)
      }
    },
    []
  )

  const updateMessageList = useCallback(
    (chatRoomUpdated: MessageRealtime, adminUserId: string) => {
      const newMessage = {
        id: chatRoomUpdated?.lastMessage?._id || '',
        uId: chatRoomUpdated?.lastMessage?.u?._id || '',
        imgId: chatRoomUpdated?.lastMessage?.file?._id || '',
        message: chatRoomUpdated.lastMessage.msg,
        isSent: chatRoomUpdated.lastMessage.u._id === adminUserId,
        typeMessage: getMessageType(chatRoomUpdated.lastMessage.attachments),
        attachments: chatRoomUpdated.lastMessage.attachments,
        isRead: false,
        timestamp: chatRoomUpdated.lastMessage.ts['$date'].toString(),
        updateAt: chatRoomUpdated.lastMessage.ts['$date'].toString()
      }

      setMessageList((prev) => {
        // void message double
        const isFirstMessage = prev[prev.length - 1].id === newMessage.id

        if (isFirstMessage) {
          return [...prev]
        }

        return [newMessage, ...prev]
      })
    },
    []
  )

  const onChatRoomSubscription = useCallback(
    async (
      chatRoomSubscription: SubscriptionRealtime,
      rIdParam: string | null
    ) => {
      // if the room is the current room, do not update the state, because it is already updated in handleClickRoom,
      if (chatRoomSubscription?.rid === rIdParam) {
        return
      }

      // check if the room will be updated
      let canChangeState = false

      // update message count not read of room
      const newRooms = roomHistoryRef.current.rooms.map((room) => {
        if (room._id === chatRoomSubscription?.rid) {
          canChangeState = true
          return {
            ...room,
            countNotRead: chatRoomSubscription.unread,
            isRead: chatRoomSubscription.unread === 0
          }
        }

        return room
      })

      if (canChangeState) {
        setRoomHistory((prev) => ({
          ...prev,
          rooms: newRooms
        }))
      }
    },
    []
  )

  const unsubscribeEmitterForChatRoom = () => {
    emitter.all.clear()
  }

  const getUpdatedRoomData = (
    roomUpdating: RoomsDataPayload,
    dataRealtime: MessageRealtime,
    rIdParam: string | null
  ): RoomsDataPayload => {
    const lastMsg = dataRealtime.lastMessage

    //rIdParam !== dataRealtime.lastMessage.rid
    // Update unread count when viewing a different room
    // check is update unread count
    const updateAtRoomUpdating = roomUpdating.lastMessage?._updatedAt
    const updateAtDataRealtime = dataRealtime.lastMessage._updatedAt['$date']
    const isReadCount = dayjs(updateAtRoomUpdating).isBefore(
      dayjs(updateAtDataRealtime)
    )
    let newSubscriptions: Subscription[] = roomUpdating.subscriptions

    if (
      updateAtRoomUpdating &&
      updateAtDataRealtime &&
      isReadCount &&
      newSubscriptions
    ) {
      const isRoomSelected = rIdParam === dataRealtime.lastMessage.rid

      if (isRoomSelected) {
        newSubscriptions = updateUnreadCount(roomUpdating.subscriptions, true)
      } else {
        newSubscriptions = updateUnreadCount(roomUpdating.subscriptions, false)
      }
    }

    return {
      ...roomUpdating,
      lastMessage: {
        ...roomUpdating.lastMessage,
        ...dataRealtime.lastMessage,
        ts: String(dataRealtime.lastMessage.ts['$date']),
        _updatedAt: String(dataRealtime.lastMessage._updatedAt['$date']),
        msg: getLastMsgForRoom(lastMsg)
      },
      updatedAt: String(dataRealtime.lastMessage._updatedAt['$date']),
      subscriptions: newSubscriptions || []
    }
  }

  const updateLastMsgInRoom = (
    chatRoomUpdated: MessageRealtime,
    rIdParam: string | null
  ) => {
    const updatedRooms = roomHistoryRef.current.rooms.map(
      (room): RoomsDataPayload =>
        room._id === chatRoomUpdated?.lastMessage?.rid
          ? getUpdatedRoomData(room, chatRoomUpdated, rIdParam)
          : room
    )

    setRoomHistory((prev) => ({
      ...prev,
      rooms: updatedRooms
    }))
  }

  const updateUnreadCount = (
    newSubscriptions: Subscription[],
    isCountZero: boolean
  ): Subscription[] => {
    const { adminUserId } = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
    )

    return newSubscriptions.map((subscription) => {
      if (subscription.user?._id !== adminUserId) {
        subscription = {
          ...subscription,
          unread: isCountZero ? 0 : subscription.unread + 1
        }
      }

      return subscription
    })
  }

  const onTypingMessage = useCallback((typingMessage: TypingMessage) => {
    setTypingMessage(typingMessage)
  }, [])

  const onAddChatRoom = useCallback((chatRoomAdded: RoomsDataPayload) => {
    const newRoom: RoomsDataPayload = {
      ...chatRoomAdded
    }

    setRoomHistory((prev) => {
      return {
        ...prev,
        rooms: [...prev.rooms, newRoom]
      }
    })
  }, [])

  return {
    methods,
    handleSubmit,
    reset: methods.reset,
    setFormValue,
    filterInfo,
    handleLoadMoreRoom,
    handleLoadMoreMessages,
    convertMessage,
    setMessageList,
    subscribeEmitterForChatRoom,
    unsubscribeEmitterForChatRoom
  } as const
}

export default useMessageList
