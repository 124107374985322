import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { DataForm as DataSetupThresholdAndCriteria } from './SetupThresholdAndCriteria'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import { formatDate } from 'utilities/helpers'

export default function ListSettingThresholdAndCriteria({
  data,
  setEditThresholdAndCriteria,
  setAddRefValue
}: {
  data: DataSetupThresholdAndCriteria[]
  setEditThresholdAndCriteria: React.Dispatch<
    React.SetStateAction<DataSetupThresholdAndCriteria | null>
  >
  setAddRefValue: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { t } = useTranslation()

  return (
    <div className="bg-white mt-2 pt-1.5 pb-4 pr-1 pl-2 rounded-md">
      <Flex className="font-bold items-center">
        <Typography className="w-[70%] pl-2.5">
          {t('lable.applicationPeriod')}
        </Typography>
        <Typography className="flex-1 text-center">
          {t('testResult.referenceValue')}
        </Typography>
        <Flex
          onClick={() => setAddRefValue(true)}
          className="w-[30px] h-[30px] rounded-md bg-primary text-white items-center justify-center cursor-pointer"
        >
          <span className="text-[24px]">+</span>
        </Flex>
      </Flex>
      <div className="border-t border-[#707070] mt-1.5"></div>
      <div className="h-[210px] overflow-y-scroll">
        <Flex vertical>
          {data.map((item: DataSetupThresholdAndCriteria) => (
            <div key={item.id}>
              <Flex className="items-center min-h-[30px] py-2.5">
                <Typography className={`w-[73%] px-2.5`}>
                  {formatDate(item.application_period_start) +
                    ' ～ ' +
                    formatDate(item.application_period_end)}
                </Typography>
                <Flex className="flex-1 justify-center">
                  <PenEditIcon
                    className="w-[15px] cursor-pointer"
                    onClick={() => {
                      setEditThresholdAndCriteria(item)
                      setAddRefValue(true)
                    }}
                  />
                </Flex>
              </Flex>
            </div>
          ))}
        </Flex>
      </div>
    </div>
  )
}
