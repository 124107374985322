import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'

import { InputLabel } from 'components/elements/InputLabel'
import { SelectSearchCustom } from 'components/elements/Select'
import CustomModal from 'components/modals'
import { META_DATA_MEDICAL_CHECKUP_MASTER } from 'configs/constant'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import { handleMetaData } from 'hooks/useProductMaster'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'
import { Options } from 'types/common'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onSuccess: (medicalCheckupMaster: MedicalCheckupMaster) => void
  options: Options[]
  dataProduct: MedicalCheckupMaster | undefined
}

const CopyProductModal = ({
  isModalOpen = false,
  onCancel,
  onSuccess,
  options,
  dataProduct
}: Props) => {
  const { t } = useTranslation('')
  const { watch, setValue } = useFormContext()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const [data, setData] = useState<MedicalCheckupMaster | undefined>(
    dataProduct
  )

  useEffect(() => {
    if (dataProduct) setData(dataProduct)
  }, [dataProduct])

  const idProduct = watch('medicalCheckupMaster')

  useEffect(() => {
    if (!idProduct) return
    const fetchData = async () => {
      const result = await getMedicalCheckupMastersById(idProduct)
      setData(result?.medicalCheckupMaster)
    }

    fetchData()
  }, [idProduct])

  useEffect(() => {
    if (isModalOpen) {
      setData(dataProduct ?? undefined)
      setValue('medicalCheckupMaster', undefined)
    }
  }, [isModalOpen])

  const handleOk = () => {
    onCancel()
    if (data) onSuccess(data)
  }

  const metaData = handleMetaData(data?.additionalInfo?.metadata ?? [])

  return (
    <CustomModal
      open={isModalOpen}
      onCancel={onCancel}
      onOk={handleOk}
      width={800}
    >
      <Flex vertical className="text-center pt-3 pb-5 px-8">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('copyExistingProduct')}
        </Typography>

        <Paragraph className="mt-[30px] mb-0">
          {t('content.copyExistingProduct')}
        </Paragraph>
        <Paragraph className="mb-0">{t('content.isThatOkay')}</Paragraph>

        <SelectSearchCustom
          name="medicalCheckupMaster"
          options={options}
          className="text-left w-[424px] mt-12"
          placeholder={t('placeholder.findACourse')}
        />

        <Row className="gap-3 w-full mt-1.5 text-left">
          <Col flex="148px">
            <InputLabel
              align="center"
              label={t('lable.courseName')}
              className="!py-0"
            />
          </Col>
          <Col flex="auto" className="flex items-center">
            <Typography>{data?.displayName}</Typography>
          </Col>
        </Row>
        <Row className="gap-3 w-full mt-1.5 text-left">
          <Col flex="148px">
            <InputLabel
              align="center"
              label={t('table.overview')}
              className="!py-0"
            />
          </Col>
          <Col flex="auto" className="flex items-center">
            <Typography>
              {metaData?.[META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW]}
            </Typography>
          </Col>
        </Row>

        <Flex className="justify-center gap-3 mt-[50px]">
          <Button
            autoInsertSpace={false}
            type="primary"
            className="min-w-[180px] font-bold"
            onClick={handleOk}
          >
            {t('button.create')}
          </Button>
          <Button
            autoInsertSpace={false}
            className="min-w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default CopyProductModal
