import { Controller, useFormContext } from 'react-hook-form'
import { Input } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ReactComponent as EyeNoHidden } from 'assets/svgs/eyeHiddenPassword.svg'
import { ReactComponent as EyeHidden } from 'assets/svgs/eyeUnHiddenPassword.svg'

type Props = {
  className?: string
  name: string
  placeholder?: string
  size?: SizeType
}
/*To do when download icon */
export const InputPassword = ({
  className = '',
  name,
  placeholder,
  size = 'middle'
}: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input.Password
          iconRender={(visible) => (visible ? <EyeNoHidden /> : <EyeHidden />)}
          className={className}
          size={size}
          status={errors?.[name] ? 'error' : ''}
          placeholder={placeholder}
          {...field}
        />
      )}
    />
  )
}
