import { ReactNode, memo } from 'react'
import { FieldValues, UseFormSetValue, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

import { controlPanelWrapper } from './PatientMemo'
import Title from './Title'

function PatientReservation({
  courseSearch,
  drawerSchedule,
  handleFillLastReservation
}: {
  handleFillLastReservation: (setValue: UseFormSetValue<FieldValues>) => void
  courseSearch: ReactNode
  drawerSchedule: ReactNode
}) {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  return (
    <div className={`${controlPanelWrapper} px-[20px]`}>
      <Title text={t('dashboard.new_appointment')} isIcon />

      <Button
        onClick={() => handleFillLastReservation(setValue)}
        type="primary"
        className="mt-4 bg-white text-[#137695] hover:bg-white outline-none shadow-none border border-[#137695] font-bold"
      >
        {t('dashboard.repeat_last_appointment')}
      </Button>
      {courseSearch}
      {drawerSchedule}
    </div>
  )
}

export default memo(PatientReservation)
