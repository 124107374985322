import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Select, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'

import styles from './scss/TabbleWrapper.module.scss'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import useConsultationReport from 'hooks/useConsultationReport'
import { ExaminedAndPaidUser, YearlyReport } from 'models/consultationReport'
import { RECORD_COUNT } from 'models/customer'

type Props = {
  onRowSelectionChange: (selectedRows: ExaminedAndPaidUser[]) => void
  setTotalRecords: (data: number) => void
}

const monthOptions = Array.from({ length: 12 }, (_, i) => ({
  label: i + 1,
  value: i + 1
}))

const dateTimeFormat = 'YYYY-MM-DD'

const Content: FC<Props> = memo(({ onRowSelectionChange, setTotalRecords }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const { getValues, setValue } = useForm()
  const navigate = useNavigate()

  const {
    loading,
    onFilterYear,
    onFilterConsultationReport,
    onFilterReservation
  } = useConsultationReport()

  const sortRef = useRef<string>()

  const [selectOptions, setSelectOptions] = useState<
    { label: number; value: number }[]
  >([])
  const [monthReportData, setMonthReportData] = useState<YearlyReport>()
  const [examinedAndPaidUserData, setExaminedAndPaidUserData] = useState<
    ExaminedAndPaidUser[]
  >([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [tenantName, setTenantName] = useState<string>('')

  const sizeOptions = useMemo(() => {
    return [
      {
        value: RECORD_COUNT.Fifty,
        label: `${RECORD_COUNT.Fifty}${t('customerManagement.displayInfo.record')}`
      },
      {
        value: RECORD_COUNT.OneHundred,
        label: `${RECORD_COUNT.OneHundred}${t('customerManagement.displayInfo.record')}`
      },
      {
        value: RECORD_COUNT.TwoHundred,
        label: `${RECORD_COUNT.TwoHundred}${t('customerManagement.displayInfo.record')}`
      },
      {
        value: RECORD_COUNT.FiveHundred,
        label: `${RECORD_COUNT.FiveHundred}${t('customerManagement.displayInfo.record')}`
      }
    ]
  }, [t])

  const queryParams = new URLSearchParams(search)
  const {
    year: yearParam = dayjs().year(),
    month: monthParam = dayjs().month() + 1,
    search: searchParam,
    startDate: startDateParam = dayjs()
      .year(+yearParam)
      .month(+monthParam - 1)
      .startOf('month')
      .format(dateTimeFormat),
    endDate: endDateParam = dayjs()
      .year(+yearParam)
      .month(+monthParam - 1)
      .endOf('month')
      .format(dateTimeFormat),
    courseId: courseParam,
    page: pageParam = 1,
    size: sizeParam = RECORD_COUNT.OneHundred,
    orderBy: orderParam = 'userId',
    sortBy: sortParam = 'desc'
  } = Object.fromEntries(queryParams)

  sortRef.current = sortParam

  useEffect(() => {
    const fetchData = async () => {
      const data = await onFilterYear()
      setSelectOptions(data.yearOptions)
      setTenantName(data.tenantName)
    }

    fetchData().finally()
  }, [])

  useEffect(() => {
    const fetchData = async (year: number, month: number) => {
      const result = await onFilterConsultationReport({
        year,
        month
      })

      if (result) {
        setMonthReportData(result.data[0])
      }
    }

    setSelectedRowKeys([])
    onRowSelectionChange([])
    fetchData(+yearParam, +monthParam).finally()
  }, [yearParam, monthParam])

  useEffect(() => {
    const determineOrderAndSort = <T extends string>(
      validOrderKeys: readonly T[]
    ) => {
      const order =
        orderParam && validOrderKeys.includes(orderParam as T)
          ? (orderParam as T)
          : undefined
      const sort: 'asc' | 'desc' | undefined =
        sortParam === 'asc' || sortParam === 'desc' ? sortParam : undefined
      return { order, sort }
    }

    const { order, sort } = determineOrderAndSort<keyof ExaminedAndPaidUser>([
      'userId',
      'courseName',
      'date',
      'userFullName'
    ])

    const fetchData = async () => {
      const result = await onFilterReservation({
        filter: {
          ...(searchParam && { idOrFullName: searchParam }),
          ...(courseParam && { courseId: courseParam.split(',') }),
          ...(startDateParam &&
            endDateParam && {
              date: { startDate: startDateParam, endDate: endDateParam }
            })
        },
        pagination: { page: +pageParam, size: +sizeParam },
        order,
        sort
      })

      setExaminedAndPaidUserData(result.data ?? [])
      setTotalRecords(result.totalRecords ?? 0)
    }

    setSelectedRowKeys([])
    onRowSelectionChange([])
    if (monthReportData) {
      fetchData().finally()
    }
  }, [search, monthReportData])

  const handleSelect = useCallback(
    (nameParam: string, value: number) => {
      queryParams.set('page', '1')
      queryParams.set(nameParam, value.toString())

      navigate(`${pathname}?${queryParams.toString()}`)
    },
    [sizeParam, queryParams]
  )

  const handleSearch = useCallback(() => {
    if (Object.entries(getValues()).length === 0) return

    const { year, month } = getValues()

    year ? queryParams.set('year', year.toString()) : queryParams.delete('year')
    month
      ? queryParams.set('month', month.toString())
      : queryParams.delete('month')
    queryParams.set('page', '1')

    navigate(`${pathname}?${queryParams.toString()}`)
  }, [yearParam, monthParam])

  const handleSort = (order: string) => {
    queryParams.set('orderBy', order.toString())
    queryParams.set(
      'sortBy',
      sortRef.current && sortRef.current === 'desc' ? 'asc' : 'desc'
    )

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  const handleNavigateUserDetailPage = (id: string | undefined) => {
    if (!id) return

    navigate({
      pathname: '/customer-detail',
      search: createSearchParams({
        id,
        currentTab: '0'
      }).toString()
    })
  }

  const SortableColumnTitle = ({
    title,
    sortKey
  }: {
    title: string
    sortKey: string
  }) => (
    <Flex justify="center" align="center">
      <Typography className="mr-4 font-normal">{t(title)}</Typography>
      <ArrowTopBottom
        className="cursor-pointer"
        onClick={() => handleSort(sortKey)}
      />
    </Flex>
  )

  const columns: TableColumnsType<ExaminedAndPaidUser> = useMemo(() => {
    return [
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.id"
            sortKey="userId"
          />
        ),
        dataIndex: 'userId',
        width: '20%',
        render: (value) => (
          <Flex className="px-3">
            <Typography className="min-w-[30%] whitespace-nowrap">
              {value}
            </Typography>
          </Flex>
        )
      },
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.userFullName"
            sortKey="userFullName"
          />
        ),
        dataIndex: 'userFullName',
        width: '18%',
        render: (value, record) => (
          <Flex className="px-3">
            <a
              className="min-w-[22%] text-sm font-bold text-[#137695] underline"
              onClick={() => handleNavigateUserDetailPage(record.userId)}
            >
              {value}
            </a>
          </Flex>
        )
      },
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.courseName"
            sortKey="courseName"
          />
        ),
        dataIndex: 'courseName',
        width: '18%',
        render: (value) => (
          <Flex className="px-3">
            {value ? (
              <Typography className="min-w-[15%]">{value}</Typography>
            ) : (
              <Typography className="min-w-[15%]">ー</Typography>
            )}
          </Flex>
        )
      },
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.courseSelect"
            sortKey="courseSelect"
          />
        ),
        dataIndex: 'courseSelect',
        width: '20%',
        render: (value) =>
          value ? (
            <Typography className={`px-3`}>{value}</Typography>
          ) : (
            <Typography className={`px-3`}>ー</Typography>
          )
      },
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.reservationDateTime"
            sortKey="date"
          />
        ),
        dataIndex: 'dateTime',
        width: '15%',
        render: (value) => (
          <Flex justify="center" className="px-3">
            <Typography className="min-w-[32%] whitespace-pre">
              {value}
            </Typography>
          </Flex>
        )
      },
      { title: null, render: () => null }
    ]
  }, [t, search])

  const dataSource = useMemo(
    () =>
      examinedAndPaidUserData.map((item, index) => {
        return {
          ...item,
          key: index,
          tenantName,
          year: yearParam,
          month: monthParam
        }
      }),
    [examinedAndPaidUserData]
  )

  const rowSelection = {
    selectedRowKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ExaminedAndPaidUser[]
    ) => {
      setSelectedRowKeys(selectedRowKeys)
      onRowSelectionChange(selectedRows)
    }
  }

  return (
    <div className="mb-14 mt-4 rounded-[5px] bg-[#FFFFFF] p-[10px] drop-shadow-md">
      <Flex align="center" justify="end" className="py-2">
        <Typography className="mr-2 text-xs">
          {t('consultationReport.content.recordQuantity')}
        </Typography>

        <Select
          options={sizeOptions}
          value={+sizeParam}
          onChange={(value: number) => handleSelect('size', value)}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
          className="h-[30px] min-w-[80px]"
        />
      </Flex>

      <Flex align="center" className="border-t-[1px] border-[#D9E0E5] py-4">
        <Typography.Text className="mr-2 text-sm">
          {t('consultationReport.content.year')}
        </Typography.Text>

        <Select
          key={`${yearParam}-year`}
          options={selectOptions}
          defaultValue={+yearParam || undefined}
          onChange={(value: number) => {
            setValue('year', value)
          }}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
          className="h-[30px] w-[200px]"
        />

        <Typography.Text className="ml-12 mr-2 text-sm">
          {t('consultationReport.content.month')}
        </Typography.Text>

        <Select
          key={`${monthParam}-month`}
          options={monthOptions}
          defaultValue={+monthParam || undefined}
          onChange={(value: number) => {
            setValue('month', value)
          }}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
          className="h-[30px] w-[100px]"
        />

        <Button
          type="primary"
          htmlType="submit"
          autoInsertSpace={false}
          onClick={handleSearch}
          className="ml-2 h-[30px] min-w-[84px] rounded-none border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
        >
          {t('consultationReport.button.show')}
        </Button>

        <div className="ml-[80px]">
          <Typography className="text-xs font-bold tracking-[1.2px]">
            {t('consultationReport.content.reservationQuantity')}
          </Typography>

          <Flex>
            <Typography className="min-w-[60px] text-[28px] font-bold tracking-[2.8px] text-[#137695]">
              {monthReportData?.quantity ?? 0}
            </Typography>

            <Typography className="mt-4 text-xs font-bold tracking-[1.2px]">
              {t('consultationReport.content.case')}
            </Typography>
          </Flex>
        </div>

        <div className="ml-[80px] hidden">
          <Typography className="text-xs font-bold tracking-[1.2px]">
            {t('consultationReport.content.amountBilled')}
          </Typography>

          <Flex>
            <Typography className="min-w-[60px] text-[28px] font-bold tracking-[2.8px] text-[#137695]">
              {monthReportData?.totalAmount.toLocaleString() ?? 0}
            </Typography>

            <Typography className="mt-4 text-xs font-bold tracking-[1.2px]">
              {t('consultationReport.content.yen')}
            </Typography>
          </Flex>
        </div>
      </Flex>

      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          y: 'calc(100vh - 400px)',
          scrollToFirstRowOnChange: true
        }}
        className={`${styles.table_wrapper} overflow-hidden py-4 pt-0`}
      />
    </div>
  )
})

export default Content
