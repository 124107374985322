import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { convertStatusRoom } from 'utilities/helpers'

export interface RoomItemProps {
  readonly id?: string
  readonly status?: string
  readonly userName?: string
  readonly time?: string
  readonly text?: string
  readonly countNotRead?: number
  readonly isFirst?: boolean
  readonly onClick?: (id: string) => void
}

function RoomItem({
  id,
  status = 'fake',
  userName,
  time,
  text,
  countNotRead,
  isFirst,
  onClick
}: RoomItemProps) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const rId = searchParams.get('rId')

  return (
    <div
      aria-hidden="true"
      onClick={() => onClick && id && onClick(id)}
      className={`${rId === id ? 'bg-gray-100' : ''} min-h-[79px] cursor-pointer hover:bg-gray-100 border-[1px] rounded-[2px] p-2 ${
        !isFirst ? 'mt-2' : ''
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="bg-[#3089BF] text-white w-[85px] py-[4px] text-center text-xs">
            {t(convertStatusRoom(status))}
          </div>
          <div className="ml-4 font-bold text-sm truncate max-w-44">
            {userName}
          </div>
        </div>
        <div className="text-xs text-[#C6C6C6]">{time}</div>
      </div>
      <div className="flex items-center justify-between py-2">
        <p className="flex-1 truncate max-w-[370px]">{t(text ?? '')}</p>
        {Boolean(countNotRead) && (
          <div className="rounded-full bg-[#DF2475] text-white w-[20px] h-[20px] flex items-center justify-center text-xs">
            {countNotRead}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(RoomItem)
