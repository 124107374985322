import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { IJudgementItem } from '../SetupThresholdAndCriteria'

type Props = {
  item: IJudgementItem
  index: number
}

export default function JudgmentItemSave({ item, index }: Props) {
  const { t } = useTranslation()

  return (
    <Flex
      vertical
      style={{ backgroundColor: item.bgColor }}
      className="rounded-lg pt-2.5 px-3 pb-4 w-[300px] justify-end text-center"
    >
      {index === 0 && (
        <Typography className="text-left text-white">
          {t('judgementSetting.judgmentCategory')}
        </Typography>
      )}
      <Typography className="text-[34px] text-white">
        {item.evaluation}
      </Typography>
      <Typography className="text-white text-base">
        {item.description}
      </Typography>
    </Flex>
  )
}
