import { useCallback, useMemo } from 'react'

import Signature from './components/Signature'
import TableInfoPDF from './components/TableInfoPDF'
import TablePDF from './components/TablePDF'
import { flatItems, groupItems } from './function'
import { Document, Page, StyleSheet, View } from '@react-pdf/renderer'
import { uuid } from 'short-uuid'

const DEFAULT_ROW = 40 // show with signature
const DEFAULT_FULL_ROW = 50 // show without signature

const styles = StyleSheet.create({
  container: {
    fontSize: 6,
    lineHeight: '1',
    margin: 0,
    width: 'auto',
    fontFamily: ['YuGothicUI', 'Roboto'],
    fontWeight: 'normal'
  }
})

const ResultPreviewPdf = ({
  pdfResult,
  tenant
}: {
  pdfResult: any
  tenant?: any
}) => {
  const categoryShow = pdfResult?.category
  const customer: any = pdfResult?.customer
  const [lastMedical, secondMedical, firstMedical]: any = useMemo(
    () => pdfResult?.data || [],
    [pdfResult?.data]
  )

  const renderNumber = useCallback((items?: any) => {
    if (!items || !items.length) return 1
    const totalItems = flatItems([...(items ?? [])]).length
    const isCheck = DEFAULT_FULL_ROW * 2 <= totalItems
    return isCheck ? DEFAULT_FULL_ROW : DEFAULT_ROW
  }, [])

  const number = renderNumber([...categoryShow])
  const flatData = flatItems([...categoryShow])
  const listFixed: any = [...flatData].slice(0, number * 2)
  const listDynamic: any =
    [...flatData].slice(number * 2, [...flatData].length) || []

  const RenderBlock = useCallback(
    ({
      items,
      renderNumber,
      style
    }: {
      items: any[]
      renderNumber: number
      style?: Record<string, string | number>
    }) => {
      if (items && Array.isArray(items) && items?.length === 0) return
      return (
        <>
          {items &&
            Array.isArray(items) &&
            Array.from({
              length: Math.ceil(items.length / renderNumber)
            }).map((_, index) => {
              return (
                <View key={uuid()} style={{ height: 745, flex: 1, ...style }}>
                  <TablePDF
                    tableFirst={groupItems(
                      [...items].slice(
                        index * renderNumber,
                        (index + 1) * renderNumber
                      ),
                      [...items]
                    ).sort((a, b) => {
                      return a.order - b.order
                    })}
                    dataFirstMedical={firstMedical}
                    dataSecondMedical={secondMedical}
                    dataLastMedical={lastMedical}
                  />
                </View>
              )
            })}
        </>
      )
    },
    [firstMedical, secondMedical, lastMedical]
  )

  let category: any = Array.isArray(lastMedical?.formatCategory)
    ? lastMedical.formatCategory
    : []

  if (category.length % 3 !== 0) {
    for (let i = 0; i < category.length % 3; i++) {
      category.push({})
    }
  }

  return (
    <Document>
      <Page size="A3" orientation="landscape" style={[styles.container]} wrap>
        <View
          style={{
            width: '100%',
            paddingTop: 55,
            paddingRight: 32,
            paddingLeft: 32,
            paddingBottom: 40,
            position: 'relative'
          }}
        >
          <View style={{ gap: 12, display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '25.8%', height: 745 }}>
              <TableInfoPDF
                category={category}
                customer={customer}
                firstMedical={firstMedical}
                lastMedical={lastMedical}
                secondMedical={secondMedical}
                tenant={tenant}
              />
            </View>

            {RenderBlock({
              items: listFixed,
              renderNumber: renderNumber([...(categoryShow ?? [])]),
              style: { flex: '1' }
            })}
          </View>
          {!listDynamic ||
            (listDynamic.length === 0 && (
              <Signature
                tenant={tenant}
                lastMedical={lastMedical}
                style={{
                  position: 'absolute',
                  bottom: 40,
                  right: 32
                }}
              />
            ))}
        </View>
      </Page>
      {/* handle create new page when content is too long  */}
      {listDynamic &&
        listDynamic.length > 0 &&
        Array.isArray(listDynamic) &&
        Array.from({
          length: Math.ceil(
            (listDynamic.length / renderNumber([...(listDynamic ?? [])])) * 2
          )
        }).map((_, index) => {
          const number = renderNumber([...(listDynamic ?? [])]) * 2
          const start = index * number
          const end = (index + 1) * number
          const dataList = listDynamic.slice(start, end)
          const lastIndex = Math.ceil(listDynamic.length / number)
          return (
            <Page
              key={uuid()}
              size="A3"
              orientation="landscape"
              style={[styles.container, { position: 'relative' }]}
              wrap
            >
              <View
                style={{
                  width: '100%',
                  paddingTop: 55,
                  paddingRight: 32,
                  paddingLeft: 32,
                  paddingBottom: 40,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 12
                }}
              >
                {RenderBlock({
                  items: dataList,
                  renderNumber: renderNumber([...(dataList ?? [])]),
                  style: {
                    width: `${
                      Math.ceil(
                        dataList.length / renderNumber([...(dataList ?? [])])
                      ) > 1
                        ? '48%'
                        : '100%'
                    }`
                  }
                })}
              </View>
              {lastIndex === index + 1 && (
                <Signature
                  tenant={tenant}
                  lastMedical={lastMedical}
                  style={{
                    position: 'absolute',
                    bottom: 40,
                    right: 32
                  }}
                />
              )}
            </Page>
          )
        })}

      {/* TODO: enable when this feature is necessary  */}
      {/* <HealthRecordPDF
        customer={customer}
        tenant={tenant}
        lastMedical={lastMedical}
        t={t}
      /> */}
    </Document>
  )
}

export default ResultPreviewPdf
