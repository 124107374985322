import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Spin } from 'antd'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  ChooseAge,
  ChooseGender,
  Discussion,
  FilterButton,
  RoomList,
  Rooms,
  Search,
  Tabs
} from './components/'
import Filter from './components/Filter/Filter'
import { GetRoomsData } from './types/types'
import {
  filterInfoAtom,
  loadMoreMessagesAtom,
  roomsAtomRooms,
  roomsSortedAtom,
  typingMessageAtom
} from 'hooks/chat/atoms'
import { useChatHistory } from 'hooks/chat/chatMessage'
import useMessageList from 'hooks/chat/messageList'
import useTabFilterMessages from 'hooks/tab/useTabFilterMessages'
import ChatLayout from 'layouts/ChatLayout/ChatLayout'
import { sort } from 'rrule/dist/esm/dateutil'
import reservationApi from 'services/reservationApi'
import {
  cleanupPreviousRoom,
  filterRooms,
  handleGetMessages
} from 'utilities/chatHelpers'
import { getLastMsgForRoom } from 'utilities/helpers'

function ChatPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const rId = searchParams.get('rId')

  const { t } = useTranslation()

  // hooks
  const { items, changeTab } = useTabFilterMessages()
  const {
    methods,
    convertMessage,
    setMessageList,
    subscribeEmitterForChatRoom,
    unsubscribeEmitterForChatRoom
  } = useMessageList()

  // atom setters
  const [loadMoreMessagesState, setLoadMoreMessagesState] =
    useRecoilState(loadMoreMessagesAtom)

  const { fetchMessagesInRoom } = useChatHistory()

  const [filterInfo, setFilterInfo] = useRecoilState(filterInfoAtom)
  const [roomHistory, setRoomsHistoryList] = useRecoilState(roomsAtomRooms)
  const [roomsSorted, setRoomsSortedAtom] = useRecoilState(roomsSortedAtom)
  const setTypingMessage = useSetRecoilState(typingMessageAtom)

  const rIdRef = useRef(rId)
  const filterInfoRef = useRef(filterInfo)

  const [loading, setLoading] = useState(false)

  // mapping filterInfo to ref
  useEffect(() => {
    filterInfoRef.current = filterInfo
  }, [filterInfo])

  // handle check filter and apply filter to rooms
  useEffect(() => {
    if (roomHistory?.rooms.length <= 0) return

    // handle filter rooms
    const newRooms = filterRooms(filterInfoRef.current, roomHistory)
    setRoomsSortedAtom(newRooms)
  }, [roomHistory])

  // fetch message by room id
  useEffect(() => {
    if (!rId) return

    rIdRef.current = rId

    const index = roomsSorted.findIndex((room) => room._id === rId)

    if (index === -1) {
      return
    }

    getMessageAndReadRoom(rId)
  }, [rId, roomsSorted])

  // if params haven't been rId yet, then set it to the first room
  useEffect(() => {
    if (roomsSorted.length <= 0) return
    const firstRoom = roomsSorted[0]
    const firstRoomId = firstRoom._id

    if (!rId) {
      setSearchParams({ rId: firstRoomId })
      return
    }
  }, [roomsSorted])

  useEffect(() => {
    subscribeEmitterForChatRoom()

    return () => {
      cleanupPreviousRoom(rIdRef.current as string, setTypingMessage)
      unsubscribeEmitterForChatRoom()
    }
  }, [])

  // get rooms
  useEffect(() => {
    const data: GetRoomsData = {
      filter: '',
      size: -1
    }

    setLoading(true)

    reservationApi
      .getRooms(data)
      .then((res) => {
        if (!res?.payload) return

        const newRooms = res.payload.map((room) => ({
          ...room,
          lastMessage: {
            ...room.lastMessage,
            msg: getLastMsgForRoom(room.lastMessage)
          }
        }))

        setRoomsHistoryList((prev) => ({
          ...prev,
          rooms: newRooms
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const getMessageAndReadRoom = async (rId: string) => {
    if (!rId) return null

    await initializeSelectedRoom(rId)
  }

  const initializeSelectedRoom = async (roomId: string) => {
    // subscriptionUserActivity(roomId)
    await handleGetMessages({
      roomId,
      timestamp: null,
      loadMoreMessagesState,
      setLoadMoreMessagesState,
      fetchMessagesInRoom,
      convertMessage,
      setMessageList
    })

    // if (unreadCount > 0) {
    //   await chatMessageApi.readMessages({ roomId })
    // }
  }

  // handle change tab
  const handleChangeTab = useCallback((id: string) => {
    changeTab(id)
  }, [])

  const handleClickRoom = useCallback(
    async (roomId: string) => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev)
        params.set('rId', roomId)

        return params
      })
    },
    [loadMoreMessagesState.isLoading, rId, roomsSorted]
  )

  return (
    <FormProvider {...methods}>
      {loading && <Spin fullscreen />}
      <ChatLayout
        filter={
          <Filter
            title={t('chat.chatRoom')}
            search={<Search />}
            chooseGender={<ChooseGender />}
            chooseAge={<ChooseAge />}
            filterButton={<FilterButton />}
          />
        }
        rooms={
          <Rooms
            tabs={<Tabs items={items} onTabChange={handleChangeTab} />}
            roomList={<RoomList onClick={handleClickRoom} />}
          />
        }
        discussion={<Discussion />}
      />
    </FormProvider>
  )
}

export default memo(ChatPage)
