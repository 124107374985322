import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import { InputForm as RegisterForm } from '../CustomerRegister'
import { ReactComponent as ArrowBottom } from 'assets/svgs/arrow-bottom.svg'
import { InputLabel } from 'components/elements/InputLabel'
import { COUNTRY } from 'models/setting'
import {
  createProvinceList,
  findProvinceLabel,
  formatInternationalPhone
} from 'utilities/helpers'

type Props = {
  t: TFunction<'translation', undefined>
  initialData?: RegisterForm
}

const CustomerDisplayForm: FC<Props> = ({ t, initialData }) => {
  const methods = useForm<RegisterForm>({
    defaultValues: {
      companyName: initialData?.companyName,
      id: initialData?.id,
      firstName: initialData?.firstName,
      lastName: initialData?.lastName,
      firstNameKana: initialData?.firstNameKana,
      lastNameKana: initialData?.lastNameKana,
      gender: initialData?.gender,
      birthday: initialData?.birthday,
      zipCode: initialData?.zipCode,
      country: initialData?.country,
      province: initialData?.province,
      district: initialData?.district,
      detailedAddress: initialData?.detailedAddress,
      apartment: initialData?.apartment,
      stateName: initialData?.stateName,
      phoneNumber: initialData?.phoneNumber,
      dialCodePhoneNumber: initialData?.dialCodePhoneNumber,
      emergencyContactNumber: initialData?.emergencyContactNumber,
      dialCodeEmergencyContactNumber:
        initialData?.dialCodeEmergencyContactNumber,
      email: initialData?.email,
      typeOfInsurance: initialData?.typeOfInsurance,
      numberOfInsurance: initialData?.numberOfInsurance,
      insuranceSymbol: initialData?.insuranceSymbol,
      insuranceNumber: initialData?.insuranceNumber,
      collaborativeApplication: initialData?.collaborativeApplication,
      userID: initialData?.userID,
      applicationStartDate: initialData?.applicationStartDate,
      note: initialData?.note,
      foreignInsuranceCompany: initialData?.foreignInsuranceCompany,
      drugAllergy: initialData?.drugAllergy,
      address: initialData?.address
    }
  })

  const provinceList: { value: string; label: string }[] = createProvinceList(t)
  const province = findProvinceLabel(
    methods.getValues('province'),
    provinceList
  )

  const isJapan = methods.getValues('country') === COUNTRY.JAPAN

  return (
    <div className="rounded-[5px] bg-white pb-[26px] pl-[10px] pt-[10px] drop-shadow-lg">
      <Flex className="mb-[20px] mt-2">
        <Typography className="mr-[50px] text-[16px] font-bold tracking-[1.6px] text-[#137695]">
          {t('customerRegister.displayInfo.basicInformation')}
        </Typography>

        <ArrowBottom
          width="16px"
          height="16px"
          className="mr-[15px] mt-[6px] cursor-pointer"
        />
      </Flex>

      <FormProvider {...methods}>
        <form>
          {/*0*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.companyName')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('companyName') ? (
              <Typography className="ml-3">
                {methods.getValues('companyName')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*1*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.id')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('id') ? (
              <Typography className="ml-3">
                {methods.getValues('id')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*2 + 3*/}
          <Flex className="mb-[6px]">
            <Flex align="center">
              <InputLabel
                label={t('customerRegister.label.firstName')}
                className="h-[30px] min-w-[160px] font-bold"
              />

              <Typography className="ml-3 min-w-[240px] max-w-[400px]">
                {methods.getValues('firstName')}
              </Typography>
            </Flex>

            <Flex align="center" className="ml-[30px]">
              <InputLabel
                label={t('customerRegister.label.lastName')}
                className="h-[30px] w-[160px] font-bold"
              />

              <Typography className="ml-3 min-w-[240px] max-w-[400px]">
                {methods.getValues('lastName')}
              </Typography>
            </Flex>
          </Flex>

          {/*4 + 5*/}
          <Flex className="mb-[6px]">
            <Flex align="center">
              <InputLabel
                label={t('customerRegister.label.firstNameKana')}
                className="h-[30px] w-[160px] font-bold"
              />

              {methods.getValues('firstNameKana') ? (
                <Typography className="ml-3 min-w-[240px] max-w-[400px]">
                  {methods.getValues('firstNameKana')}
                </Typography>
              ) : (
                <div className="ml-3 w-[240px]" />
              )}
            </Flex>

            <Flex align="center" className="ml-[30px]">
              <InputLabel
                label={t('customerRegister.label.lastNameKana')}
                className="h-[30px] w-[160px] font-bold"
              />

              {methods.getValues('lastNameKana') ? (
                <Typography className="ml-3 min-w-[240px] max-w-[400px]">
                  {methods.getValues('lastNameKana')}
                </Typography>
              ) : (
                <div className="ml-3 w-[240px]" />
              )}
            </Flex>
          </Flex>

          {/*0*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.gender')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('gender') ? (
              <Typography className="ml-3">
                {methods.getValues('gender') === 'MALE'
                  ? t('customerDetail.header.male')
                  : t('customerDetail.header.female')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*6*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.birthday')}
              className="h-[36px] w-[160px] font-bold"
            />

            <Typography className="ml-3 tracking-[1.4px]">
              {dayjs(methods.getValues('birthday')).format('YYYY年MM月DD日')}
            </Typography>
          </Flex>

          {/*7 + 8 + 9*/}
          <Flex className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.address')}
              className="h-[215px] w-[160px] font-bold tracking-[1.4px] self-stretch"
            />

            <div className="ml-3">
              <Flex className="mb-[18px]">
                <Typography className="min-w-[240px]">
                  {t('basicSetting.formLabel.country')}
                </Typography>

                <Typography className="ml-3">
                  {t(`basicSetting.country.${methods.getValues('country')}`)}
                </Typography>
              </Flex>

              {isJapan ? (
                <div>
                  <Flex>
                    <Typography className="min-w-[240px]">
                      {t('basicSetting.formLabel.zip_code')}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('zipCode')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {t('basicSetting.formLabel.prefectures')}
                    </Typography>

                    <Typography className="ml-3">{province}</Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {`${t('basicSetting.formLabel.prefixAddress')}1(${t('basicSetting.formLabel.district')})`}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('district')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {`${t('basicSetting.formLabel.prefixAddress')}2(${t('basicSetting.formLabel.address')})`}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('address')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {`${t('basicSetting.formLabel.prefixAddress')}3(${t('basicSetting.formLabel.apartment')})`}{' '}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('apartment')}
                    </Typography>
                  </Flex>
                </div>
              ) : (
                <div>
                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {`${t('basicSetting.formLabel.prefixAddress')}1(${t('basicSetting.formLabel.apartment')})`}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('apartment')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {`${t('basicSetting.formLabel.prefixAddress')}2(${t('basicSetting.formLabel.address')})`}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('address')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {`${t('basicSetting.formLabel.prefixAddress')}3(${t('basicSetting.formLabel.district')})`}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('district')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {t('basicSetting.formLabel.stateName')}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('stateName')}
                    </Typography>
                  </Flex>

                  <Flex className="mt-[18px]">
                    <Typography className="min-w-[240px]">
                      {t('basicSetting.formLabel.zip_code')}
                    </Typography>

                    <Typography className="ml-3">
                      {methods.getValues('zipCode')}
                    </Typography>
                  </Flex>
                </div>
              )}
            </div>
          </Flex>

          {/*10*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.phoneNumber')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('phoneNumber') ? (
              <Typography className="ml-3 tracking-[1.4px]">
                {formatInternationalPhone(
                  methods.getValues('dialCodePhoneNumber'),
                  methods.getValues('phoneNumber')
                )}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*11*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.emergencyContactNumber')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('emergencyContactNumber') ? (
              <Typography className="ml-3 tracking-[1.4px]">
                {formatInternationalPhone(
                  methods.getValues('dialCodeEmergencyContactNumber'),
                  methods.getValues('emergencyContactNumber')
                )}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*12*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.email')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('email') ? (
              <Typography className="ml-3 tracking-[1.4px]">
                {methods.getValues('email')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*13*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.typeOfInsurance')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('typeOfInsurance') ? (
              <Typography className="ml-3 tracking-[1.4px]">
                {methods.getValues('typeOfInsurance')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*14*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.numberOfInsurance')}
              className="h-auto w-[160px] font-bold tracking-[1.4px] self-stretch"
            />

            {methods.getValues('numberOfInsurance') ? (
              <Typography className="ml-3 tracking-[1.4px]">
                {methods.getValues('numberOfInsurance')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*15 + 16*/}
          <Flex className="mb-[6px]">
            <Flex align="center">
              <InputLabel
                label={t('customerRegister.label.insuranceSymbol')}
                className="h-[30px] w-[160px] font-bold"
              />

              {methods.getValues('insuranceSymbol') ? (
                <Typography className="ml-3 min-w-[100px] max-w-[400px] tracking-[1.4px]">
                  {methods.getValues('insuranceSymbol')}
                </Typography>
              ) : (
                <div className="ml-3 w-[240px]" />
              )}
            </Flex>

            <Flex align="center" className="ml-[30px]">
              <InputLabel
                label={t('customerRegister.label.insuranceNumber')}
                className="h-[30px] w-[160px] font-bold"
              />

              {methods.getValues('insuranceNumber') ? (
                <Typography className="ml-3 min-w-[100px] max-w-[400px] tracking-[1.4px]">
                  {methods.getValues('insuranceNumber')}
                </Typography>
              ) : (
                <div className="ml-3 w-[240px]" />
              )}
            </Flex>
          </Flex>

          {/*20*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.foreignInsuranceCompany')}
              className="h-auto w-[160px] font-bold tracking-[1.4px] self-stretch"
            />

            {methods.getValues('foreignInsuranceCompany') && (
              <Typography className="ml-3">
                {methods.getValues('foreignInsuranceCompany')}
              </Typography>
            )}
          </Flex>

          {/*20*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.drugAllergy')}
              className="h-auto w-[160px] font-bold tracking-[1.4px] self-stretch whitespace-break-spaces"
            />

            {methods.getValues('drugAllergy') ? (
              <Typography className="ml-3">
                {t('content.canBe')} ({methods.getValues('drugAllergy')})
              </Typography>
            ) : (
              <Typography className="ml-3">{t('content.none')}</Typography>
            )}
          </Flex>

          {/*17*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.collaborativeApplication')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('collaborativeApplication') ? (
              <Typography className="ml-3 w-[100px] tracking-[1.4px]">
                {methods.getValues('collaborativeApplication')}
              </Typography>
            ) : (
              <Flex
                justify="center"
                align="center"
                className="ml-[33px] h-[26px] w-[103px] rounded-[13px] bg-[#BDCBD5] text-white"
              >
                {t('customerRegister.label.none')}
              </Flex>
            )}
          </Flex>

          {/*18*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.userID')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('userID') ? (
              <Typography className="ml-3">
                {methods.getValues('userID')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*19*/}
          <Flex align="center" className="mb-[6px]">
            <InputLabel
              label={t('customerRegister.label.applicationStartDate')}
              className="h-auto w-[160px] font-bold tracking-[1.4px] self-stretch"
            />

            {methods.getValues('applicationStartDate') ? (
              <Typography className="ml-3">
                {methods.getValues('applicationStartDate')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>

          {/*20*/}
          <Flex align="center">
            <InputLabel
              label={t('customerRegister.label.note')}
              className="h-[36px] w-[160px] font-bold tracking-[1.4px]"
            />

            {methods.getValues('note') ? (
              <Typography className="ml-3">
                {methods.getValues('note')}
              </Typography>
            ) : (
              <div className="w-[240px]" />
            )}
          </Flex>
        </form>
      </FormProvider>
    </div>
  )
}

export default CustomerDisplayForm
