import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'
import { TFunction } from 'i18next'
import * as yup from 'yup'

import CategoryItems from './setupThresholdAndCriteria/CategoryItems'
import JudgmentItem from './setupThresholdAndCriteria/JudgmentItem'
import JudgmentItemSave from './setupThresholdAndCriteria/JudgmentItemSave'
import MenuCategoryList from './setupThresholdAndCriteria/MenuCategoryList'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePickerForm } from 'components/elements/DatePickerForm'
import { InputForm } from 'components/elements/InputForm'
import { openNotification } from 'components/widgets/Notification'
import {
  ConditionalNumber,
  readingTypeItemMaster,
  typeReferenceValue
} from 'configs/constant'
import { CategoryWithMultipleLang } from 'types/Categories'
import { ItemMasterWithMultipleLang } from 'types/ItemMasters'
import { StringKeyObject } from 'types/common'
import { getLanguage, uuid } from 'utilities/helpers'

export interface IJudgementItem {
  id: string
  evaluation: string
  bgColor: string
  description: string
  order: number
}

const defaultJudgementItem: IJudgementItem = {
  id: uuid(false),
  evaluation: '',
  bgColor: '#F5006B',
  description: '',
  order: 999
}

export const JUDGMENT_ITEM = 'judgment_item'
export const JUDGMENT = 'judgment'
export const ITEM_START_NAME = 'item_'
export const JUDGMENT_START_NAME = 'judgment_'

const getJudgementItemList = (t: TFunction<'translation', undefined>) => {
  return [
    {
      id: uuid(false),
      evaluation: 'A',
      bgColor: '#5CAF53',
      description: t('judgementSetting.noAbnormality'),
      order: 1
    },
    {
      id: uuid(false),

      evaluation: 'B',
      bgColor: '#F5B125',
      description: t('judgementSetting.mildAbnormality'),
      order: 2
    },
    {
      id: uuid(false),
      evaluation: 'C',
      bgColor: '#F5006B',
      description: t('judgementSetting.examination'),
      order: 3
    }
  ]
}

const getSchema = (
  judgmentItems: IJudgementItem[],
  category: CategoryWithMultipleLang[]
) => {
  const items: ItemMasterWithMultipleLang[] = category.reduce(
    (acc: ItemMasterWithMultipleLang[], item: CategoryWithMultipleLang) => {
      if (Array.isArray(item.itemMaster)) {
        acc = [...acc, ...item.itemMaster]
      }
      return acc
    },
    []
  )
  const types = Object.values(typeReferenceValue)

  return {
    application_period_start: yup.string(),
    application_period_end: yup.string().test('', '', function (value) {
      const { application_period_start } = this.parent

      if (value && application_period_start) {
        return new Date(value) >= new Date(application_period_start)
      }
      return true
    }),
    [JUDGMENT_ITEM]: yup.object().shape(
      judgmentItems.reduce(
        (acc, judgmentItem) => {
          acc[judgmentItem.id] = yup.object().shape({
            evaluation: yup.string().max(50).required(),
            description: yup.string().max(50).required()
          })
          return acc
        },
        {} as Record<string, yup.AnyObjectSchema>
      )
    ),
    [JUDGMENT]: yup.object().shape(
      judgmentItems.reduce(
        (judgmentValidate, judgmentItem) => {
          judgmentValidate[judgmentItem.id] = yup.object().shape(
            items.reduce(
              (itemMasterValidate, itemMaster) => {
                itemMasterValidate[ITEM_START_NAME + itemMaster.refId] =
                  getSchemaItemMaster(types)
                return itemMasterValidate
              },
              {} as Record<string, yup.AnySchema>
            )
          )
          return judgmentValidate
        },
        {} as Record<string, yup.AnyObjectSchema>
      )
    )
  }
}

const getSchemaItemMaster = (types: string[]) => {
  return yup.array().of(
    yup.object().shape({
      type: yup.string().oneOf(Object.values(types)).required(),
      text: yup.string().when('type', {
        is: typeReferenceValue.STRING,
        then: (schema) => schema.max(30).required()
      }),
      value: yup.number().when('type', {
        is: typeReferenceValue.CONDITIONAL_NUMBER,
        then: (schema) => schema.min(0).required()
      }),
      select: yup.string().when('type', {
        is: typeReferenceValue.CONDITIONAL_NUMBER,
        then: (schema) => schema.required()
      }),
      start: yup.number().when('type', {
        is: typeReferenceValue.RANGE_NUMBER,
        then: (schema) => schema.min(0).required()
      }),
      end: yup.number().when('type', {
        is: typeReferenceValue.RANGE_NUMBER,
        then: (schema) =>
          schema
            .required()
            .min(0)
            .test('', '', function (value) {
              return value > this.parent.start
            })
      })
    })
  )
}

export interface DataForm {
  id: string
  application_period_start?: string
  application_period_end?: string
  [JUDGMENT_ITEM]: StringKeyObject
  [JUDGMENT]: StringKeyObject
}
type Props = {
  category: CategoryWithMultipleLang[]
  nameCourse?: string | null
  setAddRefValue: Function
  listSetupThresholdAndCriteria: DataForm[]
  setListSetupThresholdAndCriteria: React.Dispatch<
    React.SetStateAction<DataForm[]>
  >
  editThresholdAndCriteria: DataForm | null
  setEditThresholdAndCriteria: React.Dispatch<
    React.SetStateAction<DataForm | null>
  >
}

export default function SetupThresholdAndCriteria({
  category,
  nameCourse,
  setAddRefValue,
  listSetupThresholdAndCriteria,
  setListSetupThresholdAndCriteria,
  editThresholdAndCriteria,
  setEditThresholdAndCriteria
}: Props) {
  const { t } = useTranslation()
  const language = getLanguage()

  const [saveData, setSaveData] = useState<DataForm | null>(null)

  const [judgementItemList, setJudgementItemList] = useState<IJudgementItem[]>(
    () => getJudgementItemList(t)
  )

  const [isJudgementItemEdit, setIsJudgementItemEdit] = useState<boolean>(false)
  const [resolverSchema, setResolverSchema] = useState<yup.AnyObjectSchema>(
    yup.object().shape(getSchema(judgementItemList, category))
  )

  useEffect(() => {
    if (category.length > 0 && judgementItemList.length > 0)
      setResolverSchema(
        yup.object().shape(getSchema(judgementItemList, category))
      )
  }, [judgementItemList, category])

  const methods = useForm({
    defaultValues: {
      id: uuid(false)
    },
    resolver: yupResolver(resolverSchema)
  })

  const { handleSubmit, unregister, setValue } = methods

  useEffect(() => {
    if (editThresholdAndCriteria) {
      setValue('id', editThresholdAndCriteria.id)
      setValue(
        'application_period_start',
        editThresholdAndCriteria.application_period_start
      )
      setValue(
        'application_period_end',
        editThresholdAndCriteria.application_period_end
      )

      const judgmentItems = editThresholdAndCriteria[JUDGMENT_ITEM] ?? {}

      setJudgementItemList(
        Object.keys(judgmentItems).map((key) => ({
          ...judgmentItems[key],
          id: key
        }))
      )

      setSaveData(editThresholdAndCriteria)
      unregister(JUDGMENT_ITEM)
      setIsJudgementItemEdit(true)
    }
  }, [editThresholdAndCriteria])

  const handleAddJudgementItem = () => {
    setJudgementItemList((prev: IJudgementItem[]) => {
      const order = prev[prev.length - 1].order + 1

      return [
        ...prev,
        {
          ...defaultJudgementItem,
          id: uuid(),
          order: order
        }
      ]
    })
  }

  const handleRemoveLastJudgementItem = () => {
    const idRemove = judgementItemList.at(-1)?.id
    if (idRemove) {
      unregister(`${JUDGMENT_ITEM}[${idRemove}]`)
      unregister(`${JUDGMENT}[${idRemove}]`)

      setJudgementItemList((prev) =>
        prev.filter((item) => item.id !== idRemove)
      )
    }
  }

  const onSubmit = async (data: DataForm) => {
    const date_start = data.application_period_start
      ? new Date(data.application_period_start).getTime()
      : null
    const date_end = data.application_period_end
      ? new Date(data.application_period_end).getTime()
      : null

    // Check for empty dates and existing items in the list
    if (!date_start && !date_end) {
      if (
        (editThresholdAndCriteria &&
          listSetupThresholdAndCriteria.length > 1) ||
        (!editThresholdAndCriteria && listSetupThresholdAndCriteria.length > 0)
      ) {
        showErrorDate()
        return
      }
    }

    for (const item of listSetupThresholdAndCriteria) {
      if (item.id === data.id) continue

      const date_start_item = item.application_period_start
        ? new Date(item.application_period_start).getTime()
        : null
      const date_end_item = item.application_period_end
        ? new Date(item.application_period_end).getTime()
        : null

      // Case: Both start and end dates are missing in the item
      if (!date_start_item && !date_end_item) {
        showErrorDate()
        return
      }

      // Case: Start date missing in the item
      if (
        !date_start_item &&
        date_end_item &&
        (!date_start || date_start <= date_end_item)
      ) {
        showErrorDate()
        return
      }

      // Case: End date missing in the item
      if (
        date_start_item &&
        !date_end_item &&
        (!date_end || date_end >= date_start_item)
      ) {
        showErrorDate()
        return
      }

      // Case: Both start and end dates exist in the item
      if (date_start_item && date_end_item) {
        // Overlapping period check
        if (
          (date_start &&
            ((date_start >= date_start_item && date_start <= date_end_item) ||
              (!date_end && date_start <= date_start_item))) ||
          (date_end &&
            ((date_end >= date_start_item && date_end <= date_end_item) ||
              (!date_start && date_end >= date_end_item)))
        ) {
          showErrorDate()
          return
        }

        // Fully encapsulating period check
        if (
          date_start &&
          date_end &&
          date_start <= date_start_item &&
          date_end >= date_end_item
        ) {
          showErrorDate()
          return
        }
      }
    }

    const judgmentItemEvaluations = new Set<string>()
    for (const key of Object.keys(data[JUDGMENT_ITEM])) {
      const evaluation = data[JUDGMENT_ITEM][key].evaluation
      if (judgmentItemEvaluations.has(evaluation)) {
        openNotification({
          type: 'error',
          title: 'commonError',
          message: t('errors.judgmentItemsUnique')
        })

        return
      }

      judgmentItemEvaluations.add(evaluation)
    }

    const items = getObjectItemMaster()
    const errorReferenceValue = []
    const judgmentItem = Object.keys(data[JUDGMENT]).reduce(
      (acc: StringKeyObject, judgmentKey: string) => {
        Object.keys(data[JUDGMENT][judgmentKey]).forEach((itemKey) => {
          if (
            itemKey.includes(ITEM_START_NAME) &&
            Array.isArray(data[JUDGMENT][judgmentKey][itemKey])
          ) {
            const refId = itemKey.replace(ITEM_START_NAME, '')
            const item = items[refId]

            if (item && item.readingType === readingTypeItemMaster.NUMERICAL) {
              if (acc[item.refId]) {
                acc[item.refId].push(
                  ...[...data[JUDGMENT][judgmentKey][itemKey]]
                )
              } else {
                acc[item.refId] = [...data[JUDGMENT][judgmentKey][itemKey]]
              }
            }
          }
        })

        return acc
      },
      {} as StringKeyObject
    )

    for (const key of Object.keys(judgmentItem)) {
      const itemReferenceValues = judgmentItem[key].filter(
        (item: StringKeyObject | undefined) => item
      )

      if (itemReferenceValues.length > 0) {
        const constraintGender = itemReferenceValues.some(
          (item: StringKeyObject) => item.gender
        )

        if (!constraintGender) {
          if (!validateValueRanges(itemReferenceValues)) {
            errorReferenceValue.push(items[key])
          }
        } else {
          const objGender = itemReferenceValues.reduce(
            (acc: StringKeyObject, item: any) => {
              if (!item.gender) return acc
              if (acc[item.gender]) {
                acc[item.gender].push(item)
              } else {
                acc[item.gender] = [item]
              }
              return acc
            },
            {}
          )

          for (const keyGender of Object.keys(objGender)) {
            if (
              !Array.isArray(objGender[keyGender]) ||
              objGender[keyGender].length === 0
            )
              continue

            if (!validateValueRanges(objGender[keyGender])) {
              errorReferenceValue.push(items[key])
              break
            }
          }
        }
      }
    }

    if (errorReferenceValue.length > 0) {
      showErrorReferenceValue(errorReferenceValue)
      return
    }

    setSaveData(data)
  }

  const validateValueRanges = (data: StringKeyObject[]) => {
    const dataMap = data.map((item: any) => {
      const { type, value, select, start, end } = item || {}
      if (type === typeReferenceValue.CONDITIONAL_NUMBER) {
        return select === ConditionalNumber.LE
          ? { end: value, start: null }
          : { end: null, start: value }
      }
      return { start, end }
    })

    for (let i = 0; i < dataMap.length; i++) {
      const item = dataMap[i]
      const totalItemNext = dataMap.slice(i + 1)
      if (totalItemNext.length > 0) {
        const checkTime = checkValueRanges(item, totalItemNext)

        if (!checkTime) {
          return false
        }
      }
    }

    return true
  }

  const checkValueRanges = (time: StringKeyObject, data: StringKeyObject[]) => {
    const valueStart = time.start
    const valueEnd = time.end

    for (const item of data) {
      const valueItemStart = item.start
      const valueItemEnd = item.end

      if (
        valueItemStart === null &&
        valueItemEnd !== null &&
        (valueStart === null || valueStart <= valueItemEnd)
      ) {
        return false
      }

      if (
        valueItemStart !== null &&
        valueItemEnd === null &&
        (valueEnd === null || valueEnd >= valueItemStart)
      ) {
        return false
      }

      if (valueItemStart !== null && valueItemEnd !== null) {
        if (
          (valueStart !== null &&
            ((valueStart >= valueItemStart && valueStart <= valueItemEnd) ||
              (valueEnd === null && valueStart <= valueItemStart))) ||
          (valueEnd !== null &&
            ((valueEnd >= valueItemStart && valueEnd <= valueItemEnd) ||
              (valueStart === null && valueEnd >= valueItemEnd)))
        ) {
          return false
        }

        if (
          valueStart !== null &&
          valueEnd !== null &&
          valueStart <= valueItemStart &&
          valueEnd >= valueItemEnd
        ) {
          return false
        }
      }
    }

    return true
  }

  const getObjectItemMaster = () => {
    if (!Array.isArray(category)) return {}
    const items = category.reduce(
      (
        acc: Record<string, ItemMasterWithMultipleLang>,
        item: CategoryWithMultipleLang
      ) => {
        if (Array.isArray(item.itemMaster)) {
          item.itemMaster.forEach((itemMaster) => {
            acc[itemMaster.refId] = itemMaster
          })
        }
        return acc
      },
      {}
    )
    return items
  }

  const showErrorDate = () => {
    openNotification({
      type: 'error',
      title: 'commonError',
      message: t('errors.invalidPeriod')
    })
  }

  const showErrorReferenceValue = (items: ItemMasterWithMultipleLang[]) => {
    const ArrName = items.map((item) => item.lang?.[language]).join(', ')

    openNotification({
      type: 'error',
      title: 'commonError',
      message: ArrName + ': ' + t('errors.duplicateJudgmentNotAllowed')
    })
  }

  const handleSave = () => {
    if (saveData) {
      setListSetupThresholdAndCriteria((prev: DataForm[]) => {
        const updatedList = prev.map((item) =>
          item.id === saveData.id ? { ...saveData } : item
        )

        if (!updatedList.some((item) => item.id === saveData.id)) {
          updatedList.push(saveData)
        }

        return updatedList
      })
    }

    handleBack()
  }

  const handleBack = () => {
    setSaveData(null)
    setAddRefValue(false)
    setEditThresholdAndCriteria(null)
  }

  return (
    <FormProvider {...methods}>
      <form
        className="mr-[-10px] overflow-x-scroll min-w-max"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex className="items-center justify-between pr-2.5">
          <Flex className="items-center gap-10">
            <Typography className="text-base font-bold tracking-[1.6px]">
              {t('menu.judgementSetting')}
            </Typography>
            <Typography>
              {t('content.selectProductForStandardValue')}
            </Typography>
          </Flex>
          <Typography
            className="text-primary underline font-bold tracking-[1.4px] cursor-pointer mt-1.5"
            onClick={handleBack}
          >
            ＜{t('button.return')}
          </Typography>
        </Flex>

        <div className="bg-white shadow-md rounded-md pt-3 mt-2.5 pr-10">
          <Flex className="items-end">
            <Flex
              vertical
              className={`px-4 w-[660px] ${saveData ? 'gap-3' : 'gap-2.5'}`}
            >
              <Row className="gap-3">
                <Col flex="120px">
                  <Typography className="font-bold text-primary">
                    {t('lable.selectedProductName')}
                  </Typography>
                </Col>
                <Col flex="auto">
                  <Typography>{nameCourse}</Typography>
                </Col>
              </Row>
              <InputForm name="id" className="hidden" />
              <Row className="gap-3">
                <Col flex="120px">
                  <Typography className="font-bold text-primary">
                    {t('lable.applicationPeriod')}
                  </Typography>
                </Col>
                <Col flex="auto" className="flex flex-col">
                  <Row className="gap-2 items-center">
                    {saveData ? (
                      saveData.application_period_start
                    ) : (
                      <DatePickerForm
                        name="application_period_start"
                        className="w-[200px]"
                        placeholder={t('placeholder.selectApplicationPeriod')}
                      />
                    )}
                    ～
                    {saveData ? (
                      saveData.application_period_end
                    ) : (
                      <DatePickerForm
                        name="application_period_end"
                        className="w-[200px]"
                        placeholder={t('placeholder.selectApplicationPeriod')}
                      />
                    )}
                  </Row>
                  {saveData && (
                    <Typography className="mt-1 text-error text-xs font-semibold">
                      {t('content.regPeriodDelNote')}
                    </Typography>
                  )}
                </Col>
              </Row>
              <Flex className="gap-3">
                {saveData ? (
                  <>
                    <Button
                      type="primary"
                      className="min-w-[120px]"
                      onClick={handleSave}
                    >
                      {t('button.keep')}
                    </Button>
                    <Button
                      className="min-w-[120px] text-primary border border-primary"
                      onClick={() => setSaveData(null)}
                    >
                      {t('lable.edit')}
                    </Button>
                  </>
                ) : (
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="min-w-[120px]"
                  >
                    {t('button.save')}
                  </Button>
                )}
              </Flex>
            </Flex>
            <Flex className="gap-1.5">
              <Flex className="gap-2.5">
                {!saveData &&
                  judgementItemList.map(
                    (item: IJudgementItem, index: number) => {
                      return (
                        <JudgmentItem key={item.id} item={item} index={index} />
                      )
                    }
                  )}
                {saveData &&
                  Object.keys(saveData[JUDGMENT_ITEM]).map(
                    (item: string, index: number) => {
                      return (
                        <JudgmentItemSave
                          key={saveData[JUDGMENT_ITEM]?.[item]?.id}
                          item={saveData[JUDGMENT_ITEM]?.[item]}
                          index={index}
                        />
                      )
                    }
                  )}
              </Flex>
              {!saveData && (
                <Flex vertical className="gap-1.5">
                  <Flex
                    className="w-6 h-6 rounded-md bg-primary text-white items-center justify-center cursor-pointer"
                    onClick={handleAddJudgementItem}
                  >
                    <span className="text-[24px]">+</span>
                  </Flex>
                  {Array.isArray(judgementItemList) &&
                    judgementItemList.length > 3 && (
                      <Flex
                        className="bg-[#BFC6CB] w-6 h-6 flex items-center justify-center  rounded-md cursor-pointer"
                        onClick={handleRemoveLastJudgementItem}
                      >
                        <span className="w-3 h-[2px] bg-white inline-block"></span>
                      </Flex>
                    )}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex className="mt-3 items-stretch">
            <div className="w-[215px]">
              <MenuCategoryList category={category} language={language} />
            </div>
            <div className="flex-1">
              {category?.map((item: CategoryWithMultipleLang) => {
                return (
                  <CategoryItems
                    key={item.refId}
                    category={item}
                    judgementItemList={judgementItemList}
                    language={language}
                    saveData={saveData}
                    editThresholdAndCriteria={editThresholdAndCriteria}
                    isJudgementItemEdit={isJudgementItemEdit}
                  />
                )
              })}
            </div>
          </Flex>
        </div>
        <Flex className="ml-[215px] gap-3 mt-8">
          {saveData ? (
            <>
              <Button
                type="primary"
                className="min-w-[120px]"
                onClick={handleSave}
              >
                {t('button.keep')}
              </Button>
              <Button
                className="min-w-[120px] text-primary border border-primary"
                onClick={() => setSaveData(null)}
              >
                {t('lable.edit')}
              </Button>
            </>
          ) : (
            <Button htmlType="submit" type="primary" className="min-w-[120px]">
              {t('button.save')}
            </Button>
          )}
        </Flex>
      </form>
    </FormProvider>
  )
}
