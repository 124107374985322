import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Col, Flex, Radio, Row, Typography } from 'antd'

import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel } from 'components/elements/InputLabel'
import {
  SelectMultipleCustom,
  SelectSearchCustom
} from 'components/elements/Select'
import { IAdmin } from 'types/Admin'
import { Options } from 'types/common'
import { getLanguage } from 'utilities/helpers'

const WIDTH_LABEL = '160px'
const WIDTH_BOX = 'w-[568px]'
const getInputLabel = (label: string, required: boolean = false) => {
  return (
    <InputLabel
      align="center"
      label={label}
      required={required}
      className="!px-0.5 !py-[3px]"
      classRequired="!py-0"
    />
  )
}

export const roleOptionByLanguage = (
  roleOptions: Options[],
  language: string
) => {
  return roleOptions.map((item: Options) => ({
    ...item,
    label: item?.lang?.[language] ?? item.label
  }))
}

export default function AdminRegisterForm({
  index,
  roleOptions,
  departmentOptions,
  assignmentOptions,
  positionOptions,
  genderOptions,
  adminOptions,
  user
}: {
  index: number
  roleOptions: Options[]
  departmentOptions: Options[]
  assignmentOptions: Options[]
  positionOptions: Options[]
  genderOptions: Options[]
  adminOptions: Options[]
  user: IAdmin | null
}) {
  const language = getLanguage()
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Flex vertical className="gap-2">
      {user?.isOwner && (
        <Row className={`gap-2 ${WIDTH_BOX}`}>
          <Col flex={WIDTH_LABEL}>
            {getInputLabel(t('lable.ownerPermissionChange'))}
          </Col>
          <Col flex="auto">
            <Flex className="items-center gap-1.5">
              <CrownIcon className="inline-block w-[17px]" />
              <Typography className="font-bold text-primary">
                {t('content.setAsCurrentOwner')}
              </Typography>
            </Flex>

            <SelectSearchCustom
              name={`blocks.${index}.ownership_change`}
              options={adminOptions}
              className="w-full mt-1"
            />
          </Col>
        </Row>
      )}
      <Flex className="gap-[30px] items-start">
        <Flex className={`${WIDTH_BOX} flex-wrap gap-1.5`}>
          <Row className="gap-2 w-full">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('customerDetail.form.label.firstName'), true)}
            </Col>
            <Col flex="auto">
              <InputForm name={`blocks.${index}.first_name`} />
              <Typography className="text-[10px] mt-1">
                {t('customerDetail.notice')}
              </Typography>
            </Col>
          </Row>
          <Row className="gap-2 w-full">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('customerDetail.form.label.firstNameKana'))}
            </Col>
            <Col flex="auto">
              <InputForm name={`blocks.${index}.first_name_kana`} />
              <Typography className="text-[10px] mt-1">
                {t('customerDetail.notice')}
              </Typography>
            </Col>
          </Row>
          <Row className="gap-2 w-full">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('lable.gender'), true)}
            </Col>
            <Col flex="auto" className="flex items-center">
              <Controller
                name={`blocks.${index}.gender`}
                control={control}
                render={({ field }) => {
                  return (
                    <Radio.Group
                      {...field}
                      optionType="button"
                      options={genderOptions}
                      className="custom-radio-group"
                    />
                  )
                }}
              />
            </Col>
          </Row>
          <Row className="gap-2 w-full items-center">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('placeholder.emailAddress'), true)}
            </Col>
            <Col flex="auto">
              <InputForm name={`blocks.${index}.email`} />
            </Col>
          </Row>
          <Row className="gap-2 w-full items-center">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('lable.userName'), true)}
            </Col>
            <Col flex="auto">
              {!user && <InputForm name={`blocks.${index}.user_name`} />}
              {user && (
                <Typography className="pl-2.5">{user?.username}</Typography>
              )}
            </Col>
          </Row>
          {!user && (
            <>
              <Row className="gap-2 w-full items-center">
                <Col flex={WIDTH_LABEL}>
                  {getInputLabel(t('placeholder.password'), true)}
                </Col>
                <Col flex="auto">
                  <InputForm
                    name={`blocks.${index}.password`}
                    type="password"
                  />
                </Col>
              </Row>
              <Row className="gap-2 w-full items-center">
                <Col flex={WIDTH_LABEL}>
                  {getInputLabel(t('lable.passwordConfirm'), true)}
                </Col>
                <Col flex="auto">
                  <InputForm
                    name={`blocks.${index}.confirm_password`}
                    type="password"
                  />
                </Col>
              </Row>
            </>
          )}
          <Row className="gap-2 w-full items-center">
            <Col flex={WIDTH_LABEL}>{getInputLabel(t('role'))}</Col>
            <Col flex="auto">
              <SelectMultipleCustom
                name={`blocks.${index}.roles`}
                placeholder={t('placeholder.searchRoles')}
                options={roleOptionByLanguage(roleOptions, language)}
                className="courseSelect w-full"
              />
            </Col>
          </Row>
          <Row className="gap-2 w-full items-center">
            <Col flex={WIDTH_LABEL}>{getInputLabel(t('affiliation'))}</Col>
            <Col flex="auto">
              <SelectMultipleCustom
                name={`blocks.${index}.departments`}
                placeholder={t('placeholder.searchAffiliation')}
                options={departmentOptions}
                className="courseSelect w-full"
              />
            </Col>
          </Row>
          <Row className="gap-2 w-full items-center">
            <Col flex={WIDTH_LABEL}>{getInputLabel(t('table.assignment'))}</Col>
            <Col flex="auto">
              <SelectMultipleCustom
                name={`blocks.${index}.assignments`}
                placeholder={t('placeholder.searchAssignment')}
                options={assignmentOptions}
                className="courseSelect w-full"
              />
            </Col>
          </Row>
          <Row className="gap-2 w-full items-center">
            <Col flex={WIDTH_LABEL}>{getInputLabel(t('lable.position'))}</Col>
            <Col flex="auto">
              <SelectMultipleCustom
                name={`blocks.${index}.positions`}
                placeholder={t('placeholder.searchPosition')}
                options={positionOptions}
                className="courseSelect w-full"
              />
            </Col>
          </Row>
        </Flex>
        <Flex className={`${WIDTH_BOX} flex-wrap gap-1.5`}>
          <Row className="gap-2 w-full">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('customerDetail.form.label.lastName'), true)}
            </Col>
            <Col flex="auto">
              <InputForm name={`blocks.${index}.last_name`} />
              <Typography className="text-[10px] mt-1">
                {t('customerDetail.notice')}
              </Typography>
            </Col>
          </Row>
          <Row className="gap-2 w-full">
            <Col flex={WIDTH_LABEL}>
              {getInputLabel(t('customerDetail.form.label.lastNameKana'))}
            </Col>
            <Col flex="auto">
              <InputForm name={`blocks.${index}.last_name_kana`} />
              <Typography className="text-[10px] mt-1">
                {t('customerDetail.notice')}
              </Typography>
            </Col>
          </Row>
        </Flex>
      </Flex>
    </Flex>
  )
}
