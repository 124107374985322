import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { useSetRecoilState } from 'recoil'

import { courseAtom } from '../../atoms/course/CourseAtom'
import OtherContent from './OtherContent'
import { DRAWER_DATA_TYPE, STEP_SCENARIO } from 'configs/constant'
import useCheckErrorField from 'hooks/scenario/useCheckErrorField'
import useDataFormBlockHandler from 'hooks/scenario/useDataFormBlockHandler'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import { convertCourseOptions } from 'utilities/scenarioHelper'

function DrawerFirstBlock() {
  const { t } = useTranslation()
  const { dataForm, setDataForm } = useDataFormBlockHandler()
  const { errors, checkError } = useCheckErrorField()

  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()
  const setCourseOptions = useSetRecoilState(courseAtom)

  // get course list
  useEffect(() => {
    getMedicalCheckupMaster({
      filter: [
        { field: 'data.additionalInfo.key1', operator: 'ne', value: 'option' },
        {
          field: 'data.additionalInfo.isDeleted',
          operator: 'ne',
          value: 'true'
        }
      ],
      sortBy: {
        field: 'createdDate',
        direction: 'desc'
      }
    }).then((res) => {
      setCourseOptions(convertCourseOptions(res))
    })
  }, [])

  const firstBlock = useMemo(() => {
    if (!dataForm?.flows[STEP_SCENARIO.FIRST_STEP])
      return {
        step: '',
        content: ''
      }

    return dataForm.flows[STEP_SCENARIO.FIRST_STEP]
  }, [dataForm])

  // Simplified event handlers
  const handleChangeTemplateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkError(DRAWER_DATA_TYPE.TEMPLATE_NAME, e.target.value)

    setDataForm((prev) => ({
      ...prev,
      name: e.target.value
    }))
  }

  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    checkError(DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST, e.target.value)

    setDataForm((prev) => {
      const updatedFlows = [...prev.flows]
      updatedFlows[STEP_SCENARIO.FIRST_STEP] = {
        ...updatedFlows[STEP_SCENARIO.FIRST_STEP],
        content: e.target.value
      }
      return {
        ...prev,
        flows: updatedFlows
      }
    })
  }

  return (
    <React.Fragment>
      <div className="px-3 py-2 bg-primary">
        <p className="text-white font-bold">{t(firstBlock.step ?? '')}</p>
      </div>

      {/* template name */}
      <div className="mt-2">
        <p className="text-primary font-bold mb-2">
          {t('scenarioCreate.drawer.templateName')}
        </p>
        <Input
          onBlur={(e) =>
            checkError(DRAWER_DATA_TYPE.TEMPLATE_NAME, e.target.value)
          }
          name="templateName"
          className="text-default"
          status={errors.templateName ? 'error' : ''}
          value={dataForm.name || undefined}
          onChange={handleChangeTemplateName}
        />
      </div>

      {/* description */}
      <div className="mt-2">
        <p className="text-primary font-bold mb-2">
          {t('scenarioCreate.drawer.description')}
        </p>
        <Input.TextArea
          onBlur={(e) =>
            checkError(DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST, e.target.value)
          }
          status={errors[DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST] ? 'error' : ''}
          name={DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST}
          className="text-default"
          value={firstBlock.content}
          onChange={handleChangeDescription}
          style={{ height: 120, resize: 'none' }}
        />
      </div>

      {/* other content */}
      <div className="mt-2">
        <OtherContent setDataForm={setDataForm} dataForm={dataForm} />
      </div>
    </React.Fragment>
  )
}

export default DrawerFirstBlock
