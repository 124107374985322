import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}

const ErrorSearchUser: FC<Props> = ({ isOpen, onOk, onCancel }) => {
  const { t } = useTranslation()

  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="pb-6 pt-4">
          <div>
            <Typography className="text-center text-[20px] font-bold tracking-[1px]">
              {t('reservationRegister.modal.errorModal')}
            </Typography>

            <Flex justify="center" className="mb-[24px] mt-[120px]">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#DF2475] text-[30px] text-white">
                !
              </div>
            </Flex>

            <Typography className="px-3 text-center whitespace-pre-line">
              {t('reservationRegister.modal.notice')}
            </Typography>

            <Flex justify="center" className="mt-[120px]">
              <Button
                autoInsertSpace={false}
                type="primary"
                htmlType="submit"
                onClick={onOk}
                className="mr-3 h-[30px] min-w-[180px] rounded-[3px] bg-[#137695] text-center font-bold text-white shadow-none"
              >
                {t('reservationRegister.modal.create')}
              </Button>

              <Button
                type="default"
                htmlType="button"
                onClick={onCancel}
                className="h-[30px] min-w-[180px] rounded-[3px] border-[1px] border-[#137695] bg-white text-center font-bold text-[#137695] shadow-none"
              >
                {t('reservationRegister.modal.cancel')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default ErrorSearchUser
