import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import styles from './JudgementSetting.module.scss'
import CommentSettings from './components/CommentSettings'
import ScreenLoader from 'components/loading/ScreenLoader'

export default function JudgementSetting() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className="min-w-[1650px] relative">
      {loading && <ScreenLoader />}
      <Flex className="items-center gap-10 mb-4">
        <Typography className="text-[#545454] text-base font-bold tracking-widest">
          {t('basicSetting.basicSettingTitle')}
        </Typography>
        <Typography>{t('judgementSetting.commentSettingTitle')}</Typography>
      </Flex>

      <Flex vertical className={styles.judgement_content}>
        <CommentSettings setLoadingScreen={setLoading} />
      </Flex>
    </div>
  )
}
