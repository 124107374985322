import dayjs from 'dayjs'

import { MedicalStatus } from './enum'

export const getMedicalStatusColor = (status: MedicalStatus) => {
  switch (status) {
    case MedicalStatus.INCOMPATIBLE:
    case MedicalStatus.CREATING:
      return '#BDCBD5'
    case MedicalStatus.WAITING:
      return '#137695'
    case MedicalStatus.ENDED:
      return '#DF2475'
    default:
      return '#545454'
  }
}

export const getMedicalStatusText = (status: MedicalStatus) => {
  switch (status) {
    case MedicalStatus.INCOMPATIBLE:
      return 'customerManagement.result.incompatible'
    case MedicalStatus.CREATING:
      return 'customerManagement.result.creating'
    case MedicalStatus.WAITING:
      return 'customerManagement.result.waiting'
    case MedicalStatus.ENDED:
      return 'customerManagement.result.ended'
    default:
      return 'customerManagement.result.sent'
  }
}

// convert date and time to timestamp
export const convertDateTimeToISOString = (date: string, time: string) => {
  const combinedDateTime = `${date}T${time}`
  return dayjs(combinedDateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
}
