import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button } from 'antd'

import Content from './components/Content'
import Widget from './components/Widget'
import ReservationRegistrationCompletedNotice from './components/modals/ReservationRegistrationCompletedNotice'
import useProductSetting from 'hooks/useProductSetting'

export enum TAB {
  RESERVATION_WEEK = '1',
  RESERVATION_DATE = '2'
}

type Form = {
  week: Record<string, number>
  date: Record<string, number>
  rrule: {
    week: Record<string, string>
    date: Record<string, string>
  }
}

export const cleanData = (obj: Record<string, string> | null | undefined) => {
  if (!obj) return {}

  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value != null)
  )
}

export default function ProductSetting() {
  const { t } = useTranslation()
  const { search } = useLocation()
  const methods = useForm<Form>()

  const { onUpdateAvailableSlot } = useProductSetting()

  const queryParams = new URLSearchParams(search)
  const { tab: tabParam = TAB.RESERVATION_WEEK } =
    Object.fromEntries(queryParams)

  const [submissionCount, setSubmissionCount] = useState(0)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  useEffect(() => {
    methods.reset({})
    setIsChange(false)
  }, [search])

  const onSubmit = async (data: Form) => {
    const finalData = data.rrule && {
      week: Object.values(cleanData(data.rrule.week)),
      date: Object.values(cleanData(data.rrule.date))
    }

    const updateSlot = async (slotData: string[]) => {
      try {
        setIsChange(false)
        await onUpdateAvailableSlot(slotData)
        setSubmissionCount((prevCount) => prevCount + 1)
        methods.reset({})
        setIsOpenModal(true)
      } catch (e) {
        console.error(e)
      }
    }

    if (finalData) {
      if (tabParam === TAB.RESERVATION_WEEK) {
        await updateSlot(finalData.week)
      }

      if (tabParam === TAB.RESERVATION_DATE) {
        await updateSlot(finalData.date)
      }
    }
  }

  const isDisabled = !isChange

  return (
    <div className="min-w-[1500px] w-full">
      <Widget />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Content
            submissionCount={submissionCount}
            setIsChange={setIsChange}
          />

          <Button
            disabled={isDisabled}
            style={{
              background: isDisabled ? '#BFC6CB' : '',
              color: isDisabled ? 'white' : ''
            }}
            type="primary"
            htmlType="submit"
            autoInsertSpace={false}
            className="mt-[14px] h-[30px] min-w-[120px] rounded-[3px] border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
          >
            {t('productSetting.button.save')}
          </Button>
        </form>
      </FormProvider>

      <ReservationRegistrationCompletedNotice
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
      />
    </div>
  )
}
