import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Typography } from 'antd'

import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals'
import { OPERATION_TYPE, RESOURCES_TENANT } from 'configs/constant'
import { StringKeyObject } from 'types/common'

const { Paragraph } = Typography

type Props = {
  dataDelete: StringKeyObject | null
  onCancel: () => void
  setIsDeleteSuccess: (data: boolean) => void
  handleDelete: Function
}

const DeleteAssignmentModal = ({
  dataDelete = null,
  onCancel,
  setIsDeleteSuccess,
  handleDelete
}: Props) => {
  const { t } = useTranslation()

  const handleOk = async () => {
    onCancel()

    await handleDelete(
      OPERATION_TYPE.DELETE,
      RESOURCES_TENANT.ASSIGNMENTS,
      dataDelete?.value,
      dataDelete?.key
    )

    setIsDeleteSuccess(true)
  }

  return (
    <CustomModal open={dataDelete !== null} onCancel={onCancel} onOk={handleOk}>
      <div className="text-center pt-3 pb-7 px-6">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('content.deleteConfirmation')}
        </Typography>

        <Paragraph className="mt-5 mb-0 w-max m-auto">
          {t('content.areYouSureToDeleteAssignment')}
          <Paragraph className="block mb-0 text-left text-error">
            {t('content.deletedAssignmentCannotBeRestored')}
          </Paragraph>
        </Paragraph>

        <Flex className="mt-[130px] gap-4 items-center justify-center">
          <Col flex="148px">
            <InputLabel label={t('table.assignmentName')} className="!py-0" />
          </Col>
          <Col flex="13rem" className="text-left">
            {dataDelete?.value}
          </Col>
        </Flex>

        <Flex className="justify-center gap-3 mt-[133px]">
          <Button
            autoInsertSpace={false}
            type="primary"
            className="w-[180px] font-bold bg-error hover:!bg-error"
            htmlType="submit"
            onClick={handleOk}
          >
            {t('button.delete')}
          </Button>
          <Button
            autoInsertSpace={false}
            className="w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </div>
    </CustomModal>
  )
}

export default DeleteAssignmentModal
