import { DocumentNode, gql } from '@apollo/client'

export const DELETE_ROCKET_USER: DocumentNode = gql`
  mutation DeleteUserMutation($refId: String = "") {
    deleteUser(user: { refId: $refId }) {
      status
      timestamp
      message
    }
  }
`
