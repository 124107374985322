import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'
import * as yup from 'yup'

import { useAuth } from '../../context/AuthProvider'
import DeleteAccountModal from './modal/DeleteAccountModal'
import DeleteCompletedModal from './modal/DeleteCompletedModal'
import LogoutModal from './modal/LogoutModal'
import { yupResolver } from '@hookform/resolvers/yup'
import { MyPageForm } from 'components/screens/MyPage'
import {
  deleteAccountKeycloak,
  getUserInfo,
  logoutKeycloak,
  resetPassword,
  userInfoUpdate
} from 'services/AuthService'
import { decodedToken, getAccessToken } from 'utilities/helpers'

type InputForm = {
  name: string
  emailAddress?: string | null
  newPassword?: string
  confirmPassword?: string
}

const createSchema = (t: any) => {
  return yup
    .object()
    .shape({
      name: yup.string().max(50).required(),
      emailAddress: yup
        .string()
        .max(50, t('errors.emailMax50'))
        .email(t('errors.invalidEmail'))
        .notRequired(),
      newPassword: yup.string(),
      confirmPassword: yup.string().oneOf([yup.ref('newPassword')])
    })
    .required()
}

export default function MyPage() {
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    useState<boolean>(false)

  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false)
  const [showDeleteCompletedModal, setShowDeleteCompletedModal] =
    useState<boolean>(false)
  const { t } = useTranslation()
  const schema = createSchema(t)
  const auth = useAuth()
  const [token] = useState<string | null>(getAccessToken() ?? null)
  const sub = decodedToken(token)

  const methods = useForm<InputForm>({
    defaultValues: {
      name: '',
      emailAddress: '',
      newPassword: '',
      confirmPassword: ''
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = methods

  const onSubmit = (data: InputForm) => {
    const changeUserInfo = async () => {
      try {
        const dataRequest: any = {
          firstName: data.name,
          lastName: ''
        }

        if (data.emailAddress !== null && data.emailAddress !== undefined) {
          dataRequest.email = data.emailAddress
        }
        await userInfoUpdate(sub, dataRequest)

        if (data.newPassword) {
          await resetPassword(sub, data.newPassword)
        }
      } catch (err) {
        console.error(err)
      }
    }
    changeUserInfo()
  }

  const handleDeleteAccount = async () => {
    const deleteAccount = async () => {
      try {
        await deleteAccountKeycloak(sub).then((response: any) => {
          auth?.logOut()
        })
      } catch (err) {
        console.error(err)
      }
    }
    await deleteAccount()

    setShowDeleteCompletedModal(false)
  }

  const logout = () => {
    const logoutAction = async () => {
      try {
        await logoutKeycloak(sub).then((response: any) => {
          auth?.logOut()
        })
      } catch (err) {
        console.error(err)
      }
    }
    logoutAction()
  }

  useEffect(() => {
    const userInfo = async () => {
      try {
        await getUserInfo(sub).then((response: any) => {
          methods.setValue(
            'name',
            (response?.firstName + ' ' + response?.lastName).trim()
          )
          methods.setValue('emailAddress', response?.email)
          return response
        })
      } catch (err) {
        console.error(err)
      }
    }
    userInfo()
  }, [])

  return (
    <Flex vertical className="gap-12">
      <Flex className="flex">
        <Typography className="text-[16px] text-[#545454] font-bold mr-[15px]">
          {t('myPage')}
        </Typography>
        <Typography className="text-[14px]">
          {t('content.myPageContent')}
        </Typography>
      </Flex>
      <Flex vertical justify="center">
        <div className="w-[600px] self-center">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                vertical
                className="bg-white rounded-sm shadow-sm p-2.5 gap-8"
              >
                <MyPageForm />
                <Button
                  autoInsertSpace={false}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="w-[180px] h-[30px] leading-none font-bold m-auto text-sm"
                >
                  {t('button.change')}
                </Button>
              </Flex>
            </form>
          </FormProvider>
          <Flex className="bg-white rounded-sm shadow-sm p-[10px] mt-[30px] justify-between items-center">
            <div className="pb-9">
              <Typography className="text-[14px] font-bold text-[#137695]">
                {t('logout')}
              </Typography>
              <Typography className="text-[14px]">
                {t('content.logoutContent')}
              </Typography>
            </div>

            <LogoutModal
              onOk={logout}
              isModalOpen={showLogoutModal}
              onCancel={() => setShowLogoutModal(false)}
            />
            <div>
              <Button
                htmlType="submit"
                size="large"
                className="w-[180px] h-[30px] self-end leading-none bg-white font-bold text-[#137695] border-[#137695] text-sm"
                onClick={() => setShowLogoutModal(true)}
              >
                {t('logout')}
              </Button>
            </div>
          </Flex>
          <Flex className=" bg-white rounded-sm shadow-sm p-[10px] mt-[30px] justify-between items-center">
            <div className="pb-9">
              <Typography className="text-[14px] font-bold text-[#DF2475]">
                {t('deleteAccount')}
              </Typography>
              <Typography className="text-[14px]">
                {t('content.deleteAccountContent')}
              </Typography>
            </div>
            <DeleteAccountModal
              isModalOpen={showDeleteAccountModal}
              onCancel={() => setShowDeleteAccountModal(false)}
              onSuccess={() => {
                setShowDeleteAccountModal(false)
                setShowDeleteCompletedModal(true)
              }}
            />
            <DeleteCompletedModal
              isModalOpen={showDeleteCompletedModal}
              onDelete={() => handleDeleteAccount()}
            />
            <div>
              <Button
                size="large"
                className="w-[180px] h-[30px] self-end leading-none bg-white font-bold text-error border-error btn-error-hover text-sm"
                onClick={() => setShowDeleteAccountModal(true)}
              >
                {t('deleteAccount')}
              </Button>
            </div>
          </Flex>
        </div>
      </Flex>
    </Flex>
  )
}
