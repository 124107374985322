import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { DateFormat } from '../../../configs/constant'
import { DataForm } from '../ReservationChange'
import RemainedSlotsByDate from './RemainedSlotsByDate'
import RemainedSlotsByWeek from './RemainedSlotsByWeek'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { SpecialConfig } from 'hooks/useTimeConfig'
import { DateRange, RemainedSlotByDate } from 'models/productManagement'
import {
  generateFullWeekRange,
  getMonthRange,
  getWeekRange
} from 'utilities/helpers'

const adjustDateRange = (dateRange: DateRange, type: 'previous' | 'next') => {
  let { startDate, endDate } = dateRange

  if (type === 'next') {
    startDate = dayjs(startDate).add(1, 'week').format('YYYY-MM-DD')
    endDate = dayjs(endDate).add(1, 'week').format('YYYY-MM-DD')
  } else if (type === 'previous') {
    startDate = dayjs(startDate).subtract(1, 'week').format('YYYY-MM-DD')
    endDate = dayjs(endDate).subtract(1, 'week').format('YYYY-MM-DD')
  }

  return { startDate, endDate }
}

const adjustMonthRange = (currentMonth: string, type: 'previous' | 'next') => {
  const startDate =
    type === 'next'
      ? dayjs(currentMonth).add(1, 'month').startOf('month').format(DateFormat)
      : dayjs(currentMonth)
          .subtract(1, 'month')
          .startOf('month')
          .format(DateFormat)

  const endDate =
    type === 'next'
      ? dayjs(currentMonth).add(1, 'month').endOf('month').format(DateFormat)
      : dayjs(currentMonth)
          .subtract(1, 'month')
          .endOf('month')
          .format(DateFormat)

  return { startDate, endDate }
}

type Props = {
  loading: boolean
  onUpdateLoading: () => void
  initialData?: RemainedSlotByDate[]
  config: SpecialConfig
  dateRange: DateRange
  setCurrentDate: (dateRange: DateRange) => void
  setSelectedSlot: (data: { date: string; time: string }) => void
}

const Content: FC<Props> = ({
  loading,
  onUpdateLoading,
  initialData,
  config,
  dateRange,
  setCurrentDate,
  setSelectedSlot
}) => {
  const { t } = useTranslation()
  const { search, state } = useLocation()

  const [selected, setSelected] = useState<boolean>(true)

  const { desiredDateTime }: DataForm = state || {}
  const selectedDesiredDate = dayjs(
    desiredDateTime?.[1]?.date || desiredDateTime?.[0]?.date
  )

  const getAvailableMonths = (dateRange: DateRange) => {
    const start = dayjs(dateRange.startDate)
    const end = dayjs(dateRange.endDate)

    const months: { [key: string]: string } = {}

    let current = start.clone().startOf('month')

    while (current.isBefore(end.clone().endOf('month'))) {
      months[current.format('YYYY-MM')] = current.format('YYYY-MM')
      current = current.add(1, 'month')
    }

    return months
  }
  const availableMonths = getAvailableMonths(dateRange)
  const firstAvailableMonth = Object.keys(availableMonths)[0]

  const [currentMonth, setCurrentMonth] = useState(firstAvailableMonth)

  useEffect(() => {
    setCurrentMonth(firstAvailableMonth)

    if (selected) {
      setCurrentDate(getWeekRange(selectedDesiredDate))
    } else if (!selected) {
      setCurrentDate(getMonthRange(firstAvailableMonth))
    }
  }, [search, selected])

  const currentMonthValue = dayjs(currentMonth)
  const fullDateArray = generateFullWeekRange(dateRange)

  const getButtonStyle = (isSelected: boolean, isLeft: boolean) => ({
    height: '30px',
    minWidth: '100px',
    color: isSelected ? 'white' : '#545454E0',
    background: isSelected ? '#137695' : 'transparent',
    border: isSelected ? 'none' : '1px solid #D9E0E5',
    fontWeight: isSelected ? 'bold' : 'normal',
    borderRadius: isLeft ? '5px 0 0 5px' : '0 5px 5px 0'
  })

  const getArrowStyle = (isDisabled: boolean) => ({
    fontSize: '25px',
    color: isDisabled ? '#BFC6CB' : '#137695',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })

  const getNextSevenDays = () => fullDateArray.slice(0, 7)

  const updateMonth = (month: string) => {
    setCurrentMonth(month)
  }

  const handlePrevClick = () => {
    if (selected) {
      setCurrentDate(adjustDateRange(dateRange, 'previous'))
      const newStartDate = fullDateArray[0]

      if (
        dayjs(newStartDate).format('YYYY-MM') !==
        currentMonthValue.format('YYYY-MM')
      ) {
        updateMonth(currentMonthValue.subtract(1, 'month').format('YYYY-MM'))
      }
    } else if (!selected) {
      setCurrentDate(adjustMonthRange(firstAvailableMonth, 'previous'))
      updateMonth(currentMonthValue.subtract(1, 'month').format('YYYY-MM'))
    }
  }

  const handleNextClick = () => {
    if (selected) {
      setCurrentDate(adjustDateRange(dateRange, 'next'))
      const newEndDate = fullDateArray[fullDateArray.length - 1]

      if (
        dayjs(newEndDate).format('YYYY-MM') !==
        currentMonthValue.format('YYYY-MM')
      ) {
        updateMonth(currentMonthValue.add(1, 'month').format('YYYY-MM'))
      }
    } else if (!selected) {
      setCurrentDate(adjustMonthRange(firstAvailableMonth, 'next'))
      updateMonth(currentMonthValue.add(1, 'month').format('YYYY-MM'))
    }
  }

  return (
    <div className="mt-3 rounded-[5px] bg-[#FFFFFF] px-5 py-[10px] drop-shadow-md">
      <Flex justify="space-between" className="my-4 ml-3">
        <Flex align="center">
          <CaretLeftOutlined
            style={getArrowStyle(false)}
            onClick={() => {
              onUpdateLoading()
              handlePrevClick()
            }}
          />

          <Typography className="mx-[11px] text-[16px] font-bold tracking-[0.8px]">
            {currentMonthValue.format(
              t('productManagement.content.formatDate')
            )}
          </Typography>

          <CaretRightOutlined
            style={getArrowStyle(false)}
            onClick={() => {
              onUpdateLoading()
              handleNextClick()
            }}
          />
        </Flex>

        <Flex align="center">
          <Typography className="mr-2 text-sm text-[#545454]">
            {t('productManagement.content.toggleDisplay')}
          </Typography>

          <Button
            style={getButtonStyle(selected, true)}
            onClick={() => {
              setSelected(true)
              onUpdateLoading()
            }}
          >
            {t('productManagement.content.week')}
          </Button>

          <Button
            style={getButtonStyle(!selected, false)}
            onClick={() => {
              setSelected(false)
              onUpdateLoading()
            }}
          >
            {t('productManagement.content.month')}
          </Button>
        </Flex>
      </Flex>

      {selected ? (
        <RemainedSlotsByDate
          loading={loading}
          initialData={initialData || []}
          dateRange={getNextSevenDays()}
          config={config}
          setSelectedSlot={setSelectedSlot}
        />
      ) : (
        <RemainedSlotsByWeek
          loading={loading}
          initialData={initialData || []}
          currentMonth={currentMonthValue}
          config={config}
          setSelectedSlot={setSelectedSlot}
        />
      )}
    </div>
  )
}

export default Content
