import { DocumentNode, gql } from '@apollo/client'

export const CREATE_RESERVATION: DocumentNode = gql`
  mutation (
    $checkupUserRefIds: [String!]!
    $medicalCheckupMasterRefId: String!
    $reservationDatetime: DatetimeInput!
  ) {
    registerReservation(
      checkupUserRefIds: $checkupUserRefIds
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
      reservationDatetime: $reservationDatetime
    ) {
      status
    }
  }
`

export const REGISTER_RESERVATION_PATIENT: DocumentNode = gql`
  mutation (
    $checkupUserRefIds: [String!]!
    $medicalCheckupMasterRefId: String!
    $reservationDatetime: DatetimeInput!
    $optionRefIds: [String!]
  ) {
    registerReservation(
      checkupUserRefIds: $checkupUserRefIds
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
      reservationDatetime: $reservationDatetime
      optionRefIds: $optionRefIds
    ) {
      message
      payload {
        checkupUserRefId
        createdDate
        medicalCheckupMasterRefId
        modifiedDate
        refId
        optionRefIds
        reservationDate
        reservationStatus
        reservationTime
        visibility {
          subTenantCodes
          tenantCodes
        }
        desiredDatetime {
          priority
          date
          timeOfDay
        }
      }
      status
      timestamp
    }
  }
`
