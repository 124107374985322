import { CSSProperties } from 'react'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'

import TextArea from 'antd/es/input/TextArea'

type Props = {
  className?: string
  name: string
  placeholder?: string
  defaultValue?: string
  style?: CSSProperties
  rows?: number
  autoSize?: any
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
}

export const TextAreaForm = ({
  className = '',
  name,
  placeholder,
  autoSize,
  defaultValue,
  style,
  rules,
  rows = 4
}: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextArea
          className={className}
          rows={rows}
          style={style}
          status={error ? 'error' : ''}
          placeholder={placeholder}
          autoSize={autoSize}
          defaultValue={defaultValue}
          {...field}
        />
      )}
    />
  )
}
