import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Empty } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { controlPanelWrapper } from './PatientMemo'
import Title from './Title'
import { tab } from '@testing-library/user-event/dist/tab'
import { openNotification } from 'components/widgets/Notification'
import { RESERVATION_STATUS } from 'configs/constant'
import useDrawerSchedule from 'hooks/useDrawerSchedule'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import usePatientDetail, {
  tableSearchSlotReservationAtom
} from 'hooks/usePatientDetail'
import { ReservationHistoryAtom } from 'pages/CustomerDetail/types/types'
import cn from 'utilities/cn'
import {
  filterCourseResponseInValid,
  renderActionStyle,
  uuid
} from 'utilities/helpers'

dayjs.locale('ja')

const tdRowStyle = 'pt-2 break-words text-sm'
const thRowStyle = 'text-sm py-2 text-left bg-white border-y-2'

function ReservationHistory({ sex, age }: { sex: string; age: string }) {
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const { reservationHistory, handleSearchCourse, getOptionsFromOptionIds } =
    usePatientDetail()
  const { onOpen: onOpenDrawerSchedule } = useDrawerSchedule()
  const [tableSearchSlotReservation, setTableSearchSlotReservation] =
    useRecoilState(tableSearchSlotReservationAtom)
  const { getOptionIdsByCourseId } = useMedicalCheckupMaster()

  const updateOptions = async (courseId: string, sex: string, age: string) => {
    if (!courseId) return

    const optionIds = getOptionIdsByCourseId(
      courseId,
      tableSearchSlotReservation.course.list
    )

    const options = await getOptionsFromOptionIds(optionIds)

    const satisfyOptions = filterCourseResponseInValid(options, {
      sex: sex,
      age: age
    })

    const optionsConvertedTypeSelect = satisfyOptions.map((option) => ({
      label: option.medicalCheckupMaster.displayName,
      value: option.medicalCheckupMaster.refId
    }))

    setTableSearchSlotReservation((prev) => ({
      ...prev,
      option: {
        ...prev.option,
        filterList: optionsConvertedTypeSelect,
        list: satisfyOptions
      }
    }))
  }

  const handleClickBtn = (data: ReservationHistoryAtom) => {
    const courseId = data.courseId
    const optionIds = data.optionIds ?? []

    if (courseId) {
      setTableSearchSlotReservation((prev) => ({
        ...prev,
        action: 'update',
        course: {
          ...prev.course,
          courseId,
          courseName: data.courseName
        },
        option: {
          ...prev.option,
          optionIds: optionIds,
          optionName: data.optionName
        },
        reservationId: data.id
      }))

      // get course list by select type
      setTimeout(() => {
        handleSearchCourse(data.courseName)
        updateOptions(courseId, sex, age)
      }, 0)

      setValue('courseSearchDrawer', courseId)
      setValue('optionSearchDrawer', optionIds)

      onOpenDrawerSchedule()
    } else {
      openNotification({
        type: 'error',
        title: t('dashboard.course_search_required'),
        message: ''
      })
    }
  }

  if (reservationHistory.length <= 0) {
    return (
      <div className={`${controlPanelWrapper}`}>
        <div className="mx-5">
          <Title text={t('dashboard.appointment_history')} isIcon />
        </div>

        <div className="h-[542px] flex items-center justify-center">
          <Empty />
        </div>
      </div>
    )
  }

  return (
    <div className={`${controlPanelWrapper}`}>
      <div className="px-5">
        <Title text={t('dashboard.appointment_history')} isIcon />
      </div>
      <div className="max-h-96 overflow-y-auto mt-1">
        <table className="w-full border-separate border-spacing-0">
          <thead className="sticky top-0 z-10">
            <tr>
              <th className={thRowStyle}></th>
              <th className={thRowStyle}>{t('dashboard.appointment_date')}</th>
              <th className={thRowStyle}>{t('dashboard.appointment_time')}</th>
              <th className={thRowStyle}>{t('dashboard.course_name')}</th>
              <th className={thRowStyle}>{t('dashboard.option_test')}</th>
            </tr>
          </thead>

          <tbody>
            {reservationHistory.map((reservation: ReservationHistoryAtom) => (
              <InfoRow
                key={uuid()}
                {...{
                  ...reservation,
                  onClick: handleClickBtn
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default memo(ReservationHistory)

const InfoRow: React.FC<
  ReservationHistoryAtom & { onClick: (data: ReservationHistoryAtom) => void }
> = (props) => {
  const { style, text } = renderActionStyle(props.reservationStatus)

  return (
    <tr>
      <td className={`${tdRowStyle} w-[10%] px-3`}>
        <Button
          disabled={
            props.reservationStatus === RESERVATION_STATUS.CANCELLED ||
            props.isDeleted
          }
          className={cn(
            `${style} text-center p-0 w-20 font-bold hover:!bg-primary hover:!text-white`,
            {
              '!bg-transparent border-none pointer-events-none':
                props.reservationStatus === RESERVATION_STATUS.CANCELLED ||
                props.isDeleted
            }
          )}
          onClick={() => props.onClick(props)}
        >
          {t(text)}
        </Button>
      </td>
      <td className={`${tdRowStyle} w-[10%]`}>{props.date}</td>
      <td className={`${tdRowStyle} w-[8%]`}>{props.time}</td>
      <td className={`${tdRowStyle} w-[36%]`}>{props.courseName}</td>
      <td className={`${tdRowStyle} w-[36%]`}>{props.optionName}</td>
    </tr>
  )
}
