import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import * as yup from 'yup'

import AdminRegisterForm from './components/AdminRegisterForm'
import AdminRegistrationConfirmation from './components/AdminRegistrationConfirmation'
import { yupResolver } from '@hookform/resolvers/yup'
import emojiRegex from 'emoji-regex'
import useAdminRegister from 'hooks/useAdminRegister'
import { uuid } from 'short-uuid'
import { IAdmin } from 'types/Admin'
import { checkIsTrue } from 'utilities/helpers'

export type DataForm = {
  blocks?: IAdminRegister[]
}

export interface IAdminRegister {
  key: string
  last_name: string
  last_name_kana?: string
  first_name: string
  first_name_kana?: string
  gender?: string
  email: string
  user_name: string
  password?: string
  confirm_password?: string
  roles?: string[]
  departments?: string[]
  assignments?: string[]
  positions?: string[]
  ownership_change?: string
}

const noEmojiTest = (value: string | undefined) =>
  !emojiRegex().test(value ?? '')

const createStringField = (isRequired: boolean = false, max: number = 255) => {
  const field = yup.string().max(max).test('', '', noEmojiTest)
  return isRequired ? field.required() : field
}

const schemaCreate = {
  blocks: yup.array().of(
    yup.object().shape({
      key: yup.string().required(),
      last_name: createStringField(true, 30),
      last_name_kana: createStringField(false, 30),
      first_name: createStringField(true, 30),
      first_name_kana: createStringField(false, 30),
      gender: yup.string(),
      email: yup.string().email().max(50).required(),
      user_name: createStringField(true, 50),
      password: createStringField(true, 50),
      confirm_password: yup
        .string()
        .max(50)
        .oneOf([yup.ref('password')])
        .required(),
      roles: yup.array().of(yup.string().required()),
      departments: yup.array().of(yup.string().required()),
      assignments: yup.array().of(yup.string().required()),
      positions: yup.array().of(yup.string().required())
    })
  )
}

const schemaUpdate = {
  blocks: yup.array().of(
    yup.object().shape({
      key: yup.string().required(),
      last_name: createStringField(true, 30),
      last_name_kana: createStringField(false, 30),
      first_name: createStringField(true, 30),
      first_name_kana: createStringField(false, 30),
      gender: yup.string(),
      email: yup.string().email().max(50).required(),
      roles: yup.array().of(yup.string().required()),
      departments: yup.array().of(yup.string().required()),
      assignments: yup.array().of(yup.string().required()),
      positions: yup.array().of(yup.string().required()),
      ownership_change: yup.string()
    })
  )
}

const defaultBlock = {
  key: uuid(),
  last_name: '',
  last_name_kana: '',
  first_name: '',
  first_name_kana: '',
  gender: 'MALE',
  email: '',
  user_name: '',
  password: '',
  confirm_password: '',
  roles: [],
  departments: [],
  assignments: [],
  positions: []
}

export default function AdminRegister() {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    roleOptions,
    departmentOptions,
    assignmentOptions,
    positionOptions,
    adminOptions,
    handleCreateAdmins,
    handleAdminOptions,
    getUserById,
    getRoleById,
    handleUpdateAdmins
  } = useAdminRegister()

  const [user, setUser] = useState<IAdmin | null>(null)
  const [confirmRegister, setConfirmRegister] = useState<
    IAdminRegister[] | null
  >(null)
  const [dataErrors, setDataErrors] = useState<IAdminRegister[] | null>(null)
  const [resolverSchema, setResolverSchema] = useState<yup.AnyObjectSchema>(
    yup.object().shape(schemaCreate)
  )

  const methods = useForm<DataForm>({
    defaultValues: {
      blocks: [defaultBlock]
    },
    resolver: yupResolver(resolverSchema)
  })

  const { handleSubmit, getValues, setValue, watch } = methods
  const blocks = watch('blocks') ?? []

  useEffect(() => {
    const id = searchParams.get('id')
    const getDataUser = async (idUser: string) => {
      const user = await getUserById(idUser)

      if (user) {
        let roles = (await getRoleById(user.id)) ?? []
        setValue(`blocks.${0}`, {
          key: user.id,
          last_name: user.lastName ?? '',
          last_name_kana: user.attributes?.lastNameKana?.[0] ?? '',
          first_name: user.firstName ?? '',
          first_name_kana: user.attributes?.firstNameKana?.[0] ?? '',
          gender: user.attributes?.sex?.[0] ?? '',
          email: user.email ?? '',
          user_name: user.username ?? '',
          password: '',
          confirm_password: '',
          roles: roles,
          departments: Array.isArray(user.attributes?.departments)
            ? user.attributes.departments
            : [],
          assignments: Array.isArray(user.attributes?.assignments)
            ? user.attributes.assignments
            : [],
          positions: Array.isArray(user.attributes?.positions)
            ? user.attributes.positions
            : []
        })

        user.roles = roles
        const isOwner = checkIsTrue(user.attributes?.isOwner?.[0])
        if (isOwner) {
          await handleAdminOptions()
        }
        user.isOwner = isOwner
        setUser(user)
        setResolverSchema(yup.object().shape(schemaUpdate))
      } else {
        navigate('/admin-list')
      }
    }

    if (id) {
      getDataUser(id)
    }
  }, [])
  const onSubmit = (data: DataForm) => {
    if (data?.blocks) {
      setConfirmRegister(data.blocks)
      setDataErrors(null)
    }
  }

  const handleAddBlock = () => {
    const data = getValues('blocks') ?? []

    setValue('blocks', [...data, { ...defaultBlock, key: uuid() }])
  }

  const handleRemoveBlock = (key: string) => {
    const data = (getValues('blocks') ?? []).filter(
      (item: IAdminRegister) => item.key !== key
    )

    setValue('blocks', data)
  }

  const genderOptions = [
    { key: '1', label: t('lable.male'), value: 'MALE' },
    { key: '2', label: t('lable.female'), value: 'FEMALE' }
  ]

  return (
    <Flex vertical className="min-w-[1680px]">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!confirmRegister && (
            <>
              <Typography className="text-base font-bold tracking-[1.6px] mb-1">
                {user ? t('editStaffInfo') : t('staffRegistration')}
              </Typography>
              {blocks?.map((block, index) => (
                <div
                  key={block.key}
                  className="bg-white px-2.5 py-4 mb-5 rounded-md shadow-md"
                >
                  <AdminRegisterForm
                    index={index}
                    assignmentOptions={assignmentOptions}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    positionOptions={positionOptions}
                    genderOptions={genderOptions}
                    adminOptions={adminOptions}
                    user={user}
                  />

                  {!(index === 0 && blocks.length > 1) && !user && (
                    <Flex className="mt-3 gap-2.5 items-center">
                      {index !== 0 && (
                        <Typography
                          className="bg-[#BFC6CB] w-max text-white pl-2 pr-3 flex items-center rounded-md cursor-pointer min-h-[32px]"
                          onClick={() => handleRemoveBlock(block.key)}
                        >
                          <span className="mr-2.5 w-3 h-[2px] bg-white inline-block"></span>
                          {t('button.delete')}
                        </Typography>
                      )}

                      <Typography
                        className="bg-primary w-max text-white pl-2 pr-3 flex items-center rounded-md cursor-pointer"
                        onClick={() => handleAddBlock()}
                      >
                        <span className="text-[32px] mr-2.5 leading-none">
                          +
                        </span>
                        {t('button.addition')}
                      </Typography>
                    </Flex>
                  )}
                </div>
              ))}
              <Button
                type="primary"
                htmlType="submit"
                autoInsertSpace={false}
                className="rounded-[3px] min-w-[180px] font-bold mt-1"
              >
                {t('button.save')}
              </Button>
            </>
          )}

          {confirmRegister && (
            <AdminRegistrationConfirmation
              data={confirmRegister}
              setConfirmRegister={setConfirmRegister}
              genderOptions={genderOptions}
              roleOptions={roleOptions}
              departmentOptions={departmentOptions}
              assignmentOptions={assignmentOptions}
              positionOptions={positionOptions}
              adminOptions={adminOptions}
              handleCreateAdmins={handleCreateAdmins}
              handleUpdateAdmins={handleUpdateAdmins}
              user={user}
              dataErrors={dataErrors}
              setDataErrors={setDataErrors}
            />
          )}
        </form>
      </FormProvider>
    </Flex>
  )
}
