import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { StringKeyObject } from 'types/common'

export default function JudgmentCategory({
  listCategory,
  dataShowMedicalCheckups,
  language
}: {
  listCategory: StringKeyObject[]
  dataShowMedicalCheckups: StringKeyObject[]
  language: string
}) {
  const { t } = useTranslation()

  return (
    <Flex className="border-b border-[#BFC6CB]">
      <Flex className="w-[12%] px-5 py-4 items-center border-b border-[#BFC6CB]">
        <span className="font-bold">{t('testResult.categoryEvaluation')}</span>
      </Flex>
      <div className="w-[88%] justify-between">
        {listCategory.map((item: StringKeyObject) => (
          <Flex key={item.refId}>
            <div className="w-[14.8%] border-b border-[#BFC6CB] border-left-between pt-1 pb-0.5 pr-1">
              <Typography className="font-bold">
                {item.lang?.[language] ?? VALUE_WITH_NO_DATA}
              </Typography>
              <Typography>{item.lang?.en ?? VALUE_WITH_NO_DATA}</Typography>
            </div>
            {dataShowMedicalCheckups.map((medicalCheckup: StringKeyObject) => (
              <div
                className="flex-1 p-2.5 border-b border-[#BFC6CB] border-left-between"
                key={medicalCheckup.refId}
              >
                <span className="font-bold">
                  {medicalCheckup.testResults?.[item.idItemMaster]?.evaluation}
                </span>
              </div>
            ))}
          </Flex>
        ))}
      </div>
    </Flex>
  )
}
