import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { COUNTRY } from '../../models/setting'
import CustomerDisplayForm from './components/CustomerDisplayForm'
import CustomerEditForm, {
  CustomerEditFormMethods
} from './components/CustomerEditForm'
import DuplicateIdError from './components/modals/DuplicateIdError'
import RegisteredNotice from './components/modals/RegisteredNotice'
import {
  STATUS_CODE_ALREADY_EXISTS,
  STATUS_CODE_SUCCESS
} from 'configs/constant'
import { useGetCheckupUsers } from 'hooks/useCheckupUser'
import useCustomer from 'hooks/useCustomer'

export type InputForm = {
  companyName: string
  id: string
  avatar?: string
  firstName: string
  lastName: string
  firstNameKana?: string
  lastNameKana?: string
  gender?: string
  birthday: string
  country?: string
  zipCode?: string
  province?: string
  district?: string
  detailedAddress?: string
  apartment?: string
  stateName?: string
  dialCodePhoneNumber: string
  phoneNumber?: string
  dialCodeEmergencyContactNumber: string
  emergencyContactNumber?: string
  email?: string
  typeOfInsurance?: string
  numberOfInsurance?: string
  insuranceStartDate?: string
  insuranceEndDate?: string
  insuranceSymbol?: string
  insuranceNumber?: string
  collaborativeApplication?: string
  userID?: string
  applicationStartDate?: string
  note?: string
  foreignInsuranceCompany?: string
  drugAllergy?: string
  address?: string
}

export default function CustomerRegister() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateCustomer } = useCustomer()

  const { getCheckupUsers } = useGetCheckupUsers()
  const [isEditForm, setEditForm] = useState<boolean>(true)
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const [dataDuplicateId, setDataDuplicateId] = useState<any>(null)
  const [formData, setFormData] = useState<any>()

  const childRef = useRef<CustomerEditFormMethods>(null)

  const handleChildFormSubmit = (data: any) => {
    setFormData(data)
    setEditForm(false)
  }

  const handleCreateCustomer = async () => {
    if (formData) {
      const firstName = formData.firstName ? formData.firstName.trim() : ''
      const lastName = formData.lastName ? formData.lastName.trim() : ''
      const firstNameKana = formData.firstNameKana
        ? formData.firstNameKana.trim()
        : ''
      const lastNameKana = formData.lastNameKana
        ? formData.lastNameKana.trim()
        : ''

      const fullName =
        `${firstName}${lastName} ${firstNameKana}${lastNameKana}`.trim()

      const newCustomer = {
        refId: formData.id.trim(),
        gender: formData.gender ? formData.gender : 'MALE',
        birthday: `${dayjs(formData.birthday, 'YYYY/MM/DD').format('YYYY-MM-DD')}`,
        additionalInfo: {
          avatar: formData.avatar ? formData.avatar.trim() : null,
          firstName: formData.firstName ? formData.firstName.trim() : null,
          firstNameKana: formData.firstNameKana
            ? formData.firstNameKana.trim()
            : null,
          lastName: formData.lastName ? formData.lastName.trim() : null,
          lastNameKana: formData.lastNameKana
            ? formData.lastNameKana.trim()
            : null,
          fullName: fullName || null,
          country: formData.country ?? null,
          zipCode: formData.zipCode.trim() ?? null,
          province: formData.province ? formData.province.trim() : null,
          district: formData.district ? formData.district.trim() : null,
          detailAddress: formData.detailedAddress
            ? formData.detailedAddress.trim()
            : null,
          address: formData.address.trim() ?? null,
          apartment: formData.apartment ? formData.apartment.trim() : null,
          stateName:
            formData.country === COUNTRY.VIETNAM
              ? formData.stateName.trim()
              : null,
          email: formData.email ? formData.email.trim() : null,
          dialCodePhone: formData.dialCodePhoneNumber
            ? formData.dialCodePhoneNumber.trim()
            : null,
          phone: formData.phoneNumber ? formData.phoneNumber.trim() : null,
          dialCodeEmergencyContact: formData.dialCodeEmergencyContactNumber
            ? formData.dialCodeEmergencyContactNumber.trim()
            : null,
          emergencyContact: formData.emergencyContactNumber
            ? formData.emergencyContactNumber.trim()
            : null,
          typeOfInsurance: formData.typeOfInsurance
            ? formData.typeOfInsurance.trim()
            : null,
          numberOfInsurance: formData.numberOfInsurance
            ? formData.numberOfInsurance.trim()
            : null,
          insuranceSymbol: formData.insuranceSymbol
            ? formData.insuranceSymbol.trim()
            : null,
          insuranceNumber: formData.insuranceNumber
            ? formData.insuranceNumber.trim()
            : null,
          insuranceStartDate: formData.insuranceStartDate
            ? formData.insuranceStartDate.trim()
            : null,
          insuranceEndDate: formData.insuranceEndDate
            ? formData.insuranceEndDate.trim()
            : null,
          collaborativeApplication: formData.collaborativeApplication
            ? formData.collaborativeApplication.trim()
            : null,
          applicationStartDate: formData.applicationStartDate
            ? formData.applicationStartDate.trim()
            : null,
          userID: formData.userID ? formData.userID.trim() : null,
          note: formData.note ? formData.note.trim() : null,
          foreignInsuranceCompany: formData.foreignInsuranceCompany
            ? formData.foreignInsuranceCompany.trim()
            : null,
          drugAllergy: formData.drugAllergy
            ? formData.drugAllergy.trim()
            : null,
          companyName: formData.companyName
            ? formData.companyName.trim()
            : null,
          isDeleted: 'false'
        }
      }

      const res = await onCreateCustomer(newCustomer)

      if (res?.addCheckupUser?.status === STATUS_CODE_SUCCESS) {
        setOpenModal(true)
      }

      if (res?.addCheckupUser?.status === STATUS_CODE_ALREADY_EXISTS) {
        const checkUser = await getCheckupUsers({
          filter: `(eq,STRING,refId,${formData.id})`,
          page: 0,
          size: 1,
          sortBy: '(desc,createdDate)'
        })

        if (checkUser?.[0]) {
          setDataDuplicateId(checkUser[0])
          return
        }
      }
    }
  }

  const handleSubmit = async () => {
    if (isEditForm) {
      childRef.current?.onSubmit()
    }

    if (!isEditForm) {
      await handleCreateCustomer()
    }
  }

  return (
    <div className="overflow-x-scroll text-[14px] text-[#545454]">
      <Flex align="center" className="mb-[6px]">
        <Typography className="text-[16px] font-bold tracking-[1.6px]">
          {t('customerRegister.customerRegister')}
        </Typography>

        {!isEditForm ? (
          <Typography className="ml-[30px]">
            {t('customerRegister.notice.isOK')}
          </Typography>
        ) : null}
      </Flex>

      {/*Form*/}
      <div>
        {isEditForm ? (
          <CustomerEditForm
            t={t}
            ref={childRef}
            onSubmit={handleChildFormSubmit}
            initialData={formData}
          />
        ) : (
          <CustomerDisplayForm t={t} initialData={formData} />
        )}
      </div>

      {/*Button*/}
      <div className="mt-[32px]">
        <Button
          autoInsertSpace={false}
          type="default"
          onClick={handleSubmit}
          className="h-[30px] w-[180px] border-none bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
        >
          {isEditForm
            ? t('customerRegister.button.confirm')
            : t('customerRegister.button.register')}
        </Button>

        {!isEditForm ? (
          <Button
            autoInsertSpace={false}
            type="default"
            onClick={() => {
              setEditForm(true)
            }}
            className="ml-3 h-[30px] w-[180px] border-[#137695] bg-white p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
          >
            {t('customerRegister.button.edit')}
          </Button>
        ) : null}
      </div>

      {/*Modal*/}
      <div>
        <RegisteredNotice
          t={t}
          isOpen={isOpenModal}
          onCancel={() => {
            setOpenModal(false)
            navigate('/customer-management')
          }}
        />
        <DuplicateIdError
          t={t}
          isOpen={dataDuplicateId}
          onCancel={() => setDataDuplicateId(null)}
          data={dataDuplicateId}
          isCreate={true}
        />
      </div>
    </div>
  )
}
