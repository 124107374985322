import { useTranslation } from 'react-i18next'

import { Image } from 'antd'

import { ScenarioOptionProps } from 'pages/Scenario/types/types'

const ScenarioOption: React.FC<
  ScenarioOptionProps & { onClick: (id: string) => void }
> = ({ id, title, description, imageSrc, onClick, disabled }) => {
  const { t } = useTranslation()

  return (
    <div
      role="presentation"
      className={`flex items-center gap-2 p-2 border ${
        disabled ? 'border-customGray' : 'bg-hover border-primary'
      } cursor-pointer hover:border-primary`}
      onClick={() => onClick(id)}
    >
      <div
        className={`w-20 flex items-center justify-center ${
          disabled && 'filter grayscale'
        }`}
      >
        <Image width={'100%'} height={'100%'} preview={false} src={imageSrc} />
      </div>
      <div className="flex flex-col text-[16px] text-default">
        <span className={`font-bold ${!disabled ? 'text-primary' : ''}`}>
          {t(title)}
        </span>
        <span className="text-sm text-wrap mt-1">{t(description)}</span>
      </div>
    </div>
  )
}

export default ScenarioOption
