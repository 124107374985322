import { ReactNode } from 'react'

interface FilterProps {
  readonly title: string
  readonly search: ReactNode
  readonly chooseGender: ReactNode
  readonly chooseAge: ReactNode
  readonly filterButton: ReactNode
}

function Filter({
  title,
  search,
  chooseGender,
  chooseAge,
  filterButton
}: FilterProps) {
  return (
    <div className="flex items-center">
      <h2 className="text-[#545454] text-[16px] font-bold">{title}</h2>
      <div className="ml-10">{search}</div>
      <div className="ml-[8px]">{chooseGender}</div>
      <div className="ml-[8px]">{chooseAge}</div>
      <div className="ml-[8px]">{filterButton}</div>
    </div>
  )
}

export default Filter
