import React, { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import CourseSelect from '../../ProductManagement/components/CourseSelect'
import { TAB } from '../ProductSetting'
import CustomDateRangePicker from 'components/elements/CustomDateRangePicker'

const Widget = memo(() => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const {
    tab: tabParam = TAB.RESERVATION_WEEK,
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam
  } = Object.fromEntries(queryParams)

  const selectedDateRangeRef = useRef<[string, string]>()

  const updateDateRange = (dates: [string, string] | undefined) => {
    if (dates) {
      const formattedDates: [string, string] = [
        dayjs(dates[0]).utc().format('YYYY-MM-DD'),
        dayjs(dates[1]).utc().format('YYYY-MM-DD')
      ]

      const hasChanged =
        !selectedDateRangeRef.current ||
        !formattedDates.every(
          (value, index) => value === selectedDateRangeRef.current?.[index]
        )

      if (hasChanged) {
        selectedDateRangeRef.current = formattedDates

        queryParams.set('startDate', formattedDates[0])
        queryParams.set('endDate', formattedDates[1])
      }
    } else {
      selectedDateRangeRef.current = undefined

      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  const handleNavigateToProductDuplication = () => {
    navigate('/product-duplication', {
      state: {
        destinationSource: courseParam,
        tabParam,
        startDate: startDateParam,
        endDate: endDateParam
      }
    })
  }

  return (
    <Flex align={'center'}>
      <Typography.Text className="mr-5 text-base font-bold tracking-[1.6px]">
        {t('productSetting.productSetting')}
      </Typography.Text>

      <CourseSelect
        placeholder={t('productManagement.widget.placeholder.search')}
      />

      {tabParam === TAB.RESERVATION_DATE && (
        <CustomDateRangePicker
          placeholder={t('productSetting.widget.placeholder.selectDate')}
          placeholder2={t('productSetting.widget.placeholder.choice')}
          initialStartDate={startDateParam ?? undefined}
          initialEndDate={endDateParam ?? undefined}
          isDisabledDate={false}
          setDates={updateDateRange}
        />
      )}

      <div className="ml-auto">
        <Button
          disabled={!courseParam}
          style={{
            background: !courseParam ? '#BFC6CB' : '',
            color: !courseParam ? 'white' : ''
          }}
          type="primary"
          htmlType="submit"
          autoInsertSpace={false}
          onClick={handleNavigateToProductDuplication}
          className="ml-[10px] h-[30px] min-w-[102px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-center text-sm font-bold text-white shadow-none"
        >
          {t('productSetting.button.exportCurrentSetting')}
        </Button>
      </div>
    </Flex>
  )
})

export default Widget
