import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { StringKeyObject } from 'types/common'

export default function DataTestTable({
  dataShowCategory,
  dataShowMedicalCheckups,
  language
}: {
  dataShowCategory: StringKeyObject[]
  dataShowMedicalCheckups: StringKeyObject[]
  language: any
}) {
  const { t } = useTranslation()

  return (
    <div className="w-full mt-2.5">
      <Flex className="bg-gradient-to-r from-[#327790] to-[#97C9CC]">
        <Flex className="w-[14%] py-1 px-1.5 pl-6 items-center border-left-between">
          <Typography className="text-white">{t('inspectionItem')}</Typography>
        </Flex>
        <Flex className="w-[86%] border-left-between">
          <Flex className="w-[12.8%] border-left-between py-1 px-1.5 pl-8 items-center">
            <Typography className="text-white">
              {t('testResult.unit')}
            </Typography>
          </Flex>
          {dataShowMedicalCheckups.map((medicalCheckup: StringKeyObject) => (
            <Flex
              className="flex-1 py-1 px-2.5 border-left-between items-center"
              key={medicalCheckup.refId}
            >
              <Typography className="text-white">
                {t('testResult.evaluation')}
              </Typography>
            </Flex>
          ))}
        </Flex>
      </Flex>
      {dataShowCategory.map((category: StringKeyObject) => (
        <React.Fragment key={category.refId}>
          <Flex className="bg-[#e8f2f5]">
            <div className="w-[14%] py-1 px-1.5 pl-6 items-center border-left-between">
              <Typography className="font-bold">
                {category.lang?.[language] ?? VALUE_WITH_NO_DATA}
              </Typography>
              <Typography>{category.lang?.en ?? VALUE_WITH_NO_DATA}</Typography>
            </div>
            <Flex className="w-[86%] border-left-between">
              <div className="w-[12.8%] border-left-between py-1 px-1.5 pl-8 items-center"></div>
              {dataShowMedicalCheckups.map(
                (medicalCheckup: StringKeyObject) => (
                  <Flex
                    className="flex-1 py-1 px-2.5 border-left-between items-center"
                    key={medicalCheckup.refId}
                  >
                    <Typography className="font-bold text-[29px]">
                      {
                        medicalCheckup.testResults?.[category.idItemMaster]
                          ?.evaluation
                      }
                    </Typography>
                  </Flex>
                )
              )}
            </Flex>
          </Flex>
          {category.itemMaster?.map((item: StringKeyObject) => (
            <Flex key={item.refId}>
              <div className="w-[14%] py-1 px-1.5 pl-6 items-center border-left-between">
                <Typography className="font-bold">
                  {item.lang?.[language] ?? VALUE_WITH_NO_DATA}
                </Typography>
                <Typography>{item.lang?.en ?? VALUE_WITH_NO_DATA}</Typography>
              </div>
              <Flex className="w-[86%] border-left-between">
                <div className="w-[12.8%] border-left-between py-1 px-1.5 pl-8 items-center">
                  {item.unitOfMeasure === 'uom' || !item.unitOfMeasure
                    ? ''
                    : `${item.unitOfMeasure}`}
                </div>
                {dataShowMedicalCheckups.map(
                  (medicalCheckup: StringKeyObject) => (
                    <Flex
                      className="flex-1 py-1 px-2.5 border-left-between items-center"
                      key={medicalCheckup.refId}
                    >
                      <Typography>
                        {medicalCheckup.testResults?.[item.refId]?.value}
                      </Typography>
                    </Flex>
                  )
                )}
              </Flex>
            </Flex>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}
