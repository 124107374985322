import { useState } from 'react'

import { useSetRecoilState } from 'recoil'

import { FIXED_TEXT_TYPE, STEP_SCENARIO } from 'configs/constant'
import { initialButtonList } from 'pages/Scenario/ScenarioCreate'
import { scenarioCreateAtom } from 'pages/Scenario/ScenarioCreate/atoms/create/scenarioCreateAtom'
import { stepEnd } from 'pages/Scenario/ScenarioCreate/data/data'
import { ButtonList, Flow, UseCreateScenario } from 'pages/Scenario/types/types'
import { scrollToEnd } from 'utilities/helpers'

const useCreateScenario = (): UseCreateScenario => {
  const setScenarioCreate = useSetRecoilState(scenarioCreateAtom)

  const [buttonListOfFixedText, setButtonListOfFixedText] =
    useState<ButtonList[]>(initialButtonList)
  const [isActivatedStartActivatingBlock, setIsActivatedStartActivatingBlock] =
    useState(false)
  const [isActivatedThankYouBlock, setIsActivatedThankYouBlock] =
    useState(false)

  const onClickButtonGender = (value: string, questions: Flow[]) => {
    setScenarioCreate((prev) => {
      const newFlows = [...prev.flows]
      const hasFixedQuestions = newFlows.some((flow) => flow.type === 'fixed')

      // Update gender selection in first step
      const updatedFlows = updateGenderSelection(newFlows, value)

      // Only add new questions if there are no fixed questions yet
      if (!hasFixedQuestions) {
        updatedFlows.push(...questions)
      }

      return {
        ...prev,
        flows: updatedFlows
      }
    })

    // Scroll to bottom after state updates
    setTimeout(scrollToEnd, 100)
  }

  const updateGenderSelection = (flows: Flow[], value: string) => {
    const newFlows = [...flows]

    return newFlows.map((flow) => {
      if (flow.order === STEP_SCENARIO.FIRST_STEP) {
        return {
          ...flow,
          options: flow.options?.map((option) => {
            return {
              ...option,
              isActivated: option.value === value ? !option.isActivated : false
            }
          })
        }
      }
      return flow
    })
  }

  const onCreateBlock = (orders: STEP_SCENARIO[]) => {
    // Update isActivated property in flows
    setScenarioCreate((prev) => {
      let flowsTemp = [...prev.flows]

      orders.forEach((order) => {
        // get index by order in flows
        const orderIndex = findIndexFlowItemByOrder(order, flowsTemp)

        if (orderIndex === -1) return

        // Add isActivated property to options
        flowsTemp[orderIndex] = addPropertyForFlowItem(
          flowsTemp[orderIndex],
          'isActivated',
          true
        )
      })

      return {
        ...prev,
        flows: [...flowsTemp]
      }
    })

    setTimeout(scrollToEnd, 100)
  }

  // find index of flow item by order
  const findIndexFlowItemByOrder = (order: number, flows: Flow[]) => {
    return flows.findIndex((flow) => flow.order === order)
  }

  // find order in flows
  const findOrderInFlows = (order: number, flows: Flow[]) => {
    return flows.find((flow) => flow.order === order)
  }

  // add property for flow item
  const addPropertyForFlowItem = (flow: Flow, key: string, value: any) => {
    return {
      ...flow,
      [key]: value
    }
  }

  const onClickActivateThankYouBlock = () => {
    setScenarioCreate((prev) => ({
      ...prev,
      flows: [
        ...prev.flows,
        {
          drawer: {
            open: false,
            openOtherContent: false,
            type: FIXED_TEXT_TYPE.LAST_STEP,
            title: 'お礼文',
            description: 'ご回答ありがとうございました。',
            templateName: ''
          },
          order: prev.flows.length,
          content: 'ご回答ありがとうございました。',
          selectionType: 'BUTTON',
          step: 'お礼文',
          setting: {}
        }
      ]
    }))

    setTimeout(scrollToEnd, 100)
  }

  return {
    buttonListOfFixedText,
    setButtonListOfFixedText,
    onClickButtonGender,
    isActivatedStartActivatingBlock,
    setIsActivatedStartActivatingBlock,
    isActivatedThankYouBlock,
    setIsActivatedThankYouBlock,
    onCreateBlock,
    onClickActivateThankYouBlock,
    findIndexFlowItemByOrder,
    addPropertyForFlowItem
  }
}

export default useCreateScenario
