import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'
import { Admin } from 'models/admin'

type Props = {
  admin: Admin | null
  onCancel: () => void
}

const DeleteAdminErrorModal = ({ admin = null, onCancel }: Props) => {
  const { t } = useTranslation('')

  return (
    <CustomModal open={!!admin} onCancel={onCancel}>
      <Flex vertical className="text-center pt-3 pb-5 tracking-[0.7px]">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('staffDeleteError')}
        </Typography>
        <ExclamationCircleFilled className="mt-[102px] m-auto w-[40px] text-error" />
        <Typography className="text-error mt-4">
          {t('content.staffInfoCannotBeDeleted')}
        </Typography>

        <Typography className="mt-9">
          {t('content.staffHasOwnerPermission')}
        </Typography>
        <Typography className="mt-0.5">
          {t('content.updateOwnerPermissionFirst')}
        </Typography>
        <Button
          autoInsertSpace={false}
          className="w-[180px] text-primary bg-white font-bold mt-[107px] m-auto border-primary"
          onClick={onCancel}
        >
          {t('button.return')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default DeleteAdminErrorModal
