import { Button } from 'antd'
import { useRecoilValue } from 'recoil'

import { scenarioCreateAtom } from '../../atoms/create/scenarioCreateAtom'
import PlusActive from './PlusActive'
import { ReactComponent as ArrowFlowIcon } from 'assets/svgs/arrow-flow.svg'
import { STEP_SCENARIO } from 'configs/constant'
import { Option } from 'pages/Scenario/types/types'
import cn from 'utilities/cn'

type FlowButtonProps = {
  children: React.ReactNode
  onClick?: (order?: number) => void
  isActivated?: boolean
  type?: string
  nextStep?: React.ReactNode
  order?: number
  genderBtns?: Option[]
}

const dots = Array.from({ length: 3 }, (_, index) => index)

const FlowButton = (props: FlowButtonProps) => {
  const { children, onClick, isActivated, nextStep } = props

  const scenarioCreate = useRecoilValue(scenarioCreateAtom)

  const hasStepLastQuestion = scenarioCreate.flows?.some(
    (flow) => flow.order === STEP_SCENARIO.LAST_STEP && flow.isActivated
  )

  return (
    <div className="relative min-h-[40px]">
      <Button
        type="primary"
        className="rounded-md w-full min-h-10 flex justify-between items-center"
        onClick={() => onClick?.(props.order)}
      >
        <span>{children}</span>
        {!isActivated && (
          <div className="absolute z-20 w-[20px] h-[20px] right-[-25px] top-[50%] translate-y-[-50%]">
            <PlusActive />
          </div>
        )}

        <div className="flex flex-col">
          {dots.map((_, index) => (
            <div
              key={`dot-${index}`}
              className={cn('z-10 w-[3px] h-[3px] bg-white rounded-full mb-1', {
                'mb-0': index === dots.length - 1
              })}
            />
          ))}
        </div>
      </Button>
      {isActivated && (
        <div>
          {(props.genderBtns?.[0].isActivated || hasStepLastQuestion) && (
            <ArrowFlowIcon className="absolute right-[-113px] top-[-120px] z-10" />
          )}
          <div className="absolute left-[370px] top-[-200px] z-10">
            {nextStep}
          </div>
        </div>
      )}
    </div>
  )
}

export default FlowButton
