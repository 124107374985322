import { StringKeyObject } from 'types/common'

export type Admin = {
  key: string
  fullName?: string
  email?: string
  sex?: string
  roles?: StringKeyObject[]
  affiliation?: string
  currentOwner?: boolean
  departments?: string
  assignments?: string
}

export type Permission = {
  key: string
  name: string
  subName: string
  groupName: string
  permissionKey: string
}

export type Role = {
  id: string
  name: string
  description?: string
  permissions: string[]
  ja?: string
  en?: string
  vi?: string
  order?: number
}

export type PermissionTableColumns = {
  title?: string | React.ReactNode
  dataIndex: string
  key: string
  width: string
}

export type PermissionTableDataSource = {
  key: string | React.ReactNode
  groupName: string
  subName?: string
  name: string
}

export const permissionList = [
  {
    key: '1',
    name: 'permission.reservationForIndividual',
    subName: '',
    groupName: 'permission.listReservation',
    permissionKey: 'reservation_management'
  },
  // {
  //   key: '2',
  //   name: 'permission.reservationForGroup',
  //   subName: '',
  //   groupName: 'permission.listReservation',
  //   permissionKey: 'reservation_group'
  // },
  {
    key: '3',
    name: 'permission.listSurvey',
    subName: '',
    groupName: 'permission.survey',
    permissionKey: 'medical_survey'
  },
  {
    key: '4',
    name: 'permission.listMedicalQuestionaireResponse',
    subName: '',
    groupName: 'permission.medicalQuestionaire',
    permissionKey: 'medical_survey_feedback'
  },
  {
    key: '5',
    name: 'permission.listProductForIndividual',
    subName: 'permission.listProduct',
    groupName: 'menu.setting',
    permissionKey: 'setting_product'
  },
  // {
  //   key: '6',
  //   name: 'permission.listProductForGroup',
  //   subName: 'permission.listProduct',
  //   groupName: 'menu.setting',
  //   permissionKey: 'setting_group'
  // },
  {
    key: '7',
    name: 'permission.listTestItem',
    subName: 'menu.inspectionSetting',
    groupName: 'menu.setting',
    permissionKey: 'setting_inspection'
  },
  {
    key: '8',
    name: 'permission.addAdmin',
    subName: 'permissionSettings',
    groupName: 'menu.setting',
    permissionKey: 'setting_role'
  },
  {
    key: '9',
    name: 'permission.listCustomer',
    subName: '',
    groupName: 'menu.customer',
    permissionKey: 'customer_management'
  },
  // {
  //   key: '10',
  //   name: 'permission.listBilling',
  //   subName: '',
  //   groupName: 'permission.billingManagement',
  //   permissionKey: 'billing_management'
  // },
  {
    key: '11',
    name: 'permission.claim',
    subName: '',
    groupName: 'permission.claim',
    permissionKey: 'health_support_claim'
  }
]

export const adminSample = {
  full_name: 'サブスク 花子',
  email: 'sample@xxxx.xxx.jp',
  role: '医院',
  affiliation:
    'グループＡクリニック, グループＢクリニック, グループＣクリニック'
}
