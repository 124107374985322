import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import { Button, Flex, Input, Select, Tabs, Typography } from 'antd'

import BasicCourseList from './components/BasicCourseList'
import OptionList from './components/OptionList'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { PaginationCustom } from 'components/elements/PaginationCustom/PaginationCustom'
import {
  PRODUCT_LIST_TOTAL,
  PRODUCT_MASTER_SORT_TYPE,
  optionsPerPage
} from 'configs/constant'
import { OPTION_FORM } from 'configs/pathRouter'
import useProductMaster from 'hooks/useProductMaster'

export type DataForm = {
  course: string
  page: number
  perPage: number
  sortBy?: string
  orderBy?: string
}

const TabBasicCourseList = '1'
const TabOptionList = '2'

export default function ProductMaster() {
  const { t } = useTranslation()
  let location = useLocation()

  const [, setListSelectedRows] = useState<any[]>([])
  const [searchParams, setSearchParams] = useSearchParams()

  const { loading, data, handleGetData, totalElement } = useProductMaster()
  const [isOption, setIsOption] = useState<boolean>(false)

  const methods = useForm<DataForm>({
    defaultValues: {
      course: '',
      page: 0,
      perPage: 100,
      sortBy: '',
      orderBy: ''
    }
  })

  const { control, getValues, setValue, resetField } = methods

  useEffect(() => {
    const searchParamsUrl = new URLSearchParams(location.search)
    let course: any = searchParamsUrl.get('course')

    if (course) {
      course = course.split(',')
    }
    let page = searchParamsUrl.get('page')
      ? Number(searchParamsUrl.get('page'))
      : 1
    let perPage = searchParamsUrl.get('perPage')
      ? Number(searchParamsUrl.get('perPage'))
      : 100
    let sortBy =
      searchParamsUrl.get('sortBy') ??
      PRODUCT_MASTER_SORT_TYPE.REGISTRATION_DATE
    let orderBy = searchParamsUrl.get('orderBy') ?? 'desc'

    if (course) {
      setValue('course', course)
    } else {
      resetField('course')
    }

    if (sortBy && orderBy) {
      setValue('sortBy', sortBy)
      setValue('orderBy', orderBy)
    } else {
      resetField('sortBy')
      resetField('orderBy')
    }

    setValue('page', page - 1)
    setValue('perPage', perPage)

    handleGetData({
      isOption,
      course: course,
      page: page - 1,
      perPage: perPage,
      sortBy: sortBy,
      orderBy: orderBy
    })
  }, [location.search, isOption, setValue])

  const onSearch = async () => {
    let course = getValues('course')
    let page: any = getValues('page')
    let perPage: any = getValues('perPage')
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')

    const newParams = new URLSearchParams(searchParams)

    if (course) {
      newParams.set('course', course)
    } else {
      newParams.delete('course')
    }

    if (sortBy && orderBy) {
      newParams.set('sortBy', sortBy)
      newParams.set('orderBy', orderBy)
    } else {
      setValue('sortBy', '')
      setValue('orderBy', '')
    }

    setValue('page', page)
    setValue('perPage', perPage)

    newParams.set('page', page + 1)
    newParams.set('perPage', perPage)

    setSearchParams(newParams)
  }

  const toggleSort = async (field: PRODUCT_MASTER_SORT_TYPE) => {
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')
    if (!orderBy) orderBy = 'asc'

    if (field === sortBy) {
      orderBy = orderBy === 'asc' ? 'desc' : 'asc'
    }

    setValue('sortBy', field)
    setValue('orderBy', orderBy)

    await onSearch()
  }

  const onChangePerPage = async () => {
    setValue('page', 0)

    await onSearch()
  }

  const onChangePage = async (value: number) => {
    setValue('page', value - 1)

    await onSearch()
  }

  const goToFirstPage = async () => {
    setValue('page', 0)

    await onSearch()
  }

  const goToLastPage = async () => {
    const length = isOption
      ? totalElement[PRODUCT_LIST_TOTAL.OPTION]
      : totalElement[PRODUCT_LIST_TOTAL.COURSE]
    const totalPages = Math.ceil(length / getValues('perPage'))
    setValue('page', totalPages - 1)

    await onSearch()
  }

  const onChangeKeyTabs = async (key: string) => {
    if (key === TabOptionList) {
      setIsOption(true)
    } else {
      setIsOption(false)
    }
    setValue('page', 0)
    await onSearch()
  }

  /**
   * Display the number of records in the table
   * @param e event
   * @returns a JSX element
   */
  function numberOfRecords() {
    return (
      <div className="absolute top-3 right-[10px] flex flex-row gap-[6px]">
        <Typography className="whitespace-nowrap overflow-hidden mt-[3px]">
          {t('lable.displayedResultNumber')}
        </Typography>
        <Controller
          name="perPage"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Select
              status={error ? 'error' : ''}
              suffixIcon={
                <CaretDownOutlined
                  color="#137695"
                  className="text-[#137695] text-[14px]"
                  style={{ pointerEvents: 'none' }}
                />
              }
              {...field}
              onChange={(value) => {
                field.onChange(value)
                onChangePerPage()
              }}
            >
              {optionsPerPage.map((option) => (
                <Select.Option value={option.value} key={option.value}>
                  <Typography className="mr-1">
                    {option.label + t('lable.ken')}
                  </Typography>
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </div>
    )
  }

  // Define the data tabs for BasicCourseList and OptionList
  const dataTabs = [
    {
      key: TabBasicCourseList,
      label: (
        <Typography className="underline">
          {t('content.basicCourse')}(
          {totalElement[PRODUCT_LIST_TOTAL.COURSE] ?? 0})
        </Typography>
      ),
      children: (
        <BasicCourseList
          loading={loading}
          dataSource={data}
          setListSelectedRows={setListSelectedRows}
          toggleSort={toggleSort}
        />
      )
    },
    {
      key: TabOptionList,
      label: (
        <Typography className="underline">
          {t('content.option')}({totalElement[PRODUCT_LIST_TOTAL.OPTION] ?? 0})
        </Typography>
      ),
      children: (
        <OptionList
          loading={loading}
          dataSource={data}
          setListSelectedRows={setListSelectedRows}
          toggleSort={toggleSort}
        />
      )
    }
  ]

  return (
    <FormProvider {...methods}>
      <div className="pb-10 min-w-[1600px]">
        <Flex className="justify-between">
          <Flex className="items-center flex-1">
            <Typography className="font-bold text-base text-nowrap mr-5 tracking-[1.6px]">
              {t('menu.productMaster')}
            </Typography>

            <Controller
              name="course"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className="w-full max-w-[424px] mr-3"
                  prefix={<SearchOutlined />}
                  placeholder={t(
                    isOption
                      ? 'placeholder.findAnOption'
                      : 'placeholder.findACourse'
                  )}
                />
              )}
            />
            <Button
              autoInsertSpace={false}
              type="primary"
              className="min-w-[120px] font-bold border-none shadow-none"
              onClick={() => {
                setValue('page', 0)
                onSearch()
              }}
            >
              {t('placeholder.search')}
            </Button>
          </Flex>
          <Flex className="gap-2.5">
            <Link to={'/course-register'}>
              <Button
                autoInsertSpace={false}
                type="primary"
                className="min-w-[120px] font-bold border-none shadow-none"
              >
                {t('courseEnrolment')}
              </Button>
            </Link>
            <Link to={`/${OPTION_FORM}`}>
              <Button
                autoInsertSpace={false}
                type="primary"
                className="min-w-[120px] font-bold border-none shadow-none bg-[#545454] hover:!bg-[#545454]"
              >
                {t('optionRegistration')}
              </Button>
            </Link>
          </Flex>
        </Flex>

        <div className="w-full mt-5">
          <div className="w-full bg-[#FFFFFF] shadow-md rounded-md p-1.5 px-2.5 pb-3 relative">
            <Tabs
              defaultActiveKey={TabBasicCourseList}
              items={dataTabs}
              onChange={onChangeKeyTabs}
              className="tab-product-master"
              // tabBarStyle={{
              //   width: 280,
              // }}
            />
            {numberOfRecords()}
          </div>
          {!loading && (
            <div className="w-full mt-6">
              <PaginationCustom
                onChangePage={onChangePage}
                currentPage={getValues('page')}
                total={
                  isOption
                    ? totalElement[PRODUCT_LIST_TOTAL.OPTION]
                    : totalElement[PRODUCT_LIST_TOTAL.COURSE]
                }
                onGoToFirstPage={goToFirstPage}
                onGoToLastPage={goToLastPage}
                pageSize={getValues('perPage')}
              />
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  )
}
