import { DataItemMaster } from './types/filterItemMaster'
import { Category } from './types/medicalCheckupMaster'
import { StringKeyObject } from 'types/common'

export enum ELanguage {
  JP = 'jp',
  EN = 'en',
  VI = 'vi',
  JA = 'ja'
}

export const showName = <T>(
  data: T & { additionalInfo: StringKeyObject; lang?: StringKeyObject },
  lang: string
) => {
  const { localizedName } = data.additionalInfo || {}
  if (!localizedName) {
    return data.lang?.[lang] ?? ''
  }
  if (lang === ELanguage.JA) {
    return (localizedName[ELanguage.JP] || localizedName[lang]) ?? ''
  }
  return localizedName[lang] ?? ''
}

export const getAllRefId = (data: DataItemMaster[]) => {
  const refIds = data.reduce<string[]>((acc, { refId, itemId, items }) => {
    if (itemId) {
      acc.push(itemId)
      if (items?.length) {
        acc.push(...items.map((i) => i.refId))
      }
    }
    return acc
  }, [])
  return refIds
}

export const transformDataToForm = <T extends StringKeyObject>(
  data: T
): Map<string, StringKeyObject> => {
  const mapItems = new Map<string, StringKeyObject>()

  Object.entries(data).forEach(([key, value]) => {
    // Extract `refId` and `language` from the key
    const [, refId, language] = key.split('_')
    if (!refId || !language) return // Skip invalid keys
    // Check if an entry for this refId already exists
    const existingItem = mapItems.get(refId)
    if (existingItem) {
      // Add the new language entry
      existingItem.localizedName[language] = value
    } else {
      // Create a new entry
      mapItems.set(refId, {
        localizedName: {
          [language]: value
        }
      })
    }
  })

  return mapItems
}

export const searchValues = (
  searchTerm: string,
  list: DataItemMaster[],
  language: string
): DataItemMaster[] => {
  const result: DataItemMaster[] = []
  if (!searchTerm) return list
  const searchMatch = (item: DataItemMaster) =>
    showName(item, language)
      ?.trim()
      ?.toLowerCase()
      .includes(searchTerm?.toLowerCase()) ||
    showName(item, ELanguage.EN)
      ?.trim()
      ?.toLowerCase()
      .includes(searchTerm?.toLowerCase())
  list.forEach((parent) => {
    const parentMatch = searchMatch(parent)
    const filteredItems = parent.items?.filter((item) => searchMatch(item))
    if (parentMatch || (filteredItems && filteredItems.length > 0)) {
      result.push({ ...parent, items: filteredItems })
    }
  })
  return result.length > 0 ? result : []
}

const foundLocalizedName = (
  listItemLanguage: DataItemMaster[],
  refId: string
) => {
  const item = listItemLanguage.find((i) => i.refId === refId)
  return item?.lang
}

// function to update localizedName and lang in list when updated localizedName in item master
export const categoryUpdate = (
  categories: DataItemMaster[],
  listItemLanguage: DataItemMaster[]
) => {
  return categories.map((category) => {
    return {
      ...category, // hold original data
      additionalInfo: {
        ...category.additionalInfo, // hold original data
        localizedName: foundLocalizedName(
          listItemLanguage,
          category.itemId ?? ''
        ) // update localizedName
      },
      lang: foundLocalizedName(listItemLanguage, category.itemId ?? '')?.lang, // update lang
      items: (category?.items ?? [])?.map((item) => ({
        ...item, // hold original data
        additionalInfo: {
          ...item.additionalInfo, // hold original data
          localizedName: foundLocalizedName(listItemLanguage, item.refId ?? '') // update localizedName
        },
        lang: foundLocalizedName(listItemLanguage, item.refId ?? '') // update lang
      }))
    }
  })
}

export const initialListCategory = (
  categories: Category[],
  listCategory: DataItemMaster[],
  originalListLanguage: DataItemMaster[]
): DataItemMaster[] => {
  return categories.map((it) => ({
    ...listCategory?.find((i) => i.refId === it.refId),
    isActiveKey: true,
    items: (it.items ?? [])
      .slice() // Ensure we're not mutating the original array
      .sort((a, b) => a.order - b.order) // Sort items
      .map((item) => ({
        ...originalListLanguage?.find((i) => i.refId === item.refId) // Find language for each item
      }))
  })) as DataItemMaster[]
}
