import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import { Button, Flex, Input, Typography } from 'antd'
import * as yup from 'yup'

import { openNotification } from '../../components/widgets/Notification'
import { useAuth } from '../../context/AuthProvider'
import { setAccessToken, setClientAccessToken } from '../../utilities/helpers'
import { LoginPayload, LoginResponse, Tenant } from './types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as EmailIcon } from 'assets/svgs/email.svg'
import { ReactComponent as PasswordIcon } from 'assets/svgs/password.svg'
import LoadingScreen from 'components/loading/LoadingScreen'
import { API_LOGIN_CLIENT_TOKEN } from 'configs/api'
import {
  KEYS_TEMP,
  STORAGE_KEYS,
  SUB_TENANT_PARAM_KEY,
  TENANT_PARAM_KEY
} from 'configs/constant'
import { jwtDecode } from 'jwt-decode'
import networkAdapter from 'utilities/networkAdapter'

type DataForm = {
  email: string
  password: string
}

const createSchema = (t: any) => {
  return yup
    .object()
    .shape({
      email: yup.string().required(t('errors.email')),
      password: yup.string().required(t('errors.password'))
    })
    .required()
}

export default function Login() {
  const { t } = useTranslation()
  const { state } = useLocation()

  const schema = createSchema(t)
  const auth = useAuth()
  const tokenRef = useRef<string | null>(null)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tenantParam = searchParams.get(TENANT_PARAM_KEY)
  const subtenantParam = searchParams.get(SUB_TENANT_PARAM_KEY)

  // override tenant and subTenant in local storage if have query params TENANT_PARAM_KEY, SUB_TENANT_PARAM_KEY
  useEffect(() => {
    if (
      searchParams.has(TENANT_PARAM_KEY) &&
      searchParams.has(SUB_TENANT_PARAM_KEY)
    ) {
      const tenantStorage = {
        [TENANT_PARAM_KEY]: tenantParam,
        [SUB_TENANT_PARAM_KEY]: subtenantParam
      }

      localStorage.setItem(STORAGE_KEYS.TENANT, JSON.stringify(tenantStorage))
    }
  }, [searchParams])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<DataForm>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: DataForm) => {
    auth?.setLoading(true)

    const isMultipleTenant =
      searchParams.has(TENANT_PARAM_KEY) &&
      searchParams.has(SUB_TENANT_PARAM_KEY)

    const body = {
      email: data.email,
      password: data.password,
      isLoginParamsTenant: isMultipleTenant
    }

    const response: LoginResponse = await auth
      ?.loginAction(body)
      .finally(() => {
        auth?.setLoading(false)
      })

    const payload = response?.payload
    const error = response?.error
    if (!payload || error) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.login')
      })
      return
    }

    const tenants = payload?.tenants || []

    // check account multi tenant then redirect to select tenant to get tenant code and subTenantCodes
    if (tenants.length > 1 && !isMultipleTenant) {
      tokenRef.current = payload.access_token
      const [tenant] = payload.tenants
      const url = `/select-tenant?${TENANT_PARAM_KEY}=${tenant.code}&${SUB_TENANT_PARAM_KEY}=${tenant.subTenantCodes[0]}`
      const tenantMerge = tenants.map((tenant: Tenant) => tenant.code).join(',')
      navigate(url, {
        state: {
          token: tokenRef.current,
          tenantCodes: tenantMerge
        }
      })

      return
    } else {
      // check account single tenant then redirect to home page
      const [tenantFirst] = payload?.tenants || [
        {
          code: tenantParam,
          subTenantCodes: [subtenantParam]
        }
      ]

      const tenantStorage = {
        [TENANT_PARAM_KEY]: tenantFirst.code,
        [SUB_TENANT_PARAM_KEY]: tenantFirst.subTenantCodes[0]
      }

      localStorage.setItem(STORAGE_KEYS.TENANT, JSON.stringify(tenantStorage))
    }

    // update state before login
    handleSetStateBeforeLogin(payload)

    // get and set token client
    await handleLoginClient()

    navigate('/')
  }

  const handleLoginClient = async () => {
    try {
      const response = await networkAdapter.post(API_LOGIN_CLIENT_TOKEN, {})

      setClientAccessToken(response.payload.access_token)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSetStateBeforeLogin = async (payload: LoginPayload) => {
    const token = payload.access_token

    auth?.setAuth(payload)
    setAccessToken(payload)
    auth?.setToken(token)
    auth?.setUser(jwtDecode(token))

    const adminRocketChatStorage = {
      adminToken: KEYS_TEMP.ADMIN_TOKEN,
      adminUserId: KEYS_TEMP.ADMIN_USER_ID
    }

    localStorage.setItem(
      STORAGE_KEYS.ADMIN_ROCKETCHAT,
      JSON.stringify(adminRocketChatStorage)
    )
  }

  return (
    <Flex vertical className="w-[400px] pt-[278px] text-center m-auto">
      {auth?.loading && <LoadingScreen loading={auth.loading} />}

      <Typography className="tracking-[2.4px] text-primary font-bold text-2xl leading-none text-center">
        {state?.tenantName ? state.tenantName : t('login')}
      </Typography>

      {state?.tenantName && (
        <Typography className="mt-11">{t('loginToTenant')}</Typography>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex vertical className="mt-[92px]">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                status={errors.email ? 'error' : ''}
                placeholder={t('lable.userName')}
                prefix={<EmailIcon className="w-[18px] mr-2.5" />}
                className="px-[13px] h-10"
              />
            )}
          />
          <Typography className="text-error text-xs h-3 mt-1.5 leading-none text-left">
            {errors.email ? errors.email?.message : ''}
          </Typography>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                status={errors.password ? 'error' : ''}
                placeholder={t('placeholder.password')}
                prefix={<PasswordIcon className="w-5 mr-2.5 " />}
                className="px-[13px] h-10 mt-8"
              />
            )}
          />

          <Typography className="text-error text-xs h-3 mt-1.5 leading-none text-left">
            {errors.password ? errors.password?.message : ''}
          </Typography>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="mt-8 text-sm font-bold"
          >
            {t('login')}
          </Button>
        </Flex>
      </form>
      <Link to="/forgot-password">
        <Typography className="tracking-[.7px] text-primary underline font-bold leading-none mt-[23px]">
          {t('forgotPassword')}
        </Typography>
      </Link>
    </Flex>
  )
}
