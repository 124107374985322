import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

function Header({
  onCreateScenario
}: {
  readonly onCreateScenario: () => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-center">
      <span className="text-[16px] text-[#545454] font-bold ">
        {t('scenarioList.title')}
      </span>
      <Button
        type="primary"
        className="rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-sm font-bold text-white shadow-none"
        onClick={onCreateScenario}
      >
        {t('scenarioList.createButton')}
      </Button>
    </div>
  )
}

export default Header
