import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

import { ReactComponent as WarningIcon } from 'assets/svgs/warning.svg'
import CustomModal from 'components/modals'

interface DeleteConfirmationModalProps {
  icon?: React.ReactNode | null
  isOpen?: boolean
  onClose?: () => void
  onConfirm?: () => void
  textOk?: string
  textCancel?: string
  title?: string
  isEnableOkButton?: boolean
  isEnableCancelButton?: boolean
  description?: string
  maskClosable?: boolean
}

function DeleteConfirmationModal({
  icon,
  isOpen,
  onClose,
  onConfirm,
  title,
  textOk,
  description,
  textCancel,
  isEnableOkButton = true,
  isEnableCancelButton = true,
  maskClosable = true
}: DeleteConfirmationModalProps) {
  const { t } = useTranslation()

  return (
    <CustomModal
      maskClosable={maskClosable}
      width={600}
      open={isOpen}
      onCancel={onClose || (() => {})}
    >
      <div className="min-h-[446px] flex flex-col justify-between">
        <p className="text-center text-xl mt-4 font-bold">
          {title || t('scenarioList.errorModal.title')}
        </p>

        <div className="flex flex-col items-center mt-14">
          {icon || <WarningIcon />}
          <div className="text-center mt-4 text-sm">
            {description ? (
              <p>{description}</p>
            ) : (
              <>
                <p>{t('scenarioList.errorModal.deleteTitle')}</p>
                <p>{t('scenarioList.errorModal.deleteMessage')}</p>
              </>
            )}
          </div>
        </div>

        <div className="flex justify-center mt-20 mb-4 gap-4">
          {isEnableOkButton && (
            <Button
              type="primary"
              className="font-bold rounded-sm shadow-none min-w-[180px] bg-error hover:!bg-error"
              onClick={onConfirm}
            >
              {textOk || t('scenarioList.errorModal.deleteButton')}
            </Button>
          )}
          {isEnableCancelButton && (
            <Button
              onClick={onClose}
              type="primary"
              className="font-bold bg-white text-primary shadow-none min-w-[180px] border-primary rounded-sm min-w-28 hover:!bg-white hover:!text-primary"
            >
              {textCancel || t('scenarioList.errorModal.cancelButton')}
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  )
}

export default DeleteConfirmationModal
