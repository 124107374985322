import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_RESERVATION: DocumentNode = gql`
  mutation (
    $refId: String!
    $medicalCheckupMasterRefId: String!
    $reservationDatetime: DatetimeInput!
    $optionRefIds: [String!]
  ) {
    updateReservation(
      refId: $refId
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
      reservationDatetime: $reservationDatetime
      optionRefIds: $optionRefIds
    ) {
      payload {
        refId
        checkupUserRefId
        medicalCheckupMasterRefId
        reservationDate
        reservationTime
        reservationStatus
      }
    }
  }
`

export const UPDATE_DESIRED_RESERVATION: DocumentNode = gql`
  mutation updateDesiredReservation(
    $refId: String!
    $desiredDatetime: [DesiredDatetimeInput!]!
  ) {
    updateDesiredReservation(refId: $refId, desiredDatetime: $desiredDatetime) {
      status
      message
    }
  }
`

export const UPDATE_RESERVATION_PATIENT: DocumentNode = gql`
  mutation (
    $medicalCheckupMasterRefId: String!
    $refId: String!
    $reservationDatetime: DatetimeInput!
    $optionRefIds: [String!]
  ) {
    updateReservation(
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
      refId: $refId
      reservationDatetime: $reservationDatetime
      optionRefIds: $optionRefIds
    ) {
      message
      status
      timestamp
      payload {
        checkupUserRefId
        createdDate
        desiredDatetime {
          date
          priority
          timeOfDay
        }
        medicalCheckupMasterRefId
        modifiedDate
        optionRefIds
        refId
        reservationDate
        reservationStatus
        reservationTime
        visibility {
          subTenantCodes
          tenantCodes
        }
      }
    }
  }
`
