import { TFunction } from 'i18next'

import {
  ChoiceTypeEnums,
  FormTypeEnums,
  QuestionChoiceOptionType,
  QuestionTypeEnums,
  SectionType,
  StatusEnums,
  SubsectionTypeEnums,
  SurveyStatusEnums,
  SurveyType
} from '../../models/survey'
import { uuid } from '../../utilities/helpers'

export const SAMPLE_DEPARTMENTS: string[] = [
  'すべて選択',
  '内科',
  '外科',
  '心療内科',
  '呼吸器科',
  'アレルギー科',
  '消化器科',
  'リウマチ科',
  '小児科',
  '形成外科',
  '産婦人科',
  '胃腸科',
  '循環器科',
  '精神神経科'
]

export const QUESTION_TYPES = [
  { key: ChoiceTypeEnums.radiobutton, title: 'RADIO_BUTTON' },
  { key: ChoiceTypeEnums.checkbox, title: 'CHECKBOX' },
  { key: QuestionTypeEnums.text, title: 'TEXT' },
  { key: ChoiceTypeEnums.dropdown, title: 'DROPDOWN' },
  { key: QuestionTypeEnums.date, title: 'DATE' },
  { key: QuestionTypeEnums.time, title: 'TIME' }
]

export const DEFAULT_QUESTION_SETTINGS = {
  TEXT: {
    status: StatusEnums.active,
    minLength: 1,
    maxLength: 400
  },
  DATE: {
    status: StatusEnums.active,
    fromDate: '1900-01-01',
    toDate: '2300-12-31',
    format: 'YYYY/MM/DD'
  },
  TIME: {
    status: StatusEnums.active,
    fromTime: '00:00:00',
    toTime: '23:59:59',
    format: 'HH:mm:ss'
  }
}

export const SAMPLE_SECTION: SectionType = {
  refId: uuid(),
  order: 1,
  status: StatusEnums.active,
  title: {
    en: '',
    ja: ''
  },
  subsectionList: []
}

export const createSampleQuestionChoice = (
  t: TFunction<['translation', ...string[]], undefined>
) => {
  return {
    refId: uuid(),
    order: 1,
    subsectionType: SubsectionTypeEnums.question,
    status: StatusEnums.active,
    questionType: QuestionTypeEnums.choice,
    isRequired: false,
    no: '1',
    displayNameMap: {
      en: '',
      ja: ''
    },
    setting: {
      isMultiple: false,
      choiceType: ChoiceTypeEnums.radiobutton,
      status: StatusEnums.active
    },
    options: [
      {
        order: 1,
        status: StatusEnums.active,
        no: '1',
        displayNameMap: {
          en: '',
          ja: ''
        }
      },
      {
        order: 2,
        status: StatusEnums.active,
        no: '2',
        displayNameMap: {
          en: '',
          ja: ''
        }
      }
    ]
  }
}

export const SAMPLE_OPTION_CHOICE: QuestionChoiceOptionType = {
  order: 1,
  status: StatusEnums.active,
  no: '1',
  displayNameMap: {
    en: '',
    ja: ''
  }
}

export const SAMPLE_SURVEY: SurveyType = {
  code: `survey-basic`,
  formType: FormTypeEnums.survey,
  surveyStatus: SurveyStatusEnums.draft,
  validFromDate: '',
  validToDate: '',
  displayNameMap: {
    en: '',
    ja: '問診票タイトル'
  },
  createdBy: '',
  sectionList: []
}
