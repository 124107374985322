import { useCallback, useEffect, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Input, Popover, Typography } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import transferIcon from '../../assets/pngs/transferIcon.png'
import CategoryAndItemList from './CategoryList'
import CollapsibleList from './CollapsibleList'
import ItemSettingCategoryList from './components/ItemSettingCategoryList'
import ModalRenderItemList from './components/ModalRenderItemList'
import SettingSuccessModal from './components/SettingSuccessModal'
import { ELanguage, getAllRefId, transformDataToForm } from './helper'
import useInspectionSetting from './hooks/useInspectionSetting'
import useMutationForInspectionSetting from './hooks/useMutationForInspectionSetting'
import { DataItemMaster, UpdateItemMaster } from './types/filterItemMaster'
import { QuestionCircleFilled } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as SearchOutlinedIcon } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as EyeNoHidden } from 'assets/svgs/eye-blue-37.svg'
import { ReactComponent as EyeHidden } from 'assets/svgs/eye-hidden14132.svg'
import { getLanguage } from 'utilities/helpers'

const createValidationSchema = (
  categories: {
    name: string
    refId: string
    language: string
  }[]
) => {
  return Yup.object(
    categories.reduce(
      (acc, { name, refId, language }) => {
        const nameValidate = `${name}_${refId}_${language}`
        acc[nameValidate] = Yup.string().min(0).required()

        return acc
      },
      {} as Record<string, Yup.StringSchema>
    )
  )
}

export default function InspectionItemSetting() {
  const { t } = useTranslation()
  const language = getLanguage()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isAddNewCategory, setIsAddNewCategory] = useState(false)
  const [isAddNewItemCategory, setIsAddNewItemCategory] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [showInspectionDescription, setShowInspectionDescription] =
    useState(true)

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({})
  )

  const methods = useForm({
    resolver: yupResolver(validationSchema)
  })

  const {
    setIsFetching,
    originalListLanguage,
    originalMedicalCheckupMaster,
    initialShowCategoryRefIds,
    initialHiddenCategoryRefIds,
    initialShowItemsMasterRefIds,
    initialHiddenItemsMasterRefIds,
    listCategory,
    listItemMaster,
    categoryShow,
    categoryHidden,
    setCategoryShow,
    setCategoryHidden,
    onDragEnd,
    updateItemMasterWithMedicalCheckupMaster,
    handleInitialDataShowCategory,
    searchTerm,
    setSearchTerm
  } = useInspectionSetting()

  useEffect(() => {
    if (categoryShow.length > 0 || categoryHidden.length > 0) {
      const CategoryFlat = [
        ...(categoryShow ?? []),
        ...(categoryHidden ?? [])
      ].flatMap((category) => {
        const { items = [], ...arg } = category || {}
        return [
          {
            name: 'category',
            refId: arg.refId,
            language,
            lang: originalListLanguage.find((item) => item.refId === arg.itemId)
              ?.lang
          },
          ...items.map((item) => ({
            name: 'item',
            refId: item.refId,
            language,
            lang: originalListLanguage.find((lang) => lang.refId === item.refId)
              ?.lang
          }))
        ]
      })

      const schemaLanguage = createValidationSchema([...CategoryFlat])

      const mergedSchema = Yup.object().shape({
        ...schemaLanguage.fields
      })
      setValidationSchema(mergedSchema)
      methods.reset({}, { keepValues: true, keepDefaultValues: true })
      CategoryFlat.forEach((item) => {
        const name = `${item.name}_${item.refId}_${item.language}` as never
        const nameEnglish = `${item.name}_${item.refId}_en2` as never
        const value = item.lang?.[language] as never
        const valueEnglish = item.lang?.[ELanguage.EN] as never
        methods.setValue(name, value)
        methods.setValue(nameEnglish, valueEnglish)
      })
    }
  }, [categoryShow, categoryHidden, methods.reset, language])

  const { handleUpdateItemMaster, handleUpdateMedicalCheckupMaster } =
    useMutationForInspectionSetting()

  const onClickSave = () => {
    showUpdate
      ? methods.handleSubmit(handleUpdateItemsMaster)()
      : handleSaveSuccess()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const initialStateModal = useCallback(() => {
    setIsAddNewCategory(false)
    setIsAddNewItemCategory(false)
    setShowUpdate(false)
    setShowInspectionDescription(true)
  }, [])

  const handleSaveSuccess = async () => {
    setIsModalVisible(true)
    try {
      const objId = uuidv4()
      // create object to update item master
      const dataUpdateItemMaster: UpdateItemMaster[] =
        updateItemMasterWithMedicalCheckupMaster(
          getAllRefId(categoryShow),
          originalListLanguage,
          objId
        )
      await handleUpdateItemMaster(dataUpdateItemMaster)

      // // update item category
      // const listCategory = originalListCategory.filter((item) =>
      //   categoryShow.some((i) => i.refId === item.refId)
      // )

      // await handleUpdateItemCategory(listCategory, {
      //   medicalCheckupMasterObjId: objId
      // })

      // update
      // save data for medical checkup master
      const additionalInfo = {
        categories: categoryShow.map((item, index) => {
          return {
            refId: item.refId,
            order: index,
            items: (item?.items || []).map((it, idx) => ({
              refId: it.refId,
              order: idx
            }))
          }
        }),
        excludedCategories: (categoryHidden ?? []).map((item, index) => {
          return {
            refId: item.refId,
            order: index,
            items: (item?.items || []).map((it, idx) => ({
              refId: it.refId,
              order: idx
            }))
          }
        }), // hold list category hidden when save
        objId: objId
      }
      if (originalMedicalCheckupMaster) {
        await handleUpdateMedicalCheckupMaster(
          originalMedicalCheckupMaster.medicalCheckupMaster,
          additionalInfo
        )
        setIsFetching(true)
        initialStateModal()
      }
    } catch (error) {
      //TODO: handle show error message
      console.error('error', error)
      initialStateModal()
    }
  }

  const handleUpdateItemsMaster = async (data: Record<string, string>) => {
    setIsModalVisible(true)
    try {
      const items: Record<string, string> = {}
      for (const key in data) {
        const [name, refId, language] = key.split('_')
        if (name === 'category') {
          const category = listCategory.find((i) => i.refId === refId)
          if (category?.itemId) {
            const newKey = `${name}_${category?.itemId}_${language}`
            items[newKey] = data[key]
          }
        } else {
          items[key] = data[key]
        }
      }
      const mapItems = transformDataToForm(items)
      const updateList: UpdateItemMaster[] = Array.from(mapItems.entries()).map(
        ([refId, value]) => {
          const item = originalListLanguage.find((i) => i.refId === refId)
          return {
            refId: item?.refId ?? '',
            displayName: item?.displayName ?? '',
            referenceValues:
              (item?.referenceValues ?? []).map((it) => {
                const { _class, ...rest } = it || {}
                return rest
              }) ?? [],
            additionalInfo: {
              ...item?.additionalInfo,
              medicalCheckupMasterObjId:
                originalMedicalCheckupMaster?.medicalCheckupMaster
                  ?.additionalInfo.objId ?? '',
              localizedName: {
                ...item?.lang,
                en: value.localizedName.en2,
                [language]: value.localizedName[language]
              }
            }
          }
        }
      )
      await handleUpdateItemMaster(updateList)
      setIsFetching(true)
      // set initial state
      initialStateModal()
    } catch (error) {
      // TODO: show error message
      initialStateModal()
    }
  }

  const handleSelectCategory = useCallback(
    (category: DataItemMaster[]) => {
      const selfCategoryHidden = categoryHidden ?? []
      const newCategories = category
        .filter((it) => !selfCategoryHidden.some((i) => i.refId === it.refId))
        .map((it) => ({
          ...it,
          isChecked: false,
          isActiveKey: true,
          items: it.items
            ?.map((i) => ({ ...i, isChecked: false }))
            .filter((i) => ![...initialHiddenItemsMasterRefIds, ...initialShowItemsMasterRefIds].includes(i.refId))
        }))

      setCategoryHidden(() => {
        const categories = [...selfCategoryHidden, ...newCategories].map(
          (it) => ({
            ...it,
            items: it.items || []
          })
        )
        return categories
      })
    },
    [categoryHidden, initialHiddenItemsMasterRefIds, setCategoryHidden]
  )

  const handleSelectItemCategory = useCallback(
    (itemCategory: DataItemMaster[]) => {
      // Filter out items that should not be included based on `initialHiddenItemsMasterRefIds`

      setCategoryHidden((prev) => {
        return prev?.map((item) => {
          const newItem = itemCategory
            .filter((it) => ![...initialHiddenItemsMasterRefIds, ...initialShowItemsMasterRefIds].includes(it.refId))
            .map((it) => ({ ...it, isChecked: item.isChecked }))
          const isLastItem = prev?.[prev.length - 1]?.refId === item.refId
          if (isLastItem) {
            // Combine new items with existing items, ensuring uniqueness by `refId`
            const combinedItems = Array.from(
              new Set([
                ...newItem.map((it) => it.refId),
                ...(item.items || []).map((it) => it.refId)
              ])
            ).map((refId) => ({
              ...originalListLanguage?.find((i) => i.refId === refId),
              isChecked:
                item.items?.some((i) => i.refId === refId && i.isChecked) ||
                newItem.some((i) => i.refId === refId && i.isChecked)
            })) as DataItemMaster[]
            return {
              ...item,
              isChecked: item.isChecked,
              items: [...combinedItems]
            }
          }

          // Filter existing items to include only those present in `itemCategory`
          return {
            ...item
          }
        })
      })
    },
    [initialHiddenItemsMasterRefIds, initialShowItemsMasterRefIds, originalListLanguage, setCategoryHidden]
  )

  // handle reset data
  const handleRestore = () => {
    handleInitialDataShowCategory()
    setCategoryHidden([])
  }

  return (
    <div className="ml-[-2px] pt-2">
      <Typography className="text-[16px] font-bold pl-2.5 leading-none">
        {t('preConfiguration')}
      </Typography>

      <Flex className="w-full mt-2 pl-[21px] justify-between">
        <Flex className="items-center">
          <Typography className="font-bold text-[#137695]">
            {t('inspectionItemRegistration')}
          </Typography>
          <Input
            placeholder={t('placeholder.search')}
            prefix={<SearchOutlinedIcon className="w-4 mr-1" />}
            className="h-[30px] w-[400px] py-1 px-3 ml-[35px]"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />

          <Typography className="pl-[15px]">
            {t('displayEnglishName')}
          </Typography>

          {showInspectionDescription && (
            <EyeNoHidden
              className="w-[22px] ml-[5px]"
              onClick={() => setShowInspectionDescription((prev) => !prev)}
            />
          )}

          {!showInspectionDescription && (
            <EyeHidden
              className="w-[22px] ml-[5px]"
              onClick={() => setShowInspectionDescription((prev) => !prev)}
            />
          )}
          <Popover
            placement="bottom"
            content={
              <div className="p-2.5 w-[330px] bg-[#545454] text-white rounded">
                {t('popover.hideEnglish')}
              </div>
            }
            overlayClassName="popover-permission"
          >
            <QuestionCircleFilled className="w-[14px] ml-1.5 text-[#B4B4B4]" />
          </Popover>
        </Flex>

        <Flex className="gap-4">
          {!showUpdate && (
            <Button
              className="h-[30px] w-[120px] font-bold text-primary border border-primary"
              autoInsertSpace={false}
              onClick={() => {
                setShowUpdate(true)
              }}
            >
              {t('button.edit')}
            </Button>
          )}
          <Button
            type="primary"
            className="h-[30px] w-[120px] font-bold"
            onClick={onClickSave}
            autoInsertSpace={false}
            htmlType="button"
          >
            {t('keepButton')}
          </Button>
        </Flex>
      </Flex>
      <FormProvider {...methods}>
        <form>
          <DragDropContext onDragEnd={onDragEnd}>
            <Flex className="mt-[11px] w-full">
              <Flex className="w-[55%] h-fit">
                <Flex className="w-[30%]">
                  <ItemSettingCategoryList
                    category={categoryShow}
                    language={language}
                    searchTerm={searchTerm}
                  />
                </Flex>

                <div className="w-[70%]">
                  <CollapsibleList
                    showInspectionDescription={showInspectionDescription}
                    showUpdate={showUpdate}
                    category={categoryShow}
                    language={language}
                    searchTerm={searchTerm}
                    setListCategoryShow={setCategoryShow}
                  />
                </div>
              </Flex>

              <Flex className="flex-1">
                <img
                  src={transferIcon}
                  alt="transfer-icon"
                  className="w-[78.9%] m-auto"
                />
              </Flex>

              <Flex className="w-[41.339%]">
                <CategoryAndItemList
                  isAddNewCategory={isAddNewCategory}
                  setIsAddNewCategory={setIsAddNewCategory}
                  isAddNewItemCategory={isAddNewItemCategory}
                  setIsAddNewItemCategory={setIsAddNewItemCategory}
                  showInspectionDescription={showInspectionDescription}
                  showUpdate={showUpdate}
                  category={categoryHidden}
                  language={language}
                  setCategoryHidden={setCategoryHidden}
                  handleRestore={handleRestore}
                  searchTerm={searchTerm}
                />
              </Flex>
            </Flex>
          </DragDropContext>
        </form>
      </FormProvider>

      <SettingSuccessModal
        isModalOpen={isModalVisible}
        onCancel={handleCancel}
      />

      {isAddNewCategory && (
        <ModalRenderItemList
          open
          onOk={handleSelectCategory}
          onCancel={() => setIsAddNewCategory(false)}
          listData={listCategory}
          title={t('newCategory')}
          titleButton={t('button.close')}
          classButton={
            'min-w-[180px] rounded-[3px] border-[1px] border-[#137695] text-[#137695] font-bold'
          }
          initialItemShow={initialShowCategoryRefIds}
          initialItemHidden={initialHiddenCategoryRefIds}
        />
      )}
      {isAddNewItemCategory && (
        <ModalRenderItemList
          open
          onOk={handleSelectItemCategory}
          onCancel={() => setIsAddNewItemCategory(false)}
          listData={(categoryHidden?.length > 0 && listItemMaster) || []}
          title={t('inspectionCategoryAddition')}
          titleButton={t('button.confirm')}
          classButton={
            'min-w-[180px] rounded-[3px] border-[1px] border-[#137695] text-white bg-[#137695] font-bold'
          }
          typeButton={'primary'}
          initialItemShow={initialShowItemsMasterRefIds}
          initialItemHidden={initialHiddenItemsMasterRefIds}
        />
      )}
    </div>
  )
}
