import { atom } from 'recoil'

import { initialDataScenarioCreate } from '../../data/data'
import { Flow, ScenarioCreate } from 'pages/Scenario/types/types'

// This atom is used to store the data of the scenario being created
export const scenarioCreateAtom = atom<ScenarioCreate>({
  key: 'scenarioCreateAtom',
  default: initialDataScenarioCreate
})

// This atom is used to store modal state of the simulator
export const simulatorModalAtom = atom<{ open: boolean }>({
  key: 'simulatorModalAtom',
  default: {
    open: false
  }
})

// save first block flow when user have not created flow choose courses yet
export const firstBlockDataTempAtom = atom<Flow | null>({
  key: 'firstBlockDataTempAtom',
  default: null
})
