import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Col, Empty, Flex, Row, Typography } from 'antd'

import editIcon from 'assets/imgs/edit.svg'
import { showName } from 'pages/InspectionItemSetting/helper'
import { Category } from 'types/FilterTestItemMaster'
import { getLanguage } from 'utilities/helpers'

const PreviewItems = ({
  items,
  onEdit
}: {
  items: Category[]
  onEdit: () => void
}) => {
  const { t } = useTranslation()
  return (
    <div className="bg-white w-[50%] rounded-lg p-4 shadow-lg">
      <Row className="w-full border-b">
        <Col span={16}>
          <Typography className="font-bold tracking-[.7px]">
            {t('lable.inspectionItems')}
          </Typography>
        </Col>
        <Col span={8}>
          <Flex className="flex items-center justify-between">
            <Typography className="font-bold tracking-[.7px]">
              {t('lable.testCD')}
            </Typography>
            <Button
              autoInsertSpace={false}
              color="default"
              variant="text"
              className="rounded-sm no-underline"
              onClick={() => onEdit()}
            >
              <img src={editIcon} alt="editIcon" />
            </Button>
          </Flex>
        </Col>
      </Row>
      {items && items.length > 0 ? (
        <Row className="w-full max-h-[218px] overflow-scroll">
          <Col span={16} className="flex flex-col">
            {items.map((it) => (
              <Typography key={it.refId} className="py-2 ">
                {showName(it, getLanguage())}
              </Typography>
            ))}
          </Col>
          <Col span={8} className="flex flex-col">
            {items.map((it) => (
              <Typography key={it.refId} className="py-2">
                {it.code.coding[0].code}
              </Typography>
            ))}
          </Col>
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  )
}

export default PreviewItems
