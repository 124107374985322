import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery } from '@apollo/client'
import { AGGREGATION_FILTER } from 'graphql/AggregationFilter/aggregationFilter'
import { FILTER_ITEM_MASTER } from 'graphql/ItemMaster/filterItemMaster'
import { StringKeyObject } from 'types/common'

const useTestItemMaster = () => {
  const [queryTestItemMaster, { loading, error, data }] =
    useLazyQuery(FILTER_ITEM_MASTER)
  const [queryAggregation] = useLazyQuery(AGGREGATION_FILTER)

  const getListItemMaster = async (variables: any) => {
    try {
      const result = await queryTestItemMaster({
        variables: {
          filter: '',
          page: 0,
          size: -1,
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  const getListItemMasterByMultipleLanguages = async (
    variables: any,
    page: number = 0,
    size: number = -1
  ) => {
    try {
      let result: any = await queryAggregation({
        variables: {
          collection: 'testItemMaster',
          page: page,
          size: size,
          request: [
            ...variables,
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      result = result?.data?.commonAggregationFilter?.payload
      if (!Array.isArray(result)) result = []
      result = result.map((item: any) => {
        const lang = item?.data?.additionalInfo?.localizedName ?? {}

        const {
          _id,
          visibility,
          status,
          _class,
          createdBy,
          createdDate,
          lastModifiedDate,
          ...rest
        } = item?.data ?? {}

        return { ...rest, lang }
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  const getItemMasterWithVariables = async (
    variables: StringKeyObject,
    page: number = 0,
    size: number = -1,
    isPayload: boolean = true
  ) => {
    try {
      const result = await queryAggregation({
        variables: {
          collection: 'testItemMaster',
          page: page,
          size: size,
          request: variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      if (!isPayload) {
        const data = result?.data?.commonAggregationFilter?.payload

        return {
          data,
          totalElements: result?.data?.commonAggregationFilter?.totalElements
        }
      }

      return result?.data?.commonAggregationFilter?.payload
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListItemMaster,
    getItemMasterWithVariables,
    getListItemMasterByMultipleLanguages,
    data,
    loading,
    error
  }
}

export default useTestItemMaster
