import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import ModalConfirm from 'components/modals/ModalConfirm'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'

const LABEL_PARENT = 'font-bold bg-[#F0F3F7] py-1.5 pl-2.5 pr-1 w-[203px]'

const ModalConfirmDelete = ({
  isModalVisible,
  medicalCheckupMasterDetails,
  handleOk,
  handleCancel
}: {
  isModalVisible: boolean
  medicalCheckupMasterDetails: MedicalCheckupMaster | null
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const { t } = useTranslation()

  const InputLabel = (label: string, isRequired: boolean = false) => {
    const { t } = useTranslation()

    return (
      <Flex className="w-full h-full justify-between items-center">
        <Typography>{label}</Typography>

        {isRequired && (
          <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white">
            {t('lable.required')}
          </Typography>
        )}
      </Flex>
    )
  }

  const Content = () => {
    const [overview, , , , , courseTimes] =
      medicalCheckupMasterDetails?.additionalInfo.metadata || []
    return (
      <div className="mb-16">
        <div className="mb-16">
          <p>{t('optionForm.textQuestion')}</p>
          <p>{t('optionForm.textConfirm')}</p>
        </div>
        <div className="px-11 text-left">
          <Flex className="mt-1.5 gap-4">
            <div className={LABEL_PARENT}>
              {InputLabel(t('optionForm.optionName'), false)}
            </div>
            <Flex className="flex-1" align="center">
              <Typography>
                {medicalCheckupMasterDetails?.displayName}
              </Typography>
            </Flex>
          </Flex>
          <Flex className="mt-1.5 gap-4">
            <div className={LABEL_PARENT}>
              {InputLabel(t('optionForm.overview'), false)}
            </div>
            <Flex className="flex-1" align="center">
              <Typography>{overview?.value}</Typography>
            </Flex>
          </Flex>
          <Flex className="mt-1.5 gap-4 w-full">
            <div className={LABEL_PARENT}>
              {InputLabel(t('optionForm.responseTime'), false)}
            </div>
            <Flex className="flex-1" align="center">
              <Typography>
                {courseTimes?.value}
                {t('optionForm.minutes')}
              </Typography>
            </Flex>
          </Flex>
        </div>
      </div>
    )
  }
  return (
    <ModalConfirm
      title={t('optionForm.deleteOption')}
      isModalVisible={isModalVisible}
      handleOk={handleOk}
      handleCancel={handleCancel}
      content={<Content />}
      classNames={{
        classNameButtonCancel: '!border-primary !text-primary'
      }}
      icons={
        <div className="pt-10 pb-5">
          <ExclamationCircleFilled className="w-10 inline-block" />
        </div>
      }
      textOk={t('button.delete')}
    />
  )
}
export default ModalConfirmDelete
