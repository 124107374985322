import { DocumentNode, gql } from '@apollo/client'

export const FILTER_AVAILABLE_SLOTS_BY_DATE: DocumentNode = gql`
  query FilterAvailableSlotsByDateQuery(
    $medicalCheckupMasterRefId: String!
    $daterange: DateRangeInput!
  ) {
    filterAvailableSlotsByDate(
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
      daterange: $daterange
    ) {
      message
      status
      payload {
        refId
        emptySlotCount
        datetimeRule {
          time
          date
          rule
        }
      }
    }
  }
`
