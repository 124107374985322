import { ArrowProps } from '../types'

const ArrowLeft = ({ onClick, disabled }: ArrowProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.34"
      height="24.563"
      viewBox="0 0 10.34 24.563"
      onClick={onClick}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        marginRight: '30px'
      }}
    >
      <path
        id="パス_40275"
        data-name="パス 40275"
        d="M-11464.668-11900l-7.721,10.887,7.721,10.889"
        transform="translate(11473.614 11901.394)"
        fill="none"
        strokeLinecap="round"
        strokeWidth="2"
        stroke={disabled ? '#BDCBD5' : '#137695'}
      />
    </svg>
  )
}

export default ArrowLeft
