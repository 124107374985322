import { useTranslation } from 'react-i18next'

import { Button, Typography } from 'antd'

import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
}

const SettingSuccessModal = ({
  isModalOpen = false,
  onCancel,
}: Props) => {
  const { t } = useTranslation('')

 

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} >
      <div className="pt-4 pb-10 text-center">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('saveComplete')}
          {/* {t('content.settingsSaved')} */}
        </Typography>
        <Paragraph className="mt-[165px] mb-0">
          {t('content.settingsSaved')}
        </Paragraph>
        <Button
          className="w-[180px] h-[30px] text-primary bg-white font-bold mt-[145px] m-auto border-primary"
          onClick={onCancel}
        >
          {t('button.close')}
        </Button>
      </div>
    </CustomModal>
  )
}

export default SettingSuccessModal
