import { StartActivating } from './steps/startActivating/StartActivating'

function ScenarioCreateContent() {
  return (
    <div
      id="scenario-create-content"
      className="overflow-auto w-full h-full flex flex-col justify-center mt-3 bg-[url('assets/pngs/create-scenarior-bgr.png')] bg-cover bg-no-repeat bg-center shadow-md px-5"
    >
      <div className="relative flex items-center">
        <StartActivating />
      </div>
    </div>
  )
}

export default ScenarioCreateContent
