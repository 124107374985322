import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Typography } from 'antd'

import { API_CLIENT_CODE } from '../../configs/api'
import { TenantPayload } from './types'
import { useQuery } from '@apollo/client'
import { ReactComponent as HospitalIcon } from 'assets/svgs/hospital-activated1.svg'
import LoadingScreen from 'components/loading/LoadingScreen'
import { SUB_TENANT_PARAM_KEY, TENANT_PARAM_KEY } from 'configs/constant'
import { FILTER_TENANT } from 'graphql/tenant/filter'
import { uuid } from 'utilities/helpers'

function SelectTenant() {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const tenantCode = searchParams.get(TENANT_PARAM_KEY)
  const subTenantCode = searchParams.get(SUB_TENANT_PARAM_KEY)

  const navigate = useNavigate()
  const { state } = useLocation()

  const {
    loading,
    data: tenantResponse,
    error
  } = useQuery(FILTER_TENANT, {
    variables: {
      filter: `(in,STRING,code,${state?.tenantCodes})`,
      page: 0,
      size: -1
    },
    fetchPolicy: 'network-only',
    context: {
      headers: {
        authorization: `Bearer ${state?.token}`,
        'CLIENT-CODE': API_CLIENT_CODE,
        'TENANT-CODE': tenantCode,
        'SUB-TENANT-CODE': subTenantCode
      }
    }
  })

  if (error) {
    navigate('/login')
  }

  const onClickTenant = (tenant: TenantPayload) => {
    const tenantFormatted = {
      tenant: tenant.code,
      tenantName: tenant.additionalInfo?.clinicKanaName ?? tenant.code,
      subTenant: tenant.subTenantCodes[0].code
    }

    // redirect to home page with params tenant and subTenant
    navigate(
      `/login?${TENANT_PARAM_KEY}=${tenantFormatted.tenant}&${SUB_TENANT_PARAM_KEY}=${tenantFormatted.subTenant}`,
      {
        state: {
          tenantName: tenantFormatted.tenantName
        }
      }
    )
  }

  return (
    <div className="h-full flex justify-center items-center">
      {loading && <LoadingScreen loading={loading} />}
      {tenantResponse && tenantResponse?.filterTenant && (
        <div className="w-[600px]">
          <Typography className="text-center text-primary font-bold text-2xl leading-none">
            {t('lable.chooseClinic')}
          </Typography>

          <Typography className="my-11 text-center flex flex-col item-center justify-center">
            <p className="mb-0">{t('content.loginClinic1')}</p>
            <p className="mb-0">{t('content.loginClinic2')}</p>
          </Typography>

          <div className="w-full max-h-96 overflow-y-auto flex flex-col gap-2">
            {tenantResponse.filterTenant?.payload?.map(
              (tenant: TenantPayload) => (
                <div
                  className="flex items-center px-3 py-4 bg-white border border-primary hover:bg-slate-50 cursor-pointer"
                  key={`${uuid()}`}
                  onClick={() => onClickTenant(tenant)}
                >
                  <HospitalIcon className="text-primary mr-2" />
                  <span className="text-primary font-bold">
                    {tenant?.additionalInfo?.clinicKanaName || tenant?.code}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectTenant
