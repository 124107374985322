import { useEffect, useState } from 'react'

import { extractDepartmentsFromCode, extractPureCode } from './survey/useSurvey'
import {
  useGetMedicalCheckupMasterByName,
  useMedicalCheckupMaster
} from './useMedicalCheckupMaster'
import { handleMetaData } from './useProductMaster'
import useTestItemMaster from './useTestItemMaster'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  ConditionalNumber,
  DEFAULT_DATE_FORMAT,
  GENDER,
  META_DATA_MEDICAL_CHECKUP_MASTER,
  typeReferenceValue
} from 'configs/constant'
import { format } from 'date-fns'
import { CREATE_MEDICAL_CHECKUP_MASTER } from 'graphql/MedicalCheckupMaster/createMedicalCheckupMaster'
import { UPDATE_MEDICAL_CHECKUP_MASTER } from 'graphql/MedicalCheckupMaster/updateMedicalCheckupMaster'
import { CREATE_PERIOD_CONFIG } from 'graphql/ReferenceValue/createPeriodConfig'
import { FILTER_PERIOD_CONFIGS } from 'graphql/ReferenceValue/listPeriodConfigs'
import { FILTER_SURVEY_LIST } from 'graphql/Survey/filter'
import { ITEM_START_NAME } from 'pages/CourseRegister/components/SetupThresholdAndCriteria'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import {
  MedicalCheckupMaster,
  UpdateMedicalCheckupMaster
} from 'types/MedicalCheckupMaster'
import { LoadSurveyList, Payload } from 'types/Surveys'
import { Options, StringKeyObject } from 'types/common'
import {
  ItemOption,
  Option,
  PeriodConfig,
  SurveyList
} from 'types/courseRegister'
import { Endpoint } from 'utilities/apolloClient'
import { formatDate, timestampInSeconds } from 'utilities/helpers'

export type CourseRegister = {
  name: string
  overview?: string
  sex?: string
  classification?: string
  startAge?: number
  endAge?: number
  courseTimes?: number
  buffer?: number
  courseCd?: number
  key?: string
}

const useCourseRegister = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [loadSurveyList] = useLazyQuery(FILTER_SURVEY_LIST)
  const [surveys, setSurveys] = useState<SurveyList[]>([])
  const [options, setOptions] = useState<Option[]>([])
  const [createMedicalCheckupMasterMutation] = useMutation(
    CREATE_MEDICAL_CHECKUP_MASTER
  )
  const [updateMedicalCheckupMasterMutation] = useMutation(
    UPDATE_MEDICAL_CHECKUP_MASTER
  )

  const [createPeriodConfigMutation] = useMutation(CREATE_PERIOD_CONFIG)
  const [loadPeriodConfig] = useLazyQuery(FILTER_PERIOD_CONFIGS)
  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()
  const { getListNameMedicalCheckupMaster } = useGetMedicalCheckupMasterByName()
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()
  const [optionsMedicalCheckupMaster, setOptionsMedicalCheckupMaster] =
    useState<Options[]>([])

  useEffect(() => {
    const getListSurvey = async () => {
      try {
        const surveysList: LoadSurveyList = await loadSurveyList({
          variables: {
            filter: '(eq,STRING,surveyStatus,ASSIGNED)',
            sortBy: '(desc,createdDate)'
          },
          context: { version: Endpoint.SURVEY_CORE },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'no-cache'
        })

        const response: Payload[] = surveysList?.data?.filterSurvey?.payload

        if (!Array.isArray(response)) return
        const result: SurveyList[] = response.map((item: Payload) => ({
          id: item.refId ?? '',
          questionnaireTitle: item.displayNameMap?.ja ?? '',
          medicalDepartment:
            extractDepartmentsFromCode(item.code).join(',') ?? '',
          creator: item.createdBy ?? '',
          dateCreated: item.createdDate
            ? format(item.createdDate, 'yyyy-MM-dd')
            : '',
          code: extractPureCode(item.code) ?? ''
        }))

        setSurveys(result)
      } catch (error) {
        setError((error as Error).message)
      }
    }

    getListSurvey()

    const getListOptions = async () => {
      const data = await getMedicalCheckupMaster({
        // filter: '(eq,STRING,additionalInfo.key1,option)',
        // sortBy: '(desc,createdDate)'

        filter: [
          {
            field: 'data.additionalInfo.key1',
            operator: 'eq',
            value: 'option'
          },
          {
            field: 'data.additionalInfo.isDeleted',
            operator: 'ne',
            value: 'true'
          }
        ],
        sortBy: {
          field: 'createdDate',
          direction: 'desc'
        }
      })

      if (!Array.isArray(data)) return
      const result: Option[] = data.map((item) => ({
        ...item.medicalCheckupMaster,
        id: item.medicalCheckupMaster?.refId ?? '',
        questionnaireTitle: item.medicalCheckupMaster?.displayName ?? '',
        creator: item.medicalCheckupMaster?.createdBy ?? '',
        dateCreated: item.medicalCheckupMaster?.createdDate
          ? format(item.medicalCheckupMaster.createdDate, DEFAULT_DATE_FORMAT)
          : ''
      }))

      setOptions(result)
    }

    getListOptions()

    const getMedicalCheckupMasterNameOptions = async () => {
      const data = await getListNameMedicalCheckupMaster({
        filter: [
          {
            field: 'data.additionalInfo.key1',
            operator: 'ne',
            value: 'option'
          },
          {
            field: 'data.additionalInfo.isDeleted',
            operator: 'ne',
            value: 'true'
          }
        ],
        sortBy: {
          field: 'createdDate',
          direction: 'desc'
        }
      })
      if (!Array.isArray(data)) return
      const result: Options[] = data.map((item) => ({
        key: item.medicalCheckupMaster?.refId ?? '',
        value: item.medicalCheckupMaster?.refId ?? '',
        label: item.medicalCheckupMaster?.displayName ?? ''
      }))

      setOptionsMedicalCheckupMaster(result)
    }

    getMedicalCheckupMasterNameOptions()
  }, [])

  const createMedicalCheckupMaster = async (request: any) => {
    const result = await createMedicalCheckupMasterMutation({
      variables: { request: request },
      context: { version: Endpoint.CHECKUP_CORE },
      fetchPolicy: 'network-only'
    })

    return result
  }

  const createPeriodConfig = async (request: PeriodConfig[]) => {
    const result = await createPeriodConfigMutation({
      variables: {
        periodConfigsData: request
      },
      context: { version: Endpoint.REFERENCE_VALUE },
      fetchPolicy: 'network-only'
    })

    return result
  }

  const getPeriodConfigs = async (medicalCheckupMasterObjId: string) => {
    try {
      const result = await loadPeriodConfig({
        variables: {
          medicalCheckupMasterObjId: medicalCheckupMasterObjId
        },
        context: { version: Endpoint.REFERENCE_VALUE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
      })

      return result
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const updateMedicalCheckupMaster = async (
    request: UpdateMedicalCheckupMaster[]
  ) => {
    const result = await updateMedicalCheckupMasterMutation({
      variables: { request: request },
      context: { version: Endpoint.CHECKUP_CORE },
      fetchPolicy: 'network-only'
    })

    return result
  }

  const deleteMedicalCheckupMaster = async (
    medicalCheckupMaster: MedicalCheckupMaster
  ) => {
    const {
      createdDate,
      createdBy,
      visibility,
      _class,
      _id,
      lastModifiedDate,
      ...dataUpdate
    } = medicalCheckupMaster
    const request = [
      {
        ...dataUpdate,
        additionalInfo: {
          ...dataUpdate.additionalInfo,
          isDeleted: 'true'
        }
      }
    ]

    const result = await updateMedicalCheckupMasterMutation({
      variables: { request: request },
      context: { version: Endpoint.CHECKUP_CORE },
      fetchPolicy: 'network-only'
    })

    return result
  }

  const handleCreateMedicalCheckupMaster = async (
    data: CourseRegister,
    surveysSelected?: SurveyList[],
    optionsSelected?: React.Key[],
    listSetupThresholdAndCriteria?: StringKeyObject[],
    refId?: string,
    listCategory?: {
      categories: DataItemMaster[]
      excludedCategories: DataItemMaster[]
    },
    objectId?: string
  ) => {
    try {
      onClearError()
      setLoading(true)
      const { categories, excludedCategories } = listCategory || {}
      const newCategories = {
        categories: mappingCategories(categories ?? []),
        excludedCategories: mappingCategories(excludedCategories ?? [])
      }

      const associatedTestItemMasters = listCategory?.categories.flatMap(
        (item) => item.items?.map((it) => it.refId) ?? []
      )
      let dataCreate = [
        {
          ...(refId && { refId: refId }),
          shortName: data.name.trim(),
          displayName: data.name.trim(),
          associatedTestItemMasters: associatedTestItemMasters ?? [],
          additionalInfo: {
            objId: objectId,
            metadata: [
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW,
                value: data.overview ?? ''
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.SEX,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.SEX,
                value: data.sex ?? ''
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION,
                value: data.classification ?? ''
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE,
                value: data.startAge
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE,
                value: data.endAge
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES,
                value: data.courseTimes
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER,
                value: data.buffer
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.SURVEYS,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.SURVEYS,
                value: (surveysSelected ?? [])?.map((item) => item.id)
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.OPTIONS,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.OPTIONS,
                value: optionsSelected ?? []
              },
              {
                name: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_CD,
                description: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_CD,
                value: data.courseCd ?? timestampInSeconds()
              }
            ],
            key1: data.key,
            ...newCategories
          }
        }
      ]

      if (refId) {
        await updateMedicalCheckupMaster(dataCreate)
        await handleReferenceValue(
          refId,
          listSetupThresholdAndCriteria,
          objectId ?? ''
        )
      } else {
        const created = await createMedicalCheckupMaster(dataCreate)
        const idCreate =
          created?.data?.addMedicalCheckupMaster?.payload?.[0].refId
        if (idCreate)
          await handleReferenceValue(
            idCreate,
            listSetupThresholdAndCriteria,
            objectId ?? ''
          )
      }
    } catch (error) {
      setError((error as Error).message)
    }

    setLoading(false)
  }

  const checkConditionWithOptions = (
    data: CourseRegister,
    optionsSelected: React.Key[]
  ) => {
    if (optionsSelected.length === 0) return []

    const errors: Option[] = []
    const optionsFilter = options.filter((item) =>
      optionsSelected.includes(item.id)
    )

    const { sex, classification, startAge = 0, endAge = 999 } = data

    optionsFilter.forEach((option) => {
      const metaDataObj = handleMetaData(option?.additionalInfo?.metadata ?? [])
      const optionSex = metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SEX]
      const optionClassification =
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION]
      const optionStartAge =
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE] ?? 0
      const optionEndAge =
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE] ?? 999

      const isSexMismatch =
        sex !== GENDER.NONE && optionSex !== GENDER.NONE && sex !== optionSex
      const isClassificationMismatch = classification !== optionClassification
      const isAgeOverlap =
        (startAge <= optionStartAge && optionStartAge <= endAge) ||
        (startAge <= optionEndAge && optionEndAge <= endAge) ||
        (optionStartAge <= startAge && endAge <= optionEndAge)

      if (isSexMismatch || isClassificationMismatch || !isAgeOverlap) {
        errors.push(option)
      }
    })

    return errors
  }

  const handleReferenceValue = async (
    refId: string,
    listSetupThresholdAndCriteria: StringKeyObject[] | undefined,
    objectId: string
  ) => {
    if (
      !Array.isArray(listSetupThresholdAndCriteria) ||
      listSetupThresholdAndCriteria.length === 0
    )
      return

    const dataHandle = listSetupThresholdAndCriteria.map(
      (item: StringKeyObject, index: number) => {
        const evaluations = Object.keys(item.judgment_item).map(
          (evaluation: string, indexEvaluation) => {
            const objItemMaster = item.judgment?.[evaluation] ?? {}
            const listReferenceValues = Object.keys(objItemMaster).map(
              (item) => {
                const refId = item.replace(ITEM_START_NAME, '')
                const constraint = (objItemMaster[item] ?? []).filter(
                  (item: StringKeyObject) => item
                )

                return {
                  testItemMasterRefId: refId,
                  constraint: constraint.map(
                    (item: StringKeyObject, idx: number) => {
                      const result: any = {
                        order: idx + 1,
                        type: item.type
                      }

                      if (item.gender) result.gender = item.gender
                      if (item.type === typeReferenceValue.RANGE_NUMBER) {
                        result.lowerLimit = item.start
                        result.upperLimit = item.end
                      }
                      if (item.type === typeReferenceValue.STRING)
                        result.textValue = item.text

                      if (item.type === typeReferenceValue.CONDITIONAL_NUMBER) {
                        result.lowerLimit =
                          item.select === ConditionalNumber.GE
                            ? item.value
                            : null
                        result.upperLimit =
                          item.select === ConditionalNumber.LE
                            ? item.value
                            : null
                      }

                      return result
                    }
                  )
                }
              }
            )

            return {
              name: item.judgment_item[evaluation].evaluation,
              desc: item.judgment_item[evaluation].description,
              color: item.judgment_item[evaluation].bgColor,
              order: indexEvaluation + 1,
              referenceValues: listReferenceValues
            }
          }
        )

        return {
          medicalCheckupMasterRefId: refId,
          startDate: item.application_period_start
            ? new Date(formatDate(item.application_period_start)).toISOString()
            : item.application_period_start,
          endDate: item.application_period_end
            ? new Date(formatDate(item.application_period_end)).toISOString()
            : item.application_period_end,
          order: index + 1,
          evaluations: evaluations,
          additionalInfo: {
            medicalCheckupMasterObjId: objectId
          }
        }
      }
    )

    await createPeriodConfig(dataHandle)
  }

  const handleDataItemsOptions = async (optionsSelected: React.Key[]) => {
    if (optionsSelected.length === 0) return []

    const data = await getMedicalCheckupMaster({
      // filter: `(eq,STRING,additionalInfo.key1,option);(in,STRING,refId,${optionsSelected.join(',')})`

      filter: [
        { field: 'data.additionalInfo.key1', operator: 'eq', value: 'option' },
        {
          field: 'data.additionalInfo.isDeleted',
          operator: 'ne',
          value: 'true'
        },
        {
          field: 'data.refId',
          operator: 'in',
          value: optionsSelected
        }
      ],
      sortBy: {
        field: 'createdDate',
        direction: 'desc'
      }
    })
    if (!Array.isArray(data)) return []

    const itemsIds: string[] = []

    for (const item of data) {
      if (Array.isArray(item.medicalCheckupMaster?.associatedTestItemMasters)) {
        itemsIds.push(...item.medicalCheckupMaster.associatedTestItemMasters)
      }
    }

    const uniqueItemsIds = Array.from(new Set(itemsIds))

    let items = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: uniqueItemsIds
            }
          ]
        }
      }
    ])
    if (!Array.isArray(items)) return []

    const result: ItemOption[] = items.map((item) => ({
      refId: item.refId ?? '',
      code: item.code?.coding?.[0]?.code ?? '',
      classification: '', //hard code
      lang: item.lang ?? {}
    }))

    return result
  }

  const onClearError = () => {
    setError('')
  }

  const mappingCategories = (categories: DataItemMaster[]) => {
    return categories.map((item, index) => {
      return {
        refId: item.refId,
        order: index,
        items: (item?.items || []).map((it, idx) => ({
          refId: it.refId,
          order: idx
        }))
      }
    })
  }

  return {
    handleCreateMedicalCheckupMaster,
    handleDataItemsOptions,
    loading,
    setLoading,
    error,
    onClearError,
    surveys,
    options,
    optionsMedicalCheckupMaster,
    deleteMedicalCheckupMaster,
    getPeriodConfigs,
    checkConditionWithOptions
  }
}

export default useCourseRegister
