import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tabs, TabsProps, Typography } from 'antd'

import { TAB } from '../ProductSetting'
import ReservationDate from './ReservationDate'
import ReservationWeek from './ReservationWeek'
import styles from './scss/TabWrapper.module.scss'

type Props = {
  submissionCount: number
  setIsChange: (isChange: boolean) => void
}

const Content: FC<Props> = memo(({ submissionCount, setIsChange }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const { tab: tabParam = TAB.RESERVATION_WEEK } =
    Object.fromEntries(queryParams)

  const items: TabsProps['items'] = [
    {
      key: TAB.RESERVATION_WEEK,
      label: (
        <Typography className="w-[180px] text-center text-sm">
          {t('productSetting.content.reservationByWeek')}
        </Typography>
      ),
      children: (
        <ReservationWeek
          submissionCount={submissionCount}
          setIsChange={setIsChange}
        />
      )
    },
    {
      key: TAB.RESERVATION_DATE,
      label: (
        <Typography className="w-[180px] text-center text-sm">
          {t('productSetting.content.reservationByDay')}
        </Typography>
      ),
      children: (
        <ReservationDate
          submissionCount={submissionCount}
          setIsChange={setIsChange}
        />
      )
    }
  ]

  const handleChangeTab = (activeKey: string) => {
    queryParams.set('tab', activeKey)

    if (activeKey === TAB.RESERVATION_WEEK) {
      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <div className="mt-[10px] overflow-y-scroll rounded-[5px] bg-[#FFFFFF] pl-[6px] pr-[6px] drop-shadow-md">
      <Tabs
        className={styles.tab_wrapper}
        items={items}
        activeKey={tabParam}
        onChange={(activeKey) => handleChangeTab(activeKey)}
      />
    </div>
  )
})

export default Content
