import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ScenarioData } from '../types/types'
import Header from './components/header/Header'
import DeleteConfirmationModal from './components/modal/DeleteConfirmationModal'
import TableCustom from './components/table/Table'
import ElmNumbers from './components/table/elmNumbers/ElmNumbers'
import ScenarioListLayout from 'layouts/Scenario/ScenarioListLayout/ScenarioListLayout'
import { RECORD_COUNT } from 'models/customer'
import { deleteScenario, getScenarios } from 'services/ScenarioService'
import { convertScenariosData } from 'utilities/scenarioHelper'

const INITIAL_PAGE = '1'
const INITIAL_LIMIT = String(RECORD_COUNT.OneHundred)

function ScenarioList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const sort = searchParams.get('sort')
  const pageCurrent = searchParams.get('page')
  const limitCurrent = searchParams.get('limit')
  const idDlt = searchParams.get('id_dlt')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [scenarios, setScenarios] = useState<ScenarioData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalRecords, setTotalRecords] = useState<number>(0)

  // get scenario list
  useEffect(() => {
    if (!sort || !pageCurrent || !limitCurrent) return

    try {
      setLoading(true)
      fetchScenarioList(pageCurrent, limitCurrent, sort).finally(() => {
        setLoading(false)
      })
    } catch (error) {
      console.log('error ====>', error)
    }
  }, [pageCurrent, limitCurrent, sort])

  // set sort, limit, page default
  useEffect(() => {
    if (!sort && !limitCurrent && !pageCurrent) {
      setSearchParams({
        sort: 'desc,modifiedDate',
        limit: INITIAL_LIMIT,
        page: INITIAL_PAGE
      })
    }
  }, [sort, scenarios])

  const fetchScenarioList = async (
    pageCurrent: string,
    limitCurrent: string,
    sort: string
  ) => {
    if (parseInt(pageCurrent) <= 0) return

    try {
      const { payload, totalElements, currentPageNumber } = await getScenarios({
        page: parseInt(pageCurrent) - 1, // page in backend is 0-indexed
        size: parseInt(limitCurrent),
        sortBy: sort
      })

      if (payload.length === 0 && parseInt(pageCurrent) > 1) {
        setParams('page', String(+pageCurrent - 1))
        return
      }

      setScenarios(
        convertScenariosData(payload, parseInt(limitCurrent), currentPageNumber)
      )
      setTotalRecords(totalElements)
    } catch (error) {
      console.log('error ====>', error)
    }
  }

  const setParams = (key: string, value: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(key, value)
      return newParams
    })
  }

  const onSortTable = (key: string) => {
    const sortParams = searchParams.get('sort')

    if (sortParams) {
      let direction =
        sortParams.includes('asc') && sortParams.includes(key) ? 'desc' : 'asc'

      setParams('sort', `${direction},${key}`)
    }
  }

  const onChangeLimit = (limit: number) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set('limit', limit.toString())
      newParams.set('page', INITIAL_PAGE)

      return newParams
    })
  }

  const onOpenDeleteModal = () => {
    setIsDeleteModalOpen(true)
  }

  const onCloseDeleteModal = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete('id_dlt')
      return newParams
    })
    setIsDeleteModalOpen(false)
  }

  const onEditScenario = (id: string) => {
    navigate(`/scenario/${id}/edit`)
  }

  const onDeleteScenario = (id: string) => {
    setParams('id_dlt', id)
    onOpenDeleteModal()
  }

  const onCreateScenario = () => {
    navigate('/scenario/create')
  }

  const onChangePage = (page: number) => {
    setParams('page', page.toString())
  }

  const handleRemoveScenario = async () => {
    await fetchScenarioList(
      pageCurrent || INITIAL_PAGE,
      limitCurrent || INITIAL_LIMIT,
      sort || 'desc,modifiedDate'
    )
  }

  const onConfirmDeleteScenario = async () => {
    onCloseDeleteModal()

    if (idDlt) {
      try {
        setLoading(true)
        await deleteScenario({ refId: idDlt })
        await handleRemoveScenario()
      } catch (error) {
        console.log('error ====>', error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <React.Fragment>
      <ScenarioListLayout
        header={<Header onCreateScenario={onCreateScenario} />}
        table={
          <TableCustom
            loading={loading}
            dataSource={scenarios}
            onSortTable={onSortTable}
            elmNumbers={
              <ElmNumbers
                label={t('scenarioList.displayCount')}
                currentElmNumbers={parseInt(limitCurrent || INITIAL_LIMIT)}
                onChange={onChangeLimit}
              />
            }
            onEditScenario={onEditScenario}
            onDeleteScenario={onDeleteScenario}
            pagination={{
              currentPage: parseInt(pageCurrent || INITIAL_PAGE),
              onChangePage,
              totalRecords,
              pageSize: parseInt(limitCurrent || INITIAL_LIMIT),
              onFirstPage: () => onChangePage(1),
              onLastPage: () =>
                onChangePage(
                  Math.ceil(
                    totalRecords / parseInt(limitCurrent || INITIAL_LIMIT)
                  )
                )
            }}
          />
        }
      />
      <DeleteConfirmationModal
        title={t('scenarioCreate.modal.deleteComplete.titleConfirm')}
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDeleteScenario}
      />
    </React.Fragment>
  )
}

export default ScenarioList
