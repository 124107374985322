import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import {
  Button,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
  Typography
} from 'antd'

import { PaginationCustom } from '../../components/elements/PaginationCustom/PaginationCustom'
import '../../components/screens/SurveyList/CustomTable.css'
import { DropdownList } from '../../components/screens/SurveyList/DropdownList'
import { openNotification } from '../../components/widgets/Notification'
import useSurveyList from '../../hooks/survey/useSurveyList'
import { useSubTenantList } from '../../hooks/tenant/useTenantList'
import { SurveyStatusEnums } from '../../models/survey'
import { surveyList } from '../../models/surveyList'
import { validPage, validPerPage } from '../../utilities/helpers'
import { downloadCSV, getCurrentDateFormatted } from '../../utilities/helpers'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as EditOutlined } from 'assets/svgs/EditOutlined.svg'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as ArrowBottomDesc } from 'assets/svgs/arrow-bottom-desc.svg'
import { ReactComponent as ArrowTopAsc } from 'assets/svgs/arrow-top-asc.svg'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'

const variablesInitFilter = {
  filter: '(ne,STRING,surveyStatus,DELETED);',
  sortBy: '(desc,createdDate)',
  paginationInput: { page: 0, size: 100 }
}

type DataForm = {
  q: string
  department: string[]
  page: number
  perPage: number
  sortBy?: string
  orderBy?: string
}

interface CheckboxItemType {
  label: string
  value: string
}

export default function SurveyList() {
  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()
  const { loadSurveyList, loading } = useSurveyList()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [listSelectedRows, setListSelectedRows] = useState<any[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  // query params
  const [searchParams, setSearchParams] = useSearchParams()
  const { subTenantCodes } = useSubTenantList()

  const methods = useForm<DataForm>({
    defaultValues: {
      q: '',
      department: [],
      sortBy: '',
      orderBy: '',
      page: 0,
      perPage: 100
    }
  })

  const { getValues, setValue, resetField } = methods

  useEffect(() => {
    if (!location.search) {
      const searchParamsDefault = new URLSearchParams(location.search)
      handleFilterByUrl(searchParamsDefault)
    }
  }, [location.search])

  useEffect(() => {
    handleFilterByUrl(searchParams)
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      const searchParamsOld = new URLSearchParams(window.location.search)
      handleFilterByUrl(searchParamsOld)
    }
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const handleFilterByUrl = async (searchParamsUrl: any) => {
    let q = searchParamsUrl.get('q')
    let department = searchParamsUrl.get('department')
    if (department) {
      department = department.split(',')
    }

    let page = searchParamsUrl.get('page')
      ? Number(searchParamsUrl.get('page'))
      : 1
    let perPage = searchParamsUrl.get('perPage')
      ? Number(searchParamsUrl.get('perPage'))
      : 100
    let sortBy = searchParamsUrl.get('sortBy')
    let orderBy = searchParamsUrl.get('orderBy')

    await fetchData(q, department, page - 1, perPage, sortBy, orderBy, false)
  }

  const fetchData = async (
    q: string,
    departmentsParam: string[],
    page: number,
    perPage: number,
    sortBy: string | undefined,
    orderBy: string | undefined,
    pushUrl: boolean = true
  ) => {
    const newParams = new URLSearchParams(searchParams)

    let filter = variablesInitFilter.filter
    if (q) {
      filter += `(like,STRING,displayNameMap.ja,${q});`
      setValue('q', q)
      newParams.set('q', q)
    } else {
      newParams.delete('q')
      resetField('q')
    }
    if (departmentsParam && departmentsParam.length > 0) {
      let filterDepartment = ``
      for (const department of departmentsParam) {
        filterDepartment = `(like,STRING,code,${department})%${filterDepartment}`
      }
      filter += filterDepartment
      setValue('department', departmentsParam)
      newParams.set('department', departmentsParam.join(','))
    } else {
      newParams.delete('department')
      resetField('department')
    }

    let variablesFilter = {
      ...variablesInitFilter,
      filter,
      paginationInput: { page: validPage(page), size: validPerPage(perPage) }
    }

    if (sortBy && orderBy) {
      setValue('sortBy', sortBy)
      setValue('orderBy', orderBy)
      newParams.set('sortBy', sortBy)
      newParams.set('orderBy', orderBy)

      variablesFilter = {
        ...variablesFilter,
        sortBy: `(${orderBy},${sortBy})`
      }
    } else {
      setValue('sortBy', '')
      setValue('orderBy', '')
    }

    const response: any = await loadSurveyList(variablesFilter, true)
    if (response) {
      const { surveyList, totalElements } = response
      if (surveyList) setDataSource(surveyList)
      if (totalElements) setTotal(totalElements)
      setSelectedRowKeys([])
    }

    setValue('page', page)
    setValue('perPage', perPage)
    newParams.set('page', `${page + 1}`)
    newParams.set('perPage', `${perPage}`)

    if (pushUrl) {
      setSearchParams(newParams)
    }
  }

  const onSearch = async () => {
    let q = getValues('q')
    let department = getValues('department')
    let page = getValues('page')
    let perPage = getValues('perPage')
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')

    await fetchData(q, department, page, perPage, sortBy, orderBy)
  }

  const goToFirstPage = () => {
    onChangePage(1)
  }

  const goToLastPage = async (total: number) => {
    const totalPages = Math.ceil(total / getValues('perPage'))
    setValue('page', totalPages - 1)

    await onSearch()
  }
  const onChangePage = async (value: number) => {
    setValue('page', value - 1)
    await onSearch()
  }

  const navigateQuestionnaireCreationPage = () => {
    navigate('/survey-creation')
  }

  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 170)
  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(window.innerHeight - 170)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleExport = () => {
    // console.log('[handleExport]', { listSelectedRows })
    const csvString = [
      ['問診票タイトル', '診療科', '作成者', '作成日'],
      ...listSelectedRows.map((item) => [
        item.title,
        item.departments,
        item.author,
        item.createdDate
      ])
    ]
      .map((e) => e.join(','))
      .join('\n')

    const filename = `問診管理一覧 ${getCurrentDateFormatted('yyyyMMdd-hhmmss')}.csv`
    downloadCSV(csvString, filename)

    openNotification({
      type: 'success',
      title: t('commonSuccess'),
      message: `${filename}がダウンロードされた`
    })
  }

  const toggleSort = async (field: string) => {
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')
    if (!orderBy) orderBy = 'asc'

    if (field === sortBy) {
      orderBy = orderBy === 'asc' ? 'desc' : 'asc'
    }

    setValue('sortBy', field)
    setValue('orderBy', orderBy)

    await onSearch()
  }

  const onChangePagination = async (page: {
    value: string
    label: React.ReactNode
  }) => {
    const pageSize = Number.parseInt(page.value)
    setValue('perPage', pageSize)

    await onSearch()
  }
  const handleKeyDownDefault = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }
  const SortIconDirection = (name: string) => {
    const sortBy = getValues('sortBy')
    const orderBy = getValues('orderBy') ?? ''
    let icon = <ArrowTopBottom />

    if (sortBy === name) {
      if (orderBy.toLowerCase() === 'asc') {
        icon = <ArrowTopAsc />
      } else if (orderBy.toLowerCase() === 'desc') {
        icon = <ArrowBottomDesc />
      }
    }

    return (
      <>
        <button className="cursor-pointer" onClick={() => toggleSort(name)}>
          {icon}
        </button>
      </>
    )
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setListSelectedRows(selectedRows)
      setSelectedRowKeys(newSelectedRowKeys)
    }
  }

  const SurveyListColumns: TableColumnsType<surveyList> = [
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('questionnaire.title')}</span>
          {SortIconDirection('displayNameMap.ja')}
        </Flex>
      ),
      dataIndex: 'title',
      width: '25%',
      align: 'start',
      render: (_, record) => (
        <Flex>
          <Link to={`/survey/${record.refId}/detail`}>
            <Typography.Text className="text-[#137695] font-bold underline">
              {record.title}
            </Typography.Text>
          </Link>
        </Flex>
      )
    },
    {
      title: (
        <Typography className="font-medium text-center">
          {t('lable.department')}
        </Typography>
      ),
      align: 'start',
      dataIndex: 'departments',
      width: '30%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.author')}</span>
          {SortIconDirection('createdBy')}
        </Flex>
      ),
      ellipsis: true,
      width: '30%',
      align: 'start',
      dataIndex: 'author'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.createdAt')}</span>
          {SortIconDirection('createdDate')}
        </Flex>
      ),
      dataIndex: 'createdDate',
      align: 'center',
      width: '12%'
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: '3%',
      render: (_, record) => {
        const { refId, status } = record
        if (status === SurveyStatusEnums.assigned || status === SurveyStatusEnums.completed) {
          return <></>
        }

        return (
          <Tooltip title={t('lable.edit')}>
            <Link to={`/survey/${refId}/edit`}>
              <Button
                className="border-transparent"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Link>
          </Tooltip>
        )
      }
    }
  ]

  return (
    <Flex
      style={{ maxHeight: `${maxHeight}px` }}
      vertical
      gap="middle"
      className="min-w-[980px]"
    >
      <FormProvider {...methods}>
        <Form className="" onKeyDown={handleKeyDownDefault}>
          <Row justify="space-between">
            <div>
              <div className="inline-block mr-4 mb-0">
                <Typography.Text strong className="text-[16px]">
                  {t('medicalInterview')}
                </Typography.Text>
              </div>
              <div className="inline-block mb-0 mr-2 w-[300px]">
                <Controller
                  name="q"
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      prefix={<SearchOutlined />}
                      placeholder={t('placeholder.titleQuestionnaire')}
                      // defaultValue={getValues('q')}
                      // onChange={(e) => setValue('q', e.target.value)}
                    />
                  )}
                />
              </div>

              <div className="inline-block mb-0 mr-2 w-[260px]">
                <Flex className="mr-[6px]">
                  <DropdownList
                    name="department"
                    options={subTenantCodes.map(
                      (s: string): CheckboxItemType => {
                        return { label: s, value: s }
                      }
                    )}
                  />
                </Flex>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                onClick={() => {
                  setValue('page', 0)
                  onSearch()
                }}
                autoInsertSpace={false}
                className="font-bold border-none shadow-none rounded-none"
              >
                {t('placeholder.search')}
              </Button>
            </div>
            <div style={{ textAlign: 'right' }}>
              <Space size="small">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  onClick={handleExport}
                  className="w-[100px] bg-gradient-to-r from-[#137695] to-[#93D1CA] font-bold border-none shadow-none"
                >
                  {t('button.export_CSV')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="px-2 bg-gradient-to-r from-[#137695] to-[#93D1CA] font-bold border-none shadow-none"
                  onClick={() => navigateQuestionnaireCreationPage()}
                >
                  {t('createMedicalQuestionnaire')}
                </Button>
              </Space>
            </div>
          </Row>
        </Form>
      </FormProvider>

      <>
        <div>
          <Flex
            vertical
            className="bg-white rounded-[5px] shadow p-[10px] gap-[8px] mb-5"
            style={{
              boxShadow: '0px 3px 5px #00000029'
            }}
          >
            <Flex justify="space-between" className="justify-end">
              <Flex justify="flex-end" align="center">
                <Typography.Text className="float-right mr-2 text-[12px]">
                  {t('lable.displayedResultNumber')}
                </Typography.Text>
                <Select
                  labelInValue
                  suffixIcon={
                    <CaretDownOutlined className="text-[#137695] text-[14px]" />
                  }
                  value={{
                    value: `${getValues('perPage')}`,
                    label: `${getValues('perPage')}${t('lable.ken')}`
                  }}
                  onChange={onChangePagination}
                  options={[
                    { value: '100', label: `100${t('lable.ken')}` },
                    { value: '50', label: `50${t('lable.ken')}` },
                    { value: '200', label: `200${t('lable.ken')}` },
                    { value: '500', label: `500${t('lable.ken')}` }
                  ]}
                />
              </Flex>
            </Flex>
            <Flex vertical gap={16}>
              <Table
                size="small"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection
                }}
                columns={SurveyListColumns}
                dataSource={dataSource}
                showSorterTooltip={{ target: 'sorter-icon' }}
                pagination={false}
                className="survey-list-table"
                loading={loading}
                scroll={{
                  // x: 1200,
                  y: 'calc(100vh - 250px)'
                }}
              />
            </Flex>
          </Flex>

          {!loading && (
            <PaginationCustom
              onChangePage={onChangePage}
              currentPage={getValues('page')}
              total={total}
              onGoToFirstPage={goToFirstPage}
              onGoToLastPage={() => goToLastPage(total)}
              pageSize={getValues('perPage')}
            />
          )}
        </div>
      </>
    </Flex>
  )
}
