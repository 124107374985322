import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, Input } from 'antd'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as EmailIcon } from 'assets/svgs/email.svg'
import { openNotification } from 'components/widgets/Notification'
import { API_FORGOT_PASSWORD } from 'configs/api'
import { STATUS_CODE_SUCCESS } from 'configs/constant'
import networkAdapter from 'utilities/networkAdapter'

export type InputFormType = {
  email: string
}

const schema = yup
  .object()
  .shape({
    email: yup.string().required()
  })
  .required()

export default function ForgotPassWord() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<InputFormType>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: InputFormType) => {
    const response = await networkAdapter.post(
      API_FORGOT_PASSWORD,
      JSON.stringify(data)
    )

    if (response?.status === STATUS_CODE_SUCCESS) {
      navigate('/password-verify-code', { state: { email: data.email } })
    } else {
      openNotification({
        type: 'error',
        title: 'commonError',
        message: ''
      })
    }
  }

  return (
    <div className="pt-[273px] text-center text-sm tracking-wider">
      <h3 className="text-primary text-2xl font-bold">{t('forgotPassword')}</h3>
      <form className="mt-[91px]" onSubmit={handleSubmit(onSubmit)}>
        <Flex vertical className="gap-5">
          <label htmlFor="input" className="cursor-pointer text-[#707070]">
            {t('lable.emailRegister')}
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="input"
                status={errors.email ? 'error' : ''}
                placeholder={t('placeholder.emailAddress')}
                prefix={<EmailIcon className="w-[18px] mr-2.5" />}
                className="w-[400px] px-[13px] h-10 mx-auto"
              />
            )}
          />
        </Flex>
        <button
          type="submit"
          className="mt-[100px] text-white bg-primary min-w-[200px] py-2 px-10 min-h-10 font-bold"
        >
          {t('sendEmail')}
        </button>
      </form>
    </div>
  )
}
