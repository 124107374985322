import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Flex,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
  Typography
} from 'antd'

import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { Options } from 'types/common'
import { SurveyList } from 'types/courseRegister'
import { preventEnterKeyDefault } from 'utilities/helpers'

export default function CourseRelatedInterviewList({
  setEdit,
  surveys,
  setSurveysSelected,
  initSurveys
}: {
  setEdit: () => void
  surveys: SurveyList[]
  setSurveysSelected: (surveyList: SurveyList[]) => void
  initSurveys: React.Key[] | null
}) {
  const { t } = useTranslation()
  const [surveyNameOptions, setSurveyNameOptions] = useState<Options[]>([])
  const [dataTable, setDataTable] = useState<SurveyList[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRows, setSelectedRows] = useState<SurveyList[]>([])
  const { control, getValues, setValue } = useFormContext()

  useEffect(() => {
    if (surveys?.length > 0) {
      const options = surveys.map((survey: SurveyList) => ({
        key: survey.id,
        label: survey.questionnaireTitle,
        value: survey.id
      }))

      setSurveyNameOptions(options)
    }
  }, [surveys])

  const columns: TableColumnsType<SurveyList> = [
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-[50px]">
            {t('table.questionnaireTitle')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'questionnaireTitle',
      key: 'questionnaireTitle',
      width: '30%',
      render: (_, record) => (
        <Typography className="text-[#137695] font-bold text-left">
          {record.questionnaireTitle}
        </Typography>
      )
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-9">
            {t('lable.department')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'medicalDepartment',
      key: 'medicalDepartment',
      width: '29%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('lable.author')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'creator',
      key: 'creator',
      width: '29%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-4">
            {t('lable.createdAt')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      width: '10%'
    }
  ]

  useEffect(() => {
    if (surveys?.length === 0 || !initSurveys) return
    setValue('medicalQuestionnaire', initSurveys)
    handleDataTable()
    setSelectedRowKeys(initSurveys)

    const selectedRowsInit = surveys.filter((survey: SurveyList) =>
      initSurveys.includes(survey.id)
    )
    setSelectedRows(selectedRowsInit)
    setSurveysSelected(selectedRowsInit)
  }, [initSurveys, surveys])

  const handleDataTable = () => {
    const dataSelected = getValues('medicalQuestionnaire') ?? []
    if (dataSelected.length === 0) return

    const dataTableClone: SurveyList[] = [...dataTable]

    const dataTableCloneIds = new Set(
      dataTable.map((survey: SurveyList) => survey.id)
    )

    dataSelected.forEach((item: string) => {
      if (!dataTableCloneIds.has(item)) {
        const itemPush = surveys.find(
          (survey: SurveyList) => survey.id === item
        )
        if (itemPush) {
          dataTableClone.push(itemPush)
          dataTableCloneIds.add(item)
        }
      }
    })

    setDataTable(dataTableClone)
    setValue('medicalQuestionnaire', [])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], rowsSelected: SurveyList[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(rowsSelected)
    }
  }

  const handleSelectedSurveys = () => {
    setSurveysSelected(selectedRows)
    setEdit()
  }

  return (
    <>
      <div className="bg-white py-4 px-2.5 rounded-md mt-2.5">
        <Flex className="gap-3">
          <Controller
            name="medicalQuestionnaire"
            control={control}
            render={({ field }) => (
              <Select
                maxTagCount="responsive"
                {...field}
                mode="multiple"
                className="w-full max-w-[424px] courseSelect"
                suffixIcon={
                  <SearchOutlined className="absolute top-[-2px] right-[-4px] cursor-pointer" />
                }
                style={{ width: '100%' }}
                placeholder={t('placeholder.searchMedicalQuestionnaire')}
                defaultValue={[]}
                options={surveyNameOptions}
                popupClassName="coursePopupSelect"
                menuItemSelectedIcon={null}
                onInputKeyDown={preventEnterKeyDefault}
                optionFilterProp="label"
                optionRender={(option) => {
                  const itemCheck = option.value ?? ''
                  return (
                    <Space>
                      <Checkbox
                        key={option.key}
                        checked={field.value.includes(itemCheck.toString())}
                        onKeyDown={preventEnterKeyDefault}
                      ></Checkbox>
                      <Typography.Text className="text-[14px]">
                        {option.label}
                      </Typography.Text>
                    </Space>
                  )
                }}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{
                      pointerEvents: 'none'
                    }}
                    title={omittedValues.map(({ label }) => label).join('、')}
                  >
                    <span>+ {omittedValues.length} ...</span>
                  </Tooltip>
                )}
              ></Select>
            )}
          />

          <Button
            autoInsertSpace={false}
            type="primary"
            className="min-w-[120px] font-bold"
            onClick={() => handleDataTable()}
          >
            {t('placeholder.search')}
          </Button>
        </Flex>

        <Table
          rowSelection={{
            type: 'radio',
            ...rowSelection
          }}
          rowKey="id"
          columns={columns}
          dataSource={dataTable}
          pagination={false}
          className="course-register-list-table mt-3"
          scroll={{
            y: '415px'
          }}
        />
      </div>
      <Button
        autoInsertSpace={false}
        type="primary"
        className="min-w-[120px] font-bold mt-5"
        disabled={selectedRows.length === 0}
        onClick={() => handleSelectedSurveys()}
      >
        {t('button.confirm')}
      </Button>
    </>
  )
}
