import { useLazyQuery } from '@apollo/client'
import { FILTER_ITEM_CATEGORY } from 'graphql/ItemCategory/filterItemCategory'
import { FILTER_ITEM_MASTER } from 'graphql/ItemMaster/filterItemMaster'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import { StringKeyObject } from 'types/common'
import { Endpoint } from 'utilities/apolloClient'

const useQueryForCourseTestItem = () => {
  const [queryTestItemCategory] = useLazyQuery(FILTER_ITEM_CATEGORY)

  const [queryTestItemMaster] = useLazyQuery(FILTER_ITEM_MASTER)

  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const getListItemCategory = async (variables?: StringKeyObject) => {
    try {
      const result = await queryTestItemCategory({
        variables: {
          filter: '(eq,STRING,status,PUBLISHED)',
          page: 0,
          size: -1,
          sortBy: '',
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  const getListItemMaster = async (variables?: StringKeyObject) => {
    try {
      const result = await queryTestItemMaster({
        variables: {
          filter:
            '(eq,STRING,status,PUBLISHED);(ne,STRING,additionalInfo.key3,所見コメント)',
          page: 0,
          size: -1,
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListItemCategory,
    getListItemMaster,
    getMedicalCheckupMastersById
  }
}

export default useQueryForCourseTestItem
