import React from 'react'

import InfoRow, { InfoRowProps } from './InfoRow'

interface PersonalInfoProps {
  data: InfoRowProps[]
}

const TablePatientInfo: React.FC<PersonalInfoProps> = ({ data }) => {
  return (
    <div className="w-full bg-white rounded-lg shadow-md">
      <table
        className="w-full border-separate table-fixed"
        style={{ borderSpacing: '0 10px' }}
      >
        <tbody>
          {data.map((item, index) => (
            <InfoRow
              key={`${item.label}-${index}`}
              label={item.label}
              value={item.value}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TablePatientInfo
