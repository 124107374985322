import { FunctionComponent, SVGProps, useCallback } from 'react'

import { atom, useRecoilState } from 'recoil'

import { ReactComponent as HospitalActivatedIcon } from 'assets/svgs/hospital-activated.svg'
import { ReactComponent as HospitalIcon } from 'assets/svgs/hospital.svg'
import { TAB_FILTER_MESSAGES } from 'configs/constant'

interface TabFilterMessages {
  items: ItemsType[]
  setItems: (items: ItemsType[]) => void
  changeTab: (id: string) => void
}

export type ItemsType = {
  id: TAB_FILTER_MESSAGES
  label: string
  isActive: boolean
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  activatedIcon: FunctionComponent<SVGProps<SVGSVGElement>>
}

const initialItemsDefault: ItemsType[] = [
  // {
  //   id: TAB_FILTER_MESSAGES.ALL,
  //   label: 'all',
  //   isActive: true,
  //   icon: AllIcon,
  //   activatedIcon: AllActivatedIcon
  // },
  {
    id: TAB_FILTER_MESSAGES.RESERVATION,
    label: 'reservation',
    isActive: true,
    icon: HospitalIcon,
    activatedIcon: HospitalActivatedIcon
  }
  // {
  //   id: TAB_FILTER_MESSAGES.SUPPORT,
  //   label: 'support',
  //   isActive: false,
  //   icon: ChatIcon,
  //   activatedIcon: ChatActivatedIcon
  // }
]

export const tabsAtom = atom<ItemsType[]>({
  key: 'tabsAtom',
  default: initialItemsDefault
})

const useTabFilterMessages = (): TabFilterMessages => {
  const [items, setItems] = useRecoilState(tabsAtom)

  const changeTab = useCallback((id: string) => {
    setItems((prev) =>
      prev.map((item) => ({ ...item, isActive: item.id === id }))
    )
  }, [])

  return { items, setItems, changeTab }
}

export default useTabFilterMessages
