import React, { FC } from 'react'

import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import avatar from 'assets/pngs/avatar.png'
import { ReactComponent as Pen } from 'assets/svgs/pen.svg'
import { CustomerDetail } from 'hooks/useCustomer'
import { TAB } from 'models/customer'

type Props = {
  t: TFunction<'translation', undefined>
  data?: CustomerDetail
  isOpenEditForm: boolean
  onOpenEditForm: () => void
  setTab: (tab: string) => void
  onNext: () => void
  onPrevious: () => void
  isFirstRecord: boolean
  isLastRecord: boolean
}

const CustomerDetailHeader: FC<Props> = ({
  t,
  data,
  isOpenEditForm,
  onOpenEditForm,
  setTab,
  onNext,
  onPrevious,
  isFirstRecord,
  isLastRecord
}) => {
  const openPreviousRecord = () => {
    onPrevious()
  }
  const openNextRecord = () => {
    onNext()
  }

  const medicalStatus = data?.medicalStatus

  return (
    <Flex className="w-full h-[80px] min-w-[1700px] rounded-[8px] bg-white px-[22px] text-[14px] text-[#545454] drop-shadow-md">
      {/*1*/}
      <Flex className="mr-[12px] mt-[2px] h-[77px] w-[77px] items-center justify-center">
        <img src={data && data.avatar ? data.avatar : avatar} alt="" />
      </Flex>

      {/*2*/}
      <Flex className="w-full items-center justify-between">
        <Flex className="flex-col justify-center">
          <Typography className="text-[16px] font-bold">{data?.id}</Typography>
          <Flex className="flex-row">
            <Typography className="mr-[70px] text-[16px] font-bold tracking-[1.6px] whitespace-nowrap">
              {data?.firstName}
              {data?.lastName}
            </Typography>
            <Typography className="mr-[20px] font-medium tracking-[1.4px] whitespace-nowrap">
              {`${dayjs(data?.birthday, 'YYYY-MM-DD').format('YYYY/MM/DD')}`}
            </Typography>
            <Typography className="mr-[20px] font-medium tracking-[1.4px] whitespace-nowrap">
              {data?.age}
              {t('customerDetail.header.age')}
            </Typography>
            <Typography className="mr-[206px] font-medium tracking-[1.4px] whitespace-nowrap">
              (
              {data && data.gender === 'MALE'
                ? t('customerDetail.header.male')
                : t('customerDetail.header.female')}
              )
            </Typography>
            <Typography
              style={{
                background: `${
                  medicalStatus == 0 || medicalStatus == 1
                    ? '#BDCBD5'
                    : medicalStatus == 2
                      ? '#137695'
                      : medicalStatus == 3
                        ? '#DF2475'
                        : '#545454'
                }`
              }}
              className="px-2 text-nowrap mr-[26px] flex h-[26px] items-center justify-center rounded-[23px] bg-[#BDCBD5] text-center text-[14px] tracking-[1.4px] text-white"
            >
              {medicalStatus == 0
                ? t('customerManagement.result.incompatible')
                : medicalStatus == 1
                  ? t('customerManagement.result.creating')
                  : medicalStatus == 2
                    ? t('customerManagement.result.waiting')
                    : medicalStatus == 3
                      ? t('customerManagement.result.ended')
                      : t('customerManagement.result.sent')}
            </Typography>
            <Flex align="center">
              <Typography className="font-medium tracking-[1.4px] whitespace-nowrap">
                {t('customerDetail.header.patientId')}：
              </Typography>
              {data?.patientId ? (
                <Typography className="font-medium tracking-[1.4px] whitespace-nowrap mr-[70px]">
                  {data?.patientId}
                </Typography>
              ) : (
                <div className="ml-[10px] h-[1px] w-[10px] bg-[#545454]" />
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.34"
            height="24.563"
            viewBox="0 0 10.34 24.563"
            onClick={openPreviousRecord}
            style={{
              cursor: isFirstRecord ? 'not-allowed' : 'pointer',
              marginRight: '30px'
            }}
          >
            <path
              id="パス_40275"
              data-name="パス 40275"
              d="M-11464.668-11900l-7.721,10.887,7.721,10.889"
              transform="translate(11473.614 11901.394)"
              fill="none"
              stroke={isFirstRecord ? '#BDCBD5' : '#137695'}
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.34"
            height="24.561"
            viewBox="0 0 10.34 24.561"
            onClick={openNextRecord}
            style={{ cursor: isLastRecord ? 'not-allowed' : 'pointer' }}
          >
            <path
              id="パス_39487"
              data-name="パス 39487"
              d="M-11472.389-11900l7.721,10.886-2.574,3.63-5.146,7.258"
              transform="translate(11473.782 11901.394)"
              fill="none"
              stroke={isLastRecord ? '#BDCBD5' : '#137695'}
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CustomerDetailHeader
