import React, { useCallback, useEffect } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Input, Popover, Row, Typography } from 'antd'

import ModalConfirmDeletedItem from './ModalConfirmDeletedItem'
import ModalRenderList from './ModalRenderList'
import PreviewItems from './PreviewItems'
import RenderList from './RenderList'
import { QuestionCircleFilled } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { ReactComponent as TrashIcon } from 'assets/imgs/trash.svg'
import transferIcon from 'assets/pngs/transferIcon.png'
import { ReactComponent as SearchOutlinedIcon } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as AddItemIcon } from 'assets/svgs/plus–42.svg'
import { FILTER_ITEM_MASTER } from 'graphql/ItemMaster/filterItemMaster'
import useTestItemMaster from 'hooks/useTestItemMaster'
import { Category, FilterTestItemMaster } from 'types/FilterTestItemMaster'
import { StringKeyObject } from 'types/common'

export enum ENumberArray {
  FIRST,
  SECOND
}

export enum Position {
  RIGHT = 'right',
  LEFT = 'left'
}

const InspectionItems = ({
  listCategory,
  setListCategory,
  associatedTestItemMasters = undefined,
  isEdit,
  isPreview,
  setIsPreview
}: {
  listCategory?: Category[]
  setListCategory?: (listCategory: Category[]) => void
  associatedTestItemMasters?: string[]
  isEdit?: boolean
  isPreview: boolean
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  // call api get item with associated test
  const [getItemWithAssociatedTest, { data: dataItems }] =
    useLazyQuery<FilterTestItemMaster>(FILTER_ITEM_MASTER)

  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  // Split initial data between left and right lists
  const [leftCategories, setLeftCategories] = React.useState<Category[]>(
    listCategory ?? []
  )
  const [searchValue, setSearchValue] = React.useState<string>('')

  // Split initial data between left and right lists
  const [rightCategories, setRightCategories] = React.useState<Category[]>([])
  const [originalItems, setOriginalItems] = React.useState<Category[]>([])
  const [openModal, setOpenModal] = React.useState(false)
  const [openModalDeletedItems, setOpenModalDeletedItems] =
    React.useState(false)
  const [listDeletedItem, setListDeletedItem] = React.useState<Category[]>([])

  // get list item master
  const fetchList = useCallback(async () => {
    const data = await getListItemMasterByMultipleLanguages([])
    const listItem = data
      ?.filter(
        (it: { additionalInfo: StringKeyObject }) =>
          it.additionalInfo.key1 === '検査項目マスター'
      )
      ?.map((it: Category) => ({ ...it, isChecked: false }))
    setOriginalItems(listItem)
  }, [])

  useEffect(() => {
    if (associatedTestItemMasters !== undefined) {
      fetchList()
    }
  }, [associatedTestItemMasters, fetchList])

  // Sync left and right lists
  useEffect(() => {
    setListCategory?.(leftCategories)
  }, [leftCategories, setListCategory])

  // handle logic set right list categories when have dataFilterItemMaster change

  useEffect(() => {
    if (associatedTestItemMasters && associatedTestItemMasters.length > 0) {
      getItemWithAssociatedTest({
        variables: {
          filter: `(eq,STRING,status,PUBLISHED);(in,STRING,refId,${associatedTestItemMasters.join(',')})`,
          page: 0,
          size: -1
        }
      })
    }
  }, [associatedTestItemMasters, getItemWithAssociatedTest])

  useEffect(() => {
    if (isPreview) setSearchValue('')
  }, [isPreview])

  useEffect(() => {
    if (dataItems) {
      setLeftCategories?.(dataItems.filterTestItemMaster.payload)
    }
  }, [dataItems, setLeftCategories])

  const changeIndexWithArray = <T,>(
    sourceIndex: number,
    destinationIndex: number,
    array?: T[]
  ): T[] => {
    if (!array) return []
    const category = [...array]
    const movedItem = category[sourceIndex]
    category.splice(sourceIndex, ENumberArray.SECOND)
    category.splice(destinationIndex, ENumberArray.FIRST, movedItem)

    return category
  }

  const addItemToArray = <T,>(item: T, index: number, array?: T[]): T[] => {
    if (!array) return []
    const items = [...array]
    items.splice(index, ENumberArray.FIRST, item)
    return items
  }

  const getLists = (isSourceLeft: boolean) => ({
    sourceList: isSourceLeft ? leftCategories : rightCategories,
    destinationList: isSourceLeft ? rightCategories : leftCategories,
    setSourceList: isSourceLeft ? setLeftCategories : setRightCategories,
    setDestinationList: isSourceLeft ? setRightCategories : setLeftCategories
  })

  const removeItemImmutably = <T,>(array: T[], index: number) =>
    array.filter((_, idx) => idx !== index)

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result
    if (!destination) return

    const isRoot = draggableId.includes('_root')
    const isSourceLeft = source.droppableId.includes('left')

    const { sourceList, destinationList, setSourceList, setDestinationList } =
      getLists(isSourceLeft)

    if (isSourceLeft === destination.droppableId.includes('left')) {
      if (isRoot) {
        setSourceList(
          changeIndexWithArray(source.index, destination.index, sourceList)
        )
      }
    } else {
      // Handle cross-list movement
      if (isRoot) {
        // If the root is moved
        const movedItem = sourceList?.[source.index]
        const newSourceList = sourceList.filter(
          (it) => !destinationList.map((it) => it.refId).includes(it.refId)
        )
        const newList = removeItemImmutably(newSourceList, source.index)
        setSourceList(newList)
        setDestinationList(
          addItemToArray(movedItem, destination.index, destinationList)
        )
      }
    }

    setListCategory?.(leftCategories)
  }

  const handleSelectedItems = (items: Category[]) => {
    setRightCategories((prev) => {
      const newItems = items
        .filter((it) => !prev.map((it) => it.refId).includes(it.refId))
        .map((it) => ({ ...it, isChecked: false }))
      const oldItems = items.filter((it) =>
        prev.map((it) => it.refId).includes(it.refId)
      )
      return [...oldItems, ...newItems]
    })
  }

  const handleChecked = (items: Category, checked: boolean) => {
    setListDeletedItem((prev) => {
      if (checked) {
        return [...prev, items]
      } else {
        return prev.filter((it) => it.refId !== items.refId)
      }
    })
    setRightCategories((prev) => {
      return prev.map((item) => ({
        ...item,
        isChecked: item.refId === items.refId ? checked : item.isChecked
      }))
    })
  }
  const handlePreviewData = useCallback(() => {
    setIsPreview((prev) => !prev)
  }, [setIsPreview])

  const handleRestore = useCallback(() => {
    setLeftCategories?.(
      originalItems.filter((it) =>
        associatedTestItemMasters?.includes(it.refId)
      )
    )
    setRightCategories?.([])
  }, [associatedTestItemMasters, originalItems])

  const handleOpenModal = useCallback(() => {
    setOpenModal((p) => !p)
  }, [])

  const handleDeletedItems = useCallback(() => {
    setRightCategories((prev) => {
      return prev.filter(
        (it) => !listDeletedItem.map((it) => it.refId).includes(it.refId)
      )
    })
    setListDeletedItem([])
    setOpenModalDeletedItems((p) => !p)
  }, [listDeletedItem])

  return (
    <>
      <Flex className="pt-[30px] pl-2 tracking-[.7px] pb-4">
        <Flex className="items-center" gap={16}>
          <Flex gap={8}>
            <Typography className="font-bold">
              {t('optionForm.inspectionItems')}
            </Typography>
            {isPreview && (
              <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white">
                {t('lable.required')}
              </Typography>
            )}
          </Flex>
          {!isPreview && (
            <Flex className="flex-1">
              <div className="flex-1">
                <Input
                  placeholder={t('optionForm.search')}
                  className="w-[424px]"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  prefix={<SearchOutlinedIcon className="w-4 mr-1" />}
                />
              </div>
            </Flex>
          )}
        </Flex>
      </Flex>
      {!isPreview && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="flex w-full items-center justify-between gap-4">
            <Row className="w-full">
              <Col span={13}>
                <div className="bg-white w-full rounded-lg pt-2.5  shadow-md">
                  <Flex className="pr-6 pl-2.5 justify-between">
                    <Typography className="font-bold tracking-[.7px]">
                      {t('setInspection')}
                    </Typography>
                    <Button
                      autoInsertSpace={false}
                      type="primary"
                      className="w-[120px]"
                      onClick={() => handlePreviewData()}
                    >
                      {t('button.confirm')}
                    </Button>
                  </Flex>
                  <RenderList
                    droppableIdPrefix={Position.LEFT}
                    items={leftCategories}
                    isShowCode
                    searchTerm={searchValue}
                  />
                </div>
              </Col>
              <Col span={1}>
                <Flex className="flex items-center h-full">
                  <img
                    src={transferIcon}
                    className="w-[78.9%] m-auto"
                    alt="transferIcon"
                  />
                </Flex>
              </Col>
              <Col span={10}>
                <div className="bg-white w-full rounded-lg pt-2.5 pb-4 shadow-md">
                  <Flex className="pr-6 pl-2.5 justify-between ">
                    <Flex align="center" gap={4}>
                      <Typography className="font-bold tracking-[.7px]">
                        {t('currentHiddenTest')}
                      </Typography>
                      <Popover
                        placement="top"
                        content={
                          <div className="p-2 w-[360px] bg-[#545454] text-white rounded">
                            {t('textIntroduce')}
                          </div>
                        }
                        overlayClassName="popover-permission"
                      >
                        <QuestionCircleFilled className="text-sm text-[#B4B4B4] " />
                      </Popover>
                    </Flex>
                    <Flex align="center" gap={8}>
                      <Popover
                        placement="top"
                        content={
                          <div className="p-2 bg-[#545454] text-white rounded">
                            {t('button.delete')}
                          </div>
                        }
                        overlayClassName="popover-permission"
                      >
                        <TrashIcon
                          className="cursor-pointer w-4"
                          onClick={() =>
                            setOpenModalDeletedItems(listDeletedItem.length > 0)
                          }
                        />
                      </Popover>
                      <Button
                        autoInsertSpace={false}
                        variant="outlined"
                        shape="round"
                        type="text"
                        className=" text-primary border-primary font-bold"
                        onClick={() => handleRestore()}
                      >
                        {t('restoreDefaultSetting')}
                      </Button>
                    </Flex>
                  </Flex>
                  <RenderList
                    allowCheckbox
                    droppableIdPrefix={Position.RIGHT}
                    items={rightCategories}
                    onChecked={handleChecked}
                    searchTerm={searchValue}
                  />
                  <div className="mt-4 px-2">
                    <Flex className="gap-[5px] mt-[14px] cursor-pointer w-max">
                      <AddItemIcon
                        className="bg-[#137695] h-[20px] w-[20px] rounded-sm"
                        onClick={handleOpenModal}
                      />
                      <Typography className="text-[14px] text-[#137695] font-semibold">
                        {t('newTestItemsAdded')}
                      </Typography>
                    </Flex>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </DragDropContext>
      )}
      {isPreview && (
        <PreviewItems items={leftCategories} onEdit={handlePreviewData} />
      )}

      {openModal && (
        <ModalRenderList
          originalItems={originalItems}
          initialRightItems={rightCategories.map((it) => it.refId)}
          initialLeftItems={leftCategories.map((it) => it.refId)}
          isModalVisible
          handleCancel={handleOpenModal}
          handleOK={handleSelectedItems}
        />
      )}

      {openModalDeletedItems && (
        <ModalConfirmDeletedItem
          items={listDeletedItem}
          onCancel={() => setOpenModalDeletedItems(false)}
          onOk={handleDeletedItems}
        />
      )}
    </>
  )
}

export default InspectionItems
