import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Select } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import { generateTenYearOptions } from 'utilities/helpers'

const { Option } = Select

const YearMonthSelector = ({
  dateSelected,
  setDateSelected
}: {
  dateSelected: { year: string; month: string }
  setDateSelected: Dispatch<SetStateAction<{ year: string; month: string }>>
}) => {
  const { t } = useTranslation()

  const years = useMemo(() => generateTenYearOptions(), [])

  const months = Array.from({ length: 12 }, (_, i) => i + 1)

  const handleYearChange = (value: number) => {
    setDateSelected((prev) => ({
      ...prev,
      year: String(value)
    }))
  }

  const handleMonthChange = (value: number) => {
    setDateSelected((prev) => ({
      ...prev,
      month: String(value)
    }))
  }

  return (
    <Row gutter={8} align="middle">
      <Col>
        <Select
          suffixIcon={
            <CaretDownOutlined
              style={{ pointerEvents: 'none' }}
              className="text-[#137695] text-[16px]"
            />
          }
          value={Number(dateSelected.year)}
          onChange={handleYearChange}
          style={{ width: 106 }}
        >
          {years.map((yr) => (
            <Option key={yr} value={yr}>
              {yr}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <span>{t('dashboard.date.year')}</span>
      </Col>
      <Col>
        <Select
          suffixIcon={
            <CaretDownOutlined
              style={{ pointerEvents: 'none' }}
              className="text-[#137695] text-[16px]"
            />
          }
          value={Number(dateSelected.month)}
          onChange={handleMonthChange}
          style={{ width: 106 }}
        >
          {months.map((mn) => (
            <Option key={mn} value={mn}>
              {mn}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <span>{t('dashboard.date.month')}</span>
      </Col>
    </Row>
  )
}

export default YearMonthSelector
