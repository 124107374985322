import React, { useCallback, useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import ModalConfirm from '../../../../components/modals/ModalConfirm'
import useCourseTestItems from '../hooks/useCourseTestItems'
import ContentCategoryHiddenList from './ContentCategoryHiddenList'
import ContentCategoryList from './ContentCategoryList'
import MenuCategoryList from './MenuCategoryList'
import { initialListCategory } from './helper'
import transferIcon from 'assets/pngs/transferIcon.png'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import useTestItemMaster from 'hooks/useTestItemMaster'
import ModalRenderItemList from 'pages/InspectionItemSetting/components/ModalRenderItemList'
import { ELanguage, showName } from 'pages/InspectionItemSetting/helper'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'
import { getLanguage } from 'utilities/helpers'

const CourseTestItemWrapper = ({
  setEdit,
  setListData: setListDataBelongToCourse,
  originalMedicalCheckupMaster
}: {
  originalMedicalCheckupMaster?: MedicalCheckupMaster
  setEdit: () => void
  setListData: React.Dispatch<
    React.SetStateAction<{
      listCategoryShow: DataItemMaster[]
      listCategoryHidden: DataItemMaster[]
      originalListCategory: DataItemMaster[]
      originalListTestItem: DataItemMaster[]
    }>
  >
}) => {
  const { t } = useTranslation()
  const {
    isModalDeleteConfirm,
    setIsModalDeleteConfirm,
    isModalRestoreConfirm,
    setIsModalRestoreConfirm,
    isShowAddTestItem,
    setIsShowAddTestItem,
    isShowAddCategoryItem,
    setIsShowAddCategoryItem,
    //
    originalListCategory,
    originalListTestItem,
    originalMedicalCheckupCourse,
    setListData,
    handleSetInitialData,
    //
    listCategory,
    listTestItem,
    //
    initialShowCategoryRefIds,
    initialHiddenCategoryRefIds,
    initialHiddenItemRefIds,
    initialShowItemRefIds,
    categoryHidden,
    setCategoryHidden,
    categoryShow,
    setCategoryShow,
    onDragEnd
  } = useCourseTestItems()

  const language = getLanguage()
  const [{ listDeleteRefId, ListDeleteItems }, setListDelete] = React.useState<{
    listDeleteRefId: string[]
    ListDeleteItems: DataItemMaster[]
  }>({
    listDeleteRefId: [],
    ListDeleteItems: []
  })

  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  // set list category send to parent then show preview list
  useEffect(() => {
    setListDataBelongToCourse({
      listCategoryShow: categoryShow ?? [],
      listCategoryHidden: categoryHidden ?? [],
      originalListCategory: originalListCategory,
      originalListTestItem: originalListTestItem
    })
  }, [
    categoryShow,
    categoryHidden,
    originalListCategory,
    originalListTestItem,
    setListDataBelongToCourse
  ])

  const fetchListItemsWithCheckupMaster = useCallback(
    async (medicalCheckupMasterObjId: string) => {
      // Fetch list items with medical checkup master object id
      try {
        return await getListItemMasterByMultipleLanguages([
          {
            type: 'MATCH',
            criteria: {
              and: [
                {
                  field: 'additionalInfo.key3',
                  operator: 'ne',
                  value: '所見コメント',
                },
                {
                  field: 'additionalInfo.medicalCheckupMasterObjId',
                  operator: 'eq',
                  value: medicalCheckupMasterObjId,
                },
              ],
            },
          },
        ]) as DataItemMaster[];
      } catch (error) {
        console.error('Error fetching list items:', error);
        return [];
      }
    },
    [] // Dependencies for useCallback
  );


  useEffect(() => {
    const fetchData = async () => {
      const mediCalCheckupData = originalMedicalCheckupMaster
        ? originalMedicalCheckupMaster
        : originalMedicalCheckupCourse;
      let listItemsWithCheckupMaster: DataItemMaster[] = [];
      if (originalMedicalCheckupMaster) {
        listItemsWithCheckupMaster = await fetchListItemsWithCheckupMaster(
          originalMedicalCheckupMaster.additionalInfo.objId
        );
      }
      if (mediCalCheckupData) {
        const { categories, excludedCategories } = mediCalCheckupData.additionalInfo || {};

        let newListCategoryShow = initialListCategory(
          categories,
          originalListCategory,
          listItemsWithCheckupMaster.length > 0 ? listItemsWithCheckupMaster : originalListTestItem
        );
        const newListCategoryHidden = initialListCategory(
          excludedCategories,
          originalListCategory,
          originalListTestItem
        );
        setCategoryShow(newListCategoryShow);
        setCategoryHidden(newListCategoryHidden);
      }
    };

    fetchData();
  }, [
    originalListCategory,
    originalListTestItem,
    originalMedicalCheckupCourse,
    originalMedicalCheckupMaster,
    fetchListItemsWithCheckupMaster, // Add this dependency
    setCategoryShow,
    setCategoryHidden,
  ]);

  useEffect(() => {
    handleSetInitialData({
      originalListCategory: originalListCategory,
      originalListTestItem: originalListTestItem,
      setListData: setListData
    })
  }, [
    handleSetInitialData,
    originalListCategory,
    originalListTestItem,
    originalMedicalCheckupMaster,
    setListData
  ])

  const handleSelectCategory = useCallback(
    (category: DataItemMaster[]) => {
      const selfCategoryHidden = categoryHidden ?? []
      const newCategories = (category ?? [])
        .filter(
          (it) => !([...selfCategoryHidden]).some((i) => i.refId === it.refId)
        )
        .map((it) => ({
          ...it,
          isChecked: false,
          items: it.items
            ?.map((i) => ({ ...i, isChecked: false }))
            .filter((i) => ![...initialHiddenItemRefIds, ...initialShowItemRefIds].includes(i.refId))
        }))

      setCategoryHidden(() => {
        const categories = [...selfCategoryHidden, ...newCategories].map((it) => ({
          ...it,
          items: it.items || []
        }))
        return categories
      })
    },
    [categoryHidden, initialHiddenItemRefIds, initialShowItemRefIds, setCategoryHidden]
  )

  const handleSelectTestItem = useCallback(
    (itemCategory: DataItemMaster[]) => {
      // Filter out items that should not be included based on `initialHiddenItemsMasterRefIds`

      setCategoryHidden((prev) => {
        return prev?.map((item) => {
          const newItem = itemCategory
            .filter((item) => ![...initialHiddenItemRefIds, ...initialShowItemRefIds].includes(item.refId))
            .map((it) => ({ ...it, isChecked: item.isChecked }))
          const isLastItem = prev?.[prev.length - 1]?.refId === item.refId
          if (isLastItem) {
            // Combine new items with existing items, ensuring uniqueness by `refId`
            const combinedItems = Array.from(
              new Set([
                ...newItem.map((it) => it.refId),
                ...(item.items || []).map((it) => it.refId)
              ])
            ).map((refId) => ({
              ...originalListTestItem?.find((i) => i.refId === refId),
              isChecked:
                item.items?.some((i) => i.refId === refId && i.isChecked) ||
                newItem.some((i) => i.refId === refId && i.isChecked)
            })) as DataItemMaster[]
            return {
              ...item,
              isChecked: item.isChecked,
              items: [...combinedItems]
            }
          }

          // Filter existing items to include only those present in `itemCategory`
          return {
            ...item
          }
        })
      })
    },
    [initialHiddenItemRefIds, originalListTestItem, initialShowItemRefIds, setCategoryHidden]
  )

  //
  const handleChecked = React.useCallback(
    (itemChecked: DataItemMaster, checked: boolean, isCheckAll?: boolean) => {
      setCategoryHidden((prev) =>
        prev.map((item) => {
          if (isCheckAll) {
            // Update the whole category when checking all
            if (item.refId !== itemChecked.refId) return item

            return {
              ...item,
              isChecked: checked,
              items: (item.items ?? []).map((subItem) => ({
                ...subItem,
                isChecked: checked
              }))
            }
          }

          // Update a specific item's checked state
          const updatedItems = (item.items ?? []).map((subItem) => ({
            ...subItem,
            isChecked:
              subItem.refId === itemChecked.refId ? checked : subItem.isChecked
          }))

          const isCategoryChecked = updatedItems.every(
            (subItem) => subItem.isChecked
          )

          return {
            ...item,
            isChecked: isCategoryChecked,
            items: updatedItems
          }
        })
      )
    },
    [setCategoryHidden]
  )

  const handleOpenModalDelete = React.useCallback(() => {
    const categoriesDelete = (categoryHidden ?? []).reduce(
      (
        acc: { arr: DataItemMaster[]; arrRefId: string[] },
        item: DataItemMaster
      ) => {
        if (item.isChecked) {
          acc.arr.push(item)
          acc.arrRefId.push(
            item.refId,
            ...(item.items || [])?.map((i) => i.refId)
          )
        } else {
          if (item.items?.length && item.items.some((i) => i.isChecked)) {
            const filterSubList = item.items.filter((i) => i.isChecked)
            acc.arrRefId.push(...filterSubList.map((i) => i.refId))
            acc.arr.push({
              ...item,
              items: filterSubList
            })
          }
        }

        return acc
      },
      { arr: [], arrRefId: [] }
    )
    setListDelete({
      ListDeleteItems: categoriesDelete.arr,
      listDeleteRefId: categoriesDelete.arrRefId
    })
    setIsModalDeleteConfirm(categoriesDelete.arrRefId.length > 0)
  }, [categoryHidden, setIsModalDeleteConfirm])

  const handleRestore = React.useCallback(() => {
    setIsModalRestoreConfirm(false)
    const initMedicalCheckupMaster =
      originalMedicalCheckupMaster ?? originalMedicalCheckupCourse
    if (initMedicalCheckupMaster) {
      const { categories } = initMedicalCheckupMaster?.additionalInfo || {}

      const newListCategoryShow = initialListCategory(
        Array.isArray(categories) ? categories : [],
        originalListCategory,
        originalListTestItem
      )

      setCategoryShow(newListCategoryShow)
    } else {
      setCategoryShow([])
    }
    setCategoryHidden([])
    setListDelete({
      ListDeleteItems: [],
      listDeleteRefId: []
    })
  }, [
    originalListTestItem,
    originalMedicalCheckupMaster,
    setCategoryHidden,
    setCategoryShow,
    setIsModalRestoreConfirm
  ])

  const handleDeleteItem = React.useCallback(() => {
    const filterList = [...(categoryHidden ?? [])].filter(
      (item) => !listDeleteRefId.includes(item.refId)
    )
    const newListHidden = filterList.map((item) => {
      if (item.items) {
        item.items = item.items.filter(
          (i) => !listDeleteRefId.includes(i.refId)
        )
      }

      return item
    })
    setCategoryHidden(newListHidden)
    setListDelete({
      ListDeleteItems: [],
      listDeleteRefId: []
    })
    setIsModalDeleteConfirm(false)
  }, [
    categoryHidden,
    listDeleteRefId,
    setCategoryHidden,
    setIsModalDeleteConfirm
  ])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex className={`pt-[30px] pl-2 tracking-[.7px] gap-2 items-center`}>
        <Typography className="font-bold">
          {t('courseRegister.inCourseTestItems')}
        </Typography>
        <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white">
          {t('lable.required')}
        </Typography>
      </Flex>
      <Flex className="mt-3">
        <Flex className="w-[55%] h-fit">
          <Flex className="w-[30%]">
            <MenuCategoryList categories={categoryShow} />
          </Flex>

          <div className="w-[70%]">
            <ContentCategoryList categories={categoryShow} setEdit={setEdit} />
          </div>
        </Flex>

        <Flex className="flex-1">
          <img
            src={transferIcon}
            className="w-[78.9%] m-auto"
            alt="transferIcon"
          />
        </Flex>

        <Flex className="w-[41.436%] border-primary">
          <ContentCategoryHiddenList
            categories={categoryHidden}
            handleOpenModalDelete={handleOpenModalDelete}
            setIsModalRestoreConfirm={setIsModalRestoreConfirm}
            setIsShowAddCategoryItem={setIsShowAddCategoryItem}
            setIsShowAddTestItem={setIsShowAddTestItem}
            onChecked={handleChecked}
          />
        </Flex>
      </Flex>
      {isModalDeleteConfirm && (
        <ModalConfirm
          isModalVisible
          title={t('button.delete')}
          classNameContent="w-[900px]"
          content={
            <>
              <div className="mb-11 w-full flex items-center justify-center">
                <div className="flex flex-col justify-center w-fit">
                  <p className="flex items-start text-default w-full">
                    {t('modalConfirm.infoConfirmDelete', {
                      count: listDeleteRefId.length
                    })}
                  </p>
                  <p className="flex items-start text-[#df2475] w-full">
                    {t('modalConfirm.contentConfirm')}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2 border max-h-[467px] overflow-auto">
                {ListDeleteItems?.map((category) => (
                  <div key={category.refId} className="flex flex-col gap-2">
                    {category.isChecked && (
                      <Flex className="grid grid-cols-2 bg-tableGray p-3">
                        <Typography className="col-span-1 text-default font-bold text-start">{`${showName(category, language) ?? VALUE_WITH_NO_DATA} `}</Typography>
                        <Typography className="col-span-1 text-default font-bold text-start">{`${showName(category, ELanguage.EN) ?? VALUE_WITH_NO_DATA} `}</Typography>
                      </Flex>
                    )}

                    {category.items?.map((item) => (
                      <Flex key={item.refId} className="grid grid-cols-2 px-3">
                        <Typography className="col-span-1 text-default text-start">{`${showName(item, language) ?? VALUE_WITH_NO_DATA} `}</Typography>
                        <Typography className="col-span-1 text-default text-start">{`${showName(item, ELanguage.EN) ?? VALUE_WITH_NO_DATA} `}</Typography>
                      </Flex>
                    ))}
                  </div>
                ))}
              </div>
            </>
          }
          handleCancel={() => setIsModalDeleteConfirm(false)}
          handleOk={() => handleDeleteItem()}
        />
      )}
      {isModalRestoreConfirm && (
        <ModalConfirm
          isModalVisible
          title={t('content.initTestItemSetting')}
          content={
            <div className="mb-32">
              <p>{t('content.initTestItemWarning1')}</p>
              <p>{t('content.initTestItemWarning2')}</p>
            </div>
          }
          handleCancel={() => setIsModalRestoreConfirm(false)}
          handleOk={() => handleRestore()}
          classNames={{
            classNameButtonCancel: '!border-primary !text-primary',
            classNameButtonOk: '!bg-primary '
          }}
        />
      )}
      {isShowAddCategoryItem && (
        <ModalRenderItemList
          open
          onOk={handleSelectCategory}
          onCancel={() => setIsShowAddCategoryItem(false)}
          listData={listCategory}
          title={t('newCategory')}
          titleButton={t('button.close')}
          initialItemShow={initialShowCategoryRefIds}
          initialItemHidden={initialHiddenCategoryRefIds}
        />
      )}
      {isShowAddTestItem && (
        <ModalRenderItemList
          open
          onOk={handleSelectTestItem}
          onCancel={() => setIsShowAddTestItem(false)}
          listData={categoryHidden?.length > 0 ? listTestItem : []}
          title={t('inspectionCategoryAddition')}
          titleButton={t('button.close')}
          initialItemShow={initialShowItemRefIds}
          initialItemHidden={initialHiddenItemRefIds}
        />
      )}
    </DragDropContext>
  )
}

export default CourseTestItemWrapper
