import reservationService from './reservationService'
import { API_GET_ROOM_ROCKET } from 'configs/api'
import { DELETE_RESERVATION } from 'graphql/ReservationManagement/deleteReservation'
import { GetRoomsData, RoomsData } from 'pages/Chat/types/types'
import { Endpoint, apolloClient } from 'utilities/apolloClient'

const reservationApi = {
  getRooms: (data: GetRoomsData): Promise<RoomsData> => {
    return reservationService.post(API_GET_ROOM_ROCKET, data)
  },
  deleteReservation: (desiredDatetimeReplyUserRefId: string) => {
    return apolloClient.mutate({
      mutation: DELETE_RESERVATION,
      variables: {
        refId: desiredDatetimeReplyUserRefId
      },
      context: {
        version: Endpoint.RESERVATION
      }
    })
  }
}

export default reservationApi
