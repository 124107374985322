import { DocumentNode } from 'graphql'

import { gql } from '@apollo/client'

export const FILTER_SLOTS: DocumentNode = gql`
  query getRemainedAvailableSlotsByDateRange(
    $startDate: String!
    $endDate: String!
    $medicalCheckupMasterRefId: String!
  ) {
    getRemainedAvailableSlotsByDateRange(
      daterange: { startDate: $startDate, endDate: $endDate }
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
    ) {
      message
      status
      timestamp
      payload {
        bydate
        byday
        byhour
        emptySlotCount
        remainedSlotCount
      }
    }
  }
`
