import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { ReactComponent as HiddenIcon } from 'assets/svgs/hiddenItem.svg'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import { SurveyList } from 'types/courseRegister'

export default function CourseRelatedInterview({
  setHidden,
  setEdit,
  surveys
}: {
  setHidden: () => void
  setEdit: () => void
  surveys: SurveyList[]
}) {
  const { t } = useTranslation()

  return (
    <div className="mt-3 bg-white pt-1.5 pb-4 pr-1 pl-2 rounded-md">
      <Flex className="font-bold items-center">
        <Typography className="w-[26%] pl-2.5">
          {t('table.interviewTitle')}
        </Typography>
        <Flex className="flex-1 justify-between items-center">
          <Typography>{t('lable.testCD')}</Typography>
          <Flex className="items-center gap-3">
            <PenEditIcon
              className="w-[15px] cursor-pointer"
              onClick={() => setEdit()}
            />
            <HiddenIcon
              className="w-[30px] cursor-pointer"
              onClick={() => setHidden()}
            />
          </Flex>
        </Flex>
      </Flex>
      <div className="border-t border-[#707070] mt-1.5"></div>
      <div className="h-[210px] overflow-y-scroll">
        <Flex vertical>
          {surveys.map((item: SurveyList) => (
            <Flex
              key={item.id}
              className="items-center min-h-[30px] py-1 border-b border-[#BDCBD5]"
            >
              <Typography className={`w-[26%] px-2.5`}>
                {item.questionnaireTitle}
              </Typography>
              <Typography className="flex-1 text-[#BDCBD5]">
                {item.code}
              </Typography>
            </Flex>
          ))}
        </Flex>
      </div>
    </div>
  )
}
