import { useTranslation } from 'react-i18next'

import { Col, Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import ModalConfirm from 'components/modals/ModalConfirm'
import { getLanguage } from 'utilities/helpers'
import { showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  category?: DataItemMaster
  onCancel: () => void
  onSuccess: () => void
}

const NoteHiddenSettingModal = ({
  isModalOpen = false,
  category,
  onCancel,
  onSuccess
}: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
    onSuccess()
    onCancel()
  }

  const language = getLanguage()

  return (
    <ModalConfirm
      isModalVisible={isModalOpen}
      title={t('NoteHiddenSetting')}
      handleCancel={onCancel}
      handleOk={handleOk}
      classNames={{
        classNameButtonCancel: '!border-primary !text-primary',
        classNameButtonOk: '!bg-primary '
      }}
      icons={
        <div className="mt-10">
            <ExclamationCircleFilled className="w-10 pb-6 inline-block" />
          </div>
      }
      content={
        <>
          <Flex
            vertical
            className="text-center pt-[17px] pr-[14px] pb-[19px] pl-[7px]"
          >
            <Paragraph className="mb-0 text-error">
              {t('content.NoteHiddenSetting')}
            </Paragraph>
            <Typography className="mt-[43px] tracking-[.7px] text-left ps-2">
              {t('CurrentlySelectedCategory')}
            </Typography>
            {category && (
              <div className="text-left pl-2 mt-[15px]">
                <Flex className="bg-[#EFF3F6] border border-solid border-[#CDD6DD] py-2 pl-[11px]">
                  <Col flex="57.8%">
                    <Typography className="leading-none tracking-[1.4px]">
                      {showName(category, language)}
                    </Typography>
                  </Col>
                  <Col flex="auto" className="font-normal">
                    <Typography className="leading-none tracking-[1.4px]">
                      {showName(category, 'en')}
                    </Typography>
                  </Col>
                </Flex>
                {category?.items &&
                  category?.items?.length > 0 &&
                  category?.items.map((item, index) => (
                    <Flex
                      className="pl-[11px] pt-2"
                      key={`${item.refId}_${index}`}
                    >
                      <Col flex="57.8%">
                        <Typography className="tracking-[1.4px] leading-4">
                          {showName(item, language)}
                        </Typography>
                      </Col>
                      <Col flex="auto" className="font-normal">
                        <Typography className="tracking-[1.4px] leading-4">
                          {showName(item, 'en')}
                        </Typography>
                      </Col>
                    </Flex>
                  ))}
              </div>
            )}
          </Flex>
        </>
      }
    />
  )
}

export default NoteHiddenSettingModal
