import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { IListReferenceValue } from './ItemMaster'
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons'
import { ReactComponent as AddIcon } from 'assets/svgs/add-circle.svg'
import { readingTypeItemMaster, typeReferenceValue } from 'configs/constant'
import useClickOutSide from 'hooks/useClickOutSide'
import { ItemMasterWithMultipleLang } from 'types/ItemMasters'
import { uuid } from 'utilities/helpers'

type Props = {
  itemMaster: ItemMasterWithMultipleLang
  judgementId: string
  setListReferenceValue: React.Dispatch<
    React.SetStateAction<IListReferenceValue>
  >
  gender?: string
}

export default function AddItemReferenceValue({
  itemMaster,
  judgementId,
  setListReferenceValue,
  gender
}: Props) {
  const { t } = useTranslation()
  const { show, setShow, nodeRef } = useClickOutSide()

  const handleAddReferenceValue = (type: typeReferenceValue) => {
    setListReferenceValue((prev: IListReferenceValue) => ({
      ...prev,
      [judgementId]: [
        ...(prev[judgementId] || []),
        { id: uuid(), type, ...(gender && { gender }) }
      ]
    }))
  }

  const renderStringType = () => (
    <Flex
      className="items-center gap-2 mt-3 cursor-pointer"
      onClick={() => handleAddReferenceValue(typeReferenceValue.STRING)}
    >
      <Flex className="w-10 h-10 items-center justify-center border border-[#CDD6DD]">
        <div className="w-8 h-5 border border-customGray"></div>
      </Flex>
      <div>
        <Typography className="text-xs font-bold">
          {t('placeholder.string')}
        </Typography>
        <Typography className="text-[11px]">
          {t('content.addTextInputField')}
        </Typography>
      </div>
    </Flex>
  )

  const renderNumericalType = () => (
    <>
      <Flex
        className="items-center gap-2 mt-3 cursor-pointer"
        onClick={() =>
          handleAddReferenceValue(typeReferenceValue.CONDITIONAL_NUMBER)
        }
      >
        <Flex className="w-10 h-10 items-center justify-center border border-[#CDD6DD] gap-[1px]">
          <div className="w-4 h-5 border border-customGray"></div>
          <Flex className="w-4 h-5 border border-customGray items-center justify-center">
            <CaretDownOutlined className="text-[#137695] text-[8px]" />
          </Flex>
        </Flex>
        <div>
          <Typography className="text-xs font-bold">
            {t('content.specifyNumericValue')}
          </Typography>
          <Typography className="text-[11px]">
            {t('content.addSpecifiedNumberInputField')}
          </Typography>
        </div>
      </Flex>
      <Flex
        className="items-center gap-2 mt-3 cursor-pointer"
        onClick={() => handleAddReferenceValue(typeReferenceValue.RANGE_NUMBER)}
      >
        <Flex className="w-10 h-10 items-center justify-center border border-[#CDD6DD] gap-[1px]">
          <div className="w-3 h-5 border border-customGray"></div>
          <Typography className="text-[11px]">~</Typography>
          <div className="w-3 h-5 border border-customGray"></div>
        </Flex>
        <div>
          <Typography className="text-xs font-bold">
            {t('content.specifyNumericValue')}
          </Typography>
          <Typography className="text-[11px]">
            {t('content.addSpecifiedNumberInputField')}
          </Typography>
        </div>
      </Flex>
    </>
  )

  return (
    <div className="w-max relative">
      <AddIcon
        className="cursor-pointer w-5"
        onClick={() => setShow(!show)}
        ref={nodeRef}
      />
      <div
        className={`absolute w-[250px] p-2.5 pt-0 pr-1.5 top-[30px] left-1/2 -translate-x-1/2 rounded-sm bg-white border-[#CDD6DD] border-[2px] z-10 ${
          show ? '' : 'hidden'
        }`}
      >
        <Flex className="justify-between items-start">
          <Typography className="mt-2.5">
            {t('content.selectAdditionalItems')}
          </Typography>
          <CloseOutlined className="text-[#C4C6CE] mt-1.5 cursor-pointer" />
        </Flex>
        {itemMaster.readingType === readingTypeItemMaster.STRING &&
          renderStringType()}
        {itemMaster.readingType === readingTypeItemMaster.NUMERICAL &&
          renderNumericalType()}
      </div>
    </div>
  )
}
