import React from 'react'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'

import { Text, View } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { getLanguage } from 'utilities/helpers'

type Props = {
  t: TFunction<'translation', undefined>
  language: any
  customer: any
}

const NameView: React.FC<Props> = ({ t, language, customer }) => {
  if (language === 'ja') {
    return (
      <View
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          fontSize: 8
        }}
      >
        <View
          style={{
            fontWeight: 'bold',
            width: '19.75%'
          }}
        >
          <Text>{`${customer?.firstName}${customer?.lastName}`}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
            flex: '1',
            paddingRight: 35
          }}
        >
          {/* <Text>花子</Text> */}
          <Text>{t('pdfExport.personTitle')}</Text>
        </View>
      </View>
    )
  } else {
    return (
      <View
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          fontSize: 8
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            flex: '1',
            paddingRight: 35
          }}
        >
          {/* <Text>花子</Text> */}
          <Text>{t('pdfExport.personTitle')}</Text>
        </View>
        <View
          style={{
            fontWeight: 'bold',
            width: '19.75%'
          }}
        >
          <Text>{`${customer?.firstName}${customer?.lastName}`}</Text>
        </View>
      </View>
    )
  }
}

const TableInfoPDF = ({
  customer,
  tenant,
  category,
  lastMedical,
  firstMedical,
  secondMedical
}: {
  tenant: any
  category: any
  customer: any
  lastMedical: any
  firstMedical: any
  secondMedical: any
}) => {
  const { t } = useTranslation()
  const language = getLanguage()

  let birthday = customer?.birthday
    ? format(customer?.birthday, 'yyyy/MM/dd')
    : ''

  return (
    <View>
      <Text style={{ fontSize: 10, color: '#147695', fontWeight: 'bold' }}>
        {t('pdfExport.regularHealthCheckReport')}
      </Text>
      <Text style={{ marginTop: 16 }}>
        {tenant?.additionalInfo?.name ?? ''}
      </Text>
      <NameView t={t} language={language} customer={customer} />

      {/* info1 */}
      <View
        style={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #93d1ca',
          fontWeight: 'bold'
        }}
      >
        <View style={{ width: '52.5%' }}>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '37.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('customerDetail.form.label.address')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2, display: 'flex', gap: 4 }}>
              <Text>{`${customer?.zipCodePart1 ?? ''}-${customer?.zipCodePart2 ?? ''}`}</Text>
              <Text>{`${customer?.province ?? ''}${customer?.district ?? ''}`}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '37.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('customerDetail.form.label.dateOfBirth')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2 }}>
              <Text>{birthday}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '37.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('lable.age')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2 }}>
              <Text>{customer?.age ?? ''}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '37.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('pdfExport.publicHealthNurseNumber')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2 }}>
              <Text>
                {customer?.numberOfInsurance
                  ? customer.numberOfInsurance.split('')
                  : ''}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '37.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('customerDetail.form.label.symbol')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2 }}>
              <Text>{customer?.insuranceSymbol ?? ''}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flex: '1',
            paddingTop: 24
          }}
        >
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '32.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('lable.gender')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2 }}>
              <Text>
                {customer?.gender
                  ? customer.gender === 'MALE'
                    ? t('customerDetail.header.male')
                    : t('customerDetail.header.female')
                  : ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <View
              style={{
                width: '32.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>ID</Text>
            </View>
            <View
              style={{
                flex: '1',
                padding: 2
              }}
            >
              <Text>
                {customer?.patientId ? customer.patientId.split('') : ''}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 'auto'
            }}
          >
            <View
              style={{
                width: '32.6%',
                backgroundColor: '#b9e1dc',
                padding: 2
              }}
            >
              <Text>{t('customerDetail.form.label.number')}</Text>
            </View>
            <View style={{ flex: '1', padding: 2 }}>
              <Text>{customer?.insuranceNumber ?? ''}</Text>
            </View>
          </View>
        </View>
      </View>

      {/* info 2 */}
      <View
        style={{
          marginTop: 12,
          display: 'flex',
          border: '1px solid #93d1ca',
          fontWeight: 'bold'
        }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <View
            style={{
              width: '19.75%',
              backgroundColor: '#b9e1dc',
              padding: 2,
              borderBottom: '1px solid #93d1ca'
            }}
          >
            <Text>{t('pdfExport.examinationDate')}</Text>
          </View>
          <View style={{ flex: '1', padding: 2, display: 'flex', gap: 4 }}>
            <Text>{lastMedical?.consultationDate ?? ''}</Text>
          </View>
        </View>

        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <View
            style={{
              width: '19.75%',
              backgroundColor: '#b9e1dc',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Text>{t('testResult.inspectionItem')}</Text>
          </View>
          <View style={{ flex: '1', padding: 2, display: 'flex', gap: 4 }}>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {Array.isArray(lastMedical?.formatCategory) &&
                lastMedical.formatCategory.map((item: any, idx: any) => {
                  return (
                    <Text
                      key={idx}
                      style={{
                        width: idx % 2 === 0 ? '41.25%' : '58.75',
                        padding: 2
                      }}
                    >
                      {item?.lang?.[language] ?? ''}
                    </Text>
                  )
                })}
            </View>
          </View>
        </View>
      </View>

      {/* Overall verdict */}
      <View
        style={{
          marginTop: 14,
          border: '1px solid #93d1ca',
          fontWeight: 'bold',
          width: '68%'
        }}
      >
        <Text
          style={{
            color: 'white',
            fontWeight: 'bold',
            width: '100%',
            backgroundColor: '#137695',
            padding: 2,
            paddingBottom: 4
          }}
        >
          {t('testResult.generalEvaluation')}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <View
            style={{
              width: '29%',
              backgroundColor: '#b9e1dc',
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text>{t('pdfExport.current')}</Text>
          </View>
          <View
            style={{
              flex: '1',
              textAlign: 'center'
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  flex: '1',
                  paddingTop: 2,
                  paddingBottom: 2,
                  color: '#1c8fbe',
                  border: '1px solid #93d1ca'
                }}
              >
                {lastMedical?.evaluation ?? ''}
              </Text>
              <Text
                style={{
                  width: '40.4%',
                  paddingTop: 2,
                  paddingBottom: 2,
                  border: '1px solid #93d1ca',
                  backgroundColor: '#eaeaea'
                }}
              >
                {t('pdfExport.last')}
              </Text>
              <Text
                style={{
                  flex: '1',
                  paddingTop: 2,
                  paddingBottom: 2,
                  color: '#1c8fbe',
                  border: '1px solid #93d1ca'
                }}
              >
                {secondMedical?.evaluation ?? ''}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  flex: '1',
                  paddingTop: 2,
                  paddingBottom: 2,
                  color: '#1c8fbe',
                  border: '1px solid #93d1ca'
                }}
              ></Text>
              <Text
                style={{
                  width: '40.4%',
                  paddingTop: 2,
                  paddingBottom: 2,
                  border: '1px solid #93d1ca',
                  backgroundColor: '#eaeaea'
                }}
              >
                {t('pdfExport.twoTimes')}
              </Text>
              <Text
                style={{
                  flex: '1',
                  paddingTop: 2,
                  paddingBottom: 2,
                  color: '#1c8fbe',
                  border: '1px solid #93d1ca'
                }}
              >
                {firstMedical?.evaluation ?? ''}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {/* table 3 */}

      <View
        style={{
          marginTop: 14,
          border: '1px solid #93d1ca',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            flex: '1',
            borderRight: '1px solid #93d1ca'
          }}
        >
          <Text
            style={{
              color: 'white',
              fontWeight: 'bold',
              width: '100%',
              backgroundColor: '#137695',
              padding: 2,
              paddingBottom: 4
            }}
          >
            {t('pdfExport.metabolicSyndromeAssessment')}
          </Text>
          <Text
            style={{
              width: '100%',
              padding: lastMedical?.metabolicSyndromeAssessment ? 2 : 5,
              textAlign: 'center'
            }}
          >
            {lastMedical?.metabolicSyndromeAssessment ?? ''}
          </Text>
        </View>
        <View
          style={{
            flex: '1'
          }}
        >
          <Text
            style={{
              color: 'white',
              fontWeight: 'bold',
              width: '100%',
              backgroundColor: '#137695',
              padding: 2,
              paddingBottom: 4
            }}
          >
            {t('pdfExport.specificHealthGuidance')}
          </Text>
          <Text
            style={{
              width: '100%',
              padding: 2,
              textAlign: 'center'
            }}
          >
            {lastMedical?.specificHealthGuidance ?? ''}
          </Text>
        </View>
      </View>

      {/* table 4 */}
      <View
        style={{
          marginTop: 14,
          border: '1px solid #93d1ca',
          fontWeight: 'bold'
        }}
      >
        <Text
          style={{
            color: 'white',
            fontWeight: 'bold',
            width: '100%',
            backgroundColor: '#137695',
            padding: 2,
            paddingBottom: 4
          }}
        >
          {t('testResult.categoryEvaluation')}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {category.map((item: any, idx: any) => {
            return (
              <View
                key={idx}
                style={{
                  width: '33.3%',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Text
                  style={{
                    width: '58.6%',
                    backgroundColor: '#b9e1dc',
                    padding: 2,
                    border: '1px solid #93d1ca'
                  }}
                >
                  {item?.lang?.[language]}
                </Text>
                <Text
                  style={{
                    flex: '1',
                    padding: 2,
                    textAlign: 'center',
                    color: '#1c8fbe',
                    border: '1px solid #93d1ca'
                  }}
                >
                  {lastMedical?.testResults?.[item.idItemMaster]?.evaluation}
                </Text>
              </View>
            )
          })}
        </View>
      </View>

      <View
        style={{
          marginTop: 4,
          display: 'flex',
          gap: 8,
          fontSize: 5
        }}
      >
        <Text>A…{t('pdfExport.evaluationA')}</Text>
        <Text>B…{t('pdfExport.evaluationB')}</Text>
        <Text>C…{t('pdfExport.evaluationC')}</Text>
        <Text>D…{t('pdfExport.evaluationD')}</Text>
      </View>

      <View
        style={{
          marginTop: 16,
          border: '1px solid #137695'
        }}
      >
        <Text
          style={{
            color: 'white',
            fontWeight: 'bold',
            width: '100%',
            backgroundColor: '#137695',
            padding: 2,
            paddingBottom: 4
          }}
        >
          {t('testResult.generalComment')}
        </Text>
        <View
          style={{
            minHeight: 132,
            padding: 6
          }}
        >
          <Text>{lastMedical?.generalComments ?? ''}</Text>
        </View>
      </View>
    </View>
  )
}

export default TableInfoPDF
