import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { StringKeyObject } from 'types/common'

export default function TestName({
  dataShowMedicalCheckups,
  language
}: {
  dataShowMedicalCheckups: StringKeyObject[]
  language: string
}) {
  const { t } = useTranslation()
  return (
    <Flex className="w-full border-b border-[#BFC6CB]">
      <div className="w-[25%] border-left-between px-5 py-3">
        <span className="font-bold">{t('testResult.inspectionItem')}</span>
      </div>
      {dataShowMedicalCheckups?.map((item: StringKeyObject) => (
        <div key={item.refId} className="w-[25%] p-2.5 border-left-between">
          <Typography className="font-bold text-base">
            {item?.medicalCheckupMaster?.displayName}
          </Typography>

          <Flex className="flex-wrap mt-2 gap-y-1">
            {item.formatCategory?.map((category: StringKeyObject) => (
              <div className="w-[50%] pr-1.5" key={category.refId}>
                {category.lang?.[language] ?? VALUE_WITH_NO_DATA}
              </div>
            ))}
          </Flex>
        </div>
      ))}
    </Flex>
  )
}
