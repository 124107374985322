import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import Title from './Title'
import { ReactComponent as Reload } from 'assets/svgs/reload.svg'

export const controlPanelTextStyles = 'text-[14px] text-[#545454]'
export const controlPanelBorder = 'border border-[#BDCBD5]'
export const controlPanelWrapper = `py-2 w-full overflow-auto ${controlPanelBorder}`

function PatientMemo({ memoText }: { memoText: string }) {
  const { t } = useTranslation()

  return (
    <div className={`${controlPanelWrapper} h-[198px] px-[20px]`}>
      <Flex justify="space-between" align="center">
        <Title text={t('dashboard.memo_section')} />
        <Flex align="center">
          <Reload
            width="13px"
            height="16px"
            className="mr-[10px] cursor-pointer"
          />
          <Typography
            className={`tracking-[1.4px] underline ${controlPanelTextStyles}`}
          >
            {dayjs(new Date()).format(t('formatDate'))}
          </Typography>
        </Flex>
        <div className="w-[42px]"></div>
      </Flex>
      <div
        className={`mt-[18px] ${controlPanelTextStyles}`}
        dangerouslySetInnerHTML={{
          __html: (memoText ?? '').replace(/\n/g, '<br>')
        }}
      ></div>
    </div>
  )
}

export default memo(PatientMemo)
