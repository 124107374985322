import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

import styles from './HealthStatusReport.module.scss'
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons'

const RANK_HEIGHT: number = 10
const SQUARE: number = 15
const SQUARE_HYPOTENUSE: number = SQUARE * Math.sqrt(2)

interface ResultItem {
  rank: string
  style?: string
  value: number
}

interface DataSourceItem {
  key: string
  title: string
  result: {
    closest1st: ResultItem
    closest2nd: ResultItem
    closest3rd: ResultItem
  }
}

const RANKS = {
  NO_PROBLEM: '問題なし',
  NEED_ATTENTION: '注意が必要',
  NEED_IMPROVEMENT: '要改善'
}

const SQUARE_BOTTOM_STYLES = {
  squareNoProblem: [4, 15], // range bottom px
  squareNeedAttention: [35, 48],
  squareNeedImprovement: [68, 78]
}

const dataSource: DataSourceItem[] = [
  {
    key: '1',
    title: '肥満',
    result: {
      closest1st: {
        rank: RANKS.NO_PROBLEM,
        value: 5
      },
      closest2nd: {
        rank: RANKS.NEED_ATTENTION,
        value: 15
      },
      closest3rd: {
        rank: RANKS.NEED_IMPROVEMENT,
        value: 25
      }
    }
  },
  {
    key: '2',
    title: '血圧',
    result: {
      closest1st: {
        rank: RANKS.NEED_IMPROVEMENT,
        value: 25
      },
      closest2nd: {
        rank: RANKS.NEED_IMPROVEMENT,
        value: 20
      },
      closest3rd: {
        rank: RANKS.NEED_ATTENTION,
        value: 15
      }
    }
  },
  {
    key: '3',
    title: '肝臓',
    result: {
      closest1st: {
        rank: RANKS.NO_PROBLEM,
        value: 10
      },
      closest2nd: {
        rank: RANKS.NEED_ATTENTION,
        value: 15
      },
      closest3rd: {
        rank: RANKS.NO_PROBLEM,
        value: 3
      }
    }
  },
  {
    key: '4',
    title: '血糖',
    result: {
      closest1st: {
        rank: RANKS.NO_PROBLEM,
        value: 8
      },
      closest2nd: {
        rank: RANKS.NO_PROBLEM,
        value: 2
      },
      closest3rd: {
        rank: RANKS.NEED_IMPROVEMENT,
        value: 21
      }
    }
  },
  {
    key: '5',
    title: '脂質',
    result: {
      closest1st: {
        rank: RANKS.NEED_IMPROVEMENT,
        value: 25
      },
      closest2nd: {
        rank: RANKS.NO_PROBLEM,
        value: 4
      },
      closest3rd: {
        rank: RANKS.NEED_ATTENTION,
        value: 18
      }
    }
  }
]

function calcOffsetBottom(rank: string, point: number) {
  let range: number[] = []
  if (rank === RANKS.NO_PROBLEM) {
    range = SQUARE_BOTTOM_STYLES.squareNoProblem
  }
  if (rank === RANKS.NEED_ATTENTION) {
    range = SQUARE_BOTTOM_STYLES.squareNeedAttention
    point -= RANK_HEIGHT
  }
  if (rank === RANKS.NEED_IMPROVEMENT) {
    range = SQUARE_BOTTOM_STYLES.squareNeedImprovement
    point -= RANK_HEIGHT * 2
  }

  const [start, end] = range
  const heightLength = end - start
  const heightPerPoint = heightLength / RANK_HEIGHT
  const bottom = start + point * heightPerPoint
  return { bottom: `${bottom}px` }
}

const NoProblemTitle = () => {
  const { t } = useTranslation()
  return (
    <span className="pl-[25px] text-[#5CAF53]">
      <SmileOutlined className="mr-1" />
      {t('lable.noProblem')}
    </span>
  )
}

const NeedAttentionTitle = () => {
  return (
    <span className="pl-[25px] text-[#F5B125]">
      <FrownOutlined className="mr-1" />
      注意が必要
    </span>
  )
}

const NeedImprovementTitle = () => {
  return (
    <span className="pl-[25px] text-[#F5006B]">
      <MehOutlined className="mr-1" />
      要改善
    </span>
  )
}

const TitleRender = (rank: string) => {
  if (rank === RANKS.NO_PROBLEM) {
    return <NoProblemTitle />
  }
  if (rank === RANKS.NEED_ATTENTION) {
    return <NeedAttentionTitle />
  }
  if (rank === RANKS.NEED_IMPROVEMENT) {
    return <NeedImprovementTitle />
  }

  return <>null</>
}

const getRankClassName = (rank: string) => {
  if (rank === RANKS.NO_PROBLEM) {
    return 'squareNoProblem'
  }
  if (rank === RANKS.NEED_ATTENTION) {
    return 'squareNeedAttention'
  }
  if (rank === RANKS.NEED_IMPROVEMENT) {
    return 'squareNeedImprovement'
  }

  return ''
}

const getLineStyles = (point1: HTMLElement, point2: HTMLElement) => {
  const {
    offsetTop: point1Top,
    offsetHeight: point1Height,
    offsetLeft: point1Left,
    offsetWidth: point1Width
  } = point1
  // console.log({ point1Top, point1Height, point1Left, point1Width })

  const {
    offsetTop: point2Top,
    offsetHeight: point2Height,
    offsetLeft: point2Left,
    offsetWidth: point2Width
  } = point2
  // console.log({ point2Top, point2Height, point2Left, point2Width })

  const point1CenterX = point1Width / 2
  const point1CenterY = point1Height / 2
  const point2CenterX = point2Width / 2
  const point2CenterY = point2Height / 2
  const angle =
    (Math.atan2(point2Top - point1Top, point2Left - point1Left) * 180) / Math.PI
  const distance = lineDistance(point1Left, point1Top, point2Left, point2Top)

  let style = {
    transform: `rotate(${angle}deg)`,
    width: `${distance}px`,
    top: 0,
    left: 0
  }

  let _left = point1Left + point1CenterX
  if (point2Left < point1Left) {
    _left = point2Left + point2CenterX
  }

  let _top: number
  if (point2Top < point1Top) {
    if (point2Top + SQUARE_HYPOTENUSE + SQUARE * 2 > point1Top) {
      _top = point2Top + SQUARE_HYPOTENUSE
    } else if (point2Top + point2CenterY + SQUARE > point1Top) {
      _top = point2Top + point2CenterY + SQUARE
    } else {
      _top = point2Top + point2CenterY + SQUARE * 2
    }
  } else if (point1Top + point1CenterY + SQUARE * 2 < point2Top) {
    _top = point1Top + point1CenterY + SQUARE * 2
  } else if (point1Top + point1CenterY + SQUARE < point2Top) {
    _top = point1Top + point1CenterY + SQUARE
  } else {
    _top = point1Top + point1CenterY
  }

  return { ...style, top: _top, left: _left + 10 }
}

function lineDistance(x: number, y: number, x0: number, y0: number) {
  return Math.sqrt((x -= x0) * x + (y -= y0) * y) - SQUARE_HYPOTENUSE
}

const ReportItem = ({ item }: { item: DataSourceItem }) => {
  const { closest1st, closest2nd, closest3rd } = item.result
  const [squareNoProblemRef, setSquareNoProblemRef] = React.useState<any>()
  const [squareNeedAttentionRef, setSquareNeedAttentionRef] =
    React.useState<any>()
  const [squareNeedImprovementRef, setSquareNeedImprovementRef] =
    React.useState<any>()
  const [
    lineNoProblemToNeedAttentionStyle,
    setLineNoProblemToNeedAttentionStyle
  ] = React.useState<any>({})
  const [lineAttentionToImprovementStyle, setLineAttentionToImprovementStyle] =
    React.useState<any>({})

  React.useEffect(() => {
    // componentDidMount
    const drawLine = () => {
      if (
        squareNoProblemRef &&
        squareNeedAttentionRef &&
        squareNeedImprovementRef
      ) {
        const styleLineNoProblemToNeedAttention = getLineStyles(
          squareNoProblemRef,
          squareNeedAttentionRef
        )
        setLineNoProblemToNeedAttentionStyle({
          ...styleLineNoProblemToNeedAttention
        })

        const styleLineAttentionToImprovement = getLineStyles(
          squareNeedAttentionRef,
          squareNeedImprovementRef
        )
        setLineAttentionToImprovementStyle({
          ...styleLineAttentionToImprovement
        })
      }
    }

    // initial draw
    drawLine()

    function handleResize() {
      // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
      drawLine()
    }

    window.addEventListener('resize', handleResize)
  }, [squareNoProblemRef, squareNeedAttentionRef, squareNeedImprovementRef])

  return (
    <Flex className="">
      <div className="w-[16.15%] ml-[0.5%] justify-between items-center flex">
        <div>
          <span className="font-bold block">{item.title}</span>
        </div>
      </div>
      <div className="w-[83.85%] ml-[0.5%] mb-[15px] justify-between relative">
        <div className="flex">
          <div className="w-[33%] flex mb-[5px]">
            <span className="">2022/12/01</span>
            {TitleRender(closest1st.rank)}
          </div>
          <div className="w-[33%] flex">
            <span className="">2022/12/01</span>
            {TitleRender(closest2nd.rank)}
          </div>
          <div className="w-[33%] flex">
            <span className="">2022/12/01</span>
            {TitleRender(closest3rd.rank)}
          </div>
        </div>
        <div>
          <div className="w-full h-[32px] bg-[#DF247533]"></div>
          <div className="w-full h-[32px] bg-[#FFC00033]"></div>
          <div className="w-full h-[20px] bg-[#5CAF5333]"></div>
          <div className="flex w-full h-[12px] bg-[#5CAF5333]">
            <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]">
              <div
                ref={(el) => setSquareNoProblemRef(el)}
                className={`${styles.square} ${styles[getRankClassName(closest1st.rank)]}`}
                style={calcOffsetBottom(closest1st.rank, closest1st.value)}
              ></div>
              <div
                style={lineNoProblemToNeedAttentionStyle}
                className={styles.line}
              ></div>
            </div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]"></div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]"></div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]">
              <div
                ref={(el) => setSquareNeedAttentionRef(el)}
                className={`${styles.square} ${styles[getRankClassName(closest2nd.rank)]}`}
                style={calcOffsetBottom(closest2nd.rank, closest2nd.value)}
              ></div>
              <div
                style={lineAttentionToImprovementStyle}
                className={styles.line}
              ></div>
            </div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]"></div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]"></div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]"></div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]">
              <div
                ref={(el) => setSquareNeedImprovementRef(el)}
                className={`${styles.square} ${styles[getRankClassName(closest3rd.rank)]}`}
                style={calcOffsetBottom(closest3rd.rank, closest3rd.value)}
              ></div>
            </div>
            <div className="w-[1px] h-full bg-[#BFC6CB] ml-[10%]"></div>
          </div>
          <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
        </div>
      </div>
    </Flex>
  )
}

export default function HealthStatusReport() {
  const { t } = useTranslation()
  return (
    <div className="w-full mt-[10px]">
      <Flex className="h-[auto] bg-[#ffffff] text-[#545454]">
        <div className="w-[10%] flex justify-between items-center">
          <div className="ml-[7px]">
            <span className="font-bold">
              {t('pdfExport.healthStatusReport')}
            </span>
          </div>
        </div>
        <div className="w-[90%] justify-between">
          {dataSource.map((item, index) => (
            <div key={index}>
              <ReportItem key={index} item={item} />
            </div>
          ))}
        </div>
      </Flex>
      <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
    </div>
  )
}
