import { Document, Font, Page, StyleSheet, Text } from '@react-pdf/renderer'
import RobotoBold from 'assets/fonts/Roboto-Bold.ttf'
import Roboto from 'assets/fonts/Roboto-Regular.ttf'
import YuGothicUI from 'assets/fonts/YuGothic-Regular-01.ttf'
import YuGothicUIBold from 'assets/fonts/YuGothicUI-Bold-02.ttf'

// Register font YuGothicUI
Font.register({
  family: 'YuGothicUI',
  fonts: [
    { src: YuGothicUI },
    { src: YuGothicUIBold, fontStyle: 'normal', fontWeight: 'bold' }
  ]
})

// Register font Roboto
Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto },
    { src: RobotoBold, fontStyle: 'normal', fontWeight: 'bold' }
  ]
})

const styles = StyleSheet.create({
  container: {
    fontFamily: ['YuGothicUI', 'Roboto'],
    fontWeight: 'normal'
  }
})

// Preload PDF Font
const PdfPreLoad = () => {
  return (
    <Document>
      <Page style={[styles.container]}>
        <Text style={{ fontWeight: 'bold' }}></Text>
      </Page>
    </Document>
  )
}

export default PdfPreLoad
