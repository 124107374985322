import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'

import { RESERVATION_MANAGEMENT_TAB } from '../ReservationManagement'
import RowForm from './RowForm'
import styles from './scss/TabbleWrapper.module.scss'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { ReactComponent as Checked } from 'assets/svgs/checked-gray.svg'
import { PATHS, TIME_PERIOD } from 'configs/constant'
import useReservationManagement from 'hooks/useReservationManagement'
import { RECORD_COUNT } from 'models/customer'
import {
  RESERVATION_STATUS,
  ReservationCollectionConditions,
  ReservationCollectionData
} from 'models/reservationManagement'

const getDayTimeAtPriority = (
  index: number,
  data?: { priority: number; date: string; timeOfDay: string }[]
) => {
  if (Array.isArray(data) && data.length > 0) {
    const foundItem = data.find((item) => item.priority === index)

    if (foundItem) {
      const { date, timeOfDay } = foundItem
      const timePeriod = timeOfDay !== TIME_PERIOD.AFTERNOON

      return {
        date,
        timePeriod
      }
    }
  }

  return { timePeriod: true }
}

type Column = {
  refId: string
  courseId: string
  courseName: string
  courseOptionIds: string[]
  courseSelect?: string
  status: RESERVATION_STATUS
  date?: string
  time?: string
  userId: string
  userFullName: string
  userPhone?: string
  userInfo: { sex: string; age: string }
  desiredDatetime?: { priority: number; date: string; timeOfDay: string }[]
  key: string
  isCourseDeleted: boolean
  isUserDeleted: boolean
}

const customerDetailTab = '0'

type Props = {
  setTotalRecords: (data: number) => void
}

const ReservationCollection: FC<Props> = ({ setTotalRecords }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const { control, getValues } = useForm()
  const navigate = useNavigate()

  const { loading, onFilterReservationCollection, onUpdateDesiredReservation } =
    useReservationManagement()

  const formatDate = t('reservationManagement.content.formatDate')

  const queryParams = new URLSearchParams(search)
  const {
    search: searchParam,
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam,
    status: statusParam,
    orderBy: orderParam,
    sortBy: sortParam = 'asc',
    page: pageParam = 1,
    size: sizeParam = RECORD_COUNT.OneHundred,
    tab: tabParam
  } = Object.fromEntries(queryParams)

  const [initialData, setInitialData] = useState<ReservationCollectionData[]>(
    []
  )
  const [userIds, setUserIds] = useState<string[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const fetchDesiredCollectionData = useCallback(
    async (conditions: ReservationCollectionConditions) => {
      try {
        const result = await onFilterReservationCollection(conditions)
        if (result.data) {
          setInitialData(result.data)
          setTotalRecords(result.totalRecords)

          const allIds = result.data.map((item) => item.userId)
          const validIds = allIds.filter((id): id is string => id != null)
          const uniqueIds = Array.from(new Set(validIds))

          setUserIds(uniqueIds)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [onFilterReservationCollection]
  )

  useEffect(() => {
    const createFilter = () => ({
      ...(searchParam && { idOrFullNameOrPhone: searchParam }),
      ...(courseParam && { courseId: courseParam.split(',') }),
      ...(statusParam && { status: statusParam }),
      ...(startDateParam &&
        endDateParam && {
          date: { startDate: startDateParam, endDate: endDateParam }
        })
    })

    const determineOrderAndSort = <T extends string>(
      validOrderKeys: readonly T[]
    ) => {
      const order =
        orderParam && validOrderKeys.includes(orderParam as T)
          ? (orderParam as T)
          : undefined
      const sort: 'asc' | 'desc' | undefined =
        sortParam === 'asc' || sortParam === 'desc' ? sortParam : undefined
      return { order, sort }
    }

    const pagination = {
      page: +pageParam,
      size: +sizeParam
    }

    const { order, sort } = determineOrderAndSort<
      keyof ReservationCollectionData
    >([
      'courseName',
      'status',
      'userId',
      'userFullName',
      'userPhone',
      'courseSelect'
    ])

    const conditions: ReservationCollectionConditions = {
      filter: createFilter(),
      pagination,
      order,
      sort
    }

    setSelectedRowKeys([])

    if (tabParam === RESERVATION_MANAGEMENT_TAB.RESERVATION_COLLECTION_LIST) {
      fetchDesiredCollectionData(conditions).finally()
    }
  }, [search])

  const handleSort = (order: string) => {
    navigate(`${pathname}?${queryParams.toString()}`)

    queryParams.set('orderBy', order.toString())
    queryParams.set('sortBy', sortParam === 'asc' ? 'desc' : 'asc')

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  const handleNavigateUserDetailPage = (id: string | undefined) => {
    if (!id) return
    const index = userIds.indexOf(id)

    navigate(
      {
        pathname: '/customer-detail',
        search: createSearchParams({
          id,
          currentTab: customerDetailTab
        }).toString()
      },
      {
        state: {
          location: { ids: userIds, index }
        }
      }
    )
  }

  const handleConfirmReservation = (record: Column) => {
    const key = record.key

    const formatDesiredDatetime = (index: number) => {
      const dateValue: string | undefined = getValues(
        `date${index}-${key}-date`
      )
      const isMorning: boolean | undefined = getValues(
        `date${index}-${key}-time`
      )

      if (!dateValue) return null

      const date = dayjs(dateValue).format('YYYY-MM-DD')
      const timeOfDay = isMorning

      return {
        priority: index,
        date,
        timeOfDay
      }
    }

    const desiredDateTime = [1, 2, 3]
      .map(formatDesiredDatetime)
      .filter((item): item is NonNullable<typeof item> => item !== null)

    navigate(
      {
        pathname: PATHS.RESERVATION_CHANGE
      },
      {
        state: {
          refId: record.refId,
          userId: record.userId,
          userFullName: record.userFullName,
          courseId: record.courseId,
          courseOptionIds: record.courseOptionIds,
          courseName: record.courseName,
          courseSelect: record.courseSelect,
          status: record.status,
          desiredDateTime,
          userInfo: record.userInfo,
          action: 'confirm'
        }
      }
    )
  }

  const handleEditReservation = (record: Column) => {
    navigate(
      {
        pathname: PATHS.RESERVATION_CHANGE
      },
      {
        state: {
          refId: record.refId,
          userId: record.userId,
          userFullName: record.userFullName,
          courseId: record.courseId,
          courseOptionIds: record.courseOptionIds,
          courseName: record.courseName,
          courseSelect: record.courseSelect,
          status: record.status,
          desiredDateTime: record.desiredDatetime,
          userInfo: record.userInfo,
          action: 'edit'
        }
      }
    )
  }

  const handleUpdateDesiredDatetime = (
    key: string,
    refId: string,
    indexNumber: number
  ) => {
    const formatDesiredDatetime = (index: number) => {
      const dateValue: string | undefined = getValues(
        `date${index}-${key}-date`
      )
      const isMorning: boolean | undefined = getValues(
        `date${index}-${key}-time`
      )

      if (!dateValue) return null

      const date = dayjs(dateValue).format('YYYY-MM-DD')
      const timeOfDay = isMorning
        ? t('reservationManagement.content.button.morning')
        : t('reservationManagement.content.button.afternoon')

      return {
        priority: index,
        date,
        timeOfDay,
        isUpdate: index === indexNumber
      }
    }

    const desiredDateTime = [1, 2, 3]
      .map(formatDesiredDatetime)
      .filter((item): item is NonNullable<typeof item> => item !== null)

    onUpdateDesiredReservation({ refId, desiredDateTime }).finally()
  }

  const SortableColumnTitle = ({
    title,
    sortKey
  }: {
    title: string
    sortKey: string
  }) => (
    <Flex justify="center" align="center">
      <Typography className="mr-4 font-normal">{t(title)}</Typography>

      <ArrowTopBottom
        className="cursor-pointer"
        onClick={() => handleSort(sortKey)}
      />
    </Flex>
  )

  const columns: TableColumnsType<Column> = [
    {
      title: (
        <SortableColumnTitle
          title="reservationManagement.content.ID"
          sortKey="userId"
        />
      ),
      dataIndex: 'userId',
      width: '15%',
      render: (value) => (
        <Typography className="h-[22px] whitespace-nowrap px-3">
          {value}
        </Typography>
      )
    },
    {
      title: (
        <SortableColumnTitle
          title="reservationManagement.content.fullName"
          sortKey="userFullName"
        />
      ),
      dataIndex: 'userFullName',
      width: '13%',
      render: (value, record) =>
        value ? (
          <Flex className="px-3">
            <button
              type="button"
              className="text-sm font-bold text-[#137695] underline break-all text-left"
              onClick={() => handleNavigateUserDetailPage(record.userId)}
            >
              {value}
            </button>
          </Flex>
        ) : (
          <Typography className="px-3">ー</Typography>
        )
    },
    {
      title: (
        <SortableColumnTitle
          title="reservationManagement.content.courseName"
          sortKey="courseName"
        />
      ),
      dataIndex: 'courseName',
      width: '14%',
      render: (value, record) => {
        return record.isCourseDeleted || record.isUserDeleted ? (
          <Typography className="px-3">ー</Typography>
        ) : (
          <Typography className="px-3">{value}</Typography>
        )
      }
    },
    {
      title: (
        <SortableColumnTitle
          title="reservationManagement.content.courseSelect"
          sortKey="courseSelect"
        />
      ),
      dataIndex: 'courseSelect',
      width: '10%',
      render: (value) =>
        value ? (
          <Typography className="px-3">{value}</Typography>
        ) : (
          <Typography className="px-3">ー</Typography>
        )
    },
    {
      title: (
        <SortableColumnTitle
          title="reservationManagement.content.reservationStatus"
          sortKey="status"
        />
      ),
      dataIndex: 'status',
      width: '14%',
      render: (value) => (
        <Flex justify="center" align="center" className="h-[26px]">
          {value === RESERVATION_STATUS.UNCONFIRMED && (
            <Typography
              style={{
                background: '#137695'
              }}
              className="min-w-[103px] rounded-[13px] px-3 py-[2px] text-center text-sm font-bold text-white"
            >
              {t('reservationManagement.widget.status.waitConfirm')}
            </Typography>
          )}

          {value === RESERVATION_STATUS.COLLECTED_DESIRED_DATE && (
            <Typography
              style={{
                background: '#DF2475'
              }}
              className="min-w-[103px] rounded-[13px] px-3 py-[2px] text-center text-sm font-bold text-white"
            >
              {t('reservationManagement.widget.status.collectDate')}
            </Typography>
          )}
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="font-normal">
            {t('reservationManagement.content.date1')}
          </Typography>
        </Flex>
      ),
      key: 'date1',
      width: '8%',
      render: (_, record) => {
        const { date, timePeriod } = getDayTimeAtPriority(
          1,
          record.desiredDatetime
        )

        return record.status === RESERVATION_STATUS.COLLECTED_DESIRED_DATE &&
          !!date ? (
          <RowForm
            name={`date1-${record.key}`}
            dateString={date}
            time={timePeriod}
            control={control}
            onUpdate={() => {
              handleUpdateDesiredDatetime(record.key, record.refId, 1)
            }}
          />
        ) : (
          <Typography className="px-3 text-center">ー</Typography>
        )
      },
      onCell: (record) => ({
        colSpan: record.status === RESERVATION_STATUS.UNCONFIRMED ? 0 : 1
      })
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="font-normal">
            {t('reservationManagement.content.date2')}
          </Typography>
        </Flex>
      ),
      key: 'date2',
      width: '8%',
      render: (_, record) => {
        const { date, timePeriod } = getDayTimeAtPriority(
          2,
          record.desiredDatetime
        )

        return record.status === RESERVATION_STATUS.COLLECTED_DESIRED_DATE &&
          !!date ? (
          <RowForm
            name={`date2-${record.key}`}
            dateString={date}
            time={timePeriod}
            control={control}
            onUpdate={() => {
              handleUpdateDesiredDatetime(record.key, record.refId, 2)
            }}
          />
        ) : (
          <Typography className="px-3 text-center">ー</Typography>
        )
      },
      onCell: (record) => ({
        colSpan: record.status === RESERVATION_STATUS.UNCONFIRMED ? 0 : 1
      })
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="font-normal">
            {t('reservationManagement.content.date3')}
          </Typography>
        </Flex>
      ),
      key: 'date3',
      width: '8%',
      render: (_, record) => {
        const { date, timePeriod } = getDayTimeAtPriority(
          3,
          record.desiredDatetime
        )

        switch (record.status) {
          case RESERVATION_STATUS.COLLECTED_DESIRED_DATE:
            return date ? (
              <RowForm
                name={`date3-${record.key}`}
                dateString={date}
                time={timePeriod}
                control={control}
                onUpdate={() => {
                  handleUpdateDesiredDatetime(record.key, record.refId, 3)
                }}
              />
            ) : (
              <Typography className="px-3 text-center">ー</Typography>
            )
          case RESERVATION_STATUS.UNCONFIRMED:
            return (
              <Flex
                justify="center"
                align="center"
                className="mx-5 border-[1px] border-[#CDD6DD] bg-[#EFF3F6] py-1"
              >
                <Checked />
                <Typography className="ml-3 min-w-[120px]">
                  {dayjs(record.date).format(formatDate)}
                </Typography>
                <Typography className="">
                  {record?.time?.slice(0, 5) ?? '00:00'}-
                </Typography>
              </Flex>
            )
          default:
            return <Typography className="px-3 text-center">ー</Typography>
        }
      },
      onCell: (record) => ({
        colSpan: record.status === RESERVATION_STATUS.UNCONFIRMED ? 3 : 1
      })
    },
    {
      title: (
        <Typography className="text-center font-normal">
          {t('reservationManagement.content.action')}
        </Typography>
      ),
      key: 'action',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        const isDisabled = record.isUserDeleted || record.isCourseDeleted

        switch (record.status) {
          case RESERVATION_STATUS.COLLECTED_DESIRED_DATE:
            return (
              <Button
                type="primary"
                htmlType="submit"
                disabled={isDisabled}
                style={{
                  background: isDisabled ? '#BFC6CB' : '',
                  color: isDisabled ? 'white' : ''
                }}
                onClick={() => {
                  handleConfirmReservation(record)
                }}
                autoInsertSpace={false}
                className="h-[30px] min-w-[84px] rounded-[3px] border-none bg-[#137695] text-center text-[14px] font-bold text-white shadow-none"
              >
                {t('reservationManagement.content.button.reservation')}
              </Button>
            )
          case RESERVATION_STATUS.UNCONFIRMED:
            return (
              <Button
                type="primary"
                htmlType="submit"
                disabled={isDisabled}
                style={{
                  background: isDisabled ? '#BFC6CB' : '',
                  color: isDisabled ? 'white' : ''
                }}
                onClick={() => {
                  handleEditReservation(record)
                }}
                autoInsertSpace={false}
                className="h-[30px] min-w-[84px] rounded-[3px] border-none bg-[#545454] text-center text-[14px] font-bold text-white shadow-none"
              >
                {t('reservationManagement.content.button.change')}
              </Button>
            )
          default:
            return <Typography className="px-3 text-center">ー</Typography>
        }
      }
    },
    {
      title: '',
      render: () => null
    }
  ]

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (keys: React.Key[]) => setSelectedRowKeys(keys)
    }),
    [selectedRowKeys]
  )

  const dataSource = initialData.map((item, index) => {
    return {
      ...item,
      key: `${RESERVATION_MANAGEMENT_TAB.RESERVATION_COLLECTION_LIST}-${index}`
    }
  })

  return (
    <Table
      loading={loading}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      scroll={{
        y: 'calc(100vh - 280px)',
        scrollToFirstRowOnChange: true
      }}
      className={`${styles.table_wrapper_2} mt-[-10px] py-4 pt-0`}
    />
  )
}

export default ReservationCollection
