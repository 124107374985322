import { gql } from '@apollo/client'

export const DELETE_RESERVATION = gql`
  mutation cancelReservation($refId: String!) {
    cancelReservation(refId: $refId) {
      message
      payload {
        refId
      }
      status
      timestamp
    }
  }
`
