import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals'

type Props = {
  isModalOpen: boolean
  onDelete: () => void
}

const DeleteCompletedModal = ({ isModalOpen = false, onDelete }: Props) => {
  const { t } = useTranslation()

  const handleOk = () => {
    //
  }

  return (
    <CustomModal open={isModalOpen} onOk={handleOk} onCancel={onDelete}>
      <Flex
        vertical
        className="pt-[16px] pb-[20px] justify-center items-center"
      >
        <Typography className="text-[20px] text-[#545454] font-bold tracking-[1px] leading-none">
          {t('content.accountDeletionCompleted')}
        </Typography>
        <div className="mt-[177px]">
          <Typography className="leading-none tracking-[.7px]">
            {t('content.yourAccountDeleted')}
          </Typography>
        </div>

        <Button
          autoInsertSpace={false}
          className="w-[180px] h-[30px] font-bold text-[#137695] border border-[#137695] mt-[173px] tracking-[.7px]"
          onClick={onDelete}
        >
          {t('button.close')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default DeleteCompletedModal
