import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Col, Flex, Input, Typography } from 'antd'
import * as yup from 'yup'

import InspectionResultByType from './InspectionResultByType'
import { InspectionResultCategoryList } from './InspectionResultCategoryList'
import InstructionsAndComments from './InstructionsAndComments'
import PatientInfoForm from './PatientInfoForm'
import SaveInspectionResultModal from './SaveInspectionResultModal'
import SaveSuccessModal from './SaveSuccessModal'
import TemporarySaveModal from './TemporarySaveModal'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as SearchOutlinedIcon } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as CheckedIcon } from 'assets/svgs/checked-38.svg'
import { SpinLoading } from 'components/screens/Spin'
import { openNotification } from 'components/widgets/Notification'
import { OVERALL_VERDICT_CATEGORY_EXAMPLE } from 'configs/api'
import {
  BASIC_INFORMATION_CODE,
  GENDER,
  MEDICAL_CHECKUP_STATUS,
  META_DATA_MEDICAL_CHECKUP_MASTER,
  readingTypeItemMaster
} from 'configs/constant'
import { useAuth } from 'context/AuthProvider'
import { useGetCheckupUsers, useGetUserDetails } from 'hooks/useCheckupUser'
import useInspectionResultCreate from 'hooks/useInspectionResultCreate'
import {
  useCreateMedicalCheckup,
  useMedicalCheckupDetails,
  useUpdateMedicalCheckup
} from 'hooks/useMedicalCheckup'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import { handleMetaData } from 'hooks/useProductMaster'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMaster from 'hooks/useTestItemMaster'
import useTestResult, {
  useCreateTestResult,
  useUpdateTestResult
} from 'hooks/useTestResult'
import { Options, StringKeyObject } from 'types/common'
import { calculateAge } from 'utilities/common'
import {
  getCurrentDateFormatted,
  getLanguage,
  getUserByToken,
  isObjectEmpty,
  uuid
} from 'utilities/helpers'

export const infoFixedCategory = [
  {
    code: '03.0001',
    order: 1,
    comprehensiveJudgment: true
  },
  {
    code: '03.0152',
    order: 2
  }
  // {
  //   code: '03.0004',
  //   order: 3
  // },
  // {
  //   code: '1.00069',
  //   order: 4
  // }
  // {
  //   code: '03.0005',
  //   order: 5
  // }
]

const FULL_NAME = 'full_name'
const PATIENT_ID = 'patient_id'
const SEX = 'sex'
const DATE_OF_BIRTH = 'date_of_birth'
const MEDICAL_CHECKUP_COURSE = 'medical_checkup_course'

const basicInformation = [
  {
    code: BASIC_INFORMATION_CODE.CHECKUP_DATE,
    order: 1,
    disable: false
  },
  {
    code: '1.00005',
    order: 2,
    field: FULL_NAME,
    disable: true
  },
  {
    code: '1.00004',
    order: 3,
    field: PATIENT_ID,
    disable: true
  },
  {
    code: '1.00011',
    order: 4,
    field: SEX,
    disable: true
  },
  {
    code: '1.0001',
    order: 5,
    field: DATE_OF_BIRTH,
    disable: true
  }
]

const basicInformationDefault = [
  {
    refId: MEDICAL_CHECKUP_COURSE,
    order: 6,
    disable: false,
    isDefault: true,
    name: 'lable.consultantRecordNumber'
  }
]

type DataForm = {
  [MEDICAL_CHECKUP_COURSE]: string
}

const initialSchema = {}

const enumValueEvaluation = ['A', 'B', 'C']

export default function InspectionResultCreate() {
  const { t } = useTranslation('')
  let language = getLanguage()
  const navigate = useNavigate()
  const {
    optionsMedicalCheckupMaster,
    loading,
    setLoading,
    getPeriodConfigs,
    setOptionsMedicalCheckupMaster
  } = useInspectionResultCreate()
  const { getUserDetailsById } = useGetUserDetails()
  const { getCheckupUsers } = useGetCheckupUsers()
  const { getMedicalCheckupDetailsById } = useMedicalCheckupDetails()

  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { createMedicalCheckup } = useCreateMedicalCheckup()
  const { updateMedicalCheckup } = useUpdateMedicalCheckup()
  const { getItemCategories } = useTestItemCategory()
  const { createTestResult } = useCreateTestResult()
  const { updateTestResult } = useUpdateTestResult()
  const { getListTestResult } = useTestResult()

  const [searchParams, setSearchParams] = useSearchParams()
  const [userContext] = useState<any>(useAuth()?.user ?? getUserByToken())
  const [temporarySave, setTemporarySave] = useState<boolean>(false)
  const [save, setSave] = useState<boolean>(false)
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
  const [status, setStatus] = useState<any>(null)
  const [user, setUser] = useState<any>({})
  const [medicalCheckupMaster, setMedicalCheckupMaster] = useState<any>({})
  const [medicalCheckup, setMedicalCheckup] = useState<any>({})
  const [medicalCheckupMasterInfo, setMedicalCheckupMasterInfo] = useState<any>(
    []
  )
  const [dataCategory, setDataCategory] = useState<any>([])
  const [dataTestResult, setDataTestResult] = useState<any>(null)
  const [isFirstDataFetch, setIsFirstDataFetch] = useState<boolean>(false)
  const [fixedCategory, setFixedCategory] = useState<any>([])
  const [overallVerdict, setOverallVerdict] = useState<any>([])
  const [valueAssessment, setValueAssessment] = useState<any>({})
  const [changeCommentOverallVerdict, setChangeCommentOverallVerdict] =
    useState<any>(false)
  const [nameComprehensiveJudgment, setNameComprehensiveJudgment] =
    useState<any>(null)
  const [checkupDateId, setCheckupDateId] = useState<string | undefined>(
    undefined
  )
  const [periodConfig, setPeriodConfig] = useState<StringKeyObject>({})
  const [listPeriodConfig, setListPeriodConfig] = useState<StringKeyObject[]>(
    []
  )

  const [schema, setSchema] = useState<any>(initialSchema)
  const [resolverSchema, setResolverSchema] = useState<any>(
    yup.object().shape(schema)
  )
  const methods = useForm<DataForm>({
    resolver: yupResolver(resolverSchema),
    mode: 'onBlur'
  })

  useEffect(() => {
    setResolverSchema(yup.object().shape(schema))
  }, [schema])

  const {
    handleSubmit,
    setValue,
    getValues,
    trigger: triggerValidation,
    watch
  } = methods

  const examinationDate = checkupDateId
    ? watch(checkupDateId as keyof DataForm)
    : ''

  const medicalCheckupMasterId = watch(MEDICAL_CHECKUP_COURSE)

  //Initialization data
  useEffect(() => {
    const idUser = searchParams.get('userRefId')
    const refId = searchParams.get('refId')

    if (idUser) {
      getDataUser(idUser)
    }

    getFixedData()

    if (refId) {
      const fetchDataMedicalCheckup = async () => {
        const data = await getMedicalCheckupDetailsById(refId)
        if (
          data &&
          data?.medicalCheckup?.additionalInfo?.healthCheckStatus !==
            MEDICAL_CHECKUP_STATUS.SUBMITTED
        ) {
          setUser(data?.checkupUser?.[0])
          setMedicalCheckup(data?.medicalCheckup)

          const fetchMedicalCheckupMaster = await getMedicalCheckupMastersById(
            data?.medicalCheckup?.medicalCheckupMasterRef,
            [
              {
                field: 'additionalInfo.objId',
                operator: 'eq',
                value:
                  data?.medicalCheckup?.additionalInfo
                    ?.medicalCheckupMasterObjId
              }
            ]
          )

          const medicalCheckupMaster =
            fetchMedicalCheckupMaster?.medicalCheckupMaster

          setMedicalCheckupMaster(medicalCheckupMaster)
          if (medicalCheckupMaster?.additionalInfo?.objId) {
            await handleDataPeriodConfigs(
              medicalCheckupMaster?.additionalInfo?.objId
            )
          }

          handleSurvey(medicalCheckupMaster)

          setValue(
            MEDICAL_CHECKUP_COURSE,
            data?.medicalCheckup?.medicalCheckupMasterRef
          )
        } else {
          navigate('/customer-management')
        }
      }
      fetchDataMedicalCheckup()
    }

    if (!refId && searchParams.has('surveyRefId')) {
      const newParams = new URLSearchParams(searchParams)
      newParams.delete('surveyRefId')
      setSearchParams(newParams)
    }
  }, [])

  // Show user information when creating new
  useEffect(() => {
    if (medicalCheckupMasterInfo.length > 0 && user && !isObjectEmpty(user)) {
      if (searchParams.get('refId')) return

      handleUserInfo(user)
    }
  }, [medicalCheckupMasterInfo, user])

  // Get data first time when refId is available
  useEffect(() => {
    if (medicalCheckupMasterInfo.length > 0 && dataCategory.length > 0) {
      if (isFirstDataFetch || !searchParams.get('refId')) return

      handleDataMedicalCheckup(searchParams.get('refId'))

      setIsFirstDataFetch(true)
    }
  }, [medicalCheckupMasterInfo, dataCategory])

  // List category, items, reference value when creating new
  useEffect(() => {
    if (searchParams.get('refId') || !medicalCheckupMasterId) return

    if (
      medicalCheckupMasterId === medicalCheckupMaster?.refId &&
      medicalCheckupMaster?.additionalInfo?.objId &&
      dataCategory.length > 0
    ) {
      handleLogicReferenceValue(
        dataCategory,
        medicalCheckupMaster?.additionalInfo?.objId,
        examinationDate
      )
      return
    }

    handleDataShow(`${medicalCheckupMasterId}`, examinationDate)
  }, [examinationDate, medicalCheckupMasterId])

  // optionsMedicalCheckupMaster when updating
  useEffect(() => {
    if (
      !searchParams.get('refId') ||
      !medicalCheckupMaster ||
      isObjectEmpty(medicalCheckupMaster)
    )
      return

    setOptionsMedicalCheckupMaster([
      {
        ...medicalCheckupMaster,
        key: medicalCheckupMaster?.refId ?? '',
        value: medicalCheckupMaster?.refId ?? '',
        label: medicalCheckupMaster?.displayName ?? ''
      }
    ])
  }, [medicalCheckupMaster])

  // List category, items, reference value when updating
  useEffect(() => {
    if (
      !searchParams.get('refId') ||
      !medicalCheckupMaster ||
      isObjectEmpty(medicalCheckupMaster) ||
      !medicalCheckup ||
      isObjectEmpty(medicalCheckup)
    )
      return

    if (dataCategory.length > 0) {
      handleLogicReferenceValue(
        dataCategory,
        medicalCheckupMaster?.additionalInfo?.objId,
        examinationDate
      )
      return
    }

    handleDataShow(
      `${medicalCheckup?.medicalCheckupMasterRef}`,
      examinationDate,
      medicalCheckupMaster
    )
  }, [examinationDate, medicalCheckupMaster, medicalCheckup])

  const handleSurvey = (medicalCheckupMaster: StringKeyObject) => {
    const metaData = medicalCheckupMaster?.additionalInfo?.metadata ?? []

    const metaDataObj = handleMetaData(metaData)
    const metaDataSurvey =
      metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SURVEYS]
    const surveyRefId = Array.isArray(metaDataSurvey) ? metaDataSurvey[0] : null

    if (surveyRefId) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set('surveyRefId', surveyRefId)
      setSearchParams(newParams)
    }

    if (!surveyRefId && searchParams.has('surveyRefId')) {
      const newParams = new URLSearchParams(searchParams)
      newParams.delete('surveyRefId')
      setSearchParams(newParams)
    }
  }
  const handleDataShow = async (
    medicalCheckupMasterId: string,
    examinationDate: string,
    medicalCheckupMaster?: StringKeyObject
  ) => {
    setLoading(true)
    let dataMedicalCheckupMaster = medicalCheckupMaster
    if (!dataMedicalCheckupMaster) {
      const fetchMedicalCheckupMaster = await getMedicalCheckupMastersById(
        medicalCheckupMasterId
      )
      dataMedicalCheckupMaster = fetchMedicalCheckupMaster?.medicalCheckupMaster

      if (!dataMedicalCheckupMaster) {
        setLoading(false)
        setDataCategory([])
        return
      }
      setMedicalCheckupMaster(dataMedicalCheckupMaster)
      handleSurvey(dataMedicalCheckupMaster)
    }

    const categoryStructure =
      dataMedicalCheckupMaster?.additionalInfo?.categories

    if (!Array.isArray(categoryStructure)) {
      setLoading(false)
      setDataCategory([])
      return
    }

    const itemMasterIds: string[] = []
    const categoryIds = categoryStructure.map((category: StringKeyObject) => {
      if (Array.isArray(category?.items)) {
        itemMasterIds.push(
          ...category.items.map((item: StringKeyObject) => item.refId)
        )
      }
      return category.refId
    })

    const dataCategoryFetch =
      (await getItemCategories([
        {
          type: 'MATCH',
          criteria: {
            and: [
              {
                field: 'refId',
                operator: 'in',
                value: [...categoryIds, OVERALL_VERDICT_CATEGORY_EXAMPLE]
              }
            ]
          }
        }
      ])) ?? []

    const itemMasterIdsCategory: string[] = []
    dataCategoryFetch?.forEach((category: StringKeyObject) => {
      if (Array.isArray(category?.associatedTestItemMasterRefIds)) {
        itemMasterIdsCategory.push(...category.associatedTestItemMasterRefIds)
      }
    })

    const dataItemMasterFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: [...itemMasterIds, ...itemMasterIdsCategory]
            },
            {
              field: 'additionalInfo.medicalCheckupMasterObjId',
              operator: 'eq',
              value: dataMedicalCheckupMaster?.additionalInfo?.objId
            }
          ]
        }
      }
    ])

    const dataCommentFetch = await fetchCommentCategory(itemMasterIdsCategory)

    const categoryFormat: StringKeyObject[] = []

    for (const category of dataCategoryFetch) {
      const categoryData = Array.isArray(
        category?.associatedTestItemMasterRefIds
      )
        ? getCommentCategory(
            category?.associatedTestItemMasterRefIds,
            dataCommentFetch
          )
        : []

      const comments = categoryData.filter(
        (item: StringKeyObject) => item?.additionalInfo?.key3 === '所見コメント'
      )

      if (category?.refId === OVERALL_VERDICT_CATEGORY_EXAMPLE) {
        setOverallVerdict(comments)
        continue
      }

      const itemMasterCategory = categoryData.find(
        (item: StringKeyObject) => item?.additionalInfo?.key3 !== '所見コメント'
      )

      const itemMasterCategoryShow = dataItemMasterFetch.find(
        (itemMaster: StringKeyObject) =>
          itemMaster.refId === itemMasterCategory?.refId
      )

      if (!itemMasterCategoryShow) continue

      const categoryItem = categoryStructure.find(
        (item: StringKeyObject) => item.refId === category.refId
      )

      const itemMasters = Array.isArray(categoryItem?.items)
        ? getItemMasterCategory(categoryItem.items, dataItemMasterFetch)
        : []

      if (itemMasters.length > 0) {
        itemMasters.sort(
          (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
        )

        categoryFormat.push({
          refId: category.refId,
          shortName: category.shortName,
          associatedTestItemMasterRefIds:
            category?.associatedTestItemMasterRefIds,
          itemMaster: itemMasters,
          comment: comments,
          idItemMaster: itemMasterCategoryShow?.refId,
          lang: itemMasterCategoryShow?.lang,
          order: categoryItem?.order ?? 9999
        })
      }
    }

    const validationSchema = categoryFormat.reduce(
      (acc: StringKeyObject, field: StringKeyObject) => {
        let validator = yup.string()

        return {
          ...acc,
          [field.refId]: validator,
          [field.refId + '_comment']: validator
        }
      },
      {}
    )

    categoryFormat.sort(
      (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
    )

    if (dataMedicalCheckupMaster?.additionalInfo?.objId) {
      await handleLogicReferenceValue(
        categoryFormat,
        dataMedicalCheckupMaster?.additionalInfo?.objId,
        examinationDate,
        !medicalCheckupMaster
      )
    } else {
      setDataCategory(categoryFormat)
      setListPeriodConfig([])
    }

    setSchema((prev: any) => ({
      ...prev,
      ...validationSchema
    }))

    setLoading(false)
  }

  const handleLogicReferenceValue = async (
    dataCategories: StringKeyObject[],
    medicalCheckupMasterObjId: string,
    examinationDate?: string,
    isChangeMedicalCheckupMaster = false
  ) => {
    const categories = JSON.parse(JSON.stringify(dataCategories))
    const dataReferenceValue = !isChangeMedicalCheckupMaster
      ? listPeriodConfig
      : await handleDataPeriodConfigs(medicalCheckupMasterObjId)

    let dataReferenceValueFind

    const date = examinationDate
      ? new Date(examinationDate).getTime()
      : new Date().getTime()

    if (
      searchParams.get('refId') &&
      checkupDateId &&
      dataTestResult?.[checkupDateId]?.value === examinationDate
    ) {
      dataReferenceValueFind = dataReferenceValue.find(
        (item: StringKeyObject) =>
          item?.additionalInfo?.objId ===
          medicalCheckup?.additionalInfo?.periodConfigObjId
      )
    } else {
      dataReferenceValueFind = dataReferenceValue.find(
        (item: StringKeyObject) => {
          if (!item.startDate && !item.endDate) return true
          if (!item.startDate && item.endDate)
            return new Date(item.endDate).getTime() >= date
          if (item.startDate && !item.endDate)
            return new Date(item.startDate).getTime() <= date

          const startDate = new Date(item.startDate).getTime()
          const endDate = new Date(item.endDate).getTime()
          return startDate <= date && date <= endDate
        }
      )

      if (!dataReferenceValueFind) {
        const dataReferenceValueFilter = dataReferenceValue.filter(
          (item: StringKeyObject) => {
            return item.endDate && new Date(item.endDate).getTime() <= date
          }
        )

        dataReferenceValueFilter.sort(
          (a: StringKeyObject, b: StringKeyObject) => {
            return new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
          }
        )

        dataReferenceValueFind = dataReferenceValueFilter[0]
      }
    }

    setPeriodConfig(dataReferenceValueFind || {})

    const evaluations: StringKeyObject[] = [
      ...(dataReferenceValueFind?.evaluations ?? [])
    ].sort((a: StringKeyObject, b: StringKeyObject) => a.order - b.order)

    const [evaluationA, ...otherReferences] = evaluations

    const referenceValues = evaluationA?.referenceValues ?? []

    const referenceValuesObj = referenceValues.reduce(
      (acc: any, item: StringKeyObject) => {
        return { ...acc, [item.testItemMasterRefId]: item.constraint }
      },
      {}
    )

    const referenceValuesObjOther: StringKeyObject = {}

    for (const otherReference of otherReferences) {
      const referenceValuesOther = otherReference?.referenceValues
      if (!Array.isArray(referenceValuesOther)) continue

      for (const item of referenceValuesOther) {
        const refId = item.testItemMasterRefId
        const constraints = item.constraint ?? []
        referenceValuesObjOther[refId] ||= []
        referenceValuesObjOther[refId].push(...constraints)
      }
    }

    const categoryFormat = categories.map((item: StringKeyObject) => {
      const itemMaster = item.itemMaster.map((itemMaster: StringKeyObject) => {
        const refValues = referenceValuesObj[itemMaster.refId] ?? []
        const refValuesOther = referenceValuesObjOther[itemMaster.refId] ?? []

        const referenceValuesItemMaster =
          itemMaster.readingType === readingTypeItemMaster.NUMERICAL
            ? refValues
            : [...refValues, ...refValuesOther]

        return {
          ...itemMaster,
          referenceValues: referenceValuesItemMaster
        }
      })

      return {
        ...item,
        itemMaster: itemMaster
      }
    })

    setDataCategory(categoryFormat)
  }

  const handleDataPeriodConfigs = async (medicalCheckupMasterObjId: string) => {
    const fetchDataReferenceValue = await getPeriodConfigs(
      medicalCheckupMasterObjId
    )
    const dataReferenceValue =
      fetchDataReferenceValue?.data?.listPeriodConfigs?.payload ?? []

    setListPeriodConfig(dataReferenceValue)
    return dataReferenceValue
  }

  const getDataUser = async (idUser: string) => {
    const user = await getUserDetailsById(idUser)

    if (user) {
      setUser(user)
    } else {
      navigate('/')
    }
  }

  const getFixedData = async () => {
    const arrCodes = [...infoFixedCategory, ...basicInformation]
    const arrCodesMap = arrCodes.map((item) => item.code)

    let itemMaster = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'code.coding.code',
              operator: 'in',
              value: arrCodesMap
            }
          ]
        }
      }
    ])

    let itemMasterFixedCategory = []
    let itemMasterBasicInformation = []

    for (let item of itemMaster) {
      const itemCode = item?.code?.coding?.[0]?.code
      const isCategory = infoFixedCategory.find(
        (category) => category.code === itemCode
      )

      if (isCategory) {
        if (isCategory?.comprehensiveJudgment) {
          setNameComprehensiveJudgment(item?.refId)
        }

        itemMasterFixedCategory.push({ ...item, order: isCategory.order })

        continue
      }

      const isItemMasterInfo = basicInformation.find(
        (itemMasterInfo) => itemMasterInfo.code === itemCode
      )

      if (isItemMasterInfo) {
        if (itemCode === BASIC_INFORMATION_CODE.CHECKUP_DATE)
          setCheckupDateId(item.refId)

        itemMasterBasicInformation.push({
          ...item,
          order: isItemMasterInfo.order,
          field: isItemMasterInfo?.field,
          disable: isItemMasterInfo.disable
        })
      }
    }

    itemMasterFixedCategory.sort((a: any, b: any) => a.order - b.order)
    itemMasterBasicInformation.sort((a: any, b: any) => a.order - b.order)

    setFixedCategory(itemMasterFixedCategory)
    setMedicalCheckupMasterInfo(itemMasterBasicInformation)

    let validationSchema = {}
    validationSchema = itemMasterBasicInformation.reduce(
      (acc: any, field: any) => {
        let validator = yup.string()

        return { ...acc, [field.refId]: validator }
      },
      validationSchema
    )
    setSchema((prev: any) => ({
      ...prev,
      ...validationSchema
    }))
  }

  useEffect(() => {
    const runDiagnostics = async () => {
      try {
        if (
          !valueAssessment ||
          Object.keys(valueAssessment).length === 0 ||
          !nameComprehensiveJudgment
        )
          return

        let objectValue = { ...valueAssessment }

        for (let category of dataCategory) {
          let value =
            valueAssessment?.[category?.refId] ?? getValues(category?.refId)
          if (!value) continue

          objectValue[category?.refId] = value
        }

        if (!changeCommentOverallVerdict) setChangeCommentOverallVerdict(true)

        let arrValue = Object.values(objectValue)
        arrValue = arrValue.sort((a, b) =>
          (b as string).localeCompare(a as string)
        )
        for (let value of arrValue) {
          if (enumValueEvaluation.includes(value as string)) {
            setValue(nameComprehensiveJudgment, value)
            break
          }
        }
      } catch (err) {
        console.error(err)
      }
    }

    runDiagnostics()
  }, [valueAssessment])

  const getItemMasterCategory = (
    structureItemMaster: StringKeyObject[],
    listItemMaster: StringKeyObject[]
  ) => {
    const data = []
    let validationSchema = {}

    for (const item of structureItemMaster) {
      const itemMaster = listItemMaster.find(
        (itemMaster) => itemMaster.refId === item.refId
      )
      if (!itemMaster) continue

      let validator: any = yup.string()
      if (itemMaster?.readingType === 'NUMERICAL') {
        validator = yup
          .number()
          .nullable()
          .min(0)
          .transform((value, originalValue) => {
            return originalValue.trim() === '' ? null : value
          })
      }

      validationSchema = {
        ...validationSchema,
        [itemMaster.refId]: validator
      }
      data.push({ ...itemMaster, order: item.order ?? 9999 })
    }

    setSchema((prev: any) => ({
      ...prev,
      ...validationSchema
    }))

    return data
  }

  const getCommentCategory = (
    itemMasterRefIds: string[],
    listItemMaster: StringKeyObject[]
  ) => {
    const data = []
    for (const item of itemMasterRefIds) {
      const itemMaster = listItemMaster.find((itemMaster) => {
        return (
          itemMaster.refId === item &&
          !(
            itemMaster?.additionalInfo?.isHidden == 1 ||
            itemMaster?.additionalInfo?.isDeleted == 1
          )
        )
      })

      if (!itemMaster) continue

      data.push(itemMaster)
    }

    return data
  }

  const fetchCommentCategory = async (ItemMasterRefIds: string[]) => {
    let dataItemMasterFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: ItemMasterRefIds
            },
            {
              field: 'additionalInfo.key1',
              operator: 'eq',
              value: 'カテゴリマスタ'
            }
          ]
        }
      }
    ])

    return dataItemMasterFetch
  }

  const onSubmit = () => {
    if (!handleCheckSave()) return
    setSave(true)
  }

  const onTemporarySave = () => {
    if (!handleCheckSave()) return
    setTemporarySave(true)
  }

  const handleCheckSave = () => {
    return checkUserId() && checkMedicalCheckupMaster()
  }

  const checkUserId = () => {
    if (!user?.refId) {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.userIdNotFound')
      })

      return false
    }

    return true
  }

  const checkMedicalCheckupMaster = () => {
    if (!medicalCheckupMasterId) {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('scenarioCreate.drawer.searchCourse.title')
      })
      return false
    }

    return true
  }

  const handleCreateMedicalCheckup = async (
    data: any,
    healthCheckStatus: any
  ) => {
    if (medicalCheckup?.refId) {
      await handleUpdateMedicalCheckup(data, healthCheckStatus)
      return
    }

    let userRefId = user?.refId
    const medicalCheckupMasterObjId =
      medicalCheckupMaster?.additionalInfo?.objId
    const medicalCheckupObjId = uuid(false)

    const requestMedicalCheckup = [
      {
        medicalCheckupMasterRef: medicalCheckupMaster?.refId,
        checkupUserRef: userRefId,
        additionalInfo: {
          healthCheckStatus: healthCheckStatus,
          medicalCheckupMasterObjId: medicalCheckupMasterObjId,
          periodConfigObjId: periodConfig?.additionalInfo?.objId
        }
      }
    ]

    const medicalCheckupCreate: any = await createMedicalCheckup(
      requestMedicalCheckup
    )

    const medicalCheckupId =
      medicalCheckupCreate?.data?.addMedicalCheckup?.payload?.[0]?.refId

    // const medicalCheckupId = '42279366232129054141719911591431'

    const dataCreateTestResultUserInfo = []
    const dataCreateTestResultCategory = []
    const dataCreateTestResultCategoryItem = []
    const dataCreateTestResultCategoryFixed = []

    for (let item of medicalCheckupMasterInfo) {
      dataCreateTestResultUserInfo.push({
        medicalCheckupRefId: medicalCheckupId,
        medicalCheckupUserRefId: user.refId,
        itemMasterRefId: item.refId,
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        readingLocation: '',
        additionalInfo: {
          timestamp: Date.now(),
          key1: '基本情報マスター',
          healthCheckStatus: healthCheckStatus,
          medicalCheckupObjId: medicalCheckupObjId
        },
        evaluation: ''
      })
    }

    for (let item of dataCategory) {
      dataCreateTestResultCategory.push({
        medicalCheckupRefId: medicalCheckupId,
        medicalCheckupUserRefId: user.refId,
        itemMasterRefId: item.idItemMaster,
        value: data[item.refId + '_comment'] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        readingLocation: '',
        additionalInfo: {
          timestamp: Date.now(),
          key1: 'カテゴリマスタ',
          healthCheckStatus: healthCheckStatus,
          medicalCheckupObjId: medicalCheckupObjId
        },
        evaluation: data[item.refId] ?? ''
      })

      for (let itemMaster of item.itemMaster) {
        dataCreateTestResultCategoryItem.push({
          medicalCheckupRefId: medicalCheckupId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: itemMaster.refId,
          value: data[itemMaster.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            key1: '検査項目マスター',
            healthCheckStatus: healthCheckStatus,
            medicalCheckupObjId: medicalCheckupObjId
          },
          evaluation: ''
        })
      }
    }

    for (let item of fixedCategory) {
      dataCreateTestResultCategoryFixed.push({
        medicalCheckupRefId: medicalCheckupId,
        medicalCheckupUserRefId: user.refId,
        itemMasterRefId: item.refId,
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        readingLocation: '',
        additionalInfo: {
          timestamp: Date.now(),
          healthCheckStatus: healthCheckStatus,
          medicalCheckupObjId: medicalCheckupObjId
        },
        evaluation: ''
      })
    }

    await createTestResult([
      ...dataCreateTestResultUserInfo,
      ...dataCreateTestResultCategory,
      ...dataCreateTestResultCategoryItem,
      ...dataCreateTestResultCategoryFixed
    ])
  }

  const handleUpdateMedicalCheckup = async (
    data: any,
    healthCheckStatus: any
  ) => {
    const { visibility, createdDate, ...dataMedicalCheckupUpdate } =
      medicalCheckup
    const medicalCheckupMasterObjId =
      medicalCheckupMaster?.additionalInfo?.objId
    const medicalCheckupObjId = uuid(false)
    const requestMedicalCheckup = [
      {
        ...dataMedicalCheckupUpdate,
        additionalInfo: {
          healthCheckStatus: healthCheckStatus,
          objId: medicalCheckupObjId,
          medicalCheckupMasterObjId: medicalCheckupMasterObjId,
          periodConfigObjId: periodConfig?.additionalInfo?.objId
        }
      }
    ]

    await updateMedicalCheckup(requestMedicalCheckup)

    const dataUpdateTestResultUserInfo = []
    const dataUpdateTestResultCategory = []
    const dataUpdateTestResultCategoryItem = []
    const dataUpdateTestResultCategoryFixed = []

    const dataCreateTestResult = []
    for (let item of medicalCheckupMasterInfo) {
      if (!dataTestResult[item.refId]) {
        dataCreateTestResult.push({
          medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: item.refId,
          value: data[item.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            key1: '基本情報マスター',
            healthCheckStatus: healthCheckStatus,
            medicalCheckupObjId: medicalCheckupObjId
          },
          evaluation: ''
        })

        continue
      }

      dataUpdateTestResultUserInfo.push({
        ...dataTestResult[item.refId],
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        additionalInfo: {
          timestamp: Date.now(),
          key1: '基本情報マスター',
          medicalCheckupObjId: medicalCheckupObjId
        }
      })
    }

    for (let item of dataCategory) {
      if (!dataTestResult[item.idItemMaster]) {
        dataCreateTestResult.push({
          medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: item.idItemMaster,
          value: data[item.refId + '_comment'] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            key1: 'カテゴリマスタ',
            healthCheckStatus: healthCheckStatus,
            medicalCheckupObjId: medicalCheckupObjId
          },
          evaluation: data[item.refId] ?? ''
        })
      } else {
        dataUpdateTestResultCategory.push({
          ...dataTestResult[item.idItemMaster],
          value: data[item.refId + '_comment'] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          additionalInfo: {
            timestamp: Date.now(),
            key1: 'カテゴリマスタ',
            medicalCheckupObjId: medicalCheckupObjId
          },
          evaluation: data[item.refId] ?? ''
        })
      }

      for (let itemMaster of item.itemMaster) {
        if (!dataTestResult[itemMaster.refId]) {
          dataCreateTestResult.push({
            medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
            medicalCheckupUserRefId: user.refId,
            itemMasterRefId: itemMaster.refId,
            value: data[itemMaster.refId] ?? '',
            valueBy: userContext?.name,
            readingTakenOn: getCurrentDateFormatted(),
            readingLocation: '',
            additionalInfo: {
              timestamp: Date.now(),
              key1: '検査項目マスター',
              healthCheckStatus: healthCheckStatus,
              medicalCheckupObjId: medicalCheckupObjId
            },
            evaluation: ''
          })

          continue
        }

        dataUpdateTestResultCategoryItem.push({
          ...dataTestResult[itemMaster.refId],
          value: data[itemMaster.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          additionalInfo: {
            timestamp: Date.now(),
            key1: '検査項目マスター',
            medicalCheckupObjId: medicalCheckupObjId
          }
        })
      }
    }

    for (let item of fixedCategory) {
      if (!dataTestResult[item.refId]) {
        dataCreateTestResult.push({
          medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: item.refId,
          value: data[item.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            healthCheckStatus: healthCheckStatus,
            medicalCheckupObjId: medicalCheckupObjId
          },
          evaluation: ''
        })

        continue
      }
      dataUpdateTestResultCategoryFixed.push({
        ...dataTestResult[item.refId],
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        additionalInfo: {
          timestamp: Date.now(),
          medicalCheckupObjId: medicalCheckupObjId
        }
      })
    }

    if (dataCreateTestResult.length > 0) {
      await createTestResult([...dataCreateTestResult])
    }

    await updateTestResult([
      ...dataUpdateTestResultUserInfo,
      ...dataUpdateTestResultCategory,
      ...dataUpdateTestResultCategoryItem,
      ...dataUpdateTestResultCategoryFixed
    ])
  }

  const handleCheckUserSearch = async (e: any) => {
    if (e.target.value) {
      let dataCheckupUser = await getCheckupUsers({
        filter: `(eq,STRING,refId,${e.target.value})`,
        page: 0,
        size: 1,
        sortBy: '(desc,createdDate)'
      })

      let user = dataCheckupUser?.[0]
      if (user) {
        const newParams = new URLSearchParams(searchParams)
        newParams.set('userRefId', user.refId)
        setSearchParams(newParams)
        setUser(user)
      } else {
        openNotification({
          type: 'error',
          title: t('errors.errorOccurred'),
          message: t('errors.patientIdNotFound')
        })
      }
    }
  }

  const handleDataMedicalCheckup = async (medicalCheckupRefId: any) => {
    let dataItemTestResult = await getListTestResult({
      filter: `(eq,STRING,medicalCheckupRefId,${medicalCheckupRefId});(eq,STRING,status,PUBLISHED)`,
      sortBy: '(desc,readingTakenOn)'
    })
    let dataForm = dataItemTestResult?.data?.filterTestResult?.payload

    const data = dataForm.reduce((acc: any, obj: any) => {
      if (
        !acc[obj.itemMasterRefId] ||
        acc[obj.itemMasterRefId].readingTakenOn < obj.readingTakenOn
      ) {
        acc[obj.itemMasterRefId] = obj
      }
      return acc
    }, {})

    for (let item of medicalCheckupMasterInfo) {
      setValue(item.refId, data?.[item.refId]?.value)
    }

    if (searchParams.get('refId')) {
      setDataTestResult(data)
      setStatus(medicalCheckup?.additionalInfo?.healthCheckStatus)
      for (let item of dataCategory) {
        setValue(item.refId, data?.[item.idItemMaster]?.evaluation)
        let categoryComment: any = `${item.refId}_comment`
        setValue(categoryComment, data?.[item.idItemMaster]?.value)
        for (let itemMaster of item.itemMaster) {
          if (data?.[itemMaster.refId]?.value) {
            setValue(itemMaster.refId, data?.[itemMaster.refId]?.value)
          }
        }
      }

      for (let item of fixedCategory) {
        setValue(item.refId, data?.[item.refId]?.value)
      }
    }
  }

  const handleUserInfo = async (user: any) => {
    for (let item of medicalCheckupMasterInfo) {
      let field = item?.field

      switch (field) {
        case FULL_NAME: {
          const firstName = user?.additionalInfo?.firstName || ''
          const lastName = user?.additionalInfo?.lastName || ''
          setValue(item.refId, `${firstName}${lastName}`.trim())
          break
        }
        case PATIENT_ID:
          setValue(item.refId, user?.refId)
          break
        case SEX:
          setValue(item.refId, user?.gender)
          break
        case DATE_OF_BIRTH:
          setValue(item.refId, user?.birthday)
          break
      }
    }
  }

  const handleChangeRatingValue = (name: string, value: any) => {
    setValueAssessment((prevValues: any) => ({
      ...prevValues,
      [name]: value
    }))
  }

  const getOptionsMedicalCheckupMaster = () => {
    if (!user || isObjectEmpty(user)) return optionsMedicalCheckupMaster

    const userAge = user?.birthday ? calculateAge(user?.birthday) : null
    const userGender = user?.gender
    const result: Options[] = optionsMedicalCheckupMaster.filter(
      (medicalCheckupMaster: StringKeyObject) => {
        const metaData = medicalCheckupMaster?.additionalInfo?.metadata ?? []
        const metaDataObj = handleMetaData(metaData)
        const sex = metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SEX]
        const startAge =
          metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE] ?? 0
        const endAge =
          metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE] ?? 999

        return (
          (sex === GENDER.NONE || sex === userGender) &&
          (userAge === null || (startAge <= userAge && userAge <= endAge))
        )
      }
    )

    return result
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <SpinLoading loading={true} />
      </div>
    )
  }

  return (
    <Flex vertical className="ml-[-10px] mb-87px">
      <Input
        prefix={<SearchOutlinedIcon />}
        placeholder={t('placeholder.search')}
        className="w-[23.5%] ml-2.5"
        disabled={searchParams.get('refId') ? true : false}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleCheckUserSearch(e)
          }
        }}
      />

      <Flex className="mt-4 gap-8">
        <div className="w-[11.7%]">
          <InspectionResultCategoryList
            items={dataCategory}
            fixedCategory={fixedCategory}
            language={language}
          />
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex-1 w-[calc(88.3%-32px)]"
            noValidate
          >
            <Flex>
              {/* {!temporarySave && (
                <Typography className="bg-[#f9d3e3] flex-1 flex items-center text-error leading-none mr-[14.4%]">
                  <ExclamationCircleFilled className="w-[21px] text-error ml-6 mr-3" />
                  未入力の項目が100項目あります。
                </Typography>
              )} */}
              {temporarySave && (
                <Typography className="bg-[#d0e4ea] flex-1 flex items-center text-primary font-semibold leading-none mr-[14.4%]">
                  <CheckedIcon className="rounded-full ml-6 mr-3" />
                  {t('content.temporarilySavedContent')}
                </Typography>
              )}

              <Flex className="justify-end gap-2 flex-1">
                <Button
                  type="primary"
                  className="w-[80px] font-bold"
                  htmlType="submit"
                >
                  {t('button.keep')}
                </Button>
                <Button
                  className="min-w-[80px] border-[#137695] text-[#137695] font-bold"
                  onClick={onTemporarySave}
                >
                  {t('button.saveDraft')}
                </Button>
              </Flex>
            </Flex>

            <div className="mt-5">
              <PatientInfoForm
                medicalCheckupMasterInfo={medicalCheckupMasterInfo}
                basicInformationDefault={basicInformationDefault}
                language={language}
                medicalCheckupCourse={MEDICAL_CHECKUP_COURSE}
                optionsMedicalCheckupMaster={getOptionsMedicalCheckupMaster()}
              />
            </div>

            <Flex className="text-white bg-primary pb-3 pt-3.5 pl-1.5 pr-5 font-bold leading-none mt-[30px]">
              <Col flex="16.9%">{t('testResult.inspectionItem')}</Col>
              <Col flex="12.4%">{t('testResult.referenceValue')}</Col>
              <Col flex="auto">{t('lable.inspectionResult')}</Col>
            </Flex>
            {/* {InspectionResultExample.length > 0 &&
                InspectionResultExample.map((item) => (
                  <InspectionResultByType key={item.name} data={item} />
                ))} */}
            {dataCategory.length > 0 &&
              dataCategory.map((item: any, index: any) => (
                <InspectionResultByType
                  key={item.refId + index}
                  data={item}
                  user={user}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                  dataTestResult={dataTestResult}
                  handleChangeRatingValue={handleChangeRatingValue}
                  language={language}
                  periodConfig={periodConfig}
                />
              ))}
            <InstructionsAndComments
              fixedCategory={fixedCategory}
              overallVerdict={overallVerdict}
              setValue={setValue}
              triggerValidation={triggerValidation}
              changeCommentOverallVerdict={changeCommentOverallVerdict}
              setChangeCommentOverallVerdict={setChangeCommentOverallVerdict}
              language={language}
            />

            {/* <Flex
                className="bg-primary gap-12 px-1.5 pt-[14px] pb-3 mt-[26px]"
                id="health_status_report"
              >
                <Typography className="font-bold text-white">
                  健康状態レポート
                </Typography>
              </Flex>
              {HealthStatusExample.length > 0 && (
                <Flex className="flex-wrap gap-[7.6%]">
                  {HealthStatusExample.map((item) => (
                    <div className="w-[34%]" key={item.name}>
                      <HealthStatusReport data={item} />
                    </div>
                  ))}
                </Flex>
              )} */}

            <Flex className="justify-end gap-2 flex-1 mt-12 mb-[131px]">
              <Button
                type="primary"
                className="w-[80px] font-bold"
                htmlType="submit"
              >
                {t('button.keep')}
              </Button>
              <Button
                className="min-w-[80px] border-[#137695] text-[#137695] font-bold"
                onClick={onTemporarySave}
              >
                {t('button.saveDraft')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>

      <SaveSuccessModal
        onCancel={() => {
          setSaveSuccess(false)
          navigate('/customer-management')
        }}
        isModalOpen={saveSuccess}
      />
      <SaveInspectionResultModal
        isModalOpen={save}
        onCancel={() => {
          setSave(false)
        }}
        data={getValues()}
        status={status}
        setSaveSuccess={setSaveSuccess}
        handleCreateMedicalCheckup={handleCreateMedicalCheckup}
      />
      <TemporarySaveModal
        onCancel={() => {
          setTemporarySave(false)
        }}
        onOk={() => {
          setTemporarySave(false)
          navigate('/customer-management')
        }}
        isModalOpen={temporarySave}
        status={status}
        data={getValues()}
        handleCreateMedicalCheckup={handleCreateMedicalCheckup}
      />
    </Flex>
  )
}
