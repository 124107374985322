import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Tabs, Typography } from 'antd'

import BasicInformationSettings from './components/BasicInformationSettings'
import ManageAssignmentsAndPositions from './components/ManageAssignmentsAndPositions'
import ManageMedicalDepartments from './components/ManageMedicalDepartments'
import ScreenLoader from 'components/loading/ScreenLoader'
import useBasicSetting from 'hooks/useBasicSetting'

const BASIC_INFORMATION_SETTINGS_TAB: string = '1'
const MANAGE_MEDICAL_DEPARTMENTS_TAB: string = '2'
const MANAGE_ASSIGNMENTS_AND_POSITIONS_TAB: string = '3'

export default function BasicSetting() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const [tab, setTab] = useState<string>(() => {
    return searchParams.get('t') ?? BASIC_INFORMATION_SETTINGS_TAB
  })

  const {
    tenant,
    departments,
    assignments,
    positions,
    loading,
    setLoad,
    taskProcessingTenantAdditionalInfo
  } = useBasicSetting()

  const dataTabs = [
    {
      key: BASIC_INFORMATION_SETTINGS_TAB,
      label: t('menu.basicSetting'),
      children: <BasicInformationSettings tenant={tenant} setLoad={setLoad} />
    },
    {
      key: MANAGE_MEDICAL_DEPARTMENTS_TAB,
      label: t('addEditDepartments'),
      children: (
        <ManageMedicalDepartments
          departments={departments}
          taskProcessingTenantAdditionalInfo={
            taskProcessingTenantAdditionalInfo
          }
        />
      )
    },
    {
      key: MANAGE_ASSIGNMENTS_AND_POSITIONS_TAB,
      label: t('addEditAssignmentsPositions'),
      children: (
        <ManageAssignmentsAndPositions
          assignments={assignments}
          positions={positions}
          taskProcessingTenantAdditionalInfo={
            taskProcessingTenantAdditionalInfo
          }
        />
      )
    }
  ]

  return (
    <div className="min-w-[1650px] relative">
      {loading && <ScreenLoader />}
      <Typography className="text-base font-bold tracking-widest">
        {t('basicSetting.basicSettingTitle')}
      </Typography>
      <Tabs
        size="small"
        defaultActiveKey={tab}
        activeKey={tab}
        items={dataTabs}
        className="basic-setting-tabs mt-1.5"
        onChange={(newTab) => {
          setTab(newTab)
          const newParams = new URLSearchParams(searchParams)
          newParams.set('t', newTab)
          setSearchParams(newParams)
        }}
      />
    </div>
  )
}
