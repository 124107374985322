import React, { FC, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'

import useTimeChecker from '../../../hooks/useTimeChecker'
import { SpecialConfig } from '../../../hooks/useTimeConfig'
import { CourseSetting } from '../ProductManagement'
import styles from './scss/TabbleWrapper.module.scss'
import 'dayjs/locale/ja'
import isoWeek from 'dayjs/plugin/isoWeek'
import { RemainedSlotByDate } from 'models/productManagement'

dayjs.locale('ja')
dayjs.extend(isoWeek)

type Props = {
  loading: boolean
  courseParam: string | null
  courseName?: string
  initialData: RemainedSlotByDate[]
  currentMonth: dayjs.Dayjs
  config: SpecialConfig
  courseSetting: CourseSetting
}

const RemainedSlotsByWeek: FC<Props> = ({
  loading,
  courseParam,
  courseName,
  initialData,
  currentMonth,
  config,
  courseSetting
}) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const { checkTime } = useTimeChecker()

  const queryParams = new URLSearchParams(search)
  const { startDate: startDateParam, endDate: endDateParam } =
    Object.fromEntries(queryParams)

  const getDaysInWeek = () => {
    const startOfWeek = dayjs().startOf('isoWeek')
    return Array.from({ length: 7 }, (_, index) => {
      return startOfWeek.add(index, 'day').format('ddd')
    })
  }

  const getWeeksInMonth = (month: dayjs.Dayjs) => {
    const weeks: { [key: number]: string[] } = {}
    const startOfWeek = month.startOf('month').startOf('isoWeek')

    for (let weekIndex = 0; weekIndex < 6; weekIndex++) {
      weeks[weekIndex + 1] = Array.from({ length: 7 }, (_, i) =>
        startOfWeek.add(weekIndex, 'week').add(i, 'day').format('YYYY-MM-DD')
      )
    }

    return weeks
  }

  const daysInWeek = getDaysInWeek()
  const daysInMonth = getWeeksInMonth(currentMonth)

  const getDayColor = (index: number) => {
    const colors = ['#3089BF', '#DF2475', '#545454']
    return colors[index === 5 ? 0 : index === 6 ? 1 : 2]
  }

  const renderCell = (
    date: string,
    index: number,
    data: RemainedSlotByDate[]
  ) => {
    const dateDayjs = dayjs(date)
    const isCurrent = dateDayjs.month() === currentMonth.month()
    const availableSlots = isCurrent
      ? data.filter((item) => item.date === date && item.remainedSlot > 0)
      : []

    const sortedData = availableSlots.sort((a, b) =>
      a.time.localeCompare(b.time)
    )

    return (
      <div
        className={`h-[120px] border border-[#CDD6DD] ${isCurrent ? 'bg-[#F0F3F7]' : ''}`}
      >
        <Typography
          style={{ color: isCurrent ? getDayColor(index) : '#CDD6DD' }}
          className="ml-1 text-[16px]"
        >
          {isCurrent
            ? dateDayjs.date()
            : dateDayjs.isSame(
                  currentMonth.add(1, 'month').startOf('month'),
                  'day'
                )
              ? dateDayjs.format('MM/D')
              : dateDayjs.date()}
        </Typography>

        {isCurrent && (
          <div className="mx-4 h-[90px] overflow-y-auto">
            {sortedData.map((item) => {
              const isDisabled = checkTime(item.date, item.time, config)

              return (
                item.emptySlot > 0 && (
                  <Flex
                    key={item.time}
                    justify="space-between"
                    className={`my-[1px] ${isDisabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer'} rounded-[3px] bg-[#137695] px-2`}
                    onClick={() =>
                      navigate('/reservation-register', {
                        state: {
                          courseId: courseParam,
                          courseName: courseName,
                          date,
                          time: item.time,
                          remainedSlots: item.remainedSlot,
                          startDate: startDateParam,
                          endDate: endDateParam,
                          courseSetting
                        }
                      })
                    }
                  >
                    <Typography className="text-[12px] text-white">
                      {item.time}
                    </Typography>
                    <Typography className="min-w-[40px] text-[12px] text-white">
                      〇{item.remainedSlot}/{item.emptySlot}
                    </Typography>
                  </Flex>
                )
              )
            })}
          </div>
        )}
      </div>
    )
  }

  const dataSource = Object.keys(daysInMonth).map((week) => {
    const row: { key: string; week: string; [key: string]: ReactNode } = {
      key: `week-${week}`,
      week: `Week ${week}`,
      ...daysInWeek.reduce<{
        [key: string]: any
      }>((acc, day, index) => {
        const date = daysInMonth[parseInt(week)][index]
        const data = initialData.filter((item) => item.date === date)
        acc[day] = renderCell(date, index, data)
        return acc
      }, {})
    }
    return row
  })

  const columns: TableColumnsType<any> = [
    ...daysInWeek.map((day, index) => ({
      title: (
        <Typography
          style={{
            color: getDayColor(index)
          }}
          className="border border-[#CDD6DD] py-2 text-center"
        >
          {day}
        </Typography>
      ),
      dataIndex: day,
      width: '11%'
    })),
    {
      title: '',
      render: () => null
    }
  ]

  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      className={`${styles.table_wrapper}`}
    />
  )
}

export default RemainedSlotsByWeek
