import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, InputNumber, Radio, Typography } from 'antd'
import * as yup from 'yup'

import InspectionItems from './InspectionItems'
import ModalConfirmDelete from './ModalConfirmDelete'
import ModalCopyOption from './ModalCopyOption'
import useOptionForm from './useOptionForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { TextAreaForm } from 'components/elements/TextAreaForm'
import ScreenLoader from 'components/loading/ScreenLoader'
import { openNotification } from 'components/widgets/Notification'
import {
  CLASSIFICATION,
  GENDER,
  META_DATA_MEDICAL_CHECKUP_MASTER
} from 'configs/constant'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import { numberNotRequiredField } from 'pages/CourseRegister/CourseRegister'
import SaveSuccessModal from 'pages/CourseRegister/components/SaveSuccessModal'
import { Category } from 'types/FilterTestItemMaster'
import { MedicalCheckupMaster, Payload } from 'types/MedicalCheckupMaster'

type DataForm = {
  name: string
  overview?: string
  sex?: string
  startAge?: number
  endAge?: number
  classification?: string
  courseTimes: number
  medicalQuestionnaire?: string[]
  optionalTests?: string[]
  buffer?: number
  courseCd?: number
}

const InputLabel = (label: string, isRequired: boolean = false) => {
  const { t } = useTranslation()

  return (
    <Flex className="w-full h-full justify-between items-center">
      <Typography>{label}</Typography>

      {isRequired && (
        <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white">
          {t('lable.required')}
        </Typography>
      )}
    </Flex>
  )
}

const LABEL_PARENT = 'font-bold bg-[#F0F3F7] py-1.5 pl-2.5 pr-1 w-[203px]'
const LABEL_CHILD = 'w-[128px]'

const inCourseInspectionItems = 'InCourseInspectionItems'
const schema = yup
  .object()
  .shape({
    name: yup.string().trim().max(100).required(),
    overview: yup.string().max(255),
    sex: yup.string(),
    classification: yup.string(),
    startAge: numberNotRequiredField(),
    endAge: numberNotRequiredField(),
    courseTimes: yup.number().required(),
    buffer: numberNotRequiredField()
  })
  .required()

const OptionForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const {
    loading,
    updateMedicalCheckupMasterOption,
    createMedicalCheckupMasterOption,
    deleteMedicalCheckupMasterOption,
    checkDataUpdateWithCourse
  } = useOptionForm()

  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()

  const [save, setSave] = useState<boolean>(false)
  const [modeEdit, setModeEdit] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  const [isPreview, setIsPreview] = React.useState(true)

  const [isShowModalDelete, setIsShowModalDelete] = useState<boolean>(false)
  const [isOpenModalCopy, setIsOpenModalCopy] = useState<boolean>(false)

  const [dataCopy, setDataCopy] = useState<MedicalCheckupMaster | null>(null)

  const [medicalCheckupMasterDetails, setMedicalCheckupMasterDetails] =
    useState<MedicalCheckupMaster | null>(null)
  const [categoryList, setCategoryList] = useState<Category[]>([])

  const genderOptions = [
    { label: t('optionForm.noSpecification'), value: GENDER.NONE },
    { label: t('optionForm.male'), value: GENDER.MALE },
    { label: t('optionForm.female'), value: GENDER.FEMALE }
  ]
  const classificationOptions = [
    { label: t('optionForm.individual'), value: CLASSIFICATION.INDIVIDUAL },
    { label: t('optionForm.group'), value: CLASSIFICATION.ORGANIZATION }
  ]

  const methods = useForm<DataForm>({
    defaultValues: {
      name: '',
      sex: 'NONE',
      classification: 'Individual',
      medicalQuestionnaire: [],
      optionalTests: []
    },
    resolver: yupResolver(schema)
  })

  const { control, handleSubmit, getValues } = methods

  useEffect(() => {
    const id = searchParams.get('id')

    if (id) {
      setShow(true)
      setModeEdit(true)
      const fetchDataMedicalCheckupMaster = async () => {
        const data = (await getMedicalCheckupMastersById(id)) as Payload
        methods.setValue('name', data.medicalCheckupMaster.displayName)
        data.medicalCheckupMaster.additionalInfo.metadata.forEach((item) => {
          const name = item.name as keyof DataForm
          methods.setValue(name, item.value)
        })
        setMedicalCheckupMasterDetails(data.medicalCheckupMaster)
      }
      fetchDataMedicalCheckupMaster()
    }
  }, [methods, searchParams])

  useEffect(() => {
    if (dataCopy && Object.entries(dataCopy).length > 0) {
      methods.setValue(
        'name',
        dataCopy.displayName + `（${t('popover.copy')}）`
      )
      dataCopy.additionalInfo.metadata.forEach((item) => {
        const name = item.name as keyof DataForm
        if (name === META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_CD) {
          methods.setValue('courseCd', undefined)
          return
        }

        methods.setValue(name, item.value)
      })
      setMedicalCheckupMasterDetails(dataCopy)
      setShow(true)
    }
  }, [dataCopy])

  const onSubmit = async (data: DataForm) => {
    if (
      data.startAge !== undefined &&
      data.endAge !== undefined &&
      data.startAge > data.endAge
    ) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.invalidAgeRange')
      })

      return
    }

    if (categoryList.length === 0) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('optionForm.inspectionItems') + ' ' + t('lable.required')
      })

      return
    }
    const payload = {
      ...data,
      key: 'option'
    }
    if (modeEdit && medicalCheckupMasterDetails) {
      const conditionInvalidCourse = await checkDataUpdateWithCourse(
        payload,
        medicalCheckupMasterDetails.refId
      )
      if (conditionInvalidCourse.length > 0) {
        const ArrNameOption = conditionInvalidCourse
          .map((item) => item.displayName)
          .join(', ')

        openNotification({
          type: 'error',
          title: t('commonError'),
          message: ArrNameOption + ': ' + t('errors.selectedOptionInvalid')
        })

        return
      }

      await updateMedicalCheckupMasterOption(
        payload,
        medicalCheckupMasterDetails.refId,
        categoryList.map((item) => item.refId)
      )
    } else {
      await createMedicalCheckupMasterOption(
        payload,
        '',
        categoryList.map((item) => item.refId)
      )
    }

    setSave(true)
  }

  const deleteMedicalCheckupMaster = async (
    medicalCheckupMaster: MedicalCheckupMaster | null
  ) => {
    if (!medicalCheckupMaster) return
    const {
      createdDate,
      createdBy,
      _class,
      _id,
      lastModifiedDate,
      ...dataUpdate
    } = medicalCheckupMaster
    const request = [
      {
        ...dataUpdate,
        additionalInfo: {
          ...dataUpdate.additionalInfo,
          isDeleted: 'true'
        }
      }
    ]
    try {
      await deleteMedicalCheckupMasterOption(request)
      navigate('/product-master')
    } catch (error) {
      setIsShowModalDelete(false)
    }
  }

  const handleShow = (key: any, display: boolean = true) => {
    setShow((prev: any) => ({
      ...prev,
      [key]: display
    }))
    setIsPreview(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        className="pb-5 pr-2.5 min-w-[1600px] relative"
        onSubmit={handleSubmit(onSubmit)}
      >
        {loading && <ScreenLoader />}
        <Flex className="items-center justify-between">
          <Typography className="text-base font-bold tracking-[1.6px] leading-none">
            {modeEdit ? t('optionChange') : t('optionForm.registerOption')}
          </Typography>
          <Flex className="gap-3 items-center justify-end tracking-[.7px]">
            {!isPreview && (
              <Typography
                className="text-primary underline font-bold tracking-[1.4px] cursor-pointer"
                onClick={() => setIsPreview(true)}
              >
                ＜{t('button.return')}
              </Typography>
            )}

            {isPreview && (
              <>
                <Button
                  autoInsertSpace={false}
                  className="text-primary font-bold min-w-[120px] border border-primary bg-inherit rounded"
                  onClick={() => setIsOpenModalCopy(true)}
                >
                  {t('optionForm.copyExistingProduct')}
                </Button>
                <Button
                  autoInsertSpace={false}
                  type="primary"
                  className="min-w-[120px] font-bold rounded"
                  htmlType="submit"
                >
                  {modeEdit ? t('button.change') : t('optionForm.register')}
                </Button>
                {modeEdit && (
                  <Button
                    autoInsertSpace={false}
                    type="primary"
                    className="min-w-[120px] font-bold bg-error hover:!bg-error rounded"
                    htmlType="button"
                    onClick={() => setIsShowModalDelete(true)}
                  >
                    {t('optionForm.delete')}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>

        <div className="mt-2.5 py-[11px] pr-[25px] pl-2.5 bg-white shadow-custom rounded-md tracking-[.7px]">
          <Flex className="items-center gap-4">
            <div className={LABEL_PARENT}>
              {InputLabel(t('optionForm.optionName'), true)}
            </div>
            <Flex className="flex-1">
              <div className="flex-1">
                <InputForm name="name" placeholder="" className="w-[424px]" />
              </div>
              <div className="flex-1 flex items-center gap-8">
                <Typography className="font-bold">
                  {t('optionForm.optionCD')}
                </Typography>
                {modeEdit && (
                  <Typography>{getValues('courseCd') ?? ''}</Typography>
                )}
              </div>
            </Flex>
          </Flex>
          <Flex className="mt-2.5 gap-4">
            <div className={LABEL_PARENT}>
              {InputLabel(t('optionForm.overview'), false)}
            </div>
            <Flex className="flex-1">
              <TextAreaForm
                name="overview"
                className="w-full !min-h-[80px]"
                autoSize={{ minRows: 3 }}
              />
            </Flex>
          </Flex>
          <Flex className="mt-2.5 gap-4">
            <div className={LABEL_PARENT}>
              {InputLabel(t('optionForm.target'), false)}
            </div>
            <div className="flex-1">
              <Flex className="items-center gap-2">
                <div className={LABEL_CHILD}>
                  {InputLabel(t('optionForm.gender'), true)}
                </div>
                <Controller
                  name="sex"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Radio.Group
                        {...field}
                        optionType="button"
                        options={genderOptions}
                        className="custom-radio-group"
                      />
                    )
                  }}
                />
              </Flex>
              <Flex className="items-center mt-3 gap-2">
                <div className={LABEL_CHILD}>
                  {InputLabel(t('optionForm.category'), false)}
                </div>
                <Controller
                  name="classification"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Radio.Group
                        {...field}
                        optionType="button"
                        options={classificationOptions}
                        className="custom-radio-group"
                      />
                    )
                  }}
                />
              </Flex>
              <Flex className="items-center mt-3 gap-2">
                <div className={LABEL_CHILD}>
                  {InputLabel(t('optionForm.age'), false)}
                </div>
                <Flex className="items-center gap-1">
                  <Controller
                    name="startAge"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        min={0}
                        max={999}
                        className="w-[80px]"
                        controls={false}
                      />
                    )}
                  />
                  <Typography className="font-bold">〜</Typography>
                  <Controller
                    name="endAge"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        min={0}
                        max={999}
                        className="w-[80px]"
                        controls={false}
                      />
                    )}
                  />
                </Flex>
              </Flex>
              <Flex className="items-center mt-3 gap-2">
                <div className={LABEL_CHILD}>
                  {InputLabel(t('optionForm.responseTime'), true)}
                </div>
                <Flex className="items-end gap-1">
                  <Controller
                    name="courseTimes"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <InputNumber
                        {...field}
                        min={1}
                        status={error ? 'error' : ''}
                        max={999}
                        className="w-[80px]"
                        controls={false}
                      />
                    )}
                  />

                  <Typography>{t('optionForm.minutes')}</Typography>
                </Flex>
              </Flex>
              <Flex className="items-center mt-3 gap-2">
                <div className={LABEL_CHILD}>
                  {InputLabel(t('optionForm.buffer'), false)}
                </div>
                <Flex className="items-end gap-1">
                  <Controller
                    name="buffer"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <InputNumber
                        {...field}
                        min={1}
                        status={error ? 'error' : ''}
                        max={999}
                        className="w-[80px]"
                        controls={false}
                      />
                    )}
                  />
                  <Typography>{t('optionForm.minutes')}</Typography>
                </Flex>
              </Flex>
            </div>
          </Flex>
        </div>

        {!show && (
          <Flex className="pt-[28.5px] tracking-[.7px]">
            <div className="w-[50.9%]">
              <Flex gap={8}>
                <Typography className="font-bold">
                  {t('optionForm.inspectionItems')}
                </Typography>
                <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white">
                  {t('lable.required')}
                </Typography>
              </Flex>

              <Flex
                onClick={() => handleShow(inCourseInspectionItems)}
                className="mt-4 w-[30px] h-[30px] rounded-md bg-primary text-white items-center justify-center cursor-pointer"
              >
                <span className="text-[24px]">+</span>
              </Flex>
            </div>
          </Flex>
        )}

        {show && (
          <>
            <InspectionItems
              setListCategory={setCategoryList}
              associatedTestItemMasters={
                medicalCheckupMasterDetails?.associatedTestItemMasters ?? []
              }
              isEdit={modeEdit}
              isPreview={isPreview}
              setIsPreview={setIsPreview}
            />
          </>
        )}
        {isShowModalDelete && (
          <ModalConfirmDelete
            isModalVisible
            medicalCheckupMasterDetails={medicalCheckupMasterDetails}
            handleCancel={() => setIsShowModalDelete(false)}
            handleOk={() =>
              deleteMedicalCheckupMaster(medicalCheckupMasterDetails)
            }
          />
        )}
        {isOpenModalCopy && (
          <ModalCopyOption
            isModalVisible
            optionRefId={searchParams.get('id')}
            handleCancel={() => setIsOpenModalCopy(false)}
            setDataCopy={setDataCopy}
          />
        )}

        <SaveSuccessModal
          isModalOpen={save}
          onCancel={() => navigate('/product-master')}
          onSuccess={() => navigate('/product-master')}
          textTitle={
            modeEdit
              ? t('optionForm.titleTextChangeSuccess')
              : t('optionForm.titleTextSuccess')
          }
          textContent={
            modeEdit
              ? t('optionForm.textSaveChangeSuccess')
              : t('optionForm.textSaveSuccess')
          }
        />
      </form>
    </FormProvider>
  )
}

export default OptionForm
