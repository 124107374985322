import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex, Table, TableColumnsType, Typography } from 'antd'

import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { PRODUCT_MASTER_SORT_TYPE } from 'configs/constant'

interface BasicCourseListT {
  id: string | number
  name: string
  overview: string
  courseTimes: string
  buffer: string
  subscriber: string
  registrationDate: string
}
export default function BasicCourseList({
  dataSource,
  loading,
  setListSelectedRows,
  toggleSort
}: {
  dataSource: any
  loading: boolean
  setListSelectedRows: any
  toggleSort: any
}) {
  const { t } = useTranslation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const productMasterColumns: TableColumnsType<BasicCourseListT> = [
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-4">
            {t('table.productName')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.NAME)
            }}
          />
        </Flex>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '22%',
      render: (_, record) => (
        <Link
          to={`/course-register?id=${record.id}`}
          className="w-full text-left"
        >
          <Typography className="text-[#137695] font-bold underline">
            {record.name}
          </Typography>
        </Link>
      )
    },
    {
      title: t('table.overview'),
      dataIndex: 'overview',
      key: 'overview',
      width: '32%',
      render: (overview) => (
        <Typography className="text-left tracking-wider">{overview}</Typography>
      )
    },
    {
      title: t('lable.responseTime'),
      dataIndex: 'courseTimes',
      key: 'courseTimes',
      width: '8%',
      render: (courseTimes) => (
        <Typography>
          {courseTimes ? courseTimes + ' ' + t('lable.minutes') : courseTimes}
        </Typography>
      )
    },
    {
      title: t('table.buffer'),
      dataIndex: 'buffer',
      key: 'buffer',
      width: '8%',
      render: (buffer) => (
        <Typography>{buffer ? buffer + t('lable.minutes') : buffer}</Typography>
      )
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('table.subscriber')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.SUBSCRIBER)
            }}
          />
        </Flex>
      ),
      dataIndex: 'subscriber',
      key: 'subscriber',
      width: '12%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('table.RegistrationDate')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.REGISTRATION_DATE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: '8%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-2">
            {t('lable.modifiedAt')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.REGISTRATION_DATE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'lastUpdateDate',
      key: 'lastUpdateDate',
      width: '12%'
    }
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setListSelectedRows(selectedRows)
    }
  }

  return (
    <div>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        rowKey={(record) => record.id}
        columns={productMasterColumns}
        dataSource={dataSource}
        pagination={false}
        className="product-master-list-table mt-1.5"
        loading={loading}
        scroll={{
          y: 'calc(100vh - 190px)'
        }}
      />
    </div>
  )
}
