import { useState } from 'react'

import { useBetween } from 'use-between'

const useShareChatState = () => {
  const [chatHistory, setChatHistory] = useState<any[]>([])

  const handleUserClick = ({ message }: { message: any }) => {
    setChatHistory((prev: any) => [...prev, { sender: 'B', message }])
  }

  return {
    chatHistory,
    setChatHistory,
    handleUserClick
  }
}

export const useChatState = () => useBetween(useShareChatState)
