import { v4 as uuidv4 } from 'uuid'

import { WEB_SOCKET_URL } from './api'
import { ACTIVITY_TYPES, STORAGE_KEYS } from './constant'
import emitter from 'models/emitter'
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs'

let realTimeAPI: any = null
let lastRoomId: any

export const connectToRocketChat = () => {
  const { adminToken } = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
  )

  if (!adminToken) return

  // console.log('Connecting Rocket Chat...')
  if (realTimeAPI) {
    realTimeAPI?.disconnect()
    realTimeAPI = null
  }

  realTimeAPI = new RealTimeAPI(WEB_SOCKET_URL)
  realTimeAPI.connectToServer()
  realTimeAPI.keepAlive().subscribe()
  const auth = realTimeAPI?.loginWithAuthToken(adminToken)
  // console.log('Logged in to Rocket Chat')

  auth.subscribe(
    (data: any) => {
      // console.log('Subscribe to Rocket Chat!!!')
      if (data?.msg === 'result') {
        const { result } = data
        if (result?.id) {
          realTimeAPI?.sendMessage({
            msg: 'sub',
            id: uuidv4(),
            name: 'stream-notify-user',
            params: [`${result.id}/subscriptions-changed`, false]
          })
          realTimeAPI?.sendMessage({
            msg: 'sub',
            id: uuidv4(),
            name: 'stream-notify-user',
            params: [
              `${result.id}/uiInteraction`,
              {
                useCollection: false,
                args: []
              }
            ]
          })
          realTimeAPI?.sendMessage({
            msg: 'sub',
            id: uuidv4(),
            name: 'stream-notify-user',
            params: [`${result.id}/rooms-changed`, false]
          })
        }
      }
    },
    (err: any) => {
      console.log(err)
      disconnectToRocketChat()
      if (err?.type === 'close' || err?.type === 'error') {
        // should reload page
        emitter.emit('SHOULD_RELOAD', true)
      }
    }
  )

  realTimeAPI?.onMessage((response: any) => {
    const { msg, collection, fields = null } = response

    if (msg === 'changed') {
      if (collection === 'stream-notify-user') {
        const { args = null, eventName = '' } = fields
        // const type = args && args.length > 0 ? args[0] : undefined

        if (eventName.includes('uiInteraction')) {
          const slash = args[0]
          emitter.emit('ADD_POLL', slash)
        } else if (eventName?.includes('rooms-changed')) {
          //   if (type === 'updated') {
          //     const newRoom = args[1]
          //     emitter.emit('UPDATE_CHAT_ROOM', newRoom)
          //   }
          //   if (type === 'inserted') {
          //     const newRoom = args[1]
          //     emitter.emit('ADD_CHAT_ROOM', newRoom)
          //   }
          // } else if (eventName?.includes('subscriptions-changed')) {
          //   if (type === 'updated') {
          //     const newRoom = args[1]
          //     emitter.emit('SUB_CHAT_ROOM', newRoom)
          //   }
          // } else {
          //   if (type === 'updated') {
          //     const newMessage = args[1]
          //     emitter.emit('SUB_CHAT_ROOM', newMessage)
          //   }
          //   if (type === 'removed') {
          //     const newMessage = args[1]
          //     emitter.emit('REMOVE_CHAT_ROOM', newMessage)
          //   }
        }
      }
      if (collection === 'stream-room-messages') {
        if (fields?.args?.length) {
          const newMessage = fields?.args[0]
          emitter.emit('CHAT_MESSAGE', newMessage)
        }
      }
      if (collection === 'stream-user-presence') {
        const { args = null, uid = '' } = fields
        if (uid && args.length) {
          const data = args[0]
          if (data?.length) {
            emitter.emit('ACTIVE_USER', {
              _id: uid,
              name: data[0],
              status: data[1] === 1 ? 'online' : 'offline'
            })
          }
        }
      }
      if (collection === 'stream-notify-room') {
        const { args = null, eventName = '' } = fields
        const isUserActivityEvent = eventName?.includes('user-activity')

        if (isUserActivityEvent && args) {
          const [userName, activityTypes] = args
          const [userTyping] = activityTypes
          const isTyping = userTyping === ACTIVITY_TYPES.TYPING

          emitter.emit('TYPING_MESSAGE', {
            userName,
            isTyping
          })
        }
      }
    }
  })
  realTimeAPI?.onError((error: any) => console.error(error))
}

export const disconnectToRocketChat = () => {
  if (realTimeAPI) {
    realTimeAPI?.disconnect()
    realTimeAPI = null
  }
}

export const subscriptionRoomMessages = (roomId: any) => {
  if (!realTimeAPI) return

  if (lastRoomId) {
    realTimeAPI?.sendMessage({
      msg: 'unsub',
      id: lastRoomId
    })
  }
  lastRoomId = roomId

  realTimeAPI?.sendMessage({
    msg: 'sub',
    id: roomId,
    name: 'stream-room-messages',
    params: [roomId, false]
  })
}

export const subscriptionUserPresences = (userIds = [], method = 'added') => {
  if (!userIds?.length) return

  realTimeAPI?.sendMessage({
    msg: 'sub',
    id: uuidv4(),
    name: 'stream-user-presence',
    params: [
      '',
      {
        [method]: userIds
      }
    ]
  })
}

export const subscriptionUserActivity = (roomId: string) => {
  if (!roomId) return

  realTimeAPI?.sendMessage({
    msg: 'sub',
    id: roomId,
    name: 'stream-notify-room',
    params: [`${roomId}/user-activity`, false]
  })
}

export const unsubscribeRocketChat = (id: string) => {
  realTimeAPI?.sendMessage({
    msg: 'unsub',
    id
  })
}

export const sendTypingMessage = (
  roomId: string,
  userName: string,
  isTyping: boolean
) => {
  if (!roomId) return

  realTimeAPI?.sendMessage({
    msg: 'method',
    method: 'stream-notify-room',
    id: uuidv4(),
    params: [`${roomId}/typing`, userName, isTyping, {}]
  })
}

export { realTimeAPI }
