import React, { FC } from 'react'

import { Button, Flex, Pagination, PaginationProps } from 'antd'

import styles from './CustomPagination.module.scss'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'

type Props = {
  page: number
  size: number
  totalRecords: number
  setPage: (page: number) => void
}

const CustomPagination: FC<Props> = ({ page, setPage, size, totalRecords }) => {
  const totalPages = Math.ceil(totalRecords / size)
  const onChangePage: PaginationProps['onChange'] = (pageNumber) => {
    setPage(pageNumber)
  }

  return (
    <Flex justify="center">
      <Button
        style={{ background: 'none' }}
        className="rounded-none"
        onClick={() => setPage(1)}
        disabled={page === 0 || page === 1}
        icon={
          <DoubleLeftOutlined
            style={{ color: page === 0 || page === 1 ? '#bdcbd5' : '#137695' }}
          />
        }
      />

      <Pagination
        className={`${styles.pagination_wrapper}`}
        total={totalRecords}
        pageSize={size}
        onChange={onChangePage}
        current={page}
      />

      <Button
        style={{ background: 'none' }}
        className="rounded-none"
        onClick={() => setPage(totalPages)}
        disabled={page === totalPages || totalPages === 0}
        icon={
          <DoubleRightOutlined
            style={{
              color:
                page === totalPages || totalPages === 0 ? '#bdcbd5' : '#137695'
            }}
          />
        }
      />
    </Flex>
  )
}

export default CustomPagination
