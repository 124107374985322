import { useLocalStorage } from './index'
import { useLazyQuery } from '@apollo/client'
import { DayAbbreviations } from 'configs/constant'
import { FILTER_TENANT } from 'graphql/tenant/filter'

enum WORK_SCHEDULE_TYPE {
  FIXED = '一律で固定',
  SPECIFY = '曜日ごとに指定'
}

type Tenant = {
  workScheduleType: WORK_SCHEDULE_TYPE
  workdays: string
  fixedDayTimes: string
  specifiedDayTimes: string
  specifiedBiweekly: string
}

enum DAY_TYPE {
  WORK = '1',
  OFF = '2'
}

type TimeRange = { start: string; end: string }

type standardConfigDay = { [p: string]: TimeRange }

type SpecialConfigDay = {
  week: number
  day: number
  time: TimeRange
  type: 'work' | 'off'
}

export type SpecialConfig = {
  time: string[]
  config: standardConfigDay
  specialConfig: SpecialConfigDay[]
}

const defaultTime: string[] = []
const defaultConfig: standardConfigDay = {}
const defaultSpecialConfig: SpecialConfigDay[] = []

type DaySchedule = {
  t: string
  w: string
  s: string
  e: string
}

type SpecifiedDayTimeSettings = {
  [day: string]: DaySchedule[]
}[]

export const initialSpecialConfig = {
  time: defaultTime,
  config: defaultConfig,
  specialConfig: defaultSpecialConfig
}

function generateTimeArray(
  start: string,
  end: string,
  interval: number = 30
): string[] {
  const timeArray: string[] = []

  let [startHour, startMinute] = start.split(':').map(Number)
  const [endHour, endMinute] = end.split(':').map(Number)

  const formatTime = (hour: number, minute: number): string =>
    `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`

  while (
    startHour < endHour ||
    (startHour === endHour && startMinute <= endMinute)
  ) {
    timeArray.push(formatTime(startHour, startMinute))

    startMinute += interval

    if (startMinute >= 60) {
      startHour += Math.floor(startMinute / 60)
      startMinute = startMinute % 60
    }
  }

  return timeArray
}

const useTimeConfig = () => {
  const [currentTenant] = useLocalStorage('@tenant', {})
  const [getTenant] = useLazyQuery(FILTER_TENANT)

  const onCreateTimeConfig = async () => {
    const { data } = await getTenant({
      variables: {
        filter: `(eq,STRING,code,${currentTenant?.tenant})`,
        sortBy: '(desc,_id)',
        page: 0,
        size: 1
      },
      fetchPolicy: 'network-only'
    })

    const payload = data?.filterTenant?.payload
    if (!Array.isArray(payload) && !payload?.[0]?.additionalInfo) {
      return {
        time: defaultTime,
        config: defaultConfig,
        specialConfig: defaultSpecialConfig
      }
    }

    const tenant: Tenant = payload[0].additionalInfo || {}

    const workType = tenant.workScheduleType
    const workDays = tenant.workdays.split(',')

    // case 1
    if (workType === WORK_SCHEDULE_TYPE.FIXED) {
      const { start, end }: TimeRange = JSON.parse(
        tenant.fixedDayTimes.replace(/\\/g, '')
      )

      const workTimes = generateTimeArray(start, end)

      const config: standardConfigDay = Object.fromEntries(
        workDays.map((day) => {
          return [DayAbbreviations[day], { start, end }]
        })
      )

      return {
        time: workTimes,
        config,
        specialConfig: defaultSpecialConfig
      }
    }

    // case 2
    else if (workType === WORK_SCHEDULE_TYPE.SPECIFY) {
      const specifiedDayTimes: {
        workday: string
        start: string
        end: string
        active: boolean
      }[] = JSON.parse(tenant.specifiedDayTimes.replace(/\\/g, ''))

      const specifiedDayTimeSettings: SpecifiedDayTimeSettings = JSON.parse(
        tenant.specifiedBiweekly.replace(/\\/g, '')
      )

      const allWorkDayTimes: { day: string; start: string; end: string }[] =
        specifiedDayTimes
          .filter(({ active }) => active)
          .map(({ workday, start, end }) => {
            return {
              day: workday,
              start,
              end
            }
          })

      if (allWorkDayTimes.length === 0) {
        return initialSpecialConfig
      }

      const allWorkTimes = allWorkDayTimes.map(({ start, end }) => ({
        start,
        end
      }))

      specifiedDayTimeSettings.forEach((item) => {
        const [_, schedules] = Object.entries(item)[0]
        schedules.forEach(({ s: start, e: end, t }) => {
          if (t === DAY_TYPE.WORK) {
            allWorkTimes.push({
              start,
              end
            })
          }
        })
      })

      const { start: startTime, end: endTime }: TimeRange = allWorkTimes.reduce(
        (acc: { start: string; end: string }, { start, end }) => {
          if (!acc.start || start < acc.start) {
            acc.start = start
          }

          if (!acc.end || end > acc.end) {
            acc.end = end
          }

          return acc
        },
        { start: '', end: '' }
      )

      const workTimes = generateTimeArray(startTime, endTime)

      const config: standardConfigDay = Object.fromEntries(
        allWorkDayTimes.map(({ day, start, end }) => {
          return [DayAbbreviations[day], { start, end }]
        })
      )

      const specialConfig: SpecialConfigDay[] =
        specifiedDayTimeSettings.flatMap((item) => {
          const [day, schedules] = Object.entries(item)[0]

          return schedules.map(({ t, w, s: start, e: end }) => ({
            week: +w,
            day: DayAbbreviations[day],
            time: { start, end },
            type: t === DAY_TYPE.WORK ? 'work' : 'off'
          }))
        })

      return {
        time: workTimes,
        config,
        specialConfig
      }
    }

    return initialSpecialConfig
  }

  return { onCreateTimeConfig }
}

export default useTimeConfig
