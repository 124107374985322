import { useEffect, useState } from 'react'
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'

import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd/lib'
import { uniqueTimes } from 'configs/constant'
import {
  AvailableSlots,
  tableSearchSlotReservationAtom
} from 'hooks/usePatientDetail'
import useTimeChecker from 'hooks/useTimeChecker'
import useTimeConfig, {
  SpecialConfig,
  initialSpecialConfig
} from 'hooks/useTimeConfig'
import { LocationState } from 'pages/CustomerDetail/types/types'
import cn from 'utilities/cn'
import { getDaysInMonth, uuid } from 'utilities/helpers'

dayjs.locale('ja')

export interface ParamsType {
  availableSlots?: AvailableSlots[]
  availableSlot?: AvailableSlots
  courseId: string
  courseName: string
  optionIds: string[]
  optionName: string
  navigate: NavigateFunction
  locationState: LocationState
  isUpdate: boolean
  customerId: string
  reservationId?: string
  times?: string[]
}

const generateColumns = (year: number, month: number): any[] => {
  const daysInMonth = getDaysInMonth(year, month)
  const columns: any[] = []

  for (let day = 1; day <= daysInMonth; day++) {
    const date = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`
    const dayOfWeek = dayjs(`${year}-${month}-${day}`).format('dd') // Get Japanese day of the week
    const dataIndex = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD')

    columns.push({
      key: date,
      dataIndex,
      title: `${date} (${dayOfWeek})`
    })
  }

  return columns
}

const startReservation = ({
  availableSlot,
  courseId,
  courseName,
  optionIds,
  optionName,
  navigate,
  locationState,
  isUpdate,
  customerId,
  reservationId
}: ParamsType) => {
  navigate(`/reservation-detail?cst-id=${customerId}`, {
    replace: true,
    state: {
      ...locationState,
      availableSlot,
      courseId,
      courseName,
      optionIds,
      optionName,
      isUpdate,
      reservationId: reservationId || ''
    }
  })
}

function ScheduleTable() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { onCreateTimeConfig } = useTimeConfig()
  const { checkTime } = useTimeChecker()

  const customerId = searchParams.get('id')

  const { course, option, action, reservationId, slot, date } = useRecoilValue(
    tableSearchSlotReservationAtom
  )

  const navigate = useNavigate()

  const [config, setConfig] = useState<SpecialConfig>(initialSpecialConfig)

  useEffect(() => {
    fetchTime()
  }, [])

  const fetchTime = async () => {
    const timeConfig = await onCreateTimeConfig()

    if (!timeConfig) return

    setConfig(timeConfig)
  }

  const columns: ColumnsType<any> = [
    {
      title: '',
      key: 'time',
      dataIndex: 'time'
    },
    ...generateColumns(Number(date.year), Number(date.month))
  ]

  const convertAvailableSlotsToScheduleTableData = ({
    navigate,
    courseId,
    courseName,
    optionIds,
    optionName,
    locationState,
    isUpdate,
    customerId,
    reservationId,
    availableSlots = []
  }: ParamsType) => {
    const workTimes = config.time ?? []

    const data = workTimes.map((time) => {
      const availableSlotsAtTime = availableSlots.filter(
        (availableSlot) => availableSlot.byhour === time
      )

      const scheduleTableData = availableSlotsAtTime.reduce(
        (acc, availableSlot) => {
          const isAfterDate = dayjs().isAfter(
            `${availableSlot.bydate} ${availableSlot.byhour}`,
            'date'
          )

          const isOverTime = checkTime(
            availableSlot.bydate,
            availableSlot.byhour,
            config
          )

          return {
            ...acc,
            [availableSlot.bydate]: (
              <div
                role="presentation"
                className={cn(
                  'underline text-primary bg-white w-full h-full cursor-pointer',
                  {
                    'bg-tableGray cursor-default no-underline text-borderGray':
                      isAfterDate || isOverTime
                  }
                )}
                onClick={() =>
                  !isAfterDate &&
                  !isOverTime &&
                  startReservation({
                    availableSlot,
                    courseId,
                    courseName,
                    optionIds,
                    optionName,
                    navigate,
                    locationState,
                    isUpdate,
                    customerId,
                    reservationId
                  })
                }
              >
                {availableSlot.remainedSlotCount === 0
                  ? ''
                  : `O${availableSlot.remainedSlotCount}`}
              </div>
            )
          }
        },
        { key: uuid(), time }
      )

      return scheduleTableData
    })

    return data
  }

  const data = convertAvailableSlotsToScheduleTableData({
    availableSlots: slot.list,
    navigate,
    courseId: course.courseId,
    courseName: course.courseName,
    optionIds: option.optionIds,
    optionName: option.optionName,
    locationState: location.state || {},
    isUpdate: action === 'update',
    customerId: customerId ?? '',
    reservationId: reservationId
  })

  return (
    <Table
      loading={slot.loading}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: 3200, y: 'calc(100vh - 545px)' }}
      bordered
      className="table-schedule pt-2"
    />
  )
}

export default ScheduleTable
