import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'

import { openNotification } from '../../components/widgets/Notification'
import { API_USER } from '../../configs/api'
import { MEDICAL_CHECKUP_STATUS } from '../../configs/constant'
import { RECORD_COUNT } from '../../models/customer'
import { RESERVATION_STATUS } from '../../models/reservationManagement'
import { sleep } from '../../utilities/helpers'
import networkAdapter from '../../utilities/networkAdapter'
import Content from './components/Content'
import Widget from './components/Widget'
import CustomPagination from 'components/elements/CustomPagination'
import ScreenLoader from 'components/loading/ScreenLoader'
import useCustomer from 'hooks/useCustomer'

export default function CustomerManagement() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { onFilterCustomers, onCountStatusCustomer, loading } = useCustomer()
  const [customers, setCustomers] = useState<any>()
  const [countCustomerByStatus, setCountCustomerByStatus] = useState<any>()

  // query params
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const pageNumber = Number(queryParams.get('page'))
  const sizeNumber = Number(queryParams.get('size'))
  const orderValue = queryParams.get('orderBy')
  const sortValue = queryParams.get('sortBy')
  const newOrderValue =
    orderValue && sortValue ? `(${sortValue},${orderValue})` : undefined

  const defaultSize = RECORD_COUNT.OneHundred
  const defaultOrderBy = {
    type: 'SORT',
    criteria: {
      field: 'createdDate',
      direction: 'desc'
    }
  }

  const defaultRequests: any[] = [
    {
      type: 'MATCH',
      criteria: {
        and: [
          {
            field: 'status',
            operator: 'eq',
            value: 'PUBLISHED'
          },
          {
            field: 'additionalInfo.isDeleted',
            operator: 'eq',
            value: 'false'
          }
        ]
      }
    },
    {
      type: 'LOOKUP',
      criteria: {
        from: 'medicalCheckup',
        localField: 'refId',
        foreignField: 'checkupUserRef',
        as: 'medicalCheckup'
      }
    },
    {
      type: 'ADD_FIELDS',
      criteria: {
        medicalCheckup: {
          $arrayElemAt: [
            {
              $sortArray: {
                input: {
                  $filter: {
                    input: '$medicalCheckup',
                    as: 'mc',
                    cond: {
                      $eq: ['$$mc.status', 'PUBLISHED']
                    }
                  }
                },
                sortBy: {
                  createdDate: -1
                }
              }
            },
            0
          ]
        }
      }
    },
    {
      type: 'LOOKUP',
      criteria: {
        from: 'testResult',
        localField: 'medicalCheckup.refId',
        foreignField: 'medicalCheckupRefId',
        as: 'testResult'
      }
    },
    {
      type: 'ADD_FIELDS',
      criteria: {
        testResult: {
          $arrayElemAt: [
            {
              $sortArray: {
                input: {
                  $filter: {
                    input: '$testResult',
                    as: 'tr',
                    cond: {
                      $eq: ['$$tr.status', 'PUBLISHED']
                    }
                  }
                },
                sortBy: {
                  createdDate: 1
                }
              }
            },
            0
          ]
        }
      }
    }
  ]

  // filter value
  const [requests, setRequest] = useState<any>(defaultRequests)
  const [page, setPage] = useState<number>(pageNumber ? pageNumber : 1)
  const [size, setSize] = useState<number>(
    sizeNumber ? sizeNumber : defaultSize
  )
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [selectOptions, setSelectOptions] = useState<
    { key: string; value: string; label: string }[]
  >([])

  const [isStatusUpdated, setStatusUpdated] = useState<boolean>(false)
  const [listIdSelected, setListIdSelected] = useState<any>()
  const [newQueryCount, setNewQueryCount] = useState<any[]>([])
  const [isExportData, setIsExportData] = useState<any>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sleep(500)
        const response = await networkAdapter
          .getClient(API_USER)
          .then((response: any) => {
            return response
          })
        const value = response.map((item: any) => {
          return {
            key: item.id,
            value: `${item.firstName} ${item.lastName}`,
            label: `${item.firstName} ${item.lastName}`
          }
        })
        setSelectOptions(value)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData().then()
  }, [])

  useEffect(() => {
    const newQuery: any[] = []
    const fullName = queryParams.get('fullName')

    if (fullName) {
      newQuery.push({
        or: [
          {
            field: 'refId',
            operator: 'like',
            value: fullName.trim()
          },
          {
            field: 'additionalInfo.fullName',
            operator: 'like',
            value: fullName.trim()
          }
        ]
      })
    }

    if (
      (queryParams.get('startAge') && queryParams.get('endAge')) ||
      (queryParams.get('startAge') === '0' && queryParams.get('endAge'))
    ) {
      const currentDate = dayjs()
      const startAgeNumber = Number(queryParams.get('startAge'))
      const endAgeNumber = Number(queryParams.get('endAge'))

      const startAge = currentDate
        .subtract(endAgeNumber + 1, 'year')
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      const endAge = currentDate
        .subtract(startAgeNumber, 'year')
        .subtract(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      newQuery.push({
        field: 'birthday',
        operator: 'between',
        value: {
          start: startAge,
          end: endAge
        }
      })
    }

    if (
      (queryParams.get('startAge') && !queryParams.get('endAge')) ||
      (queryParams.get('startAge') === '0' && !queryParams.get('endAge'))
    ) {
      const currentDate = dayjs()
      const startAgeNumber = Number(queryParams.get('startAge'))

      const startDate = currentDate
        .subtract(startAgeNumber, 'year')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      newQuery.push({
        field: 'birthday',
        operator: 'lte',
        value: startDate
      })
    }

    if (
      !queryParams.get('startAge') &&
      queryParams.get('startAge') !== '0' &&
      queryParams.get('endAge')
    ) {
      const currentDate = dayjs()
      const endAgeNumber = Number(queryParams.get('endAge'))

      const endDate = currentDate
        .subtract(endAgeNumber + 1, 'year')
        .add(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      newQuery.push({
        field: 'birthday',
        operator: 'gte',
        value: endDate
      })
    }

    if (queryParams.get('gender')) {
      newQuery.push({
        field: 'gender',
        operator: 'eq',
        value: queryParams.get('gender')
      })
    }

    if (queryParams.get('doctorName')) {
      const doctorName = queryParams.get('doctorName')?.trim() || null

      newQuery.push({
        field: 'testResult.valueBy',
        operator: 'like',
        value: doctorName
      })
    }

    if (queryParams.get('startDate') && queryParams.get('endDate')) {
      newQuery.push({
        field: 'testResult.readingTakenOn',
        operator: 'between',
        value: {
          start: queryParams.get('startDate'),
          end: queryParams.get('endDate')
        }
      })
    }

    if (
      queryParams.get('status') &&
      queryParams.get('status') !== '10' &&
      queryParams.get('status') !== '0'
    ) {
      newQuery.push({
        field: 'medicalCheckup.additionalInfo.healthCheckStatus',
        operator: 'in',
        value: [Number(queryParams.get('status')), queryParams.get('status')]
      })
    }

    if (queryParams.get('status') && queryParams.get('status') === '0') {
      newQuery.push({
        field: 'medicalCheckup.additionalInfo.healthCheckStatus',
        operator: 'nin',
        value: [
          MEDICAL_CHECKUP_STATUS.RESULTS_IN_PROGRESS,
          MEDICAL_CHECKUP_STATUS.RESULTS_IN_PROGRESS.toString(),
          MEDICAL_CHECKUP_STATUS.AWAITING_JUDGEMENT,
          MEDICAL_CHECKUP_STATUS.AWAITING_JUDGEMENT.toString(),
          MEDICAL_CHECKUP_STATUS.JUDGMENT_COMPLETE,
          MEDICAL_CHECKUP_STATUS.JUDGMENT_COMPLETE.toString(),
          MEDICAL_CHECKUP_STATUS.SUBMITTED,
          MEDICAL_CHECKUP_STATUS.SUBMITTED.toString()
        ]
      })
    }

    const newRequests = defaultRequests

    if (newQuery.length > 0) {
      newRequests.push({
        type: 'MATCH',
        criteria: {
          and: newQuery
        }
      })
    }

    setNewQueryCount(newQuery)
    setRequest(newRequests)
  }, [location.search])

  useEffect(() => {
    let isCancelled = false
    const fetchData = async () => {
      try {
        const filteredCustomers = await onFilterCustomers({
          collection: 'checkupUser',
          page: page - 1,
          size: size,
          request: requests
        })
        const countCustomerByStatus = await onCountStatusCustomer(newQueryCount)
        if (!isCancelled) {
          setTotalRecords(filteredCustomers.totalRecords)
          setCustomers(filteredCustomers.customers)
          setCountCustomerByStatus(countCustomerByStatus)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData().then()

    return () => {
      isCancelled = true
    }
  }, [requests, newQueryCount, isStatusUpdated, page, size])

  useEffect(() => {
    if (!queryParams.toString()) {
      queryParams.set('page', '1')
      queryParams.set('size', `${defaultSize}`)
      navigate(`${location.pathname}?${queryParams.toString()}`)
    }

    const currentPage = Number(queryParams.get('page'))
    const currentSize = Number(queryParams.get('size'))

    if (page !== currentPage) {
      setPage(currentPage)
    }
    if (size !== currentSize) {
      setSize(currentSize)
    }
    if (newOrderValue) {
      const newRequest = defaultRequests
      newRequest.push({
        type: 'SORT',
        criteria: {
          field: orderValue,
          direction: sortValue
        }
      })
      setRequest(newRequest)
    }
    if (!newOrderValue) {
      const newRequest = defaultRequests
      newRequest.push(defaultOrderBy)
      setRequest(newRequest)
    }
  }, [location.search])

  useEffect(() => {
    const currentPage = Number(queryParams.get('page'))
    const currentSize = Number(queryParams.get('size'))

    if (page !== currentPage || size !== currentSize) {
      queryParams.set('page', page.toString())
      queryParams.set('size', size.toString())
      navigate(`${location.pathname}?${queryParams.toString()}`)
    }
  }, [page, size])

  // logic download csv
  const handleDownloadCSV = (data: any[], fileName: string) => {
    if (!data || data.length === 0) {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.selectPatient')
      })
      return null
    }

    const headers = [
      '受診者ID',
      '氏名',
      '氏名（カナ）',
      '生年月日',
      '年齢',
      '性別',
      '健診日時',
      '健診結果ステータス',
      '担当医',
      '最終更新日',
      'ステータス'
    ]
    const keys = [
      'id',
      'name',
      'nameKana',
      'dateOfBirth',
      'age',
      'gender',
      'dateOfExam',
      'resultExam',
      'doctorName',
      'lastUpdateDate',
      'status'
    ]

    const resultExamMapping: { [key: number]: string } = {
      0: '未対応',
      1: '結果作成中',
      2: '判定待ち',
      3: '判定完了',
      4: '提出済み'
    }

    const statusMapping: { [key: string]: string } = {
      RESERVED: '予約済',
      UNCONFIRMED: '本人確認待ち',
      COLLECTED_DESIRED_DATE: '希望日回収済',
      CANCELLED: '予約キャンセル'
    }

    const csvContent = [
      headers.join(','),
      ...data.map((row) => {
        const fullName = row.name || ''
        const nameParts = fullName.split('（')
        const name = nameParts[0]
        const nameKana = nameParts[1] ? nameParts[1].replace('）', '') : ''

        return keys
          .map((key) => {
            if (key === 'id') {
              return `'${row[key]}`
            }
            if (key === 'name') {
              return name
            }
            if (key === 'nameKana') {
              return nameKana
            }
            if (key === 'resultExam') {
              return resultExamMapping[row[key]] || ''
            }
            if (key === 'status') {
              return row[key]
                ? (statusMapping[row[key]] ?? '')
                : t('customerManagement.reservationStatus.notReservation')
            }
            return row[key] !== undefined ? row[key] : ''
          })
          .join(',')
      })
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', `${fileName}.csv`)
    link.style.visibility = 'hidden'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadCSV = () => {
    const date = dayjs().format('YYYY-MM-DDTHH:mm:ss')
    handleDownloadCSV(
      listIdSelected,
      `${t('menu.testResultList')}/${date.toString()}`
    )
  }

  return (
    <div className="min-w-[2200px] relative mt-[-12px] overflow-x-scroll">
      {isExportData && <ScreenLoader />}
      <div className="mb-2">
        <Widget
          t={t}
          selectOptions={selectOptions}
          queryParams={queryParams}
          listIdSelected={listIdSelected}
          setIsExportData={setIsExportData}
          downloadCSV={downloadCSV}
        />
      </div>

      <div className="mb-5">
        <Content
          t={t}
          initialData={customers}
          initialDataCount={countCustomerByStatus}
          loading={loading}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          isStatusUpdated={isStatusUpdated}
          setStatusUpdated={setStatusUpdated}
          setListIdSelected={setListIdSelected}
          queryParams={queryParams}
        />
      </div>

      {customers && customers.length > 0 ? (
        <div>
          <CustomPagination
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
          />
        </div>
      ) : null}
    </div>
  )
}
