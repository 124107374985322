import React from 'react'

import { Typography } from 'antd'

import {
  DataForm,
  IJudgementItem,
  JUDGMENT
} from '../SetupThresholdAndCriteria'
import ItemMaster from './ItemMaster'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { CategoryWithMultipleLang } from 'types/Categories'

type Props = {
  category: CategoryWithMultipleLang
  judgementItemList: IJudgementItem[]
  language: string
  saveData: DataForm | null
  editThresholdAndCriteria: DataForm | null
  isJudgementItemEdit: boolean
}
export default function CategoryItems({
  category,
  judgementItemList,
  saveData,
  language,
  editThresholdAndCriteria,
  isJudgementItemEdit
}: Props) {
  return (
    <div className="w-full" id={JUDGMENT + category.refId}>
      <div className="p-3 tracking-widest bg-[#EFF3F6]">
        <Typography>{category.lang?.[language]}</Typography>
        <Typography className="tracking-normal">
          {category.lang?.en ?? VALUE_WITH_NO_DATA}
        </Typography>
      </div>
      {category?.itemMaster?.map((itemMaster: any) => {
        return (
          <ItemMaster
            editThresholdAndCriteria={editThresholdAndCriteria}
            key={itemMaster.refId}
            itemMaster={itemMaster}
            judgementItemList={judgementItemList}
            language={language}
            saveData={saveData}
            isJudgementItemEdit={isJudgementItemEdit}
          />
        )
      })}
    </div>
  )
}
