import React, { useEffect } from 'react'

import { MESSAGE_LIMIT, STORAGE_KEYS } from '../../configs/constant'
import emitter from '../../models/emitter'
import chatMessageApi from '../../services/chatMessageApi'
import {
  MESSAGE_TYPE,
  covertMessage,
  covertMessages,
  getInitialData
} from '../../utilities/chat'
import { useLocalStorage } from '../index'
import { subscriptionRoomMessages } from 'configs/rocketChat'
import { useBetween } from 'use-between'

const id: string = 'c8gEmvw4j356EwsKp'

const useShareReservationMessagesState = () => {
  const [gender, setGender] = React.useState<any>('')
  const [course, setCourse] = React.useState<any>('')
  const [date, setDate] = React.useState<any>('')

  return {
    gender,
    setGender,
    course,
    setCourse,
    date,
    setDate
  }
}

export const useReservationMessagesState = () =>
  useBetween(useShareReservationMessagesState)

const useShareChatMessagesState = () => {
  const [messages, setMessages] = React.useState<any[]>([])
  const [hasNewMessage, setHasNewMessage] = React.useState<boolean>(false)

  const addMessage = (message: any) => {
    let nextMessage = null
    if (messages?.length) {
      nextMessage = messages[messages?.length - 1]
    }

    const { newMessage, newNextMessage } = covertMessage(message, {
      nextMessage
    })

    if (newNextMessage) {
      messages[messages?.length - 1] = newNextMessage
    }
    setMessages((prevState) => [...prevState, newMessage])
  }

  return {
    messages,
    addMessage,
    setMessages,
    hasNewMessage,
    setHasNewMessage
  }
}

export const useChatMessagesState = () => useBetween(useShareChatMessagesState)

export const useMessages = () => {
  const { setMessages } = useChatMessagesState()

  const onUserSendMessage = async (message: any) => {
    await chatMessageApi.userSendDirectMessages({
      id: 2,
      roomId: id,
      message
    })
  }

  const onLoadHistoryMessages = async ({ date, hisDate }: any) => {
    await chatMessageApi.loadHistoryMessages({
      roomId: id,
      id: 3,
      date,
      hisDate
    })
  }

  const loadHistoryMessages = async () => {
    const payload: any = await chatMessageApi.loadHistoryMessages({
      roomId: id,
      date: new Date().getTime()
    })

    const { message } = payload
    const messagePayload = JSON.parse(message)
    if (messagePayload?.result) {
      const { messages } = messagePayload.result
      const newMessages = covertMessages(messages, {
        ...getInitialData([]),
        canLoadMore: messages?.length === MESSAGE_LIMIT
      })

      setMessages([...newMessages])
    }
  }

  return {
    onUserSendMessage,
    onLoadHistoryMessages,
    loadHistoryMessages
  }
}

export const useMessageSubscription = ({ id }: any) => {
  const { messages, addMessage, setHasNewMessage } = useChatMessagesState()
  const [rocketchat] = useLocalStorage('@rocketchat', {})

  const onChatMessageSubscription = (message: any) => {
    const currentMessage = messages?.find((mes) => mes.id === message?._id)
    if (currentMessage) {
      // if (message?.t === 'rm') {
      //   dispatch(deleteMessage(message))
      // } else if (message?.alias && message?.blocks) {
      //   dispatch(voteMessage(message))
      // } else {
      //   dispatch(reactionMessage(message))
      // }
    } else {
      addMessage(message)
      if (rocketchat?.rocketchat_user_id !== message?.u?._id) {
        setHasNewMessage(true)
      }

      chatMessageApi
        .readMessages({ id, roomId: id })
        .then(() => {
          if (message?.t === MESSAGE_TYPE.USER_LEAVE_ROOM) {
            // dispatch(setUserLeftRoom(true))
          }

          // if (message?.t === MESSAGE_TYPE.SUBSCRIPTION_ROLE_ADD) {
          //   const newRoles = [...roomRoles]
          //   newRoles.push(message?.msg)
          //   onUpdateRoomRoles(newRoles)
          //   onUpdateRoleChatRoom(newRoles?.includes(user?.rocketchat_username))
          // }

          // if (message?.t === MESSAGE_TYPE.SUBSCRIPTION_ROLE_REMOVE) {
          //   const newRoles = roomRoles.filter((r: any) => r !== message?.msg)
          //   onUpdateRoomRoles(newRoles)
          //   onUpdateRoleChatRoom(newRoles?.includes(user?.rocketchat_username))
          // }
        })
        .catch((error) => {
          console.error('Error in chatMessageApi.readMessages:', error)
        })
    }
  }

  useEffect(() => {
    const subscribeForChatMessage = () => {
      // @ts-ignore
      emitter.on('CHAT_MESSAGE', onChatMessageSubscription)
    }
    function unsubscribeForChatMessage() {
      // @ts-ignore
      emitter.off('CHAT_MESSAGE', onChatMessageSubscription)
    }

    subscribeForChatMessage()
    return () => {
      unsubscribeForChatMessage()
    }
  }, [id, JSON.stringify(messages)])

  useEffect(() => {
    subscriptionRoomMessages(id)
  }, [id])
}

export const useChatHistory = () => {
  // const setRoomsHistoryList = useSetRecoilState(roomsAtomRooms)

  // useEffect(() => {
  //   if (chatToken && chatUserId) {
  //     setRoomsHistoryList((prev) => ({
  //       ...prev,
  //       isLoading: true
  //     }))

  //     // promiseChatHistory({ chatToken, chatUserId })
  //     //   .then((res) => {
  //     //     const [channelsJoined, subscriptions]: any = res

  //     //     const history = convertChannelsToChatItems(
  //     //       channelsJoined.channels,
  //     //       subscriptions.update
  //     //     )

  //     //     setRoomsHistoryList({
  //     //       isLoading: false,
  //     //       rooms: history as ChatHistoryItem[]
  //     //     })
  //     //   })
  //     //   .catch(() => {})
  //     //   .finally(() => {
  //     //     setRoomsHistoryList((prev) => ({
  //     //       ...prev,
  //     //       isLoading: false
  //     //     }))
  //     //   })
  //   }

  //   return () => {
  //     setRoomsHistoryList({
  //       isLoading: false,
  //       rooms: []
  //     })
  //   }
  // }, [chatToken, chatUserId])

  // const convertChannelsToChatItems = (
  //   channels: RocketChatChannel[],
  //   subscriptions: RocketChatSubscription[]
  // ): ChatHistoryItem[] => {
  //   return channels.map((channel) => {
  //     const subscription = subscriptions.find((s) => s.rid === channel._id)
  //     const time = channel?._updatedAt
  //
  //     return {
  //       id: channel._id,
  //       text: getLastMsgForRoom(channel.lastMessage),
  //       time: formatTimeConditional(time),
  //       countNotRead: subscription?.unread ?? 0,
  //       isRead: subscription?.unread === 0,
  //       doctorName: channel?.customFields?.doctorName,
  //       type: channel?.customFields?.type,
  //       updateAt: time
  //     }
  //   })
  // }

  const fetchMessagesInRoom = async (
    roomId: string,
    timestamp: number | null,
    quantity = 100
  ) => {
    // id=27 is a random id
    const data = {
      message: `{"msg":"method","id":"27","method":"loadHistory","params":["${roomId}",${timestamp ? `{"$date":${timestamp}}` : null},${quantity},{"$date":${new Date().getTime()}}]}`
    }

    // Get admin token and user id from local storage
    const { adminToken, adminUserId } = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) ?? '{}'
    )

    return chatMessageApi.getMessagesInRoom({
      chatToken: adminToken,
      chatUserId: adminUserId,
      data
    })
  }

  return {
    fetchMessagesInRoom
  }
}
