import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
}

const DeleteDepartmentSuccessModal = ({
  isModalOpen = false,
  onCancel
}: Props) => {
  const { t } = useTranslation('')

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel}>
      <Flex vertical className="text-center pt-3 pb-6">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('deletionComplete')}
        </Typography>

        <Paragraph className="mt-[170px] mb-0">
          {t('content.departmentDeletionCompleted')}
        </Paragraph>

        <Button
          className="w-[180px] text-primary bg-white font-bold mt-[170px] mx-auto border-primary rounded"
          onClick={onCancel}
        >
          {t('button.return')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default DeleteDepartmentSuccessModal
