import { useLazyQuery } from '@apollo/client'
import { GET_DETAIL_PERIOD_CONFIGS } from 'graphql/ReferenceValue/getDetailPeriodConfigs'
import { Endpoint } from 'utilities/apolloClient'

const useReferenceValue = () => {
  const [getDetailPeriodConfigs] = useLazyQuery(GET_DETAIL_PERIOD_CONFIGS)

  const getListPeriodConfigsByIds = async (ids: string[]) => {
    try {
      const result = await getDetailPeriodConfigs({
        variables: {
          periodConfigObjIds: ids
        },
        context: { version: Endpoint.REFERENCE_VALUE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
      })

      return result?.data?.getDetailPeriodConfigs?.payload ?? []
    } catch (error) {
      console.error(error)
    }
  }

  return {
    getListPeriodConfigsByIds
  }
}

export default useReferenceValue
