import { RegisterReservationBodyType } from '../types'
import { REGISTER_RESERVATION_PATIENT } from 'graphql/ReservationManagement/createReservation'
import { UPDATE_RESERVATION_PATIENT } from 'graphql/ReservationManagement/updateReservation'
import { Endpoint, apolloClient } from 'utilities/apolloClient'

const registerReservation = async (body: RegisterReservationBodyType) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: REGISTER_RESERVATION_PATIENT,
    variables: body,
    context: { version: Endpoint.RESERVATION },
    fetchPolicy: 'network-only'
  })

  if (errors) {
    throw errors
  }

  const {
    registerReservation: { payload }
  } = data

  return payload
}

const updateReservation = async (body: RegisterReservationBodyType) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: UPDATE_RESERVATION_PATIENT,
    variables: body,
    context: { version: Endpoint.RESERVATION },
    fetchPolicy: 'network-only'
  })

  if (errors) {
    throw errors
  }

  const {
    updateReservation: { payload }
  } = data

  return payload
}

export { registerReservation, updateReservation }
