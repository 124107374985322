import { atom, useRecoilState } from 'recoil'

export const drawerScheduleAtom = atom({
  key: 'drawerScheduleAtom',
  default: false
})

const useDrawerSchedule = () => {
  const [isOpen, setIsOpen] = useRecoilState(drawerScheduleAtom)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    setIsOpen,
    onOpen,
    onClose
  }
}

export default useDrawerSchedule
