import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

export const InputLabel = ({
  label,
  required,
  className = '',
  bg = true,
  align = '',
  classRequired = ''
}: {
  label: string | ReactNode
  required?: boolean
  className?: string
  bg?: boolean
  align?: string
  classRequired?: string
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      className={`${bg ? 'bg-[#F0F3F7]' : ''} p-1 h-full`}
      justify="space-between"
      align={align}
    >
      <Typography
        className={`px-[6px] py-[5px] text-[#545454] text-[14px] font-bold ${className}`}
      >
        {label}
      </Typography>
      {required && (
        <Typography
          className={`bg-[#DF2475] text-nowrap px-[6px] py-[5px] text-[12px] font-bold text-white leading-[22px] ${classRequired}`}
        >
          {t('lable.required')}
        </Typography>
      )}
    </Flex>
  )
}

export const InputLabelMedium = ({
  label,
  required,
  className = '',
  bg = true,
  align = 'center'
}: {
  label: string | ReactNode
  required?: boolean
  className?: string
  bg?: boolean
  align?: string
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      className={`${bg ? 'bg-[#F0F3F7]' : ''} p-1 h-full`}
      justify="space-between"
      align={align}
    >
      <Typography.Text
        className={`px-[6px] text-[#545454] font-bold ${className}`}
      >
        {label}
      </Typography.Text>
      {required && (
        <Typography.Text className="bg-[#DF2475] text-nowrap px-[6px] text-[12px] font-bold text-white leading-[22.5px]">
          {t('lable.required')}
        </Typography.Text>
      )}
    </Flex>
  )
}

export const InputLabelNormal = ({
  label,
  required,
  className = '',
  bg = true,
  align = 'center'
}: {
  label: string | ReactNode
  required?: boolean
  className?: string
  bg?: boolean
  align?: string
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      className={`${bg ? 'bg-[#F0F3F7]' : ''} h-full`}
      justify="space-between"
      align={align}
    >
      <div className={`text-[#545454E0] font-normal ${className}`}>{label}</div>
      {required && (
        <Typography.Text className="bg-[#DF2475] text-nowrap px-[6px] py-1 font-bold text-xs text-white">
          {t('lable.required')}
        </Typography.Text>
      )}
    </Flex>
  )
}
