import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Flex, Typography } from 'antd'

import {
  DataForm,
  IJudgementItem,
  ITEM_START_NAME,
  JUDGMENT
} from '../SetupThresholdAndCriteria'
import ItemReferenceValue, {
  IReferenceValueType,
  ItemReferenceValueWithData
} from './ItemReferenceValue'
import { ReactComponent as FemaleIcon } from 'assets/svgs/female1.svg'
import { ReactComponent as GenderSelectIcon } from 'assets/svgs/gender-select.svg'
import { ReactComponent as GenderIcon } from 'assets/svgs/gender.svg'
import { ReactComponent as MaleIcon } from 'assets/svgs/male1.svg'
import { GENDER, VALUE_WITH_NO_DATA } from 'configs/constant'
import { ItemMasterWithMultipleLang } from 'types/ItemMasters'

type Props = {
  itemMaster: ItemMasterWithMultipleLang
  judgementItemList: IJudgementItem[]
  saveData: DataForm | null
  language: string
  editThresholdAndCriteria: DataForm | null
  isJudgementItemEdit: boolean
}

export interface IListReferenceValue {
  [key: string]: IReferenceValueType[]
}
export default function ItemMaster({
  itemMaster,
  judgementItemList,
  saveData,
  language,
  editThresholdAndCriteria,
  isJudgementItemEdit
}: Props) {
  const [constraintsGender, setConstraintsGender] = useState<boolean>(false)
  const { unregister } = useFormContext()
  const [listReferenceValue, setListReferenceValue] =
    useState<IListReferenceValue>({})

  useEffect(() => {
    if (!saveData) return

    const hasGender = judgementItemList.some((judgementItem) => {
      const data =
        saveData[JUDGMENT]?.[judgementItem.id]?.[
          ITEM_START_NAME + itemMaster.refId
        ]

      return Array.isArray(data) && data.some((item) => item.gender)
    })

    if (hasGender) setConstraintsGender(true)
  }, [saveData])

  useEffect(() => {
    if (!isJudgementItemEdit) return

    updateDataEdit()
  }, [editThresholdAndCriteria, isJudgementItemEdit])

  const updateDataEdit = () => {
    if (editThresholdAndCriteria) {
      const objectData: IListReferenceValue = {}
      let isGender = false
      for (const judgementItem of judgementItemList) {
        const dataByJudgementItem =
          editThresholdAndCriteria[JUDGMENT]?.[judgementItem.id]?.[
            ITEM_START_NAME + itemMaster.refId
          ] ?? []

        const dataNotEmpty: IReferenceValueType[] =
          dataByJudgementItem.length > 0
            ? dataByJudgementItem.filter((item: IReferenceValueType) => item)
            : []

        objectData[judgementItem.id] = dataNotEmpty

        if (dataNotEmpty.length > 0 && !isGender) {
          if (dataNotEmpty.some((item) => item.gender)) {
            isGender = true
          }
        }
      }

      if (isGender) {
        setConstraintsGender(true)
      }

      setListReferenceValue(objectData)
    }
  }

  useEffect(() => {
    for (const judgementItem of judgementItemList) {
      unregister(
        `${JUDGMENT}.${judgementItem.id}.${ITEM_START_NAME + itemMaster.refId}`
      )
    }
  }, [constraintsGender])

  const getReferenceValue = (judgementId: string, gender?: string) => {
    const referenceList = listReferenceValue[judgementId]
    if (!referenceList) return []

    return referenceList
      .map((item, index) => ({ ...item, parentIndex: index }))
      .filter(
        (item) =>
          (gender ? item.gender === gender : !item.gender) && !item.isDeleted
      )
  }

  return (
    <div className="w-full border-top-between">
      <Flex className="px-3 py-4 tracking-widest bg-white items-start">
        <div className="w-[228px]">
          <Typography>{itemMaster.lang?.[language]}</Typography>
          <Typography className="tracking-normal">
            {itemMaster.lang?.en ?? VALUE_WITH_NO_DATA}
          </Typography>
        </div>
        {!constraintsGender && (
          <>
            <div className="w-[84px]">
              {!saveData && (
                <GenderIcon
                  className="w-[46px] cursor-pointer"
                  onClick={() => setConstraintsGender(true)}
                />
              )}
            </div>
            <div className="w-[120px]">
              <Typography className="ml-[50px]">
                {itemMaster.unitOfMeasure ?? ''}
              </Typography>
            </div>
            <Flex className="gap-2.5">
              {judgementItemList.map((judgementItem: IJudgementItem) => {
                if (saveData)
                  return (
                    <ItemReferenceValueWithData
                      itemMaster={itemMaster}
                      judgementId={judgementItem.id}
                      key={judgementItem.id}
                      data={saveData}
                    />
                  )
                return (
                  <ItemReferenceValue
                    itemMaster={itemMaster}
                    judgementId={judgementItem.id}
                    key={judgementItem.id}
                    listReferenceValue={getReferenceValue(judgementItem.id)}
                    setListReferenceValue={setListReferenceValue}
                  />
                )
              })}
            </Flex>
          </>
        )}

        {constraintsGender && (
          <>
            <div className="w-[84px]">
              {!saveData && (
                <GenderSelectIcon
                  className="w-[46px] cursor-pointer"
                  onClick={() => setConstraintsGender(false)}
                />
              )}
            </div>
            <Flex vertical className="gap-5">
              <Flex>
                <Flex className="w-[120px]">
                  <FemaleIcon className="w-6" />
                  <Typography className="ml-[26px]">
                    {itemMaster.unitOfMeasure ?? ''}
                  </Typography>
                </Flex>
                <Flex className="gap-2.5">
                  {judgementItemList.map((judgementItem: IJudgementItem) => {
                    if (saveData)
                      return (
                        <ItemReferenceValueWithData
                          itemMaster={itemMaster}
                          judgementId={judgementItem.id}
                          key={judgementItem.id}
                          gender={GENDER.FEMALE}
                          data={saveData}
                        />
                      )

                    return (
                      <ItemReferenceValue
                        itemMaster={itemMaster}
                        judgementId={judgementItem.id}
                        key={judgementItem.id}
                        gender={GENDER.FEMALE}
                        listReferenceValue={getReferenceValue(
                          judgementItem.id,
                          GENDER.FEMALE
                        )}
                        setListReferenceValue={setListReferenceValue}
                      />
                    )
                  })}
                </Flex>
              </Flex>
              <Flex>
                <Flex className="w-[120px]">
                  <MaleIcon className="w-6" />
                  <Typography className="ml-[26px]">
                    {itemMaster.unitOfMeasure ?? ''}
                  </Typography>
                </Flex>
                <Flex className="gap-2.5">
                  {judgementItemList.map((judgementItem: IJudgementItem) => {
                    if (saveData)
                      return (
                        <ItemReferenceValueWithData
                          itemMaster={itemMaster}
                          judgementId={judgementItem.id}
                          key={judgementItem.id}
                          gender={GENDER.MALE}
                          data={saveData}
                        />
                      )

                    return (
                      <ItemReferenceValue
                        itemMaster={itemMaster}
                        judgementId={judgementItem.id}
                        key={judgementItem.id}
                        gender={GENDER.MALE}
                        listReferenceValue={getReferenceValue(
                          judgementItem.id,
                          GENDER.MALE
                        )}
                        setListReferenceValue={setListReferenceValue}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </div>
  )
}
