import { useTranslation } from 'react-i18next'

import { Text, View } from '@react-pdf/renderer'
import { StringKeyObject } from 'types/common'
import { getLanguage, handleReferenceValue } from 'utilities/helpers'

const TablePDF = ({
  tableFirst,
  dataFirstMedical,
  dataLastMedical,
  dataSecondMedical,
  style
}: {
  tableFirst: any
  dataLastMedical: any
  dataFirstMedical: any
  dataSecondMedical: any
  style?: any
}) => {
  const { t } = useTranslation()
  const language = getLanguage()
  return (
    <View style={{ flex: '1', height: 745, ...style }}>
      <View
        style={{
          color: 'white',
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#137695'
        }}
      >
        <View
          style={{
            width: '5.714%'
          }}
        >
          <Text
            style={{
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('testResult.evaluation')}
          </Text>
        </View>
        <View
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Text
            style={{
              width: '17.99%',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('inspectionItem')}
          </Text>
          <Text
            style={{
              width: '17.99%',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          ></Text>
          <Text
            style={{
              width: '5.68%',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('testResult.unit')}
          </Text>
          <Text
            style={{
              width: '10.227%',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('testResult.referenceValue')}
          </Text>
          <Text
            style={{
              flex: '1',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('pdfExport.current')}
          </Text>
          <Text
            style={{
              flex: '1',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('pdfExport.last')}
          </Text>
          <Text
            style={{
              flex: '1',
              padding: 2,
              border: '1px solid #93d1ca',
              paddingBottom: 4
            }}
          >
            {t('pdfExport.twoTimes')}
          </Text>
        </View>
      </View>
      {tableFirst.map((category: any, i: number) => (
        <View
          key={i}
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <View
            style={{
              width: '5.714%',
              backgroundColor: '#b9e1dc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #93d1ca'
            }}
          >
            <Text
              style={{
                padding: 2
              }}
            >
              {
                dataLastMedical?.testResults?.[category?.idItemMaster]
                  ?.evaluation
              }
            </Text>
          </View>
          <View
            style={{
              flex: '1',
              display: 'flex'
            }}
          >
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#b9e1dc'
              }}
            >
              <Text
                style={{
                  width: '17.99%',
                  padding: category?.lang?.[language] ? 2 : 5,
                  border: '1px solid #93d1ca'
                }}
              >
                {category?.lang?.[language] ?? ''}
              </Text>
              <Text
                style={{
                  width: '17.99%',
                  padding: 2,
                  border: '1px solid #93d1ca'
                }}
              >
                {category?.lang?.en ?? ''}
              </Text>
              <Text
                style={{
                  width: '5.68%',
                  padding: 2,
                  border: '1px solid #93d1ca'
                }}
              ></Text>
              <Text
                style={{
                  width: '10.227%',
                  padding: 2,
                  border: '1px solid #93d1ca'
                }}
              ></Text>
              <Text
                style={{
                  flex: '1',
                  padding: 2,
                  border: '1px solid #93d1ca'
                }}
              ></Text>
              <Text
                style={{
                  flex: '1',
                  padding: 2,
                  border: '1px solid #93d1ca'
                }}
              ></Text>
              <Text
                style={{
                  flex: '1',
                  padding: 2,
                  border: '1px solid #93d1ca'
                }}
              ></Text>
            </View>
            {Array.isArray(category?.itemMaster) &&
              category.itemMaster.map((itemMaster: any, k: any) => {
                return (
                  <View
                    key={k}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text
                      style={{
                        width: '17.99%',
                        backgroundColor: '#e9f5f4',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {itemMaster?.lang?.[language] ?? ''}
                    </Text>
                    <Text
                      style={{
                        width: '17.99%',
                        backgroundColor: '#e9f5f4',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {itemMaster?.lang?.en ?? ''}
                    </Text>
                    <Text
                      style={{
                        width: '5.68%',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {itemMaster?.unitOfMeasure ?? ''}
                    </Text>
                    <Text
                      style={{
                        width: '10.227%',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {itemMaster.referenceValues.map(
                        (item: StringKeyObject, index: number) => (
                          <Text
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 2
                            }}
                            key={index}
                          >
                            {handleReferenceValue(item, t)}
                          </Text>
                        )
                      )}
                    </Text>
                    <Text
                      style={{
                        flex: '1',
                        textAlign: 'right',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {dataLastMedical?.testResults?.[itemMaster.refId]
                        ?.value ?? ''}
                    </Text>
                    <Text
                      style={{
                        flex: '1',
                        textAlign: 'right',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {dataSecondMedical?.testResults?.[itemMaster.refId]
                        ?.value ?? ''}
                    </Text>
                    <Text
                      style={{
                        flex: '1',
                        textAlign: 'right',
                        padding: 2,
                        border: '1px solid #93d1ca'
                      }}
                    >
                      {dataFirstMedical?.testResults?.[itemMaster.refId]
                        ?.value ?? ''}
                    </Text>
                  </View>
                )
              })}
          </View>
        </View>
      ))}
    </View>
  )
}

export default TablePDF
