import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import ModalConfirm from 'components/modals/ModalConfirm'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { getLanguage } from 'utilities/helpers'
import { showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'

const ModalDeleteConfirm = ({
  isModalVisible,
  handleOk,
  handleCancel,
  ListDeleteItems,
  listDeleteRefId
}: {
  isModalVisible: boolean
  ListDeleteItems: DataItemMaster[]
  listDeleteRefId: string[]
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const { t } = useTranslation()
  const language = getLanguage()
  return (
    <ModalConfirm
      isModalVisible={isModalVisible}
      title={<span className='tracking-[0.2em]'>{t('button.delete')}</span>}
      handleOk={handleOk}
      handleCancel={handleCancel}
      textOk={<span className='!tracking-normal'>{t('button.delete')}</span>}
      icons={
        <div className="mt-14">
            <ExclamationCircleFilled className="w-10 pb-6 inline-block" />
          </div>
      }
      classNameModal='!w-[900px]'
      content={
        <>
          <div className="mb-11 w-full flex items-center justify-center tracking-normal">
            <div className="flex flex-col justify-center w-fit">
              <p className="flex items-start text-default w-full">
                {t('modalConfirm.infoConfirmDelete', {
                  count: listDeleteRefId.length
                })}
              </p>
              <p className="flex items-start text-[#df2475] w-full">
                {t('modalConfirm.contentConfirm')}
              </p>
            </div>
          </div>
          <div className="border max-h-[467px] flex flex-col gap-2 overflow-auto mx-5">
            {ListDeleteItems?.map((category) => (
              <div key={category.refId} className="flex flex-col gap-2">
                {category.isChecked && (
                  <Flex className="grid grid-cols-2 bg-tableGray p-3">
                    <Typography className="col-span-1 text-default font-bold text-start">{`${showName(category, language) ?? VALUE_WITH_NO_DATA} `}</Typography>
                    <Typography className="col-span-1 text-default font-bold text-start">{`${showName(category, 'en') ?? VALUE_WITH_NO_DATA} `}</Typography>
                  </Flex>
                )}
                {category.items?.map(
                  (item) =>
                    item.isChecked && (
                      <Flex key={item.refId} className="grid grid-cols-2 px-3">
                        <Typography className="col-span-1 text-default text-start">{`${showName(item, language) ?? VALUE_WITH_NO_DATA} `}</Typography>
                        <Typography className="col-span-1 text-default text-start">{`${showName(item, 'en') ?? VALUE_WITH_NO_DATA} `}</Typography>
                      </Flex>
                    )
                )}
              </div>
            ))}
          </div>
        </>
      }
    />
  )
}

export default ModalDeleteConfirm
