import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Select, Typography } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import { RECORD_COUNT } from 'models/customer'
import { uuid } from 'utilities/helpers'

const selectOptions = [
  {
    value: RECORD_COUNT.Fifty,
    label: RECORD_COUNT.Fifty
  },
  {
    value: RECORD_COUNT.OneHundred,
    label: RECORD_COUNT.OneHundred
  },
  {
    value: RECORD_COUNT.TwoHundred,
    label: RECORD_COUNT.TwoHundred
  },
  {
    value: RECORD_COUNT.FiveHundred,
    label: RECORD_COUNT.FiveHundred
  }
]

function ElmNumbers({
  label,
  currentElmNumbers,
  onChange
}: {
  readonly label: string
  readonly currentElmNumbers: number
  readonly onChange: (page: number) => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-2 my-3 mx-3">
      <Flex align="center">
        <Typography className="text-[12px]">
          <span>{label}</span>
        </Typography>

        <Select
          className="ml-[7px] h-8 min-w-[85px]"
          value={currentElmNumbers}
          onChange={onChange}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
        >
          {selectOptions.map((option, index) => (
            <Select.Option
              key={uuid()}
              value={option.value}
            >{`${option.label} ${t('customerManagement.displayInfo.record')}`}</Select.Option>
          ))}
        </Select>
      </Flex>
    </div>
  )
}

export default ElmNumbers
