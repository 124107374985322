import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Layout, Table, Typography } from 'antd'

import AdminOperations from './AdminOperations'
import DeleteAdminErrorModal from './DeleteAdminErrorModal'
import DeleteAdminModal from './DeleteAdminModal'
import DeleteAdminSuccessModal from './DeleteAdminSuccessModal'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'
import { ADMIN_LIST_SORT_TYPE, SORT_ORDER } from 'configs/constant'
import { Admin } from 'models/admin'
import { StringKeyObject } from 'types/common'
import { getLanguage } from 'utilities/helpers'

const { Content } = Layout

const getContentByLabel = (label: string) => {
  const array = label ? label.split(',') : []

  return (
    <Content className="custom-scrollbar">
      {array.map((item: string, index: number) => (
        <Typography key={index}>{item}</Typography>
      ))}
    </Content>
  )
}

const getContentRoles = (roles: StringKeyObject[], language: string) => {
  return (
    <Content className="custom-scrollbar">
      {roles.map((item: StringKeyObject) => (
        <Typography key={item.id} className="min-h-[22px]">
          {item?.lang?.[language] ?? item.label}
        </Typography>
      ))}
    </Content>
  )
}

export default function AdministratorList({
  methods,
  onSearch,
  setListRowSelected,
  loading,
  error,
  admins,
  handleSearchData,
  handleDeleteAdmin,
  setFirstCall,
  isOwner
}: {
  readonly methods: any
  readonly onSearch: any
  readonly setListRowSelected: any
  readonly loading: any
  readonly error: any
  readonly admins: any
  readonly handleSearchData: any
  readonly handleDeleteAdmin: any
  readonly setFirstCall: any
  readonly isOwner: boolean
}) {
  const { setValue, resetField, getValues } = methods
  const { t } = useTranslation()

  let location = useLocation()
  const navigate = useNavigate()
  const [deleteAdmin, setDeleteAdmin] = useState<Admin | null>(null)
  const [deleteAdminError, setDeleteAdminError] = useState<Admin | null>(null)
  const [deleteAdminSuccess, setDeleteAdminSuccess] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [reload, setReload] = useState<boolean>(false)
  const language = getLanguage()

  useEffect(() => {
    handleFilterByUrl()
  }, [location.search])

  useEffect(() => {
    if (reload) {
      handleFilterByUrl()
      setReload(false)
    }
  }, [reload])

  const handleFilterByUrl = async () => {
    const searchParamsUrl = new URLSearchParams(location.search)
    let nameOrEmailAddress = searchParamsUrl.get('nameOrEmailAddress')
    let departments: any = searchParamsUrl.get('departments')
    if (departments) {
      departments = departments.split(',')
    }

    let assignments: any = searchParamsUrl.get('assignments')
    if (assignments) {
      assignments = assignments.split(',')
    }

    let positions: any = searchParamsUrl.get('positions')
    if (positions) {
      positions = positions.split(',')
    }

    let sortBy = searchParamsUrl.get('sortBy')
    let orderBy = searchParamsUrl.get('orderBy')

    if (nameOrEmailAddress) {
      setValue('nameOrEmailAddress', nameOrEmailAddress)
    } else {
      resetField('nameOrEmailAddress')
    }

    if (departments && departments.length > 0) {
      setValue('departments', departments)
    } else {
      resetField('departments')
    }

    if (assignments && assignments.length > 0) {
      setValue('assignments', assignments)
    } else {
      resetField('assignments')
    }

    if (positions && positions.length > 0) {
      setValue('positions', positions)
    } else {
      resetField('positions')
    }

    if (sortBy && orderBy) {
      setValue('sortBy', sortBy)
      setValue('orderBy', orderBy)
    } else {
      resetField('sortBy')
      resetField('orderBy')
    }

    await handleSearchData({
      nameOrEmailAddress: nameOrEmailAddress,
      departments: departments,
      assignments: assignments,
      positions: positions,
      sortBy: sortBy ?? ADMIN_LIST_SORT_TYPE.CREATED_TIMESTAMP,
      orderBy: orderBy ?? SORT_ORDER.DESC
    })
  }

  const toggleSort = async (field: ADMIN_LIST_SORT_TYPE) => {
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')
    if (!orderBy) orderBy = 'asc'

    if (field === sortBy) {
      orderBy = orderBy === 'asc' ? 'desc' : 'asc'
    }

    setValue('sortBy', field)
    setValue('orderBy', orderBy)

    await onSearch()
  }

  const handleDelete = async (id: string) => {
    try {
      const deleteAdmin = await handleDeleteAdmin(id)

      if (deleteAdmin) {
        setDeleteAdminSuccess(true)
      } else {
        setReload(true)
      }
      setFirstCall(false)
    } catch (err) {
      console.error(err)
    }
  }

  const deleteSuccessAdmin = async () => {
    setDeleteAdminSuccess(false)
    await handleFilterByUrl()
  }

  const columns = [
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography>{t('table.name_kana')}</Typography>
          <ArrowTopBottom
            className="ml-1.5 cursor-pointer"
            onClick={() => {
              toggleSort(ADMIN_LIST_SORT_TYPE.FULL_NAME)
            }}
          />
        </Flex>
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      width: '18%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography>{t('lable.gender')}</Typography>
          <ArrowTopBottom
            className="ml-1 cursor-pointer"
            onClick={() => {
              toggleSort(ADMIN_LIST_SORT_TYPE.SEX)
            }}
          />
        </Flex>
      ),
      dataIndex: 'sex',
      key: 'sex',
      width: '8%',
      render: (sex: string | undefined) => (
        <Typography>{sex ? t(sex) : ''}</Typography>
      )
    },
    {
      title: <Typography>{t('lable.mailAddress')}</Typography>,
      dataIndex: 'email',
      key: 'email',
      width: '17%'
    },
    {
      title: <Typography>{t('role')}</Typography>,
      dataIndex: 'roles',
      key: 'roles',
      width: '18%',
      render: (roles: StringKeyObject[], record: Admin) => {
        if (record?.currentOwner) {
          return (
            <Flex className="items-center gap-[5px] tracking-wider">
              <CrownIcon className="inline-block w-[17px]" />
              {t('fullAuthorityAdministrator')}
            </Flex>
          )
        }

        return getContentRoles(roles, language)
      }
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography>{t('lable.department')}</Typography>
          <ArrowTopBottom
            className="ml-5 cursor-pointer"
            onClick={() => {
              toggleSort(ADMIN_LIST_SORT_TYPE.DEPARTMENT)
            }}
          />
        </Flex>
      ),
      dataIndex: 'departments',
      key: 'departments',
      width: '17%',
      render: (text: string) => getContentByLabel(text)
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography>{t('table.assignment')}</Typography>
          <ArrowTopBottom
            className="ml-8 cursor-pointer"
            onClick={() => {
              toggleSort(ADMIN_LIST_SORT_TYPE.ASSIGNMENT)
            }}
          />
        </Flex>
      ),
      dataIndex: 'assignments',
      key: 'assignments',
      width: '18%',
      render: (text: string) => getContentByLabel(text)
    },
    {
      title: <Typography>{t('table.operation')}</Typography>,
      dataIndex: 'operation',
      key: 'operation',
      width: '6%',
      render: (_: void, record: Admin) => (
        <AdminOperations
          admin={record}
          setDeleteAdmin={
            record?.currentOwner ? setDeleteAdminError : setDeleteAdmin
          }
          isOwner={isOwner}
        />
      )
    }
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setListRowSelected(selectedRows)
    }
  }

  if (error) {
    //Todo handle show error
  }

  return (
    <>
      <Button
        autoInsertSpace={false}
        type="primary"
        className="min-w-[120px] block ml-auto font-bold rounded-[3px] px-1 border-none mt-2 shadow-none"
        onClick={() => navigate('/admin-register')}
      >
        {t('button.newStaffAddition')}
      </Button>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        pagination={false}
        dataSource={admins}
        columns={columns}
        className="mt-2 table-admin pb-10"
        rowKey="key"
        loading={loading}
      />
      <DeleteAdminModal
        admin={deleteAdmin}
        onCancel={() => setDeleteAdmin(null)}
        onSuccess={() => {
          if (deleteAdmin?.key) handleDelete(deleteAdmin.key)
        }}
      />
      <DeleteAdminErrorModal
        admin={deleteAdminError}
        onCancel={() => setDeleteAdminError(null)}
      />
      <DeleteAdminSuccessModal
        isModalOpen={deleteAdminSuccess}
        onCancel={() => deleteSuccessAdmin()}
      />
    </>
  )
}
