import React, { FC, memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import CourseSelect from './CourseSelect'
import CustomDateRangePicker from 'components/elements/CustomDateRangePicker'
import { PATHS } from 'configs/constant'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

type Props = {}

const Widget: FC<Props> = memo(() => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const {
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam
  } = Object.fromEntries(queryParams)

  const selectedDateRangeRef = useRef<[string, string] | undefined>(undefined)

  const updateDateRange = (dates: [string, string] | undefined) => {
    if (dates) {
      const formattedDates: [string, string] = [
        dayjs(dates[0]).utc().format('YYYY-MM-DD'),
        dayjs(dates[1]).utc().format('YYYY-MM-DD')
      ]

      const hasChanged =
        !selectedDateRangeRef.current ||
        !formattedDates.every(
          (value, index) => value === selectedDateRangeRef.current?.[index]
        )

      if (hasChanged) {
        selectedDateRangeRef.current = formattedDates

        queryParams.set('startDate', formattedDates[0])
        queryParams.set('endDate', formattedDates[1])
      }
    } else {
      selectedDateRangeRef.current = undefined

      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" className="flex-1">
        <Typography.Text className="mr-6 text-base font-bold tracking-[1.6px] text-[#545454]">
          {t('productManagement.productManagement')}
        </Typography.Text>

        <CourseSelect
          placeholder={t('productManagement.widget.placeholder.search')}
        />

        <CustomDateRangePicker
          placeholder={t('productManagement.widget.placeholder.selectDate')}
          placeholder2={t('productManagement.widget.placeholder.choice')}
          initialStartDate={startDateParam || undefined}
          initialEndDate={endDateParam || undefined}
          isDisabledDate={false}
          setDates={updateDateRange}
        />
      </Flex>

      {courseParam ? (
        <Typography
          onClick={() =>
            navigate({
              pathname: PATHS.PRODUCT_MANAGEMENT
            })
          }
          className="mr-3 cursor-pointer text-sm font-bold text-[#137695] underline"
        >
          ＜{t('button.return')}
        </Typography>
      ) : (
        <Button
          type="primary"
          autoInsertSpace={false}
          className="h-[30px] min-w-[114px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-center text-sm font-bold text-white shadow-none"
          onClick={() =>
            navigate({
              pathname: PATHS.PRODUCT_SETTING
            })
          }
        >
          {t('productManagement.widget.button.setting')}
        </Button>
      )}
    </Flex>
  )
})

export default Widget
