import { useEffect, useState } from 'react'

import { List } from 'antd'
import { showName } from 'pages/InspectionItemSetting/helper'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import { getLanguage } from 'utilities/helpers'
import { uuid } from 'short-uuid'

export default function MenuCategoryList({ categories }: { categories: DataItemMaster[] }) {
  const language = getLanguage()
  const [activeIndex, setActiveIndex] = useState<number | null | string>(null)

  useEffect(() => {
    if (categories) {
      setActiveIndex(categories?.[0]?.refId)
    }
  }, [categories])

  return (
    <div className="!rounded-l-lg shadow-md rounded-r-none bg-[#DCE3EC] pl-2 pt-2.5 flex-1">
      <List itemLayout="vertical">
        {categories &&
          categories.map((item) => (
            <a
              href={`#${item.refId}`}
              style={{ color: 'inherit', textDecoration: 'none' }}
              key={uuid()}
            >
              <List.Item
                style={{
                  padding: '10px 14px 10px 14px',
                  backgroundColor:
                    activeIndex === item.refId ? '#137695' : '#DCE3EC',
                  color: activeIndex === item.refId ? '#FFFFFF' : '#137695',
                  borderRadius:
                    activeIndex === item.refId ? '6px 0px 0px 6px' : '',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  lineHeight: 1,
                  letterSpacing: '1.4px'
                }}
                onClick={() => setActiveIndex(item.refId)}
              >
                {showName(item, language)}
              </List.Item>
            </a>
          ))}
      </List>
    </div>
  )
}
