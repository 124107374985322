import React, { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex, Select, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import { RESERVATION_STATUS } from '../../../models/reservationManagement'
import './CustomManagement.css'
import StatusDropdown from './StatusDropdown'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { MEDICAL_CHECKUP_STATUS } from 'configs/constant'
import { Customer } from 'hooks/useCustomer'
import { RECORD_COUNT, TAB } from 'models/customer'

type Props = {
  t: TFunction<'translation', undefined>
  initialData: Customer[]
  initialDataCount: any
  loading: boolean
  size: number
  page: number
  setPage: (page: number) => void
  setSize: (data: number) => void
  isStatusUpdated: boolean
  setStatusUpdated: (status: boolean) => void
  setListIdSelected: any
  queryParams: URLSearchParams
}

const Content: FC<Props> = ({
  t,
  initialData,
  initialDataCount,
  loading,
  size,
  setSize,
  page,
  setPage,
  isStatusUpdated,
  setStatusUpdated,
  setListIdSelected,
  queryParams
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const status = queryParams.get('status')

  const [, setMaxHeight] = useState(window.innerHeight - 170)
  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(window.innerHeight - 170)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [filterByStatus, setFilterByStatus] = useState<number>(
    status && !isNaN(Number(status)) ? Number(status) : 10
  )
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc')
  const [selected, setSelected] = useState<number>(filterByStatus)
  const [customers, setCustomers] = useState<Customer[]>([])

  const [totalCount, setTotalCount] = useState<number>(0)
  const [resultIncompatible, setResultIncompatible] = useState<number>(0)
  const [resultCreating, setResultCreating] = useState<number>(0)
  const [resultWaiting, setResultWaiting] = useState<number>(0)
  const [resultEnded, setResultEnded] = useState<number>(0)
  const [resultSent, setResultSent] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  useEffect(() => {
    if (!queryParams.toString()) {
      setSelected(10)
      setFilterByStatus(10)
    }
  }, [location.search])

  useEffect(() => {
    if (!loading) {
      if (initialDataCount && initialData) {
        setResultIncompatible(0)
        setResultCreating(0)
        setResultWaiting(0)
        setResultEnded(0)
        setResultSent(0)
        setCustomers(initialData)
        setSelectedRowKeys([])

        const statusMap: Record<string, (count: number) => void> = {
          '0': (count) => setResultIncompatible((prev) => prev + count),
          '1': (count) => setResultCreating((prev) => prev + count),
          '2': (count) => setResultWaiting((prev) => prev + count),
          '3': (count) => setResultEnded((prev) => prev + count),
          '4': (count) => setResultSent((prev) => prev + count)
        }

        let totalCount = 0

        initialDataCount.data.forEach(
          (record: { count: number; status: number | string }) => {
            totalCount += record.count

            const updateFunction = statusMap[String(record.status)]
            if (updateFunction) {
              updateFunction(record.count)
            }
          }
        )

        setTotalCount(totalCount)
      }
    }
  }, [loading, filterByStatus, initialData, initialDataCount, page])

  const handleClick = (text: number) => {
    setSelected(text)
    setSelectedRowKeys([])

    queryParams.set('status', `${text}`)
    queryParams.set('page', `1`)
    navigate(`/customer-management?${queryParams.toString()}`)
  }

  const getStyle = (text: number) => {
    return {
      color: selected === text ? '#137695' : '',
      fontWeight: selected === text ? 'bold' : ''
    }
  }

  const handleSortBy = (sortValue: string) => {
    const newSortOrder = sortBy === 'asc' ? 'desc' : 'asc'
    setSortBy(newSortOrder)
    queryParams.set('orderBy', sortValue)
    queryParams.set('sortBy', newSortOrder)

    navigate(`/customer-management?${queryParams.toString()}`)
  }

  const dataSource: any[] = customers.map((customer, index) => {
    const firstName = customer.additionalInfo.firstName || ''
    const lastName = customer.additionalInfo.lastName || ''
    const firstNameKana = customer.additionalInfo.firstNameKana || ''
    const lastNameKana = customer.additionalInfo.lastNameKana || ''

    let fullName = `${firstName}${lastName}`.trim()
    let fullNameKana = `${firstNameKana}${lastNameKana}`.trim()

    return {
      key: index,
      id: customer.refId,
      name: fullNameKana ? `${fullName}（${fullNameKana}）` : `${fullName}`,
      dateOfBirth: customer.birthday,
      age: customer.age,
      gender: customer.gender,
      dateOfExam: customer.dateOfExam,
      resultExam: customer.resultOfExam,
      doctorName: customer.doctorName,
      disable:
        !customer.medicalCheckupDetail ||
        +customer.medicalCheckupDetail?.additionalInfo?.healthCheckStatus ===
          MEDICAL_CHECKUP_STATUS.SUBMITTED,
      // data pdf
      firstName: customer?.additionalInfo?.firstName,
      lastName: customer?.additionalInfo?.lastName,
      zipCodePart1: customer.additionalInfo.zipCode?.substring(0, 3),
      zipCodePart2: customer.additionalInfo.zipCode?.substring(3),
      province: customer.additionalInfo.province,
      district: customer.additionalInfo.district,
      birthday: customer.birthday,
      insuranceSymbol: customer.additionalInfo.insuranceSymbol,
      insuranceNumber: customer.additionalInfo.insuranceNumber,
      numberOfInsurance: customer.additionalInfo.numberOfInsurance,
      patientId: customer.refId,
      status: customer.status,
      lastUpdateDate: customer.lastUpdateDate
    }
  })

  const dataForPrevNext = customers.map((customer) => {
    return customer.refId
  })

  const getStatusText = (status: RESERVATION_STATUS) => {
    switch (status) {
      case RESERVATION_STATUS.RESERVED:
        return {
          text: t('reservationManagement.widget.status.booked'),
          color: '#137695',
          textColor: '#ffffff'
        }
      case RESERVATION_STATUS.UNCONFIRMED:
        return {
          text: t('reservationManagement.widget.status.waitConfirm'),
          color: '#137695',
          textColor: '#ffffff'
        }
      case RESERVATION_STATUS.COLLECTED_DESIRED_DATE:
        return {
          text: t('reservationManagement.widget.status.collectDate'),
          color: '#DF2475',
          textColor: '#ffffff'
        }
      case RESERVATION_STATUS.CANCELLED:
        return {
          text: t('reservationManagement.widget.status.canceled'),
          color: '',
          textColor: ''
        }
      default:
        return {
          text: t('customerManagement.reservationStatus.notReservation'),
          color: '#BDCBD5',
          textColor: '#ffffff'
        }
    }
  }

  const columns: TableColumnsType<any> = [
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.id')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('refId')}
          />
        </Flex>
      ),
      dataIndex: 'id',
      width: '18%',
      render: (text) => (
        <Typography className="h-[22px] whitespace-nowrap pl-2">
          {text}
        </Typography>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-10 font-normal">
            {t('customerManagement.table.name')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('additionalInfo.fullName')}
          />
        </Flex>
      ),
      dataIndex: 'name',
      width: '20%',
      render: (value, index) => (
        <Flex className="px-2 w-full">
          {value ? (
            <a
              className="underline"
              onClick={() => {
                navigate(
                  `/customer-detail?id=${index.id}&currentTab=${TAB.RESULT_LIST}`,
                  {
                    state: {
                      openListResultTab: TAB.CONTROL,
                      location: { ids: dataForPrevNext, index: index.key }
                    }
                  }
                )
              }}
            >
              <Typography className="font-bold text-[#137695] pl-2">
                {value}
              </Typography>
            </a>
          ) : (
            <Typography>ー</Typography>
          )}
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.dateOfBirth')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('birthday')}
          />
        </Flex>
      ),
      dataIndex: 'dateOfBirth',
      width: '7%',
      align: 'center',
      render: (text) => (
        <Typography className="text-[#707070] tracking-[1.4px]">
          {text}
        </Typography>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.age')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('birthday')}
          />
        </Flex>
      ),
      dataIndex: 'age',
      width: '7%',
      align: 'center',
      render: (text) => (
        <Typography className="tracking-[1.4px]">{text}</Typography>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.gender')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('gender')}
          />
        </Flex>
      ),
      dataIndex: 'gender',
      width: '7%',
      align: 'center',
      render: (text) => (
        <Typography>
          {text === 'MALE'
            ? t('customerDetail.header.male')
            : t('customerDetail.header.female')}
        </Typography>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.dateOfExam')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('testResult.readingTakenOn')}
          />
        </Flex>
      ),
      dataIndex: 'dateOfExam',
      width: '7%',
      align: 'center',
      render: (text) => (
        <>
          {text ? (
            <Typography className="tracking-[1.4px]">
              {dayjs(text).format('YYYY/MM/DD')}
            </Typography>
          ) : (
            <Typography>ー</Typography>
          )}
        </>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.resultExam')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() =>
              handleSortBy('medicalCheckup.additionalInfo.healthCheckStatus')
            }
          />
        </Flex>
      ),
      dataIndex: 'resultExam',
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <Flex justify="center" align="center">
          <StatusDropdown
            t={t}
            status={value}
            userId={record.id}
            disable={record.disable}
            isStatusUpdated={isStatusUpdated}
            setStatusUpdated={setStatusUpdated}
          />
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.doctorName')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => handleSortBy('testResult.valueBy')}
          />
        </Flex>
      ),
      dataIndex: 'doctorName',
      width: '8%',
      align: 'center',
      render: (text) => (
        <>
          {text ? (
            <Typography className="tracking-[1.4px]">{text}</Typography>
          ) : (
            <Typography>ー</Typography>
          )}
        </>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.lastUpdateDate')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'lastUpdateDate',
      width: '10%',
      align: 'center',
      render: (text) => (
        <>
          {text ? (
            <Typography className="text-center tracking-[1.4px]">
              {text}
            </Typography>
          ) : (
            <Typography>ー</Typography>
          )}
        </>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-normal">
            {t('customerManagement.table.status')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'status',
      width: '10%',
      align: 'center',
      render: (value) => {
        const { text, color, textColor } = getStatusText(value)

        return (
          <Flex justify="center" align="center">
            <Typography
              className={`flex min-w-[119px] justify-center text-nowrap rounded-[13px] bg-[${color}] px-3 py-[2px] text-sm tracking-[1.4px] text-[${textColor}]`}
            >
              {text}
            </Typography>
          </Flex>
        )
      }
    },
    {
      title: null,
      render: () => null
    }
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: Customer[]) => {
      // console.log(
      //   'selectedRowKeys:',
      //   newSelectedRowKeys,
      //   'selectedRows:',
      //   selectedRows
      // )
      setSelectedRowKeys(newSelectedRowKeys)
      setListIdSelected(selectedRows)
    }
  }

  const selectOptions = [
    {
      value: RECORD_COUNT.Fifty,
      label: `${RECORD_COUNT.Fifty}${t('customerManagement.displayInfo.record')}`
    },
    {
      value: RECORD_COUNT.OneHundred,
      label: `${RECORD_COUNT.OneHundred}${t('customerManagement.displayInfo.record')}`
    },
    {
      value: RECORD_COUNT.TwoHundred,
      label: `${RECORD_COUNT.TwoHundred}${t('customerManagement.displayInfo.record')}`
    },
    {
      value: RECORD_COUNT.FiveHundred,
      label: `${RECORD_COUNT.FiveHundred}${t('customerManagement.displayInfo.record')}`
    }
  ]

  return (
    <div className="overflow-y-auto rounded-[5px] bg-[#FFFFFF] drop-shadow-md">
      {!loading ? null : (
        <div className="w-full h-full z-10 absolute top-0 left-0 cursor-not-allowed"></div>
      )}
      <Flex justify="space-between" className="pl-6 pr-1 pt-1">
        <Flex className="grid grid-cols-6 min-w-[890px] gap-2">
          <Typography
            onClick={() => handleClick(10)}
            style={getStyle(10)}
            className="flex justify-center items-center min-w-40 underline cursor-pointer text-nowrap "
          >
            {t('customerManagement.actions.showAll')}({totalCount})
          </Typography>

          <Typography
            onClick={() => handleClick(0)}
            style={getStyle(0)}
            className="flex justify-center items-center min-w-40 underline cursor-pointer text-nowrap"
          >
            {t('customerManagement.result.incompatible')}({resultIncompatible})
          </Typography>

          <Typography
            onClick={() => handleClick(1)}
            style={getStyle(1)}
            className="flex justify-center items-center min-w-40 underline cursor-pointer text-nowrap"
          >
            {t('customerManagement.result.creating')}({resultCreating})
          </Typography>

          <Typography
            onClick={() => handleClick(2)}
            style={getStyle(2)}
            className="flex justify-center items-center min-w-40 underline cursor-pointer text-nowrap"
          >
            {t('customerManagement.result.waiting')}({resultWaiting})
          </Typography>

          <Typography
            onClick={() => handleClick(3)}
            style={getStyle(3)}
            className="flex justify-center items-center min-w-40 underline cursor-pointer text-nowrap"
          >
            {t('customerManagement.result.ended')}({resultEnded})
          </Typography>

          <Typography
            onClick={() => handleClick(4)}
            style={getStyle(4)}
            className="flex justify-center items-center min-w-40 underline cursor-pointer text-nowrap"
          >
            {t('customerManagement.result.sent')}({resultSent})
          </Typography>
        </Flex>

        <Flex align="center">
          <Typography className="text-[12px]">
            {t('customerManagement.displayInfo.resultDisplayed')}
          </Typography>
          <Select
            options={selectOptions}
            value={size}
            onChange={(value) => {
              setSize(value)
              setPage(1)
            }}
            suffixIcon={
              <CaretDownOutlined
                style={{ color: '#137695', pointerEvents: 'none' }}
              />
            }
            className="customer_management_select ml-[7px] h-[30px]"
          />
        </Flex>
      </Flex>

      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          x: 1500,
          y: 'calc(100vh - 115px)'
        }}
        className="customer_management_table py-4 pl-6 pr-1 pt-1"
      />
    </div>
  )
}

export default Content
