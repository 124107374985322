/* eslint-disable indent */
import mitt from 'mitt'

const emitter =
  typeof window === 'undefined'
    ? {
        all: new Map(),
        on: () => {
          console.error('emitter')
        },
        off: () => {
          console.error('emitter')
        },
        emit: () => {
          console.error('emitter')
        }
      }
    : mitt()

export default emitter
