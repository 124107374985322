import { STATUS_CODE_SUCCESS } from '../../configs/constant'
import { UPDATE_TENANT } from '../../graphql/tenant/update'
import {
  COUNTRY,
  DEFAULT_SPECIFIED_DAY_TIMES,
  WORK_SCHEDULE_TYPE
} from '../../models/setting'
import {
  getAttr,
  isArrayEmpty,
  json2str,
  str2json
} from '../../utilities/helpers'
import { useMutation } from '@apollo/client'

const prepareSpecifiedDayTimes = (days: any) => {
  if (days === '') {
    return DEFAULT_SPECIFIED_DAY_TIMES
  }
  // FIXME: handle other values

  return days.sort((a: any, b: any) => a.order - b.order)
}

export const parseFormUpdate = (tenant: any) => {
  const { subTenantCodes, additionalInfo: attributes } = tenant
  const workScheduleType = getAttr(attributes, 'workScheduleType')
  let fixedDayTimes: any = []
  if (workScheduleType === WORK_SCHEDULE_TYPE.fixedDay) {
    fixedDayTimes = str2json(getAttr(attributes, 'fixedDayTimes'))
  }

  let _specifiedDayTimes: any = DEFAULT_SPECIFIED_DAY_TIMES
  if (workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
    _specifiedDayTimes = prepareSpecifiedDayTimes(
      str2json(getAttr(attributes, 'specifiedDayTimes'))
    )

    const specifiedBiweekly: any = {}
    str2json(getAttr(attributes, 'specifiedBiweekly')).map((day: any) => {
      const wd = !isArrayEmpty(Object.keys(day)) ? Object.keys(day)[0] : ''
      specifiedBiweekly[wd] = day[wd].map((time: any) => {
        const { t, w, s, e } = time
        return { typeDay: t, week: w, start: s, end: e }
      })
    })

    _specifiedDayTimes.map((day: any) => {
      const { workday } = day
      if (specifiedBiweekly[workday]) {
        day.biweekly = specifiedBiweekly[workday]
      }

      return day
    })
  }

  const subTenantList = subTenantCodes.map((sub: any) => {
    const { code: key, phoneNumbers, notes } = sub
    let phoneNumber = ''
    let note = ''
    if (phoneNumbers && !isArrayEmpty(phoneNumbers)) {
      phoneNumber = phoneNumbers[0]['number'] ?? ''
    }
    if (notes && !isArrayEmpty(notes)) {
      note = notes[0].content ?? ''
    }

    return { ...sub, phoneNumber, note, key }
  })

  const tenantForm: any = {
    subTenantList: subTenantList,
    name: getAttr(attributes, 'name'),
    kanaName: getAttr(attributes, 'kanaName'),
    clinicName: getAttr(attributes, 'clinicName'),
    clinicKanaName: getAttr(attributes, 'clinicKanaName'),
    country: getAttr(attributes, 'country', null) ?? COUNTRY.JAPAN,
    zipCode: getAttr(attributes, 'zipCode'),
    province: getAttr(attributes, 'province', null),
    district: getAttr(attributes, 'district'),
    address: getAttr(attributes, 'address'),
    apartment: getAttr(attributes, 'apartment'),
    stateName: getAttr(attributes, 'stateName'),
    workScheduleType: workScheduleType,
    workdays: getAttr(attributes, 'workdays').split(','),
    fixedDayStartTime: fixedDayTimes?.start ?? '',
    fixedDayEndTime: fixedDayTimes?.end ?? '',
    specifiedDayTimes: _specifiedDayTimes
  }

  return tenantForm
}

export const parseDataUpdate = (tenant: any, data: any) => {
  const { refId, code, name, notes, phoneNumbers } = tenant
  const clonedData = structuredClone(data)
  const {
    workScheduleType,
    fixedDayStartTime,
    fixedDayEndTime,
    specifiedDayTimes: _specifiedDayTimes,
    subTenantList
  } = clonedData

  let fixedDayTimes: any = []
  if (workScheduleType === WORK_SCHEDULE_TYPE.fixedDay) {
    fixedDayTimes = {
      start: fixedDayStartTime,
      end: fixedDayEndTime
    }
  }

  let specifiedBiweekly: any = []
  let specifiedDayTimes: any = []
  if (workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
    specifiedBiweekly = [..._specifiedDayTimes].map((day: any) => {
      const { workday, biweekly } = day
      if (biweekly && !isArrayEmpty(biweekly)) {
        const bw: any = {}
        bw[workday] = biweekly.map((wk: any) => {
          const { typeDay: t, week: w, start: s, end: e } = wk
          return { t, w, s, e }
        })
        return bw
      }
      return null
    })

    specifiedDayTimes = _specifiedDayTimes.map((day: any) => {
      delete day.id
      delete day.biweekly
      return day
    })
  }

  const attributes: any = {
    name: data.name,
    kanaName: data.kanaName,
    clinicName: data.clinicName,
    clinicKanaName: data.clinicKanaName,
    country: data.country,
    zipCode: data.zipCode,
    province: data.province,
    district: data.district,
    address: data.address,
    apartment: data.apartment,
    stateName: data.stateName,
    workScheduleType: workScheduleType,
    workdays: data.workdays.join(','),
    fixedDayTimes: json2str(fixedDayTimes),
    specifiedBiweekly: json2str(specifiedBiweekly.filter((d: any) => d)),
    specifiedDayTimes: json2str(specifiedDayTimes)
  }

  const _subTenantList = subTenantList.map((s: any) => {
    const { phoneNumbers, phoneNumber, notes, note } = s
    let _phoneNumbers: any[] = []
    let _notes: any[] = []
    if (phoneNumbers && !isArrayEmpty(phoneNumbers)) {
      _phoneNumbers = [{ ...phoneNumbers[0], number: phoneNumber }]
    }
    if (notes && !isArrayEmpty(notes)) {
      _notes = [{ ...notes[0], content: note }]
    }

    delete s.phoneNumber
    delete s.note
    delete s.key
    delete s.id
    return { ...s, phoneNumbers: _phoneNumbers, notes: _notes }
  })

  return {
    refId,
    code,
    name,
    notes,
    phoneNumbers,
    additionalInfo: { ...tenant.additionalInfo, ...attributes },
    subTenantCodes: _subTenantList
  }
}

export const useTenantUpdate = () => {
  const [updateMutation] = useMutation(UPDATE_TENANT, {
    variables: { request: [] }
  })

  const handleUpdate = async (tenant: any) => {
    try {
      const response: any = await updateMutation({
        variables: { request: [tenant] }
      })
      console.log('[handleUpdate] response', response)
      const {
        data: {
          updateTenant: { status, payload }
        }
      } = response

      if (status === STATUS_CODE_SUCCESS) {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    handleUpdate
  }
}
