import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Flex, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'
import { array } from 'yup'

import { RESERVATION_MANAGEMENT_TAB } from '../ReservationManagement'
import styles from './scss/TabbleWrapper.module.scss'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { formatDate } from 'date-fns'
import useReservationManagement from 'hooks/useReservationManagement'
import { RECORD_COUNT } from 'models/customer'
import {
  RESERVATION_STATUS,
  ReservationConditions,
  ReservationData
} from 'models/reservationManagement'

const customerDetailTab = '0'

type Props = {
  setTotalRecords: (data: number) => void
}

type Column = {
  refId?: string
  courseId: string
  courseName: string
  courseSelect?: string
  date: string
  time: string
  status?: RESERVATION_STATUS
  remainedSlots?: number
  userId?: string
  userFullName?: string
  userPhone?: string
  key: string
  isConfirmed?: boolean
  reservationDateTime: string
  isPast?: boolean
}

const ReservationList: FC<Props> = memo(({ setTotalRecords }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const { loading, onFilterReservations } = useReservationManagement()

  const queryParams = new URLSearchParams(search)
  const {
    search: searchParam,
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam,
    status: statusParam,
    orderBy: orderParam,
    sortBy: sortParam = 'asc',
    page: pageParam = 1,
    size: sizeParam = RECORD_COUNT.OneHundred,
    display: displayParam = 'OFF',
    tab: tabParam = RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST
  } = Object.fromEntries(queryParams)

  const [initialData, setInitialData] = useState<ReservationData[] | null>(null)
  const [userIds, setUserIds] = useState<string[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const fetchReservationData = useCallback(
    async (conditions: ReservationConditions) => {
      try {
        const result = await onFilterReservations(conditions)
        if (result.data) {
          setInitialData(result.data)
          setTotalRecords(result.totalRecords)

          const allIds = result.data.map((item) => item.userId)
          const validIds = allIds.filter((id): id is string => id != null)
          const uniqueIds = Array.from(new Set(validIds))

          setUserIds(uniqueIds)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [onFilterReservations]
  )

  useEffect(() => {
    const createFilter = () => {
      const filter: {
        idOrFullNameOrPhone?: string | undefined
        date?: { startDate: string; endDate: string } | undefined
        status?: string | undefined
        courseId?: string[] | undefined
        display?: string | undefined
      } = {
        ...(searchParam && { idOrFullNameOrPhone: searchParam }),
        ...(courseParam && { courseId: courseParam.split(',') }),
        ...(startDateParam &&
          endDateParam && {
            date: { startDate: startDateParam, endDate: endDateParam }
          }),
        ...(displayParam && { display: displayParam })
      }

      if (displayParam === 'ON') {
        filter.status = statusParam || RESERVATION_STATUS.RESERVED
      } else if (statusParam) {
        filter.status = statusParam
      }

      return filter
    }

    const determineOrderAndSort = <T extends string>(
      validOrderKeys: readonly T[]
    ) => {
      const order =
        orderParam && validOrderKeys.includes(orderParam as T)
          ? (orderParam as T)
          : undefined
      const sort: 'asc' | 'desc' | undefined =
        sortParam === 'asc' || sortParam === 'desc' ? sortParam : undefined
      return { order, sort }
    }

    const pagination = {
      page: +pageParam,
      size: +sizeParam
    }

    const { order, sort } = determineOrderAndSort<keyof ReservationData>([
      'userId',
      'date',
      'status',
      'userFullName',
      'courseName',
      'courseSelect'
    ])

    const conditions: ReservationConditions = {
      filter: createFilter(),
      pagination,
      order,
      sort
    }

    setSelectedRowKeys([])
    if (tabParam === RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST) {
      fetchReservationData(conditions).finally()
    }
  }, [search])

  const handleNavigateUserDetailPage = (id: string | undefined) => {
    if (!id) return
    const index = userIds.indexOf(id)

    navigate(
      {
        pathname: '/customer-detail',
        search: createSearchParams({
          id,
          currentTab: customerDetailTab
        }).toString()
      },
      {
        state: {
          location: { ids: userIds, index }
        }
      }
    )
  }

  const handleNavigateRegisterPage = (record: ReservationData) => {
    navigate('/reservation-register', {
      state: {
        courseId: record.courseId,
        courseName: record.courseName,
        date: record.date.split('T')[0],
        time: record.time.slice(0, 5),
        remainedSlots: record.remainedSlots,
        courseSetting: record.courseSetting
      }
    })
  }

  const handleSort = (order: string) => {
    navigate(`${pathname}?${queryParams.toString()}`)

    queryParams.set('orderBy', order.toString())
    queryParams.set('sortBy', sortParam === 'asc' ? 'desc' : 'asc')

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  const SortableColumnTitle = ({
    title,
    sortKey
  }: {
    title: string
    sortKey: string
  }) => (
    <Flex justify="center" align="center">
      <Typography className="mr-4 font-normal">{t(title)}</Typography>

      <ArrowTopBottom
        className="cursor-pointer"
        onClick={() => handleSort(sortKey)}
      />
    </Flex>
  )

  const columns: TableColumnsType<Column> = useMemo(
    () => [
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.reservationDateTime"
            sortKey="date"
          />
        ),
        dataIndex: 'reservationDateTime',
        width: '11%',
        render: (value, record) =>
          value ? (
            <Typography
              className={`whitespace-pre px-3 ${(record.refId && !record.isConfirmed) || record.isPast ? 'opacity-20' : ''}`}
            >
              {value}
            </Typography>
          ) : (
            <Typography className="px-3 opacity-20">ｰ</Typography>
          )
      },
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.ID"
            sortKey="userId"
          />
        ),
        dataIndex: 'userId',
        width: '18%',
        render: (value, record) =>
          record.refId ? (
            <Typography
              className={`h-[22px] whitespace-nowrap px-3 ${!record.isConfirmed || record.isPast ? 'opacity-20' : ''}`}
            >
              {value}
            </Typography>
          ) : (
            <Typography
              className={`h-[22px] px-3 ${!record.isConfirmed || record.isPast ? 'opacity-20' : ''}`}
            >
              ー
            </Typography>
          )
      },
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.fullName"
            sortKey="userFullName"
          />
        ),
        dataIndex: 'userFullName',
        width: '22%',
        render: (value, record) =>
          record?.refId && value ? (
            <a className="underline">
              <Typography
                onClick={() => {
                  handleNavigateUserDetailPage(record?.userId)
                }}
                className={`px-3 font-bold text-[#137695] ${!record.isConfirmed || record.isPast ? 'opacity-20' : ''}`}
              >
                {value}
              </Typography>
            </a>
          ) : (
            <button
              disabled={record.isPast}
              type="button"
              // className={`bg-transparent border-none px-3 font-bold underline ${
              //   record.isPast
              //     ? 'text-[#BDCBD5] cursor-not-allowed'
              //     : 'text-[#137695] cursor-pointer'
              // }`}
              onClick={() => {
                if (!record.isPast) handleNavigateRegisterPage(record)
              }}
            >
              <Typography
                className={`bg-transparent border-none px-3 font-bold underline ${
                  record.isPast
                    ? 'text-[#BDCBD5] cursor-not-allowed'
                    : 'text-[#137695] cursor-pointer'
                }`}
              >
                {record.remainedSlots}
                {t('reservationManagement.content.empty')}
              </Typography>
            </button>
          )
      },
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.courseName"
            sortKey="courseName"
          />
        ),
        dataIndex: 'courseName',
        width: '20%',
        render: (value, record) =>
          value ? (
            <Typography
              className={`px-3 ${(record.refId && !record.isConfirmed) || record.isPast ? 'opacity-20' : ''}`}
            >
              {value}
            </Typography>
          ) : (
            <Typography className="px-3">ー</Typography>
          )
      },
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.courseSelect"
            sortKey="courseSelect"
          />
        ),
        dataIndex: 'courseSelect',
        width: '20%',
        render: (value, record) =>
          value ? (
            <Typography
              className={`px-3 ${(record.refId && !record.isConfirmed) || record.isPast ? 'opacity-20' : ''}`}
            >
              {value}
            </Typography>
          ) : (
            <Typography
              className={`px-3 ${(record.refId && !record.isConfirmed) || record.isPast ? 'opacity-20' : ''}`}
            >
              ー
            </Typography>
          )
      },
      {
        title: (
          <SortableColumnTitle
            title="reservationManagement.content.reservationStatus"
            sortKey="status"
          />
        ),
        dataIndex: 'status',
        width: '11%',
        render: (value, record) => (
          <Flex justify="center" align="center" className="h-[26px]">
            {record.refId ? (
              value === RESERVATION_STATUS.RESERVED ? (
                <Typography className="min-w-[103px] rounded-[13px] bg-[#137695] px-3 py-[2px] text-center text-sm font-bold text-white">
                  {t('reservationManagement.widget.status.booked')}
                </Typography>
              ) : (
                <Typography className="px-3 text-center text-sm">
                  {t('reservationManagement.widget.status.canceled')}
                </Typography>
              )
            ) : (
              <Typography className="min-w-[103px] rounded-[13px] bg-[#BDCBD5] px-3 py-[2px] text-center text-sm text-white">
                {t('reservationManagement.content.emptyDisplay')}
              </Typography>
            )}
          </Flex>
        )
      },
      {
        title: '',
        render: () => null
      }
    ],
    [t, initialData]
  )

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (keys: React.Key[]) => setSelectedRowKeys(keys)
    }),
    [selectedRowKeys]
  )

  const dataSource = useMemo(
    () =>
      initialData?.map((item, index) => {
        const dateTime = dayjs(item.date).format('YYYY-MM-DD')
        const isPast =
          !item.userId && dayjs(`${dateTime}T${item.time}`).isBefore(dayjs())

        return {
          ...item,
          key: `${RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST}-${index}`,
          isConfirmed: item.status === RESERVATION_STATUS.RESERVED,
          reservationDateTime: `${dayjs(item.date).format('YYYY/MM/DD')}   ${dayjs(item.time, 'HH:mm:ss').format('HH:mm')}`,
          isPast
        }
      }),
    [initialData]
  )

  const getRowStyle = (record: Column) => {
    if (record.refId) {
      return {}
    } else {
      return { backgroundColor: '#8FDCD426' }
    }
  }

  return (
    <Table
      loading={loading}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
        getCheckboxProps: (record) => ({
          style: {
            opacity:
              record.isPast || (record.userId && !record.isConfirmed)
                ? '0.3'
                : ''
          }
        })
      }}
      columns={columns}
      dataSource={dataSource}
      onRow={(record) => ({
        style: getRowStyle(record)
      })}
      pagination={false}
      scroll={{
        y: 'calc(100vh - 300px)',
        scrollToFirstRowOnChange: true
      }}
      className={`${styles.table_wrapper} mt-[-10px] py-4 pt-0`}
    />
  )
})

export default ReservationList
