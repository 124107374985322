import { DocumentNode, gql } from '@apollo/client'

export const FILTER_REMAINED_SLOTS_BY_DATE: DocumentNode = gql`
  query FilterRemainedSlotsByDate(
    $medicalCheckupMasterRefId: String!
    $daterange: DateRangeInput!
  ) {
    getRemainedAvailableSlotsByDateRange(
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
      daterange: $daterange
    ) {
      payload {
        byday
        bydate
        byhour
        emptySlotCount
        remainedSlotCount
      }
    }
  }
`
