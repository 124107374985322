import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'

import { FORMAT_DATE, SELECTION_TYPE } from '../../../../../configs/constant'
import { useReservationMessagesState } from '../../../../../hooks/chat/chatMessage'
import { useChatState } from '../../../../../hooks/chat/useChatHistory'
import {
  formatDateTimeJa as formatDate,
  sleep
} from '../../../../../utilities/helpers'
import { Flow, Option } from '../../../types/types'
import { scenarioCreateAtom } from '../../atoms/create/scenarioCreateAtom'

export const GenderMessage = () => {
  const { handleUserClick, setChatHistory } = useChatState()
  const { setGender } = useReservationMessagesState()
  const { flows } = useRecoilValue(scenarioCreateAtom)
  const currentFlowOrder: number = 0
  const currentFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder
  )
  const nextFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder + 1
  )

  const handleOnClick = async (message: string) => {
    setGender(message)
    handleUserClick({ message: message })

    await sleep(800)

    nextFlow &&
      setChatHistory((prev: any) => [
        ...prev,
        { sender: 'A', message: <CourseMessage /> }
      ])
  }

  return (
    <div className="flex gap-2 flex-col">
      <p>{currentFlow?.content || ''}</p>
      {currentFlow?.options?.map((option: Option) => (
        <Button
          key={option.displayName}
          onClick={() => handleOnClick(option.displayName ?? '')}
          className="h-[30px] w-full bg-[#137695] rounded text-center text-white"
        >
          {option.displayName ?? ''}
        </Button>
      ))}
    </div>
  )
}

export const CourseMessage = () => {
  const { handleUserClick, setChatHistory } = useChatState()
  const { setCourse } = useReservationMessagesState()

  const { flows } = useRecoilValue(scenarioCreateAtom)
  const currentFlowOrder: number = 1
  const currentFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder
  )
  const nextFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder + 1
  )

  const handleOnChange = async (event: any) => {
    handleUserClick({ message: event })
    setCourse(event)

    await sleep(700)

    nextFlow &&
      setChatHistory((prev: any) => [
        ...prev,
        { sender: 'A', message: <DatepickerMessage /> }
      ])
  }

  return (
    <div className="">
      <p className="pb-2">{currentFlow?.content ?? ''}</p>
      {currentFlow?.selectionType === SELECTION_TYPE.CHOICE && (
        <Select
          onChange={(e) => handleOnChange(e)}
          className="w-full"
          options={
            currentFlow?.options?.map((option: Option) => ({
              label: option.displayName,
              value: option.displayName
            })) ?? []
          }
        />
      )}
    </div>
  )
}

export const DatepickerMessage = () => {
  const { handleUserClick, setChatHistory } = useChatState()
  const { setDate } = useReservationMessagesState()

  const { flows } = useRecoilValue(scenarioCreateAtom)
  const currentFlow = flows.find((flow: Flow) => flow.order === 2)

  const handleOnChange = async (date: dayjs.Dayjs) => {
    if (!date) return
    const pickedDate = date.format('YYYY-MM-DDTHH:mm:ss')

    handleUserClick({ message: formatDate(pickedDate) })
    setDate(formatDate(pickedDate))

    await sleep(550)

    setChatHistory((prev: any) => [
      ...prev,
      { sender: 'A', message: <ConfirmRegisterMessage /> }
    ])
  }
  return (
    <div>
      <p className="pb-2">{currentFlow?.content ?? ''}</p>
      {currentFlow?.selectionType === SELECTION_TYPE.DATE && (
        <DatePicker
          onChange={(date, _dateString) => handleOnChange(date)}
          className="w-full"
          minDate={dayjs(new Date())}
          format={FORMAT_DATE.DATE_TIME}
          showTime
        />
      )}
    </div>
  )
}

export const ConfirmRegisterMessage = () => {
  const { t } = useTranslation()
  const { handleUserClick, setChatHistory } = useChatState()
  const { course, date } = useReservationMessagesState()
  const { flows } = useRecoilValue(scenarioCreateAtom)
  const currentFlowOrder: number = 3
  const currentFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder
  )
  const nextFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder + 1
  )

  const handleOnClick = async (message: string) => {
    handleUserClick({ message: message })

    await sleep(650)

    nextFlow &&
      setChatHistory((prev: any) => [
        ...prev,
        { sender: 'A', message: <SurveyFeedbackMessage /> }
      ])
  }

  return (
    <div className="space-y-1">
      <p>{currentFlow?.content ?? ''}</p>
      {currentFlow?.selectionType === SELECTION_TYPE.BUTTON_REGISTRATION && (
        <>
          <p>
            {t('lable.courseName')}：{course}
          </p>
          <p>
            {t('consultationReport.content.reservationDateTime')}：{date}
          </p>
          {currentFlow?.options?.map((option: Option) => (
            <Button
              key={option.displayName}
              onClick={() => handleOnClick(option.displayName ?? '')}
              className="h-[30px] w-full bg-[#137695] rounded text-center text-white"
            >
              {option.displayName ?? ''}
            </Button>
          ))}
        </>
      )}
    </div>
  )
}

export const SurveyFeedbackMessage = () => {
  const { handleUserClick, setChatHistory } = useChatState()

  const { flows } = useRecoilValue(scenarioCreateAtom)

  const currentFlowOrder: number = 4
  const currentFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder
  )
  const nextFlow = flows.find(
    (flow: Flow) => flow.order === currentFlowOrder + 1
  )

  const handleOnClick = async (message: string) => {
    await sleep(800)

    handleUserClick({ message: message })

    nextFlow &&
      setChatHistory((prev: any) => [
        ...prev,
        { sender: 'A', message: <p>{nextFlow?.content ?? ''}</p> }
      ])
  }

  return (
    <div className="space-y-1">
      <p>{currentFlow?.content ?? ''}</p>
      {currentFlow?.selectionType === SELECTION_TYPE.SURVEY_FEEDBACK && (
        <>
          <p>問診票：{currentFlow.setting?.surveyRefId ?? ''}</p>
          <Button
            onClick={() => {
              handleOnClick('問診回答した')
              // navigate({
              //   pathname: '/interview-result-create',
              //   search: createSearchParams({
              //     t: '2',
              //     userRefId: '',
              //     surveyFeedbackRefId: ''
              //   }).toString()
              // })
            }}
            className="h-[30px] w-full bg-[#137695] rounded text-center text-white"
          >
            問診回答
          </Button>
        </>
      )}
    </div>
  )
}
