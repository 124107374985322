import React, { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex, Select, Tabs, TabsProps, Typography } from 'antd'

import { RESERVATION_MANAGEMENT_TAB } from '../ReservationManagement'
import ReservationCollection from './ReservationCollection'
import ReservationList from './ReservationList'
import styles from './scss/TabWrapper.module.scss'
import { CaretDownOutlined } from '@ant-design/icons'
import { RECORD_COUNT } from 'models/customer'

type Props = {
  setTotalRecords: (data: number) => void
}

const defaultSize = RECORD_COUNT.OneHundred

const Content: FC<Props> = memo(({ setTotalRecords }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const {
    tab: tabParam = RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST,
    size: sizeParam = defaultSize
  } = Object.fromEntries(queryParams)

  const items: TabsProps['items'] = [
    {
      key: RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST,
      label: (
        <Typography className="min-w-[180px] text-center text-[14px]">
          {t('reservationManagement.content.reservationList')}
        </Typography>
      ),
      children: <ReservationList setTotalRecords={setTotalRecords} />
    },
    {
      key: RESERVATION_MANAGEMENT_TAB.RESERVATION_COLLECTION_LIST,
      label: (
        <Typography className="min-w-[180px] text-center text-[14px]">
          {t('reservationManagement.content.reservationCollectionList')}
        </Typography>
      ),
      children: <ReservationCollection setTotalRecords={setTotalRecords} />
    }
  ]

  const selectOptions = useMemo(
    () => [
      {
        value: RECORD_COUNT.Fifty,
        label: `${RECORD_COUNT.Fifty}${t('customerManagement.displayInfo.record')}`
      },
      {
        value: RECORD_COUNT.OneHundred,
        label: `${RECORD_COUNT.OneHundred}${t('customerManagement.displayInfo.record')}`
      },
      {
        value: RECORD_COUNT.TwoHundred,
        label: `${RECORD_COUNT.TwoHundred}${t('customerManagement.displayInfo.record')}`
      },
      {
        value: RECORD_COUNT.FiveHundred,
        label: `${RECORD_COUNT.FiveHundred}${t('customerManagement.displayInfo.record')}`
      }
    ],
    [t]
  )

  const handleChangeSize = (value: string | RECORD_COUNT) => {
    queryParams.set('page', '1')
    queryParams.set('size', value.toString())

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  const handleChangeTab = (activeKey: string) => {
    const newQueryParams = new URLSearchParams()

    newQueryParams.set('tab', activeKey)
    newQueryParams.set('page', '1')
    newQueryParams.set('size', defaultSize.toString())

    navigate(`${pathname}?${newQueryParams.toString()}`)
  }

  return (
    <div
      className={`mb-4 mt-3 rounded-[5px] bg-[#FFFFFF] px-[6px] drop-shadow-md`}
    >
      <Flex className="absolute right-[6px] top-2 z-10">
        <Flex align="center">
          <Typography className="text-xs">
            {t('customerManagement.displayInfo.resultDisplayed')}
          </Typography>

          <Select
            options={selectOptions}
            value={+sizeParam}
            onChange={(value) => handleChangeSize(value)}
            suffixIcon={
              <CaretDownOutlined
                style={{ color: '#137695', pointerEvents: 'none' }}
              />
            }
            className="ml-[7px] h-[30px] min-w-[80px]"
          />
        </Flex>
      </Flex>

      <Tabs
        className={styles.tab_wrapper}
        items={items}
        activeKey={tabParam}
        onChange={(activeKey) => handleChangeTab(activeKey)}
      />
    </div>
  )
})

export default Content
