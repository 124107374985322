import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Button, Empty, Flex, List, Popover, Typography } from 'antd'

import Item from '../dnd/Item'
import RenderItems from '../dnd/RenderItems'
import { QuestionCircleFilled } from '@ant-design/icons'
import { ReactComponent as TrashIcon } from 'assets/imgs/trash.svg'
import { ReactComponent as AddItemIcon } from 'assets/svgs/plus–42.svg'
import { LIST_DRAG_AND_DROP } from 'configs/constant'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import { uuid } from 'short-uuid'

export default function ContentCategoryHiddenList({
  categories,
  onChecked,
  handleOpenModalDelete,
  setIsModalRestoreConfirm,
  setIsShowAddCategoryItem,
  setIsShowAddTestItem
}: {
  categories: DataItemMaster[]
  onChecked: (
    item: DataItemMaster,
    checked: boolean,
    isCheckAll?: boolean
  ) => void
  handleOpenModalDelete: () => void
  setIsModalRestoreConfirm: React.Dispatch<React.SetStateAction<boolean>>
  setIsShowAddCategoryItem: React.Dispatch<React.SetStateAction<boolean>>
  setIsShowAddTestItem: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { t } = useTranslation()

  return (
    <div className="rounded-[10px] shadow-md bg-white pr-[11px] pt-2.5 pl-[13px] flex-1 pb-5 h-max category-item-list">
      <Flex align="center" justify="space-between">
        <Flex gap={8}>
          <Typography className="font-bold">
            {t('currentHiddenTest')}
          </Typography>

          <Popover
            placement="top"
            content={
              <div className="p-2 w-[330px] bg-[#545454] text-white rounded">
                {t('popover.hideByDragAndDrop')}
              </div>
            }
            overlayClassName="popover-permission"
          >
            <QuestionCircleFilled className="text-sm text-[#B4B4B4]" />
          </Popover>
        </Flex>
        <Flex align="center" gap={8}>
          <TrashIcon
            className="cursor-pointer w-4"
            onClick={handleOpenModalDelete}
          />
          <Button
            className="border border-[#137695] rounded-2xl text-[#137695] font-bold text-[12px]"
            onClick={() => setIsModalRestoreConfirm(true)}
          >
            {t('restoreDefaultSetting')}
          </Button>
        </Flex>
      </Flex>

      <List className="mt-6">
        <Droppable
          droppableId={`${LIST_DRAG_AND_DROP.LIST2}`}
          type={LIST_DRAG_AND_DROP.CATEGORY}
        >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories?.length ? (
                categories?.map((category, index: number) => (
                  <Draggable
                    key={uuid()}
                    draggableId={category.refId ?? uuid()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <div className="flex flex-col gap-3">
                          <List.Item className="border border-[#CDD6DD] bg-[#EFF3F6] min-h-[30px] !py-0">
                            <Item
                              item={category}
                              showIcon
                              allowCheckbox
                              onChecked={(itemChecked, checked) =>
                                onChecked(itemChecked, checked, true)
                              }
                            />
                          </List.Item>
                          <RenderItems
                            className={`gap-3 ${(category?.items ?? []).length > 0 ? 'mb-3' : ''}`}
                            items={category?.items ?? []}
                            parentRefId={category.refId}
                            position={LIST_DRAG_AND_DROP.LIST2}
                            showIcon
                            allowCheckbox
                            onChecked={(itemChecked, checked) =>
                              onChecked(itemChecked, checked)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </List>

      <div className="pt-3">
        <Flex
          className="gap-[5px] cursor-pointer w-max"
          onClick={() => setIsShowAddCategoryItem(true)}
        >
          <AddItemIcon className="bg-[#137695] h-[20px] w-[20px] rounded-sm" />
          <Typography className="text-[14px] text-[#137695] font-semibold">
            {t('newCategoryAdded')}
          </Typography>
        </Flex>

        <Flex
          className="gap-[5px] mt-[14px] cursor-pointer w-max"
          onClick={() => setIsShowAddTestItem(true)}
        >
          <AddItemIcon className="bg-[#137695] h-[20px] w-[20px] rounded-sm" />
          <Typography className="text-[14px] text-[#137695] font-semibold">
            {t('newTestItemsAdded')}
          </Typography>
        </Flex>
      </div>
    </div>
  )
}
