import { memo, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Input } from 'antd'

import { ReactComponent as SearchIcon } from 'assets/svgs/SearchOutlined.svg'
import useMessageList from 'hooks/chat/messageList'

function Search() {
  const { t } = useTranslation()

  const { methods, setFormValue } = useMessageList()

  const { watch, setValue } = useFormContext()
  const fullName = watch('fullName')

  useEffect(() => {
    if (fullName) {
      setFormValue('fullName', fullName)
    }
  }, [fullName])

  return (
    <Flex className="relative">
      <Controller
        name="fullName"
        control={methods.control}
        rules={{
          maxLength: 50
        }}
        render={({ field }) => {
          return (
            <Input
              {...field}
              value={fullName}
              onChange={(e) => {
                setFormValue('fullName', e.target.value)
                setValue('fullName', e.target.value)
              }}
              placeholder={t('chat.search.placeholder')}
              className="h-[30px] w-[250px]"
              prefix={<SearchIcon className="h-4 w-4" />}
            />
          )
        }}
      />
    </Flex>
  )
}

export default memo(Search)
