import { Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'

import { renderLoadingSpinner } from './ChatContainer'
import ChatMessage from './ChatMessage'
import DateDivider from './DateDivider'
import { loadMoreMessagesAtom } from 'hooks/chat/atoms'
import { useChatHistory } from 'hooks/chat/chatMessage'
import useMessageList from 'hooks/chat/messageList'
import { groupMessagesByDate } from 'utilities/helpers'

const MessageList = ({
  groupedMessages,
  typingMessage,
  isDivider = true
}: {
  groupedMessages: ReturnType<typeof groupMessagesByDate>
  typingMessage?: {
    userName: string
    isTyping: boolean
  }
  isDivider?: boolean
}) => {
  const loadMoreMessagesState = useRecoilValue(loadMoreMessagesAtom)
  const { handleLoadMoreMessages } = useMessageList()
  const { fetchMessagesInRoom } = useChatHistory()

  return (
    <div id="scrollableDiv" className="overflow-y-auto h-full">
      <InfiniteScroll
        dataLength={Object.values(groupedMessages).reduce(
          (total, msgs) => total + msgs.length,
          0
        )}
        next={() => handleLoadMoreMessages(fetchMessagesInRoom)}
        hasMore={!loadMoreMessagesState.isLoadedFull}
        loader={renderLoadingSpinner(loadMoreMessagesState, true)}
        inverse={true}
        className="flex flex-col-reverse !overflow-x-hidden"
        scrollableTarget="scrollableDiv"
      >
        {Object.entries(groupedMessages).map(([date, msgs]) => {
          const isToday = dayjs(date).isSame(dayjs(), 'day')

          return (
            <Fragment key={date}>
              {msgs.map((msg, index) => {
                const timestamp = new Date(+msg.timestamp)

                return (
                  <ChatMessage
                    uId={msg.uId}
                    imgId={msg.imgId}
                    typeMessage={msg.typeMessage}
                    key={`${date}-${index}`}
                    message={
                      msg.isSent ? msg?.attachments?.[0]?.title : msg.message
                    }
                    isSent={msg.isSent}
                    timestamp={
                      isToday
                        ? dayjs(timestamp).format('HH:mm')
                        : dayjs(timestamp).format('YYYY/MM/DD')
                    }
                    sender={msg.sender}
                    attachments={msg.attachments}
                    isRead={msg.isRead}
                  />
                )
              })}

              {isDivider && (
                <DateDivider date={dayjs(date).format('YYYY/MM/DD')} />
              )}
            </Fragment>
          )
        })}
      </InfiniteScroll>

      {typingMessage?.isTyping && <ChatMessage isTyping typeMessage="text" />}
    </div>
  )
}
export default MessageList
