import React, { useEffect, useState } from 'react'

import { List } from 'antd'

import { JUDGMENT } from '../SetupThresholdAndCriteria'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { CategoryWithMultipleLang } from 'types/Categories'

type Props = {
  category: CategoryWithMultipleLang[]
  language: string
}

export default function MenuCategoryList({ category, language }: Props) {
  const [activeIndex, setActiveIndex] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (category) {
      setActiveIndex(category?.[0]?.refId)
    }
  }, [category])

  const handleClickCategory = (refId: string) => {
    if (!refId) return

    setActiveIndex(refId)
    const targetElement = document.getElementById(JUDGMENT + refId)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div className="rounded-tl-[10px] shadow-md bg-[#DCE3EC] pl-3 pt-4 w-full h-full">
      <List itemLayout="vertical">
        {category?.map((item: CategoryWithMultipleLang) => (
          <List.Item
            className={`!px-3 !py-2 rounded-l-md cursor-pointer font-bold tracking-widest !border-none  ${
              activeIndex === item.refId
                ? '!bg-primary !text-white'
                : '!text-primary'
            }`}
            onClick={(e) => {
              e.preventDefault()
              handleClickCategory(item.refId)
            }}
          >
            {item.lang?.[language] ?? VALUE_WITH_NO_DATA}
          </List.Item>
        ))}
      </List>
    </div>
  )
}
