import React from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, List } from 'react-virtualized'

import { Button, Checkbox, Empty, Flex, Input, Space, Typography } from 'antd'

import { SearchOutlined } from '@ant-design/icons'
import ModalConfirm from 'components/modals/ModalConfirm'
import debounce from 'lodash.debounce'
import { ELanguage, showName } from 'pages/InspectionItemSetting/helper'
import { Category } from 'types/FilterTestItemMaster'
import cn from 'utilities/cn'
import { getLanguage } from 'utilities/helpers'

const ModalRenderList = ({
  originalItems = [],
  initialLeftItems = [],
  initialRightItems = [],
  isModalVisible,
  handleCancel,
  handleOK
}: {
  originalItems: Category[]
  initialLeftItems: string[]
  initialRightItems: string[]
  isModalVisible: boolean
  handleCancel?: () => void
  handleOK?: (values: Category[]) => void
}) => {
  const { t } = useTranslation()
  const language = getLanguage()
  const refData = React.useRef<Category[]>([])
  const [data, setData] = React.useState<Category[]>([])
  const [isSearching, setIsSearching] = React.useState(false)

  React.useEffect(() => {
    if (!originalItems.length) {
      setData([])
      refData.current = []
      return
    }
    const filterList = originalItems
      .map((item) => ({
        ...item,
        isChecked: initialRightItems.some((refId) => refId === item.refId)
      }))
      .filter((item) => !initialLeftItems.includes(item.refId))
    setData(filterList)
    refData.current = filterList
  }, [initialLeftItems, initialRightItems, originalItems])

  const handleSendData = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      const selectedItems = data.filter((item) => item.isChecked)
      handleOK && handleOK(selectedItems)
      handleCancel?.()
    },
    [data, handleCancel, handleOK]
  )

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setIsSearching(!!searchValue)
    if (!searchValue) return setData(refData.current)
    const filteredData = [...refData.current].filter((item) => {
      return (
        item.lang?.[ELanguage.VI]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item?.additionalInfo?.localizedName?.[ELanguage.VI]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item.lang?.[ELanguage.EN]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item?.additionalInfo?.localizedName?.[ELanguage.EN]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item.lang?.[ELanguage.JA]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item?.additionalInfo?.localizedName?.[ELanguage.JA]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
      )
    })
    setData(filteredData)
  }, 500) // Debounce for 500ms

  const rowRenderer = ({
    key,
    index,
    style
  }: {
    key: React.Key
    index: number
    style?: React.CSSProperties
  }) => {
    return (
      <Flex key={key} style={style} align="center">
        <Checkbox
          checked={data[index].isChecked}
          onChange={(e) => {
            const checked = e.target.checked
            setData((prev) =>
              prev.map((d, i) =>
                i === index ? { ...d, isChecked: checked } : d
              )
            )
          }}
        >
          <Flex>
            <Typography>{`${showName(data[index], language)} `}</Typography>
          </Flex>
        </Checkbox>
      </Flex>
    )
  }

  const ContentList = (
    <div>
      {data?.length === 0 && !isSearching ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div className="px-6 py-5">
          <Flex justify="end" align="center">
            <Input onChange={handleSearch} prefix={<SearchOutlined />} />
          </Flex>
          <div
            className={cn({
              'justify-center items-center': data.length === 0
            })}
          >
            <Space direction="vertical" size="middle" className="!w-full">
              {data.length > 0 ? (
                <div className="m-auto">
                  <AutoSizer className="max-h-[630px] min-h-[400px] !w-full border border-[#E8E8E8] p-4 ">
                    {({ height, width }: { height: number; width: number }) => {
                      return (
                        <List
                          height={height - 32}
                          rowHeight={32}
                          rowRenderer={rowRenderer}
                          rowCount={data.length}
                          width={width - 32}
                        />
                      )
                    }}
                  </AutoSizer>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Space>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <ModalConfirm
      isModalVisible={isModalVisible}
      handleCancel={handleSendData}
      icons={<></>}
      title={t('inspectionCategoryAddition')}
      footer={
        <Flex align="center" justify="center">
          <Button
            htmlType="button"
            onClick={handleSendData}
            variant="outlined"
            color="primary"
            className="px-16 font-bold"
          >
            {t('button.close')}
          </Button>
        </Flex>
      }
      classNameContent="!w-[900px] rounded-lg"
      content={ContentList}
    />
  )
}

export default ModalRenderList
