import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { ReactComponent as HiddenIcon } from 'assets/svgs/hiddenItem.svg'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import ScreenLoader from 'components/loading/ScreenLoader'
import { ItemOption } from 'types/courseRegister'

export default function ApplicableOptionalExamination({
  setHidden,
  setEdit,
  itemsOptions,
  language,
  loading
}: {
  setHidden: () => void
  setEdit: () => void
  itemsOptions: ItemOption[]
  language: string
  loading: boolean
}) {
  const { t } = useTranslation()

  return (
    <div className="mt-3 bg-white  pt-1.5 pb-4 pr-1 pl-2 rounded-md relative">
      {loading && <ScreenLoader />}
      <Flex className="font-bold items-center">
        <Typography className="w-[26%] pl-2.5">
          {t('lable.inspectionItems')}
        </Typography>
        <Typography className="w-[49.2%]">{t('lable.testCD')}</Typography>

        <Flex className="flex-1 justify-between items-center">
          <Typography>{t('lable.classification')}</Typography>
          <Flex className="items-center gap-3">
            <PenEditIcon
              className="w-[15px] cursor-pointer"
              onClick={() => setEdit()}
            />
            <HiddenIcon
              className="w-[30px] cursor-pointer"
              onClick={() => setHidden()}
            />
          </Flex>
        </Flex>
      </Flex>
      <div className="border-t border-[#707070] mt-1.5"></div>
      <div className="h-[210px] overflow-y-scroll">
        <Flex vertical>
          {itemsOptions.map((categoryItem: ItemOption) => (
            <div key={categoryItem.refId}>
              <Flex className="items-center min-h-[30px] py-1">
                <Typography className={`w-[27%] px-2.5`}>
                  {categoryItem.lang?.[language]}
                </Typography>
                <Typography className="w-[50%] text-xs text-[#BDCBD5] tracking-[.6px] pr-2">
                  {categoryItem?.code}
                </Typography>
                <Typography className="flex-1 tracking-[1.4px] pr-2">
                  {categoryItem?.classification}
                </Typography>
              </Flex>
              <div className="border-t border-[#BDCBD5] ml-4"></div>
            </div>
          ))}
        </Flex>
      </div>
    </div>
  )
}
