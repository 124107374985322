import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Select, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'

import SortableColumnTitle from './SortableColumnTitle'
import styles from './scss/TabbleWrapper.module.scss'
import { CaretDownOutlined } from '@ant-design/icons'
import { PATHS } from 'configs/constant'
import useConsultationReport from 'hooks/useConsultationReport'
import { YearlyReport } from 'models/consultationReport'

type Props = {
  onRowSelectionChange: (selectedRows: YearlyReport[]) => void
}

const Content: FC<Props> = memo(({ onRowSelectionChange }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const { getValues, setValue } = useForm()

  const { loading, onFilterYear, onFilterConsultationReport } =
    useConsultationReport()

  const queryParams = new URLSearchParams(search)
  const {
    year: yearParam = dayjs().year(),
    sortBy: sortParam = 'month',
    orderBy: orderParam = 'desc'
  } = Object.fromEntries(queryParams)

  const [selectOptions, setSelectOptions] = useState<
    { label: number; value: number }[]
  >([])
  const [initialData, setInitialData] = useState<YearlyReport[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [tenantName, setTenantName] = useState<string>('')

  const fetchData = useCallback(
    async (year: number) => {
      const data = await onFilterConsultationReport({
        year,
        sortBy: `(${orderParam},${sortParam})`
      })

      if (data) {
        setInitialData(data.data)
      }
    },
    [onFilterConsultationReport]
  )

  useEffect(() => {
    const fetchData = async () => {
      const data = await onFilterYear()
      setSelectOptions(data.yearOptions)
      setTenantName(data.tenantName)
    }

    fetchData().finally()
  }, [])

  useEffect(() => {
    setSelectedRowKeys([])
    onRowSelectionChange([])
    fetchData(+yearParam).finally()
  }, [yearParam, sortParam, orderParam])

  const handleSelect = useCallback(
    (param: string, value: number) => {
      queryParams.set(param, value.toString())

      navigate(`${pathname}?${queryParams.toString()}`)
    },
    [search]
  )

  const handleNavigate = (month: number) => {
    navigate({
      pathname: PATHS.MONTHLY_CONSULTATION_REPORT,
      search: createSearchParams({
        year: yearParam.toString(),
        month: month.toString()
      }).toString()
    })
  }

  const handleSelectYear = () => {
    const selectedYear = getValues('year')

    if (selectedYear && !isNaN(selectedYear)) {
      if (selectedYear !== +yearParam) {
        handleSelect('year', selectedYear)
      }
    }
  }

  const columns: TableColumnsType<YearlyReport> = useMemo(() => {
    return [
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.year"
            sortKey="year"
          />
        ),
        dataIndex: 'year',
        width: '10%',
        render: (value) => (
          <Flex justify="center" className="px-3">
            <Typography className="min-w-[30%]">{value}</Typography>
          </Flex>
        )
      },
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.month"
            sortKey="month"
          />
        ),
        dataIndex: 'month',
        width: '12%',
        render: (value) => (
          <Flex justify="center" className="px-3">
            <Typography className="min-w-[15%]">{value}</Typography>
            <Typography>{t('consultationReport.content.month')}</Typography>
          </Flex>
        )
      },
      {
        title: (
          <SortableColumnTitle
            title="consultationReport.content.quantity"
            sortKey="amount"
          />
        ),
        dataIndex: 'quantity',
        width: '15%',
        render: (value, record) => (
          <Flex justify="center" className="px-3">
            <button
              type="button"
              className="text-sm font-bold text-[#137695] underline"
              onClick={() => handleNavigate(record.month)}
            >
              {value}
            </button>
          </Flex>
        )
      },
      // {
      //   title: (
      //     <SortableColumnTitle
      //       title="consultationReport.content.totalAmount"
      //       sortKey="price"
      //     />
      //   ),
      //   dataIndex: 'totalAmount',
      //   width: '15%',
      //   render: (value) => (
      //     <Flex justify="center" className="px-3">
      //       <Typography className="min-w-[32%]">
      //         {value.toLocaleString()}
      //       </Typography>
      //     </Flex>
      //   )
      // },
      { title: null, render: () => null }
    ]
  }, [t])

  const dataSource = useMemo(
    () =>
      initialData.map((item, index) => {
        return {
          ...item,
          key: index,
          tenantName
        }
      }),
    [initialData]
  )

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: YearlyReport[]) => {
      setSelectedRowKeys(selectedRowKeys)
      onRowSelectionChange(selectedRows)
    }
  }

  return (
    <div className="mt-4 rounded-[5px] bg-[#FFFFFF] p-[10px] drop-shadow-md">
      <Flex align="center" className="border-[#D9E0E5] py-4">
        <Typography.Text className="mr-2 text-sm">
          {t('consultationReport.content.year')}
        </Typography.Text>

        <Select
          key={`${yearParam}-year`}
          options={selectOptions}
          defaultValue={+yearParam || undefined}
          onChange={(value: number) => {
            setValue('year', value)
          }}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
          className="h-[30px] w-[200px]"
        />

        <Button
          type="primary"
          htmlType="submit"
          autoInsertSpace={false}
          onClick={handleSelectYear}
          className="ml-2 h-[30px] min-w-[84px] rounded-none border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
        >
          {t('consultationReport.button.show')}
        </Button>
      </Flex>

      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          y: 'calc(100vh - 350px)',
          scrollToFirstRowOnChange: true
        }}
        className={`${styles.table_wrapper} overflow-hidden py-4 pt-0`}
      />
    </div>
  )
})

export default Content
