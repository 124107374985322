import { v4 as uuidv4 } from 'uuid'

import { KEYS_TEMP, MESSAGE_LIMIT } from '../configs/constant'
import {
  API_GET_MESSAGES_IN_ROOM,
  API_GET_MESSAGE_DETAIL,
  API_GET_USER_INFO,
  API_LOAD_HISTORY_MESSAGES,
  API_LOAD_NEXT_MESSAGES,
  API_LOAD_SURROUND_MESSAGES,
  API_POST_MESSAGE,
  API_READ_MESSAGES,
  API_SEND_FILE_MESSAGE,
  API_SEND_MESSAGES
} from 'configs/api'
import rocketChatApi from 'services/rocketChatApi'
import rocketChatUserApi from 'services/rocketChatUserApi'

const chatMessageApi = {
  /**
   * Api getMessages
   *
   * @param {Object} params direct message list data
   */
  loadHistoryMessages({ id, roomId, date, hisDate }: any) {
    const params = {
      message: JSON.stringify({
        msg: 'method',
        id,
        method: 'loadHistory',
        params: [
          roomId,
          hisDate ? { $date: hisDate } : null,
          MESSAGE_LIMIT,
          { $date: date }
        ]
      })
    }
    return rocketChatApi.post(API_LOAD_HISTORY_MESSAGES, params)
  },
  loadNextMessages({ id, roomId, date }: any) {
    const params = {
      message: JSON.stringify({
        msg: 'method',
        id,
        method: 'loadNextMessages',
        params: [roomId, { $date: date }, MESSAGE_LIMIT]
      })
    }
    return rocketChatApi.post(API_LOAD_NEXT_MESSAGES, params)
  },
  loadSurroundingMessages({ id, message }: any) {
    const params = {
      message: JSON.stringify({
        msg: 'method',
        id,
        method: 'loadSurroundingMessages',
        params: [
          {
            customClass: 'search',
            actionContext: 'search',
            valid: true,
            ...message
          },
          50
        ]
      })
    }
    return rocketChatApi.post(API_LOAD_SURROUND_MESSAGES, params)
  },
  sendDirectMessages({ id, roomId, message }: any) {
    const params = {
      message: JSON.stringify({
        msg: 'method',
        id,
        method: 'sendMessage',
        params: [{ rid: roomId, msg: message }]
      })
    }
    return rocketChatApi.post(API_SEND_MESSAGES, params)
  },
  sendMessage({ roomId, message, attachments }: any) {
    const params = {
      roomId: roomId,
      text: message,
      attachments
    }
    return rocketChatApi.post(API_POST_MESSAGE, params)
  },
  userSendDirectMessages({ id, roomId, message }: any) {
    const params = {
      message: JSON.stringify({
        msg: 'method',
        id,
        method: 'sendMessage',
        params: [{ rid: roomId, msg: message }]
      })
    }
    return rocketChatUserApi.post(API_SEND_MESSAGES, params)
  },
  getDetailMessage(msgId: any) {
    const params = { msgId }
    return rocketChatApi.get(API_GET_MESSAGE_DETAIL, params)
  },
  readMessages({ roomId }: any) {
    const params = {
      message: JSON.stringify({
        msg: 'method',
        id: uuidv4(),
        method: 'readMessages',
        params: [roomId]
      })
    }

    return rocketChatApi.post(API_READ_MESSAGES, params)
  },
  getMessagesInRoom({ chatToken, chatUserId, data }: any) {
    const headers = {
      chatToken,
      chatUserId,
      'Content-Type': 'application/json'
    }
    return rocketChatUserApi.post(API_GET_MESSAGES_IN_ROOM, data, headers)
  },
  getUserInfoRocketChat(chatToken: string, chatUserId: string) {
    const headers = {
      chatToken,
      chatUserId
    }
    return rocketChatUserApi.get(API_GET_USER_INFO, {}, headers)
  },
  sendFileMessage({ roomId, formFileData }: any) {
    const headers = {
      chatToken: KEYS_TEMP.ADMIN_TOKEN,
      chatUserId: KEYS_TEMP.ADMIN_USER_ID
    }

    return rocketChatApi.post(
      `${API_SEND_FILE_MESSAGE}/${roomId}`,
      formFileData,
      headers
    )
  }
}

export default chatMessageApi
