import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

type Props = {
  onExportToCSV: () => void
}

const Widget: FC<Props> = memo(({ onExportToCSV }) => {
  const { t } = useTranslation()

  return (
    <Flex align="center" justify="space-between">
      <Typography className="text-base font-bold tracking-[1.6px]">
        {t('consultationReport.consultationReport')}
      </Typography>

      <Button
        type="primary"
        className="h-[30px] min-w-[90px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-center text-sm font-bold text-white shadow-none"
        onClick={onExportToCSV}
      >
        {t('consultationReport.button.exportCSV')}
      </Button>
    </Flex>
  )
})

export default Widget
