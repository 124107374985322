import { useState } from 'react'

import { useMedicalCheckupMaster } from './useMedicalCheckupMaster'
import {
  DATE_FORMAT_YMD_SLASH,
  META_DATA_MEDICAL_CHECKUP_MASTER,
  PRODUCT_LIST_TOTAL
} from 'configs/constant'
import { format } from 'date-fns'
import { MetaData, Payload } from 'types/MedicalCheckupMaster'
import { StringKeyObject } from 'types/common'
import { isNumber } from 'utilities/helpers'

export const handleMetaData = (metaData: MetaData[]) => {
  if (!Array.isArray(metaData)) return {}

  const metaDataObj = metaData.reduce(
    (acc: StringKeyObject, current: MetaData) => {
      if (current?.name) {
        acc[current.name] = current?.value
      }

      return acc
    },
    {}
  )

  return metaDataObj
}
const useProductMaster = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>('')
  const [data, setData] = useState<any>([])
  const [totalElement, setTotalElement] = useState<StringKeyObject>({})
  const { getMedicalCheckupMaster, getMedicalCheckupMasterCount } =
    useMedicalCheckupMaster()

  const handleGetData = async ({ isOption, ...search }: any) => {
    try {
      onClearError()
      setLoading(true)
      const variables = handleVariable(search, isOption)
      const dataFetch = await getMedicalCheckupMaster(variables, false)
      const data = dataFetch.data

      const total = dataFetch.totalElements

      if (isOption) {
        const getCountVariables = handleVariable(search, false)
        const totalCourses =
          await getMedicalCheckupMasterCount(getCountVariables)
        setTotalElement({
          [PRODUCT_LIST_TOTAL.COURSE]: totalCourses ?? 0,
          [PRODUCT_LIST_TOTAL.OPTION]: total ?? 0
        })
      } else {
        const getCountVariables = handleVariable(search, true)
        const totalOptions =
          await getMedicalCheckupMasterCount(getCountVariables)
        setTotalElement({
          [PRODUCT_LIST_TOTAL.COURSE]: total ?? 0,
          [PRODUCT_LIST_TOTAL.OPTION]: totalOptions ?? 0
        })
      }

      if (!Array.isArray(data)) setData([])
      const result = data.map((item: Payload) => {
        const metaData: MetaData[] =
          item?.medicalCheckupMaster?.additionalInfo?.metadata ?? []

        const registrationDate = item?.medicalCheckupMaster?.createdDate
          ? format(
              new Date(item.medicalCheckupMaster.createdDate),
              DATE_FORMAT_YMD_SLASH
            )
          : ''

        const metaDataObj = handleMetaData(metaData)

        return {
          id: item?.medicalCheckupMaster?.refId ?? '',
          name: item?.medicalCheckupMaster?.displayName ?? '',
          overview:
            metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW] ?? '',
          courseTimes:
            metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES] ?? '',
          buffer: metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER] ?? '',
          subscriber: item?.medicalCheckupMaster?.createdBy ?? '',
          registrationDate: registrationDate,
          lastUpdateDate: registrationDate
        }
      })

      setData(result)
    } catch (error) {
      setError((error as Error).message)
    }

    setLoading(false)
  }

  const handleVariable = (search: StringKeyObject, isOption?: boolean) => {
    let filter: any[] = [
      {
        field: 'data.additionalInfo.isDeleted',
        operator: 'ne',
        value: 'true'
      },
      {
        field: 'data.additionalInfo.key1',
        operator: `${isOption ? 'eq' : 'ne'}`,
        value: 'option'
      }
    ]
    let page = 1
    let size = 0
    let sortBy = {}

    if (search?.course) {
      filter.push({
        field: 'data.displayName',
        operator: 'like',
        value: `${search?.course}`
      })
    }

    if (isNumber(search?.page)) {
      page = search.page
    }

    if (isNumber(search?.perPage)) {
      size = search.perPage
    }

    if (search?.sortBy && search?.orderBy) {
      sortBy = {
        field: `${search.sortBy}`,
        direction: `${search.orderBy}`
      }
    }

    return {
      filter,
      page,
      size,
      sortBy
    }
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    error,
    data,
    totalElement,
    onClearError,
    handleGetData
  }
}

export default useProductMaster
