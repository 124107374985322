import { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Select } from 'antd'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'

import { UserInfo } from './DrawerSchedule'
import YearMonthSelector from './YearMonthSelector'
import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import { SelectMultipleCustom } from 'components/elements/Select'
import { set } from 'date-fns'
import useDrawerSchedule from 'hooks/useDrawerSchedule'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import usePatientDetail, {
  tableSearchSlotReservationAtom
} from 'hooks/usePatientDetail'
import {
  CourseResponse,
  CourseSelect,
  TableSearchSlotReservation
} from 'pages/CustomerDetail/types/types'
import {
  filterCourseResponseInValid,
  getNameCourseOrOptionByIds,
  preventEnterKeyDefault
} from 'utilities/helpers'

export interface SelectDataType {
  course: {
    list: CourseResponse[]
    filterList: CourseSelect[]
    courseId: string
    courseName: string
  }
  option: {
    list: CourseResponse[]
    filterList: CourseSelect[]
    optionIds: string[]
    optionName: string
  }
}

const SearchContainer = ({
  courseSearch,
  optionSearch,
  userInfo
}: {
  courseSearch: CourseSelect[]
  optionSearch: CourseSelect[]
  userInfo: UserInfo
}) => {
  const { t } = useTranslation()
  const { getValues, setValue, watch } = useFormContext()

  // hooks custom
  const { handleSearchCourse, findAvailableSlots, getOptionsFromOptionIds } =
    usePatientDetail()
  const { isOpen } = useDrawerSchedule()

  // hooks get data
  const { getOptionIdsByCourseId } = useMedicalCheckupMaster()

  // recoil
  const [tableSearchSlotReservation, setTableSearchSlotReservation] =
    useRecoilState(tableSearchSlotReservationAtom)

  // state hook
  const [dateSelected, setDateSelected] = useState({
    year: String(dayjs().year()),
    month: String(dayjs().month() + 1)
  })
  const [selectData, setSelectData] = useState<SelectDataType>({
    course: {
      list: [],
      filterList: [],
      courseId: '',
      courseName: ''
    },
    option: {
      list: [],
      filterList: [],
      optionIds: [],
      optionName: ''
    }
  })

  // mapping course and option from tableSearchSlotReservation to selectData
  useEffect(() => {
    const { course, option } = tableSearchSlotReservation

    course.courseId && setValue('courseSearchDrawer', course.courseId)
    option.optionIds && setValue('optionSearchDrawer', option.optionIds)

    setSelectData({
      course,
      option
    })
  }, [tableSearchSlotReservation.course, tableSearchSlotReservation.option])

  // create a ref to mapping courseIdRef value
  const courseIdRef = useRef(tableSearchSlotReservation.course.courseId)

  useEffect(() => {
    const courseId = tableSearchSlotReservation.course.courseId
    courseIdRef.current = courseId
  }, [tableSearchSlotReservation.course.courseId])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    fetchSlots({
      courseId: courseIdRef.current,
      month: String(dayjs().month() + 1),
      year: String(dayjs().year())
    })
  }, [isOpen])

  const fetchSlots = async ({
    courseId,
    month,
    year
  }: {
    courseId: string
    month: string
    year: string
  }) => {
    if (!courseId) return

    return await findAvailableSlots({
      courseSearch: courseId,
      month,
      year
    })
  }

  const onResearchSlots = ({ courseId }: { courseId: string }) => {
    // update date to search
    setTableSearchSlotReservation((prev) => ({
      ...prev,
      date: {
        month: dateSelected.month,
        year: dateSelected.year
      }
    }))

    // fetch slots
    fetchSlots({ courseId, month: dateSelected.month, year: dateSelected.year })
  }

  const onChangeCourseOrOptions = (
    name: string,
    value: string[] | string // value is course id or option ids
  ) => {
    if (!name || !value) return

    setSelectData((prev) => {
      const updatedState: SelectDataType = { ...prev }

      const updateField = (field: 'course' | 'option', data: any) => {
        updatedState[field] = {
          ...updatedState[field],
          ...data
        }
      }

      if (name === 'courseSearchDrawer') {
        const courseId = value as string

        // Update options when course changes
        updateOptions(courseId, userInfo?.sex, String(userInfo?.age))
        setValue('optionSearchDrawer', [])

        const courses = updatedState.course.list ?? []
        const courseUpdate = {
          courseId,
          courseName: getNameCourseOrOptionByIds(courses, [courseId])
        }

        updateField('course', courseUpdate)
      } else if (name === 'optionSearchDrawer') {
        const optionIds = value as string[]
        const options = updatedState.option.list ?? []

        const optionUpdate = {
          optionIds,
          optionName: getNameCourseOrOptionByIds(options, optionIds)
        }

        updateField('option', optionUpdate)
      }

      return updatedState
    })
  }

  const updateOptions = async (courseId: string, sex: string, age: string) => {
    if (!courseId) return

    const optionIds = getOptionIdsByCourseId(
      courseId,
      tableSearchSlotReservation.course.list
    )

    const options = await getOptionsFromOptionIds(optionIds)

    const satisfyOptions = filterCourseResponseInValid(options, {
      sex: sex,
      age: age
    })

    const optionsConvertedTypeSelect = satisfyOptions.map((option) => ({
      label: option.medicalCheckupMaster.displayName,
      value: option.medicalCheckupMaster.refId
    }))

    setSelectData((prev) => ({
      ...prev,
      option: {
        ...prev.option,
        filterList: optionsConvertedTypeSelect,
        list: satisfyOptions,
        optionName: '',
        optionIds: []
      }
    }))
  }

  const onClickResearch = () => {
    const { optionSearchDrawer, courseSearchDrawer } = getValues()

    setValue('courseSearch', courseSearchDrawer)
    setValue('optionSearch', optionSearchDrawer)

    onResearchSlots({ courseId: courseSearchDrawer })

    // mapping selectData to tableSearchSlotReservation
    setTableSearchSlotReservation((prev) => ({
      ...prev,
      course: selectData.course,
      option: selectData.option
    }))
  }

  return (
    <div className="w-full px-12 text-[#707070] text-sm">
      <div className="flex items-center">
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 mb-1 mr-4 font-bold">
          <span>{t('dashboard.search.patient_id')}</span>
        </div>
        <div className="mb-1 mr-4 truncate">
          <span>{userInfo.id}</span>
        </div>
      </div>
      <div className="flex items-center">
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 mb-1 mr-4 font-bold">
          <span>{t('dashboard.search.last_name')}</span>
        </div>
        <div className="w-56 mb-1 mr-4 truncate">
          <span>{userInfo.lastName}</span>
        </div>
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 mb-1 mr-4 font-bold truncate">
          <span>{t('dashboard.search.first_name')}</span>
        </div>
        <div className="w-56 mb-1 mr-4 truncate">
          <span>{userInfo.firstName}</span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 mb-1 mr-4 font-bold">
          <span>{t('dashboard.search.last_name_kana')}</span>
        </div>
        <div className="w-56 mb-1 mr-4 truncate">
          <span>{userInfo.lastNameKana}</span>
        </div>
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 mb-1 mr-4 font-bold truncate">
          <span>{t('dashboard.search.first_name_kana')}</span>
        </div>
        <div className="w-56 mb-1 mr-4 truncate">
          <span>{userInfo.firstNameKana}</span>
        </div>
      </div>

      {/* course */}
      <div className="flex items-center">
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 font-bold mb-1 mr-4">
          <span>{t('dashboard.search.course')}</span>
        </div>
        <div className="flex-grow mb-1 max-w-[626px]">
          <Field
            options={selectData?.course?.filterList ?? []}
            name="courseSearchDrawer"
            placeholder={t('dashboard.search.course_placeholder')}
            onSearch={handleSearchCourse}
            onChange={onChangeCourseOrOptions}
          />
        </div>
        <div className="min-w-28"></div>
      </div>

      {/* search */}
      <div className="flex items-center">
        <div className="min-w-[164px] bg-[#F0F3F7] px-2 py-1 font-bold mr-4">
          <span>{t('dashboard.search.option')}</span>
        </div>
        <div className="flex-grow">
          <SelectMultipleCustom
            options={selectData?.option?.filterList ?? []}
            name="optionSearchDrawer"
            placeholder={t('placeholder.findAnOption')}
            className="w-full"
            onChange={onChangeCourseOrOptions}
          />
        </div>

        <div className="min-w-28">
          <Button
            type="primary"
            className="border-none ml-3"
            onClick={onClickResearch}
          >
            {t('dashboard.research')}
          </Button>
        </div>
      </div>

      {/* filter date */}
      <div className="mt-2">
        <YearMonthSelector
          setDateSelected={setDateSelected}
          dateSelected={dateSelected}
        />
      </div>
    </div>
  )
}

export default SearchContainer

export const Field = ({
  placeholder,
  name,
  options,
  onSearch,
  onChange
}: {
  placeholder: string
  name: 'courseSearchDrawer' | 'optionSearchDrawer'
  options: any[]
  onSearch?: any
  onChange: (name: string, value: string[] | string) => void
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Select
            className="w-full courseSearch mr-3"
            prefix={<Search />}
            suffixIcon={null}
            placeholder={placeholder}
            options={options}
            onSearch={onSearch}
            popupClassName="coursePopupSelect"
            menuItemSelectedIcon={null}
            onInputKeyDown={preventEnterKeyDefault}
            onChange={(value) => {
              field.onChange(value)
              onChange(name, value)
            }}
            value={field.value}
            showSearch
            filterOption={false}
          />
        )
      }}
    />
  )
}
