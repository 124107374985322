import { DocumentNode, gql } from '@apollo/client'

export const FILTER_CHECKUP_USER_NAME: DocumentNode = gql`
  query filterCheckupUser(
    $filter: String!
    $page: Int!
    $size: Int!
    $sortBy: String
  ) {
    filterCheckupUser(
      page: $page
      size: $size
      filter: $filter
      sortBy: $sortBy
    ) {
      status
      message
      payload {
        refId
        gender
        birthday
        additionalInfo
      }
    }
  }
`
