import dayjs from 'dayjs'

import { SpecialConfig } from './useTimeConfig'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const dateFormat = 'YYYY-MM-DD'

const isPastDate = (date: string) => {
  return dayjs(date).isBefore(dayjs(), 'day')
}

const isCurrentDate = (date: string) => {
  return dayjs(date).isSame(dayjs(), 'day')
}

const isInWorkTime = (time: string, range: { start: string; end: string }) => {
  const timeValue = dayjs(time, 'HH:mm')
  const startValue = dayjs(range.start, 'HH:mm')
  const endValue = dayjs(range.end, 'HH:mm')

  return (
    timeValue.isSameOrAfter(startValue) && timeValue.isSameOrBefore(endValue)
  )
}

const isPastTime = (time: string) => {
  const currentTime = dayjs()
  const inputTime = dayjs(time, 'HH:mm')

  return inputTime.isBefore(currentTime, 'minute')
}

const getWeekOfMonth = (dateString: string): number => {
  const day = dayjs(dateString).day()
  const date = dayjs(dateString).format(dateFormat)

  const now = dayjs(dateString)
  const daysInMonth = now.daysInMonth()

  const arrDate: string[] = []
  for (let i = 1; i <= daysInMonth; i++) {
    const currentDay = now.date(i)

    if (currentDay.day() === day) {
      arrDate.push(currentDay.format(dateFormat))
    }
  }

  return arrDate.indexOf(date) + 1
}

const useTimeChecker = () => {
  const checkTime = (date: string, time: string, config: SpecialConfig) => {
    const standardConfig = config.config
    const day = +dayjs(date).format('d')

    // past date
    if (isPastDate(date)) {
      return true
    }

    // not working day
    if (!standardConfig[day]) {
      return true
    }

    // pastime
    if (isCurrentDate(date) && isPastTime(time)) {
      return true
    }

    const specialConfig = config.specialConfig
    const weekNumber = getWeekOfMonth(date)

    const foundConfig = specialConfig.find(
      (item) => item.day === day && item.week === weekNumber
    )

    if (foundConfig) {
      const { type, time: foundConfigTime } = foundConfig
      if (type === 'off') {
        return true
      }
      if (!isInWorkTime(time, foundConfigTime)) {
        return true
      }
    } else if (!foundConfig) {
      // not working time
      const workTime = standardConfig[day]
      if (!isInWorkTime(time, workTime)) {
        return true
      }
    }

    return false
  }

  const checkTimeWeek = (date: number, time: string, config: SpecialConfig) => {
    const standardConfig = config.config

    // not working day
    if (!standardConfig[date]) {
      return true
    }

    // not working time
    const workTime = standardConfig[date]
    if (!isInWorkTime(time, workTime)) {
      return true
    }

    return false
  }

  return {
    checkTime,
    checkTimeWeek
  }
}

export default useTimeChecker
