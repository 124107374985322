import { useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { UPDATE_COURSE } from 'graphql/ProductManagement/copyCourse'
import { FILTER_AVAILABLE_SLOTS_BY_DATE } from 'graphql/ProductManagement/filterAvailableSlotsByDate'
import { FILTER_AVAILABLE_SLOTS_BY_WEEK } from 'graphql/ProductManagement/filterAvailableSlotsByWeek'
import { UPDATE_AVAILABLE_SLOTS } from 'graphql/ProductManagement/updateAvailableSlots'
import {
  AvailableSlotsByDate,
  AvailableSlotsByWeek
} from 'models/productManagement'
import { Endpoint } from 'utilities/apolloClient'

type FilterAvailableSlotsByDate = {
  medicalCheckupMasterRefId: string
  daterange: { startDate: string; endDate: string }
}

type CopyCourse = {
  source: string
  destination: string
}

const useProductSetting = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const [filterAvailableSlotsByWeek] = useLazyQuery(
    FILTER_AVAILABLE_SLOTS_BY_WEEK
  )
  const [filterAvailableSlotsByDate] = useLazyQuery(
    FILTER_AVAILABLE_SLOTS_BY_DATE
  )
  const [updateAvailableSlots] = useMutation(UPDATE_AVAILABLE_SLOTS)
  const [copyCourse] = useMutation(UPDATE_COURSE)

  const onFilterAvailableSlotByWeek = async (
    medicalCheckupMasterRefId: string
  ) => {
    setLoading(true)

    try {
      const reservationWeek = await filterAvailableSlotsByWeek({
        variables: {
          medicalCheckupMasterRefId: medicalCheckupMasterRefId
        },
        context: {
          version: Endpoint.RESERVATION
        },
        fetchPolicy: 'network-only'
      })

      const availableSlotByWeek: AvailableSlotsByWeek[] =
        reservationWeek.data.filterAvailableSlotsByWeek.payload.map(
          (item: any) => ({
            refId: item.refId,
            day: item.datetimeRule.byday,
            time: item.datetimeRule.time.slice(0, 5),
            emptySlot: item.emptySlotCount,
            rule: item.datetimeRule.rule
          })
        )

      setLoading(false)
      return {
        data: availableSlotByWeek
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      throw new Error('Failed to fetch available slots by week.')
    }
  }

  const onFilterAvailableSlotByDate = async (
    data: FilterAvailableSlotsByDate
  ) => {
    setLoading(true)

    try {
      const availableSlotsByDate = await filterAvailableSlotsByDate({
        variables: data,
        context: {
          version: Endpoint.RESERVATION
        },
        fetchPolicy: 'network-only'
      })

      const availableSlotByDate: AvailableSlotsByDate[] =
        availableSlotsByDate.data.filterAvailableSlotsByDate.payload.map(
          (item: any) => ({
            refId: item.refId,
            date: item.datetimeRule.date.split('T')[0],
            time: item.datetimeRule.time.slice(0, 5),
            emptySlot: item.emptySlotCount,
            rule: item.datetimeRule.rule
          })
        )

      setLoading(false)
      return {
        data: availableSlotByDate,
        dateRange: data.daterange
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      throw new Error('Failed to fetch available slots by date.')
    }
  }

  const onUpdateAvailableSlot = async (data: any) => {
    setLoading(true)

    try {
      await updateAvailableSlots({
        context: {
          version: Endpoint.RESERVATION
        },
        variables: {
          availableSlots: data
        }
      })

      setLoading(false)
      return {
        message: 'Success'
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      throw new Error('Failed to update available slots.')
    }
  }

  const onCopyCourse = async (data: CopyCourse) => {
    setLoading(true)

    try {
      await copyCourse({
        context: {
          version: Endpoint.RESERVATION
        },
        variables: {
          sourceMedicalCheckupMasterRefId: data.source,
          destinationMedicalCheckupMasterRefId: data.destination
        }
      })

      setLoading(false)
      return {
        message: 'Success'
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      throw new Error('Failed to copy course.')
    }
  }

  return {
    loading,
    onFilterAvailableSlotByWeek,
    onFilterAvailableSlotByDate,
    onUpdateAvailableSlot,
    onCopyCourse
  }
}

export default useProductSetting
