import { DRAWER_TYPE } from 'configs/constant'
import DrawerFirstBlock from 'pages/Scenario/ScenarioCreate/components/drawer/DrawerFirstBlock'
import DrawerLastBlock from 'pages/Scenario/ScenarioCreate/components/drawer/DrawerLastBlock'

const useOnCloseHandler = (type: DRAWER_TYPE) => {
  return () => {
    switch (type) {
      case DRAWER_TYPE.FIRST_STEP:
      case DRAWER_TYPE.LAST_STEP:
        break
      default:
        console.log('Default onClose logic')
    }
  }
}

const useDrawer = () => {
  const drawerData = [
    {
      type: DRAWER_TYPE.FIRST_STEP,
      component: <DrawerFirstBlock />,
      onClose: useOnCloseHandler(DRAWER_TYPE.FIRST_STEP)
    },
    {
      type: DRAWER_TYPE.LAST_STEP,
      component: <DrawerLastBlock />,
      onClose: useOnCloseHandler(DRAWER_TYPE.LAST_STEP)
    }
  ]

  return {
    drawerData: drawerData
  }
}

export default useDrawer
