import React, { FC, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'

import useTimeChecker from '../../../hooks/useTimeChecker'
import { CourseSetting } from '../ProductManagement'
import styles from './scss/TabbleWrapper.module.scss'
import { SpecialConfig } from 'hooks/useTimeConfig'
import { RemainedSlotByDate } from 'models/productManagement'

type Props = {
  loading: boolean
  courseParam: string | null
  courseName?: string
  initialData: RemainedSlotByDate[]
  dateRange: string[]
  config: SpecialConfig
  courseSetting: CourseSetting
}

const RemainedSlotsByDate: FC<Props> = ({
  loading,
  courseParam,
  courseName,
  initialData,
  dateRange,
  config,
  courseSetting
}) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const { checkTime } = useTimeChecker()

  const queryParams = new URLSearchParams(search)
  const { startDate: startDateParam, endDate: endDateParam } =
    Object.fromEntries(queryParams)

  const getDayColor = (date: string) => {
    const day = dayjs(date)
    const dayOfWeek = day.day()
    const isSunday = dayOfWeek === 0
    const isSaturday = dayOfWeek === 6

    return isSunday ? '#FEF3F7' : isSaturday ? '#F0F9FF' : '#F0F3F7'
  }

  const getDayOfWeek = (date: string) => {
    const day = dayjs(date)
    const dayOfWeek = day.day()
    const dayColor =
      dayOfWeek === 0 ? '#FEF3F7' : dayOfWeek === 6 ? '#F0F9FF' : ''
    const textColor =
      dayOfWeek === 0 ? '#DF2475' : dayOfWeek === 6 ? '#3089BF' : '#545454'

    return (
      <div
        className={`bg-${dayColor} border border-[#CDD6DD] py-2 text-center text-base`}
      >
        <Typography className={`text-[${textColor}]`}>
          {day.format('MM/DD')} ({day.format('ddd')})
        </Typography>
      </div>
    )
  }

  const initialDataMap = initialData.reduce(
    (acc, item) => {
      acc[`${item.time}-${item.date}`] = item
      return acc
    },
    {} as Record<string, RemainedSlotByDate>
  )

  const renderCell = (date: string, time: string) => {
    const item = initialDataMap[`${time}-${date}`]

    const isDisabled = checkTime(date, time, config)

    const handleNavigate = () => {
      navigate('/reservation-register', {
        state: {
          courseId: courseParam,
          courseName: courseName,
          date: date,
          time: time,
          remainedSlots: item.remainedSlot,
          startDate: startDateParam,
          endDate: endDateParam,
          courseSetting
        }
      })
    }

    return item && item.remainedSlot > 0 ? (
      <Flex
        justify="center"
        className={`h-[25px] border border-[#CDD6DD] ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}
      >
        <a
          className="font-bold text-[#137695] underline"
          onClick={handleNavigate}
        >
          {`〇${item.remainedSlot}`}
        </a>
      </Flex>
    ) : (
      <div
        style={{
          background: getDayColor(date)
        }}
        className={`h-[25px] border border-[#CDD6DD]`}
      />
    )
  }

  const dataSource = config?.time.map((time) => {
    const row: { key: string; time: string; [key: string]: ReactNode } = {
      key: time,
      time
    }

    dateRange.forEach((date) => {
      row[date] = renderCell(date, time)
    })

    return row
  })

  const columns: TableColumnsType<any> = [
    {
      title: <></>,
      dataIndex: 'time',
      width: '4%',
      render: (text) => (
        <Typography className="text-[#545454]">{text}</Typography>
      )
    },
    ...dateRange.map((date) => ({
      title: getDayOfWeek(date),
      dataIndex: date,
      width: '11%'
    })),
    {
      title: '',
      render: () => null
    }
  ]

  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      className={`${styles.table_wrapper}`}
    />
  )
}

export default RemainedSlotsByDate
