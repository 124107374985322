import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

import { StringKeyObject } from 'types/common'

export default function ComprehensiveJudgment({
  dataShowMedicalCheckups
}: {
  dataShowMedicalCheckups: StringKeyObject[]
}) {
  const { t } = useTranslation()
  return (
    <Flex className="border-b border-[#BFC6CB]">
      <div className="w-[25%] px-5 py-4 border-left-between">
        <span className="font-bold">{t('testResult.generalEvaluation')}</span>
      </div>
      {dataShowMedicalCheckups?.map((item: StringKeyObject) => (
        <Flex
          key={item.refId}
          className="w-[25%] border-left-between items-center px-2.5"
        >
          <span className="font-bold text-[29px]">{item.evaluation}</span>
        </Flex>
      ))}
    </Flex>
  )
}
