import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Table, TableColumnsType, Typography } from 'antd'

import { FoundData } from '../ReservationRegister'
import styles from './css/TabbleWrapper.module.scss'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'

type Props = {
  loading: boolean
  initialData: FoundData[]
  onRowSelectionChange: (selectedRow: FoundData | null) => void
}

const customerDetailTab = '0'

const SortableColumnTitle = memo(
  ({ title, onSort }: { title: string; onSort: () => void }) => (
    <div className="flex justify-center items-center">
      <Typography className="mr-4 font-normal">{title}</Typography>
      <ArrowTopBottom className="cursor-pointer" onClick={onSort} />
    </div>
  )
)

const UserInformation: FC<Props> = memo(
  ({ loading, initialData, onRowSelectionChange }) => {
    const { t } = useTranslation()
    const { pathname, search, state } = useLocation()
    const navigate = useNavigate()

    const queryParams = new URLSearchParams(search)
    const sortParam = queryParams.get('sortBy') || 'asc'

    const [selectedRowKey, setSelectedRowKey] = useState<React.Key | null>(null)

    useEffect(() => {
      setSelectedRowKey(null)
      onRowSelectionChange(null)
    }, [initialData])

    const handleSort = (order: string) => {
      queryParams.set('orderBy', order.toString())
      queryParams.set('sortBy', sortParam === 'asc' ? 'desc' : 'asc')

      navigate(`${pathname}?${queryParams.toString()}`, { state })
    }

    const handleNavigate = (id: string) => {
      navigate({
        pathname: '/customer-detail',
        search: createSearchParams({
          id,
          currentTab: customerDetailTab
        }).toString()
      })
    }

    const columns: TableColumnsType<FoundData> = useMemo(
      () => [
        {
          title: (
            <SortableColumnTitle
              title={t('reservationRegister.label.id')}
              onSort={() => handleSort('id')}
            />
          ),
          dataIndex: 'refId',
          width: '22%',
          render: (value) => (
            <Typography className="ml-14 h-[22px] whitespace-nowrap px-3">
              {value}
            </Typography>
          )
        },
        {
          title: (
            <SortableColumnTitle
              title={t('reservationRegister.label.fullName')}
              onSort={() => handleSort('fullName')}
            />
          ),
          dataIndex: 'fullName',
          width: '25%',
          render: (value, record) => (
            <div className="px-3">
              {value ? (
                <Typography
                  onClick={() => handleNavigate(record.refId)}
                  className="inline-block cursor-pointer font-bold text-[#137695] underline"
                >
                  {value}
                </Typography>
              ) : (
                <Typography>ー</Typography>
              )}
            </div>
          )
        },
        {
          title: (
            <SortableColumnTitle
              title={t('reservationRegister.label.birthday')}
              onSort={() => handleSort('birthday')}
            />
          ),
          dataIndex: 'birthday',
          width: '10%',
          render: (text) => (
            <Typography className="text-[#707070] tracking-[1.4px] text-center">
              {text}
            </Typography>
          )
        },
        {
          title: null,
          render: () => null
        }
      ],
      [t, initialData]
    )

    const rowSelection = {
      selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
      onChange: ([key]: React.Key[]) => {
        const selectedRow = initialData.find((row) => row.key === key) || null
        setSelectedRowKey(key || null)
        onRowSelectionChange(selectedRow)
      },
      columnWidth: 0,
      renderCell: () => null
    }

    const handleRowClick = (record: FoundData) => {
      const isSelected = selectedRowKey === record.key
      setSelectedRowKey(isSelected ? null : record.key)
      onRowSelectionChange(isSelected ? null : record)
    }

    return (
      <div className="rounded-[5px] bg-[#FFFFFF] px-[10px] pb-[4px] pt-[10px] drop-shadow-md">
        <Typography className="mb-4">
          <span className="text-[#137695]">▼</span>
          {t('reservationRegister.userFound', { count: initialData.length })}
        </Typography>

        <Table
          loading={loading}
          rowSelection={{
            type: 'radio',
            ...rowSelection
          }}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record)
            }
          })}
          columns={columns}
          dataSource={initialData}
          pagination={false}
          scroll={{
            y: 'calc(100vh - 700px)',
            scrollToFirstRowOnChange: true
          }}
          className={`${styles.table_wrapper} mt-[-10px] overflow-hidden py-4 pt-0`}
        />
      </div>
    )
  }
)

export default UserInformation
