import { ReactNode, memo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useRecoilState, useSetRecoilState } from 'recoil'

import { tableSearchSlotReservationAtom } from 'hooks/usePatientDetail'
import {
  getNameCourseOrOptionByIds,
  preventEnterKeyDefault
} from 'utilities/helpers'

function CourseSearch({
  inputCourseSearch,
  inputOptionSearch,
  btnSearch
}: {
  inputCourseSearch: ReactNode
  inputOptionSearch: ReactNode
  btnSearch: ReactNode
}) {
  // const [tableSearchSlotReservation, setTableSearchSlotReservation] =
  //   useRecoilState(tableSearchSlotReservationAtom)
  // const { watch, setValue } = useFormContext()

  // const courseIdsSearchField: string = watch('courseSearch')
  // const optionIdsSearchField: string[] = watch('optionSearch')

  // useEffect(() => {
  //   if (optionIdsSearchField || courseIdsSearchField) {
  //     setTableSearchSlotReservation((prev) => ({
  //       ...prev,
  //       course: {
  //         ...prev.course,
  //         courseId: courseIdsSearchField,
  //         courseName: getNameCourseOrOptionByIds(prev.course.list ?? [], [
  //           courseIdsSearchField ?? []
  //         ])
  //       },
  //       option: {
  //         ...prev.option,
  //         optionIds: optionIdsSearchField,
  //         optionName: getNameCourseOrOptionByIds(
  //           prev.option.list ?? [],
  //           optionIdsSearchField ?? []
  //         )
  //       }
  //     }))
  //   }
  // }, [optionIdsSearchField, courseIdsSearchField])

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
      }}
      onKeyDown={preventEnterKeyDefault}
      className="mt-4"
    >
      <div className="">{inputCourseSearch}</div>
      <div className="mt-4">{inputOptionSearch}</div>
      <div className="">{btnSearch}</div>
    </form>
  )
}

export default memo(CourseSearch)
