import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox, Col, Flex, Radio, Row, Tree, Typography } from 'antd'
import type { TreeDataNode } from 'antd'

import { ChoiceTypeEnums, QuestionTypeEnums } from '../../../models/survey'
import { isTruthy } from '../../../utilities/helpers'
import './FreeQuestionItemConfirmCard.css'
import { CheckOutlined } from '@ant-design/icons'

export const FreeQuestionItemConfirmCard = ({
  question,
  index,
  displayEnglish
}: {
  question: any
  index: number
  displayEnglish: boolean
}) => {
  const { t } = useTranslation()
  const [treeData, setTreeData] = React.useState<TreeDataNode[]>()
  const { displayNameMap, questionType, isRequired, setting, options } =
    question

  useEffect(() => {
    const treeQuest: TreeDataNode[] = [
      {
        title: (
          <div>
            <Typography className="leading-none" style={{ color: '#707070' }}>
              {displayNameMap?.ja}
            </Typography>
            {displayEnglish ? (
              <Typography
                className="leading-none mt-1"
                style={{ color: '#707070' }}
              >
                {displayNameMap?.en}
              </Typography>
            ) : null}
          </div>
        ),
        key: `${index}-0-0`,
        children: [
          {
            title: (
              <Flex gap="large" className="free-answer-row-item">
                <Typography.Text className="min-w-20">
                  {t('questionnaire.label.typeQuestion')}
                </Typography.Text>
                <Typography.Text>
                  <CheckOutlined
                    style={{ color: '#137695' }}
                    className="text-[12px] pr-3"
                  />
                  {questionType === QuestionTypeEnums.choice
                    ? t(`questionnaire.label.${setting?.choiceType}`)
                    : t(`questionnaire.label.${questionType}`)}
                </Typography.Text>
              </Flex>
            ),
            key: `${index}-0-0-0`
          },
          {
            title: (
              <Flex
                gap="large"
                className="free-answer-row-item question-preview"
              >
                <Typography.Text className="min-w-20">
                  {t('questionnaire.label.addAnswerQuestion')}
                </Typography.Text>

                {questionType === QuestionTypeEnums.choice &&
                  setting?.choiceType !== ChoiceTypeEnums.checkbox && (
                    <Radio.Group onChange={() => ({})} value="">
                      {options.map((answer: any, idx: number) => (
                        <Radio key={idx} value={answer.no}>
                          {answer?.displayNameMap?.ja}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                {questionType === QuestionTypeEnums.choice &&
                  setting?.choiceType === ChoiceTypeEnums.checkbox && (
                    <Checkbox.Group onChange={() => ({})} value={[]}>
                      {options.map((answer: any, idx: number) => (
                        <Checkbox key={idx} value={answer.no}>
                          {answer?.displayNameMap?.ja}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  )}
                {questionType === QuestionTypeEnums.text && <></>}
              </Flex>
            ),
            key: `${index}-0-0-1`
          }
        ]
      }
    ]

    // console.log({ treeQuest })
    setTreeData(treeQuest)
  }, [question])

  return (
    <Flex gap="middle" vertical className="free-questionnaire-item mt-4">
      <Row>
        <Col className="gutter-row text-center" span={1}>
          <div>{++index}</div>
        </Col>
        <Col className="" span={20}>
          {treeData && (
            <Tree
              showLine
              autoExpandParent={true}
              checkable={false}
              selectable={false}
              defaultExpandedKeys={[`${index}-0-0`]}
              switcherIcon={<></>}
              treeData={treeData}
            />
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <Flex gap="middle" className="btn-controller float-end">
            {isTruthy(isRequired) ? (
              <Typography.Text className="border-2 border-[#DF2475] px-2 py-1 rounded font-bold text-[#DF2475]">
                {t('lable.required')}
              </Typography.Text>
            ) : (
              <Typography.Text className="border-2 px-2 py-1 rounded font-bold">
                {t('lable.any')}
              </Typography.Text>
            )}
          </Flex>
        </Col>
      </Row>
    </Flex>
  )
}
