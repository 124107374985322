import { memo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Avatar } from 'antd'
import { useRecoilValue } from 'recoil'

import { CloseOutlined } from '@ant-design/icons'
import {
  messageListAtom,
  roomsSortedAtom,
  typingMessageAtom
} from 'hooks/chat/atoms'
import ChatContainer from 'pages/CustomerDetail/components/controlPanel/ChatContainer'
import { getUserInfoInRoom } from 'utilities/chatHelpers'

function Discussion() {
  const [searchParams] = useSearchParams()
  const rId = searchParams.get('rId')

  const messageList = useRecoilValue(messageListAtom)
  const roomsSorted = useRecoilValue(roomsSortedAtom) || []
  const typingMessage = useRecoilValue(typingMessageAtom)

  const [userInfoInRoom, setUserInfoInRoom] = useState({
    checkupUserRefId: '',
    patientName: '',
    patientAvatar: ''
  })

  useEffect(() => {
    if (!rId || roomsSorted.length <= 0) return

    const room = roomsSorted.find((room) => room._id === rId)

    if (room) {
      const patientInfo = getUserInfoInRoom(
        room.customFields.uid,
        room.subscriptions
      )

      setUserInfoInRoom({
        checkupUserRefId: patientInfo?.user?.username ?? '',
        patientName: patientInfo?.user?.name ?? '',
        patientAvatar: patientInfo?.user?.avatarOrigin ?? ''
      })
    }
  }, [rId, roomsSorted])

  return (
    <div className="w-full h-full">
      <div className="flex items-center justify-between px-3 py-2 bg-transparent bg-gradient-to-r from-[#127594] to-[#93D1CA] bg-no-repeat">
        <div className="flex items-center">
          <Avatar className="w-8 h-8" src={userInfoInRoom?.patientAvatar} />
          <div className="ml-5">
            <p className="text-xs text-white">
              ID: {userInfoInRoom?.checkupUserRefId}
            </p>
            <p className="text-sm text-white underline">
              {userInfoInRoom?.patientName}
            </p>
          </div>
        </div>

        <CloseOutlined className="text-white text-xl" />
      </div>

      <ChatContainer
        messages={messageList}
        typingMessage={typingMessage}
        isDivider={false}
      />
    </div>
  )
}

export default memo(Discussion)
