import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Col, Flex, Row, Typography } from 'antd'

import {
  FreeQuestionItemConfirmCard,
  QuestionItemConfirmCard
} from '../../components/screens/Questionnaires'
import SpinLoading from '../../components/screens/Spin/SpinLoading'
import { openNotification } from '../../components/widgets/Notification'
import { useDetailSurvey } from '../../hooks/survey'
import { extractDepartmentsFromCode } from '../../hooks/survey/useSurvey'
import { StatusEnums, SubsectionTypeEnums } from '../../models/survey'
import './SurveyDetail.css'

export default function SurveyDetail() {
  let { refid: refId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { fetchSurveyDetail: surveyDetail, loading } = useDetailSurvey()
  const [dataSurvey, setDataSurvey] = useState<any>()

  const fetchSurveyDetail = async (refId: string) => {
    const surveyResponse: any = await surveyDetail({
      filter: `(eq,STRING,refId,${refId})`,
      paginationInput: { page: 0, size: 1 }
    })

    if (surveyResponse) {
      setDataSurvey(surveyResponse)
      return
    }

    openNotification({
      type: 'error',
      title: t('commonError'),
      message: t('errors.specifyDetailRefId')
    })

    navigate('/survey-list')
  }

  useEffect(() => {
    if (refId) {
      fetchSurveyDetail(refId)
    }
  }, [refId])

  return (
    <>
      <SpinLoading loading={loading} />

      <Flex vertical gap="middle" className="questionnaire-creation-confirm">
        <Flex gap="middle" justify="flex-start" align="center">
          <Typography.Text className="mr-12 text-[16px]" strong>
            {t('questionnaire.label.generalHealth')}
          </Typography.Text>
        </Flex>

        <Flex vertical gap="middle" className="ml-3">
          <Flex vertical gap="middle">
            <Row>
              <Col span={12}>
                <Flex gap="middle" justify="flex-start" align="center">
                  <Col span={5} className="justify-end flex">
                    <Typography.Text className="text-default">
                      {t('questionnaire.departmentSelect')}
                    </Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Text className="text-default">
                      {dataSurvey?.code &&
                        extractDepartmentsFromCode(dataSurvey.code).join('、')}
                    </Typography.Text>
                  </Col>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                <Flex gap="middle" justify="flex-start" align="center">
                  <Col span={3} className="justify-end flex">
                    <Typography.Text className="text-default">
                      {t('questionnaire.title')}
                    </Typography.Text>
                  </Col>
                  <Col span={21}>
                    <Typography.Text className="text-default">
                      {dataSurvey?.displayNameMap?.ja}
                    </Typography.Text>
                  </Col>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Flex gap="middle" justify="flex-start" align="center">
                  <Col span={5} className="justify-end flex">
                    <Typography.Text className="text-default">
                      {t('questionnaire.label.questionCategories')}
                    </Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Text className="text-default">
                      {!loading &&
                        (dataSurvey?.displayCategories ? 'ON' : 'OFF')}
                    </Typography.Text>
                  </Col>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Flex gap="middle" justify="flex-start" align="center">
                  <Col span={5} className="justify-end flex">
                    <Typography.Text className="text-default">
                      {t('questionnaire.label.englishDisplay')}
                    </Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Text className="text-default">
                      {!loading &&
                        (dataSurvey?.displayEnglish
                          ? t('display')
                          : t('hidden'))}
                    </Typography.Text>
                  </Col>
                </Flex>
              </Col>
            </Row>
          </Flex>
        </Flex>

        <Flex
          vertical
          className="bg-white p-[12px] rounded-r-lg shadow"
          gap="middle"
        >
          <Flex gap="small" vertical className="bg-[#EFF3F6] p-[8px] gap-[0px]">
            <Typography.Text strong className="text-primary tracking-[1.4px]">
              {t('questionnaire.label.fixedList2')}
            </Typography.Text>
          </Flex>

          <Flex vertical className="p-3" gap={8}>
            {dataSurvey &&
              dataSurvey.fixedSection &&
              dataSurvey.fixedSection.subsectionList
                .filter((s: any) => s.status === StatusEnums.active)
                .map((subsection: any, index: number) => (
                  <QuestionItemConfirmCard
                    question={subsection}
                    index={index}
                    key={index}
                    displayEnglish={dataSurvey?.displayEnglish ?? true}
                  />
                ))}
          </Flex>

          <Flex gap="small" vertical className="bg-[#EFF3F6] p-2 gap-[0px]">
            <Typography.Text strong className="text-primary tracking-[1.4px]">
              {t('questionnaire.label.freeQuestion')}
            </Typography.Text>
          </Flex>

          {dataSurvey?.sectionList &&
            dataSurvey?.sectionList.map((section: any, index: number) => (
              <Flex
                key={index}
                vertical
                gap="small"
                className="section-item tracking-[0.1px]"
              >
                {dataSurvey?.displayCategories ? (
                  <Flex
                    gap="small"
                    vertical
                    className="bg-[#137695] gap-[0px] section-title p-2"
                  >
                    <Typography.Text strong className="text-[#ffffff]">
                      {section.title.ja}
                    </Typography.Text>

                    {dataSurvey?.displayEnglish ? (
                      <Typography.Text strong className="text-[#ffffff]">
                        {section.title.en}
                      </Typography.Text>
                    ) : null}
                  </Flex>
                ) : null}

                <Flex vertical className="p-3 pt-0">
                  {section.subsectionList &&
                    section.subsectionList.map((question: any, idx: number) => {
                      const { subsectionType } = question
                      if (subsectionType === SubsectionTypeEnums.question) {
                        return (
                          <FreeQuestionItemConfirmCard
                            question={question}
                            index={idx}
                            key={idx}
                            displayEnglish={dataSurvey?.displayEnglish}
                          />
                        )
                      } else {
                        // TODO render other type
                        return null
                      }
                    })}
                </Flex>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </>
  )
}
