import { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from 'assets/svgs/close1.svg'
import { uuid } from 'utilities/helpers'

function ResultItem({ item, handleDelete }: { item: any; handleDelete: any }) {
  return (
    <div
      key={item.id}
      className="flex justify-between items-center px-2 py-1.5 bg-contentGray mt-1"
    >
      <span>{item.name}</span>
      <CloseIcon
        className="cursor-pointer min-w-5"
        onClick={() => handleDelete(item.id)}
      />
    </div>
  )
}

function SearchResult({
  coursePicked
}: {
  coursePicked: {
    id: string
    name: string
  }[]
}) {
  const { t } = useTranslation()
  const { getValues, setValue } = useFormContext()

  const handleDelete = (id: string) => {
    const pickedIds = getValues('courses') || []

    setValue(
      'courses',
      pickedIds.filter((courseId: string) => courseId !== id)
    )
  }

  return (
    <div className="mt-2">
      <p className="text-default mb-2">
        <span className="text-primary">▼</span>{' '}
        {t('scenarioCreate.drawer.searchCourse.selectedCourses')}
      </p>

      <div className="w-full">
        {coursePicked.map((item) => (
          <ResultItem key={uuid()} item={item} handleDelete={handleDelete} />
        ))}
      </div>
    </div>
  )
}

export default SearchResult
