import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs, Typography } from 'antd'

import ManageAssignments from './ManageAssignments'
import ManagePositions from './ManagePositions'
import { RESOURCES_TENANT } from 'configs/constant'
import { StringKeyObject } from 'types/common'

const TabAssignments = RESOURCES_TENANT.ASSIGNMENTS
const TabPositions = RESOURCES_TENANT.POSITIONS

export default function ManageAssignmentsAndPositions({
  assignments,
  positions,
  taskProcessingTenantAdditionalInfo
}: {
  assignments: StringKeyObject[]
  positions: StringKeyObject[]
  taskProcessingTenantAdditionalInfo: Function
}) {
  const { t } = useTranslation()
  const [tab, setTab] = useState<string>(TabAssignments)

  const dataTabs = [
    {
      key: TabAssignments,
      label: (
        <Typography className="underline">
          {t('table.assignment')}({assignments.length})
        </Typography>
      ),
      children: (
        <ManageAssignments
          assignments={assignments}
          taskProcessingTenantAdditionalInfo={
            taskProcessingTenantAdditionalInfo
          }
        />
      )
    },
    {
      key: TabPositions,
      label: (
        <Typography className="underline">
          {t('lable.position')}({positions.length})
        </Typography>
      ),
      children: (
        <ManagePositions
          positions={positions}
          taskProcessingTenantAdditionalInfo={
            taskProcessingTenantAdditionalInfo
          }
        />
      )
    }
  ]

  return (
    <div className="p-2.5 bg-white rounded-b-md shadow-md shadow-[#00000029] pt-0">
      <div className="relative">
        <Tabs
          defaultActiveKey={tab}
          items={dataTabs}
          onChange={(tab) => setTab(tab)}
          className="sub-tab-basic-setting"
        />
      </div>
    </div>
  )
}
