import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onCancel: () => void
}

const ReservationRegistrationCompletedNotice: FC<Props> = ({
  isOpen,
  onCancel
}) => {
  const { t } = useTranslation()

  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="pb-6 pt-4">
          <div>
            <Typography className="mb-[176px] flex justify-center text-[20px] font-bold tracking-[1px]">
              {t('productSetting.modal.reservationRegistrationCompleted')}
            </Typography>

            <Typography className="text-center">
              {t('productSetting.modal.notice')}
            </Typography>

            <Flex justify="center">
              <Button
                type="default"
                htmlType="button"
                onClick={onCancel}
                className="mt-[150px] h-[30px] min-w-[180px] rounded-none border-[1px] border-[#137695] bg-white text-center font-bold text-[#137695] shadow-none"
              >
                {t('productSetting.modal.back')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default ReservationRegistrationCompletedNotice
