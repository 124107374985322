import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { Drawer } from 'antd'

import { DRAWER_DATA_TYPE, DRAWER_TYPE } from 'configs/constant'

export interface DrawerDataProps {
  type: DRAWER_TYPE
  component: React.ReactNode
  onClose: () => void
}

export interface SupportDrawerProps {
  data: DrawerDataProps[]
}

const SupportDrawer = ({ data }: SupportDrawerProps) => {
  const { clearErrors } = useFormContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get('type')

  const fieldsToClear: string[] = []

  const handleOnCloseDrawer = () => {
    if (type === DRAWER_TYPE.FIRST_STEP) {
      fieldsToClear.push(
        DRAWER_DATA_TYPE.TEMPLATE_NAME,
        DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST
      )
    } else if (type === DRAWER_TYPE.LAST_STEP) {
      fieldsToClear.push(DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST)
    }

    fieldsToClear.forEach((field) => clearErrors(field))

    // remove type from params
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev)
      params.delete('type')
      return params
    })

    dataType?.onClose()
  }

  const dataType = useMemo(() => {
    if (!type)
      return {
        type: '',
        component: <></>,
        onClose: () => {}
      }

    return data.find((item) => item.type === type)
  }, [data, type])

  return (
    <Drawer
      width={563}
      closable={false}
      open={!!type}
      onClose={handleOnCloseDrawer}
    >
      {dataType?.component}
    </Drawer>
  )
}

export default SupportDrawer
