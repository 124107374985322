import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import InputLabel from '../InputLabel'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
  initialData?: {
    userId: string
    userFullName: string
    courseName: string
    courseOptions: string
    selectedDate: string
    status: string
    action: 'confirm' | 'edit'
  }
}

const ConfirmReservationDraftModal: FC<Props> = ({
  isOpen,
  onOk,
  onCancel,
  initialData
}) => {
  const { t } = useTranslation()
  const {
    userId,
    userFullName,
    courseName,
    courseOptions,
    selectedDate,
    status,
    action
  } = initialData || {}

  return (
    <CustomModal
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="min-h-[500px] px-3 py-4">
          <div>
            <Typography className="text-center text-xl font-bold tracking-[1px]">
              {action === 'confirm'
                ? t('reservationManagement.modal.temporaryReservation1')
                : t('reservationManagement.modal.temporaryReservation2')}
            </Typography>

            <Typography className="mt-3 text-center text-sm">
              {action === 'confirm'
                ? t('reservationManagement.modal.sendTemporaryReservation1')
                : t('reservationManagement.modal.sendTemporaryReservation2')}
            </Typography>

            <Flex
              justify="center"
              align="center"
              className="mt-3 h-[120px] w-[540px] border-[1px] border-[#DF2475] bg-[#DF24750F]"
            >
              <div>
                <Flex justify="center" className="mb-2">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#DF2475] text-[30px] text-white">
                    !
                  </div>
                </Flex>

                <Typography className="text-center text-[#DF2475]">
                  {t('reservationChange.modals.note1')}
                </Typography>

                <Typography className="text-center">
                  {t('reservationChange.modals.note2')}
                </Typography>
              </div>
            </Flex>

            <div className="mt-4 max-w-[520px]">
              <Flex align="center">
                <InputLabel label={'ID'} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {userId || 'ー'}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel label={t('reservationChange.label.fullName')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {userFullName || 'ー'}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel label={t('reservationChange.label.courseName')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {courseName || 'ー'}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel label={t('reservationChange.label.courseSelect')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {courseOptions || 'ー'}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel
                  label={t('reservationChange.label.selectedDateTime')}
                />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {selectedDate || 'ー'}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel
                  label={t('reservationChange.label.reservationStatus')}
                />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {status || 'ー'}
                </Typography.Text>
              </Flex>
            </div>

            <Flex justify="center" className="mt-4">
              <Button
                type="primary"
                htmlType="submit"
                autoInsertSpace={false}
                onClick={onOk}
                className="h-[30px] min-w-[180px] rounded-[3px] border-[1px] border-[#137695] bg-[#137695] text-center font-bold text-white shadow-none"
              >
                {t('reservationManagement.modal.button.submit')}
              </Button>

              <Button
                type="default"
                htmlType="button"
                autoInsertSpace={false}
                onClick={onCancel}
                className="ml-3 h-[30px] min-w-[180px] rounded-[3px] border-[1px] border-[#137695] bg-white text-center font-bold text-[#137695] shadow-none"
              >
                {t('reservationManagement.modal.button.cancel')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default ConfirmReservationDraftModal
