import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Modal } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import cn from 'utilities/cn'

const Header = ({ title }: { title: React.ReactNode }) => {
  return (
    <div className="flex flex-col !rounded-[10px]">
      <span className="h-4 w-full !rounded-t-[10px] bg-gradient-to-r from-[#137695] to-[#62aeb2]"></span>
      <Flex className="mt-6 " justify="center">
        <span className="text-default text-xl">{title}</span>
      </Flex>
    </div>
  )
}

const ModalConfirm = ({
  isModalVisible,
  handleOk,
  handleCancel,
  title = 'title',
  content,
  footer,
  textOk,
  textCancel,
  classNameContent,
  classNameFooter,
  classNames,
  icons,
  classNameModal
}: {
  isModalVisible: boolean
  handleOk?: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  title?: React.ReactNode
  content?: React.ReactNode
  textOk?: React.ReactNode
  textCancel?: React.ReactNode
  footer?: React.ReactNode
  classNameContent?: string
  classNameFooter?: string
  classNames?: {
    classNameFooter?: string
    classNameContent?: string
    classNameButtonOk?: string
    classNameButtonCancel?: string
  }
  icons?: React.ReactNode
  classNameModal?: string
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={<Header title={title} />}
      open={isModalVisible}
      onOk={handleOk}
      className={classNameModal}
      onCancel={handleCancel}
      maskClosable={true}
      closable={false}
      width={600}
      footer={
        <div key="footer" className={`flex justify-center ${classNameFooter}`}>
          {footer ? (
            footer
          ) : (
            <Flex justify="center" align="center" gap={12}>
              <Button
                autoInsertSpace={false}
                color="danger"
                variant="solid"
                onClick={handleOk}
                className={cn(
                  '!min-w-[180px] !bg-[#df2475] text-[14px] text-white font-bold',
                  classNames?.classNameButtonOk
                )}
              >
                {textOk ? textOk : t('button.keep')}
              </Button>

              <Button
                autoInsertSpace={false}
                color="danger"
                variant="outlined"
                type="default"
                onClick={handleCancel}
                className={cn(
                  '!min-w-[180px] !border-[#df2475] text-[14px] !text-[#df2475] font-bold',
                  classNames?.classNameButtonCancel
                )}
              >
                {textCancel ? textCancel : t('button.cancel')}
              </Button>
            </Flex>
          )}
        </div>
      }
      style={{ textAlign: 'center' }}
      classNames={{
        content: cn('!p-0 !rounded-[10px]', classNameContent),
        header: '!rounded-[10px]',
        footer: '!m-0 !px-6 !py-5'
      }}
    >
      <div className="flex flex-col flex-1">
        {icons ? (
          icons
        ) : (
          <div className="mt-24">
            <ExclamationCircleFilled className="w-10 pb-12 inline-block" />
          </div>
        )}
        <div>{content}</div>
      </div>
    </Modal>
  )
}

export default ModalConfirm
