import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Typography } from 'antd'
import * as yup from 'yup'

import { openNotification } from '../../../components/widgets/Notification'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals'
import { OPERATION_TYPE, RESOURCES_TENANT } from 'configs/constant'
import { StringKeyObject } from 'types/common'

const { Paragraph } = Typography

export type DataForm = {
  departmentName: string
}

type Props = {
  dataUpdate: StringKeyObject | null
  onCancel: () => void
  setIsUpdateSuccess: (data: boolean) => void
  handleUpdate: Function
  departments: StringKeyObject[]
}

const schema = yup
  .object()
  .shape({
    departmentName: yup.string().max(50).required()
  })
  .required()

const UpdateDepartmentModal = ({
  dataUpdate = null,
  onCancel,
  setIsUpdateSuccess,
  handleUpdate,
  departments
}: Props) => {
  const { t } = useTranslation()

  const methods = useForm<DataForm>({
    defaultValues: {
      departmentName: ''
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit, setValue, clearErrors } = methods

  useEffect(() => {
    if (dataUpdate) setValue('departmentName', dataUpdate.value)
    else clearErrors()
  }, [dataUpdate])

  const onSubmit = async (data: DataForm) => {
    onCancel()

    if (
      dataUpdate?.value !== data.departmentName.trim() &&
      departments.find((item) => item.value === data.departmentName.trim())
    ) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: 'errors.duplicateDepartmentName'
      })
      return
    }

    await handleUpdate(
      OPERATION_TYPE.UPDATE,
      RESOURCES_TENANT.DEPARTMENTS,
      data.departmentName.trim(),
      dataUpdate?.key
    )

    setIsUpdateSuccess(true)
  }

  const handleOk = () => {
    //todo
  }

  return (
    <CustomModal open={dataUpdate !== null} onCancel={onCancel} onOk={handleOk}>
      <div className="text-center pt-3 pb-7 px-6">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('editDepartment')}
        </Typography>

        <Paragraph className="mt-12 mb-0">
          {t('content.enterDepartmentNameToChange')}
        </Paragraph>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex className="mt-[130px] gap-2">
              <Col flex="148px">
                <InputLabel
                  label={t('table.departmentName')}
                  className="!py-0"
                />
              </Col>
              <Col flex="auto">
                <InputForm name="departmentName" />
              </Col>
            </Flex>

            <Flex className="justify-center gap-3 mt-[130px]">
              <Button
                type="primary"
                className="min-w-[180px] font-bold"
                htmlType="submit"
              >
                {t('button.keep')}
              </Button>
              <Button
                className="min-w-[180px] border-[#137695] text-[#137695] font-bold"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </div>
    </CustomModal>
  )
}

export default UpdateDepartmentModal
