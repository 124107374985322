import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Col, Empty, Row, Tooltip } from 'antd'

import CreatePositionModal from './CreatePositionModal'
import CreatePositionSuccessModal from './CreatePositionSuccessModal'
import DeletePositionModal from './DeletePositionModal'
import DeletePositionSuccessModal from './DeletePositionSuccessModal'
import UpdatePositionModal from './UpdatePositionModal'
import UpdatePositionSuccessModal from './UpdatePositionSuccessModal'
import { ReactComponent as TrashIcon } from 'assets/imgs/trash.svg'
import { ReactComponent as EditIcon } from 'assets/svgs/pen.svg'
import { StringKeyObject } from 'types/common'

export default function ManagePositions({
  positions,
  taskProcessingTenantAdditionalInfo
}: {
  positions: StringKeyObject[]
  taskProcessingTenantAdditionalInfo: Function
}) {
  const { t } = useTranslation()

  const [createModal, setCreateModal] = useState<boolean>(false)
  const [dataUpdate, setDataUpdate] = useState<StringKeyObject | null>(null)
  const [dataDelete, setDataDelete] = useState<StringKeyObject | null>(null)
  const [isCreateSuccess, setIsCreateSuccess] = useState<boolean>(false)
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false)
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false)

  return (
    <>
      <Row className="text-center py-3 mt-2.5 border-y border-customGray items-center">
        <Col flex="70%">{t('table.positionName')}</Col>
        <Col flex="15%">{t('table.operation')}</Col>
        <Col flex="auto" className="flex justify-end">
          <Button
            autoInsertSpace={false}
            type="primary"
            className="min-w-[100px] font-bold"
            onClick={() => setCreateModal(true)}
          >
            {t('button.register')}
          </Button>
        </Col>
      </Row>
      {positions.map((position) => {
        return (
          <Row
            className="py-2 items-center row-basic-setting"
            key={position.key}
          >
            <Col flex="10%"></Col>
            <Col flex="60%">{position.value}</Col>
            <Col flex="15%" className="flex items-center justify-center gap-10">
              <Tooltip placement="top" title={t('button.edit')}>
                <EditIcon
                  className="cursor-pointer w-4"
                  onClick={() => setDataUpdate(position)}
                />
              </Tooltip>
              <Tooltip placement="top" title={t('button.delete')}>
                <TrashIcon
                  className="cursor-pointer w-4"
                  onClick={() => setDataDelete(position)}
                />
              </Tooltip>
            </Col>
            <Col flex="auto" className="flex justify-end"></Col>
          </Row>
        )
      })}
      {positions.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      <CreatePositionModal
        positions={positions}
        isModalOpen={createModal}
        onCancel={() => setCreateModal(false)}
        onSuccess={taskProcessingTenantAdditionalInfo}
        setIsCreateSuccess={setIsCreateSuccess}
      />
      <UpdatePositionModal
        positions={positions}
        dataUpdate={dataUpdate}
        onCancel={() => setDataUpdate(null)}
        setIsUpdateSuccess={setIsUpdateSuccess}
        handleUpdate={taskProcessingTenantAdditionalInfo}
      />
      <DeletePositionModal
        dataDelete={dataDelete}
        onCancel={() => setDataDelete(null)}
        handleDelete={taskProcessingTenantAdditionalInfo}
        setIsDeleteSuccess={setIsDeleteSuccess}
      />
      <CreatePositionSuccessModal
        isModalOpen={isCreateSuccess}
        onCancel={() => setIsCreateSuccess(false)}
      />
      <UpdatePositionSuccessModal
        isModalOpen={isUpdateSuccess}
        onCancel={() => setIsUpdateSuccess(false)}
      />
      <DeletePositionSuccessModal
        isModalOpen={isDeleteSuccess}
        onCancel={() => setIsDeleteSuccess(false)}
      />
    </>
  )
}
