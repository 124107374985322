import React from 'react'

import { Button, Card, Checkbox, Col, Flex, Radio, Row, Typography } from 'antd'

import eyeInvisible from '../../../assets/svgs/eyeInvisible.svg'
import { useQuestionnaires } from '../../../hooks/useQuestionnaires'
import {
  ChoiceTypeEnums,
  QuestionTypeEnums,
  StatusEnums
} from '../../../models/survey'
import { RadioGroupRequiredForm } from '../SurveyCreateUpdate'
import { MoreOutlined } from '@ant-design/icons'

type Props = {
  fieldName: string
  question: any
  indexActive: number
  index: number
  update?: any
}

export const QuestionItemCard = ({
  fieldName,
  question,
  index,
  indexActive,
  update
}: Props) => {
  const selectedLanguage = localStorage.getItem('i18nextLng')
  const { setHiddenQuestionnaires } = useQuestionnaires()

  const hiddenQuestionnaires = () => {
    question.status = StatusEnums.deleted
    setHiddenQuestionnaires((prevQuests) => [...[question], ...prevQuests])
    update(index, question)
  }

  return (
    <Flex gap="middle" vertical className="mt-3.5">
      <Card size="small" className="questionnaire-item rounded">
        <Row>
          <Col span={1} className="self-center">
            <Button
              className="border-transparent"
              shape="circle"
              icon={
                <MoreOutlined
                  style={{ color: '#BDCBD5', fontSize: 25, fontWeight: 'bold' }}
                  className="text-2xl"
                />
              }
            />
          </Col>
          <Col className="gutter-row" span={1}>
            <div>{indexActive + 1}</div>
          </Col>
          <Col className="gutter-row" span={18}>
            <Flex vertical gap={0} className="mb-2">
              <Typography.Text style={{ color: '#707070' }}>
                {question.displayNameMap.ja}
              </Typography.Text>
              <Typography.Text style={{ color: '#707070' }}>
                {question.displayNameMap.en}
              </Typography.Text>
            </Flex>
            <Flex vertical className="question-preview">
              {question.questionType === QuestionTypeEnums.choice &&
                question.setting?.choiceType !== ChoiceTypeEnums.checkbox && (
                  <Radio.Group onChange={() => ({})} value="">
                    {question.options.map((option: any, idx: number) => (
                      <Radio
                        key={idx}
                        value={option.refId}
                        className="mr-[61px]"
                      >
                        {selectedLanguage === 'ja'
                          ? option.displayNameMap.ja
                          : option.displayNameMap.en}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              {question.questionType === QuestionTypeEnums.choice &&
                question.setting?.choiceType === ChoiceTypeEnums.checkbox && (
                  <Checkbox.Group onChange={() => ({})}>
                    {question.options.map((option: any, idx: number) => (
                      <Checkbox key={idx} value={option.refId}>
                        {option.displayNameMap.ja}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                )}
              {question.questionType === QuestionTypeEnums.text && (
                <>
                  <Typography>テキスト形式</Typography>
                </>
              )}
            </Flex>
          </Col>
          <Col className="gutter-row" span={4}>
            <Flex gap="middle" className="btn-controller float-end">
              <Button
                className="border-transparent"
                shape="circle"
                onClick={() => hiddenQuestionnaires()}
                // icon={<EyeInvisibleOutlined style={{ color: '#BDCBD5' }} className="text-base" />}
                icon={<img src={eyeInvisible} className="text-base" alt="" />}
              />
              <RadioGroupRequiredForm fieldName={`${fieldName}.isRequired`} />
            </Flex>
          </Col>
        </Row>
      </Card>
    </Flex>
  )
}
