import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_COURSE: DocumentNode = gql`
  mutation CopyAvailableSlotsMutation(
    $destinationMedicalCheckupMasterRefId: String!
    $sourceMedicalCheckupMasterRefId: String!
  ) {
    copyAvailableSlots(
      destinationMedicalCheckupMasterRefId: $destinationMedicalCheckupMasterRefId
      sourceMedicalCheckupMasterRefId: $sourceMedicalCheckupMasterRefId
    ) {
      status
    }
  }
`
