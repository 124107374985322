import { getDate } from 'date-fns'

export const MESSAGE_GROUP_MINUTE = 5 * 60 * 1000

export const MESSAGE_TYPE = {
  CHANGE_AVATAR: 'room_changed_avatar',
  ADD_USER: 'au',
  REMOVE_USER: 'ru',
  SUBSCRIPTION_ROLE_ADD: 'subscription-role-added',
  SUBSCRIPTION_ROLE_REMOVE: 'subscription-role-removed',
  RENAME_ROOM: 'r',
  USER_LEAVE_ROOM: 'ul',
  ADD_USER_TO_TEAM: 'add-user-to-team'
}

export function covertMessage(message: any, options: any = {}) {
  const { nextMessage = null } = options
  let showDate = false
  let hideAva = false
  const date = message?.ts?.$date
  const userId = message?.u?._id
  let newNextMessage = null

  if (nextMessage) {
    const {
      created_at: nextDate,
      user_id: nextUserId,
      type: nextType
    } = nextMessage
    const isNextTypeSystem = Object.values(MESSAGE_TYPE).includes(nextType)
    showDate = getDate(date) !== getDate(new Date(nextDate))
    hideAva =
      date - nextDate < MESSAGE_GROUP_MINUTE &&
      userId === nextUserId &&
      !isNextTypeSystem
    if (
      date - nextDate < MESSAGE_GROUP_MINUTE &&
      userId === nextUserId &&
      !isNextTypeSystem
    ) {
      newNextMessage = { ...nextMessage }
      newNextMessage.hideTime = true
    }
  } else {
    showDate = true
    hideAva = false
  }

  let mentions: any = null
  if (message?.mentions?.length) {
    const { mentions: mens } = message
    mentions = {}
    for (let i = 0; i <= mens?.length - 1; i++) {
      if (mens[i]?.username === 'all') {
        mentions[mens[i]?.username] = mens[i]?.username
      } else {
        mentions[mens[i]?.username] = mens[i].name
      }
    }
  }

  let file: any = null
  let image: any = null

  if (message?.attachments?.length) {
    const attachment = message.attachments[0]
    if (attachment.type === 'file' && attachment.image_url) {
      const { image_dimensions, image_preview, image_url, image_type } =
        attachment
      image = {
        image_dimensions,
        preview: `data:${image_type};base64, ${image_preview}`,
        image_url
      }
    }
  }

  // if (message?.file) {
  //   file = {}
  //   file.name = message?.file?.name
  //   file.url = getImageUrl({
  //     userId: message?.u?._id,
  //     rid: message?.rid,
  //     id: message?.file?._id
  //   })
  //   file.linkDownload = file.linkDownload = getUrlDownload({
  //     userId: message?.u?._id,
  //     rid: message?.rid,
  //     id: message?.file?._id,
  //     filename: message?.file?.name
  //   })
  //   file.type = getTypeFile(message?.file?.name)
  // }

  let link

  if (message?.urls?.length) {
    link = {
      content: message?.msg,
      urls: message?.urls
    }
  }

  const newMessage = {
    id: message?._id,
    attachments: message?.attachments,
    content: message?.msg,
    created_at: message?.ts?.$date,
    updated_at: message?._updatedAt?.$date,
    user_id: message?.u?._id,
    user_name: message?.u?.name,
    user: message?.u,
    type: message?.t,
    reactions: message?.reactions,
    alias: message?.alias,
    blocks: message?.blocks,
    mentions,
    showDate,
    hideAva,
    hideTime: false,
    file,
    image,
    link
  }
  return { newMessage, newNextMessage }
}

export function covertMessages(messages: any[], options: any = {}) {
  const {
    initialDate = null,
    initialUserId = null,
    canLoadMore = true,
    canLoadNext = false
  } = options
  const newMessages = messages.map((message, index) => {
    let showDate = false
    let hideAva = false
    let hideTime = false
    const date = message?.ts?.$date
    const userId = message?.u?._id
    const nextDate = messages[index + 1]?.ts?.$date
    const prevDate = index > 0 ? messages[index - 1]?.ts?.$date : null
    const nextUserId = messages[index + 1]?.u?._id
    const prevUserId = index > 0 ? messages[index - 1]?.u?._id : null
    // using for system message of group chat
    const isNextTypeSystem =
      messages[index + 1]?.t &&
      Object.values(MESSAGE_TYPE).includes(messages[index + 1]?.t)

    if (index === 0) {
      if (initialDate) {
        showDate = getDate(new Date(date)) !== getDate(new Date(initialDate))
        hideAva =
          initialDate - date < MESSAGE_GROUP_MINUTE &&
          userId === initialUserId &&
          !isNextTypeSystem
        hideTime =
          initialDate - date < MESSAGE_GROUP_MINUTE &&
          userId === initialUserId &&
          !isNextTypeSystem
      } else {
        showDate = getDate(new Date(date)) !== getDate(new Date(nextDate))
        hideAva =
          date - nextDate < MESSAGE_GROUP_MINUTE &&
          userId === nextUserId &&
          !isNextTypeSystem
        hideTime =
          prevDate - date < MESSAGE_GROUP_MINUTE &&
          userId === prevUserId &&
          !isNextTypeSystem
      }
      if (canLoadNext) {
        showDate = false
        hideTime = false
        hideAva = false
        // FIXME
        // load surround load date
      }
    } else if (index < messages?.length - 1) {
      showDate = getDate(new Date(date)) !== getDate(new Date(nextDate))
      hideAva =
        date - nextDate < MESSAGE_GROUP_MINUTE &&
        userId === nextUserId &&
        !isNextTypeSystem
      hideTime =
        prevDate - date < MESSAGE_GROUP_MINUTE &&
        userId === prevUserId &&
        !isNextTypeSystem
    } else if (index === messages?.length - 1) {
      showDate = !canLoadMore
      hideTime =
        prevDate - date < MESSAGE_GROUP_MINUTE &&
        userId === prevUserId &&
        !isNextTypeSystem
      // Fix me
      hideAva = canLoadMore
    }

    let mentions: any = null
    if (message?.mentions?.length) {
      const { mentions: mens } = message
      mentions = {}
      for (let i = 0; i <= mens?.length - 1; i++) {
        mentions[mens[i]?.username] = mens[i].name
      }
    }

    let file: any = null
    let image: any = null

    if (message?.attachments?.length) {
      const attachment = message.attachments[0]
      if (attachment.type === 'file' && attachment.image_url) {
        const { image_dimensions, image_preview, image_url, image_type } =
          attachment
        image = {
          image_dimensions,
          preview: `data:${image_type};base64, ${image_preview}`,
          image_url
        }
      }
    }

    let link

    if (message?.urls?.length) {
      link = {
        content: message?.msg,
        urls: message?.urls
      }
    }

    return {
      id: message?._id,
      attachments: message?.attachments,
      content: message?.msg,
      created_at: message?.ts?.$date,
      updated_at: message?._updatedAt?.$date,
      user_id: message?.u?._id,
      user_name: message?.u?.name,
      user: message?.u,
      type: message?.t || message?.r?.t,
      reactions: message?.reactions,
      alias: message?.alias,
      blocks: message?.blocks,
      image,
      mentions,
      showDate,
      hideAva,
      hideTime,
      file,
      link
    }
  })

  return newMessages.reverse()
}

export const getInitialData = (messages: any) => {
  let initialDate = null
  let initialUserId = null
  if (messages.length) {
    initialDate = messages[0]?.created_at
    initialUserId = messages[0]?.user_id
  }
  return {
    initialDate,
    initialUserId
  }
}
