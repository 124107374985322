import React, { ReactNode } from 'react'

import { Modal, Row } from 'antd'

type Props = {
  open?: boolean
  children: ReactNode
  width?: number
  onOk?: () => void
  onCancel: () => void
  maskClosable?: boolean
}

const CustomModal = ({
  open = true,
  children,
  width = 600,
  onOk,
  onCancel,
  maskClosable = true,
  ...field
}: Props) => {
  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      className="modal"
      maskClosable={maskClosable}
      width={width}
      style={{ padding: 0 }}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      {...field}
    >
      <Row className="bg-gradient-to-r from-[#347a91] to-[#60acb0] w-full h-4 rounded-t-lg"></Row>
      {children}
    </Modal>
  )
}

export default CustomModal
