import { useLazyQuery, useMutation } from '@apollo/client'
import { AGGREGATION_FILTER } from 'graphql/AggregationFilter/aggregationFilter'
import { CREATE_TEST_RESULT } from 'graphql/TestResult/createTestResult'
import { FILTER_TEST_RESULT } from 'graphql/TestResult/filterTestResult'
import { UPDATE_TEST_RESULT } from 'graphql/TestResult/updateTestResult'
import { StringKeyObject } from 'types/common'
import { Endpoint } from 'utilities/apolloClient'

export const useCreateTestResult = () => {
  const [createTestResultMutation, { data, loading, error }] =
    useMutation(CREATE_TEST_RESULT)

  const createTestResult = async (request: any) => {
    try {
      const result = await createTestResultMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    createTestResult,
    data,
    loading,
    error
  }
}

export const useUpdateTestResult = () => {
  const [updateTestResultMutation, { data, loading, error }] =
    useMutation(UPDATE_TEST_RESULT)

  const updateTestResult = async (request: any) => {
    try {
      const result = await updateTestResultMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    updateTestResult,
    data,
    loading,
    error
  }
}

const useTestResult = () => {
  const [queryTestResult, { loading, error, data }] =
    useLazyQuery(FILTER_TEST_RESULT)
  const [aggregationFilter] = useLazyQuery(AGGREGATION_FILTER)

  const getListTestResult = async (variables: any) => {
    try {
      const result = await queryTestResult({
        variables: {
          filter: '',
          sortBy: '',
          page: 0,
          size: -1,
          ...variables
        },

        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  const getTestResultWithVariables = async (
    variables: StringKeyObject,
    page: number = 0,
    size: number = -1,
    isPayload: boolean = true
  ) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'testResult',
          page: page,
          size: size,
          request: variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      if (!isPayload) {
        const data = result?.data?.commonAggregationFilter?.payload

        return {
          data,
          totalElements: result?.data?.commonAggregationFilter?.totalElements
        }
      }

      return result?.data?.commonAggregationFilter?.payload
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListTestResult,
    getTestResultWithVariables,
    data,
    loading,
    error
  }
}

export default useTestResult
