import { useState } from 'react'

import { useMutation } from '@apollo/client'
import { GENDER, META_DATA_MEDICAL_CHECKUP_MASTER } from 'configs/constant'
import { CREATE_MEDICAL_CHECKUP_MASTER } from 'graphql/MedicalCheckupMaster/createMedicalCheckupMaster'
import { UPDATE_MEDICAL_CHECKUP_MASTER } from 'graphql/MedicalCheckupMaster/updateMedicalCheckupMaster'
import { CourseRegister } from 'hooks/useCourseRegister'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import { handleMetaData } from 'hooks/useProductMaster'
import { StringKeyObject } from 'types/common'
import { Endpoint } from 'utilities/apolloClient'
import { timestampInSeconds, uuid } from 'utilities/helpers'

const useOptionForm = () => {
  const [listMedicalCheckupMaster, setListMedicalCheckupMaster] = useState<
    StringKeyObject[] | undefined
  >(undefined)

  const [createMedicalCheckupMasterMutation, { loading: loadingCreate }] =
    useMutation(CREATE_MEDICAL_CHECKUP_MASTER)
  const [updateMedicalCheckupMasterMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_MEDICAL_CHECKUP_MASTER)
  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()
  const createDataRequest = (
    data: CourseRegister,
    refId?: string,
    associatedTestItemMasters?: string[]
  ) => {
    const objectId = uuid(false)
    let dataCreate = [
      {
        ...(refId && { refId: refId }),
        shortName: data.name.trim(),
        displayName: data.name.trim(),
        associatedTestItemMasters: associatedTestItemMasters
          ? associatedTestItemMasters
          : ['35080906541985107631718955759385'], //hard code
        additionalInfo: {
          objId: objectId,
          metadata: [
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW,
              value: data.overview ?? ''
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.SEX,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.SEX,
              value: data.sex ?? ''
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION,
              value: data.classification ?? ''
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE,
              value: data.startAge
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE,
              value: data.endAge
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES,
              value: data.courseTimes
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER,
              value: data.buffer
            },
            {
              name: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_CD,
              description: META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_CD,
              value: data.courseCd ?? timestampInSeconds()
            }
          ],
          key1: 'option'
        }
      }
    ]
    return dataCreate
  }

  const createMedicalCheckupMasterOption = async (
    data: CourseRegister,
    refId?: string,
    associatedTestItemMasters?: string[]
  ) => {
    const dataRequest = createDataRequest(
      data,
      refId,
      associatedTestItemMasters
    )
    try {
      await createMedicalCheckupMasterMutation({
        variables: { request: dataRequest },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    } catch (error) {}
  }

  const updateMedicalCheckupMasterOption = async (
    data: CourseRegister,
    refId?: string,
    associatedTestItemMasters?: string[]
  ) => {
    const dataRequest = createDataRequest(
      data,
      refId,
      associatedTestItemMasters
    )
    try {
      await updateMedicalCheckupMasterMutation({
        variables: { request: dataRequest },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    } catch (error) {}
  }

  const deleteMedicalCheckupMasterOption = async (request: Object[]) => {
    try {
      await updateMedicalCheckupMasterMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    } catch (error) {}
  }

  const checkDataUpdateWithCourse = async (
    data: CourseRegister,
    optionId: string
  ) => {
    let listCourse = listMedicalCheckupMaster
    if (!listCourse) {
      listCourse = await getMedicalCheckupMaster({
        filter: [
          {
            field: 'data.additionalInfo.key1',
            operator: 'ne',
            value: 'option'
          },
          {
            field: 'data.additionalInfo.isDeleted',
            operator: 'ne',
            value: 'true'
          }
        ],
        sortBy: {
          field: 'createdDate',
          direction: 'desc'
        }
      })

      setListMedicalCheckupMaster(listCourse)
    }

    const dataWithOption =
      listCourse?.filter((item: StringKeyObject) => {
        const metadata =
          item?.medicalCheckupMaster?.additionalInfo?.metadata ?? []
        const itemOption = metadata.find(
          (meta: StringKeyObject) =>
            meta.name === META_DATA_MEDICAL_CHECKUP_MASTER.OPTIONS
        )?.value

        return itemOption?.includes(optionId)
      }) ?? []

    if (dataWithOption?.length === 0) return []

    const errors: StringKeyObject[] = []
    const { sex, classification, startAge = 0, endAge = 999 } = data
    dataWithOption.forEach((course) => {
      const metaDataObj = handleMetaData(
        course.medicalCheckupMaster?.additionalInfo?.metadata ?? []
      )
      const courseSex = metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SEX]
      const courseClassification =
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION]
      const courseStartAge =
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE] ?? 0
      const courseEndAge =
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE] ?? 999

      const isSexMismatch =
        sex !== GENDER.NONE && courseSex !== GENDER.NONE && sex !== courseSex
      const isClassificationMismatch = classification !== courseClassification
      const isAgeOverlap =
        (startAge <= courseStartAge && courseStartAge <= endAge) ||
        (startAge <= courseEndAge && courseEndAge <= endAge) ||
        (courseStartAge <= startAge && endAge <= courseEndAge)

      if (isSexMismatch || isClassificationMismatch || !isAgeOverlap) {
        errors.push(course.medicalCheckupMaster)
      }
    })

    return errors
  }

  return {
    loading: loadingCreate || loadingUpdate,
    createMedicalCheckupMasterOption,
    updateMedicalCheckupMasterOption,
    deleteMedicalCheckupMasterOption,
    checkDataUpdateWithCourse
  }
}

export default useOptionForm
