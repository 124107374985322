import React from 'react'

function ScenarioListLayout({
  header,
  table
}: {
  readonly header: React.ReactNode
  readonly table: React.ReactNode
}) {
  return (
    <div className="min-w-[1500px] h-full">
      {header}
      <div className="mt-2 w-full bg-white rounded-lg shadow-md">{table}</div>
    </div>
  )
}

export default ScenarioListLayout
