import { StringKeyObject } from '../../../../types/common'
import hospitalReservation from 'assets/pngs/hospital.png'
import {
  HEADER_BUTTON_TYPE,
  RESERVATION_TYPE,
  SELECTION_TYPE,
  STEP_SCENARIO
} from 'configs/constant'
import {
  ButtonList,
  Flow,
  HeaderButtonProps,
  ScenarioCreate,
  ScenarioOptionProps
} from 'pages/Scenario/types/types'
import { uuid } from 'utilities/helpers'

export const initialScenarioOptions: ScenarioOptionProps[] = [
  {
    id: 'hospitalReservation',
    title: 'scenarioCreate.options.hospitalReservation.title',
    description: 'scenarioCreate.options.hospitalReservation.description',
    imageSrc: hospitalReservation,
    disabled: true
  }
]

export const initialButtonList: ButtonList[] = [
  { type: 'female', title: '女性', isActivated: false },
  { type: 'male', title: '男性', isActivated: false }
]

export const flowDescription: StringKeyObject = {
  [STEP_SCENARIO.SECOND_STEP]: 'steps.descriptionSecondStep',
  [STEP_SCENARIO.THIRD_STEP]: 'steps.descriptionThirdStep',
  [STEP_SCENARIO.FOURTH_STEP]: 'steps.descriptionFourthStep'
}

export const questions: Flow[] = [
  {
    type: 'fixed',
    selectionType: 'CHOICE',
    order: STEP_SCENARIO.SECOND_STEP,
    step: 'steps.stepSecond',
    content: 'questions.question1',
    options: [],
    setting: {
      choiceType: 'DROPDOWN',
      isMultiple: false
    }
  },
  {
    type: 'fixed',
    selectionType: 'DATE',
    order: STEP_SCENARIO.THIRD_STEP,
    step: 'steps.stepThird',
    content: 'questions.question2',
    options: [],
    setting: {}
  },
  // {
  //   type: 'fixed',
  //   order: STEP_SCENARIO.FOURTH_STEP,
  //   step: '問診回答ボタン',
  //   content:
  //     'ユーザーにコース設定に応じた問診票が 表示されます',
  //   selectionType: 'SURVEY_FEEDBACK',
  //   options: [],
  //   setting: {}
  // },
  {
    type: 'fixed',
    step: 'steps.stepFourth',
    content: 'questions.question3',
    order: STEP_SCENARIO.FOURTH_STEP,
    selectionType: 'BUTTON_REGISTRATION',
    options: [
      {
        order: 0,
        displayName: 'buttons.button1',
        value: 'accept'
      },
      {
        order: 1,
        displayName: 'buttons.button2',
        value: 'changing'
      }
      // {
      //   order: 2,
      //   displayName: 'やっぱりやめる',
      //   value: 'cancel'
      // }
    ],
    setting: {}
  }
]

export const stepEnd: Flow = {
  isActivated: true,
  content: 'ご回答ありがとうございました。',
  step: 'steps.stepEnd',
  setting: {},
  order: STEP_SCENARIO.LAST_STEP,
  selectionType: 'TEXT',
  options: []
}

export const stepFirst: Flow = {
  isActivated: false,
  order: 0,
  content:
    '病院の予約をご希望のお客様は、以下から該当する項目をタップしてください。',
  selectionType: 'BUTTON',
  step: 'steps.stepFirst',
  setting: {},
  options: [
    {
      order: 0,
      displayName: 'buttons.button3',
      value: 'accept'
    },
    {
      order: 1,
      displayName: 'buttons.button4',
      value: 'cancel'
    }
  ]
}

export const initialDataScenarioCreate: ScenarioCreate = {
  name: '',
  categories: ['chat-bot'],
  trigger: 'reservation',
  selectionType: SELECTION_TYPE.BUTTON_REGISTRATION, // current has only BUTTON_REGISTRATION
  status: '',
  flows: [{ ...stepFirst }, ...questions, { ...stepEnd }],
  typeScenario: '',
  reservationType: RESERVATION_TYPE.DESIRE_DATE_REGISTRATION
}

export const initialHeaderButtons: HeaderButtonProps[] = [
  {
    key: uuid(),
    type: 'primary',
    className:
      'min-w-[100px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-sm text-white shadow-none font-bold',
    title: 'scenarioCreate.headerButtons.simulator',
    typeBtn: HEADER_BUTTON_TYPE.SIMULATOR
  },
  {
    key: uuid(),
    type: 'primary',
    className:
      'min-w-[100px] rounded-[3px] border-primary bg-background text-sm text-primary shadow-none hover:!bg-background hover:!text-primary font-bold',
    title: 'scenarioCreate.headerButtons.temporarySave',
    typeBtn: HEADER_BUTTON_TYPE.TEMPORARY_SAVE
  },
  {
    key: uuid(),
    type: 'primary',
    className: 'min-w-[100px] rounded-[3px] shadow-none font-bold',
    title: 'scenarioCreate.headerButtons.save',
    typeBtn: HEADER_BUTTON_TYPE.SAVE
  }
]
