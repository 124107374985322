import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { FILTER_REMAINED_SLOTS_BY_DATE } from 'graphql/ProductManagement/filterRemainedSlotsByDate'
import {
  ConditionsRemainedSlotByDate,
  RemainedSlotByDate
} from 'models/productManagement'
import { Endpoint } from 'utilities/apolloClient'

const useProductManagement = () => {
  const [loading, setLoading] = useState(false)
  const [filterRemainedSlotsByDate] = useLazyQuery(
    FILTER_REMAINED_SLOTS_BY_DATE
  )

  const onFilterRemainedSlotsByDate = async (
    data: ConditionsRemainedSlotByDate
  ) => {
    setLoading(true)
    try {
      const fetchData = await filterRemainedSlotsByDate({
        variables: data,
        context: {
          version: Endpoint.RESERVATION
        },
        fetchPolicy: 'network-only'
      })

      const handledData: RemainedSlotByDate[] =
        fetchData.data.getRemainedAvailableSlotsByDateRange.payload.map(
          (item: any) => {
            return {
              time: item.byhour,
              date: item.bydate,
              emptySlot: item.emptySlotCount,
              remainedSlot: item.remainedSlotCount
            }
          }
        )

      setLoading(false)
      return handledData
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  return { loading, onFilterRemainedSlotsByDate }
}

export default useProductManagement
