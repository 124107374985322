import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery } from '@apollo/client'
import { AGGREGATION_FILTER } from 'graphql/AggregationFilter/aggregationFilter'
import { FILTER_ITEM_CATEGORY } from 'graphql/ItemCategory/filterItemCategory'
import { StringKeyObject } from 'types/common'

const useTestItemCategory = () => {
  const [queryTestItemCategory, { loading, error, data }] =
    useLazyQuery(FILTER_ITEM_CATEGORY)
  const [queryAggregation] = useLazyQuery(AGGREGATION_FILTER)

  const getListItemCategory = async (variables: any) => {
    try {
      const result = await queryTestItemCategory({
        variables: {
          filter: '',
          page: 0,
          size: -1,
          sortBy: '',
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  const getItemCategories = async (
    variables: StringKeyObject[],
    page: number = 0,
    size: number = -1
  ) => {
    try {
      const fetch = await queryAggregation({
        variables: {
          collection: 'testItemCategory',
          page: page,
          size: size,
          request: [
            ...variables,
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      const result = fetch?.data?.commonAggregationFilter?.payload
      if (!Array.isArray(result)) return []
      const data = result.map((item: StringKeyObject) => {
        const {
          _id,
          visibility,
          status,
          _class,
          createdBy,
          createdDate,
          lastModifiedDate,
          ...rest
        } = item?.data ?? {}

        return rest
      })

      return data
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListItemCategory,
    getItemCategories,
    data,
    loading,
    error
  }
}

export default useTestItemCategory
