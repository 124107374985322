import common from './common.json'
import customerManagement from './customerManagement.json'
import inspection from './inspection.json'
import productDuplication from './productDuplication.json'
import productManagement from './productManagement.json'
import productSetting from './productSetting.json'
import questionnaire from './questionnaire.json'
import reservationChange from './reservationChange.json'
import reservationManagement from './reservationManagement.json'
import reservationRegister from './reservationRegister.json'
import basicSetting from 'locales/en/basicSetting.json'
import chat from 'locales/en/chat.json'
import consultant from 'locales/en/consultant.json'
import consultationReport from 'locales/en/consultationReport.json'
import courseRegister from 'locales/en/courseRegister.json'
import customerDetail from 'locales/en/customerDetail.json'
import customerRegister from 'locales/en/customerRegister.json'
import errors from 'locales/en/errors.json'
import patientDetail from 'locales/en/patientDetail.json'
import prefectures from 'locales/en/prefectures.json'
import questionnaireResultCreate from 'locales/en/questionnaireResultCreate.json'
import scenarioData from 'locales/en/scenarioData.json'
import scenarioList from 'locales/en/scenarioList.json'

const listTranslationEn = {
  ...common,
  ...courseRegister,
  ...{ customerManagement },
  ...{ customerDetail },
  ...{ customerRegister },
  ...{ basicSetting },
  ...{ qrc: questionnaireResultCreate },
  ...{ consultant },
  ...questionnaire,
  ...inspection,
  ...errors,
  ...{ reservationManagement },
  ...{ reservationRegister },
  ...{ productManagement },
  ...{ productSetting },
  ...{ reservationChange },
  ...{ productDuplication },
  ...patientDetail,
  ...{ consultationReport },
  prefectures,
  ...chat,
  ...scenarioList,
  ...scenarioData
}

export default listTranslationEn
