import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'
import { META_DATA_MEDICAL_CHECKUP_MASTER } from 'configs/constant'
import { handleMetaData } from 'hooks/useProductMaster'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'

const { Paragraph } = Typography

type Props = {
  product: MedicalCheckupMaster | null
  onCancel: () => void
  onSuccess: () => void
}

const getRow = (label: string, value: string, className: string = '') => {
  return (
    <Row className={`gap-3 text-left ${className}`}>
      <Col flex="148px">
        <Paragraph className="font-bold px-2.5 py-1.5 leading-none bg-[#F0F3F7] mb-0 flex items-center h-full">
          {label}
        </Paragraph>
      </Col>
      <Col flex="auto" className="flex items-center">
        <Paragraph className="mb-0">{value}</Paragraph>
      </Col>
    </Row>
  )
}
const DeleteProductModal = ({ product = null, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation('')

  const metaData = product?.additionalInfo?.metadata ?? []
  const metaDataObj = handleMetaData(metaData)

  const courseTimes = metaDataObj?.[
    META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES
  ]
    ? metaDataObj[META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES] +
      t('lable.minutes')
    : ''
  const buffer = metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER]
    ? metaDataObj[META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER] + t('lable.minutes')
    : ''

  const handleOk = () => {
    //todo
    onCancel()
    onSuccess()
  }

  return (
    <CustomModal
      open={product ? true : false}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Flex vertical className="text-center pt-3 px-11 pb-5 tracking-wider">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('content.deleteCourse')}
        </Typography>
        <ExclamationCircleFilled className="mt-10 m-auto w-10 text-error" />
        <Paragraph className="mt-5 mb-0">
          {t('content.deleteCourseConfirm')}
        </Paragraph>
        <Paragraph className="mb-0">
          {t('content.deleteCourseNotification')}
        </Paragraph>

        {getRow(t('lable.courseName'), product?.displayName ?? '', 'mt-11')}

        {getRow(
          t('table.overview'),
          metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW] ?? '',
          'mt-1.5'
        )}

        {getRow(t('lable.responseTime'), courseTimes, 'mt-1.5')}

        {getRow(t('table.buffer'), buffer, 'mt-1.5')}

        <Flex className="justify-center gap-3 mt-[42px]">
          <Button
            autoInsertSpace={false}
            type="primary"
            className="w-[180px] font-bold bg-error hover:!bg-error"
            htmlType="submit"
            onClick={handleOk}
          >
            {t('button.delete')}
          </Button>
          <Button
            autoInsertSpace={false}
            className="w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default DeleteProductModal
