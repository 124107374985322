import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { PatientInfoProps } from '../types'
import { getMedicalStatusColor, getMedicalStatusText } from '../utils/functions'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import avatar from 'assets/pngs/avatar.png'

const PatientDetails = ({ props }: { props: PatientInfoProps }) => {
  const { t } = useTranslation()

  return (
    <Flex className="flex-col justify-center">
      <Typography className="text-[16px] font-bold">{props.id}</Typography>
      <Flex className="flex-row">
        <Typography className="mr-[70px] text-[16px] font-bold tracking-[1.6px] whitespace-nowrap">
          {props.firstName}
          {props.lastName}
        </Typography>
        <Typography className="mr-[20px] font-medium tracking-[1.4px] whitespace-nowrap">
          {`${dayjs(props.birthday, 'YYYY-MM-DD').format('YYYY/MM/DD')}`}
        </Typography>
        <Typography className="mr-[20px] font-medium tracking-[1.4px] whitespace-nowrap">
          {props.age}
          {t('customerDetail.header.age')}
        </Typography>
        <Typography className="mr-[206px] font-medium tracking-[1.4px] whitespace-nowrap">
          (
          {props.gender === 'MALE'
            ? t('customerDetail.header.male')
            : t('customerDetail.header.female')}
          )
        </Typography>
        <Typography
          style={{ background: getMedicalStatusColor(props.medicalStatus) }}
          className="px-2 text-nowrap mr-[26px] flex h-[26px] items-center justify-center rounded-[23px] bg-[#BDCBD5] text-center text-[14px] tracking-[1.4px] text-white"
        >
          {t(getMedicalStatusText(props.medicalStatus))}
        </Typography>
        <Flex align="center">
          <Typography className="font-medium tracking-[1.4px]">
            {t('customerDetail.header.patientId')}：
          </Typography>
          {props.patientId ? (
            <Typography className="font-medium tracking-[1.4px] whitespace-nowrap mr-[70px]">
              {props.patientId}
            </Typography>
          ) : (
            <div className="ml-[10px] h-[1px] w-[10px] bg-[#545454]" />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const PatientInfo = (props: PatientInfoProps) => {
  return (
    <Flex className="w-max h-[80px rounded-[8px] bg-white px-[22px] text-[14px] text-[#545454] drop-shadow-md">
      <Flex className="mr-[12px] mt-[2px] h-[77px] w-[77px] items-center justify-center">
        <img src={props.avatar || avatar} alt="avatar" />
      </Flex>

      <Flex className="w-full items-center justify-between">
        <PatientDetails props={props} />
      </Flex>

      <Flex className="items-center">
        <ArrowLeft disabled={true} onClick={() => {}} />
        <ArrowRight disabled={true} onClick={() => {}} />
      </Flex>
    </Flex>
  )
}

export default PatientInfo
