import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { useSetRecoilState } from 'recoil'

import { filterInfoAtom, initialFilterInfo } from 'hooks/chat/atoms'

interface ChatLayoutProps {
  readonly filter: React.ReactNode
  readonly rooms: React.ReactNode
  readonly discussion: React.ReactNode
}

function ChatLayout({ filter, rooms, discussion }: ChatLayoutProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const { setValue } = useFormContext()
  const setFilterInfo = useSetRecoilState(filterInfoAtom)

  // listen params change in Chat page
  useEffect(() => {
    const params: any = Object.fromEntries(searchParams.entries())

    if (params.gender) {
      params.gender = params.gender.split(',')
      setValue('gender', params.gender)
    }

    if (params.startAge) {
      params.startAge = Number(params['startAge'])
      setValue('startAge', params.startAge)
    }

    if (params.startAge) {
      params.startAge = Number(params['startAge'])
      setValue('endAge', params.endAge)
    }

    if (params.fullName) {
      setValue('fullName', params.fullName)
    }

    setFilterInfo(params)

    return () => {
      setFilterInfo(initialFilterInfo)
    }
  }, [searchParams])

  return (
    <div>
      <div>{filter}</div>
      <div className="flex w-full h-[calc(100vh-70px)]">
        <div className="flex-[1] mt-3 bg-white p-1 rounded-[4px] shadow-md">
          {rooms}
        </div>
        <div className="flex-[3] mt-3 ml-2 bg-white p-1 rounded-[4px] shadow-md">
          {discussion}
        </div>
      </div>
    </div>
  )
}

export default ChatLayout
