import { SetURLSearchParams } from 'react-router-dom'

import { t } from 'i18next'
import { SetterOrUpdater } from 'recoil'

import { calculateAge } from './common'
import { formatJapaneseDate, uuid } from './helpers'
import { openNotification } from 'components/widgets/Notification'
import { STORAGE_KEYS, TAB_FILTER_MESSAGES, statusRoom } from 'configs/constant'
import {
  subscriptionUserActivity,
  unsubscribeRocketChat
} from 'configs/rocketChat'
import {
  FilterDataType,
  GetMessagesParams,
  RoomsAtomState,
  TypingMessage
} from 'hooks/chat/types'
import { ItemsType } from 'hooks/tab/useTabFilterMessages'
import {
  LoadMoreMessages,
  RoomsDataPayload,
  Subscription
} from 'pages/Chat/types/types'
import { ChatMessageType } from 'pages/CustomerDetail/components/controlPanel/ChatContainer'
import {
  ResponseMessageInRoom,
  TableSearchSlotReservation
} from 'pages/CustomerDetail/types/types'
import chatMessageApi from 'services/chatMessageApi'

export const applyFilterForRooms = (
  rooms: RoomsDataPayload[],
  filterInfo: FilterDataType
) => {
  let roomsFiltered: RoomsDataPayload[] = [...rooms]

  for (const key in filterInfo) {
    roomsFiltered = applyCaseForFilter(
      roomsFiltered,
      filterInfo,
      key as keyof FilterDataType
    )
  }

  return roomsFiltered
}

export const applyCaseForFilter = (
  rooms: RoomsDataPayload[],
  filterInfo: FilterDataType,
  key: keyof FilterDataType
) => {
  switch (key) {
    case 'fullName':
      return filterRoomsByIdOrName(rooms, filterInfo.fullName.trim())
    case 'gender':
      return filterRoomsByGender(rooms, filterInfo.gender)
    case 'startAge':
    case 'endAge':
      return filterRoomsByAge(rooms, {
        from: filterInfo.startAge ?? undefined,
        to: filterInfo.endAge ?? undefined
      })
    default:
      return rooms
  }
}

export const filterRoomsByIdOrName = (
  rooms?: RoomsDataPayload[],
  userName?: string
) => {
  if (!rooms || !userName) return rooms ?? []

  return rooms.filter((room) =>
    room.subscriptions?.some(
      (sub) =>
        sub?.user?.name?.toLowerCase().includes(userName.toLowerCase()) ||
        sub?.user?.refId?.includes(userName)
    )
  )
}

export const filterRoomsByGender = (
  rooms?: RoomsDataPayload[],
  genders?: string[]
) => {
  if (!rooms || !genders?.length) return rooms ?? []

  const { adminUserId } = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
  )

  return rooms.filter((room) =>
    room.subscriptions?.some((sub) => {
      return (
        sub.user._id !== adminUserId &&
        sub?.user?.gender &&
        genders.includes(sub?.user?.gender)
      )
    })
  )
}

export const filterRoomsByAge = (
  rooms?: RoomsDataPayload[],
  age?: { from?: number; to?: number }
) => {
  const ageTemp = age ?? {}

  if (!rooms || !Object.keys(ageTemp).length) return rooms ?? []

  const { adminUserId } = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
  )

  const { from, to } = ageTemp

  if (!from && !to) return rooms

  const minAge = Number(from)
  const maxAge = Number(to)

  return rooms.filter((room) =>
    room.subscriptions?.some((sub) => {
      if (sub.user._id === adminUserId) return false

      const userAge = calculateAge(sub.user.birthday)

      if (!maxAge) return userAge >= minAge
      if (!minAge) return userAge <= maxAge

      return userAge >= minAge && userAge <= maxAge
    })
  )
}

export const sortRoomsByTab = (
  rooms: RoomsDataPayload[],
  tabs: ItemsType[]
) => {
  const tabActivated = tabs.find((tab) => tab.isActive)

  if (tabActivated?.id === TAB_FILTER_MESSAGES.RESERVATION) {
    return rooms.filter((room) => room.customFields?.status === statusRoom.OPEN)
  }

  if (tabActivated?.id === TAB_FILTER_MESSAGES.SUPPORT) {
    return rooms.filter((room) => room.customFields?.status === statusRoom.END)
  }

  return rooms
}

export const getUserInfoInRoom = (
  userId: string | undefined,
  subscribers: Subscription[] | undefined
) => {
  if (!userId || !subscribers) return null

  const subscriber = subscribers.find((sub) => sub.user?._id === userId)
  return subscriber
}

export const handleGetMessages = async (params: GetMessagesParams) => {
  const {
    roomId,
    timestamp,
    loadMoreMessagesState,
    setLoadMoreMessagesState,
    fetchMessagesInRoom,
    convertMessage,
    setMessageList,
    isUpdate = false
  } = params

  try {
    if (isUpdate && loadMoreMessagesState.isLoadedFull) {
      return
    }

    updateLoadingState(setLoadMoreMessagesState, isUpdate)

    // fetch messages
    const messages = await fetchAndParseMessages(
      roomId,
      timestamp,
      fetchMessagesInRoom
    ).finally(() => {
      setLoadMoreMessagesState((prev) => {
        return {
          ...prev,
          isLoading: false
        }
      })
    })

    if (!messages) return

    // convert messages to format message
    const convertedMessages = convertMessage(messages)

    if (isUpdate) {
      handleUpdateMessages(
        convertedMessages,
        setLoadMoreMessagesState,
        setMessageList
      )
    } else {
      handleInitialMessages(
        convertedMessages,
        setLoadMoreMessagesState,
        setMessageList
      )
    }
  } catch (error) {
    console.error('Error fetching messages:', error)
  }
}

const updateLoadingState = (
  setLoadMoreMessagesState: (
    value: React.SetStateAction<LoadMoreMessages>
  ) => void,
  isUpdate: boolean
) => {
  setLoadMoreMessagesState((prev) => {
    if (isUpdate) {
      return {
        ...prev,
        isLoadingLoadMore: true
      }
    } else {
      return {
        ...prev,
        isLoading: true,
        isLoadingLoadMore: false,
        isLoadedFull: false,
        timestamp: '0'
      }
    }
  })
}

const fetchAndParseMessages = async (
  roomId: string,
  timestamp: number | null,
  fetchMessagesInRoom: (
    roomId: string,
    timestamp: number | null
  ) => Promise<ResponseMessageInRoom>
) => {
  try {
    const response = await fetchMessagesInRoom(roomId, timestamp)
    const { result } = JSON.parse(response?.message)
    return result?.messages
  } catch (error: any) {
    openNotification({
      type: 'error',
      title: t('commonError'),
      message: ''
    })
    return null
  }
}

const handleUpdateMessages = (
  convertedMessages: ChatMessageType[],
  setLoadingMessagesState: (
    value: React.SetStateAction<LoadMoreMessages>
  ) => void,
  setMessageList: (value: React.SetStateAction<ChatMessageType[]>) => void
) => {
  if (convertedMessages.length === 0) {
    setLoadingMessagesState((prev) => ({
      ...prev,
      isLoadingLoadMore: false,
      isLoadedFull: true
    }))
  }

  setMessageList((prev) => {
    const messagesCurrent = [...prev]
    const newMessages = [...messagesCurrent, ...convertedMessages]
    return [...newMessages]
  })
}

const handleInitialMessages = (
  convertedMessages: ChatMessageType[],
  setLoadingMessagesState: (
    value: React.SetStateAction<LoadMoreMessages>
  ) => void,
  setMessageList: (value: React.SetStateAction<ChatMessageType[]>) => void
) => {
  setMessageList([...convertedMessages])
  setLoadingMessagesState((prev) => ({
    ...prev,
    isLoading: false
  }))
}

export const updatedUnreadSubscriptions = (
  subscriptions: Subscription[],
  adminUserId: string
) => {
  return subscriptions.map((subscription) => {
    if (subscription?.user?._id === adminUserId) {
      return {
        ...subscription,
        unread: 0
      }
    }

    return subscription
  })
}

export const readMessages = (
  roomId: string,
  roomsSorted: RoomsDataPayload[],
  setRoomsHistoryList: SetterOrUpdater<RoomsAtomState>
) => {
  const { adminUserId } = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
  )

  // call api to read messages
  chatMessageApi.readMessages({ roomId })

  const updatedRooms = roomsSorted.map((room) =>
    room._id === roomId
      ? {
          ...room,
          subscriptions: updatedUnreadSubscriptions(
            room.subscriptions,
            adminUserId
          )
        }
      : room
  )

  setRoomsHistoryList((prev) => ({
    ...prev,
    rooms: updatedRooms
  }))
}

export const cleanupPreviousRoom = (
  rId: string,
  setTypingMessage: (value: React.SetStateAction<TypingMessage>) => void
) => {
  unsubscribeRocketChat(rId)
  setTypingMessage({ userName: '', isTyping: false })
}

export const initializeNewRoom = (
  roomId: string,
  roomsSorted: RoomsDataPayload[],
  setRoomsHistoryList: SetterOrUpdater<RoomsAtomState>,
  setSearchParams: SetURLSearchParams
) => {
  subscriptionUserActivity(roomId)
  setSearchParams((prev) => {
    const params = new URLSearchParams(prev)
    params.set('rId', roomId)
    return params
  })
  readMessages(roomId, roomsSorted, setRoomsHistoryList)
}

export const onSendMessage = async (
  rId: string,
  message: any,
  attachments?: any
) => {
  if (!attachments) {
    return await chatMessageApi.sendDirectMessages({
      id: uuid(),
      roomId: rId,
      message
    })
  }
  return await chatMessageApi.sendMessage({
    roomId: rId,
    message,
    attachments
  })
}

export const onSendFileMessage = async (rId: string, formFileData: any) => {
  return await chatMessageApi.sendFileMessage({
    roomId: rId,
    formFileData
  })
}

export const getFieldValue = (
  name: string,
  tableSearchSlotReservation: TableSearchSlotReservation
) => {
  if (name === 'courseSearch') {
    return tableSearchSlotReservation.course.courseName
  }

  if (name === 'optionSearch') {
    return tableSearchSlotReservation.option.optionName
  }
  return null
}

export const filterRooms = (
  filterInfo: FilterDataType,
  roomHistory: RoomsAtomState
) => {
  const roomsAppliedFilter = applyFilterForRooms(
    roomHistory.rooms ?? [],
    filterInfo
  )

  return roomsAppliedFilter
}

export const convertTimePicker = (date: string, time: string, lang: string) => {
  if (!date || !time) return ''

  const timeTemp = time.includes('.') ? t(time) : time
  const dateTemp = date ? formatJapaneseDate(date, undefined, lang) : ''

  return `${dateTemp} ${timeTemp}`.trim()
}
