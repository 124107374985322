import { formatJapaneseDate, uuid } from './helpers'
import { isArray } from '@apollo/client/utilities'
import { CourseResponse } from 'pages/CustomerDetail/types/types'
import { ScenariosPayload } from 'pages/Scenario/types/types'

/**
 * Add white space for time
 * @param time -  time YYYY/MM/DD HH:mm
 */
export const addWhiteSpaceForTime = (time: string) => {
  return time.replace(' ', '　')
}

export const convertScenariosData = (
  data: ScenariosPayload[],
  limit: number,
  pageCurrent: number
) => {
  return data.map((item: any, index: number) => {
    return {
      ...item,
      refId: item.refId,
      no: pageCurrent * limit + index + 1,
      key: item.refId,
      name: item.name,
      chatCategory: '病院予約',
      trigger: item.trigger,
      selectionType: item.selectionType,
      isActive: item.status === 'ACTIVATE',
      createdAt: addWhiteSpaceForTime(
        formatJapaneseDate(item.createdDate, 'YYYY/MM/DD HH:mm')
      ),
      updatedAt: addWhiteSpaceForTime(
        formatJapaneseDate(item.modifiedDate, 'YYYY/MM/DD HH:mm')
      ),
      action: item.refId
    }
  })
}

export const convertCourseOptions = (data: CourseResponse[]) => {
  if (!isArray(data) || data.length === 0) return []

  return data.map((item: CourseResponse) => {
    return {
      key: uuid(),
      label:
        item?.medicalCheckupMaster?.displayName ??
        item?.medicalCheckupMaster?.shortName,
      value: item?.medicalCheckupMaster?.refId
    }
  })
}
