import { Draggable, Droppable } from 'react-beautiful-dnd'

import { List } from 'antd'

import { LIST_DRAG_AND_DROP } from 'configs/constant'
import cn from 'utilities/cn'
import Item from './Item'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'

const RenderItems = ({
  items,
  parentRefId,
  position,
  className,
  showCode,
  showIcon,
  allowCheckbox = false,
  onChecked
}: {
  items: DataItemMaster[]
  parentRefId: string
  position: string
  className?: string
  showCode?: boolean
  showIcon?: boolean
  allowCheckbox?: boolean
  onChecked?: (item: DataItemMaster, checked: boolean) => void
}) => {
  return (
    <Droppable
      droppableId={`${position}${LIST_DRAG_AND_DROP.SEPARATOR}${parentRefId}`}
      type={LIST_DRAG_AND_DROP.ITEM}
    >
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={cn('flex flex-col', className)}
        >
          {items.length ? items?.map((item, index) => (
            <Draggable key={item.refId} draggableId={item.refId} index={index}>
              {(provided) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <List.Item
                    className={cn(
                      'border border-[#D9E0E5] bg-white min-h-[30px] !py-0'
                    )}
                  >
                    <Item
                      item={item}
                      showCode={showCode}
                      showIcon={showIcon}
                      allowCheckbox={allowCheckbox}
                      onChecked={onChecked}
                    />
                  </List.Item>
                </div>
              )}
            </Draggable>
          )): <span className='p-1'></span>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default RenderItems
