import React from 'react'

import { MEDICAL_CHECKUP_MASTER_EXAMPLE } from 'configs/api'
import { LIST_DRAG_AND_DROP } from 'configs/constant'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useTestItemMaster from 'hooks/useTestItemMaster'
import { getLastPart } from 'utilities/helpers'
import { categoryUpdate, initialListCategory } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'
import {
  DataCategory,
  FilterTestItemCategory
} from '../types/filterTestItemCategory'
import { MedicalCheckupMaster } from '../types/medicalCheckupMaster'
import useQueryForInspectionSetting from './useQueryForInspectionSetting'

const useInspectionSetting = () => {
  const [isFetching, setIsFetching] = React.useState(true)
  const [
    {
      originalListCategory,
      originalListLanguage,
      originalMedicalCheckupMaster
    },
    setOriginalData
  ] = React.useState<{
    originalListCategory: DataCategory[]
    originalListLanguage: DataItemMaster[]
    originalMedicalCheckupMaster: MedicalCheckupMaster | undefined
  }>({
    originalListCategory: [],
    originalListLanguage: [],
    originalMedicalCheckupMaster: undefined
  })

  const [
    {
      initialShowCategoryRefIds,
      initialHiddenCategoryRefIds,
      initialShowItemsMasterRefIds,
      initialHiddenItemsMasterRefIds
    },
    setInitialItems
  ] = React.useState<{
    initialShowCategoryRefIds: string[]
    initialHiddenCategoryRefIds: string[]
    initialShowItemsMasterRefIds: string[]
    initialHiddenItemsMasterRefIds: string[]
  }>({
    initialShowCategoryRefIds: [],
    initialHiddenCategoryRefIds: [],
    initialShowItemsMasterRefIds: [],
    initialHiddenItemsMasterRefIds: []
  })

  const [{ listCategory, listItemMaster }, setListData] = React.useState<{
    listCategory: DataItemMaster[]
    listItemMaster: DataItemMaster[]
  }>({
    listCategory: [],
    listItemMaster: []
  })

  const [categoryShow, setCategoryShow] = React.useState<DataItemMaster[]>([])
  const [categoryHidden, setCategoryHidden] = React.useState<DataItemMaster[]>(
    []
  )
  const [searchTerm, setSearchTerm] = React.useState('')

  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  const { getListItemCategory: getAllListItemCategory } =
    useQueryForInspectionSetting()

  const fetchData = React.useCallback(async () => {
    if (!isFetching) return
    const dataMedicalCheckupMaster: MedicalCheckupMaster | undefined =
      await getMedicalCheckupMastersById(MEDICAL_CHECKUP_MASTER_EXAMPLE)

    const listItemsCategory: { data: FilterTestItemCategory } | undefined =
      await getAllListItemCategory({
        filter: '(eq,STRING,status,PUBLISHED)'
      })

    const listLanguage = (await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'additionalInfo.key3',
              operator: 'ne',
              value: '所見コメント'
            }
          ]
        }
      }
    ])) as DataItemMaster[]
    setOriginalData({
      originalListCategory:
        listItemsCategory?.data?.filterTestItemCategory?.payload ?? [],
      originalListLanguage: listLanguage,
      originalMedicalCheckupMaster: dataMedicalCheckupMaster
    })
    setIsFetching(false)
  }, [isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // call api get data for first time

    fetchData()
  }, [fetchData])

  // handle logic to update item master with medical checkup master
  const updateItemMasterWithMedicalCheckupMaster = (
    refIds: string[],
    originalListLanguage: DataItemMaster[],
    objId: string
  ) => {
    const listUpdate = refIds.map((refId) => {
      // find item master by refId
      const item = originalListLanguage.find((i) => i.refId === refId)
      const localizedName = item?.additionalInfo.localizedName ?? item?.lang
      return {
        refId: item?.refId ?? '',
        displayName: item?.displayName ?? '',
        referenceValues:
          (item?.referenceValues ?? []).map((it) => {
            const { _class, ...rest } = it || {}
            return rest
          }) ?? [],
        additionalInfo: {
          ...item?.additionalInfo,
          medicalCheckupMasterObjId: objId,
          localizedName
        }
      }
    })
    return listUpdate
  }

  // handle update list category and item master from originalListLanguage
  React.useEffect(() => {
    if (originalListLanguage?.length) {
      const newListCategory = originalListCategory?.map((category) => {
        // find category in list category has key1 = 'カデコマスタ'
        const findCategoryItem = originalListLanguage.find((item) =>
          (category?.associatedTestItemMasterRefIds || []).some(
            (refId) =>
              refId === item.refId &&
              item.additionalInfo.key1 === 'カテゴリマスタ'
          )
        )

        return {
          ...findCategoryItem,
          ...category,
          itemId: findCategoryItem?.refId, // id of item belong to category
          items: originalListLanguage.filter(
            (it) =>
              category.associatedTestItemMasterRefIds?.some(
                (refId) => refId === it.refId
              ) && it.additionalInfo.key1 === '検査項目マスター'
          ) // get list item belong to category
        }
      })

      // filter list item master has key1 = '検査項目マスター'
      const newListItemMaster = originalListLanguage.filter(
        (item) => item.additionalInfo.key1 === '検査項目マスター'
      )

      setListData({
        listCategory: newListCategory,
        listItemMaster: newListItemMaster
      })
    }
  }, [originalListCategory, originalListLanguage])

  const handleInitialDataShowCategory = React.useCallback(() => {
    if (
      originalMedicalCheckupMaster &&
      listCategory?.length &&
      originalListLanguage?.length
    ) {
      if (categoryShow.length > 0) {
        const categoryShowUpdate = categoryUpdate(
          [...categoryShow],
          originalListLanguage
        )
        const categoryHiddenUpdate = categoryUpdate(
          [...categoryHidden],
          originalListLanguage
        )
        setCategoryShow(categoryShowUpdate)
        setCategoryHidden(categoryHiddenUpdate)
      } else {
        const { categories = [], excludedCategories = [] } =
          originalMedicalCheckupMaster?.medicalCheckupMaster?.additionalInfo ||
          {}
        const categoryShow = initialListCategory(
          categories,
          listCategory,
          originalListLanguage
        )
        const categoryHidden = initialListCategory(
          excludedCategories,
          listCategory,
          originalListLanguage
        )
        setCategoryShow(categoryShow)
        setCategoryHidden(categoryHidden)
      }
    }
  }, [listCategory, originalMedicalCheckupMaster, originalListLanguage])

  React.useEffect(() => {
    handleInitialDataShowCategory()
  }, [handleInitialDataShowCategory])

  React.useEffect(() => {
    const safeCategoryShow = Array.isArray(categoryShow) ? categoryShow : []
    const safeCategoryHidden = Array.isArray(categoryHidden)
      ? categoryHidden
      : []

    setInitialItems({
      initialShowItemsMasterRefIds: [
        ...safeCategoryShow.reduce((acc: string[], item: DataItemMaster) => {
          return [...acc, ...(item?.items || []).map((i) => i.refId)]
        }, [])
      ],
      initialHiddenItemsMasterRefIds: [
        ...safeCategoryHidden.reduce((acc: string[], item: DataItemMaster) => {
          return [...acc, ...(item.items || []).map((i) => i.refId)]
        }, [])
      ],
      initialShowCategoryRefIds: [
        ...safeCategoryShow.map((item) => item.refId)
      ],
      initialHiddenCategoryRefIds: [
        ...safeCategoryHidden.map((item) => item.refId)
      ]
    })
  }, [categoryShow, categoryHidden])

  const onDragEnd = (result: any) => {
    const { destination, source, type } = result

    if (!destination) return
    const isStartList1 = source.droppableId.startsWith(LIST_DRAG_AND_DROP.LIST1)
    const isEndList1 = destination.droppableId.startsWith(
      LIST_DRAG_AND_DROP.LIST1
    )
    const listStart = isStartList1 ? categoryShow : categoryHidden
    const setListStart = isStartList1 ? setCategoryShow : setCategoryHidden

    if (
      destination.droppableId === source.droppableId ||
      isStartList1 === isEndList1
    ) {
      if (type === LIST_DRAG_AND_DROP.CATEGORY) {
        reOrderInList(listStart, setListStart, result)
        return
      }

      if (type === LIST_DRAG_AND_DROP.ITEM) {
        reOrderInCategory(listStart, setListStart, result)
        return
      }
    }

    const listEnd = (isEndList1 ? categoryShow : categoryHidden) ?? []
    const setListEnd = isEndList1 ? setCategoryShow : setCategoryHidden

    const listStartUpdate = [...listStart]
    const listEndUpdate = [...listEnd]

    if (type === LIST_DRAG_AND_DROP.CATEGORY) {
      const [removed] = listStartUpdate.splice(source.index, 1)

      listEndUpdate.splice(destination.index, 0, removed)

      setListStart(listStartUpdate)
      setListEnd(listEndUpdate)

      return
    }

    if (type === LIST_DRAG_AND_DROP.ITEM) {
      const sourceDroppableId = getLastPart(
        source.droppableId,
        LIST_DRAG_AND_DROP.SEPARATOR
      )
      const destinationDroppableId = getLastPart(
        destination.droppableId,
        LIST_DRAG_AND_DROP.SEPARATOR
      )

      const categoryStart = listStartUpdate.find(
        (item: any) => item.refId === sourceDroppableId
      )
      const [itemStart] = categoryStart?.items?.splice(source.index, 1) || []

      const categoryEnd = listEndUpdate.find(
        (item: any) => item.refId === destinationDroppableId
      )

      categoryEnd?.items?.splice(destination.index, 0, itemStart)

      setListStart(listStartUpdate)
      setListEnd(listEndUpdate)
    }
  }

  const reOrderInList = (category: any, setCategory: any, result: any) => {
    const { destination, source } = result

    const categoryUpdate = [...category]

    if (destination.index === source.index) {
      return
    }

    const [removed] = categoryUpdate.splice(source.index, 1)

    categoryUpdate.splice(destination.index, 0, removed)

    setCategory(categoryUpdate)
  }

  const reOrderInCategory = (category: any, setCategory: any, result: any) => {
    const { destination, source } = result

    const categoryUpdate = [...category]

    const sourceDroppableId = getLastPart(
      source.droppableId,
      LIST_DRAG_AND_DROP.SEPARATOR
    )
    const destinationDroppableId = getLastPart(
      destination.droppableId,
      LIST_DRAG_AND_DROP.SEPARATOR
    )

    const categoryStart = categoryUpdate.find(
      (item: any) => item.refId === sourceDroppableId
    )
    const [itemStart] = categoryStart.items.splice(source.index, 1)

    const categoryEnd = categoryUpdate.find(
      (item: any) => item.refId === destinationDroppableId
    )

    categoryEnd.items.splice(destination.index, 0, itemStart)

    setCategory(categoryUpdate)
  }

  return {
    isFetching,
    setIsFetching,
    // state and setter for original data
    originalListCategory,
    originalListLanguage,
    originalMedicalCheckupMaster,
    setOriginalData,
    // state and setter for list data
    listCategory,
    listItemMaster,
    setListData,
    //
    initialShowCategoryRefIds,
    initialHiddenCategoryRefIds,
    initialShowItemsMasterRefIds,
    initialHiddenItemsMasterRefIds,
    setInitialItems,
    //
    categoryShow,
    setCategoryShow,
    categoryHidden,
    setCategoryHidden,
    //
    onDragEnd,
    reOrderInList,
    reOrderInCategory,
    //
    updateItemMasterWithMedicalCheckupMaster,
    //
    handleInitialDataShowCategory,
    //
    searchTerm,
    setSearchTerm
  }
}

export default useInspectionSetting
