import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useRecoilState, useSetRecoilState } from 'recoil'
import * as yup from 'yup'

import useBasicSetting from '../../../hooks/useBasicSetting'
import { Flow, ScenarioCreate as ScenarioCreateAtomType } from '../types/types'
import {
  scenarioCreateAtom,
  simulatorModalAtom
} from './atoms/create/scenarioCreateAtom'
import ScenarioCreateContent from './components/content/ScenarioCreateContent'
import SupportDrawer from './components/drawer/SupportDrawer'
import ScenarioCreateHeaders from './components/headers/ScenarioCreateHeaders'
import ModalCreateScenario from './components/modal/ModalCreateScenario'
import ModalCreateScenarioContent from './components/modal/ModalCreateScenarioContent'
import ChatModalSimulator from './components/modal/ModalSimulator'
import { initialDataScenarioCreate } from './data/data'
import { yupResolver } from '@hookform/resolvers/yup'
import SpinLoading from 'components/screens/Spin/SpinLoading'
import { DRAWER_DATA_TYPE, MODAL_TYPE, STEP_SCENARIO } from 'configs/constant'
import useDrawer from 'hooks/scenario/useDrawer'
import useModal from 'hooks/scenario/useModal'
import CreateScenarioLayout from 'layouts/Scenario/CreateScenarioLayout/CreateScenarioLayout'
import { getScenarioDetail } from 'services/ScenarioService'

const schema = yup.object({
  [DRAWER_DATA_TYPE.TEMPLATE_NAME]: yup
    .string()
    .max(50)
    .required('templateName is required'),
  [DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST]: yup
    .string()
    .max(255)
    .required('description is required'),
  [DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST]: yup
    .string()
    .required('description is required')
})

function ScenarioCreate() {
  const methods = useForm({
    resolver: yupResolver(schema)
  })

  const setScenarioCreate = useSetRecoilState(scenarioCreateAtom)
  const [simulatorModal, setSimulatorModal] = useRecoilState(simulatorModalAtom)
  const { tenant } = useBasicSetting()

  const { modal, onCloseModal, onOpenModal } = useModal()
  let { refId } = useParams()

  const { drawerData } = useDrawer()

  const [isLoading, setIsLoading] = useState(false)

  // for edit scenario
  useEffect(() => {
    const addFlowTypes = (flows: Flow[]) => {
      const flowsTemp = [...flows]

      return flowsTemp.map((flow) => {
        const isQuestionsFixed =
          flow.order > STEP_SCENARIO.FIRST_STEP &&
          flow.order < STEP_SCENARIO.LAST_STEP

        if (flow.order === STEP_SCENARIO.FIRST_STEP) {
          return {
            ...flow,
            isActivated: true,
            options: flow.options?.map((option, index) => {
              return {
                ...option,
                isActivated: index === 0
              }
            })
          }
        }

        return isQuestionsFixed
          ? { ...flow, type: 'fixed', isActivated: true }
          : { ...flow, isActivated: true }
      })
    }

    const fetchScenarioDetail = async ({ refId }: { refId: string }) => {
      try {
        setIsLoading(true)
        const [payload]: ScenarioCreateAtomType[] = await getScenarioDetail({
          refId
        })

        const updatedFlows = addFlowTypes(payload.flows)

        setScenarioCreate({ ...payload, flows: updatedFlows })
      } finally {
        setIsLoading(false)
      }
    }

    if (refId) {
      fetchScenarioDetail({ refId })
      onCloseModal(MODAL_TYPE.CHOOSE_TYPE_SCENARIO)
    } else {
      onOpenModal(MODAL_TYPE.CHOOSE_TYPE_SCENARIO)
    }

    return () => {
      setScenarioCreate(initialDataScenarioCreate)
      onCloseModal(MODAL_TYPE.CHOOSE_TYPE_SCENARIO)
    }
  }, [refId])

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-white/50 translate-x-12">
          <SpinLoading loading={isLoading} />
        </div>
      ) : (
        <CreateScenarioLayout
          headers={<ScenarioCreateHeaders />}
          content={<ScenarioCreateContent />}
        />
      )}

      <ModalCreateScenario open={modal.chooseTypeScenario} width={650}>
        <ModalCreateScenarioContent onCloseModal={onCloseModal} />
      </ModalCreateScenario>

      {simulatorModal.open && (
        <ChatModalSimulator
          isOpen={simulatorModal.open}
          onCancel={() => setSimulatorModal({ open: false })}
          tenant={tenant}
        />
      )}

      <SupportDrawer data={drawerData} />
    </FormProvider>
  )
}

export default ScenarioCreate
