import React from 'react'

interface DateDividerProps {
  date: string
}

const DateDivider: React.FC<DateDividerProps> = ({ date }) => {
  return (
    <div className="flex items-center my-4">
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="mx-4 text-[#C6C6C6] text-[10px]">{date}</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  )
}

export default DateDivider
