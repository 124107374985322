import { DocumentNode } from 'graphql'

import { gql } from '@apollo/client'

export const FILTER_RESERVATION_HISTORY: DocumentNode = gql`
  query filterReservation(
    $filter: String!
    $page: Int!
    $size: Int!
    $sortBy: String
  ) {
    filterReservation(
      filter: $filter
      paginationInput: { page: $page, size: $size }
      sortBy: $sortBy
    ) {
      currentPageNumber
      message
      status
      timestamp
      totalElements
      totalPages
      payload {
        refId
        reservationDate
        reservationStatus
        reservationTime
        medicalCheckupMasterRefId
        optionRefIds
        checkupUserRefId
        modifiedDate
      }
    }
  }
`

export const FILTER_MEDICAL_CHECKUP_MASTER_DETAILS: DocumentNode = gql`
  query filterMedicalCheckupMasterDetails(
    $filter: String!
    $page: Int!
    $size: Int!
  ) {
    filterMedicalCheckupMasterDetails(
      page: $page
      size: $size
      filter: $filter
    ) {
      status
      message
      timestamp
      totalElements
      totalPages
      currentPageNumber
      payload {
        medicalCheckupMaster {
          refId
          shortName
          displayName
          associatedTestItemMasters
          associatedPatientInfoMasters
          additionalInfo
          createdDate
          createdBy
        }
        testItemCategory {
          refId
          shortName
          associatedTestItemMasterRefIds
        }
        patientInfoMasters {
          refId
          fieldName
          fieldType
          visibility {
            tenantCodes
            subTenantCodes
          }
        }
      }
    }
  }
`
