import React, { useEffect, useState } from 'react'

import { List } from 'antd'

import Typography from 'antd/es/typography/Typography'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import useDebounce from 'hooks/useDebounce'
import { searchValues, showName } from '../helper'
import styles from '../InspectionItemSetting.module.scss'

const ItemSettingCategoryList = ({
  category,
  language,
  searchTerm
}: {
  category: any
  language: any
  searchTerm?: string
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [listCategory, setListCategory] = useState(category)

  React.useEffect(() => {
    setListCategory(category)
  }, [category])

  const searchTermDebounced = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    const searchValue = searchValues(
      searchTermDebounced,
      [...category],
      language
    ) 
    setListCategory(searchValue)
  }, [category, language, searchTermDebounced])

  useEffect(() => {
    if (listCategory) {
      setActiveIndex(listCategory?.[0]?.refId)
    }
  }, [listCategory])

  return (
    <div className="rounded-l-[10px] shadow-md bg-[#DCE3EC] flex-1">
      <div
        className={`pl-[9px] pt-4 ${styles.inspection_current_category_list}`}
      >
        <List itemLayout="vertical">
          {listCategory &&
            listCategory.map((item: any) => (
              <a
                href={`#${item.refId}`}
                style={{ color: 'inherit', textDecoration: 'none' }}
                key={item.refId}
              >
                <List.Item
                  style={{
                    padding: '8px 16px',
                    backgroundColor:
                      activeIndex === item.refId ? '#137695' : '#DCE3EC',
                    color: activeIndex === item.refId ? '#FFFFFF' : '#137695',
                    borderRadius:
                      activeIndex === item.refId ? '6px 0px 0px 6px' : '',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    lineHeight: 1
                  }}
                  onClick={() => setActiveIndex(item.refId)}
                >
                  <Typography className="text-inherit">
                    {showName(item, language) ?? VALUE_WITH_NO_DATA}
                  </Typography>
                </List.Item>
              </a>
            ))}
        </List>
      </div>
    </div>
  )
}

export default ItemSettingCategoryList
