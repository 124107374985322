import React from 'react'

import { Modal } from 'antd'

import ScenarioLogo from 'assets/pngs/scenario.png'

interface Props {
  readonly open: boolean
  readonly children: React.ReactNode
  readonly width?: number
  readonly onOk?: () => void
  readonly onCancel?: () => void
}

function ModalCreateScenario({
  open = true,
  children,
  width = 600,
  onOk,
  onCancel,
  ...field
}: Props) {
  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      className="modal"
      maskClosable={true}
      width={width}
      style={{ padding: 0 }}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      {...field}
    >
      <div className="flex flex-col items-center justify-center bg-[url('assets/svgs/scenario-bgr.svg')] bg-cover bg-no-repeat">
        <img src={ScenarioLogo} alt="scenario-logo" />
      </div>
      {children}
    </Modal>
  )
}

export default ModalCreateScenario
