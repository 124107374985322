import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'

import { DRAWER_DATA_TYPE, STEP_SCENARIO } from 'configs/constant'
import useCheckErrorField from 'hooks/scenario/useCheckErrorField'
import useDataFormBlockHandler from 'hooks/scenario/useDataFormBlockHandler'

function DrawerLastBlock() {
  const { t } = useTranslation()
  const { dataForm, setDataForm } = useDataFormBlockHandler()
  const { errors, checkError } = useCheckErrorField()

  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    checkError(DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST, e.target.value)

    setDataForm((prev) => {
      const updatedFlows = [...prev.flows]
      updatedFlows[STEP_SCENARIO.LAST_STEP] = {
        ...updatedFlows[STEP_SCENARIO.LAST_STEP],
        content: e.target.value
      }
      return {
        ...prev,
        flows: updatedFlows
      }
    })
  }

  const lastBlock = useMemo(() => {
    if (!dataForm || !dataForm.flows[STEP_SCENARIO.LAST_STEP])
      return {
        step: '',
        content: ''
      }

    return dataForm.flows[STEP_SCENARIO.LAST_STEP]
  }, [dataForm])

  return (
    <React.Fragment>
      <div className="px-3 py-2 bg-primary">
        <p className="text-white font-bold">{t(lastBlock.step ?? '')}</p>
      </div>
      {/* description */}
      <div className="mt-2">
        <p className="text-primary font-bold mb-2">
          {t('scenarioCreate.drawer.description')}
        </p>
        <Input.TextArea
          onBlur={(e) =>
            checkError(DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST, e.target.value)
          }
          status={errors[DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST] ? 'error' : ''}
          name={DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST}
          className="text-default"
          value={lastBlock.content}
          onChange={handleChangeDescription}
          style={{ height: 120, resize: 'none' }}
        />
      </div>
    </React.Fragment>
  )
}

export default DrawerLastBlock
