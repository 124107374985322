import { useCallback, useState } from 'react'

import dayjs from 'dayjs'

import { FILTER_TENANT } from '../graphql/tenant/filter'
import { ReservationData } from '../models/reservationManagement'
import { useLocalStorage } from './index'
import { calculateAge } from './useCustomer'
import { useLazyQuery } from '@apollo/client'
import { AGGREGATION_FILTER } from 'graphql/AggregationFilter/aggregationFilter'
import { FILTER_CONSULTATION_REPORT } from 'graphql/ConsultationReport/filterConsultationReport'
import { FILTER_CHECKUP_USER_NAME } from 'graphql/ReservationManagement/filterCheckupUserName'
import { FILTER_RESERVATION } from 'graphql/ReservationManagement/filterReservation'
import {
  ExaminedAndPaidUser,
  ExaminedAndPaidUserConditions,
  YearlyReport
} from 'models/consultationReport'
import { Endpoint } from 'utilities/apolloClient'

type MedicalCheckupMaster = {
  data: { refId: string; displayName?: string }
}

type CheckupUser = {
  refId: string
  gender: string
  birthday: string
  additionalInfo: Record<string, string>
}

type ReportBill = {
  year: string
  month: string
  amount: string
  price: string
}

const useConsultationReport = () => {
  const [currentTenant] = useLocalStorage('@tenant', {})

  const [filterConsultationReport] = useLazyQuery(FILTER_CONSULTATION_REPORT)
  const [filterReservation] = useLazyQuery(FILTER_RESERVATION)
  const [filterCheckupUserName] = useLazyQuery(FILTER_CHECKUP_USER_NAME)
  const [getTenant] = useLazyQuery(FILTER_TENANT)

  const [aggregationFilter] = useLazyQuery(AGGREGATION_FILTER)

  const [loading, setLoading] = useState<boolean>(false)
  const [aggregatedData, setAggregatedData] = useState<ExaminedAndPaidUser[]>(
    []
  )

  const fetchAndAggregateData = useCallback(async () => {
    const [
      fetchReservationData,
      fetchMedicalCheckupMasterData,
      fetchMedicalCheckupMasterOnlyOptionData,
      fetchUserData
    ] = await Promise.all([
      filterReservation({
        variables: {
          filter: '(eq,STRING,reservationStatus,RESERVED)',
          sortBy: '(DESC,createdDate)',
          paginationInput: {
            page: 0,
            size: -1
          }
        },
        context: {
          version: Endpoint.RESERVATION
        },
        fetchPolicy: 'network-only'
      }),
      aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  {
                    field: 'data.additionalInfo.key1',
                    operator: 'ne',
                    value: 'option'
                  }
                ]
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      }),
      aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  {
                    field: 'data.additionalInfo.key1',
                    operator: 'eq',
                    value: 'option'
                  }
                ]
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      }),
      filterCheckupUserName({
        variables: {
          filter: '(eq,STRING,status,PUBLISHED)',
          page: 0,
          size: -1,
          sortBy: '(desc,createdDate)'
        },
        fetchPolicy: 'network-only'
      })
    ])

    const reservationData =
      fetchReservationData?.data?.filterReservation?.payload || []

    const medicalCheckupMasterNames = Object.fromEntries(
      Array.isArray(
        fetchMedicalCheckupMasterData?.data?.commonAggregationFilter?.payload
      )
        ? fetchMedicalCheckupMasterData.data.commonAggregationFilter.payload.map(
            (item: MedicalCheckupMaster) => [
              item?.data?.refId || '',
              item?.data?.displayName || ''
            ]
          )
        : []
    )

    const medicalCheckupMasterOnlyOptionNames = Object.fromEntries(
      Array.isArray(
        fetchMedicalCheckupMasterOnlyOptionData?.data?.commonAggregationFilter
          ?.payload
      )
        ? fetchMedicalCheckupMasterOnlyOptionData.data.commonAggregationFilter.payload.map(
            (item: MedicalCheckupMaster) => [
              item?.data?.refId || '',
              item?.data?.displayName || ''
            ]
          )
        : []
    )

    const userNames = Object.fromEntries(
      Array.isArray(fetchUserData?.data?.filterCheckupUser?.payload)
        ? fetchUserData.data.filterCheckupUser.payload.map(
            (item: CheckupUser) => {
              const { refId, gender, birthday } = item || {}
              const {
                firstName = '',
                lastName = '',
                firstNameKana = '',
                lastNameKana = '',
                phone = '',
                email = ''
              } = item?.additionalInfo || {}
              const fullName = `${firstName}${lastName}`.trim()
              const fullNameKana = `${firstNameKana}${lastNameKana}`.trim()

              return [
                refId,
                {
                  allName:
                    `${fullName}` + (fullNameKana ? `（${fullNameKana}）` : ''),
                  fullName,
                  fullNameKana,
                  phone,
                  email,
                  gender: gender ? gender.toLowerCase() : '',
                  birthDay: birthday
                    ? dayjs(birthday).format('YYYY/MM/DD')
                    : '',
                  age: birthday ? calculateAge(birthday) : 0
                }
              ]
            }
          )
        : []
    )

    const aggregatedData: ExaminedAndPaidUser[] = reservationData
      .map(
        (item: {
          refId: string
          medicalCheckupMasterRefId: string
          checkupUserRefId: string
          reservationDate: string
          reservationTime: string
          reservationStatus: string
          desiredDatetime?: {
            priority: number
            date: string
            timeOfDay: string
          }[]
          optionRefIds: string[]
        }) => {
          const courseName =
            medicalCheckupMasterNames[item.medicalCheckupMasterRefId]

          const {
            allName: userFullName,
            fullName,
            fullNameKana,
            birthDay,
            age,
            gender,
            email,
            phone
          } = userNames[item.checkupUserRefId] || {}

          const courseSelect =
            item.optionRefIds.length > 0
              ? item.optionRefIds
                  .map((id) => medicalCheckupMasterOnlyOptionNames[id])
                  .filter(Boolean)
                  .join(',') || undefined
              : undefined

          return {
            userId: item.checkupUserRefId,
            userFullName,
            courseId: item.medicalCheckupMasterRefId,
            courseName,
            courseSelect,
            date: item.reservationDate,
            time: item.reservationTime,
            dateTime: `${dayjs(item.reservationDate).format('YYYY/MM/DD')}   ${dayjs(item.reservationTime, 'HH:mm:ss').format('H:mm')}`,
            fullName,
            fullNameKana,
            birthDay,
            age,
            gender,
            email,
            phone
          }
        }
      )
      .filter((item: ReservationData) => item !== null)

    return aggregatedData
  }, [])

  const applyConditions = (
    data: ExaminedAndPaidUser[],
    conditions: ExaminedAndPaidUserConditions
  ) => {
    const { filter, pagination, order } = conditions
    const sort = conditions.sort ?? 'asc'
    const { idOrFullName, date, courseId } = filter || {}
    const { page, size } = pagination || {}

    // idOrFullNameOrPhone
    let filteredData = data
    if (idOrFullName) {
      const lowerCaseSearch = idOrFullName.toLowerCase()
      filteredData = filteredData.filter((item) =>
        [item.userId, item.userFullName].some((value) =>
          value ? value.toLowerCase().includes(lowerCaseSearch) : ''
        )
      )
    }

    // date
    if (date) {
      filteredData = filteredData.filter(
        (item) =>
          dayjs(item.date) >= dayjs(date.startDate) &&
          dayjs(item.date) <= dayjs(date.endDate)
      )
    }

    // courseId
    if (courseId) {
      filteredData = filteredData.filter((item) =>
        courseId.includes(item.courseId)
      )
    }

    const totalRecords = filteredData.length

    // order and sort
    if (order) {
      const compare = (aValue: string, bValue: string) => {
        if (aValue < bValue) return sort === 'desc' ? 1 : -1
        if (aValue > bValue) return sort === 'desc' ? -1 : 1
        return 0
      }

      filteredData.sort((a, b) => {
        const aValue = (a[order] ?? '').toString().toLowerCase()
        const bValue = (b[order] ?? '').toString().toLowerCase()

        let comparison = compare(aValue, bValue)

        if (order === 'date' && comparison === 0) {
          const aTime = a.time?.toLowerCase() || ''
          const bTime = b.time?.toLowerCase() || ''
          return compare(aTime, bTime)
        }

        return comparison
      })
    }

    // pagination
    const startIndex = (page - 1) * size
    const endIndex = startIndex + size
    filteredData = filteredData.slice(startIndex, endIndex)

    return {
      data: filteredData,
      totalRecords
    }
  }

  const onFilterYear = async () => {
    const currentYear = dayjs().year()
    const defaultData: {
      yearOptions: { label: number; value: number }[]
      tenantName: string
    } = {
      yearOptions: [
        {
          label: currentYear,
          value: currentYear
        }
      ],
      tenantName: ''
    }
    const fetchData = await getTenant({
      variables: {
        filter: `(eq,STRING,code,${currentTenant?.tenant})`,
        sortBy: '(desc,_id)',
        page: 0,
        size: 1
      },
      fetchPolicy: 'network-only'
    })
    const payload = fetchData?.data?.filterTenant?.payload
    if (!Array.isArray(payload) && !payload?.[0]?.additionalInfo) {
      return defaultData
    }

    const {
      createdYear,
      clinicName: tenantName
    }: { createdYear?: string; clinicName: string } = payload[0].additionalInfo
    if (!createdYear || isNaN(Number(createdYear))) {
      return defaultData
    }

    const startYear = +createdYear
    const yearOptions = Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => ({
        label: startYear + i,
        value: startYear + i
      })
    )

    return {
      yearOptions,
      tenantName
    }
  }

  const onFilterConsultationReport = async (data: {
    year: number
    month?: number
    sortBy?: string
  }) => {
    setLoading(true)
    try {
      const fetchData = await filterConsultationReport({
        variables: data,
        context: {
          version: Endpoint.RESERVATION
        },
        fetchPolicy: 'network-only'
      })

      const payload = Array.isArray(fetchData?.data?.getClaimsBills?.payload)
        ? fetchData.data.getClaimsBills.payload
        : []

      const finalData: YearlyReport[] = payload.map((item: ReportBill) => {
        return {
          year: +item.year,
          month: +item.month,
          quantity: +item.amount,
          totalAmount: +item.price
        }
      })

      setLoading(false)
      return {
        data: finalData
      }
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const onFilterReservation = async (
    conditions: ExaminedAndPaidUserConditions
  ) => {
    setLoading(true)

    if (aggregatedData.length === 0) {
      const aggregatedData = await fetchAndAggregateData()
      setAggregatedData(aggregatedData)

      const finalFilteredData = applyConditions(aggregatedData, conditions)
      setLoading(false)
      return {
        data: finalFilteredData.data,
        totalRecords: finalFilteredData.totalRecords
      }
    }

    const finalFilteredData = applyConditions(aggregatedData, conditions)

    setLoading(false)
    return {
      data: finalFilteredData.data,
      totalRecords: finalFilteredData.totalRecords
    }
  }

  return {
    loading,
    onFilterYear,
    onFilterConsultationReport,
    onFilterReservation
  }
}

export default useConsultationReport
