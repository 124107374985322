import { useTranslation } from 'react-i18next'

import { Typography } from 'antd'
import { t } from 'i18next'

import { isArray } from '@apollo/client/utilities'
import { SELECTION_TYPE } from 'configs/constant'
import { Option } from 'pages/Chat/types/types'
import { StringKeyObject } from 'types/common'
import { convertTimePicker } from 'utilities/chatHelpers'
import { getLanguage } from 'utilities/helpers'

interface ChatMessageProps {
  message?: string
  isSent?: boolean
  timestamp?: string
  sender?: string
  isTyping?: boolean
  readonly typeMessage?: string | 'image' | 'file'
  readonly attachments?: any[]
  readonly isRead?: boolean
  readonly imgId?: string
  readonly uId?: string
}

const Message = ({ isSent, timestamp, message }: ChatMessageProps) => {
  const messageClasses = isSent
    ? 'bg-primary text-white border-br-0 rounded-br-none'
    : 'bg-contentGray text-black rounded-bl-none'
  const textColor = isSent ? '#fff' : '#545454'

  return (
    <div
      className={`flex flex-col ${isSent ? 'items-end' : 'items-start'} mb-4`}
    >
      <div className="flex items-end">
        {isSent && <TimestampDisplay timestamp={timestamp} />}
        <div
          className={`shadow-md relative max-w-sm p-3 rounded-xl ${messageClasses}`}
        >
          <Typography
            className={`text-sm whitespace-pre-wrap text-[${textColor}]`}
            dangerouslySetInnerHTML={{ __html: t(message ?? '') }}
          ></Typography>
        </div>
      </div>
    </div>
  )
}

const TimestampDisplay: React.FC<{ isRead?: boolean; timestamp?: string }> = ({
  isRead,
  timestamp
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col mr-2 text-xs text-[#545454]">
      {isRead && <span className="mr-2">{t('dashboard.read')}</span>}
      <span>{timestamp}</span>
    </div>
  )
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  isSent = false,
  timestamp,
  attachments
}) => {
  const { t } = useTranslation()
  const lang = getLanguage()

  const getTitle = (key: string) => {
    switch (key) {
      case 'BUTTON':
        return t('chat.labels.button')

      case 'CHOICE':
        return t('chat.labels.choice')

      case 'CONFIRM_BUTTON':
        return t('chat.labels.confirmButton')

      case 'CHOICE_OPTION':
        return t('chat.labels.choiceOption')

      default:
        return t('chat.labels.time')
    }
  }

  const renderMessage = (key: string, msg: string) => {
    if (!msg || !key) return null

    return (
      <p className={`text-sm whitespace-pre-wrap text-white`}>
        <strong>{getTitle(key)}</strong> : {t(msg ?? '')}
      </p>
    )
  }

  const convertQuote = (quote: { [key: string]: StringKeyObject }) => {
    if (!quote) return []

    const newQuote: {
      key: string
      title: string
    }[] = []

    Object.keys(quote).forEach((quoteKey) => {
      if (quoteKey === SELECTION_TYPE.BUTTON) return

      if (quoteKey === 'timePicker') {
        Object.values(quote[quoteKey]).forEach(
          (val: { date: string; time: string }) => {
            if (!val?.date || !val?.time) return

            newQuote.push({
              key: 'timePicker',
              title: convertTimePicker(val.date, val.time, lang)
            })
          }
        )
        return
      }

      if (quoteKey === SELECTION_TYPE.CHOICE_OPTION) {
        if (!isArray(quote[quoteKey])) return

        newQuote.push({
          key: quoteKey,
          title: quote[quoteKey]
            ?.map((option: Option) => option.displayName)
            .join(', ')
        })
      }

      newQuote.push({
        key: quoteKey,
        title: t(quote[quoteKey]?.displayName) ?? ''
      })
    })

    return newQuote
  }

  if (attachments && attachments.length > 0) {
    const [attachment] = attachments
    const { selectionType, displayName, title, type } = attachment
    const quote = JSON.parse(attachment?.quote ?? '{}')

    if (type !== 'bot' && selectionType === SELECTION_TYPE.CHOICE_OPTION) {
      return (
        <div className="">
          {attachment?.options?.map((option: Option) => (
            <Message
              key={option.refId}
              isSent={isSent}
              timestamp={timestamp}
              message={option.displayName}
            />
          ))}
        </div>
      )
    } else if (
      type === 'bot' &&
      selectionType &&
      selectionType === SELECTION_TYPE.BUTTON_REGISTRATION
    ) {
      return (
        <div className="">
          <Message isSent={isSent} timestamp={timestamp} message={message} />

          <div className="flex flex-col items-end mb-4">
            <div className="flex items-end">
              <TimestampDisplay timestamp={timestamp} />
              <div
                className={
                  'shadow-md relative max-w-sm p-3 rounded-xl bg-primary text-white border-br-0 rounded-br-none`'
                }
              >
                {convertQuote(quote).map(
                  (item: { key: string; title: string }) =>
                    renderMessage(item.key, item.title)
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (
      type === 'bot' &&
      selectionType &&
      selectionType === SELECTION_TYPE.TEXT
    ) {
      return (
        <div className="">
          {quote?.title && (
            <Message
              isSent={isSent}
              timestamp={timestamp}
              message={t(quote?.title)}
            />
          )}

          {(quote?.value === 'accept' || attachment.title) && (
            <Message isSent={isSent} timestamp={timestamp} message={message} />
          )}
        </div>
      )
    } else if (
      type === 'bot' &&
      (attachment?.options?.length > 0 ||
        selectionType === SELECTION_TYPE.MSG_FALLBACK)
    ) {
      return (
        <Message
          isSent={isSent}
          timestamp={timestamp}
          message={title ?? displayName}
        />
      )
    } else if (
      type !== 'bot' ||
      (type === 'bot' && attachment?.value === 'accept')
    ) {
      return (
        <div className="">
          <Message isSent={isSent} timestamp={timestamp} message={message} />
        </div>
      )
    }
  }

  return <Message isSent={isSent} timestamp={timestamp} message={message} />
}

export default ChatMessage
