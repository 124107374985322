import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { SpecialConfig } from '../../../hooks/useTimeConfig'
import { CourseSetting } from '../ProductManagement'
import RemainedSlotsByDate from './RemainedSlotsByDate'
import RemainedSlotsByWeek from './RemainedSlotsByWeek'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { DateRange, RemainedSlotByDate } from 'models/productManagement'
import {
  generateFullWeekRange,
  getMonthRange,
  getWeekRange
} from 'utilities/helpers'

type Props = {
  loading: boolean
  courseParam: string | null
  courseName?: string
  initialData: RemainedSlotByDate[]
  dateRange: DateRange
  setCurrentDateRange: (dateRange: DateRange) => void
  config: SpecialConfig
  courseSetting: CourseSetting
}

const Content: FC<Props> = ({
  loading,
  courseParam,
  courseName,
  initialData,
  dateRange,
  setCurrentDateRange,
  config,
  courseSetting
}) => {
  const { t, i18n } = useTranslation()
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const { startDate: startDateParam, endDate: endDateParam } =
    Object.fromEntries(queryParams)

  const [selected, setSelected] = useState<boolean>(true)
  const [startIndex, setStartIndex] = useState<number>(0)

  const [_, forceRender] = useState(0)

  const triggerRender = () => forceRender((prev) => prev + 1)

  useEffect(() => {
    dayjs.locale(i18n.language)
    triggerRender()
  }, [i18n.language])

  const getAvailableMonths = (dateRange: DateRange) => {
    const start = dayjs(dateRange.startDate)
    const end = dayjs(dateRange.endDate)

    const months: { [key: string]: string } = {}

    let current = start.clone().startOf('month')

    while (current.isBefore(end.clone().endOf('month'))) {
      months[current.format('YYYY-MM')] = current.format('YYYY-MM')
      current = current.add(1, 'month')
    }

    return months
  }
  const availableMonths = getAvailableMonths(dateRange)
  const firstAvailableMonth = Object.keys(availableMonths)[0]

  const [currentMonth, setCurrentMonth] = useState(firstAvailableMonth)

  useEffect(() => {
    setStartIndex(0)

    setCurrentMonth(firstAvailableMonth)
  }, [startDateParam, endDateParam, selected, firstAvailableMonth])

  const currentMonthValue = dayjs(currentMonth)
  const fullDateArray = generateFullWeekRange(dateRange)

  const getButtonStyle = (isSelected: boolean) => ({
    color: isSelected ? 'white' : '',
    background: isSelected ? '#137695' : '',
    border: isSelected ? '' : '1px solid #D9E0E5',
    fontWeight: isSelected ? 'bold' : ''
  })

  const getArrowStyle = (isDisabled: boolean) => ({
    fontSize: '25px',
    color: isDisabled ? '#BFC6CB' : '#137695',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })

  const getNextSevenDays = () => fullDateArray.slice(startIndex, startIndex + 7)

  const canPrev = selected
    ? startIndex > 0
    : currentMonthValue.isAfter(dayjs(firstAvailableMonth))

  const canNext = selected
    ? startIndex + 7 < fullDateArray.length
    : currentMonthValue.isBefore(
        dayjs(Object.keys(availableMonths).slice(-1)[0])
      )

  const updateMonth = (month: string) => {
    setCurrentMonth(availableMonths[month] ? month : currentMonth)
  }

  const handlePrevClick = () => {
    if (selected) {
      const newStartIndex = startIndex - 7
      setStartIndex(newStartIndex)
      const newStartDate = fullDateArray[newStartIndex]

      if (
        dayjs(newStartDate).format('YYYY-MM') !==
        currentMonthValue.format('YYYY-MM')
      ) {
        updateMonth(currentMonthValue.subtract(1, 'month').format('YYYY-MM'))
      }
    } else if (!selected) {
      updateMonth(currentMonthValue.subtract(1, 'month').format('YYYY-MM'))
    }
  }

  const handleNextClick = () => {
    if (selected) {
      const newStartIndex = startIndex + 7
      setStartIndex(newStartIndex)
      const newEndDate = fullDateArray[newStartIndex - 1]

      if (
        dayjs(newEndDate).format('YYYY-MM') !==
        currentMonthValue.format('YYYY-MM')
      ) {
        updateMonth(currentMonthValue.add(1, 'month').format('YYYY-MM'))
      }
    } else if (!selected) {
      updateMonth(currentMonthValue.add(1, 'month').format('YYYY-MM'))
    }
  }

  const handleChangeDisplay = (data: boolean) => {
    setSelected(data)

    if (!startDateParam || !endDateParam) {
      if (selected) {
        setCurrentDateRange(getMonthRange())
      } else {
        setCurrentDateRange(getWeekRange())
      }
    }
  }

  return (
    <div className="rounded-[5px] bg-[#FFFFFF] px-5 py-[10px] drop-shadow-md">
      <Flex justify="space-between" className="my-4 ml-3">
        <Flex align="center">
          <CaretLeftOutlined
            style={getArrowStyle(!canPrev)}
            onClick={canPrev ? handlePrevClick : undefined}
          />

          <Typography className="mx-[11px] text-[16px] font-bold tracking-[0.8px]">
            {currentMonthValue.format(
              t('productManagement.content.formatDate')
            )}
          </Typography>

          <CaretRightOutlined
            style={getArrowStyle(!canNext)}
            onClick={canNext ? handleNextClick : undefined}
          />
        </Flex>

        <Flex align="center">
          <Typography className="mr-2 text-[14px] text-[#545454]">
            {t('productManagement.content.toggleDisplay')}
          </Typography>

          <Button
            style={getButtonStyle(selected)}
            className="h-[30px] min-w-[100px] rounded-l-[5px]"
            onClick={() => {
              handleChangeDisplay(true)
            }}
          >
            {t('productManagement.content.week')}
          </Button>

          <Button
            style={getButtonStyle(!selected)}
            className="h-[30px] min-w-[100px] rounded-r-[5px]"
            onClick={() => {
              handleChangeDisplay(false)
            }}
          >
            {t('productManagement.content.month')}
          </Button>
        </Flex>
      </Flex>

      {selected ? (
        <RemainedSlotsByDate
          loading={loading}
          courseParam={courseParam}
          courseName={courseName}
          initialData={initialData}
          dateRange={getNextSevenDays()}
          config={config}
          courseSetting={courseSetting}
        />
      ) : (
        <RemainedSlotsByWeek
          loading={loading}
          courseParam={courseParam}
          courseName={courseName}
          initialData={initialData}
          currentMonth={currentMonthValue}
          config={config}
          courseSetting={courseSetting}
        />
      )}
    </div>
  )
}

export default Content
