import { useState } from 'react'

import dayjs from 'dayjs'

import { CourseSetting } from '../pages/ProductManagement/ProductManagement'
import { calculateAge } from './useCustomer'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AGGREGATION_FILTER } from 'graphql/AggregationFilter/aggregationFilter'
import { CREATE_RESERVATION } from 'graphql/ReservationManagement/createReservation'
import { FILTER_CHECKUP_USER_NAME } from 'graphql/ReservationManagement/filterCheckupUserName'
import { FILTER_RESERVATION } from 'graphql/ReservationManagement/filterReservation'
import { GET_REMAINED_SLOTS_BY_DATE } from 'graphql/ReservationManagement/getRemainedSlotByDate'
import {
  UPDATE_DESIRED_RESERVATION,
  UPDATE_RESERVATION
} from 'graphql/ReservationManagement/updateReservation'
import {
  RESERVATION_STATUS,
  ReservationCollectionConditions,
  ReservationCollectionData,
  ReservationConditions,
  ReservationCreateData,
  ReservationData,
  ReservationUpdateData
} from 'models/reservationManagement'
import { Endpoint } from 'utilities/apolloClient'

class CustomError extends Error {
  constructor(message: string) {
    super(message)
    this.name = ''
  }
}

type MedicalCheckupMaster = {
  data: {
    refId: string
    displayName?: string
    additionalInfo: {
      isDeleted?: string
      metadata?: { name: string; value: string }[]
    }
  }
}

type User = {
  refId: string
  gender: string
  birthday: string
  additionalInfo: {
    firstName?: string
    lastName?: string
    firstNameKana?: string
    lastNameKana?: string
    phone?: string
    isDeleted?: string
  }
}

const useReservationManagement = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [aggregatedData, setAggregatedData] = useState<ReservationData[]>()
  const [remainedSlotsByDate, setRemainedSlotsByDate] = useState<
    ReservationData[]
  >([])
  const [desiredCollectionData, setDesiredCollectionData] = useState<
    ReservationCollectionData[]
  >([])

  const [filterReservation] = useLazyQuery(FILTER_RESERVATION)
  const [createReservation] = useMutation(CREATE_RESERVATION)
  const [updateReservation] = useMutation(UPDATE_RESERVATION)
  const [updateDesiredReservation] = useMutation(UPDATE_DESIRED_RESERVATION)

  const [filterCheckupUserName] = useLazyQuery(FILTER_CHECKUP_USER_NAME)
  const [filterRemainedSlots] = useLazyQuery(GET_REMAINED_SLOTS_BY_DATE)

  const [aggregationFilter] = useLazyQuery(AGGREGATION_FILTER)

  const onFilterReservations = async (conditions: ReservationConditions) => {
    setLoading(true)

    const fetchAndAggregateData = async () => {
      const [
        fetchReservationData,
        fetchMedicalCheckupMasterData,
        fetchMedicalCheckupMasterOnlyOptionData,
        fetchUserData
      ] = await Promise.all([
        filterReservation({
          variables: {
            filter: '(in,STRING,reservationStatus,RESERVED,CANCELLED)',
            sortBy: '(DESC,createdDate)',
            paginationInput: {
              page: 0,
              size: -1
            }
          },
          context: {
            version: Endpoint.RESERVATION
          },
          fetchPolicy: 'network-only'
        }),
        aggregationFilter({
          variables: {
            collection: 'medicalCheckupMaster',
            page: 0,
            size: -1,
            request: [
              {
                type: 'SORT',
                criteria: {
                  field: '_id',
                  direction: 'desc'
                }
              },
              {
                type: 'GROUP',
                criteria: {
                  field: 'refId'
                }
              },
              {
                type: 'ADD_FIELDS',
                criteria: {
                  data: {
                    $arrayElemAt: ['$data', 0]
                  }
                }
              },
              {
                type: 'MATCH',
                criteria: {
                  and: [
                    {
                      field: 'data.additionalInfo.isDeleted',
                      operator: 'ne',
                      value: 'true'
                    },
                    {
                      field: 'data.additionalInfo.key1',
                      operator: 'ne',
                      value: 'option'
                    }
                  ]
                }
              }
            ]
          },
          fetchPolicy: 'network-only'
        }),
        aggregationFilter({
          variables: {
            collection: 'medicalCheckupMaster',
            page: 0,
            size: -1,
            request: [
              {
                type: 'SORT',
                criteria: {
                  field: '_id',
                  direction: 'desc'
                }
              },
              {
                type: 'GROUP',
                criteria: {
                  field: 'refId'
                }
              },
              {
                type: 'ADD_FIELDS',
                criteria: {
                  data: {
                    $arrayElemAt: ['$data', 0]
                  }
                }
              },
              {
                type: 'MATCH',
                criteria: {
                  and: [
                    {
                      field: 'data.additionalInfo.isDeleted',
                      operator: 'ne',
                      value: 'true'
                    },
                    {
                      field: 'data.additionalInfo.key1',
                      operator: 'eq',
                      value: 'option'
                    }
                  ]
                }
              }
            ]
          },
          fetchPolicy: 'network-only'
        }),
        filterCheckupUserName({
          variables: {
            filter: '(eq,STRING,status,PUBLISHED)',
            page: 0,
            size: -1,
            sortBy: '(desc,createdDate)'
          },
          fetchPolicy: 'network-only'
        })
      ])

      const reservationData =
        fetchReservationData?.data?.filterReservation?.payload || []

      const uniqueDates = Array.from(
        new Set(
          reservationData.map(
            (item: { reservationDate: string }) =>
              item.reservationDate.split('T')[0]
          )
        )
      ).map((date) => ({ date }))

      const fetchRemainedSlots = await filterRemainedSlots({
        variables: {
          dates: uniqueDates
        },
        context: {
          version: Endpoint.RESERVATION
        },
        fetchPolicy: 'network-only'
      })

      const remainedSlotsData =
        fetchRemainedSlots?.data?.getRemainedSlots?.payload

      const medicalCheckupMasterNames = Object.fromEntries(
        fetchMedicalCheckupMasterData?.data?.commonAggregationFilter?.payload?.map(
          (item: MedicalCheckupMaster) => {
            const metadata = item.data.additionalInfo.metadata
            const defaultCourseSetting: CourseSetting = {
              sex: 'NONE',
              startAge: undefined,
              endAge: undefined
            }
            let courseSetting: CourseSetting = defaultCourseSetting

            if (Array.isArray(metadata) && metadata.length > 0) {
              courseSetting = metadata.reduce((acc, item) => {
                if (
                  item.name === 'sex' &&
                  ['MALE', 'FEMALE', 'NONE'].includes(item.value)
                ) {
                  acc.sex = item.value as CourseSetting['sex']
                }

                if (item.name === 'startAge' && !!+item.value) {
                  acc.startAge = +item.value
                }

                if (item.name === 'endAge' && !!+item.value) {
                  acc.endAge = +item.value
                }

                return acc
              }, defaultCourseSetting)
            }

            return [
              item.data.refId,
              {
                displayName: item.data.displayName,
                courseSetting
              }
            ]
          }
        ) || []
      )

      const medicalCheckupMasterOnlyOptionNames = Object.fromEntries(
        fetchMedicalCheckupMasterOnlyOptionData?.data?.commonAggregationFilter?.payload?.map(
          (item: MedicalCheckupMaster) => [
            item.data.refId,
            item.data.displayName
          ]
        ) || []
      )

      const userNames = Object.fromEntries(
        fetchUserData?.data?.filterCheckupUser?.payload?.map(
          ({ refId, additionalInfo }: User) => {
            const {
              firstName,
              lastName,
              firstNameKana = '',
              lastNameKana = '',
              phone
            } = additionalInfo || {}

            const fullNameKana = `${firstNameKana}${lastNameKana}`.trim()
            const fullName =
              `${firstName}${lastName}` +
              (fullNameKana ? `（${fullNameKana}）` : '')

            return [
              refId,
              {
                fullName,
                phone
              }
            ]
          }
        ) || []
      )

      const aggregatedData: ReservationData[] = reservationData
        .map(
          (item: {
            refId: string
            medicalCheckupMasterRefId: string
            checkupUserRefId: string
            reservationDate: string
            reservationTime: string
            reservationStatus: string
            optionRefIds: string[]
          }) => {
            const courseName =
              medicalCheckupMasterNames[item.medicalCheckupMasterRefId]
                ?.displayName
            const userFullName = userNames[item.checkupUserRefId]?.fullName
            const userPhone = userNames[item.checkupUserRefId]?.phone

            const courseSelect =
              item.optionRefIds.length > 0
                ? item.optionRefIds
                    .map((id) => medicalCheckupMasterOnlyOptionNames[id])
                    .filter(Boolean)
                    .join(',') || undefined
                : undefined

            return {
              refId: item.refId,
              courseId: item.medicalCheckupMasterRefId,
              courseName,
              courseSelect,
              date: dayjs(item.reservationDate).format('YYYY-MM-DD'),
              time: item.reservationTime,
              status: item.reservationStatus,
              userId: item.checkupUserRefId,
              userFullName,
              userPhone
            }
          }
        )
        .filter((item: ReservationData) => item !== null)

      const aggregatedDataWithRemainedSlot = remainedSlotsData
        .map(
          (item: {
            medicalCheckupMasterRefId: string
            reservationDate: string
            reservationTime: string
            remainedSlotCount: number
            courseSetting: CourseSetting
          }) => {
            const courseName =
              medicalCheckupMasterNames[item.medicalCheckupMasterRefId]
                ?.displayName

            const courseSetting = medicalCheckupMasterNames[
              item.medicalCheckupMasterRefId
            ]?.courseSetting ?? {
              sex: 'NONE',
              startAge: undefined,
              endAge: undefined
            }

            return {
              courseId: item.medicalCheckupMasterRefId,
              courseName,
              date: item.reservationDate,
              time: item.reservationTime,
              remainedSlots: item.remainedSlotCount,
              courseSetting
            }
          }
        )
        .filter(
          (
            item: {
              medicalCheckupMasterRefId: string
              reservationDate: string
              reservationTime: string
              remainedSlotCount: number
            } | null
          ) => item !== null
        )

      return {
        aggregatedData,
        aggregatedDataWithRemainedSlot
      }
    }

    const applyConditions = (
      data: ReservationData[],
      dataWithRemainedSlots: ReservationData[],
      conditions: ReservationConditions
    ) => {
      const { filter, pagination, order } = conditions
      const sort = conditions.sort ?? 'asc'
      const { idOrFullNameOrPhone, date, status, courseId, display } =
        filter || {}
      const { page, size } = pagination || {}

      // idOrFullNameOrPhone
      let filteredData = data
      if (idOrFullNameOrPhone) {
        const lowerCaseSearch = idOrFullNameOrPhone.toLowerCase()
        filteredData = filteredData.filter((item) =>
          [item.userId, item.userFullName, item.userPhone].some(
            (value) => value && value.toLowerCase().includes(lowerCaseSearch)
          )
        )
      }

      // date
      if (date) {
        filteredData = filteredData.filter(
          (item) =>
            dayjs(item.date) >= dayjs(date.startDate) &&
            dayjs(item.date) <= dayjs(date.endDate)
        )
      }

      // status
      if (status) {
        filteredData = filteredData.filter((item) => item.status === status)
      }

      // courseName
      if (courseId) {
        filteredData = filteredData.filter((item) =>
          courseId.includes(item.courseId)
        )
      }

      // order and sort
      if (order) {
        filteredData.sort((a, b) => {
          const aValue = (a[order] ?? '').toString().toLowerCase()
          const bValue = (b[order] ?? '').toString().toLowerCase()

          let comparison = 0
          if (aValue < bValue) comparison = sort === 'desc' ? 1 : -1
          if (aValue > bValue) comparison = sort === 'desc' ? -1 : 1

          if (order === 'date' && comparison === 0) {
            const aTime = a.time?.toLowerCase() || ''
            const bTime = b.time?.toLowerCase() || ''

            if (aTime < bTime) return sort === 'desc' ? 1 : -1
            if (aTime > bTime) return sort === 'desc' ? -1 : 1
          }

          return comparison
        })
      }

      // display
      if (display === 'ON') {
        if (status !== RESERVATION_STATUS.RESERVED) {
          filteredData = []
        }

        const mergedData: ReservationData[] = []

        filteredData.forEach((item) => {
          mergedData.push(item)

          const relatedSlots = dataWithRemainedSlots.filter(
            (slot) => slot.date === item.date
          )

          mergedData.push(...relatedSlots)
        })

        filteredData = mergedData
      }

      // pagination
      const startIndex = (page - 1) * size
      const endIndex = startIndex + size
      const totalRecords = filteredData.length

      // slice data
      const listRecord = [...filteredData].slice(startIndex, endIndex)

      return {
        data: listRecord,
        totalRecords
      }
    }

    if (!aggregatedData) {
      const fetchData = await fetchAndAggregateData()
      setAggregatedData(fetchData.aggregatedData)
      setRemainedSlotsByDate(fetchData.aggregatedDataWithRemainedSlot)

      const finalFilteredData = applyConditions(
        fetchData.aggregatedData,
        fetchData.aggregatedDataWithRemainedSlot,
        conditions
      )
      setLoading(false)
      return {
        data: finalFilteredData.data,
        totalRecords: finalFilteredData.totalRecords
      }
    }

    const finalFilteredData = applyConditions(
      aggregatedData,
      remainedSlotsByDate,
      conditions
    )

    setLoading(false)
    return {
      data: finalFilteredData.data,
      totalRecords: finalFilteredData.totalRecords
    }
  }

  const onFilterReservationCollection = async (
    conditions: ReservationCollectionConditions
  ) => {
    setLoading(true)

    const fetchAndAggregateData = async () => {
      const [
        fetchReservationData,
        fetchMedicalCheckupMasterData,
        fetchMedicalCheckupMasterOnlyOptionData,
        fetchUserData
      ] = await Promise.all([
        filterReservation({
          variables: {
            filter:
              '(in,STRING,reservationStatus,UNCONFIRMED,COLLECTED_DESIRED_DATE)',
            sortBy: '(DESC,modifiedDate)',
            paginationInput: {
              page: 0,
              size: -1
            }
          },
          context: {
            version: Endpoint.RESERVATION
          },
          fetchPolicy: 'network-only'
        }),
        aggregationFilter({
          variables: {
            collection: 'medicalCheckupMaster',
            page: 0,
            size: -1,
            request: [
              {
                type: 'SORT',
                criteria: {
                  field: '_id',
                  direction: 'desc'
                }
              },
              {
                type: 'GROUP',
                criteria: {
                  field: 'refId'
                }
              },
              {
                type: 'ADD_FIELDS',
                criteria: {
                  data: {
                    $arrayElemAt: ['$data', 0]
                  }
                }
              },
              {
                type: 'MATCH',
                criteria: {
                  and: [
                    // {
                    //   field: 'data.additionalInfo.isDeleted',
                    //   operator: 'ne',
                    //   value: 'true'
                    // },
                    {
                      field: 'data.additionalInfo.key1',
                      operator: 'ne',
                      value: 'option'
                    }
                  ]
                }
              }
            ]
          },
          fetchPolicy: 'network-only'
        }),
        aggregationFilter({
          variables: {
            collection: 'medicalCheckupMaster',
            page: 0,
            size: -1,
            request: [
              {
                type: 'SORT',
                criteria: {
                  field: '_id',
                  direction: 'desc'
                }
              },
              {
                type: 'GROUP',
                criteria: {
                  field: 'refId'
                }
              },
              {
                type: 'ADD_FIELDS',
                criteria: {
                  data: {
                    $arrayElemAt: ['$data', 0]
                  }
                }
              },
              {
                type: 'MATCH',
                criteria: {
                  and: [
                    // {
                    //   field: 'data.additionalInfo.isDeleted',
                    //   operator: 'ne',
                    //   value: 'true'
                    // },
                    {
                      field: 'data.additionalInfo.key1',
                      operator: 'eq',
                      value: 'option'
                    }
                  ]
                }
              }
            ]
          },
          fetchPolicy: 'network-only'
        }),
        filterCheckupUserName({
          variables: {
            filter: '(eq,STRING,status,PUBLISHED)',
            page: 0,
            size: -1,
            sortBy: '(desc,createdDate)'
          },
          fetchPolicy: 'network-only'
        })
      ])

      const reservationData =
        fetchReservationData?.data?.filterReservation?.payload || []

      const medicalCheckupMasterNames: {
        [p: string]: { displayName: string; isDeleted: boolean }
      } = Object.fromEntries(
        fetchMedicalCheckupMasterData?.data?.commonAggregationFilter?.payload?.map(
          (item: MedicalCheckupMaster) => {
            const { refId, displayName } = item.data

            return [
              refId,
              {
                displayName,
                isDeleted: item.data.additionalInfo?.isDeleted === 'true'
              }
            ]
          }
        ) || []
      )

      const medicalCheckupMasterOnlyOptionNames = Object.fromEntries(
        fetchMedicalCheckupMasterOnlyOptionData?.data?.commonAggregationFilter?.payload?.map(
          (item: MedicalCheckupMaster) => [
            item.data.refId,
            item.data.displayName
          ]
        ) || []
      )

      const userNames: {
        [p: string]: {
          fullName: string
          phone: string
          gender: string
          age: number
          isDeleted: string
        }
      } = Object.fromEntries(
        fetchUserData?.data?.filterCheckupUser?.payload?.map(
          ({ refId, gender, birthday, additionalInfo }: User) => {
            const {
              firstName,
              lastName,
              firstNameKana = '',
              lastNameKana = '',
              phone,
              isDeleted
            } = additionalInfo || {}

            const fullNameKana =
              `${firstNameKana ?? ''}${lastNameKana ?? ''}`.trim()
            const fullName =
              `${firstName}${lastName}` +
              (fullNameKana ? `（${fullNameKana}）` : '')
            const age = calculateAge(birthday)

            return [
              refId,
              {
                fullName,
                phone,
                gender,
                age,
                isDeleted: isDeleted === 'true'
              }
            ]
          }
        ) || []
      )

      const aggregatedData: ReservationCollectionData[] = reservationData
        .map(
          (item: {
            refId: string
            medicalCheckupMasterRefId: string
            checkupUserRefId: string
            reservationDate: string
            reservationTime: string
            reservationStatus: string
            desiredDatetime?: {
              priority: number
              date: string
              timeOfDay: string
            }[]
            optionRefIds: string[]
          }) => {
            const { displayName: courseName, isDeleted: isCourseDeleted } =
              medicalCheckupMasterNames[item.medicalCheckupMasterRefId]
            const {
              fullName: userFullName,
              phone: userPhone,
              gender,
              age,
              isDeleted: isUserDeleted
            } = userNames[item.checkupUserRefId] || {}

            const courseSelect =
              item.optionRefIds.length > 0
                ? item.optionRefIds
                    .map((id) => medicalCheckupMasterOnlyOptionNames[id])
                    .filter(Boolean)
                    .join(',') || undefined
                : undefined

            return {
              refId: item.refId,
              courseId: item.medicalCheckupMasterRefId,
              courseName,
              courseOptionIds: item.optionRefIds,
              courseSelect,
              date: item.reservationDate,
              time: item.reservationTime,
              status: item.reservationStatus,
              userId: item.checkupUserRefId,
              userFullName,
              userPhone,
              userInfo: {
                sex: gender,
                age: age?.toString()
              },
              desiredDatetime: item.desiredDatetime,
              isCourseDeleted,
              isUserDeleted
            }
          }
        )
        .filter((item: ReservationData) => item !== null)

      return aggregatedData
    }

    const applyConditions = (
      data: ReservationCollectionData[],
      conditions: ReservationCollectionConditions
    ) => {
      const { filter, pagination, order } = conditions
      const sort = conditions.sort ?? 'asc'
      const { idOrFullNameOrPhone, status, date } = filter || {}
      const { page, size } = pagination || {}

      // idOrFullNameOrPhone
      let filteredData = data
      if (idOrFullNameOrPhone) {
        const lowerCaseSearch = idOrFullNameOrPhone.toLowerCase()
        filteredData = filteredData.filter((item) =>
          [item.userId, item.userFullName, item.userPhone].some(
            (value) => value && value.toLowerCase().includes(lowerCaseSearch)
          )
        )
      }

      // date
      if (date) {
        filteredData = filteredData.filter(
          (item) =>
            dayjs(item.date) >= dayjs(date.startDate) &&
            dayjs(item.date) <= dayjs(date.endDate)
        )
      }

      // status
      if (status) {
        filteredData = filteredData.filter((item) => item.status === status)
      }

      const totalRecords = filteredData.length

      // order and sort
      if (order) {
        filteredData.sort((a, b) => {
          const aValue = (a[order] ?? '').toString().toLowerCase()
          const bValue = (b[order] ?? '').toString().toLowerCase()

          let comparison = 0
          if (aValue < bValue) comparison = sort === 'desc' ? 1 : -1
          if (aValue > bValue) comparison = sort === 'desc' ? -1 : 1

          return comparison
        })
      }

      // pagination
      const startIndex = (page - 1) * size
      const endIndex = startIndex + size
      filteredData = filteredData.slice(startIndex, endIndex)

      return {
        data: filteredData,
        totalRecords
      }
    }

    if (desiredCollectionData.length === 0) {
      const desiredCollectionData = await fetchAndAggregateData()
      setDesiredCollectionData(desiredCollectionData)

      const finalFilteredData = applyConditions(
        desiredCollectionData,
        conditions
      )
      setLoading(false)
      return {
        data: finalFilteredData.data,
        totalRecords: finalFilteredData.totalRecords
      }
    }

    const finalFilteredData = applyConditions(desiredCollectionData, conditions)

    setLoading(false)
    return {
      data: finalFilteredData.data,
      totalRecords: finalFilteredData.totalRecords
    }
  }

  const onCreateReservation = async (data: ReservationCreateData) => {
    try {
      await createReservation({
        variables: data,
        context: {
          version: Endpoint.RESERVATION
        }
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error)
      throw new CustomError(errorMessage)
    }
  }

  const onUpdateReservation = async (data: ReservationUpdateData) => {
    try {
      await updateReservation({
        variables: data,
        context: {
          version: Endpoint.RESERVATION
        }
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error)
      throw new CustomError(errorMessage)
    }
  }

  const onUpdateDesiredReservation = async (data: {
    refId: string
    desiredDateTime: { priority: number; date: string; timeOfDay: string }[]
  }) => {
    try {
      const { refId, desiredDateTime } = data
      await updateDesiredReservation({
        variables: {
          refId,
          desiredDatetime: desiredDateTime
        },
        context: {
          version: Endpoint.RESERVATION
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    onFilterReservations,
    onFilterReservationCollection,
    onCreateReservation,
    onUpdateReservation,
    onUpdateDesiredReservation
  }
}

export default useReservationManagement
