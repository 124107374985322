import { DocumentNode, gql } from '@apollo/client'

export const GET_DETAIL_PERIOD_CONFIGS: DocumentNode = gql`
  query getDetailPeriodConfigs($periodConfigObjIds: [String]!) {
    getDetailPeriodConfigs(periodConfigObjIds: $periodConfigObjIds) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupMasterRefId
        additionalInfo {
          objId
          medicalCheckupMasterObjId
        }
        order
        startDate
        endDate
        createdDate
        updatedDate
        evaluations {
          refId
          periodConfigRefId
          order
          name
          desc
          color
          referenceValues {
            refId
            testItemMasterRefId
            periodConfigRefId
            evaluationRefId
            constraint {
              lowerLimit
              upperLimit
              textValue
              type
              evaluationLogic
              gender
              order
            }
          }
        }
      }
    }
  }
`
