import React, { Fragment, memo } from 'react'

interface CreatedRoomsProps {
  readonly tabs: React.ReactNode
  readonly roomList: React.ReactNode
}

function CreatedRooms({ tabs, roomList }: CreatedRoomsProps) {
  return (
    <Fragment>
      {tabs}
      <div className="mt-2">{roomList}</div>
    </Fragment>
  )
}

export default memo(CreatedRooms)
