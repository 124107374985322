import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Flex, Popover, Typography } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import { InputForm } from 'components/elements/InputForm'
import { readingTypeItemMaster, VALUE_WITH_NO_DATA } from 'configs/constant'
import { showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'
import { useMemo } from 'react'

const CollapseItems = ({
  categoryItem,
  showUpdate,
  language,
  showInspectionDescription,
  index
}: {
  categoryItem: DataItemMaster
  showUpdate: boolean
  language: string
  showInspectionDescription: boolean
  index: number
}) => {
  const { t } = useTranslation()
  const Tooltip = ({ text, item }: { text: string; item: DataItemMaster }) => {
    const { readingType = '', unitOfMeasure = '' } = item || {}

    const translateTooltip: Record<string, string> = useMemo(() => (
      {
        "numerical": t("numerical"),
        "string": t("string"),
      }
    ), [t])

    const data = [
      {
        title: t('testResult.unit'),
        value: unitOfMeasure
      },
      {
        title: t('testResult.readingType'),
        value: translateTooltip[readingType.toLowerCase()] ?? readingType
      }
    ]
    return (
      <Popover
        placement="rightTop"
        content={
          <div className="w-[212px] rounded-lg">
            <div className="flex flex-col">
              <Typography className="text-[12px] font-bold tracking-[1.2px] bg-[#EFF3F6] text-primary ps-2 px-2 py-1.5">
                {showName(item, language) ?? VALUE_WITH_NO_DATA}
              </Typography>
              <table className="w-full border-spacing-y-1 border-separate px-2 py-1">
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="px-2 py-1 bg-[#EFF3F6] text-sm tracking-[1.2px]">
                        {item.title}
                      </td>
                      <td className="ps-2">
                        <span className="text-default">{item.value}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
        overlayClassName="popover-permission"
        arrow={false}
      >
        {text}
      </Popover>
    )
  }
  return (
    <Draggable draggableId={categoryItem.refId} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            key={categoryItem.refId}
            className="flex justify-between items-center border-b border-[#BDCBD5] min-h-[30px]"
          >
            <Flex className="flex-1 items-center">
              <Typography
                className={`w-[36.7%] py-2 ${showUpdate ? 'pl-[24px]' : 'pl-[27px]'}`}
              >
                {!showUpdate && (
                  <Tooltip
                    text={
                      showName(categoryItem, language) ?? VALUE_WITH_NO_DATA
                    }
                    item={categoryItem}
                  />
                )}
                {showUpdate && (
                  <InputForm
                    name={`item_${categoryItem.refId}_${language}`}
                    className="h-[22px] p-1"
                  />
                )}
              </Typography>
              <span className="flex-1 text-xs text-[#BDCBD5] tracking-[.6px] text-center">
                {categoryItem?.code?.coding?.[0]?.code}
              </span>
              <Typography className="w-[47%] text-left">
                {!showUpdate &&
                  showInspectionDescription &&
                  (showName(categoryItem, 'en') ?? VALUE_WITH_NO_DATA)}
                {showUpdate && showInspectionDescription && (
                  <InputForm
                    name={`item_${categoryItem.refId}_en2`}
                    className="h-[22px] p-1"
                    placeholder={t('questionnaire.placeholder.englishDisplay')}
                  />
                )}
              </Typography>
            </Flex>

            <span className={`w-[15px] mr-3 ml-2`}>
              <MenuOutlined />
            </span>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default CollapseItems
