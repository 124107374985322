import { useState } from 'react'

import { extractDepartmentsFromCode } from './survey/useSurvey'
import { useLazyQuery } from '@apollo/client'
import {
  CONSULTANT_SORT_TYPE,
  FEEDBACK_LIST_TOTAL,
  FEEDBACK_STATUS,
  GENDER,
  VALUE_WITH_NO_DATA
} from 'configs/constant'
import { format } from 'date-fns'
import { FILTER_CHECKUP_USER } from 'graphql/CheckupUser/filterCheckupUser'
import { FILTER_SURVEY_LIST } from 'graphql/Survey/filter'
import { CONSULTANT_MANAGEMENT_SURVEY_FEEDBACK } from 'graphql/SurveyFeedback/filter'
import { FILTER_TEST_RESULT_CONSULTANT_MANAGEMENT } from 'graphql/TestResult/filterTestResult'
import { Endpoint } from 'utilities/apolloClient'
import {
  calculateAge,
  getTotalAnswerConsultantManagement
} from 'utilities/common'
import { filterNewItemList, getStrKeyByData, isNumber } from 'utilities/helpers'

const useConsultantResults = () => {
  const [data, setData] = useState<any>([])
  const [totalByType, setTotalByType] = useState<any>({})
  const [dataShow, setDataShow] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [firstCall, setFirstCall] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [totalElement, setTotalElement] = useState<number>(0)
  const [queryTestResult] = useLazyQuery(
    FILTER_TEST_RESULT_CONSULTANT_MANAGEMENT
  )
  const [loadCheckupUser] = useLazyQuery(FILTER_CHECKUP_USER)
  const [loadDataSurveyFeedback] = useLazyQuery(
    CONSULTANT_MANAGEMENT_SURVEY_FEEDBACK
  )
  const [loadSurveyList] = useLazyQuery(FILTER_SURVEY_LIST)

  const onClearError = () => {
    setError('')
  }

  const handleGetData = async () => {
    try {
      let users: any = await getCheckupUsers({})
      users = users?.data?.filterCheckupUser?.payload
      if (!Array.isArray(users)) users = []

      let idUsers = getStrKeyByData(users, 'refId')

      let surveyFeedback: any = {}
      let survey: any = {}
      let testResult: any = {}
      if (idUsers) {
        let surveyFeedbackResponse: any = await getSurveyFeedback({
          filter: `(in,STRING,userRefId,${idUsers});(ne,STRING,feedbackStatus,DELETED)`
        })
        surveyFeedbackResponse =
          surveyFeedbackResponse?.data?.filterSurveyFeedback?.payload

        if (!Array.isArray(surveyFeedbackResponse)) surveyFeedbackResponse = []

        surveyFeedback = filterNewItemList(
          surveyFeedbackResponse,
          'userRefId',
          'createdDateTime',
          true
        )

        let idSurveys = getStrKeyByData(surveyFeedbackResponse, 'surveyRefId')
        if (idSurveys) {
          let surveyResponse: any = await getListSurvey({
            filter: `(in,STRING,refId,${idSurveys})`
          })

          surveyResponse = Array.isArray(
            surveyResponse?.data?.filterSurvey?.payload
          )
            ? surveyResponse.data.filterSurvey.payload
            : []

          survey = filterNewItemList(
            surveyResponse,
            'refId',
            'createdDate',
            true
          )
        }

        let testResultResponse: any = await getListTestResult({
          filter: `(in,STRING,medicalCheckupUserRefId,${idUsers});(eq,STRING,status,PUBLISHED)`
        })

        testResultResponse = testResultResponse?.data?.filterTestResult?.payload

        if (!Array.isArray(testResultResponse)) testResultResponse = []

        testResult = filterNewItemList(
          testResultResponse,
          'medicalCheckupUserRefId',
          'readingTakenOn',
          true
        )
      }

      let result: any = []

      for (let user of users) {
        const firstName = user?.additionalInfo?.firstName || ''
        const lastName = user?.additionalInfo?.lastName || ''
        const firstNameKana = user?.additionalInfo?.firstNameKana || ''
        const lastNameKana = user?.additionalInfo?.lastNameKana || ''

        let fullName = `${firstName}${lastName}`.trim()
        let fullNameKana = `${firstNameKana}${lastNameKana}`.trim()

        let feedbackStatus = surveyFeedback?.[user.refId]?.feedbackStatus
        result.push({
          key: user.refId,
          id: user.refId,
          name: fullName + (fullNameKana ? `（${fullNameKana}）` : ''),
          fullName: fullName,
          fullNameKana: fullNameKana,
          dateOfBirth: user.birthday
            ? format(new Date(user.birthday), 'yyyy-MM-dd')
            : VALUE_WITH_NO_DATA,
          age: calculateAge(user.birthday),
          gender:
            user.gender === GENDER.FEMALE
              ? 'customerDetail.header.female'
              : 'customerDetail.header.male',
          sex: user.gender,
          title:
            survey?.[surveyFeedback?.[user.refId]?.surveyRefId]?.displayNameMap
              ?.ja ?? '',
          examDate: testResult?.[user.refId]?.readingTakenOn
            ? format(
                new Date(testResult[user.refId].readingTakenOn),
                'yyyy-MM-dd'
              )
            : VALUE_WITH_NO_DATA,
          status: getContentFeedbackStatus(feedbackStatus),
          statusEnum:
            feedbackStatus && feedbackStatus !== FEEDBACK_STATUS.DRAFT
              ? feedbackStatus
              : VALUE_WITH_NO_DATA,
          statusSort: getStatusSortSurveyFeedback(feedbackStatus),
          createdAt: surveyFeedback?.[user.refId]?.createdDateTime
            ? format(
                new Date(surveyFeedback[user.refId].createdDateTime),
                'yyyy-MM-dd'
              )
            : VALUE_WITH_NO_DATA,
          createdSort:
            surveyFeedback?.[user.refId]?.createdDateTime ?? VALUE_WITH_NO_DATA,
          department: extractDepartmentsFromCode(
            survey?.[surveyFeedback?.[user.refId]?.surveyRefId]?.code ?? ''
          ),
          surveyRefId: surveyFeedback?.[user.refId]?.surveyRefId ?? '',
          surveyFeedbackRefId: surveyFeedback?.[user.refId]?.refId ?? ''
        })
      }

      setData(result)
      setFirstCall(true)
      return result
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const handleSearchData = async ({ ...search }: any) => {
    try {
      onClearError()
      setLoading(true)
      let dataList: any = data
      if (!firstCall) {
        dataList = await handleGetData()
      }

      if (search?.idOrNameUser) {
        dataList = dataList.filter(
          (user: any) =>
            user?.name
              .toLowerCase()
              .includes(search.idOrNameUser.toLowerCase()) ||
            user?.id.toLowerCase().includes(search.idOrNameUser.toLowerCase())
        )
      }

      if (search?.department && search.department.length > 0) {
        dataList = dataList.filter((user: any) => {
          if (user?.department && user.department.length < 1) return false

          for (let i = 0; i < user.department.length; i++) {
            if (search.department.includes(user.department[i])) {
              return true
            }
          }

          return false
        })
      }

      if (search?.gender && search.gender.length > 0) {
        dataList = dataList.filter((user: any) =>
          search.gender.includes(user?.sex)
        )
      }

      if (isNumber(search?.ageStart)) {
        dataList = dataList.filter((user: any) => user?.age >= search.ageStart)
      }
      if (isNumber(search?.ageEnd)) {
        dataList = dataList.filter((user: any) => user?.age <= search.ageEnd)
      }

      dataList = dataList.sort((a: any, b: any) => {
        let comparison = 0
        switch (search.sortBy) {
          case CONSULTANT_SORT_TYPE.USER_ID:
            comparison = a.id.localeCompare(b.id)
            break
          case CONSULTANT_SORT_TYPE.USER_NAME:
            comparison = a.name.localeCompare(b.name)
            break
          case CONSULTANT_SORT_TYPE.USER_DATE_OF_BIRTH:
            comparison = getComparisonDate(a, b, 'dateOfBirth')
            break
          case CONSULTANT_SORT_TYPE.USER_AGE:
            comparison = getComparisonDate(a, b, 'dateOfBirth')
            comparison = -comparison
            break
          case CONSULTANT_SORT_TYPE.USER_GENDER:
            comparison = a.gender.localeCompare(b.gender)
            break
          case CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_TITLE:
            comparison = a.title.localeCompare(b.title)
            break
          case CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_EXAM_DATE:
            comparison = getComparisonDate(a, b, 'examDate')
            break
          case CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_STATUS:
            comparison = a.statusSort - b.statusSort
            break
          case CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_CREATE_AT:
            comparison = getComparisonDate(a, b, 'createdSort')
            break
          default:
            console.error('Invalid sortBy value')
            break
        }

        return search.orderBy === 'desc' ? -comparison : comparison
      })

      setTotalByType({
        [FEEDBACK_LIST_TOTAL.ALL]: dataList.length,
        [FEEDBACK_LIST_TOTAL.UNANSWERED]:
          getTotalAnswerConsultantManagement(dataList),
        [FEEDBACK_LIST_TOTAL.ASSIGNED]: getTotalAnswerConsultantManagement(
          dataList,
          FEEDBACK_STATUS.ASSIGNED
        ),
        [FEEDBACK_LIST_TOTAL.COMPLETED]: getTotalAnswerConsultantManagement(
          dataList,
          FEEDBACK_STATUS.COMPLETED
        )
      })

      if (search?.status) {
        dataList = dataList.filter(
          (user: any) => user?.statusEnum === search.status
        )
      }
      setTotalElement(dataList.length)
      const startIndex = (search.page - 1) * search.perPage
      const endIndex = startIndex + search.perPage

      dataList = dataList.slice(startIndex, endIndex)
      setDataShow(dataList)
    } catch (error) {
      setError((error as Error).message)
    }

    setLoading(false)
  }

  const getComparisonDate = (a: any, b: any, field: any) => {
    let comparison = 0
    let dateA: any =
      !a[field] || a[field] === VALUE_WITH_NO_DATA ? null : new Date(a[field])
    let dateB: any =
      !a[field] || b[field] === VALUE_WITH_NO_DATA ? null : new Date(b[field])

    if (dateA === null && dateB === null) {
      comparison = 0
    } else if (dateA === null) {
      comparison = -1
    } else if (dateB === null) {
      comparison = 1
    } else {
      comparison = dateA - dateB
    }

    return comparison
  }

  const getContentFeedbackStatus = (status: any) => {
    if (!status || status === FEEDBACK_STATUS.DRAFT) return 'lable.unanswered'

    return status === FEEDBACK_STATUS.COMPLETED
      ? 'lable.answered'
      : 'lable.waitingAnswer'
  }

  const getCheckupUsers = async (variables: any) => {
    try {
      const response = await loadCheckupUser({
        variables: {
          filter: `(eq,STRING,status,PUBLISHED);(like,STRING,additionalInfo.isDeleted,false)`,
          sortBy: '',
          page: 0,
          size: -1,
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      return response
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getSurveyFeedback = async (variables: any) => {
    try {
      const response = await loadDataSurveyFeedback({
        variables: {
          filter: '',
          sortBy: '',
          ...variables
        },
        context: { version: Endpoint.SURVEY_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
      })

      return response
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getListTestResult = async (variables: any) => {
    try {
      const response = await queryTestResult({
        variables: {
          filter: '',
          sortBy: '',
          page: 0,
          size: -1,
          ...variables
        },

        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return response
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getListSurvey = async (variables: any) => {
    try {
      const response = await loadSurveyList({
        variables: {
          filter: '',
          sortBy: '',
          ...variables
        },
        context: { version: Endpoint.SURVEY_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
      })

      return response
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getStatusSortSurveyFeedback = (status: any) => {
    let order: any = 0

    switch (status) {
      case FEEDBACK_STATUS.ASSIGNED:
        order = 2
        break
      case FEEDBACK_STATUS.DRAFT:
        order = 1
        break
      case FEEDBACK_STATUS.COMPLETED:
        order = 3
        break
    }

    return order
  }
  return {
    handleSearchData,
    dataShow,
    loading,
    totalByType,
    error,
    totalElement
  }
}

export default useConsultantResults
