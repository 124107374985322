import { useCallback } from 'react'

import { DocumentNode } from 'graphql'

import { gql, useMutation } from '@apollo/client'
import {
  DataItemMaster,
  UpdateItemMaster
} from 'pages/InspectionItemSetting/types/filterItemMaster'
import { StringKeyObject } from 'types/common'

export const UPDATE_ITEM_MASTER: DocumentNode = gql`
  mutation updateItemMaster($request: [UpdateItemMaster]) {
    updateItemMaster(request: $request) {
      status
      message
      payload {
        refId
        additionalInfo
      }
    }
  }
`

export const UPDATE_ITEM_CATEGORY: DocumentNode = gql`
  mutation updateTestItemCategory($request: [UpdateTestItemCategory]!) {
    updateTestItemCategory(request: $request) {
      status
      message
      payload {
        refId
        additionalInfo
      }
    }
  }
`
export interface UpdateMedicalCheckupMasterRequest {
  refId: string
  displayName: string
  associatedTestItemMasters?: string[]
  associatedPatientInfoMasters?: string[]
  additionalInfo: StringKeyObject
  shortName?: string
}
const useMutationCourseTestItem = () => {
  const [updateItemMaster] = useMutation(UPDATE_ITEM_MASTER)

  const [updateItemCategory] = useMutation(UPDATE_ITEM_CATEGORY)

  const handleUpdateItemMaster = useCallback(
    async (request: UpdateItemMaster[]) => {
      return await updateItemMaster({
        variables: { request },
        // context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    },
    [updateItemMaster]
  )

  const handleUpdateItemCategory = useCallback(
    async (
      listItemCategory: DataItemMaster[],
      additionalInfo: StringKeyObject
    ) => {
      const dataUpdate = listItemCategory.map((item) => ({
        refId: item.refId,
        displayName: item.displayName,
        associatedTestItemMasterRefIds: item.associatedTestItemMasterRefIds,
        shortName: item.shortName,
        additionalInfo: {
          ...item.additionalInfo,
          ...additionalInfo
        }
      }))
      return await updateItemCategory({
        variables: { request: dataUpdate },
        // context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    },
    [updateItemCategory]
  )

  const mappingDataUpdateItemMaster = (
    refIds: string[],
    originalListLanguage: DataItemMaster[],
    objId: string
  ) => {
    const listUpdate = refIds.map((refId) => {
      // find item master by refId
      const item = originalListLanguage.find((i) => i.refId === refId)
      const localizedName = item?.additionalInfo?.localizedName || item?.lang
      return {
        refId: item?.refId ?? '',
        displayName: item?.displayName ?? '',
        referenceValues:
          (item?.referenceValues ?? []).map((it) => {
            const { _class, ...rest } = it || {}
            return rest
          }) ?? [],
        additionalInfo: {
          ...item?.additionalInfo,
          medicalCheckupMasterObjId: objId,
          localizedName
        }
      }
    })
    return listUpdate
  }

  return {
    handleUpdateItemMaster,
    handleUpdateItemCategory,
    mappingDataUpdateItemMaster
  }
}

export default useMutationCourseTestItem
