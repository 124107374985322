import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import { CustomDatePicker } from '../../../components/elements/CustomDatePicker'
import LocationSetting from '../../../components/screens/BasicSetting/components/LocationSetting'
import { COUNTRY } from '../../../models/setting'
import SelectPhoneNumber from '../../CustomerDetail/components/SelectPhoneNumber'
import { InputForm as RegisterForm } from '../CustomerRegister'
import InputLabel from './InputLabel'
import TextArea from 'antd/es/input/TextArea'
import { InputForm } from 'components/elements/InputForm'

export interface CustomerEditFormMethods {
  onSubmit: () => void
}

type Props = {
  t: TFunction<'translation', undefined>
  initialData?: RegisterForm
  onSubmit: (data: RegisterForm) => void
}

const CustomerEditForm = forwardRef<CustomerEditFormMethods, Props>(
  ({ t, initialData, onSubmit }: Props, ref) => {
    const methods = useForm<RegisterForm>({
      defaultValues: {
        companyName: initialData?.companyName,
        id: initialData?.id,
        firstName: initialData?.firstName,
        lastName: initialData?.lastName,
        firstNameKana: initialData?.firstNameKana,
        lastNameKana: initialData?.lastNameKana,
        gender: initialData?.gender ?? 'MALE',
        birthday: initialData?.birthday,
        zipCode: initialData?.zipCode,
        country: initialData?.country ?? 'ja',
        province: initialData?.province,
        district: initialData?.district,
        detailedAddress: initialData?.detailedAddress,
        apartment: initialData?.apartment,
        stateName: initialData?.stateName,
        dialCodePhoneNumber: initialData
          ? initialData.dialCodePhoneNumber
          : '81-Japan',
        phoneNumber: initialData?.phoneNumber,
        dialCodeEmergencyContactNumber: initialData
          ? initialData.dialCodeEmergencyContactNumber
          : '81-Japan',
        emergencyContactNumber: initialData?.emergencyContactNumber,
        email: initialData?.email,
        typeOfInsurance: initialData?.typeOfInsurance,
        numberOfInsurance: initialData?.numberOfInsurance,
        insuranceSymbol: initialData?.insuranceSymbol,
        insuranceNumber: initialData?.insuranceNumber,
        collaborativeApplication: initialData?.collaborativeApplication,
        userID: initialData?.userID,
        applicationStartDate: initialData?.applicationStartDate,
        note: initialData?.note,
        foreignInsuranceCompany: initialData?.foreignInsuranceCompany,
        drugAllergy: initialData?.drugAllergy,
        address: initialData?.address
      }
    })
    const country = methods.watch('country')

    useEffect(() => {
      if (country === COUNTRY.JAPAN) {
        methods.setValue('stateName', undefined)
      }
    }, [country])

    const [selected, setSelected] = useState<boolean>(
      !!initialData?.drugAllergy
    )
    const [selectedGender, setSelectedGender] = useState<boolean>(
      initialData?.gender === 'MALE' || initialData?.gender === undefined
    )

    useImperativeHandle(ref, () => ({
      onSubmit: () => {
        methods.handleSubmit(onSubmit)()
      }
    }))

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value
      e.currentTarget.value = inputValue.replace(/[^0-9]/g, '')
    }

    const handleFormSubmit = (data: any) => {
      onSubmit(data)
    }

    return (
      <div className="min-w-[1200px] rounded-[5px] bg-white pb-[26px] pl-[10px] pt-[10px] text-[14px] text-[#545454] drop-shadow-lg">
        <Flex className="mb-5 mt-2">
          <Typography className="mr-[50px] text-base font-bold tracking-[1.6px] text-[#137695]">
            {t('customerRegister.displayInfo.basicInformation')}
          </Typography>
        </Flex>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            {/*0*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.companyName')}
                className="h-[48px] w-[185px]  font-bold"
              />

              <div className="ml-2">
                <InputForm
                  rules={{ maxLength: 50 }}
                  name="companyName"
                  className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                />

                <Typography className="text-[10px] tracking-[0.5px]">
                  {t('customerRegister.notice.companyNote')}
                </Typography>
              </div>
            </Flex>

            {/*1*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.id')}
                required
                className="h-[36px] w-[185px] font-bold"
              />
              <InputForm
                name="id"
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: ''
                  },
                  maxLength: 50
                }}
                className="ml-2 h-[30px] w-[400px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*2 + 3*/}
            <Flex className="mb-[6px]">
              <Flex>
                <InputLabel
                  label={t('customerRegister.label.firstName')}
                  required
                  className="h-[48px] w-[185px]  font-bold"
                />

                <div className="ml-2">
                  <InputForm
                    name="firstName"
                    rules={{
                      required: true,
                      maxLength: 50
                    }}
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />

                  <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>

              <Flex className="ml-[30px]">
                <InputLabel
                  label={t('customerRegister.label.lastName')}
                  required
                  className="h-[48px] w-[185px]  font-bold"
                />

                <div className="ml-2">
                  <InputForm
                    name="lastName"
                    rules={{
                      required: true,
                      maxLength: 50
                    }}
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />

                  <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>
            </Flex>

            {/*4 + 5*/}
            <Flex className="mb-[6px]">
              <Flex>
                <InputLabel
                  label={t('customerRegister.label.firstNameKana')}
                  className="h-[48px] w-[185px]  font-bold"
                />

                <div className="ml-2">
                  <InputForm
                    rules={{
                      maxLength: 50
                    }}
                    name="firstNameKana"
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />

                  <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>

              <Flex className="ml-[30px]">
                <InputLabel
                  label={t('customerRegister.label.lastNameKana')}
                  className="h-[48px] w-[185px]  font-bold"
                />

                <div className="ml-2">
                  <InputForm
                    rules={{
                      maxLength: 50
                    }}
                    name="lastNameKana"
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />

                  <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>
            </Flex>

            {/*19*/}
            <Flex align="center" className="mb-[6px] h-full">
              <InputLabel
                label={t('customerRegister.label.gender')}
                className="h-[36px] w-[185px]  font-bold"
              />

              <div>
                <Flex align="center" className="ml-2 min-h-[49px] w-[200px]">
                  <Button
                    autoInsertSpace={false}
                    onClick={() => {
                      setSelectedGender(true)
                      methods.setValue('gender', 'MALE')
                    }}
                    className="w-[100px] rounded-l-[5px] border-r-0"
                    style={{
                      backgroundColor: selectedGender ? '#137695' : '',
                      color: selectedGender ? 'white' : '',
                      border: selectedGender ? '#137695' : ''
                    }}
                  >
                    {t('lable.male')}
                  </Button>
                  <Button
                    autoInsertSpace={false}
                    onClick={() => {
                      setSelectedGender(false)
                      methods.setValue('gender', 'FEMALE')
                    }}
                    style={{
                      backgroundColor: !selectedGender ? '#137695' : '',
                      color: !selectedGender ? 'white' : '',
                      border: !selectedGender ? '#137695' : ''
                    }}
                    className="w-[100px] rounded-r-[5px] border-l-0"
                  >
                    {t('lable.female')}
                  </Button>
                </Flex>
              </div>
            </Flex>

            {/*6*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.birthday')}
                required
                className="h-[36px] w-[185px]  font-bold"
              />
              <CustomDatePicker
                name="birthday"
                format="YYYY/MM/DD"
                rules={{
                  required: true
                }}
                placeholder={t('customerRegister.placeholder.selectDate')}
                className="ml-2 h-[30px] w-[185px] border-[1px] border-[#137695]"
              />
            </Flex>

            {/*7 + 8 + 9 + 10*/}
            <Flex className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.address')}
                className="w-[185px] font-bold"
              />

              <div className="ml-2" />
              <LocationSetting />
            </Flex>

            {/*11*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.phoneNumber')}
                className="mr-2 h-[36px] w-[185px]  font-bold"
              />
              <SelectPhoneNumber name="dialCodePhoneNumber" />
              <InputForm
                rules={{
                  maxLength: 15
                }}
                onInput={handleInput}
                name="phoneNumber"
                placeholder={t(
                  'customerRegister.placeholder.enterWithoutHyphens'
                )}
                className="ml-2 h-[30px] w-[273px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*12*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.emergencyContactNumber')}
                className="mr-2 h-[36px] w-[185px]  font-bold"
              />
              <SelectPhoneNumber name="dialCodeEmergencyContactNumber" />
              <InputForm
                rules={{
                  maxLength: 15
                }}
                onInput={handleInput}
                name="emergencyContactNumber"
                placeholder={t(
                  'customerRegister.placeholder.enterWithoutHyphens'
                )}
                className="ml-2 h-[30px] w-[273px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*13*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.email')}
                className="h-[36px] w-[185px]  font-bold"
              />
              <InputForm
                rules={{
                  maxLength: 50,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: ''
                  }
                }}
                name="email"
                className="ml-2 h-[30px] w-[400px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*14*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.typeOfInsurance')}
                className="h-[36px] w-[185px]  font-bold"
              />
              <InputForm
                rules={{
                  maxLength: 50
                }}
                name="typeOfInsurance"
                className="ml-2 h-[30px] w-[400px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*15*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.numberOfInsurance')}
                className="h-[36px] w-[185px]  font-bold"
              />
              <InputForm
                rules={{
                  maxLength: 50
                }}
                onInput={handleInput}
                name="numberOfInsurance"
                className="ml-2 h-[30px] w-[122px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*16 + 17*/}
            <Flex className="mb-[6px]">
              <Flex align="center" className="mr-[5px]">
                <InputLabel
                  label={t('customerRegister.label.insuranceSymbol')}
                  className="h-[36px] w-[185px]  font-bold"
                />
                <InputForm
                  rules={{
                    maxLength: 50
                  }}
                  onInput={handleInput}
                  name="insuranceSymbol"
                  className="ml-2 h-[30px] w-[122px] px-3 tracking-[1.4px]"
                />
              </Flex>

              <Flex align="center">
                <InputLabel
                  label={t('customerRegister.label.insuranceNumber')}
                  className="h-[36px] w-[146px] font-bold"
                />
                <InputForm
                  rules={{
                    maxLength: 50
                  }}
                  onInput={handleInput}
                  name="insuranceNumber"
                  className="ml-[5px] h-[30px] w-[122px] px-3 tracking-[1.4px]"
                />
              </Flex>
            </Flex>

            {/*18*/}
            <Flex align="center" className="mb-[6px]">
              <Flex
                justify="space-between"
                align="center"
                className="h-auto w-[185px] font-bold bg-[#F0F3F7] px-[7px] self-stretch"
              >
                <Typography className="text-sm tracking-[1.4px] text-[#545454]">
                  {
                    t('customerRegister.label.foreignInsuranceCompany').split(
                      '_'
                    )[0]
                  }
                  <br />
                  {t('customerRegister.label.foreignInsuranceCompany')
                    .split('_')
                    .slice(1)}
                </Typography>
              </Flex>

              <div className="ml-2">
                <InputForm
                  rules={{
                    maxLength: 50
                  }}
                  name="foreignInsuranceCompany"
                  className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                />

                <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                  {t('customerRegister.notice.need')}
                </Typography>
              </div>
            </Flex>

            {/*19*/}
            <Flex align="center" className="mb-[6px] h-auto">
              {/*label*/}
              <Flex
                justify="space-between"
                align="center"
                className="h-auto w-[185px] font-bold bg-[#F0F3F7] px-[7px] self-stretch"
              >
                <Typography className="text-[14px] tracking-[1.4px] text-[#545454] whitespace-break-spaces">
                  {t('customerRegister.label.drugAllergy')}
                </Typography>
              </Flex>

              <div className="flex flex-col ml-2">
                <Flex align="center" className="min-h-[49px] h-auto w-[200px]">
                  <Button
                    autoInsertSpace={false}
                    onClick={() => setSelected(true)}
                    className="w-[100px] rounded-l-[5px] border-r-0"
                    style={{
                      backgroundColor: selected ? '#137695' : '',
                      color: selected ? 'white' : '',
                      border: selected ? '#137695' : ''
                    }}
                  >
                    {t('content.canBe')}
                  </Button>
                  <Button
                    autoInsertSpace={false}
                    onClick={() => {
                      setSelected(false)
                      methods.setValue('drugAllergy', undefined)
                    }}
                    style={{
                      backgroundColor: !selected ? '#137695' : '',
                      color: !selected ? 'white' : '',
                      border: !selected ? '#137695' : ''
                    }}
                    className="w-[100px] rounded-r-[5px] border-l-0"
                  >
                    {t('content.none')}
                  </Button>
                </Flex>

                {selected && (
                  <Flex align="center">
                    <Typography className="ml-3 mr-2">
                      {t('lable.medicineName')}
                    </Typography>
                    <InputForm
                      rules={{
                        maxLength: 50
                      }}
                      name="drugAllergy"
                      className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                    />
                  </Flex>
                )}
              </div>
            </Flex>

            {/*20*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.collaborativeApplication')}
                className="h-[36px] w-[185px]  font-bold"
              />
              {methods.getValues('collaborativeApplication') ? (
                <></>
              ) : (
                <Flex
                  justify="center"
                  align="center"
                  className="ml-2 h-[26px] w-[103px] rounded-[13px] bg-[#BDCBD5] text-white"
                >
                  {t('customerRegister.label.none')}
                </Flex>
              )}
            </Flex>

            {/*21*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.userID')}
                className="h-[36px] w-[185px]  font-bold"
              />
              {methods.getValues('userID') ? (
                <InputForm
                  name="userID"
                  className="ml-2 h-[30px] w-[122px] px-3 tracking-[1.4px]"
                />
              ) : (
                <div className="ml-3 h-[1.5px] w-[10px] bg-[#545454]" />
              )}
            </Flex>

            {/*22*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.applicationStartDate')}
                className="h-auto w-[185px] font-bold self-stretch"
              />
              <div className="ml-3 h-[1.5px] w-[10px] bg-[#545454]" />
            </Flex>

            {/*23*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.note')}
                className="h-[138px] w-[185px]  font-bold"
              />

              <Controller
                name="note"
                control={methods.control}
                rules={{
                  maxLength: 400
                }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    className="ml-2 h-[138px] w-[1031px] px-3"
                  />
                )}
              />
            </Flex>
          </form>
        </FormProvider>
      </div>
    )
  }
)

export default CustomerEditForm
