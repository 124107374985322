import React from 'react'
import { useTranslation } from 'react-i18next'

import { Option } from 'pages/Scenario/types/types'

export interface FixedQuestionProps {
  order?: number
  step?: string
  content?: string
  description?: string
  type?: string
  selectionType?: string
  options?: Option[]
  setting?: {
    choiceType?: string
    isMultiple?: boolean
  }
}

function FixedQuestion({ step, description }: FixedQuestionProps) {
  const { t } = useTranslation()

  return (
    <div className="w-[280px] overflow-hidden max-w-[280px] rounded-[10px] shadow-md">
      <div className="bg-default flex justify-center items-center py-2">
        {step && (
          <span className="text-sm text-white font-bold">{t(step)}</span>
        )}
      </div>
      {description && (
        <p className="text-center text-xs text-default p-4 min-h-20 bg-contentGray">
          {t(description)}
        </p>
      )}
    </div>
  )
}

export default FixedQuestion
