import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import { ConfigProvider, Spin, notification } from 'antd'
import dayjs from 'dayjs'
import { RecoilRoot } from 'recoil'

import './App.css'
import { ProtectedRoute } from './components/ProtectedRoute'
import './configs/recoil'
import AppApolloProvider from './context/ApolloProvider'
import AuthProvider from './context/AuthProvider'
import PublicLayout from './layouts/PublicLayout'
import emitter from './models/emitter'
import ConsultantManagement from './pages/ConsultantManagement'
import CustomerManagement from './pages/CustomerManagement'
import CustomerRegister from './pages/CustomerRegister'
import InterviewResultCreate from './pages/InterviewResultCreate'
import JudgementSetting from './pages/JudgementSetting'
import MonthlyConsultationReport from './pages/MonthlyConsultationReport'
import ProductDuplication from './pages/ProductDuplication'
import ProductManagement from './pages/ProductManagement'
import ProductSetting from './pages/ProductSetting'
import ReservationChange from './pages/ReservationChange'
import ReservationManagement from './pages/ReservationManagement'
import ReservationRegister from './pages/ReservationRegister'
import RuleLogical from './pages/RuleLogical'
import SurveyCreation from './pages/SurveyCreation'
import SurveyEdit from './pages/SurveyEdit'
import SurveyList from './pages/SurveyList'
import YearlyConsultationReport from './pages/YearlyConsultationReport'
import en from 'antd/locale/en_US'
import ja from 'antd/locale/ja_JP'
import vi from 'antd/locale/vi_VN'
import { MAX_TIME_TO_RETRY, STORAGE_KEYS } from 'configs/constant'
import { ASSESSMENT_COMMENT, OPTION_FORM } from 'configs/pathRouter'
import { connectToRocketChat, disconnectToRocketChat } from 'configs/rocketChat'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import 'dayjs/locale/vi'
import { useRolePermissions } from 'hooks/role-permission/usePermissions'
import AuthenticationLayout from 'layouts/AuthenticationLayout'
import HomeLayout from 'layouts/HomeLayout/HomeLayout'
import AdminList from 'pages/AdminList'
import AdminRegister from 'pages/AdminRegister'
import BasicSetting from 'pages/BasicSetting'
import ChatPage from 'pages/Chat/Chat'
import CourseRegister from 'pages/CourseRegister'
import CustomerDetail from 'pages/CustomerDetail'
import ForgotPassWord from 'pages/ForgotPassWord'
import InspectionItemSetting from 'pages/InspectionItemSetting'
import Login from 'pages/Login'
import MyPage from 'pages/MyPage'
import PasswordResetSuccess from 'pages/PasswordResetSuccess'
import PasswordVerifyCode from 'pages/PasswordVerifyCode'
import ProductMaster from 'pages/ProductMaster'
import OptionForm from 'pages/ProductMaster/optionForm'
import ReservationDetail from 'pages/Reservation/views/ReservationDetail'
import ResetPassword from 'pages/ResetPassword'
import ScenarioCreate from 'pages/Scenario/ScenarioCreate'
import ScenarioList from 'pages/Scenario/ScenarioList'
import SelectTenant from 'pages/SelectTenant/SelectTenant'
import SurveyDetail from 'pages/SurveyDetail'

const language: any = {
  vi: vi,
  ja: ja,
  en: en
}

function App() {
  let retryConnectRocket = MAX_TIME_TO_RETRY

  const { i18n } = useTranslation()

  notification.config({
    closeIcon: false,
    duration: 3,
    maxCount: 5
  })

  useEffect(() => {
    try {
      const { adminToken, adminUserId } = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
      )

      if (adminToken && adminUserId) {
        connectToRocketChat()
        return () => {
          disconnectToRocketChat()
        }
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    // @ts-ignore
    emitter.on('SHOULD_RELOAD', onReloadPage)
    return () => {
      // @ts-ignore
      emitter.off('SHOULD_RELOAD', onReloadPage)
    }
  }, [])

  const onReloadPage = (shouldReload: any) => {
    if (shouldReload && retryConnectRocket > 0) {
      connectToRocketChat()
      retryConnectRocket = retryConnectRocket - 1
    }
  }

  useEffect(() => {
    dayjs.locale(i18n.language ?? 'ja')
  }, [i18n.language])

  return (
    <AppApolloProvider>
      <ConfigProvider
        locale={language[i18n.language ?? 'ja']}
        theme={{
          token: {
            colorPrimary: '#137695',
            colorTextPlaceholder: '#D9E0E5',
            colorTextBase: '#545454',
            borderRadius: 2,
            controlHeight: 30,
            colorBgMask: '#00000057'
          },
          components: {
            Button: {
              colorPrimary: '#137695'
            },
            Input: {
              colorPrimary: '#137695'
            },
            Typography: {
              colorPrimary: '#137695'
            },
            Radio: {
              buttonColor: '#BDCBD5',
              buttonSolidCheckedHoverBg: '#DF2475',
              buttonSolidCheckedBg: '#DF2475'
            },
            Tree: {
              nodeHoverBg: 'transparent'
            },
            Layout: {
              siderBg:
                'transparent linear-gradient(180deg, #127594 0%, #93D1CA 100%) 0% 0% no-repeat padding-box;'
            }
          }
        }}
      >
        <RecoilRoot>
          <React.Suspense
            fallback={
              <div className="flex justify-center items-center h-[100vh] w-full">
                <Spin spinning={true} />
              </div>
            }
          >
            <AuthProvider>
              <Routes>
                <Route path="/" element={<HomeLayout />}>
                  <Route index element={<DefaultDisplay />} />
                  <Route path="setting" element={<></>} />
                  <Route
                    path="consultant-management"
                    element={
                      <ProtectedRoute
                        permission="medical_survey_feedback"
                        children={<ConsultantManagement />}
                      />
                    }
                  />
                  <Route
                    path="survey/:refid/detail"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyDetail />}
                      />
                    }
                  />
                  <Route
                    path="survey/:refid/edit"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyEdit />}
                      />
                    }
                  />
                  <Route
                    path="survey-creation"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyCreation />}
                      />
                    }
                  />
                  <Route path="basic-setting" element={<BasicSetting />} />
                  <Route
                    path="inspection-item-settings"
                    element={
                      <ProtectedRoute
                        permission="setting_inspection"
                        children={<InspectionItemSetting />}
                      />
                    }
                  />
                  <Route
                    path={ASSESSMENT_COMMENT}
                    element={<JudgementSetting />}
                  />
                  <Route path="inspection-setting" element={<></>} />
                  <Route
                    path="admin-list"
                    element={
                      <ProtectedRoute
                        permission="setting_role"
                        children={<AdminList />}
                      />
                    }
                  />
                  <Route path="admin-register" element={<AdminRegister />} />
                  <Route
                    path="course-register"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<CourseRegister />}
                      />
                    }
                  />
                  <Route path={OPTION_FORM} element={<OptionForm />} />
                  <Route
                    path="product-master"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductMaster />}
                      />
                    }
                  />
                  <Route path="chat" element={<ChatPage />} />
                  <Route
                    path="interview-result-create"
                    element={<InterviewResultCreate />}
                  />
                  <Route
                    path="customer-management"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerManagement />}
                      />
                    }
                  />
                  <Route
                    path="reservation-management"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationManagement />}
                      />
                    }
                  />
                  <Route
                    path="reservation-register"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationRegister />}
                      />
                    }
                  />
                  <Route
                    path="reservation-change"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationChange />}
                      />
                    }
                  />
                  <Route
                    path="/reservation-detail"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationDetail />}
                      />
                    }
                  />
                  <Route
                    path="product-management"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductManagement />}
                      />
                    }
                  />
                  <Route
                    path="product-setting"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductSetting />}
                      />
                    }
                  />
                  <Route
                    path="product-duplication"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductDuplication />}
                      />
                    }
                  />
                  <Route
                    path="yearly-consultation-report"
                    element={
                      <ProtectedRoute
                        permission="health_support_claim"
                        children={<YearlyConsultationReport />}
                      />
                    }
                  />
                  <Route
                    path="monthly-consultation-report"
                    element={
                      <ProtectedRoute
                        permission="health_support_claim"
                        children={<MonthlyConsultationReport />}
                      />
                    }
                  />
                  <Route
                    path="customer-detail"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerDetail />}
                      />
                    }
                  />
                  <Route
                    path="customer-register"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerRegister />}
                      />
                    }
                  />
                  <Route path="my-page" element={<MyPage />} />
                  <Route
                    path="survey-list"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyList />}
                      />
                    }
                  />
                  <Route path="/scenario" element={<ScenarioList />}></Route>
                  <Route
                    path="/scenario/create"
                    element={<ScenarioCreate />}
                  ></Route>
                  <Route
                    path="/scenario/:refId/edit"
                    element={<ScenarioCreate />}
                  ></Route>
                </Route>
                <Route element={<AuthenticationLayout />}>
                  <Route
                    path="/select-tenant"
                    element={<SelectTenant />}
                  ></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route
                    path="/forgot-password"
                    element={<ForgotPassWord />}
                  ></Route>
                  <Route
                    path="/password-verify-code"
                    element={<PasswordVerifyCode />}
                  ></Route>
                  <Route
                    path="/reset-password"
                    element={<ResetPassword />}
                  ></Route>
                  <Route
                    path="/password-reset-success"
                    element={<PasswordResetSuccess />}
                  ></Route>
                </Route>
                <Route element={<PublicLayout />}>
                  <Route path="/rule-logical" element={<RuleLogical />} />
                </Route>
              </Routes>
            </AuthProvider>
          </React.Suspense>
        </RecoilRoot>
      </ConfigProvider>
    </AppApolloProvider>
  )
}

const DefaultDisplay = () => {
  const { hasPermissions } = useRolePermissions()

  if (hasPermissions('customer_management')) return <CustomerManagement />
  if (hasPermissions('medical_survey_feedback')) return <ConsultantManagement />

  return <SurveyList />
}

export default App
