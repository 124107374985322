import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Typography } from 'antd'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals'
import { openNotification } from 'components/widgets/Notification'
import { OPERATION_TYPE, RESOURCES_TENANT } from 'configs/constant'
import { StringKeyObject } from 'types/common'

const { Paragraph } = Typography

export type DataForm = {
  positionName: string
}

type Props = {
  positions: StringKeyObject[]
  isModalOpen: boolean
  onCancel: () => void
  setIsCreateSuccess: (data: boolean) => void
  onSuccess: Function
}

const schema = yup
  .object()
  .shape({
    positionName: yup.string().max(50).required()
  })
  .required()

const CreatePositionModal = ({
  isModalOpen = false,
  positions,
  onCancel,
  setIsCreateSuccess,
  onSuccess
}: Props) => {
  const { t } = useTranslation()

  const methods = useForm<DataForm>({
    defaultValues: {
      positionName: ''
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = methods

  useEffect(() => {
    if (isModalOpen) {
      methods.reset()
    }
  }, [isModalOpen])

  const onSubmit = async (data: DataForm) => {
    const name = data.positionName.trim()

    const isExits = positions.find((item) => item.value.trim() === name)
    if (isExits) {
      openNotification({
        type: 'error',
        title: 'commonError',
        message: t('errors.positionDuplicateError')
      })
      return
    }

    onCancel()

    await onSuccess(OPERATION_TYPE.CREATE, RESOURCES_TENANT.POSITIONS, name)

    setIsCreateSuccess(true)
  }

  const handleOk = () => {
    //todo
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <div className="text-center pt-3 pb-7 px-6">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('positionNewAddition')}
        </Typography>

        <div className="mt-12 pl-[17%] text-left">
          <Paragraph className="mb-0">
            {t('content.enterPositionNameToAdd')}
          </Paragraph>
          <Paragraph className="mb-0 text-error">
            {t('content.doNotEnterExistingPositionName')}
          </Paragraph>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex className="mt-[130px] gap-2">
              <Col flex="148px">
                <InputLabel label={t('table.positionName')} className="!py-0" />
              </Col>
              <Col flex="auto">
                <InputForm name="positionName" />
              </Col>
            </Flex>

            <Flex className="justify-center gap-3 mt-[130px]">
              <Button
                type="primary"
                className="min-w-[180px] font-bold"
                htmlType="submit"
              >
                {t('button.registration')}
              </Button>
              <Button
                className="min-w-[180px] border-[#137695] text-[#137695] font-bold"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </div>
    </CustomModal>
  )
}

export default CreatePositionModal
