import React, { useState } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Alert,
  Button,
  Col,
  Flex,
  Layout,
  Radio,
  RadioChangeEvent,
  Row,
  Typography
} from 'antd'

import { CheckableTagForm } from '../../components/elements/CheckableTagForm'
import { InputForm } from '../../components/elements/InputForm'
import { HiddenEnglishDisplay } from '../../components/elements/Popconfirm/HiddenEnglishDisplay'
import { HiddenTooltipPopconfirm } from '../../components/elements/Popconfirm/HiddenTooltipPopconfirm'
import { UnhideTooltipPopconfirm } from '../../components/elements/Popconfirm/UnhideTooltipPopconfirm'
import CreateSucceededModal from '../../components/modals/CreateSucceededModal'
import {
  QuestionItemCard,
  QuestionItemHiddenCard,
  SectionItemCreateCard
} from '../../components/screens/Questionnaires'
import SpinLoading from '../../components/screens/Spin/SpinLoading'
import {
  DeleteModal,
  DisplayNameEnToggle
} from '../../components/screens/SurveyCreateUpdate'
import { openNotification } from '../../components/widgets/Notification'
import { STATUS_CODE_SUCCESS } from '../../configs/constant'
import { useAuth } from '../../context/AuthProvider'
import {
  useDeleteSurvey,
  useDetailSurvey,
  useFixedSurvey,
  useUpdateSurvey,
  validatedSurveyUpdate
} from '../../hooks/survey'
import {
  TypeSchemas as SurveyInputForm,
  schemas
} from '../../hooks/survey/schemas'
import { useModalState } from '../../hooks/survey/useDeleteSurvey'
import useSurvey, {
  extractDepartmentsFromCode,
  initSubsectionList,
  parseDepartments
} from '../../hooks/survey/useSurvey'
import { useSubTenantList } from '../../hooks/tenant/useTenantList'
import { useQuestionnaires } from '../../hooks/useQuestionnaires'
import {
  StatusEnums,
  SubsectionTypeEnums,
  SurveyStatusEnums
} from '../../models/survey'
import { forceTypeBoolean, getUserByToken } from '../../utilities/helpers'
import SurveyCreateConfirmation from '../SurveyCreateConfirmation'
import '../SurveyCreation/SurveyCreation.css'
import { yupResolver } from '@hookform/resolvers/yup'
import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'
import { ReactComponent as MenuFoldOutlined } from 'assets/svgs/MenuFoldOutlined.svg'
import { ReactComponent as ArrowRight } from 'assets/svgs/arrow-right.svg'
import { format } from 'date-fns'

const DraftedMessageBox = () => {
  const { t } = useTranslation()
  const now = format(new Date(), 'yyyy/MM/dd')

  return (
    <Flex className="">
      <Typography.Text className="text-[14px]" style={{ color: '#137695' }}>
        {t('questionnaire.label.saveDraft')}
      </Typography.Text>
      <Typography.Text
        className="text-[14px] ml-5"
        style={{ color: '#707070' }}
      >
        {t('questionnaire.label.datetime')} {now}
      </Typography.Text>
    </Flex>
  )
}

const RadioOptions = [
  { label: 'ON', value: 1 },
  { label: 'OFF', value: 0 }
]

const DISPLAY_CATEGORY_DEFAULT: number = 1

export default function SurveyEdit() {
  let { refid: refId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [drafted, setDrafted] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [formData, setFormData] = useState<any>()
  const [payload, setPayload] = useState<any>()
  const { hiddenQuestionnaires, setHiddenQuestionnaires } = useQuestionnaires()
  const [user] = useState<any>(useAuth()?.user ?? getUserByToken())

  const { queryDetailSurvey, loading } = useDetailSurvey()
  const { fixedSection: fixedSectionApi } = useFixedSurvey()
  const { subTenantCodes } = useSubTenantList()
  const departments = parseDepartments(subTenantCodes)

  const { updateSurvey } = useUpdateSurvey()
  const { deleteSurvey } = useDeleteSurvey()
  const { setIsModalVisible } = useModalState()

  const {
    confirmed,
    setConfirmed,
    openCreatedDraftModal,
    setOpenCreatedDraftModal,
    sectionList
  } = useSurvey()

  const handleOk = () => {
    setOpenCreatedDraftModal(false)
    setDrafted(true)
  }

  const fetchSurveyDetail = async (refId: any) => {
    const listSurvey: any = await queryDetailSurvey({
      variables: {
        filter: `(eq,STRING,refId,${refId})`,
        paginationInput: { page: 0, size: 1 }
      }
    })

    const {
      filterSurvey: { payload }
    } = listSurvey?.data || null
    if (payload.length === 1) {
      setPayload(payload[0])
      const {
        code,
        additionalInfo,
        sectionList: _sections,
        displayNameMap: { ja }
      } = payload[0]

      let fixedSection = _sections.find(
        (section: any) => section?.title?.ja === '固定の質問'
      )
      if (fixedSection && fixedSection.subsectionList) {
        fixedSection.subsectionList.map((section: any) => {
          return { ...section, id: section.refId }
        })

        // hidden question list
        const hiddenQuestions = fixedSection.subsectionList.filter(
          (s: any) => s.status === StatusEnums.deleted
        )
        setHiddenQuestionnaires(hiddenQuestions)
      }

      const sectionList = _sections.filter(
        (section: any) => section?.refId !== fixedSection?.refId
      )

      const departments = extractDepartmentsFromCode(code)

      const surveyApi: SurveyInputForm = {
        departments: departments,
        title: ja,
        sectionList: sectionList,
        fixedSection: fixedSection,
        displayCategories: forceTypeBoolean(additionalInfo?.displayCategories)
          ? 1
          : 0,
        displayEnglish: forceTypeBoolean(additionalInfo?.displayEnglish) ? 1 : 0
      }

      return surveyApi
    }

    openNotification({
      type: 'error',
      title: t('commonError'),
      message: t('errors.specifyDetailRefId')
    })

    navigate('/survey-list')
  }

  // initial section
  sectionList[0].subsectionList = initSubsectionList(t)
  const defaultValuesCreate = {
    departments: [],
    title: '',
    displayCategories: DISPLAY_CATEGORY_DEFAULT,
    displayEnglish: 1,
    sectionList: sectionList,
    fixedSection: {
      subsectionList: []
    }
  }

  const methods = useForm<SurveyInputForm>({
    defaultValues: async () => {
      return (await fetchSurveyDetail(refId)) ?? defaultValuesCreate
    },
    resolver: yupResolver(schemas)
  })

  const {
    handleSubmit,
    trigger,
    formState: { isValid },
    control,
    getValues,
    setValue
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sectionList'
  })

  const { fields: fixedSubsectionList, update } = useFieldArray({
    control,
    name: 'fixedSection.subsectionList'
  })

  React.useEffect(() => {
    return () => {
      // componentWillUnmount
      setHiddenQuestionnaires([])
    }
  }, [])

  React.useEffect(() => {
    if (fixedSectionApi && fixedSectionApi.subsectionList) {
      // if (
      //   isArrayEmpty(hiddenQuestionnaires) &&
      //   isArrayEmpty(fixedSubsectionList)
      // ) {
      //   // hidden question list
      //   const hiddenQuestions = fixedSectionApi.subsectionList.map((s: any) => {
      //     s.status = StatusEnums.deleted
      //     return s
      //   })
      //   setHiddenQuestionnaires(hiddenQuestions)
      //
      //   reset(
      //     {
      //       fixedSection: fixedSectionApi
      //     },
      //     { keepDefaultValues: true }
      //   )
      // }
    }
  }, [fixedSectionApi])

  const onSubmit = async (formData: SurveyInputForm) => {
    if (!isValid) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.requiredFormFields')
      })
      return
    }

    const {
      departments,
      title,
      sectionList,
      fixedSection,
      displayCategories,
      displayEnglish
    } = formData
    let survey = {
      ...payload,
      displayNameMap: { ...payload.displayNameMap, ja: title },
      surveyStatus: SurveyStatusEnums.draft,
      departments,
      sectionList,
      displayCategories,
      displayEnglish,
      createdBy: user.name ?? user.preferred_username
    }

    survey = validatedSurveyUpdate(survey, fixedSection)
    console.log('[onSubmit] update survey [draft]', { survey })
    const response: any = await updateSurvey(survey)
    if (!response || !response?.isUpdated) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: response?.reason
      })
      return
    }

    openNotification({
      type: 'success',
      title: t('content.formHasBeenSaved'),
      message: t('content.formSavedSuccessfully')
    })
  }

  const handleUpdate = async () => {
    const { departments, title, sectionList, fixedSection } = formData
    let survey = {
      ...payload,
      displayNameMap: { ...payload.displayNameMap, ja: title },
      surveyStatus: SurveyStatusEnums.assigned,
      departments,
      sectionList,
      createdBy: user.name ?? user.preferred_username
    }

    survey = validatedSurveyUpdate(survey, fixedSection)
    console.log('[onSubmit] update survey [assigned]', { survey })
    const response: any = await updateSurvey(survey)
    if (!response || !response?.isUpdated) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: response?.reason
      })
      return
    }

    return response
  }

  const handleDelete = async () => {
    // console.log('[handleDelete]', { refId })
    if (refId) {
      const response: any = await deleteSurvey(refId)
      const { statusCode, payload } = response
      if (statusCode === STATUS_CODE_SUCCESS && !payload[0].isDeleted) {
        openNotification({
          type: 'error',
          title: t('commonError'),
          message: payload[0].reason
        })
        return
      }

      setIsModalVisible(true)
    }
  }

  const navigateSurveyCreateConfirmation = async () => {
    // Manually triggers form or input validation.
    await trigger().then(() => {
      // console.log({ response })
    })

    if (!isValid) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.requiredFormFields')
      })
      return
    }
    const formData = getValues()

    setFormData(formData)
    setConfirmed(true)
  }

  const backEdit = () => {
    setConfirmed(false)
  }

  return (
    <div key={refId}>
      <SpinLoading loading={loading} />
      <CreateSucceededModal
        title={t('questionnaire.label.saveDraftTitle')}
        body={t('questionnaire.label.saveDraftMessage')}
        open={openCreatedDraftModal}
        onOk={handleOk}
        onCancel={handleOk}
        button={t('button.close')}
      />
      <DeleteModal
        open={openDeleteModal}
        title={getValues('title')}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={() => handleDelete()}
        onOk={() => navigate('/survey-list')}
      />

      {confirmed ? (
        <SurveyCreateConfirmation
          formData={formData}
          goBack={backEdit}
          isUpdate={true}
          onSubmit={handleUpdate}
        />
      ) : (
        <Flex
          vertical
          gap="middle"
          className="min-w-[1500px] questionnaire-creation"
        >
          <Flex gap="middle" className="items-center">
            <Typography.Text
              className="mr-12 text-[16px]"
              strong
              style={{ color: '#545454' }}
            >
              {t('questionnaire.create')}
            </Typography.Text>
            <Typography.Text>
              {t('questionnaire.helperText.selectMedicalDepartmentProduct')}
            </Typography.Text>
          </Flex>

          {drafted && (
            <Flex
              gap="middle"
              className="items-center"
              align="center"
              justify="space-between"
            >
              <Alert
                message={<DraftedMessageBox />}
                type="error"
                showIcon={false}
                className="font-bold p-1 px-10"
                style={{ backgroundColor: '#FFF', borderColor: '#137695' }}
              />
            </Flex>
          )}

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} id="hook-form">
              <Flex vertical gap="middle" className="ml-3">
                <Flex gap="middle">
                  <Typography.Text strong className="text-primary">
                    {t('questionnaire.departmentSelect')}
                  </Typography.Text>
                </Flex>
                <Flex gap="middle" align="start" vertical>
                  <Flex
                    gap="middle"
                    vertical
                    justify="flex-start"
                    align="center"
                    wrap
                    className={`department-list-tags flex-row`}
                  >
                    <CheckableTagForm
                      name="departments"
                      tags={departments}
                      title="departmentName"
                    />
                  </Flex>
                </Flex>
                <Flex vertical gap="small">
                  <Row>
                    <Col span={10}>
                      <Flex gap="middle" justify="flex-start" align="center">
                        <Col span={6}>
                          <Typography className="float-right">
                            {t('questionnaire.title')}
                          </Typography>
                        </Col>
                        <Col span={12}>
                          <InputForm
                            name="title"
                            placeholder={t('questionnaire.placeholder.title')}
                          />
                        </Col>
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Flex
                        className="h-[30px]"
                        gap="middle"
                        justify="flex-start"
                        align="center"
                      >
                        <Col span={6}>
                          <Typography className="float-right">
                            {t('questionnaire.label.questionCategories')}
                          </Typography>
                        </Col>
                        <Col span={18}>
                          <Controller
                            name="displayCategories"
                            control={control}
                            render={({ field }) => {
                              const { value } = field
                              return (
                                <Radio.Group
                                  className="font-bold"
                                  options={RadioOptions}
                                  value={value}
                                  onChange={({
                                    target: { value }
                                  }: RadioChangeEvent) =>
                                    setValue('displayCategories', value)
                                  }
                                />
                              )
                            }}
                          />
                        </Col>
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Flex
                        className="h-[30px]"
                        gap="middle"
                        justify="flex-start"
                        align="center"
                      >
                        <Col span={6}>
                          <Typography className="float-right">
                            {t('questionnaire.label.englishDisplay')}
                          </Typography>
                        </Col>
                        <div className="flex align-middle items-center">
                          <Col className="">
                            <DisplayNameEnToggle name="displayEnglish" />
                          </Col>
                          <HiddenEnglishDisplay />
                        </div>
                      </Flex>
                    </Col>
                  </Row>
                </Flex>
              </Flex>

              <Layout className="questionnaire-layout min-h-screen shadow-md rounded-r">
                <Sider
                  collapsible
                  trigger={null}
                  breakpoint="lg"
                  collapsed={collapsed}
                  collapsedWidth={50}
                  width={400}
                  className="sider"
                  onBreakpoint={(broken) => {
                    if (broken) setCollapsed(broken)
                  }}
                >
                  <Flex
                    gap="middle"
                    justify="space-between"
                    align="center"
                    className="p-[10px] gap-[8px]"
                  >
                    <Typography.Text
                      strong
                      className={`${collapsed ? 'hidden' : 'block'} text-primary text-[16px]`}
                    >
                      {t('questionnaire.label.hiddenList')}
                      <UnhideTooltipPopconfirm />
                    </Typography.Text>
                    <Button
                      type="text"
                      icon={<MenuFoldOutlined />}
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  </Flex>
                  <Flex className={`${collapsed ? 'hidden' : 'block'}`}>
                    {hiddenQuestionnaires.map((question: any, idx: number) => {
                      const index = fixedSubsectionList.findIndex(
                        (subsection: any) => subsection.refId === question.refId
                      )
                      return (
                        <QuestionItemHiddenCard
                          key={idx}
                          index={index}
                          question={question}
                          update={update}
                        />
                      )
                    })}
                  </Flex>
                </Sider>
                <Layout className="bg-white rounded-r-lg">
                  <Content className="p-[12px]">
                    <Flex
                      gap="small"
                      className="bg-[#EFF3F6] p-[8px] gap-[0px] justify-between"
                    >
                      <Flex vertical>
                        <Typography.Text
                          strong
                          className="text-primary text-[16px]"
                        >
                          {t('questionnaire.label.fixedList')}
                          <HiddenTooltipPopconfirm />
                        </Typography.Text>
                        <Typography.Text className="">
                          {t('questionnaire.helperText.fixedList')}
                        </Typography.Text>
                      </Flex>
                      <Flex className="items-center mr-2">
                        <ArrowRight />
                      </Flex>
                    </Flex>

                    {fixedSubsectionList
                      .filter((s: any) => s.status === StatusEnums.active)
                      .map((subsection: any, idx: number) => {
                        const index = fixedSubsectionList.findIndex(
                          (question: any) => subsection.refId === question.refId
                        )

                        const fieldName = `fixedSection.subsectionList.${index}`
                        const { subsectionType } = subsection

                        if (subsectionType === SubsectionTypeEnums.question) {
                          return (
                            <fieldset name={fieldName} key={subsection.id}>
                              <QuestionItemCard
                                fieldName={fieldName}
                                question={subsection}
                                indexActive={idx}
                                index={index}
                                update={update}
                              />
                            </fieldset>
                          )
                        } else {
                          // TODO render other type
                          return null
                        }
                      })}

                    <Flex
                      gap="small"
                      className="bg-[#EFF3F6] p-2 my-2.5 gap-[0px] justify-between"
                    >
                      <Flex vertical>
                        <Typography.Text
                          strong
                          className="text-primary text-[16px]"
                        >
                          {t('questionnaire.label.freeQuestion')}
                        </Typography.Text>
                        <Typography.Text className="">
                          {t('questionnaire.helperText.freeQuestion')}
                        </Typography.Text>
                      </Flex>
                      <Flex className="items-center mr-2">
                        <ArrowRight />
                      </Flex>
                    </Flex>

                    {fields.map((section, index) => {
                      const fieldName = `sectionList.${index}`
                      const maxOrder = Math.max(
                        ...Array.from(fields, (o) => o.order)
                      )
                      return (
                        <fieldset name={fieldName} key={section.id}>
                          <SectionItemCreateCard
                            maxOrder={maxOrder}
                            append={append}
                            remove={remove}
                            disabledRemove={fields.length === 1}
                            section={section}
                            fieldName={fieldName}
                            index={index}
                          />
                        </fieldset>
                      )
                    })}
                    <Flex
                      gap="middle"
                      className="my-[5rem]"
                      justify="flex-start"
                      align="center"
                    >
                      <Button
                        type="primary"
                        size="middle"
                        onClick={() => navigateSurveyCreateConfirmation()}
                        className="shadow-none w-[180px] font-bold"
                        autoInsertSpace={false}
                      >
                        {t('button.save')}
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        htmlType="submit"
                        form="hook-form"
                        size="middle"
                        className="shadow-none w-[180px] font-bold"
                      >
                        {t('button.saveDraft')}
                      </Button>
                      <Button
                        type="default"
                        size="middle"
                        onClick={() => setOpenDeleteModal(true)}
                        className="shadow-none w-[180px] font-bold text-[#df2475] border-[#df2475] btn-primary-hover"
                        autoInsertSpace={false}
                      >
                        {t('button.delete')}
                      </Button>
                    </Flex>
                  </Content>
                </Layout>
              </Layout>
            </form>
          </FormProvider>
        </Flex>
      )}
    </div>
  )
}
