import { FC, memo, useMemo, useState } from 'react'

import { Empty, Select } from 'antd'

import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { useGetMedicalCheckupMasterByName } from 'hooks/useMedicalCheckupMaster'
import debounce from 'lodash.debounce'
import { MedicalCheckupMasterDetail } from 'models/productManagement'
import {
  filterCourseResponseInValid,
  preventEnterKeyDefault
} from 'utilities/helpers'

type Props = {
  placeholder: string
  defaultData: { label: string; value: string }[]
  onUpdateSelectedOption: (value: string) => void
  userInfo: { sex: string; age: string }
}

const CourseSelect: FC<Props> = memo(
  ({ placeholder, defaultData, onUpdateSelectedOption, userInfo }) => {
    const { getMedicalCheckupMasterWithoutOptionByName } =
      useGetMedicalCheckupMasterByName()

    const defaultValue = defaultData[0].value

    const [searchData, setSearchData] =
      useState<{ label: string; value: string }[]>(defaultData)
    const [initialValue, setInitialValue] = useState<string | undefined>(
      defaultValue
    )
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleSearch = useMemo(
      () =>
        debounce(async (value: string) => {
          if (!value) {
            setSearchData(defaultData)
            setInitialValue(defaultValue)
            return
          }

          try {
            const fetchData =
              await getMedicalCheckupMasterWithoutOptionByName(value)
            const validData = filterCourseResponseInValid(
              fetchData || [],
              userInfo
            )

            setSearchData(
              validData.map((item: MedicalCheckupMasterDetail) => ({
                value: item.medicalCheckupMaster.refId,
                label: item.medicalCheckupMaster.displayName
              }))
            )
            setIsOpen(true)
          } catch (e) {
            console.error(e)
          }
        }, 500),
      []
    )

    const handleChange = (value: string) => {
      setInitialValue(value)
      onUpdateSelectedOption(value)
    }

    return (
      <Select
        notFoundContent={
          isOpen ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null
        }
        value={initialValue}
        onChange={handleChange}
        onSearch={handleSearch}
        showSearch
        options={searchData}
        filterOption={false}
        onInputKeyDown={preventEnterKeyDefault}
        menuItemSelectedIcon={false}
        prefix={<SearchOutlined style={{ pointerEvents: 'none' }} />}
        suffixIcon={null}
        placeholder={placeholder}
        className="ml-2 w-full min-w-[200px] max-w-[424px]"
      />
    )
  }
)

export default CourseSelect
