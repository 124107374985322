import { useTranslation } from 'react-i18next'

import { Collapse, Flex, Typography } from 'antd'

import Item from '../dnd/Item'
import { ReactComponent as ArrowMenu } from 'assets/svgs/arrow-menu.svg'
import { ReactComponent as HiddenIcon } from 'assets/svgs/hiddenItem.svg'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import { uuid } from 'short-uuid'

const { Panel } = Collapse

export default function InCourseInspectionItems({
  setEdit,
  categories,
  setHidden
}: {
  setEdit: () => void
  categories: DataItemMaster[]
  setHidden: () => void
}) {
  const { t } = useTranslation()
  return (
    <div className="bg-white pt-1.5 pb-4 pr-1 pl-2 rounded-md">
      <Flex className="grid grid-cols-3 font-bold items-center">
        <Typography className="col-span-2 pl-2.5">
          {t('lable.inspectionItems')}
        </Typography>
        <Flex className="flex-1 justify-between items-center col-span-1">
          <Typography>{t('lable.testCD')}</Typography>
          <Flex className="items-center gap-3">
            <PenEditIcon
              className="w-[15px] cursor-pointer"
              onClick={() => setEdit()}
            />
            <HiddenIcon
              className="w-[30px] cursor-pointer"
              onClick={() => setHidden()}
            />
          </Flex>
        </Flex>
      </Flex>
      <div className="border-t border-[#707070] mt-1.5"></div>
      <div className="min-h-[204px] max-h-[600px] overflow-y-scroll mt-2.5">
        {categories &&
          categories.map((item) => (
            <Collapse
              key={uuid()}
              defaultActiveKey={[item.refId]}
              expandIcon={({ isActive }) => (
                <ArrowMenu
                  className={`mx-3 ${isActive ? '' : 'rotate-[-90deg]'}`}
                />
              )}
              expandIconPosition="end"
              className="in-course-inspection-items"
            >
              <Panel
                key={item.refId}
                header={
                  <Item
                    key={item.refId}
                    item={item}
                    showCode
                    isCategory
                    classNames={{
                      classNameItem: 'grid grid-cols-7',
                      classNameText: 'col-span-5',
                      classNameCode: 'col-span-2'
                    }}
                  />
                }
              >
                <div className="flex flex-col">
                  {item?.items &&
                    item.items.map((categoryItem) => (
                      <Item
                        key={categoryItem.refId}
                        item={categoryItem}
                        showCode
                        classNames={{
                          classNameItem: 'grid grid-cols-7',
                          classNameText: 'col-span-5',
                          classNameCode: 'col-span-2'
                        }}
                      />
                    ))}
                </div>
              </Panel>
            </Collapse>
          ))}
      </div>
    </div>
  )
}
