import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Flex,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
  Typography
} from 'antd'

import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { Options } from 'types/common'
import { Option } from 'types/courseRegister'
import { preventEnterKeyDefault } from 'utilities/helpers'

export default function ApplicableOptionalExaminationList({
  setEdit,
  options,
  setOptionsSelected,
  initOptions
}: {
  setEdit: () => void
  options: Option[]
  setOptionsSelected: (options: React.Key[]) => void
  initOptions: React.Key[] | null
}) {
  const { t } = useTranslation()
  const [dataTable, setDataTable] = useState<Option[]>([])
  const [nameOptions, setNameOptions] = useState<Options[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const { control, getValues, setValue } = useFormContext()

  useEffect(() => {
    if (options?.length > 0) {
      const result = options.map((survey: Option) => ({
        key: survey.id,
        label: survey.questionnaireTitle,
        value: survey.id
      }))

      setNameOptions(result)
    }
  }, [options])

  useEffect(() => {
    if (options?.length === 0 || !initOptions) return

    setValue('optionalTests', initOptions)
    handleDataTable()
    setSelectedRowKeys(initOptions)
    setOptionsSelected(initOptions)
  }, [initOptions, options])

  const columns: TableColumnsType<Option> = [
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-[50px]">
            {t('table.questionnaireTitle')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'questionnaireTitle',
      key: 'questionnaireTitle',
      width: '45%',
      render: (_, record) => (
        <Typography className="text-[#137695] font-bold text-left">
          {record.questionnaireTitle}
        </Typography>
      )
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('lable.author')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'creator',
      key: 'creator',
      width: '36%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-4">
            {t('lable.createdAt')}
          </Typography>
          <ArrowTopBottom className="cursor-pointer" />
        </Flex>
      ),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      width: '17%'
    }
  ]

  const handleDataTable = () => {
    const dataSelected = getValues('optionalTests') ?? []
    if (dataSelected.length === 0) return

    const dataTableClone: Option[] = [...dataTable]

    const dataTableCloneIds = new Set(
      dataTable.map((option: Option) => option.id)
    )

    dataSelected.forEach((item: string) => {
      if (!dataTableCloneIds.has(item)) {
        const itemPush = options.find((option: Option) => option.id === item)
        if (itemPush) {
          dataTableClone.push(itemPush)
          dataTableCloneIds.add(item)
        }
      }
    })

    setDataTable(dataTableClone)
    setValue('optionalTests', [])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
    }
  }

  const handleSelectedOptions = () => {
    setOptionsSelected(selectedRowKeys)
    setEdit()
  }

  return (
    <>
      <div className="bg-white py-4 px-2.5 rounded-md mt-2.5">
        <Flex className="gap-3">
          <Controller
            name="optionalTests"
            control={control}
            render={({ field }) => (
              <Select
                maxTagCount="responsive"
                {...field}
                mode="multiple"
                className="w-full max-w-[424px] courseSelect"
                suffixIcon={
                  <SearchOutlined className="absolute top-[-2px] right-[-4px] cursor-pointer" />
                }
                style={{ width: '100%' }}
                placeholder={t('placeholder.searchOptionalTests')}
                defaultValue={[]}
                options={nameOptions}
                popupClassName="coursePopupSelect"
                menuItemSelectedIcon={null}
                onInputKeyDown={preventEnterKeyDefault}
                optionFilterProp="label"
                optionRender={(option) => {
                  const itemCheck = option.value ?? ''
                  return (
                    <Space>
                      <Checkbox
                        key={option.key}
                        checked={field.value.includes(itemCheck.toString())}
                        onKeyDown={preventEnterKeyDefault}
                      ></Checkbox>
                      <Typography.Text className="text-[14px]">
                        {option.label}
                      </Typography.Text>
                    </Space>
                  )
                }}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{
                      pointerEvents: 'none'
                    }}
                    title={omittedValues.map(({ label }) => label).join('、')}
                  >
                    <span>+ {omittedValues.length} ...</span>
                  </Tooltip>
                )}
              ></Select>
            )}
          />

          <Button
            autoInsertSpace={false}
            type="primary"
            className="min-w-[120px] font-bold"
            onClick={() => handleDataTable()}
          >
            {t('placeholder.search')}
          </Button>
        </Flex>

        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          rowKey="id"
          columns={columns}
          dataSource={dataTable}
          pagination={false}
          className="course-register-list-table mt-3"
          scroll={{
            y: '415px'
          }}
        />
      </div>
      <Button
        autoInsertSpace={false}
        type="primary"
        className="min-w-[120px] font-bold mt-5"
        onClick={() => handleSelectedOptions()}
      >
        {t('button.confirm')}
      </Button>
    </>
  )
}
