import React, { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { Checkbox, Empty, Select, Space, Tooltip, Typography } from 'antd'

import styles from './scss/SelectWrapper.module.scss'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { useGetMedicalCheckupMasterByName } from 'hooks/useMedicalCheckupMaster'
import { preventEnterKeyDefault, uuid } from 'utilities/helpers'

type Props = {
  name: string
  placeholder: string
}

const SearchMultipleCourseDropdown: FC<Props> = ({ name, placeholder }) => {
  const { control } = useFormContext()
  const { search } = useLocation()
  const { getListNameMedicalCheckupMaster } = useGetMedicalCheckupMasterByName()

  const queryParams = new URLSearchParams(search)
  const { courseId: courseParam } = Object.fromEntries(queryParams)

  const [loading, setLoading] = useState<boolean>(true)
  const [searchData, setSearchData] = useState<
    {
      id: number
      value: string
      label: string
    }[]
  >([])
  const [selectedValue, setSelectedValue] = useState<string[]>(
    courseParam ? courseParam.split(',') : []
  )

  useEffect(() => {
    const fetchData = async () => {
      const data = await getListNameMedicalCheckupMaster({
        filter: [
          {
            field: 'data.additionalInfo.key1',
            operator: 'ne',
            value: 'option'
          },
          {
            field: 'data.additionalInfo.isDeleted',
            operator: 'ne',
            value: 'true'
          }
        ],
        sortBy: {
          field: 'createdDate',
          direction: 'desc'
        }
      })

      const handledData = data.map(
        (item: {
          medicalCheckupMaster: { refId: string; displayName: string }
        }) => ({
          id: uuid(),
          value: item.medicalCheckupMaster.refId,
          label: item.medicalCheckupMaster.displayName
        })
      )
      setSearchData(handledData)
    }

    fetchData().finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!courseParam) {
      setSelectedValue([])
    }
  }, [courseParam])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className="relative w-full min-w-[200px] max-w-[424px]">
            <Select
              {...field}
              mode="multiple"
              notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              value={loading ? [] : selectedValue}
              onChange={(value) => {
                setSelectedValue(value)
                field.onChange(value)
              }}
              showSearch
              filterOption={(inputValue, option) => {
                if (!option) return false
                return option?.label
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }}
              options={searchData}
              optionRender={(option) => {
                const isChecked = selectedValue.includes(
                  (option.value ?? '').toString()
                )

                return (
                  <Space>
                    <Checkbox
                      key={option.key}
                      checked={isChecked}
                      onKeyDown={preventEnterKeyDefault}
                    />
                    <Typography.Text className="text-[14px]">
                      {option.label}
                    </Typography.Text>
                  </Space>
                )
              }}
              maxTagCount="responsive"
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  overlayStyle={{
                    pointerEvents: 'none'
                  }}
                  title={omittedValues.map(({ label }) => label).join('、')}
                >
                  <span>+ {omittedValues.length} ...</span>
                </Tooltip>
              )}
              menuItemSelectedIcon={false}
              prefix={
                <SearchOutlined className="pointer-events-none mt-[3px]" />
              }
              suffixIcon={null}
              placeholder={placeholder}
              className={`${styles.select_wrapper} h-[30px] w-full min-w-[200px] max-w-[424px]`}
            />
          </div>
        )
      }}
    />
  )
}

export default SearchMultipleCourseDropdown
