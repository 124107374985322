import { useEffect, useState } from 'react'

import { useMedicalCheckupMaster } from './useMedicalCheckupMaster'
import { useLazyQuery } from '@apollo/client'
import { FILTER_PERIOD_CONFIGS } from 'graphql/ReferenceValue/listPeriodConfigs'
import { Options } from 'types/common'
import { Endpoint } from 'utilities/apolloClient'

const useInspectionResultCreate = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()
  const [optionsMedicalCheckupMaster, setOptionsMedicalCheckupMaster] =
    useState<Options[]>([])
  const [loadPeriodConfig] = useLazyQuery(FILTER_PERIOD_CONFIGS)

  useEffect(() => {
    const getMedicalCheckupMasterNameOptions = async () => {
      const data = await getMedicalCheckupMaster({
        filter: [
          {
            field: 'data.additionalInfo.key1',
            operator: 'ne',
            value: 'option'
          },
          {
            field: 'data.additionalInfo.isDeleted',
            operator: 'ne',
            value: 'true'
          }
        ],
        sortBy: {
          field: 'createdDate',
          direction: 'desc'
        }
      })
      if (!Array.isArray(data)) return
      const result: Options[] = data.map((item) => ({
        ...item.medicalCheckupMaster,
        key: item.medicalCheckupMaster?.refId ?? '',
        value: item.medicalCheckupMaster?.refId ?? '',
        label: item.medicalCheckupMaster?.displayName ?? ''
      }))

      setOptionsMedicalCheckupMaster(result)
    }

    getMedicalCheckupMasterNameOptions()
  }, [])

  const getPeriodConfigs = async (medicalCheckupMasterObjId: string) => {
    try {
      const result = await loadPeriodConfig({
        variables: {
          medicalCheckupMasterObjId: medicalCheckupMasterObjId
        },
        context: { version: Endpoint.REFERENCE_VALUE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
      })

      return result
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    error,
    optionsMedicalCheckupMaster,
    setOptionsMedicalCheckupMaster,
    getPeriodConfigs,
    onClearError,
    setLoading
  }
}

export default useInspectionResultCreate
