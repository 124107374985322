import { memo } from 'react'

import { Empty } from 'antd'
import { useRecoilValue } from 'recoil'

import RoomItem from './RoomItem'
import { SpinLoading } from 'components/screens/Spin'
import { loadMoreRoomAtom, roomsSortedAtom } from 'hooks/chat/atoms'
import useMessageList from 'hooks/chat/messageList'
import { RoomsDataPayload } from 'pages/Chat/types/types'
import { getUserInfoInRoom } from 'utilities/chatHelpers'
import { formatTimeConditional, sortListByUpdateAt } from 'utilities/helpers'

interface RoomsListProps {
  readonly onClick: (id: string) => void
}

function RoomList({ onClick }: RoomsListProps) {
  const { handleLoadMoreRoom } = useMessageList()
  const loadMoreRoomState = useRecoilValue(loadMoreRoomAtom)
  const roomsSorted = useRecoilValue(roomsSortedAtom) || []

  // const adminUserId = useMemo(() => {
  //   const { adminUserId } = JSON.parse(
  //     localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
  //   )
  //   return adminUserId
  // }, [])

  const renderLoadingSpinner = (isLoadingMore = false) => (
    <div
      className={`flex justify-center items-center ${isLoadingMore ? 'h-10' : 'h-[calc(100vh-150px)]'}`}
    >
      <SpinLoading
        loading={
          isLoadingMore
            ? loadMoreRoomState.isLoadingLoadMore
            : loadMoreRoomState.isLoading
        }
      />
    </div>
  )

  const renderRooms = () => (
    <div className="max-h-[calc(100vh-150px)]">
      {sortListByUpdateAt([...roomsSorted], 'updatedAt').map(
        (room: RoomsDataPayload, index) => (
          <RoomItem
            {...room}
            key={room._id}
            id={room._id}
            userName={
              getUserInfoInRoom(room?.customFields?.uid, room?.subscriptions)
                ?.user.name
            }
            time={formatTimeConditional(room.updatedAt)}
            text={room.lastMessage?.msg}
            countNotRead={0}
            isFirst={index === 0}
            onClick={onClick}
            status={room?.customFields?.status}
          />
        )
      )}
      {loadMoreRoomState?.isLoadingLoadMore &&
        !loadMoreRoomState?.isLoadedFull &&
        renderLoadingSpinner(true)}
    </div>
  )

  if (roomsSorted?.length === 0) {
    return (
      <div className="h-[calc(100vh-160px)] flex justify-center items-center">
        <Empty />
      </div>
    )
  }

  return (
    <div
      className="w-full h-full overflow-y-auto"
      onScroll={handleLoadMoreRoom}
    >
      {loadMoreRoomState?.isLoading && !loadMoreRoomState?.isLoadedFull
        ? renderLoadingSpinner()
        : renderRooms()}
    </div>
  )
}

export default memo(RoomList)
