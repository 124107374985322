import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Collapse, Typography } from 'antd'

import { ReactComponent as ArrowMenu } from 'assets/svgs/arrow-menu.svg'
import { InputForm } from 'components/elements/InputForm'
import { LIST_DRAG_AND_DROP, VALUE_WITH_NO_DATA } from 'configs/constant'
import { showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'
import CollapseItems from './CollapseItems'

const { Panel } = Collapse

const CollapseCategoryItem = ({
  category,
  showUpdate,
  language,
  showInspectionDescription,
  index,
  handleSettingModal
}: {
  category: DataItemMaster
  showUpdate: boolean
  language: string
  showInspectionDescription: boolean
  index: number
  handleSettingModal: (category: DataItemMaster, isActive: boolean) => void
}) => {
  const { t } = useTranslation()
  return (
    <Draggable draggableId={category.refId} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <Collapse
            defaultActiveKey={[category.refId]}
            expandIcon={({ isActive }) => {
              return (
                <ArrowMenu
                  className={`mr-3 ml-2 ${isActive ? '' : 'rotate-[-90deg]'}`}
                />
              )
            }}
            onChange={([key]) => {
              const isActive = !!key
              !isActive && !showUpdate && handleSettingModal(category, !!key)
            }}
            activeKey={[!!category?.isActiveKey ? category.refId : '']}
            expandIconPosition="end"
          >
            <Panel
              header={
                <div
                  {...provided.dragHandleProps}
                  className="flex justify-between items-center pt-[9px] pb-[7px]"
                  id={category.refId}
                >
                  <Typography
                    className={`w-[36.6%] ${showUpdate ? 'pl-[24px]' : 'pl-[27px]'}`}
                  >
                    {!showUpdate &&
                      (showName(category, language) ?? VALUE_WITH_NO_DATA)}
                    {showUpdate && (
                      <InputForm
                        name={`category_${category.refId}_${language}`}
                        className="h-[22px] p-1"
                      />
                    )}
                  </Typography>
                  <span className="flex-1 text-xs text-[#BDCBD5] tracking-[.6px] text-center">
                    {category?.code?.coding?.[0]?.code}
                  </span>
                  <Typography className="w-[46.8%] text-left">
                    {!showUpdate &&
                      showInspectionDescription &&
                      (showName(category, 'en') ?? VALUE_WITH_NO_DATA)}
                    {showUpdate && showInspectionDescription && (
                      <InputForm
                        name={`category_${category.refId}_en2`}
                        className="h-[22px] p-1"
                        placeholder={t(
                          'questionnaire.placeholder.englishDisplay'
                        )}
                      />
                    )}
                  </Typography>
                </div>
              }
              key={category.refId}
            >
              <div className="flex flex-col space-y-2">
                <Droppable
                  droppableId={`${LIST_DRAG_AND_DROP.LIST1}${LIST_DRAG_AND_DROP.SEPARATOR}${category.refId}`}
                  type={LIST_DRAG_AND_DROP.ITEM}
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`${category?.items && category?.items.length < 1 ? 'pb-[30px]' : ''}`}
                    >
                      {category?.items ? (
                        category.items?.map((categoryItem, index) => (
                          <CollapseItems
                            key={categoryItem.refId}
                            categoryItem={categoryItem}
                            language={language}
                            showUpdate={showUpdate}
                            showInspectionDescription={
                              showInspectionDescription
                            }
                            index={index}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </Panel>
          </Collapse>
        </div>
      )}
    </Draggable>
  )
}

export default CollapseCategoryItem
