import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, InputNumber, Radio, Typography } from 'antd'
import { TFunction } from 'i18next'
import * as yup from 'yup'

import ApplicableOptionalExamination from './components/ApplicableOptionalExamination'
import ApplicableOptionalExaminationList from './components/ApplicableOptionalExaminationList'
import CopyProductModal from './components/CopyProductModal'
import CourseRelatedInterview from './components/CourseRelatedInterview'
import CourseRelatedInterviewList from './components/CourseRelatedInterviewList'
import ListSettingThresholdAndCriteria from './components/ListSettingThresholdAndCriteria'
import SaveSuccessModal from './components/SaveSuccessModal'
import SetupThresholdAndCriteria, {
  DataForm as DataSetupThresholdAndCriteria,
  ITEM_START_NAME,
  JUDGMENT,
  JUDGMENT_ITEM,
  JUDGMENT_START_NAME
} from './components/SetupThresholdAndCriteria'
import CourseTestItemWrapper from './components/courseTestItems/CourseTestItemWrapper'
import InCourseInspectionItems from './components/courseTestItems/InCourseInspectionItems'
import useMutationCourseTestItem from './components/hooks/useMutationCourseTestItem'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { TextAreaForm } from 'components/elements/TextAreaForm'
import ScreenLoader from 'components/loading/ScreenLoader'
import { openNotification } from 'components/widgets/Notification'
import { MEDICAL_CHECKUP_MASTER_EXAMPLE } from 'configs/api'
import {
  CLASSIFICATION,
  ConditionalNumber,
  DATE_FORMAT_YMD_SLASH,
  GENDER,
  META_DATA_MEDICAL_CHECKUP_MASTER,
  typeReferenceValue
} from 'configs/constant'
import useCourseRegister from 'hooks/useCourseRegister'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import { handleMetaData } from 'hooks/useProductMaster'
import {
  DataItemMaster,
  UpdateItemMaster
} from 'pages/InspectionItemSetting/types/filterItemMaster'
import DeleteProductModal from 'pages/ProductMaster/components/DeleteProductModal'
import DeleteProductSuccessModal from 'pages/ProductMaster/components/DeleteProductSuccessModal'
import { CategoryWithMultipleLang } from 'types/Categories'
import { ItemMasterWithMultipleLang } from 'types/ItemMasters'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'
import { StringKeyObject } from 'types/common'
import { ItemOption, SurveyList } from 'types/courseRegister'
import { formatDate, getLanguage, uuid } from 'utilities/helpers'

type DataForm = {
  name: string
  overview?: string
  sex?: string
  startAge?: number
  endAge?: number
  classification?: string
  courseTimes: number
  buffer?: number
  medicalQuestionnaire?: string[]
  optionalTests?: string[]
  courseCd?: number
}

const genderOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { label: t('optionForm.noSpecification'), value: GENDER.NONE },
    { label: t('lable.male'), value: GENDER.MALE },
    { label: t('lable.female'), value: GENDER.FEMALE }
  ]
}

const classificationOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { label: t('optionForm.individual'), value: CLASSIFICATION.INDIVIDUAL },
    { label: t('optionForm.group'), value: CLASSIFICATION.ORGANIZATION }
  ]
}

const applicableOptionalExamination = 'ApplicableOptionalExamination'
const courseRelatedInterview = 'CourseRelatedInterview'
const inCourseInspectionItems = 'InCourseInspectionItems'

export const numberNotRequiredField = () => {
  return yup.number().transform((value) => (value === null ? undefined : value))
}

const schema = yup
  .object()
  .shape({
    name: yup.string().trim().max(100).required(),
    overview: yup.string().max(255),
    sex: yup.string(),
    classification: yup.string(),
    startAge: numberNotRequiredField(),
    endAge: numberNotRequiredField(),
    courseTimes: yup.number().required(),
    buffer: numberNotRequiredField()
  })
  .required()

const InputLabel = (label: string, isRequired: boolean = false) => {
  const { t } = useTranslation()

  return (
    <Flex className="w-full h-full justify-between items-center">
      <Typography>{label}</Typography>

      {isRequired && (
        <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white break-normal">
          {t('lable.required')}
        </Typography>
      )}
    </Flex>
  )
}

const LABEL_PARENT = 'font-bold bg-[#F0F3F7] py-1.5 pl-2.5 pr-1 w-[203px]'
const LABEL_CHILD = 'w-[128px]'

export default function CourseRegister() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const language = getLanguage()
  const {
    loading,
    setLoading,
    handleCreateMedicalCheckupMaster,
    getPeriodConfigs,
    handleDataItemsOptions,
    deleteMedicalCheckupMaster,
    surveys,
    options,
    optionsMedicalCheckupMaster,
    checkConditionWithOptions
  } = useCourseRegister()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()

  const [edit, setEdit] = useState<boolean | string>(false)
  const [save, setSave] = useState<boolean>(false)
  const [show, setShow] = useState<any>({
    [applicableOptionalExamination]: false,
    [courseRelatedInterview]: false,
    [inCourseInspectionItems]: false
  })
  const [surveysSelected, setSurveysSelected] = useState<SurveyList[]>([])
  const [optionsSelected, setOptionsSelected] = useState<React.Key[]>([])
  const [itemsOptions, setItemsOptions] = useState<ItemOption[]>([])
  const [initOptions, setInitOptions] = useState<React.Key[] | null>(null)
  const [initSurveys, setInitSurveys] = useState<React.Key[] | null>(null)
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false)
  const [showCopyProductModal, setShowCopyProductModal] =
    useState<boolean>(false)
  const [dataProduct, setDataProduct] = useState<
    MedicalCheckupMaster | undefined
  >(undefined)
  const [addRefValue, setAddRefValue] = useState<boolean>(false)
  const [listSetupThresholdAndCriteria, setListSetupThresholdAndCriteria] =
    useState<DataSetupThresholdAndCriteria[]>([])
  const [editThresholdAndCriteria, setEditThresholdAndCriteria] =
    useState<DataSetupThresholdAndCriteria | null>(null)

  const [deleteProduct, setDeleteProduct] =
    useState<MedicalCheckupMaster | null>(null)
  const [deleteProductSuccess, setDeleteProductSuccess] =
    useState<boolean>(false)

  const [
    { listCategoryShow, originalListTestItem, listCategoryHidden },
    setListData
  ] = useState<{
    listCategoryShow: DataItemMaster[]
    listCategoryHidden: DataItemMaster[]
    originalListCategory: DataItemMaster[]
    originalListTestItem: DataItemMaster[]
  }>({
    listCategoryShow: [],
    listCategoryHidden: [],
    originalListCategory: [],
    originalListTestItem: []
  })

  const isUpdate = !!searchParams.get('id')
  const { handleUpdateItemMaster, mappingDataUpdateItemMaster } =
    useMutationCourseTestItem()

  useEffect(() => {
    const fetchDataItemsOptions = async () => {
      setLoadingOptions(true)
      const items = (await handleDataItemsOptions(optionsSelected)) ?? []
      setItemsOptions(items)
      setLoadingOptions(false)
    }
    fetchDataItemsOptions()
  }, [optionsSelected])

  const methods = useForm<DataForm>({
    defaultValues: {
      name: '',
      sex: 'NONE',
      classification: 'Individual',
      medicalQuestionnaire: [],
      optionalTests: []
    },
    resolver: yupResolver(schema)
  })

  const { control, handleSubmit, reset, getValues } = methods

  useEffect(() => {
    const id = searchParams.get('id')

    if (id) {
      const fetchDataMedicalCheckupMaster = async () => {
        setLoading(true)
        const data = await getMedicalCheckupMastersById(id)
        if (!data) navigate('/product-master')

        const medicalCheckupMaster = data?.medicalCheckupMaster ?? {}
        showDataWithMedicalCheckupMaster(medicalCheckupMaster)
        setLoading(false)
      }
      fetchDataMedicalCheckupMaster()
    }
  }, [])

  const showDataWithMedicalCheckupMaster = async (
    medicalCheckupMaster: MedicalCheckupMaster,
    isCopy: boolean = false
  ) => {
    setDataProduct(medicalCheckupMaster)
    const metaData = medicalCheckupMaster?.additionalInfo?.metadata ?? []

    const metaDataObj = handleMetaData(metaData)

    reset({
      name:
        (medicalCheckupMaster?.displayName ?? '') +
        (isCopy ? `（${t('popover.copy')}）` : ''),
      overview: metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW] ?? '',
      sex: metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SEX] ?? '',
      classification:
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.CLASSIFICATION] ?? '',
      startAge:
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.START_AGE] ?? undefined,
      endAge:
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.END_AGE] ?? undefined,
      courseTimes:
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_TIMES] ??
        undefined,
      buffer:
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.BUFFER] ?? undefined,
      courseCd: isCopy
        ? undefined
        : (metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.COURSE_CD] ??
          undefined)
    })

    const objectId = medicalCheckupMaster?.additionalInfo?.objId
    if (objectId) {
      await handleDataReferenceValue(objectId)
    }

    const metaDataOptions =
      metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.OPTIONS] ?? []
    if (Array.isArray(metaDataOptions)) {
      setInitOptions(
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.OPTIONS] ?? []
      )
    }

    const metaDataSurveys =
      metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SURVEYS] ?? []
    if (Array.isArray(metaDataSurveys)) {
      setInitSurveys(
        metaDataObj?.[META_DATA_MEDICAL_CHECKUP_MASTER.SURVEYS] ?? []
      )
    }

    setShow({
      [applicableOptionalExamination]: true,
      [courseRelatedInterview]: true,
      [inCourseInspectionItems]: true
    })
  }

  const handleDataReferenceValue = async (
    medicalCheckupMasterObjId: string
  ) => {
    const fetchData: any = await getPeriodConfigs(medicalCheckupMasterObjId)
    const data = fetchData?.data?.listPeriodConfigs?.payload ?? []

    if (!Array.isArray(data) || data.length === 0) return
    const list = []
    for (const item of data) {
      const judgment_item: StringKeyObject = {}
      const judgment: StringKeyObject = {}
      if (!Array.isArray(item.evaluations)) continue
      const evaluations: StringKeyObject[] = [...item.evaluations].sort(
        (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
      )
      for (const evaluation of evaluations) {
        judgment[JUDGMENT_START_NAME + evaluation.refId] = {}
        judgment_item[JUDGMENT_START_NAME + evaluation.refId] = {
          bgColor: evaluation.color,
          evaluation: evaluation.name,
          description: evaluation.desc,
          order: evaluation.order
        }

        if (Array.isArray(evaluation.referenceValues)) {
          for (const referenceValue of evaluation.referenceValues) {
            const constraint = referenceValue.constraint
            if (!Array.isArray(constraint)) continue

            judgment[JUDGMENT_START_NAME + evaluation.refId][
              ITEM_START_NAME + referenceValue.testItemMasterRefId
            ] = constraint.map((itemConstraint: any) => {
              const result: any = {
                id: uuid(),
                type: itemConstraint.type
              }

              if (itemConstraint.gender) result.gender = itemConstraint.gender

              if (itemConstraint.type === typeReferenceValue.RANGE_NUMBER) {
                result.start = itemConstraint.lowerLimit
                result.end = itemConstraint.upperLimit
              }
              if (itemConstraint.type === typeReferenceValue.STRING)
                result.text = itemConstraint.textValue

              if (
                itemConstraint.type === typeReferenceValue.CONDITIONAL_NUMBER
              ) {
                if (itemConstraint.lowerLimit) {
                  result.value = itemConstraint.lowerLimit
                  result.select = ConditionalNumber.GE
                } else {
                  result.value = itemConstraint.upperLimit
                  result.select = ConditionalNumber.LE
                }
              }

              return result
            })
          }
        }
      }

      list.push({
        id: item.refId,
        application_period_start: item.startDate
          ? formatDate(item.startDate, DATE_FORMAT_YMD_SLASH)
          : undefined,
        application_period_end: item.endDate
          ? formatDate(item.endDate, DATE_FORMAT_YMD_SLASH)
          : undefined,
        [JUDGMENT_ITEM]: judgment_item,
        [JUDGMENT]: judgment,
        order: item.order
      })
    }

    setListSetupThresholdAndCriteria(list.sort((a, b) => a.order - b.order))
  }

  const onSubmit = async (data: DataForm) => {
    if (
      data.startAge !== undefined &&
      data.endAge !== undefined &&
      data.startAge > data.endAge
    ) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.invalidAgeRange')
      })

      return
    }

    if (!checkDataMedicalCheckupMaster()) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.courseInspectionItemRequired')
      })

      return
    }

    const conditionInvalidOption = checkConditionWithOptions(
      data,
      optionsSelected
    )

    if (conditionInvalidOption.length > 0) {
      const ArrNameOption = conditionInvalidOption
        .map((item) => item.displayName)
        .join(', ')
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: ArrNameOption + ': ' + t('errors.selectedOptionInvalid')
      })

      return
    }

    const objectId = uuid(false)

    if (listCategoryShow.length > 0) {
      const getAllRefId = (data: DataItemMaster[]) => {
        const refIds = data.reduce<string[]>(
          (acc, { refId, itemId, items }) => {
            acc.push(itemId ?? '')
            if (items?.length) {
              acc.push(...items.map((i) => i.refId))
            }
            return acc
          },
          []
        )
        return refIds
      }
      // update item master
      const dataUpdateItemMaster: UpdateItemMaster[] =
        mappingDataUpdateItemMaster(
          getAllRefId(listCategoryShow),
          originalListTestItem,
          objectId
        )
      await handleUpdateItemMaster(dataUpdateItemMaster)

      // // update item category
      // const listCategory = originalListCategory.filter((item) =>
      //   listCategoryShow.some((i) => i.refId === item.refId)
      // )

      // await handleUpdateItemCategory(listCategory, {
      //   medicalCheckupMasterObjId: objectId
      // })
    }

    await handleCreateMedicalCheckupMaster(
      data,
      surveysSelected,
      optionsSelected,
      listSetupThresholdAndCriteria,
      searchParams.get('id') ?? undefined,
      {
        categories: listCategoryShow,
        excludedCategories: listCategoryHidden
      },
      objectId
    )

    setSave(true)
  }

  const handleShow = (key: any, display: boolean = true) => {
    setShow((prev: any) => ({
      ...prev,
      [key]: display
    }))
  }

  const setDeleteCourse = () => {
    if (dataProduct) setDeleteProduct(dataProduct)
  }

  const handleDeleteCourse = async () => {
    if (!deleteProduct) return
    await deleteMedicalCheckupMaster(deleteProduct)
    setDeleteProductSuccess(true)
  }

  const getCategoryReferenceValue = (data: DataItemMaster[]) => {
    return data.map((item: DataItemMaster, index: number) => ({
      itemMaster: (item.items ?? []) as ItemMasterWithMultipleLang[],
      idItemMaster: item.itemId ?? '',
      refId: item.refId,
      shortName: item.shortName,
      lang: item.lang,
      order: index
    })) as CategoryWithMultipleLang[]
  }

  const checkDataMedicalCheckupMaster = () => {
    return (
      listCategoryShow.length > 0 &&
      listCategoryShow.some(
        (category) => Array.isArray(category.items) && category.items.length > 0
      )
    )
  }

  return (
    <>
      <div className={`${addRefValue ? 'hidden' : ''}`}>
        <FormProvider {...methods}>
          <form
            className="pb-5 pr-2.5 min-w-[1600px] relative"
            onSubmit={handleSubmit(onSubmit)}
          >
            {loading && <ScreenLoader />}
            <Flex className="items-center justify-between">
              <Typography className="text-base font-bold tracking-[1.6px] leading-none">
                {isUpdate ? t('courseChange') : t('courseEnrolment')}
              </Typography>
              <Flex className="gap-3 items-center justify-end tracking-[.7px]">
                {edit && (
                  <Typography
                    className="text-primary underline font-bold tracking-[1.4px] cursor-pointer"
                    onClick={() => setEdit(false)}
                  >
                    ＜{t('button.return')}
                  </Typography>
                )}

                {!edit && (
                  <>
                    <Button
                      autoInsertSpace={false}
                      className="text-primary font-bold min-w-[120px] border border-primary bg-inherit rounded "
                      onClick={() => setShowCopyProductModal(true)}
                    >
                      {t('courseRegister.existingProductCopy')}
                    </Button>
                    <Button
                      autoInsertSpace={false}
                      type="primary"
                      className="min-w-[120px] font-bold rounded"
                      htmlType="submit"
                    >
                      {isUpdate ? t('button.change') : t('button.registration')}
                    </Button>
                    {isUpdate &&
                      dataProduct?.refId !== MEDICAL_CHECKUP_MASTER_EXAMPLE && (
                        <Button
                          autoInsertSpace={false}
                          type="primary"
                          className="min-w-[120px] bg-error hover:!bg-error rounded"
                          onClick={setDeleteCourse}
                        >
                          {t('button.delete')}
                        </Button>
                      )}
                  </>
                )}
              </Flex>
            </Flex>
            {isUpdate && !edit && (
              <Typography className="mt-2">
                {t('content.changesReflectInReservation')}
              </Typography>
            )}
            <div className="mt-2.5 py-[11px] pr-[25px] pl-2.5 bg-white shadow-custom rounded-md tracking-[.7px]">
              <Flex className="gap-2.5">
                <div className={LABEL_PARENT}>
                  {InputLabel(t('lable.courseName'), true)}
                </div>
                <Flex className="flex-1">
                  <div className="flex-1">
                    <InputForm
                      name="name"
                      placeholder=""
                      className="w-[424px]"
                    />
                  </div>
                  <div className="flex-1 flex items-center gap-8">
                    <Typography className="font-bold">
                      {t('lable.courseCD')}
                    </Typography>
                    {isUpdate && (
                      <Typography>{getValues('courseCd') ?? ''}</Typography>
                    )}
                  </div>
                </Flex>
              </Flex>
              <Flex className="mt-2.5 gap-2.5">
                <div className={LABEL_PARENT}>
                  {InputLabel(t('table.overview'))}
                </div>
                <Flex className="flex-1">
                  <TextAreaForm
                    name="overview"
                    className="w-full !min-h-[80px]"
                    autoSize={{ minRows: 3 }}
                  />
                </Flex>
              </Flex>
              <Flex className="mt-2.5 gap-2.5">
                <div className={LABEL_PARENT}>
                  {InputLabel(t('lable.eligiblePatients'))}
                </div>
                <div className="flex-1">
                  <Flex className="items-center gap-2">
                    <div className={LABEL_CHILD}>
                      {InputLabel(t('lable.gender'), true)}
                    </div>

                    <Controller
                      name="sex"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Radio.Group
                            {...field}
                            optionType="button"
                            options={genderOptions(t)}
                            className="custom-radio-group"
                          />
                        )
                      }}
                    />
                  </Flex>
                  <Flex className="items-center gap-2 mt-3">
                    <div className={LABEL_CHILD}>
                      {InputLabel(t('lable.classification'))}
                    </div>

                    <Controller
                      name="classification"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Radio.Group
                            {...field}
                            optionType="button"
                            options={classificationOptions(t)}
                            className="custom-radio-group"
                          />
                        )
                      }}
                    />
                  </Flex>
                  <Flex className="items-center gap-2 mt-3">
                    <div className={LABEL_CHILD}>
                      {InputLabel(t('lable.age'))}
                    </div>
                    <Flex className="items-center gap-1">
                      <Controller
                        name="startAge"
                        control={control}
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            max={999}
                            className="w-[80px]"
                            controls={false}
                          />
                        )}
                      />
                      <Typography className="font-bold">〜</Typography>
                      <Controller
                        name="endAge"
                        control={control}
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            max={999}
                            className="w-[80px]"
                            controls={false}
                          />
                        )}
                      />
                    </Flex>
                  </Flex>

                  <Flex className="items-center gap-2 mt-3">
                    <div className={LABEL_CHILD}>
                      {InputLabel(t('lable.responseTime'), true)}
                    </div>
                    <Flex className="items-end gap-1">
                      <Controller
                        name="courseTimes"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <InputNumber
                            {...field}
                            min={1}
                            status={error ? 'error' : ''}
                            max={999}
                            className="w-[80px]"
                            controls={false}
                          />
                        )}
                      />

                      <Typography>{t('lable.minutes')}</Typography>
                    </Flex>
                  </Flex>
                  <Flex className="items-center gap-2 mt-3">
                    <div className={LABEL_CHILD}>
                      {InputLabel(t('table.buffer'))}
                    </div>
                    <Flex className="items-end gap-1">
                      <Controller
                        name="buffer"
                        control={control}
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={1}
                            max={999}
                            className="w-[80px]"
                            controls={false}
                          />
                        )}
                      />
                      <Typography>{t('lable.minutes')}</Typography>
                    </Flex>
                  </Flex>
                </div>
              </Flex>
            </div>

            {!edit && (
              <Flex className="pt-[28.5px] tracking-[.7px]">
                <div className="w-[50.9%]">
                  <Flex className="gap-2 items-center">
                    <Typography className="font-bold">
                      {t('courseRegister.inCourseTestItems')}
                    </Typography>
                    <Typography className="bg-error py-1 px-1.5 text-xs font-bold text-white">
                      {t('lable.required')}
                    </Typography>
                  </Flex>

                  {!show?.[inCourseInspectionItems] && (
                    <Flex
                      onClick={() => {
                        if (listCategoryShow.length > 0) {
                          handleShow(inCourseInspectionItems)
                        } else {
                          setEdit(inCourseInspectionItems)
                        }
                      }}
                      className="mt-4 w-[30px] h-[30px] rounded-md bg-primary text-white items-center justify-center cursor-pointer"
                    >
                      <span className="text-[24px]">+</span>
                    </Flex>
                  )}
                  {show?.[inCourseInspectionItems] && (
                    <div className="w-[93%] mt-2">
                      <InCourseInspectionItems
                        setHidden={() =>
                          handleShow(inCourseInspectionItems, false)
                        }
                        setEdit={() => setEdit(inCourseInspectionItems)}
                        categories={listCategoryShow}
                      />
                    </div>
                  )}

                  {checkDataMedicalCheckupMaster() && (
                    <Typography className="font-bold mt-4">
                      {t('setReferenceValue')}
                    </Typography>
                  )}
                  {listSetupThresholdAndCriteria.length === 0 &&
                    checkDataMedicalCheckupMaster() && (
                      <Flex
                        onClick={() => setAddRefValue(true)}
                        className="mt-4 w-[30px] h-[30px] rounded-md bg-primary text-white items-center justify-center cursor-pointer"
                      >
                        <span className="text-[24px]">+</span>
                      </Flex>
                    )}
                  {listSetupThresholdAndCriteria.length > 0 &&
                    checkDataMedicalCheckupMaster() && (
                      <div className="w-[93%]">
                        <ListSettingThresholdAndCriteria
                          data={listSetupThresholdAndCriteria}
                          setEditThresholdAndCriteria={
                            setEditThresholdAndCriteria
                          }
                          setAddRefValue={setAddRefValue}
                        />
                      </div>
                    )}
                </div>
                <div className="flex-1">
                  <Typography className="font-bold">
                    {t('courseRegister.courseRelatedInterview')}
                  </Typography>
                  {!show?.[courseRelatedInterview] && (
                    <>
                      <Flex
                        onClick={() => {
                          if (surveysSelected.length > 0) {
                            handleShow(courseRelatedInterview)
                          } else {
                            setEdit(courseRelatedInterview)
                          }
                        }}
                        className="mt-4 w-[30px] h-[30px] rounded-md bg-primary text-white items-center justify-center cursor-pointer"
                      >
                        <span className="text-[24px]">+</span>
                      </Flex>
                      <div className="h-[155px] invisible"></div>
                    </>
                  )}

                  {show?.[courseRelatedInterview] && (
                    <CourseRelatedInterview
                      setEdit={() => setEdit(courseRelatedInterview)}
                      setHidden={() =>
                        handleShow(courseRelatedInterview, false)
                      }
                      surveys={surveysSelected}
                    />
                  )}

                  <Typography className="font-bold mt-[38px]">
                    {t('courseRegister.applicableOptionalExamination')}
                  </Typography>
                  {!show?.[applicableOptionalExamination] && (
                    <Flex
                      onClick={() => {
                        if (itemsOptions.length > 0) {
                          handleShow(applicableOptionalExamination)
                        } else {
                          setEdit(applicableOptionalExamination)
                        }
                      }}
                      className="mt-4 w-[30px] h-[30px] rounded-md bg-primary text-white items-center justify-center cursor-pointer"
                    >
                      <span className="text-[24px]">+</span>
                    </Flex>
                  )}
                  {show?.[applicableOptionalExamination] && (
                    <ApplicableOptionalExamination
                      setEdit={() => setEdit(applicableOptionalExamination)}
                      setHidden={() =>
                        handleShow(applicableOptionalExamination, false)
                      }
                      itemsOptions={itemsOptions}
                      language={language}
                      loading={loadingOptions}
                    />
                  )}
                </div>
              </Flex>
            )}

            <div
              className={`${edit === inCourseInspectionItems ? '' : 'hidden'}`}
            >
              <CourseTestItemWrapper
                setEdit={() => {
                  setEdit(false)

                  if (!show?.[inCourseInspectionItems])
                    handleShow(inCourseInspectionItems)
                }}
                setListData={setListData}
                originalMedicalCheckupMaster={dataProduct}
              />
            </div>

            <div
              className={`${edit === courseRelatedInterview ? '' : 'hidden'}`}
            >
              <Flex className="pt-[30px] pl-2 tracking-[.7px]">
                <Typography className="font-bold">
                  {t('courseRegister.courseRelatedInterview')}
                </Typography>
              </Flex>
              <CourseRelatedInterviewList
                setEdit={() => {
                  setEdit(false)
                  if (!show?.[courseRelatedInterview])
                    handleShow(courseRelatedInterview)
                }}
                surveys={surveys}
                initSurveys={initSurveys}
                setSurveysSelected={setSurveysSelected}
              />
            </div>

            <div
              className={`${edit === applicableOptionalExamination ? '' : 'hidden'}`}
            >
              <Flex className="pt-[30px] pl-2 tracking-[.7px]">
                <Typography className="font-bold">
                  {t('courseRegister.applicableOptionalExamination')}
                </Typography>
              </Flex>
              <ApplicableOptionalExaminationList
                setEdit={() => {
                  setEdit(false)
                  if (!show?.[applicableOptionalExamination])
                    handleShow(applicableOptionalExamination)
                }}
                options={options}
                initOptions={initOptions}
                setOptionsSelected={setOptionsSelected}
              />
            </div>

            <SaveSuccessModal
              isModalOpen={save}
              onCancel={() => navigate('/product-master')}
              onSuccess={() => navigate('/product-master')}
              textTitle={isUpdate ? t('courseChangeComplete') : ''}
              textContent={isUpdate ? t('content.courseChanged') : ''}
            />
            <CopyProductModal
              isModalOpen={showCopyProductModal}
              onCancel={() => setShowCopyProductModal(false)}
              onSuccess={(productMaster) => {
                showDataWithMedicalCheckupMaster(productMaster, true)
                if (searchParams.get('id')) {
                  const newParams = new URLSearchParams(searchParams)
                  newParams.delete('id')
                  setSearchParams(newParams)
                }
              }}
              options={optionsMedicalCheckupMaster}
              dataProduct={dataProduct}
            />

            <DeleteProductModal
              product={deleteProduct}
              onCancel={() => setDeleteProduct(null)}
              onSuccess={handleDeleteCourse}
            />
            <DeleteProductSuccessModal
              isModalOpen={deleteProductSuccess}
              onCancel={() => setDeleteProductSuccess(false)}
              onSuccess={() => navigate('/product-master')}
            />
          </form>
        </FormProvider>
      </div>

      {addRefValue && (
        <SetupThresholdAndCriteria
          category={getCategoryReferenceValue(listCategoryShow)}
          nameCourse={getValues('name')}
          setAddRefValue={setAddRefValue}
          listSetupThresholdAndCriteria={listSetupThresholdAndCriteria}
          setListSetupThresholdAndCriteria={setListSetupThresholdAndCriteria}
          editThresholdAndCriteria={editThresholdAndCriteria}
          setEditThresholdAndCriteria={setEditThresholdAndCriteria}
        />
      )}
    </>
  )
}
