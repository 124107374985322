import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Checkbox, Empty, Flex, Select, Space, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'

import {
  useGetMedicalCheckupMasterByName,
  useGetMedicalCheckupMasterDetails
} from '../../../hooks/useMedicalCheckupMaster'
import InputLabel from '../../ReservationRegister/components/InputLabel'
import { DataForm } from '../ReservationChange'
import CourseSelect from './CourseSelect'
import styles from './scss/SelectWrapper.module.scss'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { RESERVATION_STATUS } from 'models/reservationManagement'
import {
  filterCourseResponseInValid,
  preventEnterKeyDefault
} from 'utilities/helpers'

type Props = {
  onUpdateCourseId: (data: string) => void
  onUpdateCourseOptionIds: (data: string[]) => void
  selectedText?: string
}

const ReservationInformation: FC<Props> = ({
  onUpdateCourseId,
  onUpdateCourseOptionIds,
  selectedText
}) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const {
    userId,
    userFullName,
    courseId,
    courseName,
    courseOptionIds,
    courseSelect: courseOptionsText,
    status,
    desiredDateTime,
    userInfo
  }: DataForm = state || {}

  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { getAllMedicalCheckupMastersByIds } =
    useGetMedicalCheckupMasterByName()

  const arrayCourseOptionsText = courseOptionsText
    ? courseOptionsText.split(',')
    : []
  const defaultCourseOptions = (courseOptionIds || []).map((item, index) => {
    return {
      label: arrayCourseOptionsText?.[index] || '',
      value: courseOptionIds[index]
    }
  })

  const [selectedCourse, setSelectedCourse] = useState<string>(courseId)
  const [selectedCourseOptions, setSelectedCourseOptions] =
    useState<string[]>(courseOptionIds)
  const [selectCourseOptions, setSelectCourseOptions] = useState<
    {
      value: string
      label: string
    }[]
  >(defaultCourseOptions)
  const isUpdated = useRef<boolean>(false)

  const fetchData = async () => {
    const result = await getMedicalCheckupMastersById(selectedCourse)
    const metadata: { name: string; value: string[] }[] | undefined =
      result?.medicalCheckupMaster?.additionalInfo?.metadata

    if (metadata) {
      const options = metadata.find((item) => item.name === 'options')?.value

      if (Array.isArray(options) && options.length > 0) {
        const medicalCheckupMasterOptions =
          await getAllMedicalCheckupMastersByIds(options)

        const validData = filterCourseResponseInValid(
          medicalCheckupMasterOptions || [],
          userInfo
        )

        const selectOptions = validData.map(({ medicalCheckupMaster }) => ({
          value: medicalCheckupMaster.refId,
          label: medicalCheckupMaster.displayName
        }))

        setSelectCourseOptions(selectOptions)
      }
    }
  }

  useEffect(() => {
    setSelectCourseOptions([])
    fetchData().then()

    if (selectedCourse === courseId && !isUpdated.current) {
      setSelectedCourseOptions(courseOptionIds)
      onUpdateCourseOptionIds(courseOptionIds)
    } else {
      isUpdated.current = true
      setSelectedCourseOptions([])
      onUpdateCourseOptionIds([])
    }
  }, [selectedCourse])

  const formatDate = t('reservationChange.label.formatDate')

  const statusText =
    status === RESERVATION_STATUS.COLLECTED_DESIRED_DATE
      ? t('reservationManagement.widget.status.collectDate')
      : t('reservationManagement.widget.status.waitConfirm')

  const handleUpdateCourse = (data: string) => {
    setSelectedCourse(data)
    onUpdateCourseId(data)
  }

  const handleUpdateCourseOptions = (data: string[]) => {
    setSelectedCourseOptions(data)
    onUpdateCourseOptionIds(data)
  }

  return (
    <div className="rounded-[5px] bg-[#FFFFFF] px-[10px] pb-3 pt-[10px] drop-shadow-md">
      <Flex align="center" className="mt-[6px]">
        <InputLabel label={'ID'} />
        <Typography.Text className="ml-[14px]">
          {userId || 'ー'}
        </Typography.Text>
      </Flex>

      <Flex align="center" className="mt-[6px]">
        <InputLabel label={t('reservationChange.label.fullName')} />
        <Typography.Text className="ml-[14px]">
          {userFullName || 'ー'}
        </Typography.Text>
      </Flex>

      <Flex align="center" className="mt-[6px]">
        <InputLabel label={t('reservationChange.label.courseName')} />
        <div className="w-full ml-[7px]">
          <CourseSelect
            placeholder={t('productManagement.widget.placeholder.search')}
            defaultData={[{ label: courseName, value: selectedCourse }]}
            onUpdateSelectedOption={handleUpdateCourse}
            userInfo={userInfo}
          />
        </div>
      </Flex>

      <Flex align="center" className="mt-[6px]">
        <InputLabel label={t('reservationChange.label.courseSelect')} />

        <div className="w-full ml-[14px]">
          <Select
            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            mode="multiple"
            onChange={handleUpdateCourseOptions}
            value={selectedCourseOptions}
            showSearch
            filterOption={(inputValue, option) => {
              if (!option) return false
              return option?.label
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }}
            options={selectCourseOptions}
            optionRender={(option) => {
              const isChecked =
                selectedCourseOptions.includes(option.value as string) || false

              return (
                <Space>
                  <Checkbox
                    key={option.key}
                    onKeyDown={preventEnterKeyDefault}
                    checked={isChecked}
                  />
                  <Typography.Text>{option.label}</Typography.Text>
                </Space>
              )
            }}
            maxTagCount="responsive"
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                overlayStyle={{
                  pointerEvents: 'none'
                }}
                title={omittedValues.map(({ label }) => label).join('、')}
              >
                <span>+ {omittedValues.length} ...</span>
              </Tooltip>
            )}
            menuItemSelectedIcon={false}
            prefix={
              <SearchOutlined
                style={{ pointerEvents: 'none', marginTop: '3px' }}
              />
            }
            suffixIcon={null}
            placeholder={t('reservationChange.placeholder.searchOption')}
            className={`${styles.select_wrapper} w-[424px]`}
          />
        </div>
      </Flex>

      <Flex align="center" className="mt-[6px]">
        <InputLabel label={t('reservationChange.label.reservationDateTime')} />
        <Typography.Text className="ml-[14px]">
          <div>
            {[1, 2, 3].map((_, index) => {
              const item = desiredDateTime?.find(
                (item) => item.priority === index + 1
              )

              if (!item?.date) {
                return <Typography key={index + 1}>ー</Typography>
              }

              const { date, timeOfDay } = item
              const dateString = dayjs(date).format(formatDate)

              const time = timeOfDay
                ? t('reservationManagement.content.button.morning')
                : t('reservationManagement.content.button.afternoon')

              return (
                <Typography key={index + 1} className="whitespace-pre">
                  {t(`reservationChange.label.date${index + 1}`)} :{'  '}
                  {dateString + '    ' + time}
                </Typography>
              )
            })}
          </div>
        </Typography.Text>
      </Flex>

      <Flex align="center" className="mt-[6px]">
        <InputLabel label={t('reservationChange.label.reservationStatus')} />
        <Typography.Text className="ml-[14px]">
          {statusText || 'ー'}
        </Typography.Text>
      </Flex>

      <Flex align="center" className="mt-[6px]">
        <InputLabel label={t('reservationChange.label.selectedDateTime')} />
        <Typography.Text className="ml-[14px]">
          {selectedText ?? 'ー'}
        </Typography.Text>
      </Flex>
    </div>
  )
}

export default ReservationInformation
