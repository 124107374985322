import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'

export const initialListCategory = (
  categories: {
    refId: string
    items: { order: number; refId: string }[]
  }[],
  originalListCategory: DataItemMaster[],
  originalListTestItem: DataItemMaster[]
):DataItemMaster[] => {
  const listCategory = categories?.map(
    ({
      refId,
      items
    }: {
      refId: string
      items: { order: number; refId: string }[]
    }) => {
      const findCategory = originalListCategory.find(
        (item) => item.refId === refId
      )
      return {
        ...findCategory,
        items: items.slice().sort((a, b) => a.order - b.order)
      }
    }
  )
  const newListCategory = listCategory?.map((category) => {
    // find category in list category has key1 = 'カデコマスタ'
    const findCategoryItem = originalListTestItem.find((item) =>
      (category?.associatedTestItemMasterRefIds || []).some(
        (refId) =>
          refId === item.refId && item.additionalInfo.key1 === 'カテゴリマスタ'
      )
    )

    return {
      ...findCategoryItem,
      ...category,
      itemId: findCategoryItem?.refId, // id of item belong to category
      items: [...(category.items || [])]
        ?.map((i) => {
          const findItem = originalListTestItem.find(
            (item) => item.refId === i.refId
          )

          return findItem
        })
        .filter((i): i is DataItemMaster => i !== undefined)
    }
  })

  return newListCategory as DataItemMaster[]
}
