import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Typography } from 'antd'
import { useRecoilValue } from 'recoil'

import FlowButton from '../../../buttons/FlowButton'
import FixedQuestions, {
  FixedQuestionsProps
} from '../stepsFixed/FixedQuestions'
import { STEP_SCENARIO } from 'configs/constant'
import { set } from 'date-fns'
import useCreateScenario from 'hooks/scenario/useCreateScenario'
import { scenarioCreateAtom } from 'pages/Scenario/ScenarioCreate/atoms/create/scenarioCreateAtom'
import { Flow, Option } from 'pages/Scenario/types/types'
import { uuid } from 'utilities/helpers'

export interface MessageContentProps extends FixedQuestionsProps {
  readonly description?: string
  readonly isEnabledBtns?: boolean
  readonly questions?: Flow[]
}

export function MessageContent({
  description,
  isEnabledBtns = true
}: MessageContentProps) {
  const { t } = useTranslation()
  const { onCreateBlock } = useCreateScenario()
  const scenarioCreate = useRecoilValue(scenarioCreateAtom)

  const [genderBtns, setGenderBtns] = useState<Option[]>([
    {
      order: 0,
      displayName: 'buttons.button3',
      value: 'accept'
    },
    {
      order: 1,
      displayName: 'buttons.button4',
      value: 'cancel'
    }
  ])

  useEffect(() => {
    if (!scenarioCreate?.refId) return

    setGenderBtns((prev) =>
      prev.map((btn) => ({
        ...btn,
        isActivated: btn.order === 0
      }))
    )
  }, [scenarioCreate?.refId])

  const handleClickGenderItem = (order?: number) => {
    // change
    setGenderBtns((prev) =>
      prev.map((btn) => {
        return {
          ...btn,
          isActivated: btn.order === order ? !btn.isActivated : false
        }
      })
    )

    onCreateBlock([
      STEP_SCENARIO.SECOND_STEP,
      STEP_SCENARIO.THIRD_STEP,
      STEP_SCENARIO.FOURTH_STEP,
      STEP_SCENARIO.LAST_STEP
    ])
  }

  return (
    <React.Fragment>
      <div className="border border-borderGray bg-white p-2 m-3 min-h-20">
        <Typography>{description || ''}</Typography>
      </div>

      {isEnabledBtns && (
        <div className="flex flex-col gap-2 m-3">
          {genderBtns.map((button) => (
            <FlowButton
              key={uuid()}
              onClick={handleClickGenderItem}
              order={button.order}
              isActivated={button.isActivated ?? false}
              type={button.type}
              nextStep={<FixedQuestions genderBtns={genderBtns} />}
              genderBtns={genderBtns}
            >
              {t(button.displayName ?? '')}
            </FlowButton>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}
