import { DocumentNode } from 'graphql'

import { gql } from '@apollo/client'

export const CREATE_SCENARIO: DocumentNode = gql`
  mutation addScenarioChatTemplate(
    $scenarioTemplate: ScenarioChatTemplateInput!
  ) {
    addScenarioChatTemplate(scenarioTemplate: $scenarioTemplate) {
      message
      status
      timestamp
      payload {
        categories
        createdBy
        createdDate
        modifiedDate
        name
        refId
        selectionType
        status
        trigger
        flows {
          content
          options {
            displayName
            order
            refId
            value
          }
          order
          refId
          scenarioChatTemplateRefId
          selectionType
          step
          setting {
            choiceType
            isMultiple
            refId
          }
        }
      }
    }
  }
`

export const UPDATE_SCENARIO: DocumentNode = gql`
  mutation updateScenarioChatTemplate(
    $scenarioTemplate: ScenarioChatTemplateInput!
  ) {
    updateScenarioChatTemplate(scenarioTemplate: $scenarioTemplate) {
      message
      status
      timestamp
    }
  }
`
