import { useTranslation } from 'react-i18next'

import { Button, Flex } from 'antd'

import { ButtonsProps } from '../types'

const Buttons = ({ confirm, cancel }: ButtonsProps) => {
  const { t } = useTranslation()

  return (
    <Flex className="gap-2 mt-4">
      <Button
        type="primary"
        className="min-w-44 rounded-sm font-bold"
        onClick={confirm}
      >
        {t('button.confirm')}
      </Button>
      <Button
        className="min-w-44 rounded-sm border-primary text-primary font-bold"
        onClick={cancel}
      >
        {t('button.cancel')}
      </Button>
    </Flex>
  )
}

export default Buttons
