import { CourseSetting } from '../pages/ProductManagement/ProductManagement'

export type ReservationCreateData = {
  checkupUserRefIds: string[]
  medicalCheckupMasterRefId: string
  reservationDatetime: { datetime: string }
}

export type ReservationUpdateData = {
  refId: string
  medicalCheckupMasterRefId: string
  reservationDatetime: { datetime: string }
}

export enum RESERVATION_STATUS {
  RESERVED = 'RESERVED',
  UNCONFIRMED = 'UNCONFIRMED',
  COLLECTED_DESIRED_DATE = 'COLLECTED_DESIRED_DATE',
  CANCELLED = 'CANCELLED'
}

export type ReservationData = {
  refId?: string
  courseId: string
  courseName: string
  courseSelect?: string
  date: string
  time: string
  status?: RESERVATION_STATUS
  remainedSlots?: number
  userId?: string
  userFullName?: string
  userPhone?: string
  courseSetting?: CourseSetting
}

export type ReservationConditions = {
  filter: {
    idOrFullNameOrPhone?: string
    date?: {
      startDate: string
      endDate: string
    }
    status?: string
    courseId?: string[]
    display?: string
  }
  pagination: {
    page: number
    size: number
  }
  order?: keyof ReservationData
  sort?: 'asc' | 'desc'
}

export enum RESERVATION_COLLECTION_STATUS {
  UNRESERVED = 'UNRESERVED',
  COLLECT_DATE = 'COLLECT',
  WAIT_CONFIRM = 'WAIT',
  CONFIRMED = 'CONFIRMED'
}

export type ReservationCollectionData = {
  refId: string
  courseId: string
  courseName: string
  courseOptionIds: string[]
  courseSelect?: string
  status: RESERVATION_STATUS
  date?: string
  time?: string
  userId: string
  userFullName: string
  userPhone?: string
  userInfo: { sex: string; age: string }
  desiredDatetime?: { priority: number; date: string; timeOfDay: string }[]
  isCourseDeleted: boolean
  isUserDeleted: boolean
}

export type ReservationCollectionConditions = {
  filter: {
    idOrFullNameOrPhone?: string
    date?: {
      startDate: string
      endDate: string
    }
    status?: string
  }
  pagination: {
    page: number
    size: number
  }
  order?: keyof ReservationCollectionData
  sort?: 'asc' | 'desc'
}
