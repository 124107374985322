import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Empty, Typography } from 'antd'

import styles from './InspectionItemSetting.module.scss'
import CollapseCategoryItem from './components/CollapseCategoryItem'
import NoteHiddenSettingModal from './components/NoteHiddenSettingModal'
import { searchValues } from './helper'
import { DataItemMaster } from './types/filterItemMaster'
import { LIST_DRAG_AND_DROP } from 'configs/constant'
import useDebounce from 'hooks/useDebounce'

type Props = {
  showInspectionDescription: boolean
  showUpdate: boolean

  category: DataItemMaster[]
  language: string
  searchTerm?: string
  setListCategoryShow: React.Dispatch<React.SetStateAction<DataItemMaster[]>>
}

const CollapsibleList = ({
  showInspectionDescription,
  showUpdate,
  category,
  language,
  searchTerm = '',
  setListCategoryShow
}: Props) => {
  const { t } = useTranslation()
  const [listCategory, setListCategory] =
    React.useState<DataItemMaster[]>(category)

  const searchTermDebounced = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    const searchValue = searchValues(
      searchTermDebounced,
      [...category],
      language
    )
    setListCategory(searchValue)
  }, [category, language, searchTermDebounced])

  const [categoryNote, setCategoryNote] = React.useState<
    DataItemMaster | undefined
  >(undefined)
  const [showNoteHiddenSettingModal, setShowNoteHiddenSettingModal] =
    React.useState(false)

  const handleSettingModal = (category: DataItemMaster) => {
    setCategoryNote(category)
    setListCategory((prev) =>
      prev.map((item) => ({
        ...item,
        isActiveKey:
          category && category?.refId === item.refId
            ? !item.isActiveKey
            : item.isActiveKey
      }))
    )
    setListCategoryShow((prev) =>
      prev.map((item) => ({
        ...item,
        isActiveKey:
          category && category?.refId === item.refId && category.isActiveKey
            ? !item.isActiveKey
            : item.isActiveKey
      }))
    )

    if (!category.isActiveKey) {
      setShowNoteHiddenSettingModal((prev) => !prev)
    }
  }
  const handleActionNoteModal = () => {
    setListCategory((prev) =>
      prev.map((item) => ({
        ...item,
        isActiveKey:
          categoryNote?.refId === item.refId
            ? !item.isActiveKey
            : item.isActiveKey
      }))
    )
    setListCategoryShow((prev) =>
      prev.map((item) => ({
        ...item,
        isActiveKey:
          categoryNote?.refId === item.refId
            ? !item.isActiveKey
            : item.isActiveKey
      }))
    )
    setShowNoteHiddenSettingModal((prev) => !prev)
  }

  return (
    <div
      className={`category-with-items w-full border border-[#BDCBD5] pr-[8px] pb-[44px] bg-white rounded-r-lg shadow-md ${styles.inspection_content_box} ${styles.inspection_content_header}`}
    >
      <Typography className="font-bold pl-[7px] mt-[13px] leading-none">
        {t('setInspection')}
      </Typography>

      <div className="mt-[25px] max-h-[700px] overflow-y-scroll overflow-x-hidden">
        <Droppable
          droppableId={`${LIST_DRAG_AND_DROP.LIST1}`}
          type={LIST_DRAG_AND_DROP.CATEGORY}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${listCategory?.length < 1 ? 'pb-[60px]' : ''}`}
            >
              {listCategory?.length ? (
                listCategory?.map((item, index) => (
                  <CollapseCategoryItem
                    key={item.refId}
                    category={item}
                    showUpdate={showUpdate}
                    language={language}
                    showInspectionDescription={showInspectionDescription}
                    index={index}
                    handleSettingModal={handleSettingModal}
                  />
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      {showNoteHiddenSettingModal && (
        <NoteHiddenSettingModal
          isModalOpen
          onCancel={() => setShowNoteHiddenSettingModal(false)}
          onSuccess={handleActionNoteModal}
          category={categoryNote}
        />
      )}
    </div>
  )
}

export default CollapsibleList
