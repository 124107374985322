import { useFormContext } from 'react-hook-form'

import { DRAWER_DATA_TYPE } from 'configs/constant'

const useCheckErrorField = () => {
  const {
    trigger,
    formState: { errors },
    clearErrors
  } = useFormContext()

  const checkError = (key: DRAWER_DATA_TYPE, value: string) => {
    if (value.trim()) {
      clearErrors(key)
    } else {
      trigger(key)
    }
  }

  return {
    checkError,
    errors
  }
}

export default useCheckErrorField
