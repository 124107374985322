import React, { FC, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { Button, Flex, Table, TableColumnsType, Typography } from 'antd'
import { TFunction } from 'i18next'

import {
  useDeleteInterView,
  useInterviewList
} from '../../../hooks/useInterviewList'
import HealthCheckModal from '../../ConsultantManagement/components/HealthCheckModal'
import styles from './InterviewList.module.scss'
import DeleteMedicalQuestion from './modals/DeleteMedicalQuestion'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { ReactComponent as Copy } from 'assets/svgs/copy.svg'
import { PaginationCustom } from 'components/elements/PaginationCustom/PaginationCustom'
import { openNotification } from 'components/widgets/Notification'
import { FEEDBACK_STATUS } from 'configs/constant'
import useSurveyFeedback, {
  useCreateSurveyFeedback
} from 'hooks/useSurveyFeedback'
import useSurveyForFeedback from 'hooks/useSurveyForFeedback'
import { IInterviewList } from 'models/surveyListFeedback'

type Props = {
  t: TFunction<'translation', undefined>
  userRefId: string
  patientName: string
}

const InterviewList: FC<Props> = ({ t, userRefId, patientName }) => {
  const navigate = useNavigate()
  const [isOpenCheckHealthModal, setOpenCheckHealthModal] =
    useState<boolean>(false)
  const [isOpenDeleteMedicalQuestionModal, setOpenDeleteMedicalQuestionModal] =
    useState<boolean>(false)
  const [isSortAnswerDateAsc, setIsSortAnswerDateAsc] = useState<boolean>(false)
  const [interviewListItem, setInterviewListItem] = useState<IInterviewList>()

  const {
    interviewList,
    setVariablesFilter,
    totalInterview,
    variablesFilter,
    loadInterviewList,
    loading,
    onReloadId
  } = useInterviewList(userRefId)

  const [surveyFeedback, setSurveyFeedback] = useState<any>(null)
  const [survey, setSurvey] = useState<any>(null)
  const { variablesFilter: filterSurveyFeedback, loadSurveyFeedbackList } =
    useSurveyFeedback()
  const { variablesFilter: filterSurvey, loadSurveyList } =
    useSurveyForFeedback()

  const onClickShowResult = async (data: string) => {
    setOpenCheckHealthModal(true)

    const variablesFilterSurveyFeedback = {
      ...filterSurveyFeedback,
      filter: `(eq,STRING,refId,${data})`,
      paginationInput: { page: 0, size: 1 }
    }
    const feedbacks = await loadSurveyFeedbackList({
      variables: variablesFilterSurveyFeedback
    })
    const feedback = feedbacks.data.filterSurveyFeedback.payload[0]
    setSurveyFeedback(feedback)

    const variablesFilterSurvey = {
      ...filterSurvey,
      filter: `(eq,STRING,refId,${feedback.surveyRefId})`,
      paginationInput: { page: 0, size: 1 }
    }
    const listSurvey = await loadSurveyList({
      variables: variablesFilterSurvey
    })
    const survey = listSurvey.data.filterSurvey.payload[0]
    setSurvey(survey)
  }

  const [surveyFeedbackData, setSurveyFeedbackData] = useState<any>([])
  const { parseFormFeedbackSurvey } = useCreateSurveyFeedback()
  useEffect(() => {
    if (survey && surveyFeedback) {
      const dataShow = parseFormFeedbackSurvey(survey, surveyFeedback, true)
      dataShow.patientId = userRefId
      dataShow.patientName = patientName
      setSurveyFeedbackData(dataShow)
    }
  }, [survey, surveyFeedback])

  const {
    deleteInterview,
    loading: isLoadingDelete,
    data: dataDelete,
    error: errorDelete
  } = useDeleteInterView()

  const handleNavigate = (surveyFeedbackRefId?: string) => {
    if (surveyFeedbackRefId) {
      navigate({
        pathname: '/interview-result-create',
        search: createSearchParams({
          t: '2',
          userRefId: userRefId,
          surveyFeedbackRefId: surveyFeedbackRefId
        }).toString()
      })
    } else {
      navigate({
        pathname: '/interview-result-create',
        search: createSearchParams({ t: '2', userRefId: userRefId }).toString()
      })
    }
  }

  const onSortByAnswerDate = () => {
    setIsSortAnswerDateAsc(!isSortAnswerDateAsc)
    setVariablesFilter((prev) => {
      return {
        ...prev,
        sortBy: !isSortAnswerDateAsc
          ? '(asc, createdDateTime)'
          : '(desc, createdDateTime)'
      }
    })
  }

  useEffect(() => {
    if (userRefId !== undefined || userRefId !== '') {
      onReloadId(userRefId)
    }
  }, [userRefId])

  const onChangePage = (value: number) => {
    setVariablesFilter((prev) => {
      const sizeNow = prev.paginationInput?.size ?? 0
      return {
        ...prev,
        paginationInput: { page: value - 1, size: sizeNow }
      }
    })
  }
  const goToFirstPage = () => {
    setVariablesFilter((prev) => {
      const sizeNow = prev.paginationInput?.size ?? 0
      return {
        ...prev,
        paginationInput: { page: 0, size: sizeNow }
      }
    })
  }
  const goToLastPage = () => {
    const size = variablesFilter.paginationInput?.size ?? 0
    const totalPages = Math.ceil(totalInterview / size)
    setVariablesFilter((prev) => {
      const sizeNow = prev.paginationInput?.size ?? 0
      return {
        ...prev,
        paginationInput: { page: totalPages - 1, size: sizeNow }
      }
    })
  }

  useEffect(() => {
    if (
      (!isLoadingDelete && dataDelete !== undefined) ||
      (!isLoadingDelete && errorDelete !== undefined)
    ) {
      setOpenDeleteMedicalQuestionModal(false)
      loadInterviewList()
      if (errorDelete !== undefined) {
        openNotification({
          type: 'error',
          title: t('commonError'),
          message: ''
        })
      } else {
        const { deleteSurveyFeedback } = dataDelete
        const { payload } = deleteSurveyFeedback
        if (payload && payload.length > 0 && payload[0].isDeleted) {
          openNotification({
            type: 'success',
            title: t('successfullyDeleted'),
            message: ''
          })
        } else {
          openNotification({
            type: 'error',
            title: t('commonError'),
            message: ''
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDelete, dataDelete, errorDelete])

  const columns: TableColumnsType = [
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-bold text-nowrap">
            {t('customerDetail.answerDate')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              onSortByAnswerDate()
            }}
          />
        </Flex>
      ),
      dataIndex: 'createdDateTime',
      key: 'createdDateTime',
      width: 120,
      render: (text) => (
        <Flex
          justify="center"
          className="font-bold tracking-[1.4px] whitespace-nowrap"
        >
          {text}
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-bold whitespace-nowrap">
            {t('customerDetail.table.title.result')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'result',
      key: 'result',
      width: 200,
      render: (_, record) => (
        <Flex justify="center">
          <Copy
            onClick={() => {
              onClickShowResult(record.id)
            }}
            className="cursor-pointer"
          />
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center" align="center">
          <Typography className="mr-3 font-bold whitespace-nowrap">
            {t('customerDetail.answerMethod')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'answerMethod',
      key: 'answerMethod',
      width: 150,
      render: (text) => (
        <Flex
          justify="center"
          className="font-bold tracking-[1.4px] whitespace-nowrap"
        >
          {text}
        </Flex>
      )
    },
    {
      title: <></>,
      dataIndex: 'key',
      key: 'key',
      width: 300,
      render: (index) => (
        <Flex justify="end">
          <Button
            type="primary"
            autoInsertSpace={false}
            onClick={() => {
              const items = interviewList.filter((item) => item.id === index)
              if (items.length > 0) {
                handleNavigate(items[0].id)
              }
            }}
            className="h-[30px] w-[80px] rounded-[4px] bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
          >
            {t('customerDetail.table.edit')}
          </Button>

          <Button
            type="primary"
            autoInsertSpace={false}
            onClick={() => {
              const items = interviewList.filter((item) => item.id === index)
              if (items.length > 0) {
                if (items[0]?.feedbackStatus === FEEDBACK_STATUS.COMPLETED) {
                  openNotification({
                    type: 'error',
                    title: t('commonError'),
                    message: t('errors.deleteCompletedSurveyFeedback')
                  })

                  return
                }

                setInterviewListItem(undefined)
                setInterviewListItem(items[0])
                setOpenDeleteMedicalQuestionModal(true)
              }
            }}
            className="ml-6 h-[30px] w-[80px] rounded-[4px] bg-[#D9E0E5] p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
          >
            {t('customerDetail.table.delete')}
          </Button>
        </Flex>
      )
    },
    {
      title: <></>,
      render: () => null
    }
  ]

  return (
    <div className="pr-[660px]">
      <Flex className="mb-[34px] w-full pt-[12px] gap-2" justify="end">
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          onClick={() => {
            handleNavigate()
          }}
          className="h-[30px] px-2 rounded-none border-[1px] border-[#137695] bg-white p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
        >
          {t('customerDetail.table.button.register2')}
        </Button>
        {/* <Download className="cursor-pointer" /> */}
      </Flex>

      <div>
        <Table
          style={{ borderBottom: 'none' }}
          className={`${styles.table_wrapper}`}
          columns={columns}
          dataSource={interviewList}
          pagination={false}
          loading={loading}
        />
      </div>
      <PaginationCustom
        onChangePage={onChangePage}
        currentPage={variablesFilter.paginationInput?.page ?? 0}
        total={totalInterview}
        onGoToFirstPage={goToFirstPage}
        onGoToLastPage={goToLastPage}
        pageSize={variablesFilter.paginationInput?.size ?? 0}
      />
      <div>
        {isOpenCheckHealthModal && (
          <HealthCheckModal
            isModalOpen={isOpenCheckHealthModal}
            onCancel={() => {
              setOpenCheckHealthModal(false)
            }}
            surveyFeedbackList={surveyFeedbackData}
          />
        )}

        <DeleteMedicalQuestion
          t={t}
          isOpen={isOpenDeleteMedicalQuestionModal}
          onDelete={async () => {
            if (interviewListItem) {
              deleteInterview(interviewListItem.id)
            }
          }}
          isLoadingDelete={isLoadingDelete}
          dataInterview={interviewListItem}
          onCancel={() => {
            setOpenDeleteMedicalQuestionModal(false)
          }}
        />
      </div>
    </div>
  )
}

export default InterviewList
