import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Row, Typography } from 'antd'
import { useRecoilValue } from 'recoil'

import { useChatState } from '../../../../../hooks/chat/useChatHistory'
import { Tenant } from '../../../../../types/Tenant'
import cn from '../../../../../utilities/cn'
import { isArrayEmpty } from '../../../../../utilities/helpers'
import { scenarioCreateAtom } from '../../atoms/create/scenarioCreateAtom'
import { GenderMessage } from './TemplateMessage'
import { ReactComponent as BotLogo } from 'assets/svgs/bot-logo.svg'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onCancel: () => void
  tenant: Tenant | undefined
}

const ChatModalSimulator: FC<Props> = ({ isOpen, onCancel, tenant }) => {
  const { chatHistory, setChatHistory } = useChatState()
  const lastMessageRef = React.useRef(null)
  const { t } = useTranslation()
  const { flows } = useRecoilValue(scenarioCreateAtom)

  const initChat = () => {
    setChatHistory([
      {
        sender: 'A',
        extra: 'gender',
        message: <GenderMessage />
      }
    ])
  }

  React.useEffect(() => {
    if (lastMessageRef.current) {
      // @ts-ignore
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatHistory])

  React.useEffect(() => {
    if (isArrayEmpty(flows)) return
    initChat()
  }, [flows])

  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={() => {
        onCancel()
      }}
      children={
        <Flex justify="center" className="flex-col h-full min-h-[600px] gap-3">
          <Typography className="flex justify-center text-[20px] font-bold tracking-[1px]">
            {t('scenarioCreate.modal.simulator.title')}
          </Typography>

          <Flex className="px-6 h-[500px] overflow-auto">
            <Flex className="bg-[#EFF3F6] border border-[#BDCBD5] w-full h-full flex-col rounded shadow-xl">
              <Row className="min-h-8 bg-gradient-to-r justify-center items-center from-[#347a91] to-[#60acb0] w-full">
                <Typography className="flex text-white text-sm font-bold tracking-[1px]">
                  {tenant?.additionalInfo?.clinicName ??
                    `＊＊${t('scenarioCreate.modal.simulator.clinic')}`}
                </Typography>
              </Row>
              <Flex className="p-3 overflow-auto">
                {isArrayEmpty(chatHistory) ? (
                  <div />
                ) : (
                  <Flex className="bg-white border border-[#CDD6DD] w-full h-full p-3 flex-col space-y-3 overflow-scroll">
                    {chatHistory.map((chat: any, index: number) => (
                      <div
                        key={index}
                        className={`flex ${chat.sender === 'A' ? 'justify-start' : 'justify-end'}`}
                      >
                        {chat.sender === 'A' && (
                          <BotLogo className="w-16 h-16 mr-3" />
                        )}
                        <div
                          className={cn(
                            'shadow-xl border p-2 whitespace-pre-line',
                            chat.sender === 'A'
                              ? 'rounded-r-lg rounded-b-lg bg-[#EFF3F6] border-[#BDCBD5] w-[75%]'
                              : 'rounded-l-lg rounded-t-lg bg-[#CDECEC] border-[#5FA3B8]'
                          )}
                        >
                          {chat.sender === 'A' ? (
                            chat.message
                          ) : (
                            <p>{chat.message}</p>
                          )}
                        </div>
                      </div>
                    ))}
                    <div ref={lastMessageRef} />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex justify="center" className="">
            <Button
              type="default"
              htmlType="button"
              onClick={onCancel}
              className="h-[30px] w-[180px] rounded-none border-[1px] border-[#137695] bg-white p-0 text-center font-bold text-[#137695] shadow-none"
            >
              {t('button.close')}
            </Button>
          </Flex>
        </Flex>
      }
    />
  )
}

export default ChatModalSimulator
