import React, { ReactNode, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { controlPanelTextStyles, controlPanelWrapper } from './PatientMemo'
import Title from './Title'
import { ReactComponent as Pen } from 'assets/svgs/pen.svg'
import { ReactComponent as Reload } from 'assets/svgs/reload.svg'
import { TAB } from 'models/customer'

function PatientInfo({
  onOpenEditForm,
  setTab,
  tableInfo
}: {
  onOpenEditForm: () => void
  setTab: (tab: TAB) => void
  tableInfo: ReactNode
}) {
  const { t } = useTranslation()

  return (
    <div className={`${controlPanelWrapper} px-[20px]`}>
      <Flex align="center" justify="space-between">
        <Title text={t('dashboard.basic_info')} isIcon />

        <Flex align="center" className="ml-[-100px]">
          <Reload
            width="13px"
            height="16px"
            className="mr-[10px] cursor-pointer"
          />
          <Typography
            className={`tracking-[1.4px] underline ${controlPanelTextStyles}`}
          >
            {dayjs(new Date()).format(t('formatDate'))}
          </Typography>
        </Flex>

        <Pen
          width="18px"
          height="19px"
          onClick={() => {
            onOpenEditForm()
            setTab(TAB.CONTROL)
          }}
          className="cursor-pointer"
        />
      </Flex>
      <div className="h-[600px] mt-[10px] overflow-y-auto">{tableInfo}</div>
    </div>
  )
}

export default memo(PatientInfo)
