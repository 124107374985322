import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'

import { useRolePermissions } from '../hooks/role-permission/usePermissions'
import { LoginApiProps, loginApi } from '../services/AuthService'
import { decodedToken, getAccessToken } from '../utilities/helpers'
import { LoginPayload } from 'pages/Login/types'

interface Props {
  children?: React.ReactNode
}

export type AuthContextType = {
  auth: LoginPayload | null
  token: string | null
  logOut: () => void
  loginAction: ({ email, password, isLoginParamsTenant }: LoginApiProps) => any
  user: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setAuth: Dispatch<SetStateAction<LoginPayload | null>>
  setToken: Dispatch<SetStateAction<string | null>>
  setUser: Dispatch<SetStateAction<any>>
}

const AuthContext = createContext<AuthContextType | null>(null)

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const { resetUserId, setUserIdState } = useRolePermissions()

  const [auth, setAuth] = useState<LoginPayload | null>(null)
  const [user, setUser] = useState<any>(null)
  const [token, setToken] = useState<string | null>(getAccessToken() ?? null)
  const [loading, setLoading] = useState<boolean>(false)

  React.useEffect(() => {
    if (!token) return

    const sub = decodedToken(token)
    setUserIdState(sub)
  }, [token])

  const loginAction = async ({
    email,
    password,
    isLoginParamsTenant
  }: LoginApiProps) => {
    return await loginApi({ email, password, isLoginParamsTenant })
  }

  const logOut = () => {
    setAuth(null)
    setToken(null)

    localStorage.clear()
    resetUserId()

    navigate('/')
    return
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        auth,
        loginAction,
        logOut,
        user,
        loading,
        setLoading,
        setAuth,
        setToken,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}
