import { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { notification } from 'antd'
import dayjs from 'dayjs'
import { atom, useRecoilState } from 'recoil'

import { useMedicalCheckupMaster } from './useMedicalCheckupMaster'
import { useLazyQuery } from '@apollo/client'
import { RESERVATION_STATUS } from 'configs/constant'
import { FILTER_SLOTS } from 'graphql/PatientDetail/reservation'
import debounce from 'lodash.debounce'
import {
  CourseResponse,
  ReservationHistory,
  ReservationHistoryAtom,
  TableSearchSlotReservation
} from 'pages/CustomerDetail/types/types'
import { uuid } from 'short-uuid'
import { Endpoint } from 'utilities/apolloClient'
import {
  formatJapaneseDate,
  getFirstAndLastDayOfMonth,
  getTimeRange
} from 'utilities/helpers'

export interface AvailableSlots {
  bydate: string
  byday: string
  byhour: string
  emptySlotCount: number
  remainedSlotCount: number
}

export const initialTableSearchSlotReservation: TableSearchSlotReservation = {
  action: 'search',
  reservationId: '',
  course: {
    list: [],
    filterList: [],
    courseId: '',
    courseName: ''
  },
  option: {
    list: [],
    filterList: [],
    optionIds: [],
    optionName: ''
  },
  date: {
    year: String(dayjs().year()),
    month: String(dayjs().month() + 1)
  },
  user: {
    id: '',
    firstName: '',
    firstNameKana: '',
    lastName: '',
    lastNameKana: ''
  },
  slot: {
    loading: false,
    list: []
  }
}

export const tableSearchSlotReservationAtom = atom<TableSearchSlotReservation>({
  key: 'tableSearchSlotReservationAtom',
  default: initialTableSearchSlotReservation
})

export const reservationHistoryAtom = atom<ReservationHistoryAtom[]>({
  key: 'reservationHistoryAtom',
  default: []
})

const usePatientDetail = () => {
  const [reservationHistory, setReservationHistory] = useRecoilState(
    reservationHistoryAtom
  )
  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()

  const [tableSearchSlotReservation, setTableSearchSlotReservation] =
    useRecoilState(tableSearchSlotReservationAtom)

  const { t } = useTranslation()

  const [fetchAvailableSlotsByDateRange] = useLazyQuery(FILTER_SLOTS, {
    context: { version: Endpoint.RESERVATION },
    fetchPolicy: 'network-only'
  })

  const handleSearchOption = debounce(async (name: string) => {
    if (!name) {
      return
    }
  }, 500)

  const handleSearchCourse = debounce(async (name: string) => {
    if (!name) {
      setTableSearchSlotReservation((prev) => ({
        ...prev,
        course: {
          ...prev.course,
          filterList: []
        }
      }))
      return
    }

    const courseFilterByRefId = tableSearchSlotReservation.course.list.filter(
      (course) =>
        course.medicalCheckupMaster.displayName
          ? course.medicalCheckupMaster.displayName
              .toLowerCase()
              .includes(name.toLowerCase())
          : false
    )

    const courseConvertedTypeSelect = courseFilterByRefId.map((item: any) => ({
      key: uuid(),
      value: item.medicalCheckupMaster.refId,
      label: item.medicalCheckupMaster.displayName
    }))

    setTableSearchSlotReservation((prev) => ({
      ...prev,
      course: {
        ...prev.course,
        filterList: courseConvertedTypeSelect
      }
    }))
  }, 500)

  const convertReservationToTableData = async (
    reservationData: ReservationHistory[],
    coursesData: CourseResponse[]
  ) => {
    // return empty list if filterReservation list empty
    if (!reservationData || reservationData.length <= 0) {
      setReservationHistory([])
      return
    }

    const mergeOptionIds = reservationData
      .map((reservationItem) => reservationItem.optionRefIds)
      .flat()

    const options = await getOptionsFromOptionIds(mergeOptionIds)

    const reservationHistory = reservationData.map((reservationItem) => {
      const medicalCheckupItem = getMedicalCheckupMasterDetailsByRefId(
        reservationItem.medicalCheckupMasterRefId,
        coursesData
      )

      const time = reservationItem.reservationTime
        ? getTimeRange(reservationItem.reservationTime)
        : '-'

      const date = reservationItem.reservationDate
        ? formatJapaneseDate(reservationItem.reservationDate)
        : '-'

      const reservationStatus =
        reservationItem?.reservationStatus as RESERVATION_STATUS

      const courseId: string =
        medicalCheckupItem?.medicalCheckupMaster?.refId || ''
      const courseName: string =
        medicalCheckupItem?.medicalCheckupMaster?.displayName || '-'

      const optionsConverted = getOptionsByCourseIds(
        reservationItem.optionRefIds,
        options
      )

      const optionName =
        optionsConverted.reduce((acc, option, index) => {
          if (!option) {
            return acc
          }

          if (index === optionsConverted.length - 1) {
            return acc + option?.medicalCheckupMaster?.displayName
          }

          return acc + option?.medicalCheckupMaster?.displayName + ', '
        }, '') || '-'

      const optionIds = optionsConverted.map(
        (option) => option?.medicalCheckupMaster?.refId ?? ''
      )

      const isDeleted = Boolean(
        medicalCheckupItem?.medicalCheckupMaster?.additionalInfo?.isDeleted
      )

      return {
        id: reservationItem.refId,
        courseId,
        reservationStatus,
        date,
        time,
        courseName: isDeleted ? '-' : courseName,
        optionIds,
        optionName: isDeleted ? '-' : optionName,
        isDeleted
      }
    })

    setReservationHistory(reservationHistory as ReservationHistoryAtom[])
  }

  const getOptionsByCourseIds = (
    optionIds: string[],
    options: CourseResponse[]
  ) => {
    const optionList = options || []
    const ids = optionIds || []

    return ids.map((optionId) => {
      const option = optionList.find(
        (option) => option.medicalCheckupMaster.refId === optionId
      )
      return option
    })
  }

  const getOptionsFromOptionIds = async (optionIds: string[]) => {
    if (!optionIds || optionIds.length <= 0) {
      return []
    }

    const options: CourseResponse[] = await getMedicalCheckupMaster({
      filter: [
        { field: 'data.additionalInfo.key1', operator: 'eq', value: 'option' },
        {
          field: 'data.refId',
          operator: 'in',
          value: optionIds
        }
      ],
      sortBy: {
        field: 'createdDate',
        direction: 'desc'
      }
    })

    return options
  }

  const getMedicalCheckupMasterDetailsByRefId = (
    refId: string,
    filterMedicalCheckupMasterDetails: any[]
  ) => {
    return filterMedicalCheckupMasterDetails.find(
      (item) => item?.medicalCheckupMaster?.refId === refId
    )
  }

  const findAvailableSlots = async ({
    courseSearch,
    month,
    year
  }: {
    courseSearch: string
    month: string
    year: string
  }) => {
    if (!courseSearch) {
      notification.open({
        message: t('dashboard.course_search_required')
      })
      return
    }

    const { firstDay, lastDay } = getFirstAndLastDayOfMonth(
      Number(month),
      Number(year)
    )

    try {
      setTableSearchSlotReservation((prev) => ({
        ...prev,
        slot: {
          ...prev.slot,
          loading: true
        }
      }))

      const { data } = await fetchAvailableSlotsByDateRange({
        variables: {
          medicalCheckupMasterRefId: courseSearch,
          startDate: firstDay,
          endDate: lastDay
        }
      })

      setTableSearchSlotReservation((prev) => ({
        ...prev,
        slot: {
          ...prev.slot,
          list: data.getRemainedAvailableSlotsByDateRange.payload,
          loading: false
        }
      }))
    } catch (error) {}
  }

  return {
    getOptionsFromOptionIds,
    findAvailableSlots,
    reservationHistory,
    setReservationHistory,
    handleSearchCourse,
    convertReservationToTableData,
    handleSearchOption
  }
}

export default usePatientDetail
