import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Modal, Spin, Typography } from 'antd'

import ResultPreviewPdf from './exportPdf/ResultPreviewPdf'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ReactComponent as NextIcon } from 'assets/svgs/NextIcon.svg'
import {
  ComprehensiveJudgment,
  DataTestTable,
  GeneralComment,
  JudgmentCategory,
  TestName
} from 'components/screens/ResultPreview'
import { openNotification } from 'components/widgets/Notification'
import { useTenantList } from 'hooks/tenant/useTenantList'
import { StringKeyObject } from 'types/common'
import {
  downloadCSV,
  getCurrentDateFormatted,
  isObjectEmpty,
  removeLineBreaks
} from 'utilities/helpers'

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  id: string
  listId: string[]
  data: StringKeyObject[]
  customer: any
  setId: any
  language: any
}

const ResultPreviewModal = ({
  isModalOpen = false,
  onCancel,
  id,
  listId,
  data,
  setId,
  customer,
  language
}: Props) => {
  const { t } = useTranslation('')
  const [showIconNext, setShowIconNext] = useState<boolean>(true)
  const [pdfResult, setPdfResult] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataShowCategory, setDataShowCategory] = useState<StringKeyObject[]>(
    []
  )
  const [dataShowMedicalCheckups, setDataShowMedicalCheckups] = useState<any>(
    []
  )
  const { tenant } = useTenantList()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const resultListIds: string[] = []
        const currentIndex = listId.indexOf(id)
        if (currentIndex === -1) {
          setDataShowCategory([])
          setDataShowCategory([])
          return
        }

        const remainingItems = listId.length - currentIndex
        if (remainingItems > 2) {
          setShowIconNext(true)
          resultListIds.push(...listId.slice(currentIndex, currentIndex + 3))
          if (remainingItems === 3) {
            setShowIconNext(false)
          }
        } else {
          setShowIconNext(false)
          resultListIds.push(...listId.slice(currentIndex))
        }

        const dataCategories: StringKeyObject[] = []
        const dataMedicalCheckups = []

        const dataHandle = JSON.parse(JSON.stringify(data))
        for (const resultId of resultListIds) {
          const result = dataHandle.find(
            (item: StringKeyObject) => item.id === resultId
          )
          if (!result) continue

          dataMedicalCheckups.push(result)
          const formatCategory = result?.formatCategory ?? []
          if (dataCategories.length === 0) {
            dataCategories.push(...formatCategory)
            continue
          }

          for (const category of formatCategory) {
            const dataFind = dataCategories.find(
              (item: StringKeyObject) => item.refId === category.refId
            )
            if (!dataFind) {
              dataCategories.push(category)
              continue
            }

            const itemMaster = category.itemMaster
            for (const item of itemMaster) {
              const itemFind = dataFind.itemMaster.find(
                (itemFind: StringKeyObject) => itemFind.refId === item.refId
              )

              if (!itemFind) {
                dataFind.itemMaster.push(item)
              }
            }
          }
        }

        dataCategories.sort(
          (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
        )
        dataCategories.forEach((dataCategory) => {
          dataCategory.itemMaster.sort(
            (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
          )
        })

        while (dataMedicalCheckups.length < 3) {
          dataMedicalCheckups.push({})
        }

        setDataShowCategory(dataCategories)
        setDataShowMedicalCheckups(dataMedicalCheckups)

        setPdfResult({
          customer: customer,
          data: dataMedicalCheckups,
          category: dataCategories
        })
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    if (listId && id && data && data.length > 0) {
      fetchData()
    }
  }, [listId, data, id])

  const handleOk = () => {
    //todo
  }

  const handlePdfDownload = () => {
    const currentDate = new Date()
    const currentTimeString = currentDate.toLocaleString().replace(/\s/g, '')
    let fileNamePDF = `${customer?.patientId}-${currentTimeString}.pdf`
    return fileNamePDF
  }

  const handleExportCsv = () => {
    const customerHeader = ['受診者ID', '氏名', '氏名（カナ）']
    let feedbackHeader = ['健診日時', 'コース名', '総合判定', '総合コメント']

    const { id, firstName, firstNameKana, lastName, lastNameKana } = customer
    const customerBody = [
      id,
      `${firstName}${lastName}`,
      `${firstNameKana ?? ''}${lastNameKana ?? ''}`
    ]

    let body: any[] = []
    dataShowMedicalCheckups.forEach((medicalCheckup: StringKeyObject) => {
      if (isObjectEmpty(medicalCheckup)) return

      const feedbackBody = [
        medicalCheckup.consultationDate,
        medicalCheckup.medicalCheckupMaster?.displayName,
        removeLineBreaks(medicalCheckup.evaluation),
        removeLineBreaks(medicalCheckup.generalComments)
      ]

      body.push(customerBody.concat(feedbackBody))
    })

    const headers = customerHeader.concat(feedbackHeader)

    const csvString = [headers, ...body].map((e) => e.join(',')).join('\n')

    const filename = `マイカルテ- 検査結果 ${getCurrentDateFormatted('yyyyMMdd-hhmmss')}.csv`
    downloadCSV(csvString, filename)

    openNotification({
      type: 'success',
      title: '成功',
      message: `${filename}がダウンロードされた`
    })
  }

  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      className="modal"
      maskClosable={true}
      style={{ padding: 10 }}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={onCancel}
      width={1900}
    >
      {loading && (
        <Flex className="items-center justify-center min-h-[500px]">
          <Spin />
        </Flex>
      )}
      {!loading && (
        <div id="pdf-content">
          <Flex vertical className="pt-1.5 tracking-wider">
            <Flex className="w-full pr-2.5 justify-end">
              <button
                className="w-[23px] h-[21px] text-black border border-gray-600 flex items-center justify-center text-[18px] pb-[4px]"
                onClick={onCancel}
              >
                x
              </button>
            </Flex>
            <Flex className="w-full pl-6 mt-2 pr-2.5">
              <Flex className="w-[50%]">
                <Typography className="font-bold text-base">
                  {customer?.id}　/　{customer?.firstName + customer?.lastName}
                </Typography>
              </Flex>
              <Flex className="w-[50%] justify-end gap-4 text-white">
                <PDFDownloadLink
                  className="min-w-[120px] bg-gradient-to-r from-[#327790] to-[#97C9CC] flex items-center justify-center hover:!text-white rounded"
                  document={
                    pdfResult && (
                      <ResultPreviewPdf pdfResult={pdfResult} tenant={tenant} />
                    )
                  }
                  fileName={handlePdfDownload()}
                >
                  {t('button.export_PDF')}
                </PDFDownloadLink>

                <button
                  onClick={handleExportCsv}
                  className="min-w-[120px] bg-gradient-to-r from-[#327790] to-[#97C9CC] min-h-7 rounded"
                >
                  {t('button.export_CSV')}
                </button>
              </Flex>
            </Flex>
            <Flex className="h-[40px] w-full mt-[10px] bg-gradient-to-r from-[#93D1CA] to-[#127594] text-[#ffffff] text-[16px]">
              <div className="w-[25%] border-left-between"></div>
              {dataShowMedicalCheckups.map(
                (item: StringKeyObject, index: number) => {
                  return (
                    <Flex
                      className="w-[25%] justify-between items-center px-2.5 border-left-between"
                      key={item.refId}
                    >
                      <span>{item.consultationDate?.replace(/-/g, '/')}</span>
                      {showIconNext &&
                        index === dataShowMedicalCheckups.length - 1 && (
                          <NextIcon
                            className="mr-6 cursor-pointer"
                            onClick={() => {
                              const index = listId.indexOf(id)
                              setId(listId[index + 3])
                            }}
                          />
                        )}
                    </Flex>
                  )
                }
              )}
            </Flex>

            <TestName
              dataShowMedicalCheckups={dataShowMedicalCheckups}
              language={language}
            />

            <ComprehensiveJudgment
              dataShowMedicalCheckups={dataShowMedicalCheckups}
            />

            {/* {listSyndromeDeterminationData && (
            <SyndromeDetermination
              listSyndromeDetermination={listSyndromeDeterminationData}
            />
          )}
          {listSpecificHealthData && (
            <SpecificHealth listSpecificHealth={listSpecificHealthData} />
          )} */}

            <GeneralComment dataShowMedicalCheckups={dataShowMedicalCheckups} />

            <JudgmentCategory
              listCategory={dataShowCategory}
              dataShowMedicalCheckups={dataShowMedicalCheckups}
              language={language}
            />

            <DataTestTable
              dataShowCategory={dataShowCategory}
              dataShowMedicalCheckups={dataShowMedicalCheckups}
              language={language}
            />

            {/* <HealthStatusReport />
          <LifeStyleReport /> */}
            {/* {listHealthGuidanceData && (
            <HealthGuidance listHealthGuidance={listHealthGuidanceData} />
          )} */}
          </Flex>
        </div>
      )}
    </Modal>
  )
}

export default ResultPreviewModal
