import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { Flex, Image, Tooltip } from 'antd'

import trashIcon from 'assets/imgs/trash.svg'
import { ReactComponent as EditIcon } from 'assets/svgs/pen.svg'
import { Admin } from 'models/admin'

type AdminOperationProps = {
  admin: Admin
  setDeleteAdmin: (admin: Admin) => void
  isOwner: boolean
}

const AdminOperations: React.FC<AdminOperationProps> = ({
  admin,
  setDeleteAdmin,
  isOwner
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  if (!isOwner) return null
  return (
    <Flex className="gap-6 justify-center">
      <Tooltip placement="top" title={t('button.edit')}>
        <EditIcon
          className="cursor-pointer text-base"
          onClick={() =>
            navigate({
              pathname: '/admin-register',
              search: createSearchParams({
                id: admin.key
              }).toString()
            })
          }
        />
      </Tooltip>

      <Tooltip placement="top" title={t('button.delete')}>
        <Image
          width={16}
          src={trashIcon}
          preview={false}
          className="cursor-pointer"
          onClick={() => setDeleteAdmin(admin)}
        />
      </Tooltip>
    </Flex>
  )
}

export default AdminOperations
