import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemsType } from 'hooks/tab/useTabFilterMessages'
import cn from 'utilities/cn'

interface TabsProps {
  readonly items: ItemsType[]
  readonly onTabChange?: (id: string) => void
}

function Tabs({ items, onTabChange }: TabsProps) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center justify-between">
      {items.map((item) => {
        const Icon = item.isActive ? item.activatedIcon : item.icon

        return (
          <div
            role="presentation"
            key={item.id}
            onClick={() => onTabChange?.(item.id)}
            className={cn(
              'flex items-center justify-center flex-1 py-2 text-[14px] font-bold border-[1px] rounded cursor-pointer transition-all duration-300 ease-in-out',
              {
                'border-[#137695] text-[#137695]': item.isActive,
                'border-[#F0F3F7] text-[#BDC4CE] hover:bg-[#F0F3F7]':
                  !item.isActive,
                'ml-2': item.id !== items[0].id
              }
            )}
          >
            <div className="mr-2">
              <Icon />
            </div>
            {t(`chat.tabs.${item.label}`)}
          </div>
        )
      })}
    </div>
  )
}

export default memo(Tabs)
