import React, { useEffect, useState } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from 'react-virtualized'
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer'
import 'react-virtualized/styles.css'

import {
  Button,
  Checkbox,
  Empty,
  Flex,
  Input,
  Modal,
  Space,
  Typography
} from 'antd'

import { ELanguage, showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'
import { SearchOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'
import cn from 'utilities/cn'
import { getLanguage } from 'utilities/helpers'

const Header = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-col !rounded-[10px]">
      <span className="h-4 w-full !rounded-t-[10px] bg-gradient-to-r from-[#137695] to-[#62aeb2]"></span>
      <Flex className="mt-6" justify="center">
        {title}
      </Flex>
    </div>
  )
}

const cache = new CellMeasurerCache({
  fixedWidth: true, // fixed width
  defaultHeight: 32 // default height
})

const ModalRenderItemList = ({
  listData = [],
  initialItemShow = [],
  initialItemHidden = [],
  open,
  onCancel,
  onOk,
  title,
  titleButton,
  classButton,
  typeButton
}: {
  listData: DataItemMaster[] | []
  initialItemShow: string[]
  initialItemHidden: string[]
  title: string
  titleButton: string
  classButton?: string
  typeButton?: string
  open?: boolean
  onOk?: (data: DataItemMaster[]) => void
  onCancel?: () => void
}) => {
  let language = getLanguage()
  const refData = React.useRef<DataItemMaster[]>([])
  const [data, setData] = useState<DataItemMaster[]>([])
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    if (!listData.length) {
      setData([])
      refData.current = []
      return
    }
    if (listData?.length > 0) {
      const filterList = listData
        .map((item) => ({
          ...item,
          isChecked: initialItemHidden.some((refId) => refId === item.refId)
        }))
        .filter((item) => !initialItemShow.includes(item.refId))
      setData(filterList)
      refData.current = filterList
    }
    // handle filter data
  }, [listData, initialItemShow, initialItemHidden])

  const handleOke = (e: React.MouseEvent) => {
    e.preventDefault()
    const selectedItems = data.filter((item) => item.isChecked)
    onOk && onOk(selectedItems)
    onOk && onCancel && onCancel()
  }

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setIsSearching(!!searchValue)
    if (!searchValue) return setData(refData.current)
    const filteredData = [...refData.current].filter((item) => {
      return (
        item.lang?.[ELanguage.VI]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item?.additionalInfo?.localizedName?.[ELanguage.VI]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item.lang?.[ELanguage.EN]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item?.additionalInfo?.localizedName?.[ELanguage.EN]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item.lang?.[ELanguage.JA]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        item?.additionalInfo?.localizedName?.[ELanguage.JA]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
      )
    })
    setData(filteredData)
  }, 500) // Debounce for 500ms

  const rowRenderer = ({
    key,
    index,
    style,
    parent
  }: {
    key: React.Key
    index: number
    parent: MeasuredCellParent
    style?: React.CSSProperties
  }) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        {({ measure }) => {
          const isChecked = data[index].isChecked

          return (
            <div
              key={key}
              onLoad={measure}
              style={{
                ...style,
                whiteSpace: 'normal',
                backgroundColor: isChecked ? '#EFF3F6' : ''
              }}
            >
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  const checked = e.target.checked
                  setData((prev) =>
                    prev.map((d, i) =>
                      i === index ? { ...d, isChecked: checked } : d
                    )
                  )
                }}
              >
                <Flex>
                  <Typography>{`${showName(data[index], language)} `}</Typography>
                </Flex>
              </Checkbox>
            </div>
          )
        }}
      </CellMeasurer>
    )
  }
  return (
    <Modal
      title={<Header title={title} />}
      closeIcon={null}
      open={open}
      onCancel={onCancel}
      footer={
        data?.length > 0 && (
          <Flex align="center" justify="center">
            {/* hard code */}
            <Button
              htmlType="button"
              type={typeButton ? 'primary' : 'default'}
              onClick={handleOke}
              autoInsertSpace={false}
              className={classButton ? classButton : 'px-16 font-bold'}
            >
              {titleButton}
            </Button>{' '}
          </Flex>
        )
      }
      className="!w-[900px] rounded-lg"
      classNames={{
        content: '!p-0 !rounded-[10px]',
        header: '!rounded-[10px]',
        footer: '!m-0 !px-6 !py-5'
      }}
    >
      {data?.length === 0 && !isSearching ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div className="px-6 py-5">
          <Flex justify="end" align="center">
            <Input onChange={handleSearch} prefix={<SearchOutlined />} />
          </Flex>
          <div
            className={cn({
              'justify-center items-center': data.length === 0
            })}
          >
            <Space direction="vertical" size="middle" className="!w-full">
              {data.length > 0 ? (
                <div className="m-auto">
                  <AutoSizer className="max-h-[630px] min-h-[400px] !w-full border border-[#E8E8E8] p-4">
                    {({ height, width }: { height: number; width: number }) => {
                      return (
                        <List
                          height={height - 32}
                          rowHeight={cache.rowHeight}
                          rowRenderer={rowRenderer}
                          rowCount={data.length}
                          width={width - 15}
                        />
                      )
                    }}
                  </AutoSizer>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Space>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ModalRenderItemList
