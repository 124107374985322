import { memo, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Drawer } from 'antd'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { ReactComponent as CloseIcon } from '../../../../assets/svgs/close.svg'
import ScheduleTable from './ScheduleTable'
import SearchContainer from './SearchContainer'
import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import { RESERVATION_STATUS } from 'configs/constant'
import useDrawerSchedule from 'hooks/useDrawerSchedule'
import {
  initialTableSearchSlotReservation,
  reservationHistoryAtom,
  tableSearchSlotReservationAtom
} from 'hooks/usePatientDetail'
import { CourseSelect } from 'pages/CustomerDetail/types/types'
import reservationApi from 'services/reservationApi'

export interface UserInfo {
  id: string
  firstName: string
  firstNameKana: string
  lastName: string
  lastNameKana: string
  age: number
  sex: string
}

interface DrawerScheduleProps {
  readonly courseSearch: CourseSelect[]
  readonly optionSearch: CourseSelect[]
  readonly userInfo: UserInfo
}

const btnStyles = {
  confirmDelete:
    'bg-error text-white rounded-sm shadow-none hover:!bg-error hover:!text-white',
  deleteAppointment:
    'border-error bg-white text-error rounded-sm shadow-none hover:!bg-white hover:!text-error'
}

function DrawerSchedule({
  courseSearch,
  optionSearch,
  userInfo
}: DrawerScheduleProps) {
  const { setValue } = useFormContext()
  const { t } = useTranslation()
  const { onClose, isOpen } = useDrawerSchedule()

  const [tableSearchSlotReservation, setTableSearchSlotReservation] =
    useRecoilState(tableSearchSlotReservationAtom)
  const setReservationHistory = useSetRecoilState(reservationHistoryAtom)

  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    return () => {
      onClose()
    }
  }, [])

  const handleCloseDrawer = () => {
    onClose()

    if (tableSearchSlotReservation.action !== 'update') return

    setTableSearchSlotReservation((prev) => ({
      ...prev,
      action: 'search',
      course: {
        ...prev.course,
        filterList: [],
        courseId: '',
        courseName: ''
      },
      option: {
        ...prev.option,
        filterList: [],
        optionIds: [],
        optionName: ''
      },
      reservationId: ''
    }))

    setValue('courseSearchDrawer', null)
    setValue('optionSearchDrawer', [])
    setValue('courseSearch', null)
    setValue('optionSearch', [])
  }

  const handleDelete = async () => {
    if (!tableSearchSlotReservation.reservationId) return

    try {
      setIsLoading(true)
      await reservationApi.deleteReservation(
        tableSearchSlotReservation.reservationId
      )

      setReservationHistory((prev) =>
        prev.map((item) => ({
          ...item,
          reservationStatus:
            item.id === tableSearchSlotReservation.reservationId
              ? RESERVATION_STATUS.CANCELLED
              : item.reservationStatus
        }))
      )
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <Drawer
      loading={isLoading}
      closable={false}
      open={isOpen}
      mask={true}
      rootClassName="drawer-schedule"
    >
      <div className="flex items-center justify-between p-2">
        <p className="text-primary text-base font-bold">
          {tableSearchSlotReservation.action === 'update'
            ? t('dashboard.appointment_change')
            : t('dashboard.new_appointment')}
        </p>

        <div className="flex items-center gap-2 ">
          {tableSearchSlotReservation.action === 'update' && (
            <Button
              onClick={() =>
                !isConfirmDelete ? setIsConfirmDelete(true) : handleDelete()
              }
              type="primary"
              className={`${isConfirmDelete ? btnStyles.confirmDelete : btnStyles.deleteAppointment} font-bold`}
            >
              {isConfirmDelete
                ? t('dashboard.button.confirm_delete')
                : t('dashboard.button.delete_appointment')}
            </Button>
          )}
          <CloseIcon onClick={handleCloseDrawer} className="cursor-pointer" />
        </div>
      </div>

      {isConfirmDelete && (
        <div className="flex items-center justify-center">
          <ExclamationCircleFilled className="mr-2 w-[20px] text-error" />
          <p className="text-error text-base">
            {t('dashboard.delete_appointment_confirm')}
          </p>
        </div>
      )}

      <SearchContainer
        courseSearch={courseSearch}
        optionSearch={optionSearch}
        userInfo={userInfo}
      />

      <ScheduleTable />
    </Drawer>
  )
}

export default memo(DrawerSchedule)
