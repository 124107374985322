import { DocumentNode } from 'graphql'

import { gql } from '@apollo/client'

export const FILTER_LIST: DocumentNode = gql`
  query filterScenarioChatTemplateQuery(
    $page: Int!
    $size: Int!
    $sortBy: String
  ) {
    filterScenarioChatTemplate(
      paginationInput: { page: $page, size: $size }
      sortBy: $sortBy
    ) {
      status
      message
      currentPageNumber
      timestamp
      totalElements
      totalPages
      payload {
        refId
        name
        status
        trigger
        selectionType
        createdBy
        categories
        # medicalCheckupMasterRefIds
        visibility {
          tenantCodes
          subTenantCodes
        }
        modifiedDate
        createdDate
      }
    }
  }
`

export const SCENARIO_DETAIL: DocumentNode = gql`
  query GetScenarioChatTemplateQuery($refId: String = "") {
    getScenarioChatTemplate(refId: $refId) {
      message
      status
      timestamp
      payload {
        reservationType
        refId
        name
        status
        trigger
        categories
        selectionType
        flows {
          refId
          order
          step
          selectionType
          content
          setting {
            choiceType
            isMultiple
            refId
          }
          options {
            displayName
            order
            refId
            value
          }
        }
      }
    }
  }
`

export const DELETE_SCENARIO: DocumentNode = gql`
  mutation deleteScenarioChatTemplate($refId: String!) {
    deleteScenarioChatTemplate(refId: $refId) {
      message
      status
      payload {
        refId
      }
    }
  }
`
