import { Text, View } from '@react-pdf/renderer'
import { getTenantAddress } from 'utilities/helpers'

const Signature = ({
  tenant,
  lastMedical,
  style
}: {
  tenant: any
  lastMedical: any
  style: Record<string, string | number>
}) => {
  let tenantAddress = getTenantAddress(tenant)
  return (
    <View style={style}>
      <View style={{ display: 'flex', gap: 4 }}>
        <Text>{tenant?.additionalInfo?.clinicName ?? ''}</Text>
        <Text>{tenantAddress}</Text>
        <View
          style={{
            minHeight: 32,
            width: '10%',
            borderBottom: '2px solid #d9d9d9'
          }}
        ></View>
      </View>
      <View
        style={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <View
          style={{
            width: '49.15%',
            padding: 4,
            borderBottom: '2px solid #d9d9d9'
          }}
        >
          <Text>{lastMedical?.doctorName ?? ''}</Text>
        </View>
      </View>
    </View>
  )
}

export default Signature
