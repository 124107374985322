import { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Empty, Select } from 'antd'

import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import { preventEnterKeyDefault } from 'utilities/helpers'

export type SearchType = 'courseSearch' | 'optionSearch'
export type SearchOption = {
  value: string | number
  label: string
}

function ReservationField({
  label,
  placeholder,
  name,
  selectData,
  handleSearch,
  searchVal,
  onchange
}: {
  label: string
  placeholder: string
  name: SearchType
  selectData: any[]
  handleSearch?: any
  searchVal?: string
  onchange: (name: string, value: string[] | string) => void
}) {
  const { control } = useFormContext()

  return (
    <div className="flex flex-col w-[calc(100vw-1340px)] min-w-[580px]">
      <label className="text-[#545454] text-sm font-bold">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              notFoundContent={
                field.value ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : null
              }
              maxTagCount="responsive"
              {...field}
              className="w-full courseSelect"
              style={{ width: '100%' }}
              placeholder={placeholder}
              options={selectData}
              popupClassName="coursePopupSelect"
              menuItemSelectedIcon={null}
              onInputKeyDown={preventEnterKeyDefault}
              onSearch={handleSearch}
              value={field.value}
              onChange={(value) => {
                field.onChange(value)
                onchange(name, value)
              }}
              prefix={<Search />}
              suffixIcon={null}
              showSearch
              filterOption={false}
            ></Select>
          )
        }}
      />
    </div>
  )
}

export default memo(ReservationField)
