import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Col, Row, Typography } from 'antd'

import { InputLabel } from 'components/elements/InputLabel'
import { SelectSearchCustom } from 'components/elements/Select'
import ModalConfirm from 'components/modals/ModalConfirm'
import { META_DATA_MEDICAL_CHECKUP_MASTER } from 'configs/constant'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'
import { Options, StringKeyObject } from 'types/common'

const ModalCopyOption = ({
  isModalVisible,
  optionRefId,
  handleCancel,
  setDataCopy
}: {
  isModalVisible: boolean
  handleCancel?: () => void
  optionRefId: string | null
  setDataCopy: React.Dispatch<React.SetStateAction<MedicalCheckupMaster | null>>
}) => {
  const { t } = useTranslation()
  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()
  const { watch, setValue } = useFormContext()

  const [data, setData] = React.useState<
    | { displayName: string; overview: string; originVal: MedicalCheckupMaster }
    | undefined
  >(undefined)

  const [listOption, setListOption] = React.useState<Options[]>([])

  const idProduct = watch('medicalCheckupMaster')

  const fetchData = async () => {
    const result: { medicalCheckupMaster: MedicalCheckupMaster }[] =
      await getMedicalCheckupMaster(
        {
          filter: [
            {
              field: 'data.additionalInfo.key1',
              operator: 'eq',
              value: 'option'
            },
            {
              field: 'data.additionalInfo.isDeleted',
              operator: 'ne',
              value: 'true'
            }
          ],
          sortBy: {
            field: 'createdDate',
            direction: 'desc'
          }
        },
        true
      )
    if (result && result.length > 0) {
      setListOption(
        result.map((item) => ({
          key: item.medicalCheckupMaster?.refId ?? '',
          value: item.medicalCheckupMaster?.refId ?? '',
          label: item.medicalCheckupMaster?.displayName ?? '',
          originVal: item.medicalCheckupMaster
        }))
      )
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  React.useEffect(() => {
    if (!idProduct && !optionRefId) return
    const refId = idProduct || optionRefId
    const item = listOption.find((item) => item.key === refId)
    const overview = item?.originVal.additionalInfo.metadata.find(
      (it: StringKeyObject) =>
        it.name === META_DATA_MEDICAL_CHECKUP_MASTER.OVERVIEW
    )
    setData({
      displayName: item?.originVal.displayName,
      overview: overview?.value,
      originVal: item?.originVal
    })
  }, [idProduct, optionRefId, listOption])

  const handleCancelModal = () => {
    setValue('medicalCheckupMaster', undefined)
    handleCancel?.()
  }

  const handleSubmitData = () => {
    handleCancelModal()
    if (!data) return
    setDataCopy?.(data?.originVal)
  }

  return (
    <ModalConfirm
      isModalVisible={isModalVisible}
      title={t('copyExistingProduct')}
      icons={<></>}
      classNames={{
        classNameButtonCancel: '!border-primary !text-primary',
        classNameButtonOk: '!bg-primary '
      }}
      classNameModal="!w-[800px]"
      handleCancel={handleCancelModal}
      handleOk={handleSubmitData}
      content={
        <div className="mt-5 tracking-wider">
          <div>
            <p>{t('content.copyExistingProduct')}</p>
            <p>{t('content.isThatOkay')}</p>
          </div>
          <div className="flex flex-col px-8 pb-6">
            <SelectSearchCustom
              name="medicalCheckupMaster"
              options={listOption}
              className="text-left w-[424px] mt-12"
              placeholder={t('placeholder.findAnOption')}
            />
            <Row className="gap-3 w-full mt-1.5 text-left">
              <Col flex="148px">
                <InputLabel
                  align="center"
                  label={t('tableOption.optionName')}
                  className="!py-0"
                />
              </Col>
              <Col flex="auto" className="flex items-center">
                <Typography>{data?.displayName}</Typography>
              </Col>
            </Row>
            <Row className="gap-3 w-full mt-1.5 text-left">
              <Col flex="148px">
                <InputLabel
                  align="center"
                  label={t('table.overview')}
                  className="!py-0"
                />
              </Col>
              <Col flex="auto" className="flex items-center">
                <Typography>{data?.overview}</Typography>
              </Col>
            </Row>
          </div>
        </div>
      }
      textOk={t('button.create')}
      textCancel={t('button.cancel')}
    />
  )
}

export default ModalCopyOption
