import { useEffect, useState } from 'react'

import { useLocalStorage } from './index'
import { useTenantUpdate } from './tenant/useTenantUpdate'
import { useLazyQuery } from '@apollo/client'
import { isArray } from '@apollo/client/utilities'
import {
  OPERATION_TYPE,
  RESOURCES_TENANT,
  STORAGE_TENANT_KEY
} from 'configs/constant'
import { FILTER_TENANT } from 'graphql/tenant/filter'
import { Tenant } from 'types/Tenant'
import { StringKeyObject } from 'types/common'
import { uuid } from 'utilities/helpers'

const useBasicSetting = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [tenant, setTenant] = useState<Tenant | undefined>(undefined)
  const [assignments, setAssignments] = useState<StringKeyObject[]>([])
  const [departments, setDepartments] = useState<StringKeyObject[]>([])
  const [positions, setPositions] = useState<StringKeyObject[]>([])
  const [loadTenantList] = useLazyQuery(FILTER_TENANT)
  const [load, setLoad] = useState<number>(0)
  const { handleUpdate } = useTenantUpdate()
  const [currentTenant] = useLocalStorage(STORAGE_TENANT_KEY, {})

  useEffect(() => {
    getTenant()
  }, [load])

  const getTenant = async () => {
    setLoading(true)
    try {
      const response = await loadTenantList({
        variables: {
          sortBy: '(desc,_id)',
          page: 0,
          size: 1,
          filter: `(eq,STRING,code,${currentTenant?.tenant})`
        },
        fetchPolicy: 'network-only'
      })
      const dataTenant = response?.data?.filterTenant?.payload?.[0]

      if (dataTenant?.additionalInfo?.assignments) {
        const dataAssignments = JSON.parse(
          dataTenant.additionalInfo.assignments
        )
        const dataSetAssignments = isArray(dataAssignments)
          ? [...dataAssignments]
          : []

        setAssignments(dataSetAssignments)
      }

      if (dataTenant?.additionalInfo?.departments) {
        const dataDepartments = JSON.parse(
          dataTenant.additionalInfo.departments
        )
        const dataSetDepartments = isArray(dataDepartments)
          ? [...dataDepartments]
          : []

        setDepartments(dataSetDepartments)
      }

      if (dataTenant?.additionalInfo?.positions) {
        const dataPositions = JSON.parse(dataTenant.additionalInfo.positions)
        const dataSetPositions = isArray(dataPositions)
          ? [...dataPositions]
          : []

        setPositions(dataSetPositions)
      }

      setTenant(dataTenant)
    } catch (error) {
      setError((error as Error).message)
    }

    setLoading(false)
  }

  const taskProcessingTenantAdditionalInfo = async (
    operation: string,
    resource: string,
    value: string,
    key?: string
  ) => {
    if (!tenant) return
    setLoading(true)
    switch (operation) {
      case OPERATION_TYPE.CREATE: {
        await processingCreationAdditionalInfo(resource, value)
        break
      }
      case OPERATION_TYPE.UPDATE: {
        if (!key) break
        await processingUpdateAdditionalInfo(resource, value, key)
        break
      }
      case OPERATION_TYPE.DELETE: {
        if (!key) break
        await processingDeleteAdditionalInfo(resource, key)
        break
      }
    }

    setLoading(false)
    setLoad((prev) => prev + 1)
  }

  const processingCreationAdditionalInfo = async (
    resource: string,
    value: string
  ) => {
    const dataTenant = JSON.parse(JSON.stringify(tenant))

    switch (resource) {
      case RESOURCES_TENANT.ASSIGNMENTS: {
        const dataAssignments = [...assignments]

        dataAssignments.push({
          key: uuid(false),
          value: value
        })
        dataTenant.additionalInfo.assignments = JSON.stringify(dataAssignments)
        break
      }
      case RESOURCES_TENANT.DEPARTMENTS: {
        const dataDepartments = [...departments]

        dataDepartments.push({
          key: uuid(false),
          value: value
        })
        dataTenant.additionalInfo.departments = JSON.stringify(dataDepartments)
        break
      }
      case RESOURCES_TENANT.POSITIONS: {
        const dataPositions = [...positions]

        dataPositions.push({
          key: uuid(false),
          value: value
        })
        dataTenant.additionalInfo.positions = JSON.stringify(dataPositions)
        break
      }
    }

    await handleUpdate(dataTenant)
  }

  const processingUpdateAdditionalInfo = async (
    resource: string,
    value: string,
    key: string
  ) => {
    const dataTenant = JSON.parse(JSON.stringify(tenant))

    switch (resource) {
      case RESOURCES_TENANT.ASSIGNMENTS: {
        const dataAssignments = [...assignments]
        const index = dataAssignments.findIndex((item) => item.key === key)
        if (index !== -1) {
          dataAssignments[index].value = value
          dataTenant.additionalInfo.assignments =
            JSON.stringify(dataAssignments)
        }
        break
      }
      case RESOURCES_TENANT.DEPARTMENTS: {
        const dataDepartments = [...departments]
        const index = dataDepartments.findIndex((item) => item.key === key)
        if (index !== -1) {
          dataDepartments[index].value = value
          dataTenant.additionalInfo.departments =
            JSON.stringify(dataDepartments)
        }
        break
      }
      case RESOURCES_TENANT.POSITIONS: {
        const dataPositions = [...positions]
        const index = dataPositions.findIndex((item) => item.key === key)
        if (index !== -1) {
          dataPositions[index].value = value
          dataTenant.additionalInfo.positions = JSON.stringify(dataPositions)
        }
        break
      }
    }

    await handleUpdate(dataTenant)
  }

  const processingDeleteAdditionalInfo = async (
    resource: string,
    key: string
  ) => {
    const dataTenant = JSON.parse(JSON.stringify(tenant))

    switch (resource) {
      case RESOURCES_TENANT.ASSIGNMENTS: {
        const dataAssignments = [...assignments]
        const index = dataAssignments.findIndex((item) => item.key === key)
        if (index !== -1) {
          dataAssignments.splice(index, 1)
          dataTenant.additionalInfo.assignments =
            JSON.stringify(dataAssignments)
        }
        break
      }
      case RESOURCES_TENANT.DEPARTMENTS: {
        const dataDepartments = [...departments]
        const index = dataDepartments.findIndex((item) => item.key === key)
        if (index !== -1) {
          dataDepartments.splice(index, 1)
          dataTenant.additionalInfo.departments =
            JSON.stringify(dataDepartments)
        }
        break
      }
      case RESOURCES_TENANT.POSITIONS: {
        const dataPositions = [...positions]
        const index = dataPositions.findIndex((item) => item.key === key)
        if (index !== -1) {
          dataPositions.splice(index, 1)
          dataTenant.additionalInfo.positions = JSON.stringify(dataPositions)
        }
        break
      }
    }

    await handleUpdate(dataTenant)
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    error,
    tenant,
    departments,
    positions,
    assignments,
    setLoad,
    getTenant,
    onClearError,
    taskProcessingTenantAdditionalInfo
  }
}

export default useBasicSetting
