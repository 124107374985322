import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Content from './components/Content'
import Widget from './components/Widget'
import CustomPagination from 'components/elements/CustomPagination'
import { RECORD_COUNT } from 'models/customer'

export enum RESERVATION_MANAGEMENT_TAB {
  RESERVATION_LIST = '1',
  RESERVATION_COLLECTION_LIST = '2'
}

export default function ReservationManagement() {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const { page: pageParam = 1, size: sizeParam = RECORD_COUNT.OneHundred } =
    Object.fromEntries(queryParams)

  const [totalRecords, setTotalRecords] = useState<number>(0)

  const handleChangePage = (page: number) => {
    queryParams.set('page', page.toString())
    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <div className="w-full min-w-[1900px]">
      <Widget />

      <Content setTotalRecords={setTotalRecords} />

      {!!totalRecords && (
        <CustomPagination
          page={+pageParam}
          size={+sizeParam}
          totalRecords={totalRecords}
          setPage={handleChangePage}
        />
      )}
    </div>
  )
}
