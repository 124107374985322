import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex, Typography } from 'antd'

import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'

type Props = {
  title: string
  sortKey: string
}

const SortableColumnTitle: FC<Props> = ({ title, sortKey }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const { orderBy: orderParam = 'desc' } = Object.fromEntries(queryParams)

  const handleSort = (sort: string) => {
    queryParams.set('sortBy', sort.toString())
    queryParams.set('orderBy', orderParam === 'desc' ? 'asc' : 'desc')

    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <Flex justify="center" align="center">
      <Typography className="mr-4 font-normal">{t(title)}</Typography>
      <ArrowTopBottom
        className="cursor-pointer"
        onClick={() => handleSort(sortKey)}
      />
    </Flex>
  )
}

export default SortableColumnTitle
