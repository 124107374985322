import { memo, useMemo } from 'react'

import { Empty } from 'antd'
import { useRecoilValue } from 'recoil'

import MessageList from './MessageList'
import { SpinLoading } from 'components/screens/Spin'
import { loadMoreMessagesAtom } from 'hooks/chat/atoms'
import { LoadMoreMessages } from 'pages/Chat/types/types'
import { groupMessagesByDate } from 'utilities/helpers'

export interface ChatMessageType {
  id: string
  message: string
  isSent?: boolean
  timestamp: string
  sender?: string
  attachments?: any[]
  typeMessage: string | 'image' | 'file'
  isRead?: boolean
  imgId?: string
  uId?: string
}

interface ChatContainerProps {
  readonly messages: ChatMessageType[]
  readonly typingMessage?: {
    userName: string
    isTyping: boolean
  }
  readonly isDivider?: boolean
}

function ChatContainer({
  messages = [],
  typingMessage,
  isDivider = true
}: ChatContainerProps) {
  const loadMoreMessagesState = useRecoilValue(loadMoreMessagesAtom)
  const groupedMessages = useMemo(
    () => groupMessagesByDate(messages),
    [messages]
  )

  // Render messages
  const renderMessages = () => {
    // If there are no messages, show empty state
    if (Object.keys(groupedMessages).length === 0) {
      return (
        <div className="flex justify-center items-center h-full">
          <Empty />
        </div>
      )
    }

    return (
      <div className="h-full">
        <MessageList
          groupedMessages={groupedMessages}
          typingMessage={typingMessage}
          isDivider={isDivider}
        />
      </div>
    )
  }

  return (
    <div className="w-full py-2 pl-4 h-[calc(100%-50px)] flex flex-col justify-between">
      {loadMoreMessagesState?.isLoading
        ? renderLoadingSpinner(loadMoreMessagesState, false)
        : renderMessages()}
    </div>
  )
}

export default memo(ChatContainer)

export const renderLoadingSpinner = (
  loadMoreMessagesState: LoadMoreMessages,
  isLoadingMore: boolean
) => (
  <div
    className={`flex justify-center items-center ${isLoadingMore ? 'h-10' : 'h-full'}`}
  >
    <SpinLoading
      loading={
        isLoadingMore
          ? (loadMoreMessagesState?.isLoadingLoadMore ?? false)
          : (loadMoreMessagesState?.isLoading ?? false)
      }
    />
  </div>
)
