import { DocumentNode, gql } from '@apollo/client'

export const GET_REMAINED_SLOTS_BY_DATE: DocumentNode = gql`
  query GetRemainedSlots($dates: [DateInput!]!) {
    getRemainedSlots(dates: $dates) {
      payload {
        medicalCheckupMasterRefId
        remainedSlotCount
        reservationDate
        reservationTime
      }
    }
  }
`
