import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Button, Input, List, Typography } from 'antd'

import DuplicationConfirm from './modals/DuplicationConfirm'
import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import {
  useGetMedicalCheckupMasterByName,
  useGetMedicalCheckupMasterDetails
} from 'hooks/useMedicalCheckupMaster'
import useProductSetting from 'hooks/useProductSetting'
import debounce from 'lodash.debounce'

type CopySource = {
  source: string
  destination: string
}

type option = { value: string; label: string }

type Props = {}

const Content: FC<Props> = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { getMedicalCheckupMastersByName } = useGetMedicalCheckupMasterByName()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { onCopyCourse } = useProductSetting()

  const [duplicationData, setDuplicationData] = useState<CopySource>()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchData, setSearchData] = useState<option[]>([])
  const [selectedItem, setSelectedItem] = useState<option>()
  const [loading, setLoading] = useState<boolean>(false)
  const [courseName, setCourseName] = useState<string>('')

  const { destinationSource, tabParam, startDate, endDate } = state || {}
  const isDisabled = !(selectedItem && courseName)

  useEffect(() => {
    const fetchData = async () => {
      if (destinationSource) {
        const fetchData = await getMedicalCheckupMastersById(destinationSource)

        const courseName = fetchData?.medicalCheckupMaster?.displayName
        setCourseName(courseName || '')
      }
    }

    fetchData().finally()
  }, [])

  useEffect(() => {
    setSelectedItem(undefined)
  }, [searchData])

  const handleSearch = useCallback(
    debounce(async (value: string) => {
      if (!value) {
        setSelectedItem(undefined)
        setSearchData([])
        setLoading(false)
        return
      }

      setLoading(true)
      try {
        const fetchData = await getMedicalCheckupMastersByName(value)
        const handledData = fetchData
          .filter(
            (item: any) => item.medicalCheckupMaster.refId !== destinationSource
          )
          .map((item: any) => ({
            value: item.medicalCheckupMaster.refId,
            label: item.medicalCheckupMaster.displayName
          }))
        setSearchData(handledData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }, 500),
    []
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchText(value)
    handleSearch(value)
  }

  const handleCreateDuplicationData = () => {
    if (destinationSource && selectedItem) {
      setDuplicationData({
        source: selectedItem.label,
        destination: courseName
      })
      setIsOpenModal(true)
    }
  }

  const handleDuplicationCourse = async () => {
    if (!destinationSource || !tabParam || !selectedItem) return

    const handleNavigate = (params: Record<string, string>) => {
      navigate({
        pathname: '/product-setting',
        search: createSearchParams(params).toString()
      })
    }

    try {
      await onCopyCourse({
        source: selectedItem.value,
        destination: destinationSource
      })
      handleNavigate({
        courseId: destinationSource,
        tab: tabParam,
        ...(startDate && endDate ? { startDate, endDate } : {})
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="min-h-[800px] overflow-y-scroll rounded-[5px] bg-[#FFFFFF] pb-[80px] pl-[32px] pt-4 text-[14px] text-[#545454] drop-shadow-md">
      <Typography className="mb-5">{t('productDuplication.notice')}</Typography>

      <Input
        value={searchText}
        onChange={handleInputChange}
        className="h-[30px] w-[424px]"
        placeholder={t('productManagement.widget.placeholder.search')}
        prefix={<Search className="h-4 w-4" />}
      />

      <List
        className="mt-5 h-[600px] overflow-y-scroll pr-16"
        dataSource={searchData}
        loading={loading}
        renderItem={(item, index) => {
          return (
            <div
              key={index}
              className={`mt-2 w-full cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap border-[1px] border-[#137695] px-3 py-1 hover:border-[#EBF2F5] hover:bg-[#EBF2F5] ${selectedItem?.value === item.value ? 'bg-[#EBF2F5] border-none' : ''}`}
              onClick={() => setSelectedItem(item)}
            >
              {item.label}
            </div>
          )
        }}
      />

      <Button
        disabled={isDisabled}
        style={{
          background: isDisabled ? '#BFC6CB' : '',
          color: isDisabled ? 'white' : ''
        }}
        type="primary"
        htmlType="submit"
        autoInsertSpace={false}
        className="absolute bottom-4 right-0 h-[30px] min-w-[120px] rounded-none border-none bg-[#137695] text-center text-[14px] font-bold text-white shadow-none"
        onClick={handleCreateDuplicationData}
      >
        {t('productDuplication.button.copy')}
      </Button>

      <DuplicationConfirm
        isOpen={isOpenModal}
        onSubmit={handleDuplicationCourse}
        onCancel={() => setIsOpenModal(false)}
        initialData={duplicationData}
      />
    </div>
  )
}

export default Content
