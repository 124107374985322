import React from 'react'

import { PlusOutlined } from '@ant-design/icons'

type PlusActiveProps = {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

function PlusActive({ onClick }: PlusActiveProps) {
  return (
    <div
      className="flex justify-center items-center w-full h-full bg-primary rounded-full border-none outline-none cursor-pointer"
      onClick={onClick}
    >
      <PlusOutlined className="text-xs text-white" />
    </div>
  )
}

export default PlusActive
