import React, {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Flex, InputNumber, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'
import { ByWeekday, RRule } from 'rrule'

import useTimeChecker from '../../../hooks/useTimeChecker'
import { TAB, cleanData } from '../ProductSetting'
import styles from './scss/TabbleWrapper.module.scss'
import { useGetMedicalCheckupMasterByName } from 'hooks/useMedicalCheckupMaster'
import useProductSetting from 'hooks/useProductSetting'
import useTimeConfig, {
  SpecialConfig,
  initialSpecialConfig
} from 'hooks/useTimeConfig'
import { AvailableSlotsByWeek } from 'models/productManagement'

const dayToByWeekdayMap: Record<string, ByWeekday> = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU
}

type Props = {
  submissionCount: number
  setIsChange: (isChange: boolean) => void
}

const ReservationWeek: FC<Props> = memo(({ submissionCount, setIsChange }) => {
  const { t, i18n } = useTranslation()
  const { search } = useLocation()
  const { getValues, setValue } = useFormContext()

  const { checkTimeWeek } = useTimeChecker()

  const { getMedicalCheckupMastersByIds } = useGetMedicalCheckupMasterByName()
  const { onFilterAvailableSlotByWeek } = useProductSetting()
  const { onCreateTimeConfig } = useTimeConfig()

  const queryParams = new URLSearchParams(search)
  const { courseId: courseParam, tab: tabParam = TAB.RESERVATION_WEEK } =
    Object.fromEntries(queryParams)

  const [loading, setLoading] = useState(false)
  const [initialData, setInitialData] = useState<
    AvailableSlotsByWeek[] | undefined
  >()
  const [config, setConfig] = useState<SpecialConfig>(initialSpecialConfig)
  const [daysOfWeek, setDaysOfWeek] = useState<
    { label: string; key: string; value: number }[]
  >([])

  useEffect(() => {
    dayjs.locale(i18n.language)
    setDaysOfWeek([
      { label: dayjs().day(1).format('ddd'), key: 'MO', value: 1 },
      { label: dayjs().day(2).format('ddd'), key: 'TU', value: 2 },
      { label: dayjs().day(3).format('ddd'), key: 'WE', value: 3 },
      { label: dayjs().day(4).format('ddd'), key: 'TH', value: 4 },
      { label: dayjs().day(5).format('ddd'), key: 'FR', value: 5 },
      { label: dayjs().day(6).format('ddd'), key: 'SA', value: 6 },
      { label: dayjs().day(0).format('ddd'), key: 'SU', value: 0 }
    ])
  }, [i18n.language])

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const data = await getMedicalCheckupMastersByIds([courseParam])
      if (data?.length > 0) {
        const config = await onCreateTimeConfig()
        const result = await onFilterAvailableSlotByWeek(courseParam)
        setConfig(config)
        setInitialData(result?.data || [])
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }, [getMedicalCheckupMastersByIds, onFilterAvailableSlotByWeek])

  useEffect(() => {
    // setInitialData(undefined)
    if (tabParam === TAB.RESERVATION_WEEK && courseParam) {
      fetchData().finally()
    }
  }, [courseParam, submissionCount])

  const createRRuleData = (day: string, time: string, value: number) => {
    const [hours, minutes] = time.split(':').map(Number)
    const byweekday = dayToByWeekdayMap[day.toUpperCase()]

    return {
      medicalCheckupMasterRefId: courseParam,
      emptySlotCount: value,
      datetimeRule: new RRule({
        freq: RRule.WEEKLY,
        // count: 30,
        byweekday: byweekday,
        byhour: [hours],
        byminute: [minutes],
        bysecond: [0]
      }).toString()
    }
  }

  const handleCreateRRuleData = (
    day: string,
    time: string,
    value: number | undefined,
    refId?: string,
    rule?: string
  ) => {
    const data = !value
      ? refId && rule
        ? {
            medicalCheckupMasterRefId: courseParam,
            medicalCheckupEmptySlotRefId: refId,
            emptySlotCount: 0,
            datetimeRule: rule
          }
        : undefined
      : refId && rule
        ? {
            medicalCheckupMasterRefId: courseParam,
            medicalCheckupEmptySlotRefId: refId,
            emptySlotCount: value,
            datetimeRule: rule
          }
        : createRRuleData(day, time, value)

    setValue(`rrule.week.${day}-${time}`, data)

    if (Object.keys(cleanData(getValues('rrule.week'))).length === 0) {
      setIsChange(false)
    } else {
      setIsChange(true)
    }
  }

  const initialDataMap = useMemo(
    () =>
      initialData?.reduce(
        (acc, item) => {
          acc[`week.${item.day}-${item.time}`] = item
          return acc
        },
        {} as Record<string, AvailableSlotsByWeek>
      ),
    [initialData]
  )

  const daysKeyValue = useMemo(
    () =>
      daysOfWeek.reduce(
        (acc, day) => {
          acc[day.key] = day.value
          return acc
        },
        {} as { [key: string]: number }
      ),
    []
  )

  const renderCell = (day: string, time: string) => {
    const key = `week.${day}-${time}`
    const foundItem = initialDataMap ? initialDataMap[key] : undefined
    const initialVal = getValues(key) ?? foundItem?.emptySlot ?? undefined

    if (getValues(key) !== initialVal) {
      setValue(key, initialVal)
    }

    const isDisabled = checkTimeWeek(daysKeyValue[day], time, config)

    const handleChange = (value: number) => {
      setValue(key, value)
      handleCreateRRuleData(day, time, value, foundItem?.refId, foundItem?.rule)
    }

    return (
      <Flex justify="center">
        <InputNumber
          key={`${courseParam}.${tabParam}.${day}-${time}`}
          disabled={isDisabled}
          className="h-[30px] w-[80px]"
          defaultValue={initialVal}
          maxLength={3}
          min={0}
          max={100}
          onChange={(value) => handleChange(value)}
          controls={false}
        />
      </Flex>
    )
  }

  const dataSource = useMemo(
    () =>
      config?.time.map((time) => {
        const row: { key: string; time: string; [key: string]: ReactNode } = {
          key: time,
          time
        }
        daysOfWeek.forEach(({ key: day }) => (row[day] = renderCell(day, time)))
        return row
      }),
    [initialData]
  )

  const columns: TableColumnsType<{
    time: string
    [key: string]: ReactNode
  }> = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'time',
        key: 'time',
        width: '4%',
        render: (value) => (
          <Typography className="text-center">{value}</Typography>
        )
      },
      ...daysOfWeek.map((item) => ({
        title: (
          <div
            className={`${item.key === 'SU' ? 'bg-[#DF247530]' : item.key === 'SA' ? 'bg-[#CEECFE]' : 'bg-[#EFF3F6]'} mx-[1px] py-3 text-center text-base`}
          >
            <Typography
              className={`${item.key === 'SU' ? 'text-[#DF2475]' : item.key === 'SA' ? 'text-[#3089BF]' : 'text-[#545454]'}`}
            >
              {item.label}
            </Typography>
          </div>
        ),
        dataIndex: item.key,
        width: '9%'
      })),
      { title: '', render: () => null }
    ],
    [t, initialData, daysOfWeek]
  )

  return initialData ? (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className={`${styles.table_wrapper} mt-[-10px] py-4 pt-0`}
    />
  ) : (
    <Typography className="ml-8 pb-14 pt-10 text-[#C4C6CE]">
      {t('productSetting.content.notice')}
    </Typography>
  )
})

export default ReservationWeek
