import { openNotification } from '../components/widgets/Notification'
import {
  ASSIGNED_GROUP_LIST,
  GROUP_DETAIL,
  KEYCLOAK_URL,
  ROLE_DETAIL,
  ROLE_LIST,
  ROLE_MAPPING,
  ROLE_UPDATE
} from '../configs/api'
import { getAccessTokenClient } from '../utilities/helpers'
import networkAdapter from '../utilities/keycloakAdapter'

export const roleMapping = async (userId: string) => {
  const url = `${ROLE_MAPPING.replace('USER_ID', userId)}`
  return await networkAdapter.get(url)
}

export const groupsList = async (userId: string, params: any = {}) => {
  const url = `${ASSIGNED_GROUP_LIST.replace('USER_ID', userId)}`

  return await networkAdapter
    .get(url, params)
    .then((response: any) => response)
    .catch((error: any) => {
      console.error(error)
      return []
    })
    .finally(() => {
      return []
    })
}

export const subgroupCreate = async (groupId: string, data: any) => {
  const url = `${GROUP_DETAIL}/${groupId}/children`
  return await networkAdapter.post(url, JSON.stringify(data))
}

export const groupDetail = async (groupId: string) => {
  const url = `${GROUP_DETAIL}/${groupId}`
  return await networkAdapter.get(url)
}

export const groupUpdate = async (groupId: string, data: any) => {
  const url = `${GROUP_DETAIL}/${groupId}`
  return await networkAdapter.put(url, data)
}

export const groupDelete = async (groupId: string) => {
  const url = `${GROUP_DETAIL}/${groupId}`
  return await networkAdapter.delete(url)
}

export const roleList = async (params: any = {}) => {
  const url = `${ROLE_LIST}`

  return await networkAdapter
    .getAsync(url, params)
    .then((response: any) => response)
    .catch((error: any) => {
      console.error(error)
      return []
    })
    .finally(() => {
      return []
    })
}

export const roleDetail = async (roleId: string) => {
  const url = `${ROLE_DETAIL}/${roleId}`
  return await networkAdapter.get(url)
}

export const roleCreate = async (name: string, attributes: object) => {
  const url = `${ROLE_LIST}`
  return await networkAdapter.post(url, { name, attributes })
}

export const roleUpdate = async (roleName: string, data: any) => {
  const url = `${KEYCLOAK_URL}/${ROLE_UPDATE}/${roleName}`

  const token = getAccessTokenClient()

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + token)

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data),
    redirect: 'follow'
  }

  fetch(url, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error))
}

export const roleUpdateBatch = async (roles: any[]) => {
  let url: string = `${KEYCLOAK_URL}/${ROLE_UPDATE}`

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + getAccessTokenClient())

  let requestOptions: RequestInit = {
    method: 'PUT',
    headers: headers,
    redirect: 'manual'
  }

  let promises = []
  for (const role of roles) {
    const { name: roleName } = role
    requestOptions.body = JSON.stringify(role)
    promises.push(fetch(`${url}/${roleName}`, requestOptions))
  }

  Promise.all(promises)
    .then((responses) => {
      responses.forEach((response) => {
        if (!response.ok) {
          openNotification({
            type: 'error',
            title: 'commonError',
            message: ''
          })
          throw new Error(`Failed to update role`)
        }
      })
    })
    .catch((error) => console.error(error))
}
