import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_MEDICAL_CHECKUP_MASTER: DocumentNode = gql`
  mutation updateMedicalCheckupMaster($request: [UpdateMedicalCheckupMaster]!) {
    updateMedicalCheckupMaster(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        shortName
        displayName
        associatedTestItemMasters
        associatedPatientInfoMasters
        additionalInfo
        visibility {
          tenantCodes
          subTenantCodes
        }
        createdDate
        createdBy
      }
    }
  }
`
