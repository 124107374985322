import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Typography } from 'antd'

import Content from './components/Content'
import CourseInformation from './components/CourseInformation'
import Widget from './components/Widget'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useProductManagement from 'hooks/useProductManagement'
import useTimeConfig, {
  SpecialConfig,
  initialSpecialConfig
} from 'hooks/useTimeConfig'
import { DateRange, RemainedSlotByDate } from 'models/productManagement'
import { getWeekRange } from 'utilities/helpers'

export type CourseSetting = {
  sex: 'MALE' | 'FEMALE' | 'NONE'
  startAge: number | undefined
  endAge: number | undefined
}

export default function ProductManagement() {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { loading, onFilterRemainedSlotsByDate } = useProductManagement()
  const { onCreateTimeConfig } = useTimeConfig()

  const queryParams = new URLSearchParams(search)
  const {
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam
  } = Object.fromEntries(queryParams)

  const [remainedSlotData, setRemainedSlotData] =
    useState<RemainedSlotByDate[]>()
  const [config, setConfig] = useState<SpecialConfig>(initialSpecialConfig)
  const [courseName, setCourseName] = useState<string>('')
  const [courseSetting, setCourseSettings] = useState<CourseSetting>({
    sex: 'NONE',
    startAge: undefined,
    endAge: undefined
  })
  const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
    startDateParam && endDateParam
      ? {
          startDate: startDateParam,
          endDate: endDateParam
        }
      : getWeekRange()
  )

  const fetchData = useCallback(
    async (param: string) => {
      try {
        const medicalCheckupMaster = await getMedicalCheckupMastersById(param)
        const medicalCheckupMasterName: string | undefined =
          medicalCheckupMaster?.medicalCheckupMaster?.displayName

        if (medicalCheckupMasterName) {
          const config = await onCreateTimeConfig()
          const remainedSlotsData = await onFilterRemainedSlotsByDate({
            medicalCheckupMasterRefId: param,
            daterange: currentDateRange
          })
          setRemainedSlotData(remainedSlotsData || [])
          setConfig(config)
          setCourseName(medicalCheckupMasterName)

          const metadata: any[] =
            medicalCheckupMaster?.medicalCheckupMaster?.additionalInfo.metadata

          if (Array.isArray(metadata) && metadata.length > 0) {
            const courseSetting: CourseSetting = metadata.reduce(
              (acc, item) => {
                if (
                  item.name === 'sex' &&
                  ['MALE', 'FEMALE', 'NONE'].includes(item.value)
                ) {
                  acc.sex = item.value as CourseSetting['sex']
                }

                if (
                  item.name === 'startAge' &&
                  typeof item.value === 'number'
                ) {
                  acc.startAge = item.value
                }

                if (item.name === 'endAge' && typeof item.value === 'number') {
                  acc.endAge = item.value
                }

                return acc
              },
              {
                sex: 'NONE',
                startAge: undefined,
                endAge: undefined
              } as CourseSetting
            )

            setCourseSettings(courseSetting)
          }
        } else {
          setRemainedSlotData(undefined)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [onFilterRemainedSlotsByDate]
  )

  useEffect(() => {
    if (courseParam) {
      fetchData(courseParam).finally()
    } else {
      setRemainedSlotData(undefined)
      setCourseName('')
    }
  }, [courseParam, currentDateRange])

  useEffect(() => {
    if (startDateParam && endDateParam) {
      setCurrentDateRange({
        startDate: startDateParam,
        endDate: endDateParam
      })
    }

    if (!startDateParam || !endDateParam) {
      setCurrentDateRange(getWeekRange())
    }
  }, [startDateParam, endDateParam])

  return (
    <div className="w-full min-w-[1500px]">
      <Widget />

      {remainedSlotData ? (
        <>
          <CourseInformation courseName={courseName} />

          <Content
            loading={loading}
            courseParam={courseParam}
            courseName={courseName}
            initialData={remainedSlotData}
            dateRange={currentDateRange}
            setCurrentDateRange={setCurrentDateRange}
            config={config}
            courseSetting={courseSetting}
          />
        </>
      ) : (
        <Typography className="mt-12 text-sm text-[#545454]">
          {t('productManagement.notice')}
        </Typography>
      )}
    </div>
  )
}
