import { useTranslation } from 'react-i18next'

import {
  SelectMultipleCustom,
  SelectSearchCustom
} from 'components/elements/Select'
import { Options } from 'types/common'

function SearchCourse({ courseOptions }: { courseOptions?: Options[] }) {
  const { t } = useTranslation()

  return (
    <div className="mt-2 ">
      <p className="text-default text-sm">
        {t('scenarioCreate.drawer.searchCourse.title')}
      </p>

      <div className="w-full mt-2">
        <SelectMultipleCustom
          name="courses"
          placeholder={t('scenarioCreate.drawer.searchCourse.placeholder')}
          options={courseOptions || []}
          className="w-full"
        />
      </div>
    </div>
  )
}

export default SearchCourse
