import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex, List, Typography } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import { InputForm } from 'components/elements/InputForm'
import { LIST_DRAG_AND_DROP, VALUE_WITH_NO_DATA } from 'configs/constant'
import { showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'
import ItemList from './ItemList'

const CategoryItem = ({
  category,
  showUpdate,
  language,
  showInspectionDescription,
  index,
  onChecked
}: {
  category: DataItemMaster
  showUpdate: boolean
  language: string
  showInspectionDescription: boolean
  index: number
  onChecked: (
    data: DataItemMaster,
    checked: boolean,
    isCheckAll?: boolean
  ) => void
}) => {
  const { t } = useTranslation()
  return (
    <Draggable draggableId={category.refId} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <div className="mt-5">
            <List.Item
              className="border border-[#CDD6DD] bg-[#EFF3F6] min-h-[30px] !p-0"
              {...provided.dragHandleProps}
            >
              <Flex className="w-full gap-3 items-center">
                <Flex
                  className={`flex-1 gap-3 ${showUpdate ? 'gap-1.5 pl-[9px] pr-[3px]' : ''}`}
                >
                  <Checkbox
                    checked={category.isChecked}
                    onChange={(e) =>
                      onChecked(category, e.target.checked, true)
                    }
                    className="ps-1"
                  ></Checkbox>
                  {!showUpdate && (
                    <Typography className="w-1/2">
                      {showName(category, language) ?? VALUE_WITH_NO_DATA}
                    </Typography>
                  )}
                  {!showUpdate && showInspectionDescription && (
                    <Typography className="w-1/2">
                      {showName(category, 'en') ?? VALUE_WITH_NO_DATA}
                    </Typography>
                  )}
                  {showUpdate && (
                    <InputForm
                      name={`category_${category.refId}_${language}`}
                      className="h-[22px] p-1"
                    />
                  )}
                  {showUpdate && showInspectionDescription && (
                    <InputForm
                      name={`category_${category.refId}_en2`}
                      className="h-[22px] p-1"
                      placeholder={t(
                        'questionnaire.placeholder.englishDisplay'
                      )}
                    />
                  )}
                </Flex>

                <Flex className="gap-[3px] items-center mr-[14px]">
                  <MenuOutlined />
                </Flex>
              </Flex>
            </List.Item>
            <Droppable
              droppableId={`${LIST_DRAG_AND_DROP.LIST2}${LIST_DRAG_AND_DROP.SEPARATOR}${category.refId}`}
              type={LIST_DRAG_AND_DROP.ITEM}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`${category?.items && category.items?.length < 1 ? 'pb-[30px]' : ''}`}
                >
                  {category?.items ? (
                    category?.items?.map((data, index) => (
                      <ItemList
                        key={data.refId}
                        data={data}
                        showUpdate={showUpdate}
                        language={language}
                        showInspectionDescription={showInspectionDescription}
                        index={index}
                        onChecked={onChecked}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default CategoryItem
