import React, { FC, memo, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import SearchMultipleCourseDropdown from '../../ReservationManagement/components/SearchMultipleCourseDropdown'
import CustomDateRangePicker from 'components/elements/CustomDateRangePicker'

type Props = {
  onExportToCSV: () => void
}

const Widget: FC<Props> = memo(({ onExportToCSV }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const {
    month: monthParam = dayjs().month() + 1,
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam
  } = Object.fromEntries(queryParams)

  const defaultValues = {
    courseId: courseParam ? courseParam.split(',') : undefined
  }

  const methods = useForm<{ search: string; courseId: string[] }>({
    defaultValues
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [courseParam])

  const selectedDateRangeRef = useRef<[string, string]>()

  const updateDateRange = (dates: [string, string] | undefined) => {
    if (dates) {
      selectedDateRangeRef.current = [
        dates[0].split('T')[0],
        dates[1].split('T')[0]
      ]
    } else {
      selectedDateRangeRef.current = undefined
    }
  }

  const handleSubmit = () => {
    if (Object.entries(methods.getValues()).length === 0) return

    const { search, courseId } = methods.getValues()
    search ? queryParams.set('search', search) : queryParams.delete('search')
    courseId && courseId.length > 0
      ? queryParams.set('courseId', courseId.toString())
      : queryParams.delete('courseId')

    if (selectedDateRangeRef.current) {
      queryParams.set('startDate', selectedDateRangeRef.current[0])
      queryParams.set('endDate', selectedDateRangeRef.current[1])
    } else {
      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    queryParams.set('page', '1')
    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <FormProvider {...methods}>
      <form
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
          }
        }}
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <Flex align="center" justify="space-between">
          <Flex align="center" className="w-full">
            <Typography className="mr-10 text-base font-bold tracking-[1.6px]">
              {t('consultationReport.consultationReport')}
            </Typography>

            <SearchMultipleCourseDropdown
              name={'courseId'}
              placeholder={t('placeholder.freeWordSearch')}
            />

            <CustomDateRangePicker
              placeholder={t('consultationReport.widget.selectDate')}
              placeholder2={t('consultationReport.widget.choice')}
              initialStartDate={startDateParam || undefined}
              initialEndDate={endDateParam || undefined}
              setDates={updateDateRange}
              isDisabledMonth={true}
              selectedMonth={+monthParam - 1}
            />

            <Button
              type="primary"
              htmlType="submit"
              autoInsertSpace={false}
              className="ml-2 h-[30px] min-w-[84px] rounded-none border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
            >
              {t('consultationReport.button.search')}
            </Button>
          </Flex>

          <Button
            type="primary"
            className="h-[30px] min-w-[90px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-center text-sm font-bold text-white shadow-none"
            onClick={onExportToCSV}
          >
            {t('consultationReport.button.exportCSV')}
          </Button>
        </Flex>
      </form>
    </FormProvider>
  )
})

export default Widget
