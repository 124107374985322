import { gql } from '@apollo/client'

export const FILTER_RESERVATION_STATUS_BY_USER = gql`
  query MyQuery($filter: String!) {
    filterReservationStatusByUsers(filter: $filter) {
      status
      message
      payload {
        checkupUserRefId
        reservationStatus
        modifiedDate
      }
    }
  }
`
