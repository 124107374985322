import { ReactNode, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, Form, Input, Tabs, Typography } from 'antd'

import { useUserInfo } from '../../hooks/role-permission/usePermissions'
import AdministratorList from './components/AdministratorList'
import RolePermissionTab from './components/RolePermissionTab'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'
import { SelectMultipleCustom } from 'components/elements/Select'
import { format } from 'date-fns'
import useAdmins from 'hooks/useAdmins'
import { downloadCSV } from 'utilities/helpers'

const { Title, Paragraph } = Typography

export type InputFormType = {
  nameOrEmailAddress: string
  departments: string[]
  assignments: string[]
  positions: any[]
  sortBy?: string
  orderBy?: string
}

const ROLE_PERMISSION_TAB = '2'
export default function AdminList() {
  const { t } = useTranslation()
  const [tab, setTab] = useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const [listRowSelected, setListRowSelected] = useState<any>([])
  const {
    departmentOptions,
    assignmentOptions,
    positionOptions,
    loading,
    error,
    dataShow: admins,
    handleSearchData,
    handleDeleteAdmin,
    setFirstCall
  } = useAdmins()
  const { isOwner } = useUserInfo()

  const navigate = useNavigate()

  const methods = useForm<InputFormType>({
    defaultValues: {
      nameOrEmailAddress: '',
      departments: [],
      assignments: [],
      positions: [],
      sortBy: '',
      orderBy: ''
    }
  })

  const { control, getValues } = methods

  const titleTab: { [key: string]: ReactNode } = {
    '1': (
      <Flex className="items-center">
        {t('content.permissionSettings1')}
        <CrownIcon className="inline-block w-[17px]" />
        {t('content.permissionSettings2')}
      </Flex>
    ),
    '2': t('content.editPermissionSettings')
  }

  const onSearch = async () => {
    let nameOrEmailAddress = getValues('nameOrEmailAddress')
    let departments = getValues('departments')
    let assignments = getValues('assignments')
    let positions = getValues('positions')
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')

    const newParams = new URLSearchParams(searchParams)

    if (nameOrEmailAddress) {
      newParams.set('nameOrEmailAddress', nameOrEmailAddress)
    } else {
      newParams.delete('nameOrEmailAddress')
    }

    if (departments && departments.length > 0) {
      newParams.set('departments', departments.join(','))
    } else {
      newParams.delete('departments')
    }

    if (assignments && assignments.length > 0) {
      newParams.set('assignments', assignments.join(','))
    } else {
      newParams.delete('assignments')
    }

    if (positions && positions.length > 0) {
      newParams.set('positions', positions.join(','))
    } else {
      newParams.delete('positions')
    }

    if (sortBy && orderBy) {
      newParams.set('sortBy', sortBy)
      newParams.set('orderBy', orderBy)
    } else {
      newParams.delete('sortBy')
      newParams.delete('orderBy')
    }

    setSearchParams(newParams)
  }

  const dataTabs = [
    {
      key: '1',
      label: t('staffList'),
      children: (
        <AdministratorList
          methods={methods}
          onSearch={onSearch}
          setListRowSelected={setListRowSelected}
          loading={loading}
          error={error}
          admins={admins}
          handleSearchData={handleSearchData}
          handleDeleteAdmin={handleDeleteAdmin}
          setFirstCall={setFirstCall}
          isOwner={isOwner()}
        />
      )
    },
    {
      key: '2',
      label: t('roleSettings'),
      children: <RolePermissionTab tab={tab} />
    }
  ]

  const handleDownloadCsv = () => {
    const titleCsv = [
      t('table.id'),
      t('table.name_kana'),
      t('lable.gender'),
      t('lable.mailAddress'),
      t('role'),
      t('lable.department'),
      t('table.assignment')
    ]

    let dataExport: any = listRowSelected ?? []

    const contentCsvArr = [
      titleCsv,
      ...dataExport.map((item: any) => {
        return [
          `'${item.key}`,
          item.fullName,
          item.sex,
          item.email,
          `"${item.roles}"`,
          `"${item.departments}"`,
          `"${item.assignments}"`
        ]
      })
    ]

    const contentCsvString = contentCsvArr
      .map((arr) => arr.join(','))
      .join('\n')

    const filename = `${t('menu.staffManagement')} ${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}.csv`

    downloadCSV(contentCsvString, filename)
  }

  return (
    <Flex vertical className="min-w-[1680px]">
      <FormProvider {...methods}>
        <Flex className="items-center justify-between">
          <Flex className="items-center">
            <Title
              level={2}
              className="tracking-widest text-base font-bold text-nowrap mb-0"
            >
              {t('menu.staffManagement')}
            </Title>
            <Flex
              className={`items-center ${tab === ROLE_PERMISSION_TAB ? 'hidden' : ''}`}
            >
              <Form.Item className="ml-[46px] mb-0 hidden">
                <Controller
                  name="nameOrEmailAddress"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="w-[250px]"
                      prefix={<SearchOutlined />}
                      placeholder={t('placeholder.nameOrEmailAddress')}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item className="ml-8 mb-0">
                <SelectMultipleCustom
                  name="departments"
                  placeholder={t('affiliation')}
                  options={departmentOptions}
                  className="!w-[200px] courseSelect"
                />
              </Form.Item>
              <Form.Item className="ml-2.5 mb-0">
                <SelectMultipleCustom
                  name="assignments"
                  placeholder={t('table.assignment')}
                  options={assignmentOptions}
                  className="!w-[200px] courseSelect"
                />
              </Form.Item>
              <Form.Item className="ml-2.5 mb-0">
                <SelectMultipleCustom
                  name="positions"
                  placeholder={t('lable.position')}
                  options={positionOptions}
                  className="!w-[200px] courseSelect"
                />
              </Form.Item>
              <Button
                type="primary"
                autoInsertSpace={false}
                htmlType="submit"
                size="middle"
                className="min-w-[84px] ml-2.5 font-bold rounded-none shadow-none"
                onClick={() => onSearch()}
              >
                {t('placeholder.search')}
              </Button>
            </Flex>
          </Flex>
          <Flex className="items-center gap-2 hidden">
            <Button
              autoInsertSpace={false}
              type="primary"
              size="middle"
              className="min-w-[114px] bg-gradient-to-r from-[#137695] to-[#93D1CA] font-bold border-none shadow-none"
              onClick={() => navigate('/admin-register')}
            >
              {t('button.register')}
            </Button>
            <Button
              autoInsertSpace={false}
              type="primary"
              size="middle"
              className="min-w-[91px] bg-gradient-to-r from-[#137695] to-[#93D1CA] font-bold border-none shadow-none"
              onClick={handleDownloadCsv}
            >
              {t('button.export_CSV')}
            </Button>
          </Flex>
        </Flex>
      </FormProvider>
      <Flex
        vertical
        className="bg-white p-2.5 shadow-custom min-h-full rounded-[5px] text-sm text-default mt-2.5"
      >
        <Title
          level={3}
          className="tracking-[1.4px] text-primary font-bold text-sm mb-0"
        >
          {t('permissionSettings')}
        </Title>
        <Paragraph className="mt-2.5 mb-0">{titleTab[tab]}</Paragraph>
        <Tabs
          defaultActiveKey="1"
          items={dataTabs}
          className="tab-admin-list mt-[11px]"
          onChange={(tab) => setTab(tab)}
        />
      </Flex>
    </Flex>
  )
}
