import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Col, Empty, Row, Tooltip } from 'antd'

import CreateAssignmentModal from './CreateAssignmentModal'
import CreateAssignmentSuccessModal from './CreateAssignmentSuccessModal'
import DeleteAssignmentModal from './DeleteAssignmentModal'
import DeleteAssignmentSuccessModal from './DeleteAssignmentSuccessModal'
import UpdateAssignmentModal from './UpdateAssignmentModal'
import UpdateAssignmentSuccessModal from './UpdateAssignmentSuccessModal'
import { ReactComponent as TrashIcon } from 'assets/imgs/trash.svg'
import { ReactComponent as EditIcon } from 'assets/svgs/pen.svg'
import { StringKeyObject } from 'types/common'

export default function ManageAssignments({
  assignments,
  taskProcessingTenantAdditionalInfo
}: {
  assignments: StringKeyObject[]
  taskProcessingTenantAdditionalInfo: Function
}) {
  const { t } = useTranslation()

  const [createModal, setCreateModal] = useState<boolean>(false)
  const [dataUpdate, setDataUpdate] = useState<StringKeyObject | null>(null)
  const [dataDelete, setDataDelete] = useState<StringKeyObject | null>(null)
  const [isCreateSuccess, setIsCreateSuccess] = useState<boolean>(false)
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false)
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false)

  return (
    <>
      <Row className="text-center py-3 mt-2.5 border-y border-customGray items-center">
        <Col flex="70%">{t('table.assignmentName')}</Col>
        <Col flex="15%">{t('table.operation')}</Col>
        <Col flex="auto" className="flex justify-end">
          <Button
            autoInsertSpace={false}
            type="primary"
            className="min-w-[100px] font-bold"
            onClick={() => setCreateModal(true)}
          >
            {t('button.register')}
          </Button>
        </Col>
      </Row>
      {assignments.map((assignment) => {
        return (
          <Row
            className="py-2 items-center row-basic-setting"
            key={assignment.key}
          >
            <Col flex="10%"></Col>
            <Col flex="60%">{assignment.value}</Col>
            <Col flex="15%" className="flex items-center justify-center gap-10">
              <Tooltip placement="top" title={t('button.edit')}>
                <EditIcon
                  className="cursor-pointer w-4"
                  onClick={() => setDataUpdate(assignment)}
                />
              </Tooltip>
              <Tooltip placement="top" title={t('button.delete')}>
                <TrashIcon
                  className="cursor-pointer w-4"
                  onClick={() => setDataDelete(assignment)}
                />
              </Tooltip>
            </Col>
            <Col flex="auto" className="flex justify-end"></Col>
          </Row>
        )
      })}
      {assignments.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <CreateAssignmentModal
        assignments={assignments}
        isModalOpen={createModal}
        onCancel={() => setCreateModal(false)}
        onSuccess={taskProcessingTenantAdditionalInfo}
        setIsCreateSuccess={setIsCreateSuccess}
      />
      <UpdateAssignmentModal
        assignments={assignments}
        dataUpdate={dataUpdate}
        onCancel={() => setDataUpdate(null)}
        handleUpdate={taskProcessingTenantAdditionalInfo}
        setIsUpdateSuccess={setIsUpdateSuccess}
      />
      <DeleteAssignmentModal
        dataDelete={dataDelete}
        onCancel={() => setDataDelete(null)}
        handleDelete={taskProcessingTenantAdditionalInfo}
        setIsDeleteSuccess={setIsDeleteSuccess}
      />
      <CreateAssignmentSuccessModal
        isModalOpen={isCreateSuccess}
        onCancel={() => setIsCreateSuccess(false)}
      />
      <UpdateAssignmentSuccessModal
        isModalOpen={isUpdateSuccess}
        onCancel={() => setIsUpdateSuccess(false)}
      />
      <DeleteAssignmentSuccessModal
        isModalOpen={isDeleteSuccess}
        onCancel={() => setIsDeleteSuccess(false)}
      />
    </>
  )
}
