import { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, InputNumber, Typography } from 'antd'

import useMessageList from 'hooks/chat/messageList'

function ChooseAge() {
  const { t } = useTranslation()
  const { methods, setFormValue } = useMessageList()

  const { getValues, setValue } = useFormContext()
  const startAge = getValues('startAge')
  const endAge = getValues('endAge')

  const renderAgeInput = (name: 'startAge' | 'endAge', className: string) => (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => (
        <InputNumber
          {...field}
          value={name === 'startAge' ? startAge : endAge}
          onChange={(value: number | null) => {
            setFormValue(name, value)
            setValue(name, value)
          }}
          min={0}
          max={999}
          className={`h-[30px] w-[50px] ${className}`}
        />
      )}
    />
  )

  return (
    <Flex align="center">
      <Typography className="font-bold text-nowrap">
        {t('customerManagement.table.age')}
      </Typography>
      {renderAgeInput('startAge', 'ml-[6px]')}
      <Typography className="mx-[2px]">～</Typography>
      {renderAgeInput('endAge', '')}
    </Flex>
  )
}

export default memo(ChooseAge)
