export function useLocalStorage(
  key: string,
  defaultValue: any = null,
  shouldStringify: any = true
) {
  let value = defaultValue

  if (typeof window !== 'undefined') {
    if (localStorage?.getItem(key)) {
      const localValue = localStorage.getItem(key)
      if (localValue) value = JSON.parse(localValue)
    }
  }

  const setValue = (newValue: any) => {
    if (localStorage) {
      if (newValue || typeof newValue === 'boolean') {
        localStorage?.setItem(
          key,
          shouldStringify ? JSON.stringify(newValue) : newValue
        )
      } else {
        localStorage?.removeItem(key)
      }
    }
  }

  return [value, setValue]
}

export const getLocalStorage = (key: string, defaultValue = null) => {
  const value = localStorage.getItem(key)
  if (value) {
    return JSON.parse(value)
  }
  return defaultValue
}
