import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Radio, Tooltip } from 'antd'
import { useRecoilValue } from 'recoil'

import { courseAtom } from '../../atoms/course/CourseAtom'
import { scenarioCreateAtom } from '../../atoms/create/scenarioCreateAtom'
import SearchCourse from './SearchCourse'
import SearchResult from './SearchResult'
import { QuestionCircleFilled } from '@ant-design/icons'
import { RadioChangeEvent } from 'antd/es/radio'
import { RESERVATION_TYPE, STEP_SCENARIO } from 'configs/constant'
import { ScenarioCreate } from 'pages/Scenario/types/types'

const ReservationSection = ({
  dataForm,
  setDataForm
}: {
  dataForm: ScenarioCreate
  setDataForm: Dispatch<SetStateAction<ScenarioCreate>>
}) => {
  const { t } = useTranslation()

  const { getValues, setValue, watch } = useFormContext()

  const courseOptions = useRecoilValue(courseAtom)
  const scenarioCreate = useRecoilValue(scenarioCreateAtom)

  const [coursesPicked, setCoursesPicked] = useState<
    { id: string; name: string }[]
  >([])

  const courseRefIdsSelected = watch('courses')

  // Update coursesPicked when options exist
  useEffect(() => {
    if (
      !scenarioCreate?.flows?.[STEP_SCENARIO.SECOND_STEP]?.options?.length ||
      courseOptions.length === 0
    )
      return

    const options = scenarioCreate?.flows?.[STEP_SCENARIO.SECOND_STEP]?.options

    if (!options.length) return

    setCoursesPicked((prev) => {
      const newCoursesPicked: { id: string; name: string }[] = []

      options.forEach((option) => {
        const index = courseOptions.findIndex(
          (course) => course.value === option.value
        )

        if (index !== -1) {
          newCoursesPicked.push({
            id: option.value ?? '',
            name: option.displayName ?? ''
          })
        }
      })

      // set value for form
      setValue(
        'courses',
        newCoursesPicked.map((option) => option.id)
      )

      return newCoursesPicked
    })
  }, [
    scenarioCreate?.flows?.[STEP_SCENARIO.SECOND_STEP]?.options,
    courseOptions
  ])

  // set data form when coursesPicked change
  useEffect(() => {
    const mapToOptions = (
      items: any[],
      mapper: (item: any, index: number) => any
    ) => {
      return items.map((item, index) => mapper(item, index))
    }

    const updateFlowOptions = (options: any[]) => (prev: ScenarioCreate) => {
      return {
        ...prev,
        flows: prev.flows.map((flow) => {
          if (flow.order === STEP_SCENARIO.SECOND_STEP) {
            return { ...flow, options }
          }
          return flow
        })
      }
    }

    setDataForm(
      updateFlowOptions(
        mapToOptions(coursesPicked, (course, index) => ({
          order: index,
          displayName: course.name,
          value: course.id
        }))
      )
    )
  }, [coursesPicked])

  // set coursesPicked when courses change
  useEffect(() => {
    if (courseRefIdsSelected) {
      const coursesPicked = courseRefIdsSelected.map((courseRefId: string) => {
        const course = courseOptions.find(
          (option) => option.value === courseRefId
        )

        return {
          id: course?.value ?? '',
          name: course?.label ?? ''
        }
      })

      setCoursesPicked(coursesPicked)
    }
  }, [courseRefIdsSelected, courseOptions])

  const onChangeReservationType = (e: RadioChangeEvent) => {
    setDataForm((prev) => ({
      ...prev,
      reservationType: e.target.value
    }))
  }

  return (
    <div className="mt-2">
      <p className="text-primary font-bold mb-2">
        {t('scenarioCreate.selectionForm.reservationButton')}
      </p>
      <SearchCourse courseOptions={courseOptions} />
      <SearchResult coursePicked={coursesPicked} />

      <p className="text-primary font-bold my-2">
        {t('scenarioCreate.selectionForm.title')}
      </p>
      <Radio.Group
        value={dataForm.reservationType}
        onChange={onChangeReservationType}
      >
        <Radio value={RESERVATION_TYPE.RESERVED_REGISTRATION}>
          {t('scenarioCreate.selectionForm.reservationType.full')}
          <ReservationTooltip
            title={t('scenarioCreate.tooltip.reservationRadio1')}
          />
        </Radio>

        <Radio value={RESERVATION_TYPE.DESIRE_DATE_REGISTRATION}>
          {t('scenarioCreate.selectionForm.reservationType.preferred')}
          <ReservationTooltip
            title={t('scenarioCreate.tooltip.reservationRadio2')}
          />
        </Radio>
      </Radio.Group>
    </div>
  )
}

export default ReservationSection

const ReservationTooltip = ({ title }: { title: string }) => {
  return (
    <span>
      <Tooltip
        title={<p>{title}</p>}
        color="#545454"
        overlayStyle={{
          maxWidth: '15rem',
          opacity: 0.8
        }}
        overlayInnerStyle={{
          borderRadius: '5px'
        }}
      >
        <QuestionCircleFilled style={{ color: '#8E9EAA', marginLeft: '3px' }} />
      </Tooltip>
    </span>
  )
}
