import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'

import { openNotification } from '../../components/widgets/Notification'
import useCustomer from '../../hooks/useCustomer'
import CustomerDetailContent from './components/CustomerDetailContent'
import CustomerDetailHeader from './components/CustomerDetailHeader'
import { CustomerDetailEditFormMethods } from './components/forms/CustomerDetailEditForm'
import Delete from './components/modals/Delete'
import DeletionNotice from './components/modals/DeletionNotice'
import {
  GENDER,
  ITEM_MASTER_CODE,
  STATUS_CODE_ALREADY_EXISTS,
  STATUS_CODE_SUCCESS,
  TEMP_ID,
  TEXT_CATEGORY_MASTER,
  TEXT_COMMENTS,
  typeReferenceValue
} from 'configs/constant'
import { useGetCheckupUsers } from 'hooks/useCheckupUser'
import { CustomerDetail as CustomerData } from 'hooks/useCustomer'
import useMedicalCheckup from 'hooks/useMedicalCheckup'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import useReferenceValue from 'hooks/useReferenceValue'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMasters from 'hooks/useTestItemMaster'
import useTestResult from 'hooks/useTestResult'
import { TAB } from 'models/customer'
import DuplicateIdError from 'pages/CustomerRegister/components/modals/DuplicateIdError'
import { StringKeyObject } from 'types/common'
import { getLanguage, isObjectEmpty, mergeItemMaster } from 'utilities/helpers'

export default function CustomerDetail() {
  const { t } = useTranslation()
  let language = getLanguage()
  const { state } = useLocation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    onFilterCustomer,
    onUpdateCustomer,
    onDeleteCustomer,
    onCreateCustomer,
    onDeleteRocketChatUser,
    onUpdateRocketChatUser,
    handleDataCustomerDetail,
    onUpdateDataCustomerByNewId
  } = useCustomer()
  const { getMedicalCheckupList } = useMedicalCheckup()
  const { getCheckupUsers } = useGetCheckupUsers()
  const { getTestResultWithVariables } = useTestResult()
  const { getItemCategories } = useTestItemCategory()

  const { getListItemMasterByMultipleLanguages, getItemMasterWithVariables } =
    useTestItemMasters()

  const { getMedicalCheckupMasterWithVariables } = useMedicalCheckupMaster()
  const { getListPeriodConfigsByIds } = useReferenceValue()

  const [isOpenEditForm, setOpenEditForm] = useState<boolean>(false)
  const [isOpenDeleteCustomerModal, setOpenDeleteCustomerModal] =
    useState<boolean>(false)
  const [isOpenDeleteNoticeModal, setOpenDeleteNoticeModal] =
    useState<boolean>(false)
  const [tab, setTab] = useState<string>(TAB.CONTROL)
  const [customerData, setCustomerData] = useState<CustomerData>()
  const [count, setCount] = useState<number>(0)
  const [currentIndex, setCurrentIndex] = useState<number>(
    state !== null ? state.location.index : 0
  )
  const [isFirstRecord, setFirstRecord] = useState<boolean>(false)
  const [isLastRecord, setLastRecord] = useState<boolean>(false)
  const [medicalCheckupData, setMedicalCheckupData] = useState<any>([])

  const [page, setPage] = useState<number>(1)
  const [size] = useState<number>(20)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [dataDuplicateId, setDataDuplicateId] = useState<any>(null)

  const ids = state !== null ? state.location.ids : []

  const search = location.search
  const searchParams = new URLSearchParams(search)
  const idCustomer = searchParams.get('id')
  const currentTabId = searchParams.get('currentTab')

  useEffect(() => {
    const search = {
      currentTab: currentTabId ?? TAB.CONTROL
    }
    setTab(search.currentTab)
  }, [search])

  const handleNextRecord = () => {
    if (ids.length - 1 > currentIndex) {
      setMedicalCheckupData([])
      navigate(
        `/customer-detail?id=${ids[currentIndex + 1]}&currentTab=${currentTabId}`,
        {
          state: {
            openListResultTab: tab,
            location: {
              ids: state.location.ids,
              index: currentIndex + 1
            }
          }
        }
      )
      setCurrentIndex(currentIndex + 1)
      setFirstRecord(false)
      setOpenEditForm(false)
    }
  }

  const handlePreviousRecord = () => {
    if (currentIndex !== 0 && ids.length - 1 >= currentIndex) {
      setMedicalCheckupData([])
      navigate(
        `/customer-detail?id=${ids[currentIndex - 1]}&currentTab=${currentTabId}`,
        {
          state: {
            openListResultTab: tab,
            location: {
              ids: state.location.ids,
              index: currentIndex - 1
            }
          }
        }
      )
      setCurrentIndex(currentIndex - 1)
      setLastRecord(false)
      setOpenEditForm(false)
    }
  }

  const childRef = useRef<CustomerDetailEditFormMethods>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (currentIndex === 0) {
        setFirstRecord(true)
        setLastRecord(false)
      }
      if ((ids ?? []).length - 1 === currentIndex) {
        setFirstRecord(false)
        setLastRecord(true)
      }
      if (currentIndex === 0 && ids.length === 0) {
        setFirstRecord(true)
        setLastRecord(true)
      }
      try {
        const filteredCustomer = await onFilterCustomer({
          filter: `(eq,STRING,status,PUBLISHED);(eq,STRING,refId,${idCustomer})`,
          page: 0,
          size: 1
        })

        if (filteredCustomer?.additionalInfo?.isDeleted === 'true') {
          openNotification({
            title: t('commonError'),
            message: t('customerDetail.notifications.customerDeleted'),
            type: 'error'
          })
          navigate('/')
        }

        setCustomerData(filteredCustomer)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData().then()
  }, [currentIndex, count])

  const getFixedDataItemMaster = async () => {
    const dataFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'code.coding.code',
              operator: 'in',
              value: [
                ITEM_MASTER_CODE.CONSULTANT_DATE,
                ITEM_MASTER_CODE.EVALUATION,
                ITEM_MASTER_CODE.GENERAL_COMMENTS
              ]
            }
          ]
        }
      }
    ])

    const result = dataFetch.reduce(
      (acc: StringKeyObject, item: StringKeyObject) => {
        acc[item.code?.coding?.[0]?.code] = item
        return acc
      },
      {}
    )

    return result
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        //Get medical checkup list
        const filterMedicalCheckup = await getMedicalCheckupList({
          filter: `(eq,STRING,checkupUserRef,${customerData?.id});(ne,STRING,additionalInfo.status,DELETED);(eq,STRING,status,PUBLISHED)`,
          page: page - 1,
          size: size,
          sortBy: '(desc,createdDate)'
        })

        setTotalRecords(
          filterMedicalCheckup?.data?.filterMedicalCheckupDetails
            ?.totalElements ?? 0
        )

        const medicalCheckupListConvert =
          filterMedicalCheckup?.data?.filterMedicalCheckupDetails?.payload ?? []

        if (medicalCheckupListConvert.length === 0) {
          setMedicalCheckupData([])
          return
        }

        const dataMedicalCheckup = medicalCheckupListConvert.map(
          (item: StringKeyObject) => item.medicalCheckup
        )

        // Get fixed master item list
        const fixedDataItemMaster = await getFixedDataItemMaster()

        const setMedicalCheckupRefIds = new Set<string>()
        const setMedicalCheckupMasterRefIds = new Set<string>()
        const setMedicalCheckupMasterObjIds = new Set<string>()
        const setPeriodConfigObjIds = new Set<string>()

        dataMedicalCheckup.forEach((item: StringKeyObject) => {
          if (item.refId) setMedicalCheckupRefIds.add(item.refId)
          if (item.medicalCheckupMasterRef)
            setMedicalCheckupMasterRefIds.add(item.medicalCheckupMasterRef)
          if (item.additionalInfo?.medicalCheckupMasterObjId)
            setMedicalCheckupMasterObjIds.add(
              item.additionalInfo.medicalCheckupMasterObjId
            )
          if (item.additionalInfo?.periodConfigObjId)
            setPeriodConfigObjIds.add(item.additionalInfo.periodConfigObjId)
        })

        const medicalCheckupRefIds = Array.from(setMedicalCheckupRefIds)
        const medicalCheckupMasterRefIds = Array.from(
          setMedicalCheckupMasterRefIds
        )
        const medicalCheckupMasterObjIds = Array.from(
          setMedicalCheckupMasterObjIds
        )
        const periodConfigObjIds = Array.from(setPeriodConfigObjIds)

        // Get medical checkup master list
        const filterMedicalCheckupMasters =
          (await getMedicalCheckupMasterWithVariables([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: medicalCheckupMasterRefIds
                  },
                  {
                    field: 'additionalInfo.objId',
                    operator: 'in',
                    value:
                      medicalCheckupMasterRefIds.length ===
                      medicalCheckupMasterObjIds.length
                        ? medicalCheckupMasterObjIds
                        : [...medicalCheckupMasterObjIds, null]
                  }
                ]
              }
            },
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'additionalInfo.objId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                medicalCheckupMasterObjId: '$_id'
              }
            }
          ])) ?? []

        const itemMasterIds: string[] = []
        const categoryIds: string[] = []
        const temporaryId = TEMP_ID
        const medicalCheckupMasterObj = filterMedicalCheckupMasters.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            const data = item?.data ?? []
            if (item?.medicalCheckupMasterObjId) {
              acc[item.medicalCheckupMasterObjId] = data[0] ?? {}
            } else {
              acc[temporaryId] = data
            }

            for (const dataItem of data) {
              const categoryStructure =
                dataItem?.additionalInfo?.categories ?? []
              categoryStructure.forEach((category: StringKeyObject) => {
                if (Array.isArray(category?.items)) {
                  itemMasterIds.push(
                    ...category.items.map((item: StringKeyObject) => item.refId)
                  )
                }
                categoryIds.push(category.refId)
              })
            }
            return acc
          },
          {}
        )

        // Get period config list
        const filterPeriodConfigs =
          await getListPeriodConfigsByIds(periodConfigObjIds)

        const periodConfigObj = filterPeriodConfigs.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            if (item?.additionalInfo?.objId) {
              acc[item?.additionalInfo?.objId] = item
            }
            return acc
          },
          {}
        )

        // Get test result list
        const filterTestResults =
          (await getTestResultWithVariables([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'medicalCheckupRefId',
                    operator: 'in',
                    value: medicalCheckupRefIds
                  },
                  {
                    field: 'status',
                    operator: 'eq',
                    value: 'PUBLISHED'
                  }
                ]
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'medicalCheckupRefId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                medicalCheckupRefId: '$_id'
              }
            }
          ])) ?? []

        const testResultObj = filterTestResults.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            const dataItem = item?.data ?? []

            acc[item?.medicalCheckupRefId] = dataItem.reduce(
              (acc2: StringKeyObject, item2: StringKeyObject) => {
                if (item2?.itemMasterRefId) acc2[item2.itemMasterRefId] = item2

                return acc2
              },
              {}
            )
            return acc
          },
          {}
        )

        // Get category list
        const filterCategories =
          (await getItemCategories([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: Array.from(new Set(categoryIds))
                  }
                ]
              }
            }
          ])) ?? []

        const itemMasterIdsCategory: string[] = []
        const categoryObj: StringKeyObject = {}
        filterCategories.forEach((category: StringKeyObject) => {
          categoryObj[category.refId] = category

          if (Array.isArray(category?.associatedTestItemMasterRefIds)) {
            itemMasterIdsCategory.push(
              ...category.associatedTestItemMasterRefIds
            )
          }
        })

        // Get test item master list
        const filterTestItemMasters = await getItemMasterWithVariables([
          {
            type: 'MATCH',
            criteria: {
              and: [
                {
                  field: 'refId',
                  operator: 'in',
                  value: Array.from(
                    new Set([...itemMasterIds, ...itemMasterIdsCategory])
                  )
                },
                {
                  field: 'additionalInfo.medicalCheckupMasterObjId',
                  operator: 'in',
                  value:
                    medicalCheckupMasterRefIds.length ===
                    medicalCheckupMasterObjIds.length
                      ? medicalCheckupMasterObjIds
                      : [...medicalCheckupMasterObjIds, null]
                }
              ]
            }
          },
          {
            type: 'SORT',
            criteria: {
              field: '_id',
              direction: 'desc'
            }
          },
          {
            type: 'ADD_FIELDS',
            criteria: {
              lang: '$additionalInfo.localizedName'
            }
          },
          {
            type: 'GROUP',
            criteria: {
              field: 'additionalInfo.medicalCheckupMasterObjId'
            }
          },
          {
            type: 'ADD_FIELDS',
            criteria: {
              medicalCheckupMasterObjId: '$_id'
            }
          }
        ])

        const testItemMasterObj = filterTestItemMasters.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            const data = item?.data ?? []
            if (item?.medicalCheckupMasterObjId) {
              acc[item.medicalCheckupMasterObjId] = data
            } else {
              acc[temporaryId] = mergeItemMaster(data)
            }
            return acc
          },
          {}
        )

        //format data
        const dataMedicalCheckupFormat = []

        const itemConsultantDateRefId =
          fixedDataItemMaster[ITEM_MASTER_CODE.CONSULTANT_DATE]?.refId
        const itemEvaluationDateRefId =
          fixedDataItemMaster[ITEM_MASTER_CODE.EVALUATION]?.refId
        const itemGeneralCommentsRefId =
          fixedDataItemMaster[ITEM_MASTER_CODE.GENERAL_COMMENTS]?.refId

        for (const medicalCheckup of dataMedicalCheckup) {
          let medicalCheckupMaster
          if (medicalCheckup?.additionalInfo?.medicalCheckupMasterObjId) {
            medicalCheckupMaster =
              medicalCheckupMasterObj[
                medicalCheckup?.additionalInfo?.medicalCheckupMasterObjId
              ]
          } else {
            medicalCheckupMaster = (
              medicalCheckupMasterObj[temporaryId] ?? []
            ).find(
              (item: StringKeyObject) =>
                item.refId === medicalCheckup?.medicalCheckupMasterRef
            )
          }
          if (!medicalCheckupMaster) continue

          let periodConfig
          if (medicalCheckup?.additionalInfo?.periodConfigObjId) {
            const dataPeriodConfig =
              periodConfigObj[
                medicalCheckup?.additionalInfo?.periodConfigObjId
              ] ?? {}

            const evaluations: StringKeyObject[] = [
              ...(dataPeriodConfig?.evaluations ?? [])
            ].sort(
              (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
            )

            const [evaluationA] = evaluations

            const referenceValues = evaluationA?.referenceValues ?? []
            const referenceValuesObj = referenceValues.reduce(
              (acc: any, item: StringKeyObject) => {
                return { ...acc, [item.testItemMasterRefId]: item.constraint }
              },
              {}
            )
            periodConfig = referenceValuesObj
          }

          const medicalCheckupTestResults =
            testResultObj[medicalCheckup.refId] ?? {}
          const consultationDate =
            medicalCheckupTestResults[itemConsultantDateRefId]?.value ?? ''
          const evaluation = medicalCheckupTestResults[itemEvaluationDateRefId]
          const generalComments =
            medicalCheckupTestResults[itemGeneralCommentsRefId]?.value ?? ''

          const categoryFormat = handleDataCategory(
            medicalCheckupMaster,
            testItemMasterObj,
            categoryObj,
            periodConfig ?? {}
          )

          const badTestResults = checkDataTestResult(
            categoryFormat,
            medicalCheckupTestResults
          )

          //valueBy
          dataMedicalCheckupFormat.push({
            ...medicalCheckup,
            key: medicalCheckup.refId,
            id: medicalCheckup.refId,
            status: medicalCheckup.additionalInfo?.healthCheckStatus,
            listIds: medicalCheckupRefIds,
            consultationDate: consultationDate
              ? consultationDate.split('T')[0].replace(/-/g, '/')
              : '',
            doctorName: evaluation?.valueBy ?? '',
            evaluation: evaluation?.value ?? '',
            generalComments: generalComments,
            registrationDate: medicalCheckup.createdDate
              .split('T')[0]
              .replace(/-/g, '/'),
            testResults: medicalCheckupTestResults,
            medicalCheckupMaster: medicalCheckupMaster,
            periodConfig: periodConfig ?? {},
            formatCategory: categoryFormat,
            badTestResults: badTestResults
          })
        }

        setMedicalCheckupData(dataMedicalCheckupFormat)
      } catch (error) {
        console.error(error)
      }
    }
    if (customerData && !isObjectEmpty(customerData)) {
      fetchData().then(() => {
        setLoading(false)
      })
    }
  }, [customerData?.id, page])

  const handleDataCategory = (
    medicalCheckupMaster: StringKeyObject,
    testItemMasterObj: StringKeyObject,
    categoryObj: StringKeyObject,
    periodConfig: StringKeyObject
  ) => {
    const categoryStructure = medicalCheckupMaster?.additionalInfo?.categories
    if (!Array.isArray(categoryStructure)) return []

    const itemMasters =
      testItemMasterObj[medicalCheckupMaster?.additionalInfo?.objId] ??
      testItemMasterObj[TEMP_ID]
    if (!Array.isArray(itemMasters)) return []

    const categoryFormat = []
    for (const category of categoryStructure) {
      const dataCategory = categoryObj[category.refId]
      if (!dataCategory) continue
      const itemMasterCategory = itemMasters.find(
        (itemMaster: StringKeyObject) =>
          (dataCategory?.associatedTestItemMasterRefIds ?? []).includes(
            itemMaster.refId
          ) &&
          itemMaster?.additionalInfo?.key3 !== TEXT_COMMENTS &&
          itemMaster?.additionalInfo?.key1 === TEXT_CATEGORY_MASTER
      )

      if (!itemMasterCategory) continue

      const structureItemMaster = category?.items ?? []
      const items = []
      for (const item of structureItemMaster) {
        const itemMaster = itemMasters.find(
          (itemMaster) => itemMaster.refId === item.refId
        )
        if (!itemMaster) continue
        const refValues = periodConfig[itemMaster.refId] ?? []
        const dataConstraints = refValues.filter(
          (refValue: StringKeyObject) => {
            return (
              refValue?.gender &&
              refValue?.gender === (customerData?.gender ?? GENDER.MALE)
            )
          }
        )

        items.push({
          ...itemMaster,
          lang: itemMaster.lang ?? {},
          referenceValues:
            dataConstraints.length > 0 ? dataConstraints : refValues,
          order: item.order ?? 9999
        })
      }

      if (items.length > 0)
        items.sort(
          (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
        )

      categoryFormat.push({
        refId: dataCategory.refId,
        shortName: dataCategory.shortName,
        associatedTestItemMasterRefIds:
          dataCategory?.associatedTestItemMasterRefIds,
        itemMaster: items,
        idItemMaster: itemMasterCategory?.refId,
        lang: itemMasterCategory?.lang ?? {},
        order: category?.order ?? 9999
      })
    }

    categoryFormat.sort(
      (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
    )

    return categoryFormat
  }

  const checkDataTestResult = (
    categories: StringKeyObject[],
    testResult: StringKeyObject
  ) => {
    const result = []
    for (const category of categories) {
      const items = category?.itemMaster ?? []
      for (const item of items) {
        if (item.referenceValues.length > 0) {
          const referenceValues = item.referenceValues

          const testResultValue = testResult[item.refId]?.value
          if (!testResultValue) continue

          let isAddResult = true
          for (const referenceValue of referenceValues) {
            switch (referenceValue.type) {
              case typeReferenceValue.RANGE_NUMBER:
                if (
                  parseFloat(referenceValue.lowerLimit) <=
                    parseFloat(testResultValue) &&
                  parseFloat(testResultValue) <=
                    parseFloat(referenceValue.upperLimit)
                )
                  isAddResult = false
                break
              case typeReferenceValue.CONDITIONAL_NUMBER:
                if (
                  referenceValue?.upperLimit &&
                  parseFloat(testResultValue) <=
                    parseFloat(referenceValue.upperLimit)
                )
                  isAddResult = false
                if (
                  referenceValue?.lowerLimit &&
                  parseFloat(testResultValue) >=
                    parseFloat(referenceValue.lowerLimit)
                )
                  isAddResult = false
                break
              case typeReferenceValue.STRING:
                if (referenceValue.textValue === testResultValue)
                  isAddResult = false
                break
            }
          }

          if (isAddResult)
            result.push({
              ...item,
              categoryLang: category.lang,
              value: testResultValue
            })
        }
      }
    }

    return result
  }

  useEffect(() => {
    setOpenEditForm(state?.isOpenEditForm === true)
  }, [state?.openListResultTab])

  const handleDeleteCustomer = async () => {
    await onDeleteCustomer(customerData).then(async () => {
      await onDeleteRocketChatUser(customerData?.id as string)
      setOpenDeleteCustomerModal(false)
      setOpenDeleteNoticeModal(true)
    })
  }

  const handleUpdateCustomer = () => {}

  const handleChildFormSubmit = async (data: any) => {
    if (data.id === data.patientId) {
      await onUpdateCustomer(data).then(async () => {
        setOpenEditForm(false)
        setCount(count + 1)

        await onUpdateRocketChatUser(data.id)
      })

      return
    }

    const newCustomer = handleDataCustomerDetail(data)

    const res = await onCreateCustomer({
      ...newCustomer,
      refId: data.patientId
    })

    if (res?.addCheckupUser?.status === STATUS_CODE_SUCCESS) {
      await onUpdateDataCustomerByNewId(data.id, data.patientId)

      await onDeleteCustomer(data)

      setOpenEditForm(false)
      setCount(count + 1)
      navigate(
        `/customer-detail?id=${data.patientId}&currentTab=${TAB.CONTROL}`,
        {
          state: {
            openListResultTab: tab,
            location: {
              ids: ids
                ? ids.map((item: any) =>
                    item === data.id ? data.patientId : item
                  )
                : [],
              index: currentIndex
            }
          }
        }
      )
    }

    if (res?.addCheckupUser?.status === STATUS_CODE_ALREADY_EXISTS) {
      const checkUser = await getCheckupUsers({
        filter: `(eq,STRING,refId,${data.patientId})`,
        page: 0,
        size: 1,
        sortBy: '(desc,createdDate)'
      })

      if (checkUser?.[0]) setDataDuplicateId(checkUser[0])
    }
  }

  const handleSubmit = async () => {
    setTab(TAB.CONTROL)
    if (isOpenEditForm) {
      childRef.current?.onSubmit()
    }

    if (!isOpenEditForm) {
      handleUpdateCustomer()
    }
  }

  return (
    <div className="text-[14px] text-[#545454] w-fit">
      <Typography className="mb-4 text-[16px] font-bold tracking-[1.6px]">
        {t('customerDetail.customerDetail')}
      </Typography>

      <div className="mb-[14px]">
        <CustomerDetailHeader
          t={t}
          setTab={setTab}
          data={customerData}
          isOpenEditForm={isOpenEditForm}
          onOpenEditForm={() => setOpenEditForm(!isOpenEditForm)}
          onNext={handleNextRecord}
          onPrevious={handlePreviousRecord}
          isFirstRecord={isFirstRecord}
          isLastRecord={isLastRecord}
        />
      </div>
      <div>
        {medicalCheckupData && (
          <CustomerDetailContent
            onOpenEditForm={() => setOpenEditForm(!isOpenEditForm)}
            t={t}
            tab={tab}
            setTab={setTab}
            dataForm={customerData}
            dataTable={medicalCheckupData}
            isOpenEditForm={isOpenEditForm}
            onChange={(value) => {
              navigate(
                `/customer-detail?id=${idCustomer}&currentTab=${value}`,
                {
                  state: {
                    openListResultTab: tab,
                    location: {
                      ids: ids,
                      index: currentIndex + 1
                    }
                  }
                }
              )
              setOpenEditForm(false)
            }}
            childRef={childRef}
            onSubmitChild={handleChildFormSubmit}
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
            loadingMedical={loading}
            language={language}
          />
        )}
      </div>

      {isOpenEditForm ? (
        <Flex className="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            autoInsertSpace={false}
            onClick={handleSubmit}
            className="mr-[8px] h-[33px] w-[180px] rounded-[3px] bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
          >
            {t('customerDetail.keep')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            autoInsertSpace={false}
            onClick={() => setOpenDeleteCustomerModal(true)}
            className="h-[33px] w-[180px] rounded-[3px] bg-[#DF2475] p-0 text-center text-[14px] font-bold text-white shadow-none"
          >
            {t('customerDetail.delete')}
          </Button>
        </Flex>
      ) : (
        <></>
      )}

      {customerData !== null ? (
        <div>
          <Delete
            t={t}
            isOpen={isOpenDeleteCustomerModal}
            data={{
              id: customerData?.id,
              fullName: customerData?.fullName
            }}
            onDelete={handleDeleteCustomer}
            onCancel={() => setOpenDeleteCustomerModal(false)}
          />

          <DeletionNotice
            t={t}
            isOpen={isOpenDeleteNoticeModal}
            data={{
              id: customerData?.id,
              fullName: customerData?.fullName
            }}
            onCancel={() => {
              setOpenDeleteNoticeModal(false)
              navigate('/customer-management')
            }}
          />
        </div>
      ) : null}

      <DuplicateIdError
        t={t}
        isOpen={dataDuplicateId}
        onCancel={() => setDataDuplicateId(null)}
        data={dataDuplicateId}
        isCreate={false}
      />
    </div>
  )
}
