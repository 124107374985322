import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Flex, Row, Typography } from 'antd'

import { IAdminRegister } from '../AdminRegister'
import { roleOptionByLanguage } from './AdminRegisterForm'
import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'
import { InputLabel } from 'components/elements/InputLabel'
import { Options } from 'types/common'
import { getLabelByValue, getLanguage } from 'utilities/helpers'

const WIDTH_LABEL = '160px'
const WIDTH_BOX = 'w-[568px]'

const getRowInformation = (label: string, value?: string | null) => {
  return (
    <Row className="gap-3 w-full items-center">
      <Col flex={WIDTH_LABEL} className="h-full">
        <InputLabel align="center" label={label} className="!py-0 px-0.5" />
      </Col>
      <Col flex="auto">
        <Typography>{value}</Typography>
      </Col>
    </Row>
  )
}

export default function InformationConfirmationBlock({
  item,
  roleOptions,
  departmentOptions,
  assignmentOptions,
  positionOptions,
  genderOptions,
  adminOptions
}: {
  item: IAdminRegister
  roleOptions: Options[]
  departmentOptions: Options[]
  assignmentOptions: Options[]
  positionOptions: Options[]
  genderOptions: Options[]
  adminOptions: Options[]
}) {
  const { t } = useTranslation()
  const language = getLanguage()

  const informationOnTheLeft = [
    {
      label: t('customerDetail.form.label.firstName'),
      value: item?.first_name
    },
    {
      label: t('customerDetail.form.label.firstNameKana'),
      value: item?.first_name_kana
    },
    {
      label: t('lable.gender'),
      value: getLabelByValue(item?.gender, genderOptions)
    },
    {
      label: t('placeholder.emailAddress'),
      value: item?.email
    },
    {
      label: t('lable.userName'),
      value: item?.user_name
    },
    {
      label: t('role'),
      value: getLabelByValue(
        item?.roles,
        roleOptionByLanguage(roleOptions, language),
        true
      )
    },
    {
      label: t('affiliation'),
      value: getLabelByValue(item?.departments, departmentOptions, true)
    },
    {
      label: t('table.assignment'),
      value: getLabelByValue(item?.assignments, assignmentOptions, true)
    },
    {
      label: t('lable.position'),
      value: getLabelByValue(item?.positions, positionOptions, true)
    }
  ]

  const informationOnTheRight = [
    { label: t('customerDetail.form.label.lastName'), value: item?.last_name },
    {
      label: t('customerDetail.form.label.lastNameKana'),
      value: item?.last_name_kana
    }
  ]
  return (
    <Flex vertical>
      {item?.ownership_change && (
        <>
          <Typography className="py-2.5 px-3.5 bg-[#F5006B0D] border border-error text-error mb-3 w-max">
            {t('content.editAdminNotify')}
          </Typography>
          <Row className={`gap-2 mb-2 ${WIDTH_BOX}`}>
            <Col flex={WIDTH_LABEL}>
              <InputLabel
                align="center"
                label={t('lable.ownerPermissionChange')}
                className="!py-0 px-0.5"
              />
            </Col>
            <Col flex="auto">
              <Flex className="items-center gap-1.5">
                <CrownIcon className="inline-block w-[17px]" />
                <Typography className="font-bold text-primary">
                  {t('content.setAsCurrentOwner')}
                </Typography>
              </Flex>

              <Typography>
                {getLabelByValue(item.ownership_change, adminOptions)}
              </Typography>
            </Col>
          </Row>
        </>
      )}
      <Flex className="gap-[30px] items-start">
        <Flex className={`${WIDTH_BOX} flex-wrap gap-1.5`}>
          {informationOnTheLeft.map((info, index) => (
            <React.Fragment key={index}>
              {getRowInformation(info.label, info.value)}
            </React.Fragment>
          ))}
        </Flex>
        <Flex className={`${WIDTH_BOX} flex-wrap gap-1.5`}>
          {informationOnTheRight.map((info, index) => (
            <React.Fragment key={index}>
              {getRowInformation(info.label, info.value)}
            </React.Fragment>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
