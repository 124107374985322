import { DocumentNode, gql } from '@apollo/client'

export const CREATE_PERIOD_CONFIG: DocumentNode = gql`
  mutation createPeriodConfigs($periodConfigsData: [PeriodConfigInput]!) {
    createPeriodConfigs(periodConfigsData: $periodConfigsData) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupMasterRefId
        order
        startDate
        endDate
        createdDate
        updatedDate
      }
    }
  }
`
