import { Endpoint, apolloClient } from '../utilities/apolloClient'
import { CREATE_SCENARIO, UPDATE_SCENARIO } from 'graphql/Scenario/create'
import {
  DELETE_SCENARIO,
  FILTER_LIST,
  SCENARIO_DETAIL
} from 'graphql/Scenario/list'
import { ScenarioCreateData, ScenariosGet } from 'pages/Scenario/types/types'

export const createScenario = async (scenario: ScenarioCreateData) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: CREATE_SCENARIO,
    variables: {
      scenarioTemplate: {
        name: scenario.name,
        categories: scenario.categories,
        trigger: scenario.trigger,
        selectionType: scenario.selectionType,
        status: scenario.status,
        flows: scenario.flows,
        reservationType: scenario.reservationType
      }
    },
    context: { version: Endpoint.RESERVATION }
  })

  if (errors) {
    throw errors
  }

  return data
}

export const updateScenario = async (scenario: ScenarioCreateData) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: UPDATE_SCENARIO,
    variables: { scenarioTemplate: scenario },
    context: { version: Endpoint.RESERVATION }
  })

  if (errors) {
    throw errors
  }

  return data
}

export const getScenarios = async ({ page, size, sortBy }: ScenariosGet) => {
  const { data, errors } = await apolloClient.query({
    query: FILTER_LIST,
    variables: { page, size, sortBy },
    context: { version: Endpoint.RESERVATION },
    fetchPolicy: 'network-only'
  })

  if (errors) {
    throw errors
  }

  const {
    filterScenarioChatTemplate: { payload, totalElements, currentPageNumber }
  } = data

  return { payload, totalElements, currentPageNumber }
}

export const getScenarioDetail = async ({ refId }: { refId: string }) => {
  const { data, errors } = await apolloClient.query({
    query: SCENARIO_DETAIL,
    variables: { refId },
    context: { version: Endpoint.RESERVATION },
    fetchPolicy: 'network-only'
  })

  if (errors) {
    throw errors
  }

  const {
    getScenarioChatTemplate: { payload }
  } = data

  return payload
}

export const deleteScenario = async ({ refId }: { refId: string }) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: DELETE_SCENARIO,
    variables: { refId },
    context: { version: Endpoint.RESERVATION }
  })

  if (errors) {
    throw errors
  }

  return data
}
