import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

import useMessageList from 'hooks/chat/messageList'

function FilterButton() {
  const { t } = useTranslation()
  const { handleSubmit } = useMessageList()
  return (
    <Button
      onClick={handleSubmit}
      type="primary"
      htmlType="submit"
      autoInsertSpace={false}
      className="mx-1 h-[30px] w-[84px] rounded-none border-none bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
    >
      {t('customerManagement.actions.search')}
    </Button>
  )
}

export default memo(FilterButton)
