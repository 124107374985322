import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'
import { useSetRecoilState } from 'recoil'

import { scenarioCreateAtom } from '../../atoms/create/scenarioCreateAtom'
import { initialScenarioOptions } from '../../data/data'
import ScenarioOption from '../scenarioOption/ScenarioOption'
import { MODAL_TYPE } from 'configs/constant'
import { ScenarioOptionProps } from 'pages/Scenario/types/types'
import { uuid } from 'utilities/helpers'

interface ModalCreateScenarioContentProps {
  readonly onCloseModal: (type: MODAL_TYPE) => void
}

function ModalCreateScenarioContent({
  onCloseModal
}: ModalCreateScenarioContentProps) {
  const { t } = useTranslation()
  const setScenarioDataCreate = useSetRecoilState(scenarioCreateAtom)

  const [optionsChooseTypeScenario, setOptionsChooseTypeScenario] = useState<
    ScenarioOptionProps[]
  >(initialScenarioOptions)

  const isDisabledButton = useMemo(
    () => optionsChooseTypeScenario.every((option) => option.disabled),
    [optionsChooseTypeScenario]
  )

  const handleClickButtonCreateScenario = () => {
    const selectedOption = optionsChooseTypeScenario.find(
      (option) => !option.disabled
    )

    if (selectedOption) {
      setScenarioDataCreate((prev) => ({
        ...prev,
        typeScenario: selectedOption.id
      }))
      onCloseModal(MODAL_TYPE.CHOOSE_TYPE_SCENARIO)

      setOptionsChooseTypeScenario(initialScenarioOptions)
    }
  }

  const handleClickOption = (id: string) => {
    setOptionsChooseTypeScenario((prev) => {
      // Deep copy
      const optionsTemp = JSON.parse(
        JSON.stringify(prev)
      ) as ScenarioOptionProps[]

      const indexFoundById = optionsTemp.findIndex((option) => option.id === id)

      if (indexFoundById === -1) return optionsTemp

      const optionFoundById = optionsTemp[indexFoundById]
      optionsTemp[indexFoundById].disabled = !optionFoundById.disabled

      return optionsTemp
    })
  }

  return (
    <React.Fragment>
      <p className="text-[18px] text-primary font-bold text-center mt-2">
        {t('scenarioCreate.title')}
      </p>
      <p className="text-sm text-default text-center mt-2">
        {t('scenarioCreate.description')}
      </p>

      <div className="my-3 px-7">
        {optionsChooseTypeScenario.map((option, index) => (
          <div key={uuid()} className={index > 0 ? 'mt-3' : ''}>
            <ScenarioOption {...option} onClick={handleClickOption} />
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-3 mb-3 pb-3">
        <Button
          type="primary"
          disabled={isDisabledButton}
          className="w-[180px] bg-primary text-white disabled:bg-disabled disabled:text-white font-bold"
          onClick={handleClickButtonCreateScenario}
        >
          {t('scenarioCreate.button')}
        </Button>
      </div>
    </React.Fragment>
  )
}

export default ModalCreateScenarioContent
