import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { openNotification } from '../../components/widgets/Notification'
import Content from './components/Content'
import Widget from './components/Widget'
import CustomPagination from 'components/elements/CustomPagination'
import { ExaminedAndPaidUser } from 'models/consultationReport'
import { RECORD_COUNT } from 'models/customer'
import { downloadCSV } from 'utilities/helpers'

export default function MonthlyConsultationReport() {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const { page: pageParam = 1, size: sizeParam = RECORD_COUNT.OneHundred } =
    Object.fromEntries(queryParams)

  const selectedRowsRef = useRef<ExaminedAndPaidUser[]>([])

  const [totalRecords, setTotalRecords] = useState<number>(0)

  const handleRowSelectionChange = useCallback(
    (selectedRows: ExaminedAndPaidUser[]) => {
      selectedRowsRef.current = selectedRows
    },
    []
  )

  const handleChangePage = (page: number) => {
    queryParams.set('page', page.toString())
    navigate(`${pathname}?${queryParams.toString()}`)
  }

  const exportSelectedRowsToCSV = useCallback(() => {
    const data = selectedRowsRef.current
    if (data.length === 0) {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.selectPatient')
      })
      return null
    }

    const headers = [
      t('basicSetting.formLabel.clinic_name'),
      t('consultationReport.content.year'),
      t('consultationReport.content.month'),
      t('reservationManagement.reservationDetail.patientId'),
      t('lable.customerName'),
      t('table.name_kana'),
      t('lable.dateOfBirth'),
      t('lable.age'),
      t('lable.gender'),
      t('lable.phone'),
      t('lable.mailAddress'),
      t('consultationReport.content.courseName'),
      t('reservationManagement.content.courseSelect'),
      t('consultationReport.content.reservationDateTime')
    ]
    const keys: Array<keyof ExaminedAndPaidUser> = [
      'tenantName',
      'year',
      'month',
      'userId',
      'fullName',
      'fullNameKana',
      'birthDay',
      'age',
      'gender',
      'phone',
      'email',
      'courseName',
      'courseSelect',
      'dateTime'
    ]

    const csvData = [
      headers.join(','),
      ...data.map((item) =>
        keys
          .map((key) => {
            const value = item[key]
            return typeof value === 'string' && value.includes(',')
              ? `"${value}"`
              : value
          })
          .join(',')
      )
    ].join('\n')

    downloadCSV(csvData, t('consultationReport.monthlyReport'))
  }, [])

  return (
    <div className="min-h-[400px] w-full min-w-[1500px]">
      <Widget onExportToCSV={exportSelectedRowsToCSV} />

      <Content
        onRowSelectionChange={handleRowSelectionChange}
        setTotalRecords={setTotalRecords}
      />

      {totalRecords > 0 && (
        <CustomPagination
          page={+pageParam}
          size={+sizeParam}
          totalRecords={totalRecords}
          setPage={handleChangePage}
        />
      )}
    </div>
  )
}
