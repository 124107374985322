import { Dispatch, SetStateAction, useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'

import { useRecoilState } from 'recoil'

import { scenarioCreateAtom } from 'pages/Scenario/ScenarioCreate/atoms/create/scenarioCreateAtom'
import { initialDataScenarioCreate } from 'pages/Scenario/ScenarioCreate/data/data'
import { ScenarioCreate } from 'pages/Scenario/types/types'

const useDataFormBlockHandler = (): {
  dataForm: ScenarioCreate
  setDataForm: Dispatch<SetStateAction<ScenarioCreate>>
} => {
  const [dataForm, setDataForm] = useState<ScenarioCreate>(
    initialDataScenarioCreate
  )
  const [scenarioCreate, setScenarioCreate] = useRecoilState(scenarioCreateAtom)
  const dataFormRef = useRef<ScenarioCreate>(initialDataScenarioCreate)

  useEffect(() => {
    dataFormRef.current = dataForm
  }, [dataForm])

  useEffect(() => {
    if (!scenarioCreate) return

    // get data and set to form
    setDataForm(scenarioCreate)

    return () => {
      setScenarioCreate(dataFormRef.current)
      setTimeout(() => {
        dataFormRef.current = initialDataScenarioCreate
      }, 0)
    }
  }, [scenarioCreate])

  return {
    dataForm,
    setDataForm
  }
}

export default useDataFormBlockHandler
