import { useState } from 'react'

import { useLocalStorage } from './index'
import { EXCLUDED_NAME } from './useRoles'
import { useLazyQuery } from '@apollo/client'
import { API_USER, KEYCLOAK_CLIENT_ID } from 'configs/api'
import { ADMIN_LIST_SORT_TYPE, genderOptions } from 'configs/constant'
import { FILTER_TENANT } from 'graphql/tenant/filter'
import { deleteAccountKeycloak, logoutKeycloak } from 'services/AuthService'
import { IRoles } from 'types/Roles'
import { StringKeyObject } from 'types/common'
import {
  checkIsTrue,
  decodedToken,
  getAccessToken,
  getLabelByValue,
  isErrorKeycloak
} from 'utilities/helpers'
import networkAdapter from 'utilities/networkAdapter'

const useAdmins = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [admins, setAdmins] = useState<any>([])
  const [loadTenantList] = useLazyQuery(FILTER_TENANT)
  const [firstCall, setFirstCall] = useState<boolean>(false)
  const [dataShow, setDataShow] = useState<any>([])
  const [departmentOptions, setDepartmentOptions] = useState<any>([])
  const [assignmentOptions, setAssignmentOptions] = useState<any>([])
  const [positionOptions, setPositionOptions] = useState<any>([])
  const [currentTenant] = useLocalStorage('@tenant', {})

  const handleGetAdmins = async () => {
    try {
      const response = await networkAdapter
        .getClient(API_USER)
        .then((response: any) => {
          return response
        })

      const dataAdmin = await handleDataAdmin(response)
      setAdmins(dataAdmin)
      setFirstCall(true)
      return dataAdmin
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const handleSearchData = async ({ ...search }: any) => {
    try {
      onClearError()
      setLoading(true)
      let dataList: any = admins
      if (!firstCall) {
        dataList = (await handleGetAdmins()) ?? []
      }

      if (search.nameOrEmailAddress) {
        dataList = dataList.filter(
          (user: any) =>
            user?.fullName
              ?.toLowerCase()
              .includes(search.nameOrEmailAddress.toLowerCase()) ||
            user?.email
              ?.toLowerCase()
              .includes(search.nameOrEmailAddress.toLowerCase())
        )
      }

      if (search.departments?.length > 0) {
        dataList = dataList.filter((user: any) => {
          if (!user?.departments) return false
          let userDepartments = user?.departments.split(',')

          for (const userDepartment of userDepartments) {
            if (search.departments.includes(userDepartment)) {
              return true
            }
          }

          return false
        })
      }

      if (search.assignments?.length > 0) {
        dataList = dataList.filter((user: any) => {
          if (!user?.assignments) return false
          let userAssignments = user?.assignments.split(',')

          for (const userAssignment of userAssignments) {
            if (search.assignments.includes(userAssignment)) {
              return true
            }
          }

          return false
        })
      }

      if (search.positions?.length > 0) {
        dataList = dataList.filter((user: any) => {
          if (!user?.positions) return false
          let userPositions = user?.positions.split(',')

          for (const userPosition of userPositions) {
            if (search.positions.includes(userPosition)) {
              return true
            }
          }

          return false
        })
      }

      if (search.sortBy && search.orderBy) {
        dataList = dataList.sort((a: any, b: any) => {
          let comparison = 0
          switch (search.sortBy) {
            case ADMIN_LIST_SORT_TYPE.FULL_NAME:
              comparison = a.fullName.localeCompare(b.fullName)
              break
            case ADMIN_LIST_SORT_TYPE.SEX:
              comparison = a.sex.localeCompare(b.sex)
              break
            case ADMIN_LIST_SORT_TYPE.DEPARTMENT:
              comparison = a.departments.localeCompare(b.departments)
              break
            case ADMIN_LIST_SORT_TYPE.ASSIGNMENT:
              comparison = a.assignments.localeCompare(b.assignments)
              break
            case ADMIN_LIST_SORT_TYPE.CREATED_TIMESTAMP:
              comparison = a.createdTimestamp - b.createdTimestamp
              break
            default:
              console.error('Invalid sortBy value')
              break
          }

          return search.orderBy === 'desc' ? -comparison : comparison
        })
      }

      setDataShow(dataList)
    } catch (error) {
      setError((error as Error).message)
    }

    setLoading(false)
  }

  const handleDataAdmin = async (response: any) => {
    const data = []

    if (!Array.isArray(response)) response = []

    const tenant: any = await getTenant({
      filter: `(eq,STRING,code,${currentTenant?.tenant})`
    })

    let departmentOptions: any = []
    let assignmentOptions: any = []
    let positionOptions: any = []

    let departments = tenant?.additionalInfo?.departments
    if (departments) {
      departments = JSON.parse(departments)
      if (!Array.isArray(departments)) departments = []
      departments = departments.map((item: any) => {
        if (!item?.key || !item?.value) return null
        return {
          key: item.key,
          value: item.value,
          label: item.value
        }
      })

      departmentOptions = departments.filter((item: any) => item !== null)
      setDepartmentOptions(departmentOptions)
    }

    let assignments = tenant?.additionalInfo?.assignments
    if (assignments) {
      assignments = JSON.parse(assignments)
      if (!Array.isArray(assignments)) assignments = []
      assignments = assignments.map((item: any) => {
        if (!item?.key || !item?.value) return null
        return {
          key: item.key,
          value: item.value,
          label: item.value
        }
      })

      assignmentOptions = assignments.filter((item: any) => item !== null)
      setAssignmentOptions(assignmentOptions)
    }

    let positions = tenant?.additionalInfo?.positions
    if (positions) {
      positions = JSON.parse(positions)
      if (!Array.isArray(positions)) positions = []
      positions = positions.map((item: any) => {
        if (!item?.key || !item?.value) return null
        return {
          key: item.key,
          value: item.value,
          label: item.value
        }
      })

      positionOptions = positions.filter((item: any) => item !== null)
      setPositionOptions(positionOptions)
    }

    for (const admin of response) {
      data.push({
        key: admin?.id,
        email: admin?.email,
        fullName: (admin?.firstName + ' ' + admin?.lastName).trim(),
        sex: getLabelByValue(admin.attributes?.sex?.[0], genderOptions),
        roles: await getRoleByUserId(admin?.id),
        departments: getLabelByValue(
          admin.attributes?.departments,
          departmentOptions,
          true
        ),
        assignments: getLabelByValue(
          admin.attributes?.assignments,
          assignmentOptions,
          true
        ),
        positions: getLabelByValue(
          admin.attributes?.positions,
          positionOptions,
          true
        ),
        createdTimestamp: admin?.createdTimestamp,
        currentOwner: checkIsTrue(admin.attributes?.isOwner?.[0])
      })
    }

    return data
  }

  const handleDeleteAdmin = async (id: string) => {
    try {
      const sub = decodedToken(getAccessToken() ?? null)

      const logout = await logoutKeycloak(id)
      if (isErrorKeycloak(logout)) {
        return false
      }

      const deleteAdmin = await deleteAccountKeycloak(id)
      if (isErrorKeycloak(deleteAdmin)) {
        return false
      }

      if (sub === id) {
        localStorage.clear()
      }
    } catch (error) {
      setError((error as Error).message)
    }

    return true
  }

  // const getDataSex = (data: any) => {
  //   const sex = data?.attributes?.sex?.[0]
  //   let result = ''
  //   if (sex === 'MALE') result = 'lable.male'
  //   else if (sex === 'FEMALE') result = 'lable.female'
  //
  //   return result
  // }

  const getRoleByUserId = async (id: any) => {
    try {
      const response = await networkAdapter
        .getClient(`${API_USER}/${id}/role-mappings`)
        .then((response: any) => {
          return response
        })

      const mappingRole =
        response?.clientMappings?.[KEYCLOAK_CLIENT_ID ?? '']?.mappings
      if (!mappingRole || !Array.isArray(mappingRole)) return []

      const dataRoles = mappingRole
        .map((item: IRoles) => {
          if (item.name === EXCLUDED_NAME) return null
          const lang = item.description ? JSON.parse(item.description) : {}
          return {
            id: item.id,
            name: item.name,
            label: ' ',
            lang: Object.fromEntries(
              Object.entries(lang).map(([key, value]: [string, any]) => [
                key,
                value?.[0]
              ])
            )
          }
        })
        .filter((item: StringKeyObject | null) => item !== null)

      return dataRoles
    } catch (error) {
      setError((error as Error).message)
    }
  }

  // const getAffiliationByUserId = async (id: any) => {
  //   try {
  //     const response = await networkAdapter
  //       .getClient(`${API_USER}/${id}/groups`)
  //       .then((response: any) => {
  //         return response
  //       })
  //
  //     let data = []
  //     for (let i = 0; i < response.length; i++) {
  //       data.push(response?.[i]?.name)
  //     }
  //
  //     return data.join(',')
  //   } catch (error) {
  //     setError((error as Error).message)
  //   }
  // }

  const getTenant = async (variables: any) => {
    try {
      const response = await loadTenantList({
        variables: {
          filter: '',
          sortBy: '(desc,_id)',
          page: 0,
          size: 1,
          ...variables
        },
        fetchPolicy: 'network-only'
      })
      return response?.data?.filterTenant?.payload?.[0]
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    error,
    admins,
    onClearError,
    handleSearchData,
    handleDeleteAdmin,
    setFirstCall,
    getTenant,
    dataShow,
    departmentOptions,
    assignmentOptions,
    positionOptions
  }
}

export default useAdmins
