import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useRecoilState } from 'recoil'

import { flowDescription, questions, stepEnd } from '../../../../data/data'
import PlusActive from '../../../buttons/PlusActive'
import LastStep from '../firstStep/FirstStep'
import FixedQuestion from './FixedQuestion'
import {
  DRAWER_TYPE,
  FIXED_TEXT_TYPE,
  MODAL_TYPE,
  STEP_SCENARIO
} from 'configs/constant'
import useCreateScenario from 'hooks/scenario/useCreateScenario'
import useModal from 'hooks/scenario/useModal'
import { scenarioCreateAtom } from 'pages/Scenario/ScenarioCreate/atoms/create/scenarioCreateAtom'
import { Flow, Option } from 'pages/Scenario/types/types'

const Divider = () => <div className="w-4 inline-block h-[2px] bg-gray-200" />

export interface FixedQuestionsProps {
  type?: string
  selectionType?: string
  choiceType?: string | null
  options?: Option[]
}

function FixedQuestions({ genderBtns }: { readonly genderBtns?: Option[] }) {
  const [scenarioCreate, setScenarioCreate] = useRecoilState(scenarioCreateAtom)
  const [, setSearchParams] = useSearchParams()
  const { onCreateBlock } = useCreateScenario()
  const { modal, onCloseModal, onOpenModal, onConfirmModal } =
    useModal(setScenarioCreate)

  const propsBlock = {
    type: FIXED_TEXT_TYPE.LAST_STEP,
    isOpenRemoveBlockModal: modal.deleteBlock,
    onOpenRemoveBlockModal: () => onOpenModal(MODAL_TYPE.DELETE_BLOCK),
    onCloseRemoveBlockModal: () => onCloseModal(MODAL_TYPE.DELETE_BLOCK),
    onConfirmRemoveBlockModal: () =>
      onConfirmModal(MODAL_TYPE.DELETE_BLOCK, STEP_SCENARIO.LAST_STEP),
    isOpenDeletedBlockModal: modal.deletedBlock,
    onOpenDeletedBlockModal: () => onOpenModal(MODAL_TYPE.DELETED_BLOCK),
    onConfirmDeletedBlockModal: () =>
      onConfirmModal(MODAL_TYPE.DELETED_BLOCK, STEP_SCENARIO.LAST_STEP),
    questions: [],
    title: stepEnd.step,
    description:
      scenarioCreate?.flows?.[scenarioCreate?.flows?.length - 1]?.content,
    onOpenDrawer: () => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev)
        params.set('type', String(DRAWER_TYPE.LAST_STEP))
        return params
      })
    }
  }

  const isActivated =
    scenarioCreate.flows[STEP_SCENARIO.LAST_STEP]?.isActivated ?? false

  const fixedQuestions = useMemo(
    () =>
      scenarioCreate?.flows.filter(
        (flow) => flow.type === 'fixed' && flow.isActivated
      ),
    [scenarioCreate?.flows]
  )

  const handleCreateBlock = () => {
    if (isActivated) return

    onCreateBlock([STEP_SCENARIO.LAST_STEP])
  }

  return (
    <div className="relative flex items-center">
      {genderBtns?.[0].isActivated &&
        fixedQuestions?.map((flow: Flow, index) => {
          return (
            <div className="relative flex items-center" key={flow.order}>
              <FixedQuestion
                step={questions[flow.order - 1].step}
                description={
                  flow.description ?? flowDescription[flow.order.toString()]
                }
              />
              {index < fixedQuestions.length - 1 && <Divider />}
              {index === fixedQuestions.length - 1 && (
                <div className="w-[20px] h-[20px] translate-x-[-10px]">
                  <PlusActive onClick={handleCreateBlock} />
                </div>
              )}
            </div>
          )
        })}

      {/* last step */}
      {isActivated && (
        <div className="flex items-center">
          {genderBtns?.[0].isActivated && (
            <div className="-ml-2 -mt-1">
              <Divider />
            </div>
          )}
          <div className="absolute right-[-320px] -top-4 mr-10 flex items-center">
            <div className="w-[280px]">
              <LastStep {...propsBlock} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(FixedQuestions)
