import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { ITEM_START_NAME, JUDGMENT } from '../SetupThresholdAndCriteria'
import AddItemReferenceValue from './AddItemReferenceValue'
import { IListReferenceValue } from './ItemMaster'
import { ReactComponent as RemoveIcon } from 'assets/svgs/remove-circle.svg'
import { InputForm } from 'components/elements/InputForm'
import { SelectCustom } from 'components/elements/Select'
import { ConditionalNumber, GENDER, typeReferenceValue } from 'configs/constant'
import { ItemMasterWithMultipleLang } from 'types/ItemMasters'
import { StringKeyObject } from 'types/common'

export interface IReferenceValueType {
  id: string
  type: typeReferenceValue
  gender?: string
  isDeleted?: boolean
  [key: string]: any
}

export interface IReferenceValueWithParent extends IReferenceValueType {
  parentIndex: number
}

type Props = {
  itemMaster: ItemMasterWithMultipleLang
  judgementId: string
  gender?: GENDER
  listReferenceValue: IReferenceValueWithParent[]
  setListReferenceValue: React.Dispatch<
    React.SetStateAction<IListReferenceValue>
  >
}

const optionsConditionalNumber = [
  { label: 'lable.above', value: ConditionalNumber.GE },
  { label: 'lable.comeDown', value: ConditionalNumber.LE }
]

const ItemReferenceValue: React.FC<Props> = ({
  itemMaster,
  judgementId,
  gender,
  listReferenceValue,
  setListReferenceValue
}) => {
  const { unregister } = useFormContext()
  const nameInput = `${JUDGMENT}.${judgementId}.${ITEM_START_NAME + itemMaster.refId}`

  const handleRemoveItem = (id: string, idx: number) => {
    unregister(`${nameInput}.${idx}`)
    setListReferenceValue((prev: IListReferenceValue) => {
      if (!prev[judgementId]) return prev

      return {
        ...prev,
        [judgementId]: prev[judgementId].map((item: IReferenceValueType) => {
          if (item.id === id) {
            return { ...item, isDeleted: true }
          }

          return item
        })
      }
    })
  }

  const renderInput = (item: IReferenceValueType, idx: number) => {
    switch (item.type) {
      case typeReferenceValue.STRING:
        return (
          <InputForm
            name={`${nameInput}.${idx}.text`}
            className="w-[250px]"
            defaultValue={item?.text}
          />
        )
      case typeReferenceValue.CONDITIONAL_NUMBER:
        return (
          <>
            <InputForm
              type="number"
              name={`${nameInput}.${idx}.value`}
              className="w-[120px]"
              defaultValue={item?.value}
            />
            <SelectCustom
              name={`${nameInput}.${idx}.select`}
              className="w-[75px]"
              options={optionsConditionalNumber}
              defaultValue={item?.select}
            />
          </>
        )
      case typeReferenceValue.RANGE_NUMBER:
        return (
          <>
            <InputForm
              type="number"
              name={`${nameInput}.${idx}.start`}
              className="w-[80px]"
              defaultValue={item?.start}
            />
            <Typography>~</Typography>
            <InputForm
              type="number"
              name={`${nameInput}.${idx}.end`}
              className="w-[80px]"
              defaultValue={item?.end}
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <Flex className="justify-center w-[300px]">
      {listReferenceValue.length > 0 ? (
        <Flex vertical className="gap-3">
          {listReferenceValue.map((item, idx) => (
            <Flex className="items-center gap-[5px]" key={item.id}>
              {gender && (
                <InputForm
                  name={`${nameInput}.${item.parentIndex}.gender`}
                  className="hidden"
                  defaultValue={gender}
                />
              )}
              <InputForm
                name={`${nameInput}.${item.parentIndex}.type`}
                className="hidden"
                defaultValue={item.type}
              />
              <InputForm
                name={`${nameInput}.${item.parentIndex}.id`}
                className="hidden"
                defaultValue={item.id}
              />
              {renderInput(item, item.parentIndex)}
              {idx === listReferenceValue.length - 1 && (
                <AddItemReferenceValue
                  itemMaster={itemMaster}
                  judgementId={judgementId}
                  setListReferenceValue={setListReferenceValue}
                  gender={gender}
                />
              )}
              <RemoveIcon
                className="w-5 cursor-pointer"
                onClick={() => handleRemoveItem(item.id, item.parentIndex)}
              />
            </Flex>
          ))}
        </Flex>
      ) : (
        <AddItemReferenceValue
          itemMaster={itemMaster}
          judgementId={judgementId}
          setListReferenceValue={setListReferenceValue}
          gender={gender}
        />
      )}
    </Flex>
  )
}

export const ItemReferenceValueWithData = ({
  itemMaster,
  judgementId,
  gender,
  data
}: {
  itemMaster: ItemMasterWithMultipleLang
  judgementId: string
  gender?: GENDER
  data: any
}) => {
  const { t } = useTranslation()
  const [listReferenceValue, setListReferenceValue] = useState<
    StringKeyObject[]
  >([])

  useEffect(() => {
    if (!data || !judgementId || !itemMaster.refId) return

    const result =
      data[JUDGMENT]?.[judgementId]?.[ITEM_START_NAME + itemMaster.refId]

    if (Array.isArray(result)) {
      setListReferenceValue(
        result.filter(
          (item) => item && (gender ? item.gender === gender : !item.gender)
        )
      )
    }
  }, [data, gender, judgementId, itemMaster.refId])

  const showReferenceValueNumber = (item: StringKeyObject) => {
    if (item.type === typeReferenceValue.RANGE_NUMBER)
      return item?.start + '-' + item?.end

    return (
      item?.value +
      (item?.select === ConditionalNumber.GE
        ? t('lable.above')
        : t('lable.comeDown'))
    )
  }

  const renderReferenceValue = () => {
    const readingType = itemMaster.readingType
    if (listReferenceValue.length === 0) return null

    if (readingType == 'STRING')
      return (
        <>
          {listReferenceValue.map((item: StringKeyObject, idx: number) => (
            <Typography key={idx}>{item?.text ?? ''}</Typography>
          ))}
        </>
      )

    return (
      <>
        {listReferenceValue.map((item: StringKeyObject, idx: number) => (
          <Typography key={idx}>{showReferenceValueNumber(item)}</Typography>
        ))}
      </>
    )
  }

  return (
    <Flex className="justify-center w-[300px]">
      <Flex vertical className="gap-1">
        {renderReferenceValue()}
      </Flex>
    </Flex>
  )
}

export default ItemReferenceValue
