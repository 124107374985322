import React, { useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Tooltip, Typography } from 'antd'

import TenantInformation from './TenantInformation'
import { ReactComponent as EditIcon } from 'assets/svgs/pen.svg'
import {
  BasicSettingForm,
  SubTenantForm
} from 'components/screens/BasicSetting'
import SaveSuccessModal from 'components/screens/BasicSetting/components/SaveSuccessModal'
import { SubTenantEditModal } from 'components/screens/BasicSetting/components/SubTenantEditModal'
import { openNotification } from 'components/widgets/Notification'
import {
  parseDataUpdate,
  parseFormUpdate,
  useTenantUpdate
} from 'hooks/tenant/useTenantUpdate'
import { useGroupModal } from 'hooks/useUserGroup'
import { DEFAULT_SPECIFIED_DAY_TIMES, WORK_SCHEDULE_TYPE } from 'models/setting'
import { Tenant } from 'types/Tenant'
import { groupByKey } from 'utilities/helpers'

const tenantInitial = {
  name: '',
  kanaName: '',
  clinicName: '',
  clinicKanaName: '',
  zipCode: '',
  province: '',
  district: '',
  address: '',
  workScheduleType: WORK_SCHEDULE_TYPE.fixedDay,
  workdays: [],
  fixedDayStartTime: '09:00',
  fixedDayEndTime: '20:00',
  specifiedDayTimes: DEFAULT_SPECIFIED_DAY_TIMES,
  subTenantList: []
}

export default function BasicInformationSettings({
  tenant,
  setLoad
}: {
  tenant?: Tenant
  setLoad: Function
}) {
  const { t } = useTranslation()

  const { handleUpdate } = useTenantUpdate()
  const { setShowEditModal, setIsModeEdit, setSelectedIndex, showEditModal } =
    useGroupModal()
  const [tenantForm, setTenantForm] = useState<any>(tenantInitial)

  const [isShowModal, setIsShowModal] = useState(false)
  const [edit, setEdit] = useState<boolean>(false)

  const methods = useForm<any>({
    defaultValues: tenantForm
  })

  const { control } = methods
  const { fields: subTenantList, update: updateSubTenant } = useFieldArray({
    control,
    name: 'subTenantList'
  })

  useEffect(() => {
    if (tenant) {
      const data = parseFormUpdate(tenant)
      setTenantForm(data)
      methods.reset(data)
    }
  }, [tenant])

  const onSubmit = async (data: any) => {
    // validate specifiedDayTimes
    const { specifiedDayTimes } = data
    const activatedDayTimes = specifiedDayTimes.filter((d: any) => d.active)
    for (let activatedDay of activatedDayTimes) {
      if (!activatedDay.biweekly) continue
      const weeks = groupByKey(activatedDay.biweekly, 'week')

      for (const duplicateWeek of Object.values(weeks)) {
        if (
          duplicateWeek &&
          Array.isArray(duplicateWeek) &&
          duplicateWeek.length > 1
        ) {
          openNotification({
            type: 'error',
            title: t('commonError'),
            message: t('errors.duplicateBiweeklySettings')
          })
          return
        }
      }
    }

    const tenantData = parseDataUpdate(tenant, {
      ...data,
      subTenantList: subTenantList
    })

    const response: any = await handleUpdate(tenantData)
    if (!response) {
      return
    }

    setIsShowModal(true)
  }

  return (
    <Flex vertical gap={6}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex
            vertical
            className="bg-white rounded-b-md shadow-md shadow-[#00000029] p-2.5 pt-1.5"
          >
            <Flex vertical gap={16}>
              <Flex className="items-center gap-5">
                <Typography className="text-primary font-bold">
                  {t('clinicBasicInfo')}
                </Typography>
                {!edit && (
                  <Tooltip placement="top" title={t('button.edit')}>
                    <EditIcon
                      className="cursor-pointer w-[18px]"
                      onClick={() => setEdit(true)}
                    />
                  </Tooltip>
                )}
              </Flex>
              {edit && (
                <>
                  <Typography className="text-[14px]">
                    {t('basicSetting.basicSettingNote')}
                  </Typography>

                  <BasicSettingForm />
                </>
              )}
              {!edit && <TenantInformation tenantForm={tenantForm} />}
            </Flex>
          </Flex>
        </form>
      </FormProvider>

      <div className="mt-2 bg-[#fff] rounded-[5px] p-2 shadow-md shadow-[#00000029]">
        <Flex vertical gap={8}>
          <Typography className="text-[14px] text-primary font-bold">
            {t('basicSetting.contactSetting')}
          </Typography>

          <Flex justify="space-between" className="mt-2">
            <Typography className="text-[14px]">
              {t('basicSetting.contactSettingNote')}
            </Typography>
            <Button
              type="primary"
              size="middle"
              className="min-w-[100px] rounded-[3px] font-bold"
              onClick={() => {
                setSelectedIndex(undefined)
                setShowEditModal(true)
                setIsModeEdit(false)
              }}
            >
              {t('button.register')}
            </Button>
          </Flex>

          <div className="border-t-[1px] border-[#f0f0f0]">
            <SubTenantForm
              update={updateSubTenant}
              subTenantList={subTenantList}
            />
          </div>
        </Flex>
      </div>

      {showEditModal && (
        <SubTenantEditModal
          subTenantList={subTenantList}
          update={updateSubTenant}
          onCancel={() => setShowEditModal(false)}
        />
      )}

      {isShowModal && (
        <SaveSuccessModal
          onCancel={() => {
            setEdit(false)
            setIsShowModal(false)
            setLoad((prev: number) => prev + 1)
          }}
          isModalOpen={isShowModal}
        />
      )}

      <div className="mt-5">
        <Button
          autoInsertSpace={false}
          type="primary"
          htmlType="submit"
          className="w-[180px] font-bold"
          onClick={methods.handleSubmit(onSubmit)}
        >
          {t('button.keep')}
        </Button>
      </div>
    </Flex>
  )
}
