import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { StringKeyObject } from 'types/common'

export default function GeneralComment({
  dataShowMedicalCheckups
}: {
  dataShowMedicalCheckups: StringKeyObject[]
}) {
  const { t } = useTranslation()
  return (
    <Flex className="border-b border-[#BFC6CB]">
      <Flex className="w-[25%] px-5 py-4 border-left-between items-center">
        <span className="font-bold">{t('testResult.generalComment')}</span>
      </Flex>
      {dataShowMedicalCheckups.map((item: StringKeyObject) => (
        <div key={item.refId} className="w-[25%] border-left-between p-2.5">
          <Typography className="text-wrap">{item.generalComments}</Typography>
        </div>
      ))}
    </Flex>
  )
}
