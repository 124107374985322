import React, { FC } from 'react'

import { Flex, Typography } from 'antd'

type Props = {
  label: string
}

const InputLabel: FC<Props> = ({ label }) => {
  return (
    <Flex
      align="center"
      className="h-auto min-h-[30px] w-[180px] self-stretch bg-[#F0F3F7] px-[9px] flex-shrink-0"
    >
      <Typography className="text-[15px] font-bold tracking-[0px] text-[#707070]">
        {label}
      </Typography>
    </Flex>
  )
}

export default InputLabel
