import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'antd'

import Content from './components/Content'

export default function ProductDuplication() {
  const { t } = useTranslation()

  return (
    <div className="min-w-[1300px] w-full text-[14px] text-[#545454]">
      <Typography.Text className="text-[16px] font-bold tracking-[1.6px]">
        {t('productSetting.productSetting')}
      </Typography.Text>

      <div className="mt-5">
        <Content />
      </div>
    </div>
  )
}
