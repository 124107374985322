import { Button, Collapse, Flex, Typography } from 'antd'
import { t } from 'i18next'

import { IAdminRegister } from '../AdminRegister'
import AdminRegisterForm from './AdminRegisterForm'
import InformationConfirmationBlock from './InformationConfirmationBlock'
import { ReactComponent as ArrowMenu } from 'assets/svgs/arrow-menu.svg'
import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import { IAdmin } from 'types/Admin'
import { Options } from 'types/common'

export default function ShowAdminsRegistrationError({
  genderOptions,
  roleOptions,
  departmentOptions,
  assignmentOptions,
  positionOptions,
  adminOptions,
  dataErrors,
  dataSuccess,
  user
}: {
  genderOptions: Options[]
  roleOptions: Options[]
  departmentOptions: Options[]
  assignmentOptions: Options[]
  positionOptions: Options[]
  adminOptions: Options[]
  dataErrors: IAdminRegister[] | null
  dataSuccess: IAdminRegister[] | null
  user: IAdmin | null
}) {
  return (
    <div>
      <Typography className="text-base font-bold tracking-[1.6px]">
        {user ? t('editStaffInfo') : t('staffRegistration')}
      </Typography>
      <Collapse
        expandIcon={({ isActive }) => (
          <ArrowMenu className={`${isActive ? '' : 'rotate-[180deg]'}`} />
        )}
        bordered={false}
        className="bg-white mt-3 border-0 rounded-md collapse-custom-admin-registration"
        items={[
          {
            key: '1',
            label: t('content.displayRegisteredStaff'),
            children: (
              <div>
                {dataSuccess?.map((item: IAdminRegister) => (
                  <div
                    key={item.key}
                    className="item-collapse-admin-registration"
                  >
                    <InformationConfirmationBlock
                      item={item}
                      assignmentOptions={assignmentOptions}
                      roleOptions={roleOptions}
                      departmentOptions={departmentOptions}
                      positionOptions={positionOptions}
                      genderOptions={genderOptions}
                      adminOptions={adminOptions}
                    />
                  </div>
                ))}
              </div>
            )
          }
        ]}
      />
      <Flex className="gap-1.5 mt-10 ">
        <ExclamationCircleFilled className="w-5 text-error" />
        <Typography>{t('content.staffRegistrationFailed')}</Typography>
      </Flex>
      <Flex
        vertical
        className="bg-white px-2.5 py-3 rounded-md shadow-md mt-3 border border-error"
      >
        {dataErrors?.map((block, index) => (
          <div key={block.key} className="item-collapse-admin-registration">
            <AdminRegisterForm
              index={index}
              assignmentOptions={assignmentOptions}
              roleOptions={roleOptions}
              departmentOptions={departmentOptions}
              positionOptions={positionOptions}
              genderOptions={genderOptions}
              adminOptions={adminOptions}
              user={user}
            />
          </div>
        ))}
      </Flex>

      <Button
        type="primary"
        htmlType="submit"
        autoInsertSpace={false}
        className="rounded-[3px] min-w-[180px] font-bold mt-6"
      >
        {t('button.registration')}
      </Button>
    </div>
  )
}
