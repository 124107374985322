import { Flex } from 'antd'

import { RowInfoProps } from '../types'
import cn from 'utilities/cn'

const RowInfo = ({ title, value }: RowInfoProps) => {
  return (
    <Flex className="min-h-9">
      <span
        className={cn('min-w-40 text-sm text-default font-bold p-2', {
          'bg-background2': title
        })}
      >
        {title}
      </span>
      <span className="text-sm text-default p-2">{value}</span>
    </Flex>
  )
}

export default RowInfo
