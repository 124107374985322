import { DocumentNode, gql } from '@apollo/client'

export const FILTER_AVAILABLE_SLOTS_BY_WEEK: DocumentNode = gql`
  query FilterAvailableSlotsByWeekQuery($medicalCheckupMasterRefId: String!) {
    filterAvailableSlotsByWeek(
      medicalCheckupMasterRefId: $medicalCheckupMasterRefId
    ) {
      payload {
        refId
        emptySlotCount
        datetimeRule {
          refId
          byday
          time
          rule
        }
      }
    }
  }
`
