import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

const DraggableWrapper = ({
  draggableId,
  index,
  children
}: {
  draggableId: string
  index: number
  children: React.ReactNode
}) => {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
}

export default DraggableWrapper
