import {
  API_LOGIN_V1,
  API_LOGIN_V2,
  API_USER,
  KEYCLOAK_URL
} from '../configs/api'
import networkAdapter from '../utilities/networkAdapter'
import { STORAGE_TOKEN_CLIENT_KEY } from 'configs/constant'

export interface LoginApiProps {
  email: string
  password: string
  isLoginParamsTenant: boolean
}

export const loginApi = async (body: LoginApiProps) => {
  const credential = {
    email: body.email,
    password: body.password
  }

  return await networkAdapter.post(
    body.isLoginParamsTenant ? API_LOGIN_V1 : API_LOGIN_V2,
    JSON.stringify(credential)
  )
}

export const logoutKeycloak = async (userId: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}/logout`
  return networkAdapter.postClient(url, null)
}

export const deleteAccountKeycloak = async (userId: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}`
  return networkAdapter.deleteClient(url)
}

export const userInfoUpdate = async (userId: any, data: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}`
  const token = localStorage.getItem(STORAGE_TOKEN_CLIENT_KEY)

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')
  headers.append('Authorization', `Bearer ${token}`)

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  }

  try {
    return await fetch(url, requestOptions)
  } catch (error) {
    return console.error(error)
  }
}

export const getUserInfo = async (userId: string) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}`
  return networkAdapter.getClient(url)
}

export const resetPassword = async (userId: string, password: string) => {
  await networkAdapter.putClient(`${API_USER}/${userId}/reset-password`, {
    temporary: false,
    type: 'password',
    value: password
  })
}
