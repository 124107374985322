import React, { FC, memo, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import SelectPhoneNumber from '../../CustomerDetail/components/SelectPhoneNumber'
import { SearchData } from '../ReservationRegister'
import InputLabel from './InputLabel'
import { CustomDatePicker } from 'components/elements/CustomDatePicker'
import { InputForm } from 'components/elements/InputForm'

type Props = {
  onSubmit: (data: SearchData) => void
}

const displayDateFormat = 'YYYY/MM/DD'
const datePickerFormat = 'YYYY/M/D'
const defaultDialCodePhone = '81-Japan'

const RegisterForm: FC<Props> = memo(({ onSubmit }) => {
  const { t } = useTranslation()
  const { state } = useLocation()

  const { courseName, remainedSlots, date, time } = state || {}

  const defaultValues = {
    dialCodePhone: defaultDialCodePhone
  }

  const methods = useForm<SearchData>({
    defaultValues
  })

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '')
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(methods.getValues())
  }

  const dateFormat = useMemo(
    () =>
      `${dayjs(date).format(displayDateFormat)}（${dayjs(date).format('ddd')}）`,
    [date]
  )

  return (
    <div className="mb-5 mt-[6px] rounded-[5px] bg-[#FFFFFF] px-[10px] pb-[22px] pt-[10px] drop-shadow-md">
      <div>
        <Flex align="center">
          <InputLabel label={t('reservationRegister.label.courseName')} />
          <Typography.Text className="ml-[14px]">{courseName}</Typography.Text>
        </Flex>

        <Flex align="center" className="mt-[6px]">
          <InputLabel label={t('reservationRegister.label.date')} />
          <Typography.Text className="ml-[14px]">{dateFormat}</Typography.Text>
        </Flex>

        <Flex align="center" className="mt-[6px]">
          <InputLabel label={t('reservationRegister.label.time')} />
          <Typography.Text className="ml-[14px]">{time}</Typography.Text>
        </Flex>

        <Flex align="center" className="mt-[6px]">
          <InputLabel label={t('reservationRegister.label.remainedSlots')} />
          <Typography.Text className="ml-[14px]">
            {remainedSlots}
          </Typography.Text>
        </Flex>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <Flex align="center" className="mt-[6px]">
            <InputLabel label={t('reservationRegister.label.id')} />
            <InputForm
              rules={{ maxLength: 50 }}
              name="refId"
              className="ml-2 h-[30px] w-[400px] px-3"
            />
          </Flex>

          <Flex className="mt-[6px]">
            <Flex align="center">
              <InputLabel label={t('reservationRegister.label.firstName')} />

              <div className="ml-2">
                <InputForm
                  rules={{ maxLength: 30 }}
                  name="firstName"
                  className="h-[30px] w-[400px] px-3"
                />
                <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                  {t('customerRegister.notice.enterEnglish')}
                </Typography>
              </div>
            </Flex>

            <Flex align="center" className="ml-5">
              <InputLabel label={t('reservationRegister.label.lastName')} />

              <div className="ml-2">
                <InputForm
                  rules={{ maxLength: 30 }}
                  name="lastName"
                  className="h-[30px] w-[400px] px-3"
                />
                <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                  {t('customerRegister.notice.enterEnglish')}
                </Typography>
              </div>
            </Flex>
          </Flex>

          <Flex className="mt-[6px]">
            <Flex align="center">
              <InputLabel
                label={t('reservationRegister.label.firstNameKana')}
              />

              <div className="ml-2">
                <InputForm
                  rules={{ maxLength: 30 }}
                  name="firstNameKana"
                  className="h-[30px] w-[400px] px-3"
                />
                <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                  {t('customerRegister.notice.enterEnglish')}
                </Typography>
              </div>
            </Flex>

            <Flex align="center" className="ml-5">
              <InputLabel label={t('reservationRegister.label.lastNameKana')} />

              <div className="ml-2">
                <InputForm
                  rules={{ maxLength: 30 }}
                  name="lastNameKana"
                  className="h-[30px] w-[400px] px-3"
                />
                <Typography className="mt-1 text-[10px] tracking-[0.5px]">
                  {t('customerRegister.notice.enterEnglish')}
                </Typography>
              </div>
            </Flex>
          </Flex>

          <Flex align="center" className="mt-[6px]">
            <InputLabel label={t('reservationRegister.label.birthday')} />

            <CustomDatePicker
              name="birthday"
              format={datePickerFormat}
              placeholder={t('reservationRegister.placeholder.selectDate')}
              className="ml-2 h-[30px] w-[200px] border-[1px] border-[#137695]"
            />
          </Flex>

          <Flex align="center" className="mt-[6px]">
            <InputLabel label={t('reservationRegister.label.phone')} />

            <div className="ml-2">
              <SelectPhoneNumber name="dialCodePhone" />
            </div>

            <InputForm
              rules={{ maxLength: 15 }}
              onInput={(event) => {
                handleInput(event)
              }}
              name="phone"
              placeholder={t(
                'reservationRegister.placeholder.enterWithoutHyphens'
              )}
              className="ml-2 h-[30px] w-[273px] px-3 tracking-[1.4px]"
            />
          </Flex>

          <Flex justify="center" className="mt-7">
            <Button
              type="primary"
              htmlType="submit"
              autoInsertSpace={false}
              className="h-[30px] min-w-[180px] rounded-[3px] border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
            >
              {t('reservationRegister.button.search')}
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </div>
  )
})

export default RegisterForm
