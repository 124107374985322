import React from 'react'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex } from 'antd'

import { StyledDivError } from '../../../styled'
import { SizeType } from 'antd/es/config-provider/SizeContext'

type Props = {
  className?: string
  defaultValue?: string
  name: string
  options: {
    label: string
    value: number | string
  }[]
  size?: SizeType
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
}

export const CheckboxGroupForm = ({
  className = '',
  name,
  options = [],
  rules
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <StyledDivError status={error ? 'error' : ''}>
          <Checkbox.Group className={className} {...field}>
            <Flex gap={20}>
              {options.map((option: any, index: number) => (
                <Checkbox key={index} value={option.value}>
                  {t(option.label)}
                </Checkbox>
              ))}
            </Flex>
          </Checkbox.Group>
        </StyledDivError>
      )}
    />
  )
}
