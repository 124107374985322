import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { openNotification } from '../../components/widgets/Notification'
import Content from './components/Content'
import Widget from './components/Widget'
import { YearlyReport } from 'models/consultationReport'
import { downloadCSV } from 'utilities/helpers'

export default function YearlyConsultationReport() {
  const { t } = useTranslation()

  const selectedRowsRef = useRef<YearlyReport[]>([])

  const handleRowSelectionChange = useCallback(
    (selectedRows: YearlyReport[]) => {
      selectedRowsRef.current = selectedRows
    },
    []
  )

  const exportSelectedRowsToCSV = useCallback(() => {
    const data = selectedRowsRef.current
    if (data.length === 0) {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.pleaseSelectMonth')
      })
      return null
    }

    const headers = [
      t('basicSetting.formLabel.clinic_name'),
      t('consultationReport.content.year'),
      t('consultationReport.content.month'),
      t('consultationReport.content.quantity')
    ]
    const keys: Array<keyof YearlyReport> = [
      'tenantName',
      'year',
      'month',
      'quantity'
    ]

    const csvData = [
      headers.join(','),
      ...data.map((item) => keys.map((key) => item[key]).join(','))
    ].join('\n')

    downloadCSV(csvData, t('consultationReport.YearlyReport'))
  }, [])

  return (
    <div className="min-h-[400px] w-full min-w-[1500px]">
      <Widget onExportToCSV={exportSelectedRowsToCSV} />
      <Content onRowSelectionChange={handleRowSelectionChange} />
    </div>
  )
}
