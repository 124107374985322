import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import InputLabel from '../InputLabel'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
  initialData: {
    courseId: string | null
    courseName: string | null
    date: string | null
    time: string | null
    remainedSlots: number | null
    fullName: string | null
  }
}

const ConfirmRegisterModal: FC<Props> = ({
  isOpen,
  onOk,
  onCancel,
  initialData
}) => {
  const { t } = useTranslation()
  const { courseName, date, time, remainedSlots, fullName } = initialData || {}

  return (
    <CustomModal
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="min-h-[446px] py-4">
          <div>
            <Typography className="text-center text-[20px] font-bold tracking-[1px]">
              {t('reservationRegister.modal.confirmRegister')}
            </Typography>

            <Typography className="mt-10 text-center text-sm">
              {t('reservationRegister.modal.areYouSure1')}
            </Typography>

            <Typography className="text-center text-sm">
              {t('reservationRegister.modal.areYouSure2')}
            </Typography>

            <div className="mt-12">
              <Flex align="center">
                <InputLabel label={t('reservationRegister.label.courseName')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {courseName}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel label={t('reservationRegister.label.date')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {date}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel label={t('reservationRegister.label.time')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {time}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel
                  label={t('reservationRegister.label.remainedSlots')}
                />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {remainedSlots}
                </Typography.Text>
              </Flex>

              <Flex align="center" className="mt-[6px]">
                <InputLabel label={t('reservationRegister.label.fullName')} />
                <Typography.Text className="ml-[14px] min-w-[300px]">
                  {fullName}
                </Typography.Text>
              </Flex>
            </div>

            <Flex justify="center" className="mt-14">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onOk}
                className="h-[30px] min-w-[180px] rounded-[3px] border-[1px] border-[#137695] bg-[#137695] text-center font-bold text-white shadow-none"
              >
                {t('reservationRegister.modal.register')}
              </Button>

              <Button
                type="default"
                htmlType="button"
                onClick={onCancel}
                className="ml-5 h-[30px] min-w-[180px] rounded-[3px] border-[1px] border-[#137695] bg-white text-center font-bold text-[#137695] shadow-none"
              >
                {t('reservationRegister.modal.cancel')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default ConfirmRegisterModal
