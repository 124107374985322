import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex, List, Popover, Typography } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import { InputForm } from 'components/elements/InputForm'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { showName } from '../helper'
import { DataItemMaster } from '../types/filterItemMaster'

const ItemList = ({
  data,
  showUpdate,
  language,
  showInspectionDescription,
  index,
  onChecked
}: {
  data: DataItemMaster
  showUpdate: boolean
  language: string
  showInspectionDescription: boolean
  index: number
  onChecked: (data: DataItemMaster, checked: boolean) => void
}) => {
  const { t } = useTranslation()

  return (
    <Draggable draggableId={data.refId} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <List.Item className="border border-[#D9E0E5] bg-white min-h-[30px] mt-2.5 !p-0">
            <Flex className="w-full gap-3 items-center">
              <Flex
                className={`flex-1 items-center gap-3 pr-[4px] ${showUpdate ? 'gap-1.5 pl-[9px] pr-[3px]' : ''}`}
              >
                <Checkbox
                  checked={data.isChecked}
                  onChange={(e) => onChecked(data, e.target.checked)}
                  className="ps-1"
                ></Checkbox>
                {!showUpdate && (
                  <Typography className="w-1/2">
                    {showName(data, language) ?? VALUE_WITH_NO_DATA}
                  </Typography>
                )}
                {!showUpdate && showInspectionDescription && (
                  <Typography className="w-1/2">
                    {showName(data, 'en') ?? VALUE_WITH_NO_DATA}
                  </Typography>
                )}
                {showUpdate && (
                  <InputForm
                    name={`item_${data.refId}_${language}`}
                    className="h-[22px] p-1"
                  />
                )}
                {showUpdate && showInspectionDescription && (
                  <InputForm
                    name={`item_${data.refId}_en2`}
                    className="h-[22px] p-1"
                    placeholder={t(
                      'questionnaire.placeholder.englishDisplay'
                    )}
                  />
                )}
              </Flex>

              <Flex className="gap-[3px] items-center mr-[14px]">
                <Popover
                  placement="top"
                  content={
                    <div className="px-1 py-[2px] text-white rounded bg-[#545454]">
                      {t('popover.copy')}
                    </div>
                  }
                  overlayClassName="popover-permission"
                ></Popover>

                <MenuOutlined />
              </Flex>
            </Flex>
          </List.Item>
        </div>
      )}
    </Draggable>
  )
}

export default ItemList
