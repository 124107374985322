import common from './common.json'
import customerManagement from './customerManagement.json'
import inspection from './inspection.json'
import productDuplication from './productDuplication.json'
import productSetting from './productSetting.json'
import questionnaire from './questionnaire.json'
import reservationChange from './reservationChange.json'
import reservationManagement from './reservationManagement.json'
import reservationRegister from './reservationRegister.json'
import prefectures from 'locales/en/prefectures.json'
import basicSetting from 'locales/vi/basicSetting.json'
import chat from 'locales/vi/chat.json'
import consultant from 'locales/vi/consultant.json'
import consultationReport from 'locales/vi/consultationReport.json'
import courseRegister from 'locales/vi/courseRegister.json'
import customerDetail from 'locales/vi/customerDetail.json'
import customerRegister from 'locales/vi/customerRegister.json'
import errors from 'locales/vi/errors.json'
import patientDetail from 'locales/vi/patientDetail.json'
import productManagement from 'locales/vi/productManagement.json'
import questionnaireResultCreate from 'locales/vi/questionnaireResultCreate.json'
import scenarioData from 'locales/vi/scenarioData.json'
import scenarioList from 'locales/vi/scenarioList.json'

const listTranslationJp = {
  ...common,
  ...courseRegister,
  ...{ customerManagement },
  ...{ customerDetail },
  ...{ customerRegister },
  ...{ basicSetting },
  ...{ qrc: questionnaireResultCreate },
  ...{ consultant },
  ...questionnaire,
  ...inspection,
  ...errors,
  ...{ reservationManagement },
  ...{ reservationRegister },
  ...{ productManagement },
  ...{ productSetting },
  ...{ reservationChange },
  ...{ productDuplication },
  ...patientDetail,
  ...scenarioList,
  ...{ consultationReport },
  prefectures,
  ...chat,
  ...scenarioData
}

export default listTranslationJp
