import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Input } from 'antd'
import * as yup from 'yup'

import styles from './PasswordVerifyCode.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { openNotification } from 'components/widgets/Notification'
import { API_PASSWORD_VERIFY_CODE } from 'configs/api'
import { STATUS_CODE_SUCCESS } from 'configs/constant'
import networkAdapter from 'utilities/networkAdapter'

export type InputFormType = {
  code: string
}

const schema = yup
  .object()
  .shape({
    code: yup.string().required()
  })
  .required()

export default function PasswordVerifyCode() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location?.state?.email) navigate('/login')
  }, [location])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<InputFormType>({
    defaultValues: {
      code: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: InputFormType) => {
    const dataSubmit = {
      email: location?.state?.email,
      otp: data.code
    }

    const response = await networkAdapter.post(
      API_PASSWORD_VERIFY_CODE,
      JSON.stringify(dataSubmit)
    )

    if (response?.status === STATUS_CODE_SUCCESS) {
      navigate('/reset-password', {
        state: { email: location?.state?.email, otp: data.code }
      })
    } else {
      openNotification({
        type: 'error',
        title: 'commonError',
        message: ''
      })
    }
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('passwordVerifyCode')}</h3>
      <form
        action=""
        className={styles['form-submit']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles['box-input']}>
          <label htmlFor="input">{t('lable.passwordVerifyCode')}</label>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="input"
                status={errors.code ? 'error' : ''}
                placeholder={t('placeholder.passwordVerifyCode')}
              />
            )}
          />
        </div>
        <button type="submit" className={styles['btn-submit']}>
          {t('send')}
        </button>
      </form>
    </div>
  )
}
