import React, { FC, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputNumber,
  Select,
  Typography
} from 'antd'
import { TFunction } from 'i18next'

import { CaretDownOutlined } from '@ant-design/icons'
import { pdf } from '@react-pdf/renderer'
import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import CustomDateRangePicker from 'components/elements/CustomDateRangePicker'
import { openNotification } from 'components/widgets/Notification'
import {
  ITEM_MASTER_CODE,
  TEMP_ID,
  TEXT_CATEGORY_MASTER,
  TEXT_COMMENTS,
  typeReferenceValue
} from 'configs/constant'
import { useTenantList } from 'hooks/tenant/useTenantList'
import useMedicalCheckup from 'hooks/useMedicalCheckup'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import useReferenceValue from 'hooks/useReferenceValue'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMaster from 'hooks/useTestItemMaster'
import useTestResult from 'hooks/useTestResult'
import { GENDER } from 'models/customer'
import ResultPreviewPdf from 'pages/CustomerDetail/components/modals/exportPdf/ResultPreviewPdf'
import { StringKeyObject } from 'types/common'
import { isNumber, mergeItemMaster } from 'utilities/helpers'

type InputForm = {
  fullName: string
  startDate: string
  endDate: string
  doctorName: string | undefined
  gender: string | undefined
  startAge: number | undefined
  endAge: number
}

type Props = {
  t: TFunction<'translation', undefined>
  selectOptions: { key: string; value: string; label: string }[]
  listIdSelected: any
  queryParams: URLSearchParams
  setIsExportData: any
  downloadCSV: () => void
}

const { Option } = Select

const Widget: FC<Props> = ({
  t,
  selectOptions,
  queryParams,
  listIdSelected,
  setIsExportData,
  downloadCSV
}) => {
  const location = useLocation()
  const { getMedicalCheckupMasterWithVariables } = useMedicalCheckupMaster()
  const { getListPeriodConfigsByIds } = useReferenceValue()
  const { getTestResultWithVariables } = useTestResult()
  const { getItemCategories } = useTestItemCategory()

  const { getMedicalCheckupList } = useMedicalCheckup()
  const { getListItemMasterByMultipleLanguages, getItemMasterWithVariables } =
    useTestItemMaster()

  const { tenant } = useTenantList()
  const methods = useForm<InputForm>({
    defaultValues: {
      fullName: queryParams.get('fullName') || '',
      startAge: queryParams.get('startAge')
        ? Number(queryParams.get('startAge')) || 0
        : undefined,
      endAge: Number(queryParams.get('endAge')) || undefined,
      gender: queryParams.get('gender') || undefined,
      doctorName: queryParams.get('doctorName') || undefined
    }
  })

  const startDateParam = queryParams.get('startDate')
  const endDateParam = queryParams.get('endDate')

  const navigate = useNavigate()

  const [selectedGender, setSelectedGender] = useState<GENDER[]>([])
  const [dates, setDates] = useState<[string, string]>()

  useEffect(() => {
    if (
      queryParams.get('gender') === GENDER.Male ||
      queryParams.get('gender') === GENDER.Female
    ) {
      setSelectedGender([queryParams.get('gender') as GENDER])
    }
  }, [])

  useEffect(() => {
    const startDate = queryParams.get('startDate') || undefined
    const endDate = queryParams.get('endDate') || undefined

    const params = Array.from(queryParams.keys())
    const isOnlyPageAndSize =
      params.length === 2 && params.includes('page') && params.includes('size')

    if (isOnlyPageAndSize) {
      methods.reset({
        fullName: '',
        startAge: undefined,
        endAge: undefined,
        gender: undefined,
        doctorName: ''
      })
      setSelectedGender([])
    } else {
      methods.reset({
        fullName: queryParams.get('fullName') || '',
        startDate: queryParams.get('startDate') || '',
        endDate: queryParams.get('endDate') || '',
        doctorName: queryParams.get('doctorName') || undefined,
        gender: queryParams.get('gender') || undefined,
        startAge: queryParams.get('startAge')
          ? Number(queryParams.get('startAge')) || 0
          : undefined,
        endAge: queryParams.get('endAge')
          ? Number(queryParams.get('endAge'))
          : undefined
      })

      if (
        queryParams.get('gender') === GENDER.Male ||
        queryParams.get('gender') === GENDER.Female
      ) {
        setSelectedGender([queryParams.get('gender') as GENDER])
      } else {
        setSelectedGender([])
      }

      if (startDate && endDate) {
        setDates([`${startDate}`, `${endDate}`])
      }
    }
  }, [location.search])

  const handleSubmit = () => {
    const values = methods.getValues()

    if (values.fullName) {
      queryParams.set('fullName', values.fullName)
    } else {
      queryParams.delete('fullName')
    }

    if (isNumber(values.startAge)) {
      queryParams.set('startAge', `${values.startAge}`)
    } else {
      queryParams.delete('startAge')
    }

    if (isNumber(values.endAge)) {
      queryParams.set('endAge', `${values.endAge}`)
    } else {
      queryParams.delete('endAge')
    }

    if (values.gender && values.gender.length) {
      queryParams.set('gender', values.gender)
    } else {
      queryParams.delete('gender')
    }

    if (values.doctorName) {
      queryParams.set('doctorName', values.doctorName)
    } else {
      queryParams.delete('doctorName')
    }

    if (dates) {
      queryParams.set('startDate', dates[0])
      queryParams.set('endDate', dates[1])
    } else {
      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    queryParams.set('page', '1')
    navigate(`/customer-management?${queryParams.toString()}`)
  }

  const checkboxOptions = [
    { label: t('customerDetail.header.male'), value: GENDER.Male },
    { label: t('customerDetail.header.female'), value: GENDER.Female }
  ]

  const handleCheckboxChange = (checkedValues: GENDER[]) => {
    if (checkedValues.length > 1) {
      const newCheckedValue = checkedValues.find(
        (value) => !selectedGender.includes(value)
      )
      if (newCheckedValue) {
        setSelectedGender([newCheckedValue])
        methods.setValue('gender', newCheckedValue)
      }
    } else {
      setSelectedGender(checkedValues)
      methods.setValue('gender', checkedValues[0])
    }
  }

  const handleSelectChange = (value: string | undefined) => {
    methods.setValue('doctorName', value)
  }

  const handleExportPdf = async (selectedRows: StringKeyObject[]) => {
    setIsExportData(true)

    if (selectedRows && selectedRows.length > 0) {
      const fetchData = async () => {
        const patientDetailIds: string[] = selectedRows.map(
          (item: StringKeyObject) => item.id
        )

        const filterMedicalCheckup = await getMedicalCheckupList({
          filter: `(in,STRING,checkupUserRef,${patientDetailIds});(ne,STRING,additionalInfo.status,DELETED);(eq,STRING,status,PUBLISHED)`,
          page: 0,
          size: -1,
          sortBy: '(desc,createdDate)'
        })
        const medicalCheckupPayload =
          filterMedicalCheckup?.data?.filterMedicalCheckupDetails?.payload ?? []
        const dataMedicalCheckup = medicalCheckupPayload.map(
          (item: StringKeyObject) => item.medicalCheckup
        )

        const patientNoMedicalCheckup: string[] = []
        selectedRows.forEach((patientDetail) => {
          const checkDataMedicalCheckup = dataMedicalCheckup.some(
            (item: StringKeyObject) => item.checkupUserRef === patientDetail?.id
          )
          if (!checkDataMedicalCheckup) {
            patientNoMedicalCheckup.push(patientDetail?.name)
          }
        })

        if (patientNoMedicalCheckup.length > 0) {
          const message = patientNoMedicalCheckup.join('; ')
          openNotification({
            type: 'error',
            title: t('errors.errorOccurred'),
            message: t('errors.patientNoMedicalCheckup') + message
          })

          return
        }

        const dataMedicalCheckupExport: StringKeyObject[] = []
        const userObj: StringKeyObject = {}

        selectedRows.forEach((patientDetail) => {
          userObj[patientDetail.id] = patientDetail
          const medicalCheckupList = dataMedicalCheckup
            .filter(
              (medicalCheckup: StringKeyObject) =>
                patientDetail.id === medicalCheckup.checkupUserRef
            )
            .slice(0, 3)

          dataMedicalCheckupExport.push(...medicalCheckupList)
        })

        // Get fixed master item list
        const fixedDataItemMaster = await getFixedDataItemMaster()

        const setMedicalCheckupRefIds = new Set<string>()
        const setMedicalCheckupMasterRefIds = new Set<string>()
        const setMedicalCheckupMasterObjIds = new Set<string>()
        const setPeriodConfigObjIds = new Set<string>()

        dataMedicalCheckupExport.forEach((item: StringKeyObject) => {
          if (item.refId) setMedicalCheckupRefIds.add(item.refId)
          if (item.medicalCheckupMasterRef)
            setMedicalCheckupMasterRefIds.add(item.medicalCheckupMasterRef)
          if (item.additionalInfo?.medicalCheckupMasterObjId)
            setMedicalCheckupMasterObjIds.add(
              item.additionalInfo.medicalCheckupMasterObjId
            )
          if (item.additionalInfo?.periodConfigObjId)
            setPeriodConfigObjIds.add(item.additionalInfo.periodConfigObjId)
        })

        const medicalCheckupRefIds = Array.from(setMedicalCheckupRefIds)
        const medicalCheckupMasterRefIds = Array.from(
          setMedicalCheckupMasterRefIds
        )
        const medicalCheckupMasterObjIds = Array.from(
          setMedicalCheckupMasterObjIds
        )
        const periodConfigObjIds = Array.from(setPeriodConfigObjIds)

        // Get medical checkup master list
        const filterMedicalCheckupMasters =
          (await getMedicalCheckupMasterWithVariables([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: medicalCheckupMasterRefIds
                  },
                  {
                    field: 'additionalInfo.objId',
                    operator: 'in',
                    value:
                      medicalCheckupMasterRefIds.length ===
                      medicalCheckupMasterObjIds.length
                        ? medicalCheckupMasterObjIds
                        : [...medicalCheckupMasterObjIds, null]
                  }
                ]
              }
            },
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'additionalInfo.objId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                medicalCheckupMasterObjId: '$_id'
              }
            }
          ])) ?? []

        const itemMasterIds: string[] = []
        const categoryIds: string[] = []
        const temporaryId = TEMP_ID
        const medicalCheckupMasterObj = filterMedicalCheckupMasters.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            const data = item?.data ?? []
            if (item?.medicalCheckupMasterObjId) {
              acc[item.medicalCheckupMasterObjId] = data[0] ?? {}
            } else {
              acc[temporaryId] = data
            }

            for (const dataItem of data) {
              const categoryStructure =
                dataItem?.additionalInfo?.categories ?? []
              categoryStructure.forEach((category: StringKeyObject) => {
                if (Array.isArray(category?.items)) {
                  itemMasterIds.push(
                    ...category.items.map((item: StringKeyObject) => item.refId)
                  )
                }
                categoryIds.push(category.refId)
              })
            }
            return acc
          },
          {}
        )

        // Get period config list
        const filterPeriodConfigs =
          await getListPeriodConfigsByIds(periodConfigObjIds)

        const periodConfigObj = filterPeriodConfigs.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            if (item?.additionalInfo?.objId) {
              acc[item?.additionalInfo?.objId] = item
            }
            return acc
          },
          {}
        )

        // Get test result list
        const filterTestResults =
          (await getTestResultWithVariables([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'medicalCheckupRefId',
                    operator: 'in',
                    value: medicalCheckupRefIds
                  },
                  {
                    field: 'status',
                    operator: 'eq',
                    value: 'PUBLISHED'
                  }
                ]
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'medicalCheckupRefId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                medicalCheckupRefId: '$_id'
              }
            }
          ])) ?? []

        const testResultObj = filterTestResults.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            const dataItem = item?.data ?? []

            acc[item?.medicalCheckupRefId] = dataItem.reduce(
              (acc2: StringKeyObject, item2: StringKeyObject) => {
                if (item2?.itemMasterRefId) acc2[item2.itemMasterRefId] = item2

                return acc2
              },
              {}
            )
            return acc
          },
          {}
        )

        // Get category list
        const filterCategories =
          (await getItemCategories([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: Array.from(new Set(categoryIds))
                  }
                ]
              }
            }
          ])) ?? []

        const itemMasterIdsCategory: string[] = []
        const categoryObj: StringKeyObject = {}
        filterCategories.forEach((category: StringKeyObject) => {
          categoryObj[category.refId] = category

          if (Array.isArray(category?.associatedTestItemMasterRefIds)) {
            itemMasterIdsCategory.push(
              ...category.associatedTestItemMasterRefIds
            )
          }
        })

        // Get test item master list
        const filterTestItemMasters = await getItemMasterWithVariables([
          {
            type: 'MATCH',
            criteria: {
              and: [
                {
                  field: 'refId',
                  operator: 'in',
                  value: Array.from(
                    new Set([...itemMasterIds, ...itemMasterIdsCategory])
                  )
                },
                {
                  field: 'additionalInfo.medicalCheckupMasterObjId',
                  operator: 'in',
                  value:
                    medicalCheckupMasterRefIds.length ===
                    medicalCheckupMasterObjIds.length
                      ? medicalCheckupMasterObjIds
                      : [...medicalCheckupMasterObjIds, null]
                }
              ]
            }
          },
          {
            type: 'SORT',
            criteria: {
              field: '_id',
              direction: 'desc'
            }
          },
          {
            type: 'ADD_FIELDS',
            criteria: {
              lang: '$additionalInfo.localizedName'
            }
          },
          {
            type: 'GROUP',
            criteria: {
              field: 'additionalInfo.medicalCheckupMasterObjId'
            }
          },
          {
            type: 'ADD_FIELDS',
            criteria: {
              medicalCheckupMasterObjId: '$_id'
            }
          }
        ])

        const testItemMasterObj = filterTestItemMasters.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            const data = item?.data ?? []
            if (item?.medicalCheckupMasterObjId) {
              acc[item.medicalCheckupMasterObjId] = data
            } else {
              acc[temporaryId] = mergeItemMaster(data)
            }
            return acc
          },
          {}
        )

        //format data
        const dataMedicalCheckupFormat = []

        const itemConsultantDateRefId =
          fixedDataItemMaster[ITEM_MASTER_CODE.CONSULTANT_DATE]?.refId
        const itemEvaluationDateRefId =
          fixedDataItemMaster[ITEM_MASTER_CODE.EVALUATION]?.refId
        const itemGeneralCommentsRefId =
          fixedDataItemMaster[ITEM_MASTER_CODE.GENERAL_COMMENTS]?.refId

        for (const medicalCheckup of dataMedicalCheckupExport) {
          let medicalCheckupMaster
          if (medicalCheckup?.additionalInfo?.medicalCheckupMasterObjId) {
            medicalCheckupMaster =
              medicalCheckupMasterObj[
                medicalCheckup?.additionalInfo?.medicalCheckupMasterObjId
              ]
          } else {
            medicalCheckupMaster = (
              medicalCheckupMasterObj[temporaryId] ?? []
            ).find(
              (item: StringKeyObject) =>
                item.refId === medicalCheckup?.medicalCheckupMasterRef
            )
          }
          if (!medicalCheckupMaster) continue

          let periodConfig
          if (medicalCheckup?.additionalInfo?.periodConfigObjId) {
            const dataPeriodConfig =
              periodConfigObj[
                medicalCheckup?.additionalInfo?.periodConfigObjId
              ] ?? {}

            const evaluations: StringKeyObject[] = [
              ...(dataPeriodConfig?.evaluations ?? [])
            ].sort(
              (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
            )

            const [evaluationA] = evaluations

            const referenceValues = evaluationA?.referenceValues ?? []
            const referenceValuesObj = referenceValues.reduce(
              (acc: any, item: StringKeyObject) => {
                return { ...acc, [item.testItemMasterRefId]: item.constraint }
              },
              {}
            )
            periodConfig = referenceValuesObj
          }

          const user = userObj[medicalCheckup.checkupUserRef] ?? {}

          const medicalCheckupTestResults =
            testResultObj[medicalCheckup.refId] ?? {}
          const consultationDate =
            medicalCheckupTestResults[itemConsultantDateRefId]?.value ?? ''
          const evaluation = medicalCheckupTestResults[itemEvaluationDateRefId]
          const generalComments =
            medicalCheckupTestResults[itemGeneralCommentsRefId]?.value ?? ''

          const categoryFormat = handleDataCategory(
            medicalCheckupMaster,
            testItemMasterObj,
            categoryObj,
            periodConfig ?? {},
            user
          )

          const badTestResults = checkDataTestResult(
            categoryFormat,
            medicalCheckupTestResults
          )

          dataMedicalCheckupFormat.push({
            ...medicalCheckup,
            key: medicalCheckup.refId,
            id: medicalCheckup.refId,
            status: medicalCheckup.additionalInfo?.healthCheckStatus,
            listIds: medicalCheckupRefIds,
            consultationDate: consultationDate
              ? consultationDate.split('T')[0].replace(/-/g, '/')
              : '',
            doctorName: evaluation?.valueBy ?? '',
            evaluation: evaluation?.value ?? '',
            generalComments: generalComments,
            registrationDate: medicalCheckup.createdDate
              .split('T')[0]
              .replace(/-/g, '/'),
            testResults: medicalCheckupTestResults,
            medicalCheckupMaster: medicalCheckupMaster,
            periodConfig: periodConfig ?? {},
            formatCategory: categoryFormat,
            badTestResults: badTestResults
          })
        }

        const dataMedicalCheckupExportFormat = dataMedicalCheckupFormat.reduce(
          (acc: StringKeyObject, item: StringKeyObject) => {
            acc[item.checkupUserRef] = acc[item.checkupUserRef] || []
            acc[item.checkupUserRef].push(item)
            return acc
          },
          {}
        )

        const dataExportPdf: StringKeyObject[] = []
        Object.entries(dataMedicalCheckupExportFormat).forEach(
          ([userId, medicalCheckupList]) => {
            const dataPdf = handleDataPdf(
              medicalCheckupList as StringKeyObject[],
              userObj[userId] ?? {}
            )

            dataExportPdf.push(dataPdf)
          }
        )

        for (let pdfFileDetail of dataExportPdf) {
          try {
            const currentDate = new Date()
            const currentTimeString = currentDate
              .toLocaleString()
              .replace(/\s/g, '')
            let fileNamePDF = `${pdfFileDetail?.customer?.patientId}-${currentTimeString}.pdf`
            const blob = await pdf(
              <ResultPreviewPdf pdfResult={pdfFileDetail} tenant={tenant} />
            ).toBlob()
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileNamePDF
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } catch (error) {
            console.error('Failed to generate and download PDF:', error)
          }
        }
      }
      await fetchData()
    } else {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.selectPatient')
      })
    }

    setIsExportData(false)
  }

  const getFixedDataItemMaster = async () => {
    const dataFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'code.coding.code',
              operator: 'in',
              value: [
                ITEM_MASTER_CODE.CONSULTANT_DATE,
                ITEM_MASTER_CODE.EVALUATION,
                ITEM_MASTER_CODE.GENERAL_COMMENTS
              ]
            }
          ]
        }
      }
    ])

    const result = dataFetch.reduce(
      (acc: StringKeyObject, item: StringKeyObject) => {
        acc[item.code?.coding?.[0]?.code] = item
        return acc
      },
      {}
    )

    return result
  }

  const handleDataCategory = (
    medicalCheckupMaster: StringKeyObject,
    testItemMasterObj: StringKeyObject,
    categoryObj: StringKeyObject,
    periodConfig: StringKeyObject,
    customerData: StringKeyObject
  ) => {
    const categoryStructure = medicalCheckupMaster?.additionalInfo?.categories
    if (!Array.isArray(categoryStructure)) return []

    const itemMasters =
      testItemMasterObj[medicalCheckupMaster?.additionalInfo?.objId] ??
      testItemMasterObj[TEMP_ID]
    if (!Array.isArray(itemMasters)) return []

    const categoryFormat = []
    for (const category of categoryStructure) {
      const dataCategory = categoryObj[category.refId]
      if (!dataCategory) continue
      const itemMasterCategory = itemMasters.find(
        (itemMaster: StringKeyObject) =>
          (dataCategory?.associatedTestItemMasterRefIds ?? []).includes(
            itemMaster.refId
          ) &&
          itemMaster?.additionalInfo?.key3 !== TEXT_COMMENTS &&
          itemMaster?.additionalInfo?.key1 === TEXT_CATEGORY_MASTER
      )

      if (!itemMasterCategory) continue

      const structureItemMaster = category?.items ?? []
      const items = []
      for (const item of structureItemMaster) {
        const itemMaster = itemMasters.find(
          (itemMaster) => itemMaster.refId === item.refId
        )
        if (!itemMaster) continue
        const refValues = periodConfig[itemMaster.refId] ?? []
        const dataConstraints = refValues.filter(
          (refValue: StringKeyObject) => {
            return (
              refValue?.gender &&
              refValue?.gender === (customerData?.gender ?? GENDER.Male)
            )
          }
        )

        items.push({
          ...itemMaster,
          lang: itemMaster.lang ?? {},
          referenceValues:
            dataConstraints.length > 0 ? dataConstraints : refValues,
          order: item.order ?? 9999
        })
      }

      if (items.length > 0)
        items.sort(
          (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
        )

      categoryFormat.push({
        refId: dataCategory.refId,
        shortName: dataCategory.shortName,
        associatedTestItemMasterRefIds:
          dataCategory?.associatedTestItemMasterRefIds,
        itemMaster: items,
        idItemMaster: itemMasterCategory?.refId,
        lang: itemMasterCategory?.lang ?? {},
        order: category?.order ?? 9999
      })
    }

    categoryFormat.sort(
      (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
    )

    return categoryFormat
  }

  const checkDataTestResult = (
    categories: StringKeyObject[],
    testResult: StringKeyObject
  ) => {
    const result = []
    for (const category of categories) {
      const items = category?.itemMaster ?? []
      for (const item of items) {
        if (item.referenceValues.length > 0) {
          const referenceValues = item.referenceValues

          const testResultValue = testResult[item.refId]?.value
          if (!testResultValue) continue

          let isAddResult = true
          for (const referenceValue of referenceValues) {
            switch (referenceValue.type) {
              case typeReferenceValue.RANGE_NUMBER:
                if (
                  parseFloat(referenceValue.lowerLimit) <=
                    parseFloat(testResultValue) &&
                  parseFloat(testResultValue) <=
                    parseFloat(referenceValue.upperLimit)
                )
                  isAddResult = false
                break
              case typeReferenceValue.CONDITIONAL_NUMBER:
                if (
                  referenceValue?.upperLimit &&
                  parseFloat(testResultValue) <=
                    parseFloat(referenceValue.upperLimit)
                )
                  isAddResult = false
                if (
                  referenceValue?.lowerLimit &&
                  parseFloat(testResultValue) >=
                    parseFloat(referenceValue.lowerLimit)
                )
                  isAddResult = false
                break
              case typeReferenceValue.STRING:
                if (referenceValue.textValue === testResultValue)
                  isAddResult = false
                break
            }
          }

          if (isAddResult)
            result.push({
              ...item,
              categoryLang: category.lang,
              value: testResultValue
            })
        }
      }
    }

    return result
  }

  const handleDataPdf = (
    medicalCheckupList: StringKeyObject[],
    customerPdf: StringKeyObject
  ) => {
    const dataCategories: StringKeyObject[] = []
    const dataMedicalCheckups = JSON.parse(JSON.stringify(medicalCheckupList))

    for (const medicalCheckup of dataMedicalCheckups) {
      const formatCategory = medicalCheckup?.formatCategory

      if (dataCategories.length === 0) {
        dataCategories.push(...formatCategory)
        continue
      }

      for (const category of formatCategory) {
        const dataFind = dataCategories.find(
          (item: StringKeyObject) => item.refId === category.refId
        )
        if (!dataFind) {
          dataCategories.push(category)
          continue
        }

        const itemMaster = category.itemMaster
        for (const item of itemMaster) {
          const itemFind = dataFind.itemMaster.find(
            (itemFind: StringKeyObject) => itemFind.refId === item.refId
          )

          if (!itemFind) {
            dataFind.itemMaster.push(item)
          }
        }
      }
    }

    dataCategories.sort(
      (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
    )
    dataCategories.forEach((dataCategory) => {
      dataCategory.itemMaster.sort(
        (a: StringKeyObject, b: StringKeyObject) => a.order - b.order
      )
    })

    while (dataMedicalCheckups.length < 3) {
      dataMedicalCheckups.push({})
    }

    return {
      customer: customerPdf,
      data: dataMedicalCheckups,
      category: dataCategories
    }
  }

  return (
    <Flex justify="space-between" className="text-[14px] text-[#545454]">
      <FormProvider {...methods}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit()
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <Flex className="flex justify-center items-center">
            <Typography className="flex items-center text-[16px] font-bold tracking-[1.6px]">
              {t('customerManagement.listOfTestResults')}
            </Typography>

            <Flex className="ml-[7px]">
              <Flex className="relative">
                <Controller
                  name="fullName"
                  control={methods.control}
                  rules={{
                    maxLength: 50
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t('customerManagement.placeholder.idOrName')}
                      className="h-[30px] w-[250px] pl-[32px]"
                    />
                  )}
                />
                <Search className="absolute left-2 top-[7px] h-4 w-4" />
              </Flex>

              <CustomDateRangePicker
                setDates={setDates}
                initialStartDate={startDateParam ? startDateParam : undefined}
                initialEndDate={endDateParam ? endDateParam : undefined}
                isDisabledDate={true}
              />

              <Controller
                name="doctorName"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value ? field.value : undefined}
                    onChange={(value) => {
                      methods.setValue('doctorName', value)
                      handleSelectChange(value)
                    }}
                    placeholder={t(
                      'customerManagement.placeholder.selectDoctor'
                    )}
                    suffixIcon={
                      <CaretDownOutlined
                        style={{ color: '#137695', pointerEvents: 'none' }}
                      />
                    }
                    className="ml-[10px] h-[30px] w-[200px]"
                  >
                    {selectOptions.map((option) => (
                      <Option key={option.key} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </Flex>

            <div className="flex justify-center items-center">
              <Flex align="center" className="ml-[10px]">
                <Typography className="font-bold text-nowrap">
                  {t('customerManagement.table.gender')}
                </Typography>

                <div className="min-w-36">
                  <Controller
                    name="gender"
                    control={methods.control}
                    render={({ field }) => (
                      <Checkbox.Group
                        {...field}
                        options={checkboxOptions}
                        value={selectedGender}
                        onChange={handleCheckboxChange}
                        className="ml-[10px]"
                      />
                    )}
                  />
                </div>
              </Flex>

              <Flex align="center">
                <Typography className="font-bold text-nowrap">
                  {t('customerManagement.table.age')}
                </Typography>
                <Controller
                  name="startAge"
                  control={methods.control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      min={0}
                      max={999}
                      className="ml-[6px] h-[30px] w-[50px]"
                    />
                  )}
                />
                <Typography className="mx-[2px]">～</Typography>
                <Controller
                  name="endAge"
                  control={methods.control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      min={0}
                      max={999}
                      className="h-[30px] w-[50px]"
                    />
                  )}
                />
              </Flex>

              <Button
                type="primary"
                htmlType="submit"
                autoInsertSpace={false}
                className="mx-1 h-[30px] w-[84px] rounded-none border-none bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
              >
                {t('customerManagement.actions.search')}
              </Button>
            </div>
          </Flex>
        </form>
      </FormProvider>

      <Flex className="flex justify-center items-center">
        <Button
          type="primary"
          className="mx-1 rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-[14px] font-bold text-white shadow-none"
          onClick={() => navigate('/interview-result-create')}
        >
          {t('customerManagement.button.createResult')}
        </Button>

        <Button
          type="primary"
          className="mx-1 rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-[14px] font-bold text-white shadow-none"
          onClick={() => {
            handleExportPdf(listIdSelected)
          }}
        >
          {t('customerManagement.button.printResult')}
        </Button>

        <Button
          type="primary"
          className="mx-1 rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-[14px] font-bold text-white shadow-none"
          onClick={() => navigate('/customer-register')}
        >
          {t('customerManagement.button.registerCustomer')}
        </Button>

        <Button
          type="primary"
          className="ml-[7px] h-[30px] w-[90px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] p-0 text-center text-[14px] font-bold text-white shadow-none"
          onClick={() => downloadCSV()}
        >
          {t('customerManagement.button.csv')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default Widget
