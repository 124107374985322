import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export interface InfoRowProps {
  label: string
  value: string | ReactNode
}

const InfoRow: React.FC<InfoRowProps> = ({ label, value }) => {
  const { t } = useTranslation()

  return (
    <tr>
      <td className="p-2 w-36 bg-[#F0F3F7]">{t(label)}</td>
      <td className="p-2 break-words">{value}</td>
    </tr>
  )
}

export default InfoRow
