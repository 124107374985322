import React from 'react'

import { Flex } from 'antd'

import { ReactComponent as Tick } from 'assets/svgs/tick.svg'

function Title({ text, isIcon }: { text: string; isIcon?: boolean }) {
  return (
    <Flex align="center">
      <span className="text-primary font-bold text-base">{text}</span>
      {isIcon && <Tick className="ml-4" width="22px" height="10px" />}
    </Flex>
  )
}

export default Title
