import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'

import { DataForm } from '../../ReservationChange'
import InputLabel from '../InputLabel'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}

const ReservationNoticeModal: FC<Props> = ({ isOpen, onOk, onCancel }) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { action }: DataForm = state || {}

  return (
    <CustomModal
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="h-[500px] pt-4">
          <div>
            <Typography className="text-center text-xl font-bold tracking-[1px]">
              {action === 'confirm'
                ? t('reservationChange.modals.reservationDraftConfirmed1')
                : t('reservationChange.modals.reservationDraftConfirmed2')}
            </Typography>

            <Typography className="mt-48 text-center text-sm">
              {action === 'confirm'
                ? t('reservationChange.modals.reservationDetailSent1')
                : t('reservationChange.modals.reservationDetailSent2')}
            </Typography>

            <Flex justify="center" className="mt-48">
              <Button
                type="default"
                htmlType="button"
                autoInsertSpace={false}
                onClick={onCancel}
                className="ml-3 h-[30px] min-w-[180px] rounded-[3px] border-[1px] border-[#137695] bg-white text-center font-bold text-[#137695] shadow-none"
              >
                {t('reservationManagement.modal.button.back')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default ReservationNoticeModal
