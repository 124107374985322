import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: any
  onSuccess: any
  textTitle?: string
  textContent?: string
}

const SaveSuccessModal = ({
  isModalOpen = false,
  onCancel,
  onSuccess,
  textContent,
  textTitle
}: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
    onCancel()
    onSuccess()
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <Flex vertical className="text-center pt-3 pb-10">
        <Typography className="text-xl font-bold tracking-[1px]">
          {textTitle ? textTitle : t('courseRegister.complete')}
        </Typography>

        <Paragraph className="mt-[159px] mb-0">
          {textContent ? textContent : t('courseRegister.enrolled')}
        </Paragraph>

        <Button
          autoInsertSpace={false}
          className="w-[180px] text-primary bg-white font-bold mt-[143px] m-auto border-primary"
          onClick={handleOk}
        >
          {t('button.return')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default SaveSuccessModal
