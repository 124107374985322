import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex, Table, TableColumnsType, Typography } from 'antd'

import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { PRODUCT_MASTER_SORT_TYPE } from 'configs/constant'

interface ColumnsType {
  id: string | number
  name: string
  overview: string
  responseTime: string
  subscriber: string
  registrationDate: string
  lastUpdateDate: string
}
interface OptionListProps {
  dataSource: any
  loading: boolean
  setListSelectedRows: Function
  toggleSort: Function
}
const OptionList: React.FC<OptionListProps> = ({
  dataSource,
  loading,
  setListSelectedRows,
  toggleSort
}) => {
  const { t } = useTranslation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const productMasterColumns: TableColumnsType<ColumnsType> = [
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-4">
            {t('tableOption.optionName')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.NAME)
            }}
          />
        </Flex>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (_, record) => (
        <Link
          to={`/option-form?id=${record.id}`}
          className="w-full text-left"
        >
          <Typography className="text-[#137695] font-bold underline">
            {record.name}
          </Typography>
        </Link>
      )
    },
    {
      title: t('tableOption.overview'),
      dataIndex: 'overview',
      key: 'overview',
      width: '34%',
      render: (overview) => (
        <Typography className="text-left">{overview}</Typography>
      )
    },
    {
      title: t('tableOption.responseTime'),
      dataIndex: 'buffer',
      key: 'buffer',
      width: '8%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('tableOption.subscriber')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.SUBSCRIBER)
            }}
          />
        </Flex>
      ),
      dataIndex: 'subscriber',
      key: 'subscriber',
      width: '12%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('tableOption.registrationDate')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.REGISTRATION_DATE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: '12%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <Typography className="font-medium mr-3">
            {t('tableOption.lastUpdateDate')}
          </Typography>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSort(PRODUCT_MASTER_SORT_TYPE.REGISTRATION_DATE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'lastUpdateDate',
      key: 'lastUpdateDate',
      width: '12%'
    }
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setListSelectedRows(selectedRows)
    }
  }

  return (
    <div>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        rowKey={(record) =>record.id}
        columns={productMasterColumns}
        dataSource={dataSource}
        pagination={false}
        className="product-master-list-table mt-1.5"
        loading={loading}
        scroll={{
          y: 'calc(100vh - 190px)'
        }}
      />
    </div>
  )
}

export default OptionList
