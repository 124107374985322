import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  API_RESERVATION_API_URL,
  KEYCLOAK_REALM
} from '../configs/api'
import {
  ERROR_AUTHORIZE_CODE,
  ERROR_PATH,
  REQUEST_TIMEOUT
} from '../configs/constant'
import { getAccessToken } from 'utilities/helpers'

// Define types for better type safety
interface RequestOptions extends AxiosRequestConfig {
  successCallback?: (data: any) => void
  errorCallback?: (error: any) => void
}

interface Headers {
  'X-Auth-Token'?: string
  'X-User-Id'?: string
}

// Create typed axios instance
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${API_RESERVATION_API_URL}/`,
  timeout: REQUEST_TIMEOUT,
  validateStatus: () => true
})

axiosInstance.interceptors.request.use(
  function (config: any) {
    const token = getAccessToken()

    const { tenant, subtenant } = JSON.parse(
      localStorage.getItem('@tenant') ?? '{}'
    )

    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    config.headers['Content-Type'] = 'application/json'
    config.headers['TENANT-CODE'] = tenant
    config.headers['SUB-TENANT-CODE'] = subtenant
    config.headers['CLIENT-CODE'] = KEYCLOAK_REALM

    return config
  },
  function (error: any) {
    return Promise.reject(error)
  }
)

/**
 * Config request common
 *
 * @param {String} method Request method
 * @param {String} url Request URL
 * @param {Object} data Request params
 * @param {Object} headers Request headers
 * @param {Object} options Config options
 */
const request = async (
  method = 'GET',
  url = '',
  data = {},
  headers: Headers = {},
  options: RequestOptions = {}
): Promise<any> => {
  const { successCallback, errorCallback, ...rest } = options

  const defaultParams: AxiosRequestConfig = {
    method,
    url,
    headers: headers as AxiosHeaders,
    timeout: REQUEST_TIMEOUT,
    ...rest
  }

  const paramConfigs =
    method === 'GET'
      ? { ...defaultParams, params: data }
      : { ...defaultParams, data }

  try {
    const response = await axiosInstance(paramConfigs)
    successCallback?.(response.data)
    return response.data
  } catch (error) {
    errorCallback?.(error)
    throw error
  }
}

/**
 * Request process callback with method GET
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Object} headers Request headers
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiGet = (
  url: string,
  params = {},
  headers = {},
  successCallback?: (data: any) => void,
  errorCallback?: (error: any) => void
) => request('GET', url, params, headers, { successCallback, errorCallback })

/**
 * Request process callback with method POST
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiPost = (
  url: string,
  params = {},
  headers = {},
  successCallback?: (data: any) => void,
  errorCallback?: (error: any) => void
) => request('POST', url, params, headers, { successCallback, errorCallback })

/**
 * Request process callback with method PUT
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiPut = (
  url: string,
  params = {},
  successCallback?: (data: any) => void,
  errorCallback?: (error: any) => void
) => request('PUT', url, params, {}, { successCallback, errorCallback })

/**
 * Request process callback with method DELETE
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCalback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiDelete = (
  url: string,
  params = {},
  successCallback?: (data: any) => void,
  errorCallback?: (error: any) => void
) => request('DELETE', url, params, {}, { successCallback, errorCallback })

// Simplified interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const isUnauthorized =
      error?.message?.includes(ERROR_AUTHORIZE_CODE) ||
      error?.response?.status === ERROR_AUTHORIZE_CODE

    if (isUnauthorized) {
      window.location.href = ERROR_PATH
      return
    }

    throw error
  }
)

export default {
  get: apiGet,
  post: apiPost,
  put: apiPut,
  delete: apiDelete,
  request
}
