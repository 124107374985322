import { memo, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex, Typography } from 'antd'
import { useRecoilValue } from 'recoil'

import { GENDER } from 'configs/constant'
import { filterInfoAtom } from 'hooks/chat/atoms'
import useMessageList from 'hooks/chat/messageList'

function ChooseGender() {
  const { t } = useTranslation()

  const { methods, setFormValue } = useMessageList()
  const filterInfo = useRecoilValue(filterInfoAtom)

  const { getValues } = useFormContext()
  const gender = getValues('gender')

  const [selectedGender, setSelectedGender] = useState<GENDER[] | undefined>()

  useEffect(() => {
    if (gender) {
      setSelectedGender(gender)
    }
  }, [gender])

  const handleCheckboxChange = (checkedValues: GENDER[]) => {
    setSelectedGender(checkedValues)
    setFormValue('gender', checkedValues)
  }

  const checkboxOptions = [
    { label: t('customerDetail.header.male'), value: GENDER.MALE },
    { label: t('customerDetail.header.female'), value: GENDER.FEMALE }
  ]

  return (
    <Flex align="center">
      <Typography className="font-bold text-nowrap">
        {t('customerManagement.table.gender')}
      </Typography>

      <div>
        <Controller
          name="gender"
          control={methods.control}
          render={({ field }) => (
            <Checkbox.Group
              {...field}
              options={checkboxOptions}
              value={selectedGender}
              onChange={handleCheckboxChange}
              className="ml-[10px]"
            />
          )}
        />
      </div>
    </Flex>
  )
}

export default memo(ChooseGender)
