import React, { useState } from 'react'
import { FormProvider, UseFieldArrayUpdate, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Tooltip, Typography } from 'antd'
import * as yup from 'yup'

import { useGroupModal } from '../../../../hooks/useUserGroup'
import { InputForm } from '../../../elements/InputForm'
import { SelectCustom } from '../../../elements/Select'
import { TextAreaForm } from '../../../elements/TextAreaForm'
import { QuestionCircleFilled } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomModal from 'components/modals'
import { PHONE_NUMBER_REGEX } from 'configs/constant'

type Props = {
  subTenantList: any
  onCancel: () => void
  update: UseFieldArrayUpdate<any, 'subTenantList'>
}

const schema = yup.object().shape({
  selected: yup.number().required(),
  phoneNumber: yup
    .string()
    .test('', '', (value) => !value || PHONE_NUMBER_REGEX.test(value)),
  note: yup.string().max(255)
})

export const SubTenantEditModal = ({
  onCancel,
  update,
  subTenantList
}: Props) => {
  const { t } = useTranslation()
  const { showEditModal, selectedIndex, setSelectedIndex, isModeEdit } =
    useGroupModal()
  const [group, setGroup] = useState<any>(
    selectedIndex ? subTenantList[selectedIndex] : {}
  )

  const options = subTenantList.map((subGroup: any, index: number) => {
    return { label: subGroup.name, value: index }
  })

  const methods = useForm<any>({
    defaultValues: {
      selected: undefined,
      phoneNumber: '',
      note: ''
    },
    resolver: yupResolver(schema)
  })

  const selectedName = methods.watch('selected')

  React.useEffect(() => {
    if (selectedIndex !== undefined) {
      const selectedGroup = subTenantList[selectedIndex]
      setGroup(selectedGroup)

      if (isModeEdit) {
        methods.reset({ selected: selectedIndex, ...selectedGroup })
      } else {
        methods.reset({
          selected: selectedIndex,
          phoneNumber: selectedGroup?.phoneNumber,
          note: selectedGroup?.note
        })
      }
    }

    return () => {}
  }, [selectedIndex, subTenantList])

  React.useEffect(() => {
    setSelectedIndex(selectedName)
  }, [selectedName])

  const handleOk = () => {
    //
  }

  const onSubmit = (data: any) => {
    if (selectedIndex === undefined) {
      console.error('empty selected name')
      methods.setError('name', { type: 'required' })
      return
    }
    onCancel() //-> to closed dialog
    const { phoneNumber, note } = data

    const selectedSubTenant = subTenantList[selectedIndex]
    const { additionalInfo } = selectedSubTenant

    update(selectedIndex, {
      ...selectedSubTenant,
      phoneNumber,
      note,
      phoneNumbers: [phoneNumber],
      notes: [note],
      additionalInfo: { ...additionalInfo, status: 'activated' }
    })
  }

  return (
    <CustomModal open={showEditModal} onOk={handleOk} onCancel={onCancel}>
      <Flex vertical className="p-[16px] gap-[26px]">
        <Typography className="text-[20px] text-[#545454] font-bold self-center">
          {t('contactRegistration')}
        </Typography>
        <Typography className="text-[14px] self-center">
          {t('content.enterContactInformation')}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex vertical className="px-[60px] gap-[28px]">
              <Flex vertical className="gap-[10px]">
                <Typography className="font-bold">
                  {t('basicSetting.familyName')}
                </Typography>
                <SelectCustom
                  name="selected"
                  className="w-full h-[30px]"
                  placeholder={t('placeholder.pleaseSelect')}
                  options={options}
                  rules={{
                    required: true
                  }}
                />
              </Flex>
              <Flex vertical className="gap-[10px]">
                <Typography className="font-bold">
                  {t('lable.phone')}
                </Typography>
                <InputForm
                  name="phoneNumber"
                  type="tel"
                  rules={{
                    maxLength: 15
                  }}
                  // onInput={handlePhoneNumberInput}
                  defaultValue={isModeEdit ? group?.phoneNumber : ''}
                />
              </Flex>
              <Flex vertical className="gap-[10px]">
                <Flex>
                  <Typography className="font-bold">
                    {t('content.cautionNote')}
                  </Typography>
                  <Tooltip title={t('popover.notesArePublic')}>
                    <QuestionCircleFilled className="text-[#B4B4B4] ml-[3px]" />
                  </Tooltip>
                </Flex>
                <TextAreaForm
                  name="note"
                  className="p-[10px] scrollbar-custom"
                  rows={4}
                  style={{ resize: 'none' }}
                  defaultValue={isModeEdit ? group?.note : ''}
                />
              </Flex>
            </Flex>
            <Flex className="justify-center gap-[12px] mt-[12px]">
              <Button
                type="primary"
                htmlType="submit"
                className="w-[180px] font-bold"
                autoInsertSpace={false}
              >
                {t('button.registration')}
              </Button>
              <Button
                className="font-bold w-[180px] border-[#137695] text-[#137695]"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </CustomModal>
  )
}
