import { useCallback } from 'react'

import { DocumentNode } from 'graphql'

import { gql, useMutation } from '@apollo/client'
import { StringKeyObject } from 'types/common'
import { UpdateItemMaster } from '../types/filterItemMaster'
import { DataCategory } from '../types/filterTestItemCategory'
import { DataMedicalCheckupMaster } from '../types/medicalCheckupMaster'

const UPDATE_FILTER_TEST_ITEM_MASTER: DocumentNode = gql`
  mutation updateMedicalCheckupMaster($request: [UpdateMedicalCheckupMaster]!) {
    updateMedicalCheckupMaster(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        shortName
        displayName
        associatedTestItemMasters
        associatedPatientInfoMasters
        additionalInfo
        createdDate
        createdBy
      }
    }
  }
`

export const UPDATE_ITEM_MASTER: DocumentNode = gql`
  mutation updateItemMaster($request: [UpdateItemMaster]) {
    updateItemMaster(request: $request) {
      status
      message
      payload {
        refId
        additionalInfo
      }
    }
  }
`

export const UPDATE_ITEM_CATEGORY: DocumentNode = gql`
  mutation updateTestItemCategory($request: [UpdateTestItemCategory]!) {
    updateTestItemCategory(request: $request) {
      status
      message
      payload {
        refId
        additionalInfo
      }
    }
  }
`
export interface UpdateMedicalCheckupMasterRequest {
  refId: string
  displayName: string
  associatedTestItemMasters?: string[]
  associatedPatientInfoMasters?: string[]
  additionalInfo: StringKeyObject
  shortName?: string
}
const useMutationForInspectionSetting = () => {
  const [updateMedicalCheckupMaster] = useMutation(
    UPDATE_FILTER_TEST_ITEM_MASTER
  )

  const [updateItemMaster] = useMutation(UPDATE_ITEM_MASTER)

  const [updateItemCategory] = useMutation(UPDATE_ITEM_CATEGORY)

  const handleUpdateMedicalCheckupMaster = useCallback(
    async (
      medicalCheckupMaster: DataMedicalCheckupMaster,
      additionalInfo: StringKeyObject
    ) => {
      const dataUpdate: UpdateMedicalCheckupMasterRequest = {
        refId: medicalCheckupMaster.refId,
        displayName: medicalCheckupMaster.displayName,
        shortName: medicalCheckupMaster.shortName,
        associatedTestItemMasters: medicalCheckupMaster.associatedTestItemMasters,
        associatedPatientInfoMasters:
          medicalCheckupMaster.associatedPatientInfoMasters,
        additionalInfo: {
          ...medicalCheckupMaster.additionalInfo,
          ...additionalInfo
        }
      }
      return await updateMedicalCheckupMaster({
        variables: { request: dataUpdate },
        // context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    },
    [updateMedicalCheckupMaster]
  )

  const handleUpdateItemMaster = useCallback(
    async (request: UpdateItemMaster[]) => {
      return await updateItemMaster({
        variables: { request },
        // context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    },
    [updateItemMaster]
  )

  const handleUpdateItemCategory = useCallback(
    async (
      listItemCategory: DataCategory[],
      additionalInfo: StringKeyObject
    ) => {
      const dataUpdate = listItemCategory.map((item) => ({
        refId: item.refId,
        displayName: item.displayName,
        associatedTestItemMasterRefIds: item.associatedTestItemMasterRefIds,
        shortName: item.shortName,
        additionalInfo: {
          ...item.additionalInfo,
          ...additionalInfo
        }
      }))
      return await updateItemCategory({
        variables: { request: dataUpdate },
        // context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })
    },
    [updateItemCategory]
  )

  return {
    handleUpdateMedicalCheckupMaster,
    handleUpdateItemMaster,
    handleUpdateItemCategory
  }
}

export default useMutationForInspectionSetting
