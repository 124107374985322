import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalStorage } from './useLocalStorage'
import { EXCLUDED_NAME } from './useRoles'
import {
  API_USER,
  GROUP_LIST,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_CLIENT_UUID,
  ROLE_UPDATE
} from 'configs/api'
import { STORAGE_TENANT_KEY } from 'configs/constant'
import useAdmins from 'hooks/useAdmins'
import { IAdminRegister } from 'pages/AdminRegister/AdminRegister'
import { IGroup } from 'types/Groups'
import { IRoles } from 'types/Roles'
import { Options } from 'types/common'
import networkAdapter from 'utilities/networkAdapter'

const useAdminRegister = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [roleOptions, setRoleOptions] = useState<any>([])
  const [roles, setRoles] = useState<IRoles[]>([])
  const [departmentOptions, setDepartmentOptions] = useState<any>([])
  const [assignmentOptions, setAssignmentOptions] = useState<any>([])
  const [positionOptions, setPositionOptions] = useState<any>([])
  const [adminOptions, setAdminOptions] = useState<Options[]>([])
  const [groups, setGroups] = useState<string[]>([])
  const [currentTenant] = useLocalStorage(STORAGE_TENANT_KEY, {})
  const { getTenant } = useAdmins()
  const navigate = useNavigate()

  useEffect(() => {
    const handleDataTenant = async () => {
      const tenant: any = await getTenant({
        filter: `(eq,STRING,code,${currentTenant?.tenant})`
      })

      if (!tenant) return
      let departments = tenant?.additionalInfo?.departments
      if (departments) {
        departments = JSON.parse(departments)
        if (!Array.isArray(departments)) departments = []
        departments = departments.map((item: any) => {
          if (!item?.key || !item?.value) return null
          return {
            key: item.key,
            value: item.key,
            label: item.value
          }
        })

        setDepartmentOptions(departments.filter((item: any) => item !== null))
      }

      let assignments = tenant?.additionalInfo?.assignments
      if (assignments) {
        assignments = JSON.parse(assignments)
        if (!Array.isArray(assignments)) assignments = []
        assignments = assignments.map((item: any) => {
          if (!item?.key || !item?.value) return null
          return {
            key: item.key,
            value: item.key,
            label: item.value
          }
        })

        setAssignmentOptions(assignments.filter((item: any) => item !== null))
      }

      let positions = tenant?.additionalInfo?.positions
      if (positions) {
        positions = JSON.parse(positions)
        if (!Array.isArray(positions)) positions = []
        positions = positions.map((item: any) => {
          if (!item?.key || !item?.value) return null
          return {
            key: item.key,
            value: item.key,
            label: item.value
          }
        })

        setPositionOptions(positions.filter((item: any) => item !== null))
      }
    }
    const handleDataRole = async () => {
      let dataRoles = await networkAdapter.getClient(ROLE_UPDATE)
      if (!Array.isArray(dataRoles)) dataRoles = []

      setRoles(dataRoles)
      dataRoles = dataRoles
        .map((item: IRoles) => {
          if (item.name === EXCLUDED_NAME) return null
          const lang = item.description ? JSON.parse(item.description) : {}
          return {
            key: item.id,
            label: ' ',
            value: item.id,
            lang: Object.fromEntries(
              Object.entries(lang).map(([key, value]: [string, any]) => [
                key,
                value?.[0]
              ])
            )
          }
        })
        .filter((item: Options[] | null) => item !== null)

      setRoleOptions(dataRoles)
    }

    const handleGroupsTenant = async () => {
      try {
        const response: IGroup[] = await networkAdapter.getClient(GROUP_LIST)
        if (!Array.isArray(response)) return
        const data = []
        for (const group of response) {
          if (!Array.isArray(group?.realmRoles)) continue

          if (
            group.realmRoles.includes(currentTenant?.tenant) ||
            group.realmRoles.includes(currentTenant?.subtenant)
          ) {
            data.push(group.path)
          }
        }

        setGroups(data)
      } catch (error) {
        setError((error as Error).message)
      }
    }

    handleDataRole()
    handleDataTenant()
    handleGroupsTenant()
  }, [])

  const handleAdminOptions = async () => {
    const response = await networkAdapter.getClient(API_USER)
    if (!Array.isArray(response)) return

    const data = []
    for (const admin of response) {
      data.push({
        key: admin.id,
        label: (admin.firstName + ' ' + admin.lastName).trim(),
        value: admin.id
      })
    }

    setAdminOptions(data)
  }

  const handleCreateAdmins = async (data: IAdminRegister[]) => {
    const errors = []
    setLoading(true)
    for (const admin of data) {
      const dataCreate = {
        username: admin.user_name.trim(),
        email: admin.email.trim(),
        firstName: admin.first_name.trim(),
        lastName: admin.last_name.trim(),
        requiredActions: [],
        emailVerified: false,
        groups: groups,
        enabled: true,
        attributes: {
          assignments: admin.assignments ?? [],
          departments: admin.departments ?? [],
          sex: [admin.gender],
          positions: admin.positions ?? [],
          firstNameKana: [admin.first_name_kana ?? ''],
          lastNameKana: [admin.last_name_kana ?? '']
        }
      }

      const create = await networkAdapter.postClient(API_USER, dataCreate)

      if (create?.status && !(create.status >= 200 && create.status < 300)) {
        errors.push(admin.key)
        continue
      }

      const user = await networkAdapter.getClient(
        API_USER + `?username=${encodeURIComponent(admin.user_name)}`
      )

      const userCreated = user?.[0]
      if (!userCreated) continue

      const userId = userCreated?.id

      await networkAdapter.putClient(`${API_USER}/${userId}/reset-password`, {
        temporary: false,
        type: 'password',
        value: admin.password
      })

      if (!admin.roles || admin.roles?.length === 0) continue

      const dataAddRoles = []
      for (const role of admin.roles) {
        const roleAdd = roles.find((item: IRoles) => item.id === role)
        if (!roleAdd) continue

        dataAddRoles.push({
          id: roleAdd.id,
          name: roleAdd.name,
          description: roleAdd.description
        })
      }

      await networkAdapter.postClient(
        `${API_USER}/${userId}/role-mappings/clients/${KEYCLOAK_CLIENT_UUID}`,
        dataAddRoles
      )
    }
    setLoading(false)

    return errors
  }

  const handleUpdateAdmins = async (data: any, user: any) => {
    const errors = []
    setLoading(true)
    for (const admin of data) {
      const { roles: oldRoles, isOwner, ...oldUser } = user
      const dataUpdate = {
        ...oldUser,
        email: admin.email?.trim(),
        firstName: admin.first_name?.trim(),
        lastName: admin.last_name?.trim(),
        requiredActions: [],
        emailVerified: false,
        groups: groups,
        enabled: true,
        attributes: {
          ...(oldUser.attributes ?? []),
          assignments: admin.assignments ?? [],
          departments: admin.departments ?? [],
          sex: [admin.gender],
          positions: admin.positions ?? [],
          firstNameKana: [admin.first_name_kana ?? ''],
          lastNameKana: [admin.last_name_kana ?? ''],
          isOwner: [isOwner ? (admin.ownership_change ? 0 : 1) : 0]
        }
      }

      const update = await networkAdapter.putClient(
        `${API_USER}/${user.id}`,
        dataUpdate
      )

      if (update?.status && !(update.status >= 200 && update.status < 300)) {
        errors.push(admin.key)
        navigate('/admin-list')
        continue
      }

      if (admin.ownership_change) {
        const adminChange = await networkAdapter.getClient(
          `${API_USER}/${admin.ownership_change}`
        )
        if (adminChange) {
          const dataUpdateAdminChange = {
            ...adminChange,
            attributes: {
              ...(adminChange.attributes ?? []),
              isOwner: [1]
            }
          }
          await networkAdapter.putClient(
            `${API_USER}/${admin.ownership_change}`,
            dataUpdateAdminChange
          )
        }
      }

      if (admin.password) {
        await networkAdapter.putClient(
          `${API_USER}/${user.id}/reset-password`,
          {
            temporary: false,
            type: 'password',
            value: admin.password
          }
        )
      }

      if (oldRoles?.length !== 0) {
        const dataDeleteRoles = []
        for (const role of oldRoles) {
          const roleDelete = roles.find((item: IRoles) => item.id === role)
          if (!roleDelete) continue

          dataDeleteRoles.push({
            id: roleDelete.id,
            name: roleDelete.name
          })
        }

        await networkAdapter.deleteClientWithData(
          `${API_USER}/${user.id}/role-mappings/clients/${KEYCLOAK_CLIENT_UUID}`,
          dataDeleteRoles
        )
      }

      if (admin.roles?.length === 0) continue

      const dataAddRoles = []
      for (const role of admin.roles) {
        const roleAdd = roles.find((item: IRoles) => item.id === role)
        if (!roleAdd) continue

        dataAddRoles.push({
          id: roleAdd.id,
          name: roleAdd.name,
          description: roleAdd.description
        })
      }

      await networkAdapter.postClient(
        `${API_USER}/${user.id}/role-mappings/clients/${KEYCLOAK_CLIENT_UUID}`,
        dataAddRoles
      )
    }
    setLoading(false)
    return errors
  }

  const getUserById = async (id: string) => {
    try {
      const response = await networkAdapter.getClient(`${API_USER}/${id}`)
      return response
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getRoleById = async (id: any) => {
    try {
      if (!id) return []

      const response = await networkAdapter.getClient(
        `${API_USER}/${id}/role-mappings`
      )
      const mappingRole =
        response?.clientMappings?.[KEYCLOAK_CLIENT_ID ?? '']?.mappings
      if (!Array.isArray(mappingRole)) return []

      let data: any = []
      mappingRole.forEach((role: any) => {
        if (role?.id) {
          data.push(role.id)
        }
      })

      return data
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    error,
    onClearError,
    departmentOptions,
    assignmentOptions,
    positionOptions,
    roleOptions,
    handleCreateAdmins,
    getUserById,
    getRoleById,
    handleAdminOptions,
    adminOptions,
    handleUpdateAdmins
  }
}

export default useAdminRegister
