import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'

import { DatePicker, GetProps } from 'antd'
import dayjs from 'dayjs'

import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ReactComponent as DatePickerIcon } from 'assets/svgs/date-picker.svg'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

type Props = {
  className?: string
  name: string
  placeholder?: string
  disabled?: boolean
  size?: SizeType
  picker?: any
  format?: string
  minDate?: string
  maxDate?: string
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
}

export const DatePickerForm = ({
  className = '',
  name,
  placeholder,
  disabled = false,
  size = 'small',
  format = 'YYYY/MM/DD',
  minDate,
  maxDate,
  rules
}: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field
        return (
          <DatePicker
            className={className}
            size={size}
            suffixIcon={
              <DatePickerIcon className="mr-[-7px] h-[30px] w-[30px]" />
            }
            format={format}
            disabled={disabled}
            status={error ? 'error' : ''}
            placeholder={placeholder}
            minDate={minDate ? dayjs(minDate, format) : undefined}
            maxDate={maxDate ? dayjs(maxDate, format) : undefined}
            onChange={(date, dateString) => onChange(dateString)}
            value={value ? dayjs(value, format) : null}
            {...restField}
          />
        )
      }}
    />
  )
}

type DatePickerCustomProps = {
  className?: string
  name: string
  placeholder?: string
  disabled?: boolean
  size?: SizeType
  format?: string
  value?: string
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
  onChange: (name: string, value: string | string[]) => void
}

export const DatePickerCustom = ({
  className = '',
  name,
  placeholder = '',
  disabled = false,
  size = 'small',
  format = 'YYYY/MM/DD',
  value,
  rules,
  onChange
}: DatePickerCustomProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  type RangePickerProps = GetProps<typeof DatePicker.RangePicker>
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= dayjs().endOf('day')
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const {
          value: fieldValue,
          onChange: fieldOnChange,
          ...restField
        } = field
        return (
          <DatePicker
            className={className}
            disabledDate={disabledDate}
            size={size}
            suffixIcon={
              <DatePickerIcon className="mr-[-7px] h-[30px] w-[30px]" />
            }
            format={format}
            disabled={disabled}
            status={errors?.[name] ? 'error' : ''}
            placeholder={placeholder}
            defaultValue={value ? dayjs(value, format) : undefined}
            value={
              fieldValue
                ? dayjs(fieldValue, format)
                : value
                  ? dayjs(value, format)
                  : null
            }
            onChange={(date, dateString) => {
              fieldOnChange(dateString)
              onChange(name, dateString)
            }}
            {...restField}
          />
        )
      }}
    />
  )
}
