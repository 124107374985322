import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Flex, Row, Typography } from 'antd'

import { InputLabel } from 'components/elements/InputLabel'
import { weekdayOptions } from 'configs/constant'
import { WORK_SCHEDULE_TYPE } from 'models/setting'
import { StringKeyObject } from 'types/common'

const weekDayLabels = (workdays: string[]) => {
  const weekDays = weekdayOptions.filter((day) => workdays.includes(day.value))

  return weekDays.map((day) => day.label)
}
const weekNumberLabels: { [key: number]: string } = {
  1: 'basicSetting.no.first',
  2: 'basicSetting.no.second',
  3: 'basicSetting.no.third',
  4: 'basicSetting.no.fourth',
  5: 'basicSetting.no.fifth'
}
function DetailsOpeningTimes({ tenantForm }: { tenantForm: StringKeyObject }) {
  const { t } = useTranslation()

  if (!tenantForm?.workScheduleType) return null

  if (tenantForm.workScheduleType === WORK_SCHEDULE_TYPE.fixedDay) {
    return (
      <Typography>
        {tenantForm?.fixedDayStartTime} ～ {tenantForm?.fixedDayEndTime}
      </Typography>
    )
  }

  if (tenantForm.workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
    const specifiedDayTimes = tenantForm?.specifiedDayTimes
    if (!Array.isArray(specifiedDayTimes)) return null

    const dayActive = specifiedDayTimes.filter((day) => day.active)

    return (
      <>
        {dayActive.map((day) => {
          const option = weekdayOptions.find((o) => o.value === day.workday)
          if (!option) return null

          return (
            <Flex vertical key={day.workday}>
              <Typography>
                {t(option.label)}：{day.start} ～ {day.end}
              </Typography>
              {day.hasBiweekly && (
                <div className="ml-4">
                  {day.biweekly.map((week: StringKeyObject) => (
                    <Typography key={week.week}>
                      {t(weekNumberLabels[week.week])}：
                      {week.typeDay === '1'
                        ? `${week.start} ～ ${week.end}`
                        : t('basicSetting.closedDay')}
                    </Typography>
                  ))}
                </div>
              )}
            </Flex>
          )
        })}
      </>
    )
  }

  return null
}

export default function TenantInformation({
  tenantForm
}: {
  tenantForm: StringKeyObject
}) {
  const { t } = useTranslation()
  const showData = [
    {
      label: t('basicSetting.formLabel.name'),
      value: <Typography>{tenantForm?.name}</Typography>
    },
    {
      label: t('basicSetting.formLabel.kana_name'),
      value: <Typography>{tenantForm?.kanaName}</Typography>
    },
    {
      label: t('basicSetting.formLabel.clinic_name'),
      value: <Typography>{tenantForm?.clinicName}</Typography>
    },
    {
      label: t('basicSetting.formLabel.clinic_kana_name'),
      value: <Typography>{tenantForm?.clinicKanaName}</Typography>
    },
    {
      label: t('basicSetting.formLabel.location'),
      value: (
        <>
          {tenantForm.country === 'ja' ? (
            <>
              <Typography>〒{tenantForm?.zipCode}</Typography>
              <Typography>
                {tenantForm?.province +
                  tenantForm?.district +
                  tenantForm?.address}
              </Typography>
              <Typography>{tenantForm?.apartment}</Typography>
            </>
          ) : (
            <>
              <Typography>〒{tenantForm?.zipCode}</Typography>
              <Typography>
                {`${tenantForm?.apartment} ${tenantForm?.address} ${tenantForm?.district}`}
              </Typography>
              <Typography>{tenantForm?.stateName}</Typography>
            </>
          )}
        </>
      )
    },
    {
      label: t('basicSetting.businessDay'),
      value: (
        <>
          <Typography>
            {t('lable.businessDays')}：
            {weekDayLabels(
              Array.isArray(tenantForm?.workdays) ? tenantForm.workdays : []
            )
              .map((item: string) => t(item))
              .join(' / ')}
          </Typography>
          <Flex>
            <Typography>{t('lable.businessHours')}：</Typography>
            <div>
              <DetailsOpeningTimes tenantForm={tenantForm} />
            </div>
          </Flex>
        </>
      )
    }
  ]
  return (
    <Flex className="gap-1.5" vertical>
      {showData.map((data) => (
        <Row key={data.label} className="gap-3">
          <Col flex="203px">
            <InputLabel label={data.label} align="center" className="!py-0" />
          </Col>
          <Col flex="auto">{data.value}</Col>
        </Row>
      ))}
    </Flex>
  )
}
