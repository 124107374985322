import { ReactNode } from 'react'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Empty, Select, Space, Tooltip, Typography } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import { Options } from 'types/common'
import { preventEnterKeyDefault } from 'utilities/helpers'

type Props = {
  className?: string
  name: string
  options: {
    label: string | ReactNode | any
    value: number | string
  }[]
  placeholder?: string
  disabled?: boolean
  size?: SizeType
  defaultValue?: number | string
  value?: number | string
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
      >
    | undefined
  onChange?: ((name: string, value: string | number) => void) | null
}

export const SelectCustom = ({
  className = '',
  name,
  options = [],
  placeholder = '',
  disabled = false,
  defaultValue,
  onChange,
  value,
  rules
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <Select
          placeholder={placeholder}
          disabled={disabled}
          className={className}
          status={error ? 'error' : ''}
          suffixIcon={
            <CaretDownOutlined
              color="#137695"
              className="text-[#137695] text-[14px]"
              style={{ pointerEvents: 'none' }}
            />
          }
          {...field}
          value={field.value ?? defaultValue}
          onChange={(e) => {
            field.onChange(e)

            if (onChange) onChange(name, e)
          }}
        >
          {options.map((option) => (
            <Select.Option value={option.value} key={option.value}>
              {t(option.label)}
            </Select.Option>
          ))}
        </Select>
      )}
    />
  )
}

export const SelectMultipleCustom = ({
  name,
  placeholder = '',
  options = [],
  className = '',
  defaultValue = [],
  isOpenNotFoundContent = true,
  onChange
}: {
  name: string
  placeholder?: string
  options?: any[]
  className?: string
  defaultValue?: any[]
  isOpenNotFoundContent?: boolean
  onChange?: (name: string, value: string[] | string) => void
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const filteredValues = (field.value ?? []).filter(
          (val: string | number) =>
            options.some((option) => option.value === val)
        )

        return (
          <Select
            notFoundContent={
              isOpenNotFoundContent ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : null
            }
            maxTagCount="responsive"
            {...field}
            mode="multiple"
            value={filteredValues}
            className={`select-multi-options ${className}`}
            prefix={<Search />}
            suffixIcon={null}
            placeholder={placeholder}
            defaultValue={defaultValue}
            options={options}
            popupClassName="popupSelectMultipleCustom"
            menuItemSelectedIcon={null}
            onInputKeyDown={preventEnterKeyDefault}
            optionFilterProp="label"
            optionRender={(option) => {
              const itemCheck = option.value ?? ''
              return (
                <Space>
                  <Checkbox
                    key={option.key}
                    checked={
                      field?.value
                        ? field?.value.includes(itemCheck.toString())
                        : false
                    }
                    onKeyDown={preventEnterKeyDefault}
                  ></Checkbox>
                  <Typography.Text className="text-[14px]">
                    {option.label}
                  </Typography.Text>
                </Space>
              )
            }}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                overlayStyle={{
                  pointerEvents: 'none'
                }}
                title={omittedValues.map(({ label }) => label).join('、')}
              >
                <span>+ {omittedValues.length} ...</span>
              </Tooltip>
            )}
            onChange={(value) => {
              field.onChange(value)
              if (onChange) onChange(name, value)
            }}
          ></Select>
        )
      }}
    />
  )
}

export const SelectSearchCustom = ({
  name,
  placeholder = '',
  options = [],
  className = '',
  defaultValue,
  isHiddenValue = false,
  disabled = false
}: {
  name: string
  placeholder?: string
  options?: Options[]
  className?: string
  defaultValue?: string
  isHiddenValue?: boolean
  disabled?: boolean
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          className={className}
          showSearch
          suffixIcon={
            <SearchOutlined className="absolute top-[-2px] right-[-4px] cursor-pointer" />
          }
          placeholder={placeholder}
          defaultValue={defaultValue}
          options={options}
          popupClassName="popupSelectSearchCustom"
          menuItemSelectedIcon={null}
          onInputKeyDown={preventEnterKeyDefault}
          optionFilterProp="label"
          value={isHiddenValue ? null : (field.value ?? defaultValue)}
          disabled={disabled}
        ></Select>
      )}
    />
  )
}
