import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import { Checkbox, Empty, Flex, Typography } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import useDebounce from 'hooks/useDebounce'
import { showName } from 'pages/InspectionItemSetting/helper'
import { Category } from 'types/FilterTestItemMaster'
import cn from 'utilities/cn'
import { getLanguage } from 'utilities/helpers'
import DraggableWrapper from './DraggableWrapper'

interface RenderListProps {
  droppableIdPrefix: string
  isShowCode?: boolean
  items?: Category[]
  handleLoadMore?: () => Promise<void>
  scrollTo?: (curr: HTMLDivElement) => void
  onChecked?: (item: Category, checked: boolean) => void
  allowCheckbox?: boolean
  searchTerm?: string
}

const RenderList: React.FC<RenderListProps> = ({
  items,
  isShowCode = false,
  droppableIdPrefix,
  onChecked,
  allowCheckbox,
  searchTerm
}) => {
  const language = getLanguage()
  const [listItems, setListItems] = React.useState<Category[]>([])

  React.useEffect(() => {
    setListItems(items ?? [])
  }, [items])

  const searchTermDebounced = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    if (!searchTermDebounced) return setListItems(items ?? [])

    setListItems(
      items?.filter((it) =>
        showName(it, language)
          ?.toLowerCase()
          .includes(searchTermDebounced?.trim().toLowerCase())
      ) ?? []
    )
  }, [searchTermDebounced, items, language])

  return (
    <Droppable
      droppableId={droppableIdPrefix}
      mode="virtual"
      renderClone={(provided, _, rubric) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Flex
            className="flex justify-between items-center border-b border-[#BDCBD5] min-h-[30px] tracking-[1.4px]"
            key={`${listItems?.[rubric.source.index].displayName}_${rubric.source.index}`}
          >
            <Flex className="flex-1 items-center py-1">
              <Typography className="w-[50%] pl-[25px] pr-2">
                {listItems?.[rubric.source.index] &&
                  showName(listItems?.[rubric.source.index], language)}
              </Typography>
              {isShowCode && (
                <Typography className="flex-1 text-xs text-[#BDCBD5] tracking-[.6px] pr-2">
                  {
                    items?.[rubric.source.index]?.code.coding.find(
                      (it) => it.system === 'PHT'
                    )?.code
                  }
                </Typography>
              )}
            </Flex>
            <span className="w-[15px] mr-3 ml-2">
              <MenuOutlined />
            </span>
          </Flex>
        </div>
      )}
    >
      {(provided) => (
        <div
          ref={(el) => {
            provided.innerRef(el)
          }}
          {...provided.droppableProps}
          className="flex flex-col p-2 bg-white  max-h-[900px] rounded-b-lg overflow-y-scroll"
        >
          {listItems?.length ? (
            listItems?.map((category, index) => (
              <DraggableWrapper
                key={category.refId + '_' + index}
                draggableId={`${category.refId}_root_${index}`}
                index={index}
              >
                <div
                  className="border border-[#BDCBD5] mb-1"
                  key={category.refId}
                >
                  <Flex
                    className="flex justify-between items-center border-b border-[#BDCBD5] min-h-[30px] tracking-[1.4px]"
                    key={`${category.displayName}_${index}`}
                  >
                    {allowCheckbox && (
                      <Checkbox
                        className="px-2"
                        onChange={(e) => {
                          const checked = e.target.checked
                          onChecked?.(category, checked)
                          category.isChecked = checked
                        }}
                      ></Checkbox>
                    )}
                    <Flex className="flex-1 items-center py-1">
                      <Typography
                        className={cn('pr-2', {
                          'w-[50%] ': isShowCode,
                          'pl-[25px]': !allowCheckbox
                        })}
                      >
                        {category && showName(category, language)}
                      </Typography>
                      {isShowCode && (
                        <Typography className="flex-1 text-xs text-[#BDCBD5] tracking-[.6px] pr-2">
                          {
                            category?.code.coding.find(
                              (it) => it.system === 'PHT'
                            )?.code
                          }
                        </Typography>
                      )}
                    </Flex>
                    <span className="w-[15px] mr-3 ml-2">
                      <MenuOutlined />
                    </span>
                  </Flex>
                </div>
              </DraggableWrapper>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {/* {isShow && (
            <div className="w-full flex justify-center">
              <Button
                variant="outlined"
                className="rounded-md"
                onClick={handleLoadMore}
              >
                Load more
              </Button>
            </div>
          )} */}
        </div>
      )}
    </Droppable>
  )
}

export default RenderList
