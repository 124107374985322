import { AGGREGATION_FILTER } from '../graphql/AggregationFilter/aggregationFilter'
import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery } from '@apollo/client'
import { CourseResponse } from 'pages/CustomerDetail/types/types'
import { StringKeyObject } from 'types/common'

const useGetMedicalCheckupMasterDetails = () => {
  const [aggregationFilter, { loading, data, error }] =
    useLazyQuery(AGGREGATION_FILTER)

  const getMedicalCheckupMastersById = async (
    id: string,
    variables: StringKeyObject[] = []
  ) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: 1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'eq',
                    value: id
                  },
                  {
                    field: 'additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  ...variables
                ]
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      const payload = result?.data?.commonAggregationFilter?.payload
      const data =
        Array.isArray(payload) && payload.length > 0 ? payload[0] : undefined

      return data
        ? {
            medicalCheckupMaster: data
          }
        : data
    } catch (err) {
      console.error(err)
    }
  }

  const getMedicalCheckupMastersWithOption = async () => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.key1',
                    operator: 'eq',
                    value: 'option'
                  },
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  }
                ]
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      const payload = result?.data?.commonAggregationFilter?.payload
      const data =
        Array.isArray(payload) && payload.length > 0
          ? payload[0]?.data
          : undefined

      return data
        ? {
            medicalCheckupMaster: data
          }
        : data
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getMedicalCheckupMastersById,
    getMedicalCheckupMastersWithOption,
    data,
    loading,
    error
  }
}

const useFirstMedicalCheckupMaster = () => {
  const [aggregationFilter, { loading, data, error }] =
    useLazyQuery(AGGREGATION_FILTER)

  const getFirstMedicalCheckupMaster = async () => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  }
                ]
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      const payload = result?.data?.commonAggregationFilter?.payload
      const data =
        Array.isArray(payload) && payload.length > 0
          ? payload[0]?.data
          : undefined

      return data
        ? {
            medicalCheckupMaster: data
          }
        : data
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getFirstMedicalCheckupMaster,
    data,
    loading,
    error
  }
}

const useMedicalCheckupMaster = () => {
  const [aggregationFilter, { loading, data, error }] =
    useLazyQuery(AGGREGATION_FILTER)

  const getMedicalCheckupMaster = async (
    variables: StringKeyObject,
    isPayload: boolean = true
  ) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: variables.page || 0,
          size: variables.size || -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: variables.filter
              }
            },
            {
              type: 'SORT',
              criteria: {
                field: `data.${variables.sortBy.field}`,
                direction: variables.sortBy.direction
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      if (!isPayload) {
        const data = result?.data?.commonAggregationFilter?.payload
        const finalData = data.map((item: any) => {
          return {
            medicalCheckupMaster: item.data
          }
        })

        return {
          data: finalData,
          totalElements: result?.data?.commonAggregationFilter?.totalElements
        }
      }

      const data = result?.data?.commonAggregationFilter?.payload
      return data?.map((item: any) => {
        return {
          medicalCheckupMaster: item.data
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const getMedicalCheckupMasterWithVariables = async (
    variables: StringKeyObject,
    page: number = 0,
    size: number = -1,
    isPayload: boolean = true
  ) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: page,
          size: size,
          request: variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      if (!isPayload) {
        const data = result?.data?.commonAggregationFilter?.payload

        return {
          data,
          totalElements: result?.data?.commonAggregationFilter?.totalElements
        }
      }

      return result?.data?.commonAggregationFilter?.payload
    } catch (err) {
      console.error(err)
    }
  }

  const getMedicalCheckupMasterCount = async (variables: StringKeyObject) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: variables.page || 0,
          size: variables.size || -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: variables.filter
              }
            },
            {
              type: 'SORT',
              criteria: {
                field: `data.${variables.sortBy.field}`,
                direction: variables.sortBy.direction
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      return result?.data?.commonAggregationFilter?.totalElements
    } catch (err) {
      console.error(err)
    }
  }

  const getOptionIdsByCourseId = (
    courseId: string,
    medicalCheckupMasters: CourseResponse[]
  ) => {
    if (!medicalCheckupMasters) return []

    const course = medicalCheckupMasters.find(
      (medicalCheckupMaster) =>
        medicalCheckupMaster?.medicalCheckupMaster?.refId === courseId
    )

    if (!course) {
      return []
    }

    return (
      course?.medicalCheckupMaster?.additionalInfo?.metadata?.find(
        (item) => item.name === 'options'
      )?.value ?? []
    )
  }

  return {
    getOptionIdsByCourseId,
    getMedicalCheckupMasterCount,
    getMedicalCheckupMaster,
    getMedicalCheckupMasterWithVariables,
    data,
    loading,
    error
  }
}

const useGetMedicalCheckupMasterByName = () => {
  const [aggregationFilter, { error }] = useLazyQuery(AGGREGATION_FILTER)

  const convertData = (data: any) => {
    return data.map((item: any) => {
      return {
        medicalCheckupMaster: {
          refId: item.data.refId,
          displayName: item.data.displayName
        }
      }
    })
  }

  const getMedicalCheckupMastersByName = async (name: string) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  {
                    field: 'data.additionalInfo.key1',
                    operator: 'ne',
                    value: 'option'
                  },
                  {
                    field: 'data.displayName',
                    operator: 'like',
                    value: name
                  }
                ]
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      })

      const data = result?.data?.commonAggregationFilter?.payload || []
      return convertData(data)
    } catch (e) {
      console.error(error)
    }
  }

  const getMedicalCheckupMasterWithoutOptionByName = async (name: string) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  {
                    field: 'data.additionalInfo.key1',
                    operator: 'ne',
                    value: 'option'
                  },
                  {
                    field: 'data.displayName',
                    operator: 'like',
                    value: name
                  }
                ]
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      })

      const payload = result?.data?.commonAggregationFilter?.payload
      const data = Array.isArray(payload) && payload.length > 0 ? payload : []

      const finalData =
        data?.map((item: any) => {
          return {
            medicalCheckupMaster: item.data
          }
        }) || []

      return finalData
    } catch (e) {
      console.error(error)
    }
  }

  const getAllMedicalCheckupMastersByIds = async (ids: string[]) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  {
                    field: 'data.refId',
                    operator: 'in',
                    value: ids
                  }
                ]
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      })

      const payload = result?.data?.commonAggregationFilter?.payload
      const data = Array.isArray(payload) && payload.length > 0 ? payload : []

      const finalData =
        data?.map((item: any) => {
          return {
            medicalCheckupMaster: item.data
          }
        }) || []

      return finalData
    } catch (e) {
      console.error(error)
    }
  }

  const getMedicalCheckupMastersByIds = async (ids: string[]) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'data.additionalInfo.isDeleted',
                    operator: 'ne',
                    value: 'true'
                  },
                  {
                    field: 'data.refId',
                    operator: 'in',
                    value: ids
                  }
                ]
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      })

      const data = result?.data?.commonAggregationFilter?.payload || []
      return convertData(data)
    } catch (e) {
      console.error(error)
    }
  }

  const getListNameMedicalCheckupMaster = async (
    variables: StringKeyObject
  ) => {
    try {
      const result = await aggregationFilter({
        variables: {
          collection: 'medicalCheckupMaster',
          page: 0,
          size: -1,
          request: [
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                data: {
                  $arrayElemAt: ['$data', 0]
                }
              }
            },
            {
              type: 'MATCH',
              criteria: {
                and: variables.filter
              }
            },
            {
              type: 'SORT',
              criteria: {
                field: `data.${variables.sortBy.field}`,
                direction: variables.sortBy.direction
              }
            }
          ]
        },
        fetchPolicy: 'network-only'
      })

      const data = result?.data?.commonAggregationFilter?.payload || []
      return convertData(data)
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getMedicalCheckupMastersByName,
    getMedicalCheckupMastersByIds,
    getListNameMedicalCheckupMaster,
    getMedicalCheckupMasterWithoutOptionByName,
    getAllMedicalCheckupMastersByIds
  }
}

export {
  useFirstMedicalCheckupMaster,
  useGetMedicalCheckupMasterByName,
  useGetMedicalCheckupMasterDetails,
  useMedicalCheckupMaster
}
