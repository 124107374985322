import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Empty, Flex, List, Popover, Typography } from 'antd'

import CategoryItem from './components/CategoryItem'
import ModalDeleteConfirm from './components/ModalDeleteConfirm'
import { searchValues } from './helper'
import { DataItemMaster } from './types/filterItemMaster'
import { QuestionCircleFilled } from '@ant-design/icons'
import { ReactComponent as TrashIcon } from 'assets/imgs/trash.svg'
import { ReactComponent as AddItemIcon } from 'assets/svgs/plus–42.svg'
import ModalConfirm from 'components/modals/ModalConfirm'
import { LIST_DRAG_AND_DROP } from 'configs/constant'
import useDebounce from 'hooks/useDebounce'

type Props = {
  isAddNewCategory: boolean
  showInspectionDescription: boolean
  showUpdate: boolean
  setIsAddNewCategory: (boolean: boolean) => void
  category: DataItemMaster[]
  isAddNewItemCategory: boolean
  setIsAddNewItemCategory: (boolean: boolean) => void
  language: string
  setCategoryHidden: React.Dispatch<React.SetStateAction<DataItemMaster[]>>
  handleRestore: () => void
  searchTerm?: string
}

const CategoryAndItemList = ({
  showInspectionDescription,
  showUpdate,
  setIsAddNewCategory,
  category,
  setIsAddNewItemCategory,
  language,
  setCategoryHidden,
  handleRestore,
  searchTerm = ''
}: Props) => {
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [isModalDeleteConfirm, setIsModalDeleteConfirm] = React.useState(false)
  const [listCategory, setListCategory] = React.useState<DataItemMaster[]>([])

  const [{ listDeleteRefId, ListDeleteItems }, setListDelete] = React.useState<{
    listDeleteRefId: string[]
    ListDeleteItems: DataItemMaster[]
  }>({
    listDeleteRefId: [],
    ListDeleteItems: []
  })

  React.useEffect(() => {
    setListCategory(category)
  }, [category])

  const searchTermDebounced = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    const searchValue = searchValues(
      searchTermDebounced,
      [...category],
      language
    )
    setListCategory(searchValue)
  }, [category, language, searchTermDebounced])

  const showModalReset = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleDeleteItem = () => {
    const filterList = listCategory.filter((item) => !item.isChecked)
    const newList = filterList.map((item) => {
      if (item.items) {
        item.items = item.items.filter((i) => !i.isChecked)
      }

      return item
    })
    setListCategory(newList)
    setIsModalDeleteConfirm(false)
    setCategoryHidden(newList)
  }

  const handleCheckedChange = React.useCallback(
    (itemChecked: DataItemMaster, checked: boolean, isCheckAll?: boolean) => {
      setCategoryHidden((prev) =>
        prev.map((item) => {
          if (isCheckAll) {
            // Update the whole category when checking all
            if (item.refId !== itemChecked.refId) return item

            return {
              ...item,
              isChecked: checked,
              items: (item.items ?? []).map((subItem) => ({
                ...subItem,
                isChecked: checked
              }))
            }
          }

          // Update a specific item's checked state
          const updatedItems = (item.items ?? []).map((subItem) => ({
            ...subItem,
            isChecked:
              subItem.refId === itemChecked.refId ? checked : subItem.isChecked
          }))

          const isCategoryChecked = updatedItems.every(
            (subItem) => subItem.isChecked
          )

          return {
            ...item,
            isChecked: isCategoryChecked,
            items: updatedItems
          }
        })
      )
    },
    [setCategoryHidden]
  )

  const handleRestoreItem = () => {
    handleRestore()
    setIsModalVisible(false)
  }

  const handleOpenModalDelete = React.useCallback(() => {
    const categoriesDelete = listCategory.reduce(
      (
        acc: { arr: DataItemMaster[]; arrRefId: string[] },
        item: DataItemMaster
      ) => {
        if (item.isChecked) {
          acc.arr.push(item)
          acc.arrRefId.push(
            item.refId,
            ...(item.items || [])?.map((i) => i.refId)
          )
        } else {
          if (item.items?.length && item.items.some((i) => i.isChecked)) {
            const filterSubList = item.items.filter((i) => i.isChecked)
            acc.arrRefId.push(...filterSubList.map((i) => i.refId))
            acc.arr.push({
              ...item,
              items: filterSubList
            })
          }
        }

        return acc
      },
      { arr: [], arrRefId: [] }
    )
    setListDelete({
      ListDeleteItems: categoriesDelete.arr,
      listDeleteRefId: categoriesDelete.arrRefId
    })
    setIsModalDeleteConfirm(categoriesDelete.arrRefId.length > 0)
  }, [listCategory, setIsModalDeleteConfirm])

  return (
    <div className="rounded-lg shadow-md bg-[#fff] pr-[11px] pt-2 pl-[13px] flex-1 pb-6 category-item-list h-max">
      <Flex className="items-center pl-[9px] justify-between">
        <Flex className="gap-[3px]">
          <Typography className="font-bold">
            {t('currentHiddenTest')}
          </Typography>
          <Popover
            placement="top"
            content={
              <div className="p-2 w-[330px] bg-[#545454] text-white rounded">
                {t('popover.hideByDragAndDrop')}
              </div>
            }
            overlayClassName="popover-permission"
          >
            <QuestionCircleFilled className="text-sm text-[#B4B4B4]" />
          </Popover>
        </Flex>

        <Flex className="gap-2 items-center">
          <TrashIcon
            className="cursor-pointer w-4"
            onClick={handleOpenModalDelete}
          />
          <Typography
            className="border border-[#137695] rounded-2xl text-[#137695] font-bold text-[12px] py-1.5 pl-[9px] pr-[3px] leading-none cursor-pointer"
            onClick={showModalReset}
          >
            {t('restoreDefaultSetting')}
          </Typography>
        </Flex>
      </Flex>

      <List className="mt-[21px] mr-[-10px] max-h-[700px] overflow-y-scroll overflow-x-hidden">
        <Droppable
          droppableId={`${LIST_DRAG_AND_DROP.LIST2}`}
          type={LIST_DRAG_AND_DROP.CATEGORY}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${listCategory?.length < 1 ? 'pb-[60px]' : ''}`}
            >
              {listCategory?.length ? (
                listCategory?.map((item, index) => (
                  <CategoryItem
                    key={item.refId}
                    category={item}
                    index={index}
                    showInspectionDescription={showInspectionDescription}
                    language={language}
                    showUpdate={showUpdate}
                    onChecked={handleCheckedChange}
                  />
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </List>

      <div className="pt-4">
        <Flex
          className="gap-[5px] cursor-pointer w-max"
          onClick={() => setIsAddNewCategory(true)}
        >
          <AddItemIcon className="bg-[#137695] h-[20px] w-[20px] rounded-sm" />
          <Typography className="text-[14px] text-[#137695] font-semibold">
            {t('newCategoryAdded')}
          </Typography>
        </Flex>

        <Flex
          className="gap-[5px] mt-[14px] cursor-pointer w-max"
          onClick={() => setIsAddNewItemCategory(true)}
        >
          <AddItemIcon className="bg-[#137695] h-[20px] w-[20px] rounded-sm" />
          <Typography className="text-[14px] text-[#137695] font-semibold">
            {t('newTestItemsAdded')}
          </Typography>
        </Flex>
      </div>
      <div>
        {isModalVisible && (
          <ModalConfirm
            isModalVisible
            handleCancel={handleCancel}
            handleOk={handleRestoreItem}
            title={t('content.initTestItemSetting')}
            classNames={{
              classNameButtonCancel: '!border-primary !text-primary',
              classNameButtonOk: '!bg-primary '
            }}
            content={
              <div className="mb-32">
                <p>{t('content.initTestItemWarning1')}</p>
                <p>{t('content.initTestItemWarning2')}</p>
              </div>
            }
          />
        )}
        {isModalDeleteConfirm && (
          <ModalDeleteConfirm
            isModalVisible
            ListDeleteItems={ListDeleteItems}
            listDeleteRefId={listDeleteRefId}
            handleCancel={() => setIsModalDeleteConfirm(false)}
            handleOk={handleDeleteItem}
          />
        )}
      </div>
    </div>
  )
}

export default CategoryAndItemList
