import { useTranslation } from 'react-i18next'

import { ReactComponent as PenWhiteIcon } from 'assets/svgs/pen-white.svg'
import { ReactComponent as TrashWhiteIcon } from 'assets/svgs/trash-white.svg'

export interface MessageHeaderProps {
  title?: string
  onOpenDrawer?: () => void
  onOpenModalRemoveBlock?: () => void
}

export function MessageHeader({
  title,
  onOpenModalRemoveBlock,
  onOpenDrawer
}: MessageHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="rounded-t-[10px] flex justify-between items-center bg-gradient-to-r from-[#137695] to-[#93D1CA] py-2 px-3">
      {title && <span className="text-white font-bold">{t(title)}</span>}
      <div className="flex items-center gap-2">
        <PenWhiteIcon className="cursor-pointer" onClick={onOpenDrawer} />
        <TrashWhiteIcon
          className="cursor-pointer"
          onClick={onOpenModalRemoveBlock}
        />
      </div>
    </div>
  )
}
