import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Select, SelectProps, Space, Tooltip, Typography } from 'antd'

import { ReactComponent as FilterOutlined } from 'assets/svgs/FilterOutlined.svg'
import { preventEnterKeyDefault } from 'utilities/helpers'

interface ISelectDropdown {
  departments: SelectProps['options']
  name: string
}
export const SelectDropdown = (prop: ISelectDropdown) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Controller
      name={prop.name}
      control={control}
      render={({ field }) => (
        <Select
          maxTagCount="responsive"
          {...field}
          mode="multiple"
          className="w-full max-w-[200px] departmentSelect"
          suffixIcon={
            <FilterOutlined className="absolute top-[-2px] right-[-4px] cursor-pointer" />
          }
          style={{ width: '100%' }}
          placeholder={t('questionnaire.departmentSelect')}
          defaultValue={[]}
          options={prop.departments}
          popupClassName="departmentPopupSelect"
          menuItemSelectedIcon={null}
          onInputKeyDown={preventEnterKeyDefault}
          optionRender={(option) => {
            const itemCheck = option.value ?? ''
            return (
              <Space>
                <Checkbox
                  key={option.key}
                  checked={field.value.includes(itemCheck.toString())}
                  onKeyDown={preventEnterKeyDefault}
                ></Checkbox>
                <Typography.Text className="text-[14px]">
                  {option.label}
                </Typography.Text>
              </Space>
            )
          }}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: 'none'
              }}
              title={omittedValues.map(({ label }) => label).join('、')}
            >
              <span>+ {omittedValues.length} ...</span>
            </Tooltip>
          )}
        ></Select>
      )}
    />
  )
}
