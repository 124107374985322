import React, {
  ChangeEvent,
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  Flex,
  Input,
  InputNumber,
  Table,
  TableColumnsType,
  Typography
} from 'antd'
import dayjs from 'dayjs'
import { RRule } from 'rrule'

import { TAB, cleanData } from '../ProductSetting'
import styles from './scss/TabbleWrapper.module.scss'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useGetMedicalCheckupMasterByName } from 'hooks/useMedicalCheckupMaster'
import useProductSetting from 'hooks/useProductSetting'
import useTimeBlockChecker from 'hooks/useTimeChecker'
import useTimeChecker from 'hooks/useTimeChecker'
import useTimeConfig, {
  SpecialConfig,
  initialSpecialConfig
} from 'hooks/useTimeConfig'
import { AvailableSlotsByDate } from 'models/productManagement'
import { generateDateRange, getWeekRange } from 'utilities/helpers'

dayjs.locale('ja')

const maxDisplayedDate = 7

type Props = {
  submissionCount: number
  setIsChange: (isChange: boolean) => void
}

const ReservationDate: FC<Props> = memo(({ submissionCount, setIsChange }) => {
  const { t, i18n } = useTranslation()
  const { search } = useLocation()
  const { setValue, getValues } = useFormContext()

  const { getMedicalCheckupMastersByIds } = useGetMedicalCheckupMasterByName()
  const { onFilterAvailableSlotByDate } = useProductSetting()
  const { onCreateTimeConfig } = useTimeConfig()

  const { checkTime } = useTimeChecker()

  const queryParams = new URLSearchParams(search)
  const {
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam,
    tab: tabParam
  } = Object.fromEntries(queryParams)

  const [loading, setLoading] = useState(false)
  const [initialData, setInitialData] = useState<AvailableSlotsByDate[]>()
  const [config, setConfig] = useState<SpecialConfig>(initialSpecialConfig)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const dateRange =
    startDateParam && endDateParam
      ? {
          startDate: startDateParam.split('T')[0],
          endDate: endDateParam.split('T')[0]
        }
      : getWeekRange()

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const data = await getMedicalCheckupMastersByIds([courseParam])
      if (data?.length > 0) {
        const config: SpecialConfig = await onCreateTimeConfig()
        const result = await onFilterAvailableSlotByDate({
          medicalCheckupMasterRefId: courseParam,
          daterange: dateRange
        })
        setConfig(config)
        setInitialData(result?.data || [])
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }, [getMedicalCheckupMastersByIds, onFilterAvailableSlotByDate])

  useEffect(() => {
    setCurrentIndex(0)
    // setInitialData(undefined)
    if (tabParam === TAB.RESERVATION_DATE && courseParam) {
      fetchData().finally()
    }
  }, [courseParam, startDateParam, endDateParam, submissionCount])

  const createRRuleData = (dateString: string, time: string, value: number) => {
    const [hours, minutes] = time.split(':').map(Number)
    const date = new Date(dateString)
    const dateTime = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        hours,
        minutes,
        0
      )
    )

    const rule = new RRule({
      freq: RRule.WEEKLY,
      dtstart: dateTime,
      count: 1,
      byhour: [hours],
      byminute: [minutes],
      bysecond: [0]
    })

    return {
      medicalCheckupMasterRefId: courseParam,
      emptySlotCount: value,
      datetimeRule: rule.toString().replace('\n', '_')
    }
  }

  const handleCreateRRuleData = (
    date: string,
    time: string,
    value: number | undefined,
    refId?: string,
    rule?: string
  ) => {
    let data

    if (!value) {
      data =
        refId && rule
          ? {
              medicalCheckupMasterRefId: courseParam,
              medicalCheckupEmptySlotRefId: refId,
              emptySlotCount: 0,
              datetimeRule: rule
            }
          : undefined
    } else {
      data =
        refId && rule
          ? {
              medicalCheckupMasterRefId: courseParam,
              medicalCheckupEmptySlotRefId: refId,
              emptySlotCount: value,
              datetimeRule: rule
            }
          : createRRuleData(date, time, value)
    }

    setValue(`rrule.date.${date}-${time}`, data)

    if (Object.keys(cleanData(getValues('rrule.date'))).length === 0) {
      setIsChange(false)
    } else {
      setIsChange(true)
    }
  }

  const validDates = generateDateRange(dateRange)
  const displayedDates = validDates.slice(
    currentIndex,
    currentIndex + maxDisplayedDate
  )

  const getDayOfWeek = (dateString: string, language: string) => {
    dayjs.locale(language)
    const day = dayjs(dateString)
    const dayOfWeek = day.day()
    const dayColor =
      dayOfWeek === 0 ? '#DF247530' : dayOfWeek === 6 ? '#CEECFE' : '#EFF3F6'
    const textColor =
      dayOfWeek === 0 ? '#DF2475' : dayOfWeek === 6 ? '#3089BF' : '#545454'

    return (
      <div className={`bg-[${dayColor}] mx-[1px] py-3 text-center text-[16px]`}>
        <Typography className={`text-[${textColor}]`}>
          {day.format('MM/DD')} ({day.format('ddd')})
        </Typography>
      </div>
    )
  }

  const initialDataMap = useMemo(
    () =>
      initialData?.reduce(
        (acc, item) => {
          acc[`date.${item.date}-${item.time}`] = item
          return acc
        },
        {} as Record<string, AvailableSlotsByDate>
      ),
    [initialData]
  )

  const renderCell = (date: string, time: string) => {
    const key = `date.${date}-${time}`
    const foundItem = initialDataMap ? initialDataMap[key] : undefined
    const initialVal = getValues(key) ?? foundItem?.emptySlot ?? undefined

    if (getValues(key) !== initialVal) {
      setValue(key, initialVal)
    }

    const isDisabled = checkTime(date, time, config)

    const handleChange = (value: number) => {
      setValue(key, value)
      handleCreateRRuleData(
        date,
        time,
        value,
        foundItem?.refId,
        foundItem?.rule
      )
    }

    return (
      <Flex justify="center">
        <InputNumber
          key={`${courseParam}.${tabParam}.${date}-${time}`}
          disabled={isDisabled}
          className="h-[30px] w-[80px]"
          defaultValue={initialVal}
          maxLength={3}
          min={0}
          max={100}
          onChange={(value) => handleChange(value)}
          controls={false}
        />
      </Flex>
    )
  }

  const dataSource = useMemo(
    () =>
      config?.time.map((time) => {
        const row: { key: string; time: string; [key: string]: ReactNode } = {
          key: time,
          time
        }

        displayedDates.forEach((date) => {
          row[date] = renderCell(date, time)
        })

        return row
      }),
    [displayedDates]
  )

  const columns: TableColumnsType<{
    time: string
    [key: string]: ReactNode
  }> = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'time',
        key: 'time',
        width: '4%',
        render: (value) => (
          <Typography className="text-center">{value}</Typography>
        )
      },
      ...displayedDates.map((date) => ({
        title: getDayOfWeek(date, i18n.language),
        dataIndex: date,
        width: '9%'
      })),
      {
        title: '',
        render: () => null
      }
    ],
    [t, displayedDates]
  )

  return initialData ? (
    <>
      <Table
        className={`${styles.table_wrapper} mt-[-10px] py-4 pt-0`}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <CaretLeftOutlined
        style={{
          color: currentIndex === 0 ? '#BFC6CB' : '#137695',
          cursor: currentIndex === 0 ? 'not-allowed' : 'pointer'
        }}
        onClick={() => {
          if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - maxDisplayedDate)
          }
        }}
        className="absolute left-[2%] top-[1.8%]"
      />

      <CaretRightOutlined
        style={{
          color:
            currentIndex + maxDisplayedDate >= validDates.length
              ? '#BFC6CB'
              : '#137695',
          cursor:
            currentIndex + maxDisplayedDate >= validDates.length
              ? 'not-allowed'
              : 'pointer'
        }}
        onClick={() => {
          if (currentIndex + maxDisplayedDate < validDates.length) {
            setCurrentIndex((prev) => prev + maxDisplayedDate)
          }
        }}
        className="absolute left-[68.5%] top-[1.8%]"
      />
    </>
  ) : (
    <Typography className="ml-8 pb-14 pt-10 text-[#C4C6CE]">
      {t('productSetting.content.notice')}
    </Typography>
  )
})

export default ReservationDate
