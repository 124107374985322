import { atom } from 'recoil'

import { FilterDataType, RoomsAtomState } from './types'
import { LoadMoreMessages, RoomsDataPayload } from 'pages/Chat/types/types'
import { ChatMessageType } from 'pages/CustomerDetail/components/controlPanel/ChatContainer'

export const messagesContainerRefAtom = atom<HTMLDivElement | null>({
  key: 'messagesContainerRefAtom',
  default: null
})

const initialFilterInfo: FilterDataType = {
  fullName: '',
  gender: [],
  startAge: null,
  endAge: null,
  dates: null
}

const initialLoadMoreRoom = {
  isLoading: false,
  isLoadedFull: false,
  isLoadingLoadMore: false
}

const initialLoadMoreMessages = {
  isLoading: false,
  isLoadedFull: false,
  isLoadingLoadMore: false,
  timestamp: '0'
}

const filterInfoAtom = atom<FilterDataType>({
  key: 'filterDataType',
  default: initialFilterInfo
})

const loadMoreMessagesAtom = atom<LoadMoreMessages>({
  key: 'loadMoreMessagesAtom',
  default: initialLoadMoreMessages
})

const messageListAtom = atom<ChatMessageType[]>({
  key: 'msgListAtom',
  default: []
})

const loadMoreRoomAtom = atom({
  key: 'loadMoreRoomAtom',
  default: initialLoadMoreRoom
})

const typingMessageAtom = atom<{
  userName: string
  isTyping: boolean
}>({
  key: 'typingMessageAtom',
  default: undefined
})

const roomsAtomRooms = atom<RoomsAtomState>({
  key: 'roomsAtom',
  default: {
    isLoading: false,
    rooms: [] as RoomsDataPayload[]
  }
})

const roomsSortedAtom = atom({
  key: 'roomsSortedAtom',
  default: [] as RoomsDataPayload[]
})

// const roomsSortedAtom = selector({
//   key: 'roomsSortedAtom',
//   get: ({ get }) => {
//     const rooms = get(roomsAtomRooms)
//     const filterInfo = get(filterInfoAtom)

//     const roomsAppliedFilter = applyFilterForRooms(
//       rooms.rooms ?? [],
//       filterInfo
//     )

//     console.log('roomsAppliedFilter', filterInfo, rooms.rooms)

//     const tab = get(tabsAtom)

//     return sortRoomsByTab(roomsAppliedFilter, tab)
//   }
// })

export {
  initialFilterInfo,
  initialLoadMoreRoom,
  initialLoadMoreMessages,
  filterInfoAtom,
  loadMoreMessagesAtom,
  messageListAtom,
  loadMoreRoomAtom,
  typingMessageAtom,
  roomsAtomRooms,
  roomsSortedAtom
}
