import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

import { ReservationInfoProps } from '../types'
import RowInfo from './RowInfo'
import { uuid } from 'utilities/helpers'

const ReservationInfo = ({
  rowInfoLeft,
  rowInfoRight
}: ReservationInfoProps) => {
  const { t } = useTranslation()

  return (
    <div className="w-full mt-5 bg-white rounded-[8px] px-3 drop-shadow-md">
      <p className="text-sm text-default py-5">
        {t('reservationRegister.modal.areYouSure1')}
      </p>

      <Flex className="gap-44 pb-3">
        <Flex className="flex-col gap-2">
          {rowInfoLeft.map((row) => (
            <RowInfo
              key={`${row.title}-${uuid()}`}
              title={row.title}
              value={row.value}
            />
          ))}
        </Flex>
        <Flex className="flex-col gap-2">
          {rowInfoRight.map((row) => (
            <RowInfo
              key={`${row.title}-${uuid()}`}
              title={row.title}
              value={row.value}
            />
          ))}
        </Flex>
      </Flex>
    </div>
  )
}

export default ReservationInfo
