import React, { FC, useRef, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import {
  Button,
  Flex,
  Popover,
  Table,
  TableColumnsType,
  Typography
} from 'antd'
import { TFunction } from 'i18next'

import { CustomerDetailInputTable } from '../../../models/customer'
import ResultPreviewModal from './modals/ResultPreviewModal'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { ReactComponent as Copy } from 'assets/svgs/copy.svg'
import CustomPagination from 'components/elements/CustomPagination'
import { openNotification } from 'components/widgets/Notification'
import { MEDICAL_CHECKUP_STATUS, VALUE_WITH_NO_DATA } from 'configs/constant'
import { useUpdateMedicalCheckup } from 'hooks/useMedicalCheckup'
import useMedicalCheckup from 'hooks/useMedicalCheckup'
import { MedicalCheckup } from 'models/medicalCheckup'
import { StringKeyObject } from 'types/common'

type Props = {
  t: TFunction<'translation', undefined>
  data: MedicalCheckup[]
  customer: any
  page: any
  size: any
  totalRecords: any
  setPage: any
  loadingMedical: any
  language: any
}

const CustomerDetailTable: FC<Props> = ({
  t,
  data,
  customer,
  page,
  size,
  totalRecords,
  setPage,
  loadingMedical,
  language
}) => {
  const navigate = useNavigate()
  const fileInputRef = useRef<any>(null)

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        try {
          const json = JSON.parse(e.target.result)
          // Todo call api
          console.log(json)
        } catch (err) {
          openNotification({
            type: 'error',
            title: t('errors.invalidFileFormat'),
            message: t('errors.fileMustBeJSON')
          })
        }
        // fileInputRef.current.value = ''
      }
      reader.readAsText(selectedFile)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const [id, setId] = useState<string>('')
  const [listId, setListId] = useState<string[]>([])
  const [listData, setListData] = useState<StringKeyObject[]>([])
  const [showResultPreview, setShowResultPreview] = useState<boolean>(false)
  const { getMedicalCheckupList } = useMedicalCheckup()
  const { updateMedicalCheckup } = useUpdateMedicalCheckup()
  const handleCopy = async (
    id: string,
    listId: string[],
    data: StringKeyObject[]
  ) => {
    setShowResultPreview(() => {
      setListId(listId)
      setListData(data)
      setId(id)
      return true
    })
  }
  const handleEdit = (refIfd: string) => {
    navigate({
      pathname: '/interview-result-create',
      search: createSearchParams({
        t: '1',
        refId: refIfd //hard code
      }).toString()
    })
  }
  const handleDelete = async (refId: string) => {
    const filterMedicalCheckup = await getMedicalCheckupList({
      filter: `(like,STRING,refId,${refId});(ne,STRING,additionalInfo.status,DELETED)`,
      page: 0,
      size: -1,
      sortBy: '(desc,createdDate)'
    })

    const { visibility, createdBy, createdDate, ...dataMedicalCheckupUpdate } =
      filterMedicalCheckup?.data?.filterMedicalCheckupDetails?.payload?.[0]
        ?.medicalCheckup || {}

    const requestMedicalCheckup = [
      {
        ...dataMedicalCheckupUpdate,
        additionalInfo: {
          ...dataMedicalCheckupUpdate?.additionalInfo,
          status: 'DELETED'
        }
      }
    ]
    await updateMedicalCheckup(requestMedicalCheckup)
    window.location.reload()
  }

  const columns: TableColumnsType = [
    {
      title: (
        <Flex justify="center">
          <Typography className="mr-4 font-bold tracking-[1.4px]">
            {t('customerDetail.table.title.consultationDate')}
          </Typography>
          <Flex align="center" className="cursor-pointer flex-col">
            <CaretUpOutlined style={{ fontSize: '10px' }} />
            <CaretDownOutlined style={{ fontSize: '10px' }} />
          </Flex>
        </Flex>
      ),
      dataIndex: 'consultationDate',
      key: 'consultationDate',
      width: 200,
      render: (text) => (
        <Flex justify="center">
          <Typography className="font-bold tracking-[1.4px]">{text}</Typography>
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center">
          <Typography className="font-bold tracking-[1.4px]">
            {t('customerDetail.table.title.result')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'key',
      key: 'key',
      width: 120,
      render: (_, record) => (
        <Flex justify="center" className="cursor-pointer">
          <Copy onClick={() => handleCopy(record.id, record.listIds, data)} />
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center">
          <Typography className="font-bold tracking-[1.4px]">
            {t('customerDetail.table.title.registerDate')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: 250,
      render: (text) => (
        <Flex justify="center">
          <Typography className="font-bold tracking-[1.4px]">{text}</Typography>
        </Flex>
      )
    },
    {
      title: (
        <Flex justify="center">
          <Typography className="font-bold tracking-[1.4px]">
            {t('customerDetail.table.title.result')}
          </Typography>
        </Flex>
      ),
      dataIndex: 'badTestResults',
      key: 'badTestResults',
      width: 500,
      render: (_, record) => {
        let result = record.badTestResults
        let showPopover = false
        if (result && result.length > 3) {
          result = result.slice(0, 3)
          showPopover = true
        }
        return (
          <Flex>
            <div className="w-[24%] ml-[20px] mr-5 flex flex-col justify-center items-center">
              <Typography className="text-[32px] font-bold text-[#0588D8]">
                {record.evaluation}
              </Typography>
              <Typography className="text-[#0588D8] font-bold text-nowrap">
                {t('customerDetail.table.detect')}
              </Typography>
            </div>
            <div className="mr-8 w-[80%] ">
              {result.map((item: any, index: any) => (
                <div key={index} className="">
                  <Flex className="mb-1">
                    <Typography className="text-[12px] w-[40%] h-auto font-bold tracking-[1.2px]">
                      {item.categoryLang?.[language] ?? VALUE_WITH_NO_DATA}
                    </Typography>
                    <Typography className="ml-[30px] w-[60%] text-[12px] tracking-[1.2px]">
                      {item.lang?.[language] ?? VALUE_WITH_NO_DATA} {item.value}{' '}
                      {item.unitOfMeasure}
                    </Typography>
                  </Flex>
                </div>
              ))}
              <div className="justify-end flex">
                {showPopover && (
                  <Popover
                    placement="bottom"
                    content={
                      <div className="bg-[#545454] justify-end">
                        {record.badTestResults.map((item: any, index: any) => (
                          <div key={index} className="m-1 w-[180px]">
                            <Flex className="mb-1 w-auto">
                              <Typography className="text-[12px] w-[40%] h-auto text-white">
                                {item.categoryLang?.[language] ??
                                  VALUE_WITH_NO_DATA}
                              </Typography>
                              <Typography className="text-[12px] w-[60%] pl-[10px] text-white h-auto">
                                {item.lang?.[language] ?? VALUE_WITH_NO_DATA}{' '}
                                {item.value} {item.unitOfMeasure}
                              </Typography>
                            </Flex>
                          </div>
                        ))}
                      </div>
                    }
                    overlayClassName="popover-permission"
                  >
                    {t('lable.detail')}＞
                  </Popover>
                )}
              </div>
            </div>
          </Flex>
        )
      }
    },
    {
      title: (
        <Flex justify="center">
          <Typography className="mr-4 font-bold tracking-[1.4px]">
            {t('customerDetail.table.title.doctorName')}
          </Typography>
          <Flex align="center" className="cursor-pointer flex-col">
            <CaretUpOutlined style={{ fontSize: '10px' }} />
            <CaretDownOutlined style={{ fontSize: '10px' }} />
          </Flex>
        </Flex>
      ),
      dataIndex: 'doctorName',
      key: 'doctorName',
      width: 200,
      render: (text) => (
        <Flex justify="center" className="text-[#707070] tracking-[1.4px]">
          {text}
        </Flex>
      )
    },
    {
      title: <></>,
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Flex justify="end">
          <Button
            autoInsertSpace={false}
            disabled={+record.status === MEDICAL_CHECKUP_STATUS.SUBMITTED}
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => handleEdit(record.id)}
            className="h-[30px] w-[80px] rounded-[4px] bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
          >
            {t('customerDetail.table.edit')}
          </Button>
          <Button
            autoInsertSpace={false}
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => handleDelete(record.id)}
            className="ml-6 h-[30px] w-[80px] rounded-[4px] bg-[#BDCBD5] p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
          >
            {t('customerDetail.table.delete')}
          </Button>
        </Flex>
      )
    }
  ]

  const dataSource: (CustomerDetailInputTable & { key: number })[] = data.map(
    (item, index) => ({
      ...item,
      key: index
    })
  )
  return (
    <div className="pr-[160px]">
      {/*widget*/}
      <Flex className="mb-[34px] w-full pt-[12px]" justify="end">
        <input
          type="file"
          accept=".json"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
        />
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          onClick={handleButtonClick}
          className="invisible mr-[8px] h-[30px] w-[120px] rounded-none border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] p-0 text-center text-[14px] font-bold text-white shadow-none"
        >
          {t('customerDetail.table.button.enterResult')}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          onClick={() =>
            navigate({
              pathname: '/interview-result-create',
              search: createSearchParams({
                t: '1',
                userRefId: customer?.id
              }).toString()
            })
          }
          className="mr-[8px] h-[30px] w-[120px] rounded-none border-[1px] border-[#137695] bg-white p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
        >
          {t('customerDetail.table.button.register1')}
        </Button>
      </Flex>
      {/*table*/}
      <div>
        <Table
          style={{ borderBottom: 'none' }}
          className=""
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loadingMedical}
        />
      </div>
      <div className="mt-[20px]">
        {totalRecords > 0 && (
          <CustomPagination
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
          />
        )}
      </div>
      <ResultPreviewModal
        id={id}
        listId={listId}
        setId={setId}
        data={listData}
        customer={customer}
        isModalOpen={showResultPreview}
        onCancel={() => setShowResultPreview(false)}
        language={language}
      />
    </div>
  )
}

export default CustomerDetailTable
