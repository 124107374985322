import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, DatePicker, Flex } from 'antd'
import dayjs from 'dayjs'

import styles from './scss/DatePickerWrapper.module.scss'
import { ReactComponent as DatePickerIcon } from 'assets/svgs/date_picker_none_bg.svg'

type Props = {
  name: string
  dateString?: string
  time: boolean
  control: Control
  onUpdate: () => void
}

const RowForm: React.FC<Props> = ({
  name,
  dateString,
  time,
  control,
  onUpdate
}) => {
  const { t } = useTranslation()
  const date = dateString ? dayjs(dateString) : undefined

  const disablePastDates = (current: dayjs.Dayjs) => {
    return current?.isBefore(dayjs(), 'day')
  }

  return (
    <div>
      <Flex justify="center" className="mb-[6px]">
        <Controller
          name={`${name}-date`}
          control={control}
          defaultValue={date}
          render={({ field }) => (
            <DatePicker
              {...field}
              format={t('reservationManagement.content.formatDate')}
              disabledDate={disablePastDates}
              inputReadOnly
              allowClear={false}
              suffixIcon={<DatePickerIcon />}
              style={{
                background: '',
                padding: 0,
                border: 'none',
                outline: 'none',
                boxShadow: 'none'
              }}
              onChange={(date) => {
                field.onChange(date)
                onUpdate()
              }}
              className={`${styles.date_wrapper} mr-4`}
            />
          )}
        />
      </Flex>

      <Controller
        control={control}
        name={`${name}-time`}
        defaultValue={time}
        render={({ field }) => (
          <Flex align="center" justify="center">
            <Button
              style={{
                backgroundColor: field.value ? '#13769526' : '',
                color: field.value ? '#137695' : '#D9E0E5',
                border: field.value ? '1px solid #137695' : '1px solid #D9E0E5'
              }}
              className="flex h-[26px] w-[40px] items-center justify-center rounded-l-[5px] border-r-0 px-0"
              autoInsertSpace={false}
              onClick={() => {
                field.onChange(true)
                onUpdate()
              }}
            >
              {t('reservationManagement.content.button.morning')}
            </Button>

            <Button
              style={{
                backgroundColor: !field.value ? '#13769526' : '',
                color: !field.value ? '#137695' : '#D9E0E5',
                border: !field.value ? '1px solid #137695' : '1px solid #D9E0E5'
              }}
              className="flex h-[26px] w-[40px] items-center justify-center rounded-r-[5px] border-r-0 px-0"
              autoInsertSpace={false}
              onClick={() => {
                field.onChange(false)
                onUpdate()
              }}
            >
              {t('reservationManagement.content.button.afternoon')}
            </Button>
          </Flex>
        )}
      />
    </div>
  )
}

export default RowForm
