import { ArrowProps } from '../types'

const ArrowRight = ({ onClick, disabled }: ArrowProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.34"
      height="24.561"
      viewBox="0 0 10.34 24.561"
      onClick={onClick}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        marginRight: '30px'
      }}
    >
      <path
        id="パス_39487"
        data-name="パス 39487"
        d="M-11472.389-11900l7.721,10.886-2.574,3.63-5.146,7.258"
        transform="translate(11473.782 11901.394)"
        fill="none"
        stroke={disabled ? '#BDCBD5' : '#137695'}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default ArrowRight
