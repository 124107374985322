import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { RESERVATION_STATUS } from '../../models/reservationManagement'
import Content from './components/Content'
import ReservationInformation from './components/ReservationInformation'
import ConfirmReservationDraftModal from './components/modals/ConfirmReservationDraftModal'
import ReservationNoticeModal from './components/modals/ReservationNoticeModal'
import { PATHS } from 'configs/constant'
import {
  useGetMedicalCheckupMasterByName,
  useGetMedicalCheckupMasterDetails
} from 'hooks/useMedicalCheckupMaster'
import useProductManagement from 'hooks/useProductManagement'
import useReservationManagement from 'hooks/useReservationManagement'
import useTimeConfig, {
  SpecialConfig,
  initialSpecialConfig
} from 'hooks/useTimeConfig'
import { DateRange, RemainedSlotByDate } from 'models/productManagement'
import { filterCourseResponseInValid, getWeekRange } from 'utilities/helpers'

export type DataForm = {
  refId: string
  userId: string
  userFullName: string
  courseId: string
  courseName: string
  courseOptionIds: string[]
  courseSelect?: string
  status: string
  desiredDateTime: { priority: number; date: string; timeOfDay: boolean }[]
  userInfo: { sex: string; age: string }
  action: 'confirm' | 'edit'
}

export type Slot = {
  date: string
  time: string
}

export default function ReservationChange() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const { onFilterRemainedSlotsByDate } = useProductManagement()
  const { onUpdateReservation } = useReservationManagement()
  const { onCreateTimeConfig } = useTimeConfig()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { getAllMedicalCheckupMastersByIds } =
    useGetMedicalCheckupMasterByName()

  const {
    refId,
    courseId,
    userId,
    userFullName,
    courseOptionIds,
    status,
    desiredDateTime,
    userInfo,
    action
  }: DataForm = state || {}

  const selectedDesiredDate = dayjs(
    desiredDateTime?.[1]?.date || desiredDateTime?.[0]?.date
  )

  const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
    getWeekRange(selectedDesiredDate)
  )
  const [remainedSlotsData, setRemainedSlotsData] =
    useState<RemainedSlotByDate[]>()
  const [selectedSlot, setSelectedSlot] = useState<Slot>()
  const [selectOptions, setSelectOptions] = useState<
    {
      value: string
      label: string
    }[]
  >([])
  const [courseName, setCourseName] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenModalNotice, setIsOpenModalNotice] = useState<boolean>(false)
  const [dataModal, setDataModal] = useState<{
    userId: string
    userFullName: string
    courseName: string
    courseOptions: string
    selectedDate: string
    status: string
    action: 'confirm' | 'edit'
  }>()

  const [config, setConfig] = useState<SpecialConfig>(initialSpecialConfig)
  const [selectedCourseId, setSelectedCourseId] = useState<string>(courseId)
  const [selectedCourseOptionsIds, setSelectedCourseOptionsIds] =
    useState<string[]>(courseOptionIds)

  useEffect(() => {
    setSelectedSlot(undefined)
  }, [loading])

  const fetchData = useCallback(
    async (courseId: string) => {
      try {
        setLoading(true)
        setSelectOptions([])
        const medicalCheckupMaster =
          await getMedicalCheckupMastersById(courseId)

        const medicalCheckupMasterName: string | undefined =
          medicalCheckupMaster?.medicalCheckupMaster?.displayName

        if (!medicalCheckupMasterName) {
          setCourseName('')
          setRemainedSlotsData(undefined)
          setSelectOptions([])
          return
        }

        setCourseName(medicalCheckupMasterName)
        const metadata: { name: string; value: string[] }[] | undefined =
          medicalCheckupMaster?.medicalCheckupMaster?.additionalInfo?.metadata

        if (metadata) {
          const options = metadata.find(
            (item) => item.name === 'options'
          )?.value

          if (Array.isArray(options) && options.length > 0) {
            const medicalCheckupMasterOptions =
              await getAllMedicalCheckupMastersByIds(options)

            const validData = filterCourseResponseInValid(
              medicalCheckupMasterOptions || [],
              userInfo
            )

            const selectOptions = validData.map(({ medicalCheckupMaster }) => ({
              value: medicalCheckupMaster.refId,
              label: medicalCheckupMaster.displayName
            }))

            setSelectOptions(selectOptions)
          }
        }

        const filterParams = {
          medicalCheckupMasterRefId: courseId,
          daterange: currentDateRange
        }
        const remainedSlots = await onFilterRemainedSlotsByDate(filterParams)
        const timeConfig = await onCreateTimeConfig()

        setRemainedSlotsData(remainedSlots)
        setConfig(timeConfig)
      } catch (e) {
        console.error(e)
      }
    },
    [onFilterRemainedSlotsByDate]
  )

  useEffect(() => {
    if (userId && selectedCourseId) {
      fetchData(selectedCourseId).finally(() => setLoading(false))
    } else {
      setRemainedSlotsData(undefined)
      setSelectOptions([])
    }
  }, [selectedCourseId, currentDateRange])

  const formatDate = t('reservationChange.label.formatDate')

  const handleOpenModal = () => {
    const selectedLabels = selectOptions
      .filter((option) => selectedCourseOptionsIds.includes(option.value))
      .map((option) => option.label)

    const statusText = (() => {
      switch (status) {
        case RESERVATION_STATUS.COLLECTED_DESIRED_DATE:
          return `${t('reservationManagement.widget.status.collectDate')} → ${t('reservationManagement.widget.status.waitConfirm')}`
        case RESERVATION_STATUS.UNCONFIRMED:
          return t('reservationManagement.widget.status.waitConfirm')
        default:
          return 'ー'
      }
    })()

    const data = {
      userId,
      userFullName,
      courseName,
      courseOptions: selectedLabels.join(','),
      selectedDate: selectedSlot
        ? `${dayjs(selectedSlot.date).format(formatDate)} ${selectedSlot.time}-`
        : '',
      status: statusText,
      action
    }
    setDataModal(data)

    setIsOpen(true)
  }

  const handleSubmit = async () => {
    try {
      const updateParams = {
        refId: refId,
        medicalCheckupMasterRefId: selectedCourseId,
        reservationDatetime: {
          datetime: `${selectedSlot?.date}T${selectedSlot?.time}:00`
        },
        optionRefIds: selectedCourseOptionsIds
      }

      await onUpdateReservation(updateParams).then(() => {
        setIsOpen(false)
        setIsOpenModalNotice(true)
      })
    } catch (e) {
      console.error(e)
      setIsOpen(false)
    }
  }

  const selectedText: string | undefined = useMemo(
    () =>
      selectedSlot
        ? `${dayjs(selectedSlot.date).format(formatDate)} ${selectedSlot.time}-`
        : undefined,
    [selectedSlot, formatDate]
  )

  return (
    <div className="w-full min-w-[1500px]">
      <Flex justify="space-between">
        <div>
          {action === 'confirm' && (
            <>
              <Typography className="text-base font-bold tracking-[1.6px]">
                {t('reservationChange.reservationChange1')}
              </Typography>

              <Typography className="mt-4 mb-3">
                {selectedSlot
                  ? t('reservationChange.changeNotes')
                  : t('reservationChange.selectNotes1')}
              </Typography>
            </>
          )}

          {action === 'edit' && (
            <>
              <Typography className="text-base font-bold tracking-[1.6px]">
                {t('reservationChange.reservationChange2')}
              </Typography>

              <Typography className="mt-4 mb-3">
                {t('reservationChange.selectNotes2')}
              </Typography>
            </>
          )}
        </div>

        <Typography
          onClick={() =>
            navigate({
              pathname: PATHS.RESERVATION_MANAGEMENT,
              search: createSearchParams({ tab: '2' }).toString()
            })
          }
          className="mr-3 cursor-pointer text-sm font-bold text-[#137695] underline"
        >
          ＜{t('button.return')}
        </Typography>
      </Flex>

      <ReservationInformation
        onUpdateCourseId={setSelectedCourseId}
        onUpdateCourseOptionIds={setSelectedCourseOptionsIds}
        selectedText={selectedText}
      />

      <Content
        loading={loading}
        onUpdateLoading={() => setLoading(true)}
        initialData={remainedSlotsData}
        config={config}
        dateRange={currentDateRange}
        setCurrentDate={setCurrentDateRange}
        setSelectedSlot={setSelectedSlot}
      />

      <Button
        type="primary"
        htmlType="submit"
        autoInsertSpace={false}
        onClick={handleOpenModal}
        disabled={!selectedSlot}
        style={{
          background: !selectedSlot ? '#BFC6CB' : '#137695',
          color: !selectedSlot ? 'white' : ''
        }}
        className="mt-4 h-[30px] min-w-[120px] rounded-[3px] border-none bg-[#BFC6CB] text-center text-sm font-bold text-white shadow-none"
      >
        {t('reservationChange.button.save')}
      </Button>

      <ConfirmReservationDraftModal
        isOpen={isOpen}
        initialData={dataModal}
        onOk={handleSubmit}
        onCancel={() => setIsOpen(false)}
      />

      <ReservationNoticeModal
        isOpen={isOpenModalNotice}
        onOk={() => {}}
        onCancel={() =>
          navigate({
            pathname: PATHS.RESERVATION_MANAGEMENT,
            search: createSearchParams({ tab: '2' }).toString()
          })
        }
      />
    </div>
  )
}
