import React from 'react'

function CreateScenarioLayout({
  headers,
  content
}: {
  headers: React.ReactNode
  content: React.ReactNode
}) {
  return (
    <div className="flex flex-col w-full h-full">
      {headers}
      {content}
    </div>
  )
}

export default CreateScenarioLayout
