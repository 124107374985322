import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Flex } from 'antd'
import dayjs from 'dayjs'

import { SpinLoading } from '../../../components/screens/Spin'
import Buttons from '../components/Buttons'
import PatientInfo from '../components/PatientInfo'
import ReservationInfo from '../components/ReservationInfo'
import {
  RegisterReservationBodyType,
  ResponseFetchCustomerInfo
} from '../types'
import { registerReservation, updateReservation } from '../utils/api'
import { convertDateTimeToISOString } from '../utils/functions'
import useCustomer from 'hooks/useCustomer'
import { TAB } from 'models/customer'
import { LocationState } from 'pages/CustomerDetail/types/types'
import SuccessRegisteredNotice from 'pages/ReservationRegister/components/modals/SuccessRegisteredNotice'

const PAGE = 0
const SIZE = 1

const ReservationDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // api get customer info
  const { onFilterCustomer } = useCustomer()

  // get customer id in params
  const [searchParams] = useSearchParams()
  const customerId = searchParams.get('cst-id')

  // get ids customer from location
  const location = useLocation()
  const { state }: { state: LocationState } = location

  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [loadingApi, setLoadingApi] = useState<boolean>(false)
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false)
  const [customerInfo, setCustomerInfo] = useState<ResponseFetchCustomerInfo>(
    {} as ResponseFetchCustomerInfo
  )

  useEffect(() => {
    if (!customerId) return

    const fetchCustomerInfo = async () => {
      try {
        setLoadingPage(true)
        const resFetchCustomerInfo = await onFilterCustomer({
          filter: `(eq,STRING,status,PUBLISHED);(like,STRING,refId,${customerId})`,
          page: PAGE,
          size: SIZE
        })

        setCustomerInfo(resFetchCustomerInfo)
      } catch (e) {
        console.log('error getCheckupUsers', e)
      } finally {
        setLoadingPage(false)
      }
    }

    fetchCustomerInfo()
  }, [customerId])

  const updateLocationStateAndNavigateToCustomerDetail = (index: number) => {
    navigate(`/customer-detail?id=${customerId}&currentTab=${TAB.CONTROL}`, {
      state: {
        ...state,
        location: { ...state.location, index },
        openListResultTab: TAB.CONTROL
      }
    })
  }

  const rowInfoLeft = [
    {
      title: t('reservationManagement.reservationDetail.patientId'),
      value: customerInfo.refId
    },
    {
      title: t('reservationManagement.reservationDetail.lastName'),
      value: customerInfo.lastName
    },
    {
      title: t('reservationManagement.reservationDetail.lastNameKana'),
      value: customerInfo.lastNameKana
    },
    {
      title: t('reservationManagement.reservationDetail.birthday'),
      value: dayjs(customerInfo.birthday).format('YYYY/MM/DD')
    },
    {
      title: t('reservationManagement.reservationDetail.course'),
      value: state.courseName
    },
    {
      title: t('reservationManagement.reservationDetail.option'),
      value: state.optionName || '-'
    },
    {
      title: t('reservationManagement.reservationDetail.date'),
      value: state.availableSlot.bydate
    },
    {
      title: t('reservationManagement.reservationDetail.time'),
      value: state.availableSlot.byhour
    }
  ]

  const rowInfoRight = [
    { title: '', value: '' },
    {
      title: t('reservationManagement.reservationDetail.firstName'),
      value: customerInfo.firstName
    },
    {
      title: t('reservationManagement.reservationDetail.firstNameKana'),
      value: customerInfo.firstNameKana
    }
  ]

  const onStartReservation = async () => {
    const datetime = String(
      convertDateTimeToISOString(
        state.availableSlot.bydate,
        state.availableSlot.byhour
      )
    )

    const body: RegisterReservationBodyType = {
      medicalCheckupMasterRefId: state.courseId,
      reservationDatetime: {
        datetime
      },
      optionRefIds: state.optionIds
    }

    // call api register reservation
    try {
      setLoadingApi(true)

      if (state.isUpdate) {
        body['refId'] = state.reservationId
        await updateReservation(body)
      } else {
        if (!customerId) return
        body['checkupUserRefIds'] = [customerId]
        await registerReservation(body)
      }
      setIsOpenModalSuccess(true)
    } catch {
      console.log('error register reservation')
    } finally {
      setLoadingApi(false)
    }
  }

  const handleNavigateToCustomerDetail = () => {
    navigate(`/customer-detail?id=${customerId}&currentTab=${TAB.CONTROL}`, {
      replace: true,
      state: {
        location: {
          ...state.location
        },
        openListResultTab: state.openListResultTab
      }
    })
  }

  if (loadingPage) {
    return (
      <Flex className="h-screen justify-center items-center">
        <SpinLoading loading={loadingPage} />
      </Flex>
    )
  }

  return (
    <div className="w-fit">
      {loadingApi && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-slate-50 opacity-30">
          <Flex className="h-full w-full justify-center items-center">
            <SpinLoading loading={loadingApi} />
          </Flex>
        </div>
      )}
      <PatientInfo
        avatar={customerInfo.avatar}
        id={customerInfo.refId}
        firstName={customerInfo.firstName}
        lastName={customerInfo.lastName}
        birthday={customerInfo.birthday}
        age={customerInfo.age}
        gender={customerInfo.gender}
        medicalStatus={Number(customerInfo.medicalStatus)}
        patientId={customerInfo.patientId}
      />
      <ReservationInfo rowInfoLeft={rowInfoLeft} rowInfoRight={rowInfoRight} />
      <Buttons
        confirm={() => onStartReservation()}
        cancel={() =>
          updateLocationStateAndNavigateToCustomerDetail(
            state.location.index - 1
          )
        }
      />

      <SuccessRegisteredNotice
        isOpen={isOpenModalSuccess}
        onCancel={() => {
          setIsOpenModalSuccess(false)
          handleNavigateToCustomerDetail()
        }}
      />
    </div>
  )
}

export default ReservationDetail
