import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Checkbox, Col, Flex, Radio, Row, Typography } from 'antd'

import { ChoiceTypeEnums, QuestionTypeEnums } from '../../../models/survey'
import { isTruthy } from '../../../utilities/helpers'

export const QuestionItemConfirmCard = ({
  question,
  displayEnglish,
  index
}: {
  question: any
  displayEnglish: boolean
  index: number
}) => {
  const { t } = useTranslation()

  return (
    <Flex gap="middle" vertical className="questionnaire-item">
      <Card size="small" className="questionnaire-item-card">
        <Row>
          <Col className="gutter-row" span={1}>
            <div>{index + 1}</div>
          </Col>
          <Col className="mb-5 pr-5" span={14}>
            <Flex vertical gap={0} className="">
              <Typography.Text style={{ color: '#707070' }}>
                {question.displayNameMap.ja}
              </Typography.Text>
              <Typography.Text style={{ color: '#707070' }}>
                {displayEnglish && question.displayNameMap.en}
              </Typography.Text>
            </Flex>
          </Col>
          <Col className="answer-col question-preview" span={6}>
            <Flex vertical>
              {question.questionType === QuestionTypeEnums.choice &&
                question.setting?.choiceType !== ChoiceTypeEnums.checkbox && (
                  <Radio.Group onChange={() => ({})} value="">
                    {question.options.map((option: any, idx: number) => (
                      <Radio
                        key={idx}
                        value={option.refId}
                        className="mr-[61px]"
                      >
                        {option.displayNameMap.ja}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              {question.questionType === QuestionTypeEnums.choice &&
                question.setting?.choiceType === ChoiceTypeEnums.checkbox && (
                  <Checkbox.Group onChange={() => ({})} value={[]}>
                    {question.options.map((option: any, idx: number) => (
                      <Checkbox key={idx} value={option.refId}>
                        {option.displayNameMap.ja}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                )}
              {question.questionType === QuestionTypeEnums.text && (
                <>
                  <Typography>テキスト形式</Typography>
                </>
              )}
            </Flex>
          </Col>
          <Col className="gutter-row" span={3}>
            <Flex gap="middle" className="btn-controller float-end">
              {isTruthy(question.isRequired) ? (
                <Typography.Text className="border-2 border-[#DF2475] px-2 py-1 rounded font-bold text-[#DF2475]">
                  {t('lable.required')}
                </Typography.Text>
              ) : (
                <Typography.Text className="border-2 px-2 py-1 rounded font-bold">
                  {t('lable.any')}
                </Typography.Text>
              )}
            </Flex>
          </Col>
        </Row>
      </Card>
    </Flex>
  )
}
