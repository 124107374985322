
import { Checkbox, Flex, Typography } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import { showName } from 'pages/InspectionItemSetting/helper'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import cn from 'utilities/cn'
import { getLanguage } from 'utilities/helpers'

const Item = ({
  item,
  showCode,
  showIcon,
  allowCheckbox = false,
  isCategory,
  className,
  classNames,
  onChecked
}: {
  item: DataItemMaster
  showCode?: boolean
  showIcon?: boolean
  allowCheckbox?: boolean
  isCategory?: boolean
  isBorder?: boolean
  className?: string
  classNames?: {
    classNameItem?: string
    classNameText?: string
    classNameCode?: string
  }
  onChecked?: (item: DataItemMaster, checked: boolean) => void
}) => {
  const language = getLanguage()
  return (
    <Flex
      className={cn(
        'w-full',
        {
          'pe-10': !isCategory && !showIcon && !allowCheckbox
        },
        className
      )}
      align="center"
      justify="space-between"
      gap={10}
    >
      {allowCheckbox && (
        <Checkbox
          className="ps-3"
          checked={item?.isChecked}
          onChange={(e) => onChecked?.(item, e.target.checked)}
        />
      )}
      <Flex
        className={cn('w-full grid grid-cols-2', classNames?.classNameItem)}
        align="center"
      >
        <Typography
          className={cn(
            'flex-1 py-1 col-span-2',
            {
              'col-span-1': showCode,
              'px-6': !allowCheckbox
            },
            classNames?.classNameText
          )}
        >
          {showName(item, language)}
        </Typography>
        {showCode && (
          <Typography
            className={cn(
              'flex-1 col-span-1 text-xs text-[#BDCBD5] tracking-[.6px]',
              classNames?.classNameCode
            )}
          >
            {item?.code?.coding?.find((it) => it.system === 'PHT')?.code}
          </Typography>
        )}
      </Flex>
      {showIcon && (
        <Flex className="gap-[3px] items-center mr-[11px]">
          <MenuOutlined className="text-base" />
        </Flex>
      )}
    </Flex>
  )
}

export default Item
