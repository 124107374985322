import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'

const { Paragraph } = Typography

export default function PasswordResetSuccess() {
  const { t } = useTranslation()

  return (
    <Flex vertical className="pt-[417px] text-center">
      <Typography className="text-primary font-bold leading-none tracking-[.7px]">
        {t('passwordResetCompleted')}
      </Typography>
      <Paragraph className="mt-[91px] mb-0">
        {t('content.passwordResetSuccess')}
      </Paragraph>
      <Link to="/login">
        <Button
          type="primary"
          htmlType="submit"
          className="h-10 mt-[86px] text-white text-base p-3 leading-none font-bold"
        >
          {t('backToLogin')}
        </Button>
      </Link>
    </Flex>
  )
}
