import React from 'react'

import useQueryForCourseTestItem from './useQueryForCourseTestItem'
import { MEDICAL_CHECKUP_MASTER_EXAMPLE } from 'configs/api'
import { LIST_DRAG_AND_DROP } from 'configs/constant'
import useTestItemMaster from 'hooks/useTestItemMaster'
import { DataItemMaster } from 'pages/InspectionItemSetting/types/filterItemMaster'
import { MedicalCheckupMaster } from 'types/MedicalCheckupMaster'
import { getLastPart } from 'utilities/helpers'

const useCourseTestItems = () => {
  //state for modal
  const [isModalDeleteConfirm, setIsModalDeleteConfirm] = React.useState(false)
  const [isModalRestoreConfirm, setIsModalRestoreConfirm] =
    React.useState(false)
  const [isShowAddCategoryItem, setIsShowAddCategoryItem] =
    React.useState(false)
  const [isShowAddTestItem, setIsShowAddTestItem] = React.useState(false)

  const [
    {
      originalListCategory,
      originalListTestItem,
      originalMedicalCheckupCourse
    },
    setOriginalList
  ] = React.useState<{
    originalListCategory: DataItemMaster[]
    originalListTestItem: DataItemMaster[]
    originalMedicalCheckupCourse: MedicalCheckupMaster | undefined
  }>({
    originalListCategory: [],
    originalListTestItem: [],
    originalMedicalCheckupCourse: undefined
  })

  const [{ listCategory, listTestItem }, setListData] = React.useState<{
    listCategory: DataItemMaster[]
    listTestItem: DataItemMaster[]
  }>({
    listCategory: [],
    listTestItem: []
  })

  const [categoryShow, setCategoryShow] = React.useState<DataItemMaster[]>([])
  const [categoryHidden, setCategoryHidden] = React.useState<DataItemMaster[]>(
    []
  )

  const [
    {
      initialShowCategoryRefIds,
      initialHiddenCategoryRefIds,
      initialHiddenItemRefIds,
      initialShowItemRefIds
    },
    setInitialItems
  ] = React.useState<{
    initialShowCategoryRefIds: string[]
    initialHiddenCategoryRefIds: string[]
    initialShowItemRefIds: string[]
    initialHiddenItemRefIds: string[]
  }>({
    initialShowCategoryRefIds: [],
    initialHiddenCategoryRefIds: [],
    initialHiddenItemRefIds: [],
    initialShowItemRefIds: []
  })

  const { getListItemCategory, getMedicalCheckupMastersById } =
    useQueryForCourseTestItem()
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  const fetchData = React.useCallback(async () => {
    try {
      const listItemCategory = await getListItemCategory()

      const listItemMaster = await getListItemMasterByMultipleLanguages([
        {
          type: 'MATCH',
          criteria: {
            and: [
              {
                field: 'additionalInfo.key3',
                operator: 'ne',
                value: '所見コメント'
              }
            ]
          }
        }
      ])

      const dataMedicalCheckupMaster: {
        medicalCheckupMaster: MedicalCheckupMaster | undefined
      } = await getMedicalCheckupMastersById(MEDICAL_CHECKUP_MASTER_EXAMPLE)
      setOriginalList({
        originalListCategory:
          listItemCategory?.data?.filterTestItemCategory.payload,
        originalListTestItem: listItemMaster,
        originalMedicalCheckupCourse:
          dataMedicalCheckupMaster?.medicalCheckupMaster
      })
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleSetInitialData = React.useCallback(
    ({
      originalListCategory,
      originalListTestItem,
      setListData
    }: {
      originalListCategory: DataItemMaster[]
      originalListTestItem: DataItemMaster[]
      setListData: ({
        listCategory,
        listTestItem
      }: {
        listCategory: DataItemMaster[]
        listTestItem: DataItemMaster[]
      }) => void
    }) => {
      // get list category with list test item
      const newListCategory = originalListCategory?.map((category) => {
        // find category in list category has key1 = 'カデコマスタ'
        const findCategoryItem = originalListTestItem.find((item) =>
          (category?.associatedTestItemMasterRefIds || []).some(
            (refId) =>
              refId === item.refId &&
              item.additionalInfo.key1 === 'カテゴリマスタ'
          )
        )

        return {
          ...findCategoryItem,
          ...category,
          itemId: findCategoryItem?.refId, // id of item belong to category
          items: originalListTestItem.filter(
            (it) =>
              category.associatedTestItemMasterRefIds?.some(
                (refId) => refId === it.refId
              ) && it.additionalInfo.key1 === '検査項目マスター'
          ) // get list item belong to category
        }
      })

      // filter all item has key1 = '検査項目マスター'. it will be test item master
      const newListTestItem = originalListTestItem.filter(
        (item) => item.additionalInfo.key1 === '検査項目マスター'
      )
      setListData({
        listCategory: newListCategory,
        listTestItem: newListTestItem
      })
    },
    []
  )

  React.useEffect(() => {
    setInitialItems({
      initialShowItemRefIds: [
        ...(categoryShow || [])?.reduce(
          (acc: string[], item: DataItemMaster) => {
            return [...acc, ...(item?.items || []).map((i) => i.refId)]
          },
          []
        )
      ],
      initialHiddenItemRefIds: [
        ...(categoryHidden || [])?.reduce(
          (acc: string[], item: DataItemMaster) => {
            return [...acc, ...(item.items || []).map((i) => i.refId)]
          },
          []
        )
      ],
      initialShowCategoryRefIds: [
        ...(categoryShow || [])?.map((item) => item.refId)
      ],
      initialHiddenCategoryRefIds: [
        ...(categoryHidden || [])?.map((item) => item.refId)
      ]
    })
  }, [categoryShow, categoryHidden])

  const onDragEnd = (result: any) => {
    const { destination, source, type } = result
    if (!destination) return
    const isStartList1 = source.droppableId.startsWith(LIST_DRAG_AND_DROP.LIST1)
    const isEndList1 = destination.droppableId.startsWith(
      LIST_DRAG_AND_DROP.LIST1
    )
    const listStart = isStartList1 ? categoryShow : categoryHidden
    const setListStart = isStartList1 ? setCategoryShow : setCategoryHidden

    if (
      destination.droppableId === source.droppableId ||
      isStartList1 === isEndList1
    ) {
      if (type === LIST_DRAG_AND_DROP.CATEGORY) {
        reOrderInList(listStart, setListStart, result)
        return
      }

      if (type === LIST_DRAG_AND_DROP.ITEM) {
        reOrderInCategory(listStart, setListStart, result)
        return
      }
    }

    const listEnd = (isEndList1 ? categoryShow : categoryHidden) ?? []
    const setListEnd = isEndList1 ? setCategoryShow : setCategoryHidden

    const listStartUpdate = [...listStart]
    const listEndUpdate = [...listEnd]

    if (type === LIST_DRAG_AND_DROP.CATEGORY) {
      const [removed] = listStartUpdate.splice(source.index, 1)

      listEndUpdate.splice(destination.index, 0, removed)

      setListStart(listStartUpdate)
      setListEnd(listEndUpdate)

      return
    }

    if (type === LIST_DRAG_AND_DROP.ITEM) {
      const sourceDroppableId = getLastPart(
        source.droppableId,
        LIST_DRAG_AND_DROP.SEPARATOR
      )
      const destinationDroppableId = getLastPart(
        destination.droppableId,
        LIST_DRAG_AND_DROP.SEPARATOR
      )

      const categoryStart = listStartUpdate.find(
        (item: any) => item.refId === sourceDroppableId
      )
      const [itemStart] = categoryStart?.items?.splice(source.index, 1) || []

      const categoryEnd = listEndUpdate.find(
        (item: any) => item.refId === destinationDroppableId
      )

      categoryEnd?.items?.splice(destination.index, 0, itemStart)

      setListStart(listStartUpdate)
      setListEnd(listEndUpdate)
    }
  }

  const reOrderInList = (category: any, setCategory: any, result: any) => {
    const { destination, source } = result

    const categoryUpdate = [...category]

    if (destination.index === source.index) {
      return
    }

    const [removed] = categoryUpdate.splice(source.index, 1)

    categoryUpdate.splice(destination.index, 0, removed)

    setCategory(categoryUpdate)
  }

  const reOrderInCategory = (category: any, setCategory: any, result: any) => {
    const { destination, source } = result
    const categoryUpdate = [...category]

    const sourceDroppableId = getLastPart(
      source.droppableId,
      LIST_DRAG_AND_DROP.SEPARATOR
    )
    const destinationDroppableId = getLastPart(
      destination.droppableId,
      LIST_DRAG_AND_DROP.SEPARATOR
    )

    const categoryStart =
      categoryUpdate.find((item: any) => item.refId === sourceDroppableId) || []
    const [itemStart] = categoryStart.items.splice(source.index, 1)

    const categoryEnd = categoryUpdate.find(
      (item: any) => item.refId === destinationDroppableId
    )

    categoryEnd.items.splice(destination.index, 0, itemStart)
    setCategory(categoryUpdate)
  }

  return {
    //
    listCategory,
    listTestItem,
    setListData,
    originalListCategory,
    originalListTestItem,
    originalMedicalCheckupCourse,
    setOriginalList,
    //

    isModalDeleteConfirm,
    setIsModalDeleteConfirm,
    isModalRestoreConfirm,
    setIsModalRestoreConfirm,
    isShowAddCategoryItem,
    setIsShowAddCategoryItem,
    isShowAddTestItem,
    setIsShowAddTestItem,
    //

    initialShowCategoryRefIds,
    initialHiddenCategoryRefIds,
    initialShowItemRefIds,
    initialHiddenItemRefIds,
    setInitialItems,
    //
    categoryShow,
    setCategoryShow,
    categoryHidden,
    setCategoryHidden,
    //
    handleSetInitialData,
    onDragEnd
  }
}

export default useCourseTestItems

/**
 *
 */
