import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Typography } from 'antd'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals'
import { openNotification } from 'components/widgets/Notification'
import { OPERATION_TYPE, RESOURCES_TENANT } from 'configs/constant'
import { StringKeyObject } from 'types/common'

const { Paragraph } = Typography

export type DataForm = {
  assignmentName: string
  assignmentShortcode?: string
}

type Props = {
  dataUpdate: StringKeyObject | null
  assignments: StringKeyObject[]
  onCancel: () => void
  setIsUpdateSuccess: (data: boolean) => void
  handleUpdate: Function
}

const schema = yup
  .object()
  .shape({
    assignmentName: yup.string().max(50).required()
  })
  .required()

const UpdateAssignmentModal = ({
  dataUpdate = null,
  assignments,
  onCancel,
  setIsUpdateSuccess,
  handleUpdate
}: Props) => {
  const { t } = useTranslation()

  const methods = useForm<DataForm>({
    defaultValues: {
      assignmentName: ''
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit, setValue, clearErrors } = methods

  useEffect(() => {
    if (dataUpdate) setValue('assignmentName', dataUpdate.value)
    else clearErrors()
  }, [dataUpdate])

  const onSubmit = async (data: DataForm) => {
    const name = data.assignmentName.trim()

    const isExits = assignments.find(
      (item) => item.value.trim() === name && item.key !== dataUpdate?.key
    )
    if (isExits) {
      openNotification({
        type: 'error',
        title: 'commonError',
        message: t('errors.assignmentDuplicateError')
      })
      return
    }

    onCancel()

    await handleUpdate(
      OPERATION_TYPE.UPDATE,
      RESOURCES_TENANT.ASSIGNMENTS,
      name,
      dataUpdate?.key
    )

    setIsUpdateSuccess(true)
  }

  const handleOk = () => {
    //todo
  }

  return (
    <CustomModal open={dataUpdate !== null} onCancel={onCancel} onOk={handleOk}>
      <div className="text-center pt-3 pb-7 px-6">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('editAssignment')}
        </Typography>

        <Paragraph className="mt-12 mb-0">
          {t('content.enterAssignmentToAdd')}
        </Paragraph>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex vertical className="mt-[130px] gap-2">
              <Flex gap={8}>
                <Col flex="148px">
                  <InputLabel
                    label={t('table.assignmentName')}
                    className="!py-0"
                  />
                </Col>
                <Col flex="auto">
                  <InputForm name="assignmentName" />
                </Col>
              </Flex>
            </Flex>

            <Flex className="justify-center gap-3 mt-[130px]">
              <Button
                type="primary"
                className="min-w-[180px] font-bold"
                htmlType="submit"
              >
                {t('button.keep')}
              </Button>
              <Button
                className="min-w-[180px] border-[#137695] text-[#137695] font-bold"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </div>
    </CustomModal>
  )
}

export default UpdateAssignmentModal
