import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onCancel: () => void
}

const SuccessRegisteredNotice: FC<Props> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation()

  return (
    <CustomModal
      open={isOpen}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="h-[446px] pb-6 pt-4">
          <div>
            <Typography className="mb-[176px] text-center text-[20px] font-bold tracking-[1px]">
              {t('reservationRegister.modal.success')}
            </Typography>

            <Typography className="text-center">
              {t('reservationRegister.modal.successRegistered')}
            </Typography>

            <Flex justify="center" className="mt-[140px]">
              <Button
                type="default"
                htmlType="button"
                onClick={onCancel}
                className="h-[30px] min-w-[180px] rounded-none border-[1px] border-[#137695] bg-white text-center font-bold text-[#137695] shadow-none"
              >
                {t('reservationRegister.modal.back')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default SuccessRegisteredNotice
