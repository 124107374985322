import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Button } from 'antd'
import { useRecoilState } from 'recoil'

import PlusActive from '../../../buttons/PlusActive'
import FirstStep from '../firstStep/FirstStep'
import { ReactComponent as ArrowFlowIcon } from 'assets/svgs/arrow-flow.svg'
import {
  DRAWER_TYPE,
  FIXED_TEXT_TYPE,
  MODAL_TYPE,
  STEP_SCENARIO
} from 'configs/constant'
import useCreateScenario from 'hooks/scenario/useCreateScenario'
import useModal from 'hooks/scenario/useModal'
import { scenarioCreateAtom } from 'pages/Scenario/ScenarioCreate/atoms/create/scenarioCreateAtom'
import { stepFirst } from 'pages/Scenario/ScenarioCreate/data/data'

export const StartActivating = () => {
  const { t } = useTranslation()
  const [, setSearchParams] = useSearchParams()
  const [scenarioCreate, setScenarioCreate] = useRecoilState(scenarioCreateAtom)

  const { onCreateBlock } = useCreateScenario()
  const { modal, onCloseModal, onOpenModal, onConfirmModal } =
    useModal(setScenarioCreate)

  const isActivated =
    scenarioCreate.flows[STEP_SCENARIO.FIRST_STEP]?.isActivated ?? false

  const handleCreateBlock = () => {
    if (!isActivated) {
      onCreateBlock([STEP_SCENARIO.FIRST_STEP])
    }
  }

  const handleOpenDrawer = () => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev)
      params.set('type', String(DRAWER_TYPE.FIRST_STEP))
      return params
    })
  }

  return (
    <div className="border absolute left-0 top-0 min-w-[190px] w-fit bg-contentGray border-borderGray p-3 rounded-[10px] shadow-md">
      <p className="text-sm text-default text-center my-2 font-bold">
        {t('scenarioCreate.content.title')}
      </p>
      <div className="flex flex-col gap-2">
        <Button
          type="primary"
          className="min-w-40 w-fit flex justify-start items-center min-h-[40px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-sm text-white shadow-none font-bold"
          onClick={handleCreateBlock}
        >
          <span>{t('scenarioCreate.content.buttonTitle')}</span>

          {!isActivated && (
            <div className="absolute z-20 w-[20px] h-[20px] right-[-22px] top-[50%] translate-y-[-50%]">
              <PlusActive />
            </div>
          )}
        </Button>
      </div>

      {isActivated && (
        <div>
          <ArrowFlowIcon className="absolute right-[-100px] top-[-70px]" />
          <div className="absolute left-72 top-[-200px] w-[280px]">
            <FirstStep
              isOpenRemoveBlockModal={modal.deleteBlock}
              onOpenRemoveBlockModal={() =>
                onOpenModal(MODAL_TYPE.DELETE_BLOCK)
              }
              onCloseRemoveBlockModal={() =>
                onCloseModal(MODAL_TYPE.DELETE_BLOCK)
              }
              onConfirmRemoveBlockModal={() =>
                onConfirmModal(
                  MODAL_TYPE.DELETE_BLOCK,
                  STEP_SCENARIO.FIRST_STEP
                )
              }
              isOpenDeletedBlockModal={modal.deletedBlock}
              onConfirmDeletedBlockModal={() =>
                onConfirmModal(
                  MODAL_TYPE.DELETED_BLOCK,
                  STEP_SCENARIO.FIRST_STEP
                )
              }
              type={FIXED_TEXT_TYPE.START_ACTIVATING}
              title={stepFirst.step}
              description={
                scenarioCreate.flows[STEP_SCENARIO.FIRST_STEP].content
              }
              onOpenDrawer={handleOpenDrawer}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(StartActivating)
