import React from 'react'

import { SpinLoading } from 'components/screens/Spin'

function LoadingScreen({ loading }: { loading: boolean }) {
  return (
    <div className="absolute z-10 top-0 left-0 bottom-0 right-0 bg-background2 bg-opacity-65 flex items-center justify-center">
      <SpinLoading loading={loading} />
    </div>
  )
}

export default LoadingScreen
