import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import { filterUserInValid } from '../../utilities/helpers'
import RegisterForm from './components/RegisterForm'
import UserInformation from './components/UserInformation'
import ConfirmRegisterModal from './components/modals/ConfirmRegisterModal'
import ErrorSearchUser from './components/modals/ErrorSearchUser'
import SuccessRegisteredNotice from './components/modals/SuccessRegisteredNotice'
import useCustomer from 'hooks/useCustomer'
import useReservationManagement from 'hooks/useReservationManagement'

export type SearchData = {
  refId?: string
  firstName?: string
  lastName?: string
  firstNameKana?: string
  lastNameKana?: string
  birthday?: string
  dialCodePhone?: string
  phone?: string
}

export type FoundData = {
  key: number
  refId: string
  fullName: string
  birthday: string
}

const dataMap: Record<string, string> = {
  id: 'refId',
  fullName: 'additionalInfo.fullName',
  birthday: 'birthday'
}

const defaultOrder = 'createdDate'

function getMappedValue(key: string): string {
  return key in dataMap ? dataMap[key] : defaultOrder
}

function createRequests(userSearchData: SearchData) {
  const baseRequests = [
    { field: 'status', operator: 'eq', value: 'PUBLISHED' },
    { field: 'additionalInfo.isDeleted', operator: 'eq', value: 'false' }
  ]

  const birthdayFormat = dayjs(userSearchData.birthday).format(
    'YYYY-MM-DDTHH:mm:ss.SSS+00:00'
  )

  const birthdayCondition = userSearchData.birthday
    ? {
        field: 'birthday',
        operator: 'between',
        value: {
          start: birthdayFormat,
          end: birthdayFormat
        }
      }
    : null

  const cleanData = (value: string | undefined) => {
    if (!value || value.trim() === '') {
      return ''
    }
    return value.trim()
  }

  const optionalRequests: string[][] = [
    ['refId', 'like', cleanData(userSearchData.refId)],
    ['additionalInfo.firstName', 'like', cleanData(userSearchData.firstName)],
    ['additionalInfo.lastName', 'like', cleanData(userSearchData.lastName)],
    [
      'additionalInfo.firstNameKana',
      'like',
      cleanData(userSearchData.firstNameKana)
    ],
    [
      'additionalInfo.lastNameKana',
      'like',
      cleanData(userSearchData.lastNameKana)
    ],
    ['additionalInfo.phone', 'like', cleanData(userSearchData.phone)],
    [
      'additionalInfo.dialCodePhone',
      'eq',
      cleanData(userSearchData.dialCodePhone)
    ]
  ]

  const filteredRequests: {
    field: string
    operator: string
    value: string | object
  }[] = optionalRequests
    .filter(([, , value]) => value !== '')
    .map(([field, operator, value]) => {
      return {
        field,
        operator,
        value
      }
    })

  if (birthdayCondition) {
    filteredRequests.push(birthdayCondition)
  }

  return [...baseRequests, ...filteredRequests]
}

export default function ReservationRegister() {
  const { t } = useTranslation()
  const { state, search } = useLocation()
  const navigate = useNavigate()
  const { onFilterCustomers } = useCustomer()
  const { onCreateReservation } = useReservationManagement()

  const { courseId, date, time, startDate, endDate, courseSetting } =
    state || {}

  const queryParams = new URLSearchParams(search)
  const { orderBy: orderParam, sortBy: sortParam = 'desc' } =
    Object.fromEntries(queryParams)

  const [loading, setLoading] = useState<boolean>(false)
  const [userSearchData, setUserSearchData] = useState<SearchData | null>(null)
  const [userFoundData, setUserFoundData] = useState<FoundData[]>([])
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false)
  const [isOpenNoticeModal, setIsOpenNoticeModal] = useState<boolean>(false)
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<FoundData | null>(null)

  const fetchData = useCallback(
    async (requests: any[]) => {
      try {
        setLoading(true)
        const result = await onFilterCustomers({
          collection: 'checkupUser',
          page: 0,
          size: -1,
          request: [
            { type: 'MATCH', criteria: { and: requests } },
            {
              type: 'SORT',
              criteria: {
                direction: sortParam,
                field: getMappedValue(orderParam)
              }
            }
          ]
        })

        if (requests.length > 3 && result.customers.length === 0) {
          setIsOpenErrorModal(true)
        }

        const validData = filterUserInValid(result.customers, courseSetting)

        const handledData = validData.map((item, index) => {
          const { firstName, lastName, firstNameKana, lastNameKana } =
            item.additionalInfo
          const fullNameKana =
            `${firstNameKana ?? ''}${lastNameKana ?? ''}`.trim()
          const fullName = `${firstName || ''}${lastName || ''}${
            fullNameKana ? `（${fullNameKana}）` : ''
          }`

          return {
            key: index + 1,
            refId: item.refId,
            fullName,
            birthday: item.birthday
          }
        })

        setUserFoundData(handledData)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [onFilterCustomers, orderParam, sortParam]
  )

  useEffect(() => {
    if (courseId && userSearchData) {
      const requests = createRequests(userSearchData)
      fetchData(requests).finally()
    }
  }, [userSearchData, orderParam, sortParam])

  const handleRowSelectionChange = useCallback(
    (selectedRows: FoundData | null) => {
      setSelectedRows(selectedRows)
    },
    []
  )

  const handleOpenConfirmModal = () => {
    if (selectedRows) {
      setIsOpenConfirmModal(true)
    }
  }

  const handleSubmit = async () => {
    if (selectedRows) {
      try {
        setLoading(true)

        await onCreateReservation({
          checkupUserRefIds: [selectedRows.refId],
          medicalCheckupMasterRefId: courseId,
          reservationDatetime: {
            datetime: `${date}T${time}:00`
          }
        })

        setIsOpenConfirmModal(false)
        setIsOpenNoticeModal(true)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleNavigatePreviousPage = () => {
    const params: Record<string, string> = {
      courseId: courseId.toString()
    }

    if (startDate) {
      params.startDate = startDate.toString()
    }

    if (endDate) {
      params.endDate = endDate.toString()
    }

    navigate({
      pathname: '/product-management',
      search: createSearchParams(params).toString()
    })
  }

  const dataModal = {
    ...state,
    fullName: selectedRows?.fullName
  }

  const handleNavigateToRegisterPage = () => {
    navigate('/customer-register')
  }

  const isDisabled = !selectedRows

  return (
    <div className="w-full min-w-[1500px]">
      <Flex justify="space-between" align="center">
        <Typography.Text className="text-base font-bold tracking-[1.6px]">
          {t('reservationRegister.reservationRegister')}
        </Typography.Text>

        <Typography
          onClick={handleNavigatePreviousPage}
          className="inline-block cursor-pointer text-sm text-[#137695] underline"
        >
          ＜{t('reservationRegister.modal.back')}
        </Typography>
      </Flex>

      <RegisterForm onSubmit={setUserSearchData} />

      {userSearchData && (
        <>
          <UserInformation
            loading={loading}
            initialData={userFoundData}
            onRowSelectionChange={handleRowSelectionChange}
          />

          <Flex justify="center" className="mt-[14px]">
            <Button
              disabled={isDisabled}
              style={{
                background: isDisabled ? '#BFC6CB' : '',
                color: isDisabled ? 'white' : ''
              }}
              type="primary"
              htmlType="submit"
              autoInsertSpace={false}
              className="h-[30px] min-w-[180px] rounded-[3px] border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
              onClick={handleOpenConfirmModal}
            >
              {t('reservationRegister.reservationRegister')}
            </Button>
          </Flex>
        </>
      )}

      <ConfirmRegisterModal
        isOpen={isOpenConfirmModal}
        onOk={handleSubmit}
        onCancel={() => setIsOpenConfirmModal(false)}
        initialData={dataModal}
      />

      <SuccessRegisteredNotice
        isOpen={isOpenNoticeModal}
        onCancel={() => {
          setIsOpenNoticeModal(false)
          handleNavigatePreviousPage()
        }}
      />

      <ErrorSearchUser
        isOpen={isOpenErrorModal}
        onOk={handleNavigateToRegisterPage}
        onCancel={() => setIsOpenErrorModal(false)}
      />
    </div>
  )
}
