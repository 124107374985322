import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

const DeleteAccountModal = ({
  isModalOpen = false,
  onCancel,
  onSuccess
}: Props) => {
  const { t } = useTranslation()

  const handleOk = () => {
    //
  }

  return (
    <CustomModal open={isModalOpen} onOk={handleOk} onCancel={onCancel}>
      <Flex
        vertical
        className="pt-[16px] pb-5 px-[14px] items-center justify-center"
      >
        <Typography className="text-[20px] text-[#545454] font-bold leading-none tracking-[1px]">
          {t('content.accountDeletionConfirmation')}
        </Typography>

        <ExclamationCircleFilled className="w-[40px] h-[40px] mt-[95px]" />

        <div className="h-[40px] w-full bg-[#F5006B0D] border border-[#df2475] mt-5 flex items-center justify-center">
          <Typography className="leading-none text-[#df2475]">
            {t('content.noAccountRecovery')}
          </Typography>
        </div>

        <Typography className="leading-none mt-[22px]">
          {t('content.deletingAccountNow')}
        </Typography>
        <Typography className="leading-none mt-2">
          {t('content.areYouSure')}
        </Typography>

        <Flex className="justify-center gap-[12px] mt-[113px]">
          <Button
            type="primary"
            autoInsertSpace={false}
            className="w-[180px] bg-[#df2475] font-bold hover:!bg-error"
            onClick={() => {
              onSuccess()
            }}
          >
            {t('button.delete')}
          </Button>
          <Button
            className="w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={() => onCancel()}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default DeleteAccountModal
