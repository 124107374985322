import React, { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'

import useTimeChecker from '../../../hooks/useTimeChecker'
import styles from './scss/TabbleWrapper.module.scss'
import { DateFormat } from 'configs/constant'
import 'dayjs/locale/ja'
import isoWeek from 'dayjs/plugin/isoWeek'
import { SpecialConfig } from 'hooks/useTimeConfig'
import { RemainedSlotByDate } from 'models/productManagement'

dayjs.locale('ja')
dayjs.extend(isoWeek)

type Props = {
  loading: boolean
  initialData: RemainedSlotByDate[]
  currentMonth: dayjs.Dayjs
  config: SpecialConfig
  setSelectedSlot: (data: { date: string; time: string }) => void
}

const RemainedSlotsByWeek: FC<Props> = ({
  loading,
  initialData,
  currentMonth,
  config,
  setSelectedSlot
}) => {
  const { t } = useTranslation()
  const { checkTime } = useTimeChecker()

  const [selectedCell, setSelectedCell] = useState<{
    date: string
    time: string
  }>()

  const getDaysInWeek = () => {
    const startOfWeek = dayjs().startOf('isoWeek')
    return Array.from({ length: 7 }, (_, index) => {
      return startOfWeek.add(index, 'day').format('ddd')
    })
  }

  const getWeeksInMonth = (month: dayjs.Dayjs) => {
    const weeks: { [key: number]: string[] } = {}
    const startOfWeek = month.startOf('month').startOf('isoWeek')

    for (let weekIndex = 0; weekIndex < 6; weekIndex++) {
      weeks[weekIndex + 1] = Array.from({ length: 7 }, (_, i) =>
        startOfWeek.add(weekIndex, 'week').add(i, 'day').format(DateFormat)
      )
    }

    return weeks
  }

  const daysInWeek = getDaysInWeek()
  const daysInMonth = getWeeksInMonth(currentMonth)

  const getDayColor = (index: number) => {
    const colors = ['#3089BF', '#DF2475', '#545454']

    switch (index) {
      case 5:
        return colors[0]
      case 6:
        return colors[1]
      default:
        return colors[2]
    }
  }

  const renderCell = (
    date: string,
    index: number,
    data: RemainedSlotByDate[]
  ) => {
    const dateDayjs = dayjs(date)
    const isCurrent = dateDayjs.month() === currentMonth.month()
    const availableSlots = isCurrent
      ? data.filter((item) => item.date === date && item.remainedSlot > 0)
      : []

    const sortedData = availableSlots.sort((a, b) =>
      a.time.localeCompare(b.time)
    )

    let displayDate

    if (isCurrent) {
      displayDate = dateDayjs.date()
    } else if (
      dateDayjs.isSame(currentMonth.add(1, 'month').startOf('month'), 'day')
    ) {
      displayDate = dateDayjs.format('MM/D')
    } else {
      displayDate = dateDayjs.date()
    }

    return (
      <div
        className={`h-[120px] border border-[#CDD6DD] ${isCurrent ? 'bg-[#F0F3F7]' : ''}`}
      >
        <Typography
          className={`ml-1 text-[16px] ${isCurrent ? getDayColor(index) : 'text-[#CDD6DD]'}`}
        >
          {displayDate}
        </Typography>

        {isCurrent && (
          <div className="ml-1 mr-3 h-[90px] overflow-y-auto">
            {sortedData.map((item) => {
              const isDisabled = checkTime(item.date, item.time, config)

              const isSelected =
                selectedCell?.date === item.date &&
                selectedCell?.time === item.time

              return (
                item.emptySlot > 0 && (
                  <Flex align="center" justify="space-between" key={item.time}>
                    {isSelected && (
                      <Typography className="text-[6px] text-[#DF2475] absolute">
                        {t('reservationChange.label.selected')}
                      </Typography>
                    )}

                    <Flex
                      justify="space-between"
                      className={`ml-5 my-[1px] ${isSelected ? 'bg-[#DF2475]' : 'bg-[#137695]'} ${isDisabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer'} flex-1 rounded-[3px] px-2`}
                      onClick={() => {
                        setSelectedCell({ date, time: item.time })
                        setSelectedSlot({ date, time: item.time })
                      }}
                    >
                      <Typography className="text-xs text-white">
                        {item.time}
                      </Typography>
                      <Typography className="min-w-[40px] text-xs text-white">
                        〇{item.remainedSlot}/{item.emptySlot}
                      </Typography>
                    </Flex>
                  </Flex>
                )
              )
            })}
          </div>
        )}
      </div>
    )
  }

  const dataSource = Object.keys(daysInMonth).map((week) => {
    const row: { key: string; week: string; [key: string]: ReactNode } = {
      key: `week-${week}`,
      week: `Week ${week}`,
      ...daysInWeek.reduce<{
        [key: string]: ReactNode
      }>((acc, day, index) => {
        const date = daysInMonth[parseInt(week)][index]
        const data = initialData.filter((item) => item.date === date)
        acc[day] = renderCell(date, index, data)
        return acc
      }, {})
    }
    return row
  })

  const columns: TableColumnsType<{
    key: string
    week: string
    [p: string]: ReactNode
  }> = [
    ...daysInWeek.map((day, index) => ({
      title: (
        <Typography
          className={`border border-[#CDD6DD] py-2 text-center text-[${getDayColor(index)}]`}
        >
          {day}
        </Typography>
      ),
      dataIndex: day,
      width: '11%'
    })),
    {
      title: '',
      render: () => null
    }
  ]

  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      className={`${styles.table_wrapper}`}
    />
  )
}

export default RemainedSlotsByWeek
