import React, { ReactNode, memo } from 'react'

import '../css/controlPanel.css'

interface ControlPanelProps {
  patientMemo: ReactNode
  patientInfo: ReactNode
  patientReservation: ReactNode
  reservationHistory: ReactNode
  chatHistory: ReactNode
}

function ControlPanel(props: ControlPanelProps) {
  return (
    <div className="control-panel-wrapper">
      <div className="left">
        <div className="patient-memo">{props.patientMemo}</div>
        <div className="patient-info">{props.patientInfo}</div>
      </div>
      <div className="right">
        <div className="above">
          <div className="patient-reservation">{props.patientReservation}</div>
          <div className="chat-history">{props.chatHistory}</div>
        </div>
        <div className="below">
          <div className="reservation-history">{props.reservationHistory}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(ControlPanel)
