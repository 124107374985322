import React, { memo, useEffect, useRef } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Checkbox, Flex, Input, Radio, Typography } from 'antd'

import { RESERVATION_MANAGEMENT_TAB } from '../ReservationManagement'
import SearchMultipleCourseDropdown from './SearchMultipleCourseDropdown'
import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import CustomDateRangePicker from 'components/elements/CustomDateRangePicker'
import { RECORD_COUNT } from 'models/customer'
import { RESERVATION_STATUS } from 'models/reservationManagement'

type DataForm = {
  search: string
  courseId: string[]
  status: string[]
  display: string
}

const Widget = memo(() => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const {
    search: searchParam,
    courseId: courseParam,
    startDate: startDateParam,
    endDate: endDateParam,
    status: statusParam,
    tab: tabParam = RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST,
    display: displayParam,
    size: sizeParam = RECORD_COUNT.OneHundred
  } = Object.fromEntries(queryParams)

  const defaultValues = {
    search: searchParam,
    courseId: courseParam ? courseParam.split(',') : undefined,
    status: statusParam ? statusParam.split(',') : undefined,
    display: displayParam || undefined
  }

  const methods = useForm<DataForm>({
    defaultValues: defaultValues
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [search])

  const selectedDateRangeRef = useRef<[string, string]>()

  const updateDateRange = (dates: [string, string] | undefined) => {
    if (dates) {
      selectedDateRangeRef.current = [
        dates[0].split('T')[0],
        dates[1].split('T')[0]
      ]
    } else {
      selectedDateRangeRef.current = undefined
    }
  }

  const reservationStatusOptions = [
    {
      label: t('reservationManagement.widget.status.booked'),
      value: RESERVATION_STATUS.RESERVED
    },
    {
      label: t('reservationManagement.widget.status.canceled'),
      value: RESERVATION_STATUS.CANCELLED
    }
  ]

  const reservationCollectionStatusOptions = [
    {
      label: t('reservationManagement.widget.status.collectDate'),
      value: RESERVATION_STATUS.COLLECTED_DESIRED_DATE
    },
    {
      label: t('reservationManagement.widget.status.waitConfirm'),
      value: RESERVATION_STATUS.UNCONFIRMED
    }
  ]

  const handleSubmit = () => {
    const {
      search: searchText,
      status,
      courseId,
      display
    } = methods.getValues()

    if (searchText) {
      queryParams.set('search', searchText.toString())
    } else {
      queryParams.delete('search')
    }

    if (courseId && courseId.length > 0) {
      queryParams.set('courseId', courseId.toString())
    } else {
      queryParams.delete('courseId')
    }

    if (status && status.length > 0) {
      queryParams.set('status', status[0].toString())
    } else {
      queryParams.delete('status')
    }

    if (display) {
      queryParams.set('display', display.toString())
    } else {
      queryParams.delete('display')
    }

    if (selectedDateRangeRef.current) {
      queryParams.set('startDate', selectedDateRangeRef.current[0])
      queryParams.set('endDate', selectedDateRangeRef.current[1])
    } else {
      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    queryParams.set('page', '1')
    queryParams.set('size', sizeParam.toString())
    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <FormProvider {...methods}>
      <form
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
          }
        }}
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <Flex align={'center'}>
          <Typography.Text className="mr-[30px] text-base font-bold tracking-[1.6px]">
            {t('reservationManagement.reservationManagement')}
          </Typography.Text>

          <Controller
            name="search"
            control={methods.control}
            render={({ field }) => (
              <Input
                {...field}
                className="h-[30px] min-w-[200px] max-w-[424px]"
                prefix={<Search />}
                placeholder={t(
                  'reservationManagement.widget.placeholder.enterIdOrNameOrPhone'
                )}
              />
            )}
          />

          <CustomDateRangePicker
            initialStartDate={startDateParam || undefined}
            initialEndDate={endDateParam || undefined}
            placeholder={t(
              'reservationManagement.widget.placeholder.selectDate'
            )}
            placeholder2={t('reservationManagement.widget.placeholder.choice')}
            isDisabledDate={false}
            setDates={updateDateRange}
          />

          <Typography.Text className="ml-2 mr-6 text-sm font-bold">
            {t('reservationManagement.widget.reservationStatus')}
          </Typography.Text>

          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => (
              <Checkbox.Group
                {...field}
                value={field.value}
                options={
                  tabParam === RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST
                    ? reservationStatusOptions
                    : reservationCollectionStatusOptions
                }
                onChange={(selectedValues) => {
                  if (selectedValues.length === 2) {
                    selectedValues = selectedValues.filter(
                      (item) => item !== methods.getValues('status')?.[0]
                    )
                  }
                  field.onChange(selectedValues)
                }}
              />
            )}
          />

          {tabParam === RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST && (
            <>
              <Typography.Text className="ml-6 mr-3 font-bold">
                {t('reservationManagement.widget.displayType')}
              </Typography.Text>

              <Controller
                name="display"
                control={methods.control}
                defaultValue={'OFF'}
                render={({ field: { value, onChange } }) => (
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={'ON'}>ON</Radio>
                    <Radio value={'OFF'}>OFF</Radio>
                  </Radio.Group>
                )}
              />
            </>
          )}

          <Button
            type="primary"
            htmlType="submit"
            autoInsertSpace={false}
            className="ml-4 h-[30px] min-w-[84px] rounded-none border-none bg-[#137695] text-center text-sm font-bold text-white shadow-none"
          >
            {t('reservationManagement.widget.button.search')}
          </Button>
        </Flex>

        {tabParam === RESERVATION_MANAGEMENT_TAB.RESERVATION_LIST && (
          <Flex align={'center'} className="mt-2">
            <Typography.Text className="invisible mr-[30px] text-[16px] font-bold tracking-[1.6px]">
              {t('reservationManagement.reservationManagement')}
            </Typography.Text>

            <SearchMultipleCourseDropdown
              name={'courseId'}
              placeholder={t(
                'reservationManagement.widget.placeholder.enterCourse'
              )}
            />
          </Flex>
        )}
      </form>
    </FormProvider>
  )
})

export default Widget
