import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Input, Typography } from 'antd'

import { InputForm } from 'components/elements/InputForm'
import { InputPassword } from 'components/elements/InputPassword'

export default function MyPageForm() {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const [isReadOnly, setIsReadOnly] = useState(true)
  const onFocus = () => {
    setIsReadOnly(false)
  }

  const showError = (errors: any) => {
    if (
      errors?.emailAddress?.message === null ||
      errors?.emailAddress?.message === undefined
    )
      return ''
    return errors?.emailAddress?.message
  }

  return (
    <Flex vertical gap={6}>
      <Typography className="text-[14px] text-primary font-bold">
        {t('accountInformation')}
      </Typography>
      <Flex vertical gap={6} className="w-[400px] self-center">
        <Typography className="font-bold">{t('lable.name')}</Typography>
        <InputForm
          name="name"
          className="h-[30px] "
          placeholder={t('placeholder.subscriptionTaro')}
        />
      </Flex>
      <Flex vertical gap={6} className="w-[400px] self-center">
        <Typography className="font-bold">{t('lable.mailAddress')}</Typography>
        <Controller
          name="emailAddress"
          control={control}
          render={({ field }) => (
            <>
              <Input
                {...field}
                placeholder={t('placeholder.emailAddress')}
                className="h-[30px]"
                onFocus={onFocus}
                readOnly={isReadOnly}
              />
              <Typography className="text-error text-xs h-3 mt-1.5 leading-none text-left">
                {showError(errors)}
              </Typography>
            </>
          )}
        />
      </Flex>

      <Typography className=" text-primary font-bold">
        {t('changePassword')}
      </Typography>

      <Flex vertical gap={6} className="w-[400px] self-center">
        <Typography className="font-bold">
          {t('lable.newPasswordMyPage')}
        </Typography>
        <InputPassword name="newPassword" className="h-[30px]" />
      </Flex>

      <Flex vertical gap={6} className="w-[400px] self-center">
        <Typography className="font-bold">
          {t('lable.confirmNewPassword')}
        </Typography>
        <InputPassword name="confirmPassword" className="h-[30px]" />
      </Flex>
    </Flex>
  )
}
