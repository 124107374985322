import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { IJudgementItem, JUDGMENT_ITEM } from '../SetupThresholdAndCriteria'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import { ReactComponent as SaveIcon } from 'assets/svgs/save.svg'
import { InputForm } from 'components/elements/InputForm'

type Props = {
  item: IJudgementItem
  index: number
}

export default function JudgmentItem({ item, index }: Props) {
  const { t } = useTranslation()
  const nameInputEvaluation = `${JUDGMENT_ITEM}.${item.id}.evaluation`
  const nameInputDescription = `${JUDGMENT_ITEM}.${item.id}.description`
  const nameInputBgColor = `${JUDGMENT_ITEM}.${item.id}.bgColor`
  const nameInputBgOrder = `${JUDGMENT_ITEM}.${item.id}.order`
  const [editEvaluation, setEditEvaluation] = useState<boolean>(false)
  const [editDescription, setEditDescription] = useState<boolean>(false)
  const {
    getValues,
    formState: { errors }
  } = useFormContext()

  const errorsItem = (errors.judgment_item as Record<string, any>)?.[item.id]

  useEffect(() => {
    const evaluationError = errorsItem?.evaluation
    const descriptionError = errorsItem?.description

    if (evaluationError) {
      setEditEvaluation(true)
    }

    if (descriptionError) {
      setEditDescription(true)
    }
  }, [errorsItem])

  return (
    <Flex
      vertical
      style={{ backgroundColor: item.bgColor }}
      className={`rounded-lg p-2.5 w-[300px] gap-3 justify-end`}
    >
      <InputForm
        name={nameInputBgColor}
        defaultValue={item.bgColor}
        className="hidden"
      />
      <InputForm
        name={nameInputBgOrder}
        type="number"
        defaultValue={item.order}
        className="hidden"
      />
      {index === 0 && (
        <Typography className="text-white">
          {t('judgementSetting.judgmentCategory')}
        </Typography>
      )}
      <Flex
        className={`min-h-[30px] items-center pl-6 pr-2.5 justify-between bg-[#EEF3FA] ${editEvaluation ? '' : 'hidden'}`}
      >
        <InputForm
          name={nameInputEvaluation}
          defaultValue={item.evaluation}
          className="border-none pl-0 bg-[#EEF3FA] focus:shadow-none focus:bg-[#EEF3FA] hover:bg-[#EEF3FA] flex-1"
        />
        <SaveIcon
          className="w-[15px] cursor-pointer"
          onClick={() => setEditEvaluation(false)}
        />
      </Flex>
      <Flex
        className={`min-h-[30px] bg-white items-center pl-6 pr-2.5 justify-between ${editEvaluation ? 'hidden' : ''}`}
      >
        <Typography className="flex-1">
          {getValues(nameInputEvaluation) ?? item.evaluation}
        </Typography>
        <PenEditIcon
          className="w-[18px] cursor-pointer"
          onClick={() => setEditEvaluation(true)}
        />
      </Flex>
      <Flex
        className={`min-h-[30px] items-center pl-6 pr-2.5 justify-between bg-[#EEF3FA] ${editDescription ? '' : 'hidden'}`}
      >
        <InputForm
          name={nameInputDescription}
          defaultValue={item.description}
          className="border-none pl-0 bg-[#EEF3FA] focus:shadow-none focus:bg-[#EEF3FA] hover:bg-[#EEF3FA] flex-1"
        />
        <SaveIcon
          className="w-[15px] cursor-pointer"
          onClick={() => setEditDescription(false)}
        />
      </Flex>
      <Flex
        className={`min-h-[30px] bg-white items-center pl-6 pr-2.5 justify-between  ${editDescription ? 'hidden' : ''}`}
      >
        <Typography className="flex-1">
          {getValues(nameInputDescription) ?? item.description}
        </Typography>
        <PenEditIcon
          className="w-[18px] cursor-pointer"
          onClick={() => setEditDescription(true)}
        />
      </Flex>
    </Flex>
  )
}
