import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Radio } from 'antd'

import ReservationSection from './ReservationSection'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { SELECTION_TYPE, STEP_SCENARIO } from 'configs/constant'
import { ScenarioCreate } from 'pages/Scenario/types/types'

const styles = {
  buttonActive:
    'text-white bg-primary font-bold cursor-pointer border-primary border rounded-[4px]',
  buttonInactive:
    'text-primary font-bold cursor-pointer border-primary border rounded-[4px]'
}

function OtherContent({
  setDataForm,
  dataForm
}: {
  readonly setDataForm: Dispatch<SetStateAction<ScenarioCreate>>
  readonly dataForm: ScenarioCreate
}) {
  const { t } = useTranslation()
  const methods = useForm()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!dataForm.flows[STEP_SCENARIO.SECOND_STEP].options?.length) return

    setIsActive(true)
  }, [dataForm.flows[STEP_SCENARIO.SECOND_STEP].options])

  return (
    <FormProvider {...methods}>
      <div className="mt-2">
        <Button
          onClick={() => setIsActive(!isActive)}
          icon={isActive ? <MinusOutlined /> : <PlusOutlined />}
          className={isActive ? styles.buttonInactive : styles.buttonActive}
        >
          {t('scenarioCreate.deleteScenario')}
        </Button>

        {isActive && (
          <React.Fragment>
            <p className="text-primary font-bold my-2">
              {t('scenarioCreate.selectionForm.selectionType')}
            </p>
            <Radio.Group value={dataForm.selectionType}>
              <Radio value={SELECTION_TYPE.BUTTON_REGISTRATION}>
                {t('scenarioCreate.selectionForm.reservationButton')}
              </Radio>
            </Radio.Group>

            <ReservationSection dataForm={dataForm} setDataForm={setDataForm} />
          </React.Fragment>
        )}
      </div>
    </FormProvider>
  )
}

export default OtherContent
