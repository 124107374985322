import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import ModalConfirm from 'components/modals/ModalConfirm'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { showName } from 'pages/InspectionItemSetting/helper'
import { Category } from 'types/FilterTestItemMaster'
import { getLanguage } from 'utilities/helpers'

const ModalConfirmDeletedItem = ({
  items,
  onCancel,
  onOk
}: {
  items: Category[]
  onCancel: () => void
  onOk: () => void
}) => {
  const { t } = useTranslation()
  const language = getLanguage()
  return (
    <ModalConfirm
      isModalVisible={true}
      title={t('button.delete')}
      classNameModal='!w-[900px]'
      handleCancel={onCancel}
      handleOk={onOk}
      textOk={t('button.delete')}
      icons={
        <div className="mt-14">
          <ExclamationCircleFilled className="w-10 pb-12 inline-block" />
        </div>
      }
      content={
        <>
          <div className="mb-11 w-full flex items-center justify-center">
            <div className="flex flex-col justify-center w-fit">
              <p className="flex items-start text-default w-full">
                {t('modalConfirm.infoConfirmDelete', {
                  count: items.length
                })}
              </p>
              <p className="flex items-start text-[#df2475] w-full">
                {t('modalConfirm.contentConfirm')}
              </p>
            </div>
          </div>
          <div className="flex flex-col border max-h-[467px] overflow-auto mx-5">
            {items.map((it) => (
              <Flex
                key={it.refId}
                className="grid grid-cols-2 px-3 py-1 border-b"
              >
                <Typography className="col-span-1 text-default text-start">{`${showName(it, language) ?? VALUE_WITH_NO_DATA} `}</Typography>
                <Typography className="col-span-1 text-default text-start">{`${showName(it, 'en') ?? VALUE_WITH_NO_DATA} `}</Typography>
              </Flex>
            ))}
          </div>
        </>
      }
    />
  )
}

export default ModalConfirmDeletedItem
