import { memo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button } from 'antd'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
  scenarioCreateAtom,
  simulatorModalAtom
} from '../../atoms/create/scenarioCreateAtom'
import { initialHeaderButtons } from '../../data/data'
import { SpinLoading } from 'components/screens/Spin'
import { openNotification } from 'components/widgets/Notification'
import {
  DRAWER_DATA_TYPE,
  DRAWER_TYPE,
  HEADER_BUTTON_TYPE,
  STEP_SCENARIO
} from 'configs/constant'
import DeleteConfirmationModal from 'pages/Scenario/ScenarioList/components/modal/DeleteConfirmationModal'
import { Option, ScenarioCreate } from 'pages/Scenario/types/types'
import { createScenario, updateScenario } from 'services/ScenarioService'

function ScenarioCreateHeaders() {
  const [, setSearchParams] = useSearchParams()
  const { refId } = useParams()

  const { setError } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const scenarioData = useRecoilValue(scenarioCreateAtom)
  const setSimulatorModal = useSetRecoilState(simulatorModalAtom)

  const [modalSave, setModalSave] = useState({ type: 'temp', open: false })
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseModalSaved = () => {
    setModalSave({ ...modalSave, open: false })
    navigate('/scenario')
  }

  const onClickHeaderButton = async (typeBtn: HEADER_BUTTON_TYPE) => {
    if (
      [HEADER_BUTTON_TYPE.SAVE, HEADER_BUTTON_TYPE.TEMPORARY_SAVE].includes(
        typeBtn
      )
    ) {
      if (!scenarioData.flows[STEP_SCENARIO.FIRST_STEP]?.isActivated) {
        openNotification({
          type: 'error',
          title: t('scenarioCreate.notification.errorTitleCreate'),
          message: t('scenarioCreate.notification.errorCreate')
        })

        return
      }

      const errors = validateData(scenarioData)

      if (!errors.length) {
        handleCallApiSaveScenario(
          typeBtn === HEADER_BUTTON_TYPE.SAVE,
          Boolean(refId)
        )
        return
      }

      const hasFirstBlockErrors = [
        DRAWER_DATA_TYPE.TEMPLATE_NAME,
        DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST
      ].some((type) => errors.includes(type))

      const hasLastBlockErrors = errors.includes(
        DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST
      )

      if (
        hasFirstBlockErrors &&
        scenarioData?.flows?.some(
          (flow) => flow.order === STEP_SCENARIO.FIRST_STEP
        )
      ) {
        updateSearchParams(DRAWER_TYPE.FIRST_STEP)
      } else if (
        hasLastBlockErrors &&
        scenarioData?.flows?.some(
          (flow) => flow.order === STEP_SCENARIO.LAST_STEP
        )
      ) {
        updateSearchParams(DRAWER_TYPE.LAST_STEP)
      }
    } else {
      setSimulatorModal({ open: true })
    }
  }

  const updateSearchParams = (type: string) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev)
      params.set('type', type)
      return params
    })
  }

  const findBlock = (block: STEP_SCENARIO, scenarioData: ScenarioCreate) => {
    return scenarioData?.flows?.find((flow) => flow.order === block)
  }

  const validateData = (data: ScenarioCreate) => {
    let errors: string[] = []

    const rulesValidate = [
      { key: DRAWER_DATA_TYPE.TEMPLATE_NAME, value: data.name, max: 50 },
      {
        key: DRAWER_DATA_TYPE.CONTENT_BLOCK_FIRST,
        value: findBlock(STEP_SCENARIO.FIRST_STEP, data)?.content,
        max: 255
      },
      {
        key: DRAWER_DATA_TYPE.CONTENT_BLOCK_LAST,
        value: findBlock(STEP_SCENARIO.LAST_STEP, data)?.content
      }
    ]

    for (const rule of rulesValidate) {
      if (
        (!rule.value?.trim() && rule.value !== undefined) ||
        (rule.max && rule.value && rule.value.length > rule.max)
      ) {
        errors.push(rule.key)
      }
    }

    if (errors.length) {
      setTimeout(() => {
        errors.forEach((error) => {
          setError(error, {
            type: 'required'
          })
        })
      }, 0)
    }

    return errors
  }

  const handleCallApiSaveScenario = async (
    isActive: boolean,
    isUpdate: boolean
  ) => {
    if (isLoading) return

    setIsLoading(true)

    // remove type from flow of scenarioData and isActivated from options
    const { flows, typeScenario, ...rest } = scenarioData

    const flowCheckStatusLastStep = flows.filter(
      (flow) => !(flow.order === STEP_SCENARIO.LAST_STEP && !flow.isActivated)
    )

    const newFlows = flowCheckStatusLastStep.map(
      ({ isActivated, type, ...flow }) => ({
        ...flow,
        options: flow.options?.map((option: Option) => {
          const { isActivated, ...rest } = option

          return rest
        })
      })
    )

    const data = {
      ...rest,
      flows: newFlows,
      status: isActive ? 'ACTIVATE' : 'INACTIVATE'
    }

    try {
      if (isUpdate) {
        await updateScenario({ ...data, refId })
      } else {
        await createScenario(data)
      }

      if (isActive) {
        navigate('/scenario')
      } else {
        setModalSave({ ...modalSave, open: true })
      }
    } catch (error) {
      console.log('error ====>', error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading)
    return (
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-white/50 translate-x-12">
        <SpinLoading loading={isLoading} />
      </div>
    )

  return (
    <div className="flex items-center justify-between">
      <h1 className="text-base font-bold">
        {t('scenarioCreate.header.title')}
      </h1>
      <div className="flex gap-2">
        {initialHeaderButtons.map((buttonProps) => (
          <Button
            key={buttonProps.key}
            type={buttonProps.type}
            className={buttonProps.className}
            onClick={() => onClickHeaderButton(buttonProps.typeBtn)}
          >
            {t(buttonProps.title || '')}
          </Button>
        ))}
      </div>

      {modalSave.open && (
        <DeleteConfirmationModal
          isOpen={modalSave.open}
          onClose={handleCloseModalSaved}
          isEnableOkButton={false}
          description={t('scenarioCreate.modal.save.tempDescription')}
          title={t('scenarioCreate.modal.save.tempTitle')}
          icon={<></>}
          textCancel={t('scenarioList.errorModal.backButton')}
          maskClosable={false}
        />
      )}
    </div>
  )
}

export default memo(ScenarioCreateHeaders)
