import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Col, Empty, Row, Tooltip } from 'antd'

import CreateDepartmentModal from './CreateDepartmentModal'
import CreateDepartmentSuccessModal from './CreateDepartmentSuccessModal'
import DeleteDepartmentModal from './DeleteDepartmentModal'
import DeleteDepartmentSuccessModal from './DeleteDepartmentSuccessModal'
import UpdateDepartmentModal from './UpdateDepartmentModal'
import UpdateDepartmentSuccessModal from './UpdateDepartmentSuccessModal'
import { ReactComponent as TrashIcon } from 'assets/imgs/trash.svg'
import { ReactComponent as EditIcon } from 'assets/svgs/pen.svg'
import { StringKeyObject } from 'types/common'

export default function ManageMedicalDepartments({
  departments,
  taskProcessingTenantAdditionalInfo
}: {
  departments: StringKeyObject[]
  taskProcessingTenantAdditionalInfo: Function
}) {
  const { t } = useTranslation()
  const [dataUpdate, setDataUpdate] = useState<StringKeyObject | null>(null)
  const [dataDelete, setDataDelete] = useState<StringKeyObject | null>(null)
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [isCreateSuccess, setIsCreateSuccess] = useState<boolean>(false)
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false)
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false)

  return (
    <>
      <div className="p-2.5 bg-white pt-0 rounded-b-md shadow-md shadow-[#00000029]">
        <Row className="text-center pb-3 pt-1 border-b border-customGray items-center">
          <Col flex="70%">{t('table.departmentName')}</Col>
          <Col flex="15%">{t('table.operation')}</Col>
          <Col flex="auto" className="flex justify-end">
            <Button
              autoInsertSpace={false}
              type="primary"
              className="min-w-[100px] font-bold"
              onClick={() => setCreateModal(true)}
            >
              {t('button.register')}
            </Button>
          </Col>
        </Row>
        {departments.map((department) => {
          return (
            <Row className="py-2 row-basic-setting" key={department.key}>
              <Col flex="10%"></Col>
              <Col flex="60%">{department.value}</Col>
              <Col
                flex="15%"
                className="flex items-center justify-center gap-10"
              >
                <Tooltip placement="top" title={t('button.edit')}>
                  <EditIcon
                    className="cursor-pointer w-4"
                    onClick={() => setDataUpdate(department)}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t('button.delete')}>
                  <TrashIcon
                    className="cursor-pointer w-4"
                    onClick={() => setDataDelete(department)}
                  />
                </Tooltip>
              </Col>
              <Col flex="auto" className="flex justify-end"></Col>
            </Row>
          )
        })}
        {departments.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
      <CreateDepartmentModal
        isModalOpen={createModal}
        onCancel={() => setCreateModal(false)}
        onSuccess={taskProcessingTenantAdditionalInfo}
        setIsCreateSuccess={setIsCreateSuccess}
        departments={departments}
      />
      <UpdateDepartmentModal
        dataUpdate={dataUpdate}
        onCancel={() => setDataUpdate(null)}
        handleUpdate={taskProcessingTenantAdditionalInfo}
        setIsUpdateSuccess={setIsUpdateSuccess}
        departments={departments}
      />
      <DeleteDepartmentModal
        dataDelete={dataDelete}
        onCancel={() => setDataDelete(null)}
        handleDelete={taskProcessingTenantAdditionalInfo}
        setIsDeleteSuccess={setIsDeleteSuccess}
      />
      <CreateDepartmentSuccessModal
        isModalOpen={isCreateSuccess}
        onCancel={() => setIsCreateSuccess(false)}
      />
      <UpdateDepartmentSuccessModal
        isModalOpen={isUpdateSuccess}
        onCancel={() => setIsUpdateSuccess(false)}
      />
      <DeleteDepartmentSuccessModal
        isModalOpen={isDeleteSuccess}
        onCancel={() => setIsDeleteSuccess(false)}
      />
    </>
  )
}
