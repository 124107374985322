import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Col, Flex, Row, Typography } from 'antd'

import { DatePickerCustom } from 'components/elements/DatePickerForm'
import { InputCustom } from 'components/elements/InputForm'
import { SelectSearchCustom } from 'components/elements/Select'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { Options, StringKeyObject } from 'types/common'

export default function PatientInfoForm({
  language,
  medicalCheckupMasterInfo,
  medicalCheckupCourse,
  optionsMedicalCheckupMaster,
  basicInformationDefault
}: {
  language: any
  medicalCheckupMasterInfo: any
  medicalCheckupCourse?: string
  optionsMedicalCheckupMaster: Options[]
  basicInformationDefault: StringKeyObject[]
}) {
  const { t } = useTranslation('')
  const [formValues, setFormValues] = useState<any>({})
  const [searchParams] = useSearchParams()
  const [dataInfo, setDataInfo] = useState<StringKeyObject[]>([])

  useEffect(() => {
    if (medicalCheckupMasterInfo.length === 0) return

    setDataInfo(
      [...basicInformationDefault, ...medicalCheckupMasterInfo].sort(
        (a, b) => a.order - b.order
      )
    )
  }, [basicInformationDefault, medicalCheckupMasterInfo])

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value
    }))
  }

  const renderInputType = (item: any) => {
    if (item.refId === medicalCheckupCourse) {
      return (
        <SelectSearchCustom
          name={item.refId}
          options={optionsMedicalCheckupMaster}
          className="w-full"
          disabled={searchParams.get('refId') ? true : false}
        />
      )
    }

    if (item.readingType === 'DATE') {
      return (
        <DatePickerCustom
          name={item.refId}
          className="w-full"
          value={formValues ? formValues[item.refId] : ''}
          onChange={handleInputChange}
          disabled={item?.disable}
        />
      )
    }

    return (
      <InputCustom
        name={item.refId}
        value={formValues ? formValues[item.refId] : ''}
        onChange={handleInputChange}
        disabled={item?.disable}
      />
    )
  }

  return (
    <Flex className="gap-[5%] pr-[9.4%] flex-wrap">
      {dataInfo.map((item: StringKeyObject) => (
        <div className="w-[47%]" key={item.refId}>
          <Row className="mt-1.5 items-center text-left">
            <Col flex="105px" className="font-bold pr-1.5">
              {item.isDefault ? (
                <Typography>{t(item.name)}</Typography>
              ) : (
                <Typography>
                  {item?.lang?.[language] ?? VALUE_WITH_NO_DATA}
                </Typography>
              )}
            </Col>
            <Col flex="auto" className="w-[calc(100%-105px)]">
              {renderInputType(item)}
            </Col>
          </Row>
        </div>
      ))}
    </Flex>
  )
}
