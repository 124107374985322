//TODO: change any type for this function bellow
export const flatItems = (items: any[]) => {
  if (!Array.isArray(items)) return []

  // flatten items and add order
  return items.flatMap((it, order) => {
    const { itemMaster = [], ...arg } = it || {}
    return [
      { ...arg, type: 'parent', order },
      ...itemMaster.map((item: any, index: any) => ({
        ...item,
        parentRefId: arg.refId,
        order: index
      }))
    ]
  })
}

export const sortItems = (items: any[]) => {
  return items.sort((a, b) => {
    if (a.type === 'parent' && b.type !== 'parent') {
      return -1
    } else if (a.type !== 'parent' && b.type === 'parent') {
      return 1
    }
    return 0
  })
}

export const groupItems = (items: any[], parentList?: any[]) => {
  if (!Array.isArray(items)) return []
  // group items
  const newArr = []
  // for each item
  for (let item in items) {
    const data = items?.[item] || {}
    if (!data?.refId) continue
    if (data.type === 'parent') {
      newArr.push({ ...data, itemMaster: [] })
      continue
    }

    const hasItems = newArr.find((it) => it.refId === data.parentRefId)
    if (hasItems) {
      hasItems.itemMaster.push(data)
    } else {
      const findParentInAllList = [...(parentList ?? [])].find(
        (it) => it.refId === data.parentRefId
      )
      if (findParentInAllList) {
        const parentItems = {
          ...findParentInAllList,
          itemMaster: [data]
        }
        newArr.push(parentItems)
      }
    }
  }

  return newArr
}
