import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onCancel: () => void
  initialData?: { source: string; destination: string }
}

const ReservationRegistrationCompletedNotice: FC<Props> = ({
  isOpen,
  onSubmit,
  onCancel,
  initialData
}) => {
  const { t } = useTranslation()

  return (
    <CustomModal
      open={isOpen}
      onCancel={onCancel}
      children={
        <div className="min-h-[440px] w-[600px] pb-6 pt-4">
          <Typography className="text-center text-[20px] font-bold tracking-[1px]">
            {t('productDuplication.modal.duplication')}
          </Typography>

          <Typography className="mt-5 text-center">
            {t('productDuplication.modal.notice')}
          </Typography>

          <Flex justify="center" align="center" className="mt-[100px]">
            <InputLabel
              label={t('productDuplication.modal.copyFrom')}
              className="min-h-[30px] w-[148px]"
            />
            <Typography className="ml-3 mr-12 min-w-[250px]">
              {initialData?.source}
            </Typography>
          </Flex>

          <Flex justify="center" align="center" className="mt-[6px]">
            <InputLabel
              label={t('productDuplication.modal.copyTo')}
              className="h-[30px] w-[148px]"
            />
            <Typography className="ml-3 mr-12 min-w-[250px]">
              {initialData?.destination}
            </Typography>
          </Flex>

          <Flex justify="center" className="mt-[110px]">
            <Button
              type="primary"
              htmlType="button"
              onClick={onSubmit}
              className="h-[30px] w-[180px] rounded-none border-[1px] border-[#137695] bg-[#137695] p-0 text-center font-bold text-white shadow-none"
            >
              {t('productDuplication.modal.button.copy')}
            </Button>

            <Button
              type="default"
              htmlType="button"
              onClick={onCancel}
              className="ml-3 h-[30px] w-[180px] rounded-none border-[1px] border-[#137695] bg-white p-0 text-center font-bold text-[#137695] shadow-none"
            >
              {t('productDuplication.modal.button.cancel')}
            </Button>
          </Flex>
        </div>
      }
    />
  )
}

export default ReservationRegistrationCompletedNotice
