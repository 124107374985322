import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  FieldValues,
  FormProvider,
  UseFormSetValue,
  useForm,
  useFormContext
} from 'react-hook-form'

import { Button, Tabs, TabsProps, Typography } from 'antd'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { useRecoilState, useRecoilValue } from 'recoil'

import { CourseResponse, ReservationHistoryAtom } from '../types/types'
import CustomerDetailTable from './CustomerDetailTable'
import InterviewList from './InterviewList'
import styles from './InterviewList.module.scss'
import ControlPanel from './controlPanel/ControlPanel'
import DrawerSchedule from './controlPanel/DrawerSchedule'
import PatientInfo from './controlPanel/PatientInfo'
import PatientMemo from './controlPanel/PatientMemo'
import PatientReservation from './controlPanel/PatientReservation'
import ReservationField from './controlPanel/ReservationField'
import ReservationHistory from './controlPanel/ReservationHistory'
import TablePatientInfo from './controlPanel/TablePatientInfo'
import CourseSearch from './forms/CourseSearch'
import CustomerDetailEditForm, {
  CustomerDetailEditFormMethods
} from './forms/CustomerDetailEditForm'
import { useLazyQuery } from '@apollo/client'
import { SelectMultipleCustom } from 'components/elements/Select'
import { openNotification } from 'components/widgets/Notification'
import { RESERVATION_STATUS } from 'configs/constant'
import { FILTER_RESERVATION_HISTORY } from 'graphql/PatientDetail/reservationHistory'
import useDrawerSchedule from 'hooks/useDrawerSchedule'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import usePatientDetail, {
  initialTableSearchSlotReservation,
  reservationHistoryAtom,
  tableSearchSlotReservationAtom
} from 'hooks/usePatientDetail'
import { TAB } from 'models/customer'
import { COUNTRY } from 'models/setting'
import { Endpoint } from 'utilities/apolloClient'
import {
  filterCourseResponseInValid,
  getNameCourseOrOptionByIds
} from 'utilities/helpers'

type Props = {
  t: TFunction<'translation', undefined>
  dataForm: any
  dataTable: any
  tab: string
  setTab: (tab: string) => void
  isOpenEditForm: boolean
  onChange: (tab: string) => void
  childRef: React.RefObject<CustomerDetailEditFormMethods>
  onSubmitChild: (data: any) => void
  page: any
  size: any
  totalRecords: any
  setPage: any
  loadingMedical: any
  language: any
  onOpenEditForm: any
}

const CustomerDetailContent: FC<Props> = ({
  t,
  dataForm,
  dataTable,
  tab,
  setTab,
  isOpenEditForm,
  onChange,
  childRef,
  onSubmitChild,
  page,
  size,
  totalRecords,
  setPage,
  loadingMedical,
  language,
  onOpenEditForm
}) => {
  // const customerId = dataForm?.id

  // hooks custom
  // const { isOpen, setIsOpen } = useChatModal()

  const {
    isOpen: isOpenDrawerSchedule,
    onOpen: onOpenDrawerSchedule,
    onClose: onCloseDrawerSchedule
  } = useDrawerSchedule()
  const { handleSearchCourse } = usePatientDetail()

  const [searchVal, setSearchVal] = useState<string>('')

  const handleTabChange = (selectedTab: string) => {
    if (selectedTab !== TAB.CONTROL && isOpenDrawerSchedule) {
      onCloseDrawerSchedule()
    }

    setTab(selectedTab)
    onChange(selectedTab)
  }

  const dataTablePatientInfo = useMemo(() => {
    const province =
      dataForm?.country === COUNTRY.JAPAN
        ? dataForm?.province
        : dataForm?.stateName

    const location = [
      dataForm?.apartment,
      dataForm?.address,
      dataForm?.district,
      province
    ]

    const [apartment, address, district, state] =
      dataForm?.country === COUNTRY.JAPAN ? location.reverse() : location

    return [
      {
        label: 'dashboard.last_name',
        value: dataForm?.firstName
      },
      {
        label: 'dashboard.first_name',
        value: dataForm?.lastName
      },
      {
        label: 'dashboard.gender',
        value: dataForm?.gender === 'MALE' ? '男' : '女性'
      },
      {
        label: 'dashboard.date_of_birth',
        value: dayjs(dataForm?.birthday).format('YYYY/MM/DD')
      },
      {
        label: 'dashboard.address1',
        value: (
          <div className="flex flex-col">
            <span>{apartment}</span>
            <span>{address}</span>
            <span>{district}</span>
            <span>{state}</span>
          </div>
        )
      },
      {
        label: 'dashboard.phone_number',
        value: dataForm?.phone
      },
      {
        label: 'dashboard.emergency_contact',
        value: dataForm?.emergencyContact
      },
      {
        label: 'dashboard.email_address',
        value: dataForm?.email
      },
      {
        label: 'dashboard.insurance_type',
        value: dataForm?.typeOfInsurance
      },
      {
        label: 'dashboard.insurance_number',
        value: dataForm?.numberOfInsurance
      },
      {
        label: 'dashboard.expiration_date',
        value: dataForm?.insuranceEndDate
      },
      { label: 'dashboard.record_number', value: dataForm?.insuranceSymbol },
      { label: 'dashboard.number', value: dataForm?.insuranceNumber },
      { label: 'dashboard.delivery_date', value: dataForm?.insuranceStartDate },
      {
        label: 'dashboard.contracted_travel_insurance',
        value: dataForm?.additionalInfo?.foreignInsuranceCompany
      },
      {
        label: 'dashboard.medication_allergy',
        value: dataForm?.additionalInfo?.drugAllergy
          ? `${t('content.canBe')} (${dataForm.additionalInfo.drugAllergy})`
          : `${t('content.none')}`
      },
      { label: 'dashboard.user_id', value: dataForm?.id },
      { label: 'dashboard.memo', value: dataForm?.note }
    ]
  }, [dataForm])

  const { convertReservationToTableData, getOptionsFromOptionIds } =
    usePatientDetail()
  const reservationHistory = useRecoilValue(reservationHistoryAtom)
  const { getMedicalCheckupMaster, getOptionIdsByCourseId } =
    useMedicalCheckupMaster()
  const [fetchReservationHistory] = useLazyQuery(FILTER_RESERVATION_HISTORY, {
    context: { version: Endpoint.RESERVATION },
    fetchPolicy: 'network-only'
  })

  const [tableSearchSlotReservation, setTableSearchSlotReservation] =
    useRecoilState(tableSearchSlotReservationAtom)

  const methods = useForm<{
    courseSearch: string | undefined
    optionSearch: string | undefined
    courseSearchDrawer: string | undefined
    optionSearchDrawer: string | undefined
  }>()
  useEffect(() => {
    if (tableSearchSlotReservation.course?.list.length && dataForm?.id) {
      fetchReservationHistory({
        variables: {
          filter: `(in,STRING,checkupUserRefId,${dataForm.id});(in,STRING,reservationStatus,${RESERVATION_STATUS.RESERVED},${RESERVATION_STATUS.CANCELLED})`,
          page: 1,
          size: -1,
          sortBy: '(desc,modifiedDate)'
        }
      })
        .then((res: any) => {
          const {
            data: {
              filterReservation: { payload }
            }
          } = res

          if (!payload) return

          getMedicalCheckupMaster({
            filter: [
              {
                field: 'data.additionalInfo.key1',
                operator: 'ne',
                value: 'option'
              }
            ],
            sortBy: {
              field: 'createdDate',
              direction: 'desc'
            }
          }).then((res: CourseResponse[]) => {
            convertReservationToTableData(payload, res)
          })
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [tableSearchSlotReservation.course?.list, dataForm?.id])

  // get course list
  useEffect(() => {
    if (!dataForm) return

    getMedicalCheckupMaster({
      filter: [
        { field: 'data.additionalInfo.key1', operator: 'ne', value: 'option' },
        {
          field: 'data.additionalInfo.isDeleted',
          operator: 'ne',
          value: 'true'
        }
      ],
      sortBy: {
        field: 'createdDate',
        direction: 'desc'
      }
    }).then((res: CourseResponse[]) => {
      setTableSearchSlotReservation((prev) => ({
        ...prev,
        course: {
          ...prev.course,
          list: filterCourseResponseInValid(res, {
            sex: dataForm?.gender,
            age: String(dataForm?.age)
          })
        }
      }))
    })

    return () =>
      setTableSearchSlotReservation(initialTableSearchSlotReservation)
  }, [dataForm?.age, dataForm?.gender])

  const updateOptions = async (
    courseId: string,
    sex: string,
    age: string,
    isReset: boolean
  ) => {
    if (!courseId) return

    const optionIds = getOptionIdsByCourseId(
      courseId,
      tableSearchSlotReservation.course.list
    )

    const options = await getOptionsFromOptionIds(optionIds)

    const satisfyOptions = filterCourseResponseInValid(options, {
      sex: sex,
      age: age
    })

    const optionsConvertedTypeSelect = satisfyOptions.map((option) => ({
      label: option.medicalCheckupMaster.displayName,
      value: option.medicalCheckupMaster.refId
    }))

    setTableSearchSlotReservation((prev) => ({
      ...prev,
      option: {
        ...prev.option,
        filterList: optionsConvertedTypeSelect,
        list: satisfyOptions,
        optionName: isReset ? '' : prev.option.optionName,
        optionIds: isReset ? [] : prev.option.optionIds
      }
    }))
  }

  const handleFillLastReservation = (
    setValue: UseFormSetValue<FieldValues>
  ) => {
    if (reservationHistory.length > 0) {
      const lastReservation: ReservationHistoryAtom = reservationHistory[0]

      const courseName =
        lastReservation.courseName !== '-'
          ? lastReservation.courseName
          : undefined
      const courseId = lastReservation.courseId

      const optionName =
        lastReservation.optionName !== '-'
          ? lastReservation.optionName
          : undefined
      const optionIds = lastReservation.optionIds ?? []

      // setCourseAndOptionsSelected({
      //   courseSearch: {
      //     courseId: courseId,
      //     courseName: courseName ?? ''
      //   },
      //   optionSearch: {
      //     optionIds: optionIds,
      //     optionName: optionName ?? ''
      //   }
      // })

      setTableSearchSlotReservation((prev) => ({
        ...prev,
        course: {
          ...prev.course,
          courseId,
          courseName: courseName ?? ''
        },
        option: {
          ...prev.option,
          optionIds,
          optionName: optionName ?? ''
        }
      }))

      // get course list by select type
      setTimeout(() => {
        handleSearchCourse(courseName ?? '')
        updateOptions(courseId, dataForm.gender, dataForm.age, false)
      }, 0)

      setValue('courseSearch', courseId)
      setValue('optionSearch', optionIds)
    }
  }

  const handleOpenDrawerSchedule = () => {
    const courseId = tableSearchSlotReservation.course?.courseId

    if (courseId) {
      setTableSearchSlotReservation((prev) => ({
        ...prev,
        action: 'search',
        course: {
          ...prev.course,
          courseId,
          courseName: tableSearchSlotReservation.course.courseName
        },
        option: {
          ...prev.option,
          optionIds: tableSearchSlotReservation.option.optionIds,
          optionName: tableSearchSlotReservation.option.optionName
        }
      }))

      onOpenDrawerSchedule()
    } else {
      openNotification({
        type: 'error',
        title: t('dashboard.course_search_required'),
        message: ''
      })
    }
  }

  const onChangeCourseOrOptions = (
    name: string,
    value: string[] | string // value is course id or option ids
  ) => {
    if (!name || !value) return

    setTableSearchSlotReservation((prev) => {
      const updatedState = { ...prev }

      const updateField = (field: 'course' | 'option', data: any) => {
        updatedState[field] = {
          ...updatedState[field],
          ...data
        }
      }

      if (name === 'courseSearch') {
        const courseId = value as string

        // Update options when course changes
        updateOptions(courseId, dataForm?.gender, String(dataForm?.age), true)

        const courses = updatedState.course.list ?? []
        const courseUpdate = {
          courseId,
          courseName: getNameCourseOrOptionByIds(courses, [courseId])
        }

        updateField('course', courseUpdate)
      } else if (name === 'optionSearch') {
        const optionIds = value as string[]
        const options = updatedState.option.list ?? []

        const optionUpdate = {
          optionIds,
          optionName: getNameCourseOrOptionByIds(options, optionIds)
        }

        updateField('option', optionUpdate)
      }

      return updatedState
    })
  }

  const items: TabsProps['items'] = [
    {
      key: TAB.CONTROL,
      label: (
        <Typography className="w-[180px] text-center text-[14px]">
          {t('customerDetail.control')}
        </Typography>
      ),
      children: (
        <>
          {isOpenEditForm ? (
            <CustomerDetailEditForm
              t={t}
              ref={childRef}
              initialData={dataForm}
              onSubmit={onSubmitChild}
            />
          ) : (
            <FormProvider {...methods}>
              <ControlPanel
                patientMemo={<PatientMemo memoText={dataForm?.note} />}
                patientInfo={
                  <PatientInfo
                    setTab={setTab}
                    onOpenEditForm={onOpenEditForm}
                    tableInfo={<TablePatientInfo data={dataTablePatientInfo} />}
                  />
                }
                patientReservation={
                  <PatientReservation
                    handleFillLastReservation={handleFillLastReservation}
                    courseSearch={
                      <CourseSearch
                        inputCourseSearch={
                          <ReservationField
                            onchange={onChangeCourseOrOptions}
                            selectData={
                              tableSearchSlotReservation.course.filterList
                            }
                            name="courseSearch"
                            label={t('dashboard.search.course')}
                            placeholder={t(
                              'dashboard.search.course_placeholder'
                            )}
                            handleSearch={(val: string) => {
                              handleSearchCourse(val)
                              setSearchVal(val)
                            }}
                            searchVal={searchVal}
                          />
                        }
                        inputOptionSearch={
                          <div className="flex flex-col">
                            <label className="text-default text-sm font-bold">
                              {t('dashboard.search.option')}
                            </label>
                            <SelectMultipleCustom
                              onChange={onChangeCourseOrOptions}
                              name="optionSearch"
                              placeholder={t('placeholder.findAnOption')}
                              options={
                                tableSearchSlotReservation.option.filterList
                              }
                              className="w-full"
                              isOpenNotFoundContent={
                                Boolean(
                                  tableSearchSlotReservation?.course?.courseId
                                ) ?? false
                              }
                            />
                          </div>
                        }
                        btnSearch={
                          <div className="flex justify-end">
                            <Button
                              onClick={handleOpenDrawerSchedule}
                              className="shadow-none mt-[40px] font-bold"
                              type="primary"
                            >
                              {t('dashboard.search_empty_slots')}
                            </Button>
                          </div>
                        }
                      />
                    }
                    drawerSchedule={
                      isOpenDrawerSchedule ? (
                        <DrawerSchedule
                          courseSearch={
                            tableSearchSlotReservation.course.filterList
                          }
                          optionSearch={
                            tableSearchSlotReservation.option.filterList
                          }
                          userInfo={{
                            id: dataForm?.id,
                            firstName: dataForm?.firstName,
                            firstNameKana: dataForm?.firstNameKana,
                            lastName: dataForm?.lastName,
                            lastNameKana: dataForm?.lastNameKana,
                            age: dataForm?.age,
                            sex: dataForm?.gender
                          }}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                }
                reservationHistory={
                  <ReservationHistory
                    age={dataForm?.age}
                    sex={dataForm?.gender}
                  />
                }
                // chatHistory={
                //   <ChatHistory
                //     messages={<Rooms data={userRooms} />}
                //     chatModal={
                //       <ChatModal isOpen={isOpen} setIsOpen={setIsOpen} />
                //     }
                //   />
                // }
                chatHistory={<></>}
              />
            </FormProvider>
          )}
        </>
      )
    },
    {
      key: TAB.RESULT_LIST,
      label: (
        <Typography className="w-[180px] text-center text-[14px]">
          {t('customerDetail.resultList')}
        </Typography>
      ),
      children: (
        <>
          <CustomerDetailTable
            t={t}
            data={dataTable}
            customer={dataForm}
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
            loadingMedical={loadingMedical}
            language={language}
          />
        </>
      )
    },
    {
      key: TAB.INTERVIEW_LIST,
      label: (
        <Typography className="w-[180px] text-center text-[14px]">
          {t('customerDetail.interviewList')}
        </Typography>
      ),
      children:
        dataForm !== undefined ? (
          <InterviewList
            userRefId={dataForm && dataForm.id}
            patientName={dataForm && dataForm.firstName + dataForm.lastName}
            t={t}
          />
        ) : (
          <></>
        )
    }
  ]

  return (
    <div className="min-h-[700px] min-w-[1700px] rounded-[8px] bg-white px-[10px] pb-[26px] pt-[6px] text-[14px] text-[#545454] drop-shadow-md">
      <Tabs
        activeKey={tab}
        items={items}
        onChange={handleTabChange}
        className={styles.tab_wrapper}
      />
    </div>
  )
}

export default CustomerDetailContent
