import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { MessageContent } from './MessageContent'
import { MessageHeader } from './MessageHeader'
import { FIXED_TEXT_TYPE, STEP_SCENARIO } from 'configs/constant'
import DeleteConfirmationModal from 'pages/Scenario/ScenarioList/components/modal/DeleteConfirmationModal'
import { Flow } from 'pages/Scenario/types/types'

export interface DrawerDataProps {
  title?: string
  description?: string
  templateName?: string
  type?: STEP_SCENARIO
}

export interface FixedTextProps {
  type: string
  isOpenRemoveBlockModal?: boolean
  onCloseRemoveBlockModal?: () => void
  onOpenRemoveBlockModal?: () => void
  onConfirmRemoveBlockModal?: () => void
  isOpenDeletedBlockModal?: boolean
  onConfirmDeletedBlockModal?: () => void
  title?: string
  description?: string
  onOpenDrawer?: () => void
}

const FirstStep = (props: FixedTextProps) => {
  const { t } = useTranslation()

  return (
    <div className="border w-full bg-contentGray border-borderGray rounded-[10px] shadow-md">
      <MessageHeader
        title={props.title}
        onOpenDrawer={props.onOpenDrawer}
        onOpenModalRemoveBlock={props.onOpenRemoveBlockModal}
      />

      <MessageContent
        description={props.description}
        isEnabledBtns={props.type === FIXED_TEXT_TYPE.START_ACTIVATING}
      />

      {props.isOpenRemoveBlockModal && (
        <DeleteConfirmationModal
          title={t('scenarioCreate.modal.deleteComplete.titleConfirm')}
          isOpen={props.isOpenRemoveBlockModal}
          onClose={props.onCloseRemoveBlockModal}
          onConfirm={props.onConfirmRemoveBlockModal}
        />
      )}

      {props.isOpenDeletedBlockModal && (
        <DeleteConfirmationModal
          isOpen={props.isOpenDeletedBlockModal}
          onClose={props.onConfirmDeletedBlockModal}
          isEnableOkButton={false}
          description={t('scenarioCreate.modal.deleteComplete.description')}
          title={t('scenarioCreate.modal.deleteComplete.title')}
          icon={<></>}
          textCancel={t('scenarioList.errorModal.backButton')}
          maskClosable={false}
        />
      )}
    </div>
  )
}

export default memo(FirstStep)
