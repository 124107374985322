import { FC, memo, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Empty, Select, SelectProps } from 'antd'

import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import {
  useGetMedicalCheckupMasterByName,
  useGetMedicalCheckupMasterDetails
} from 'hooks/useMedicalCheckupMaster'
import debounce from 'lodash.debounce'
import { MedicalCheckupMasterDetail } from 'models/productManagement'
import { preventEnterKeyDefault } from 'utilities/helpers'

type Props = {
  placeholder: string
}

const CourseSelect: FC<Props> = memo(({ placeholder }) => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const { getMedicalCheckupMastersByName } = useGetMedicalCheckupMasterByName()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()

  const queryParams = new URLSearchParams(search)
  const { courseId: courseParam } = Object.fromEntries(queryParams)

  const [searchData, setSearchData] = useState<SelectProps['options']>([])
  const [initialValue, setInitialValue] = useState<string>()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (courseParam) {
      getMedicalCheckupMastersById(courseParam)
        .then((data: MedicalCheckupMasterDetail) => {
          if (!data) return

          setSearchData([
            {
              value: data.medicalCheckupMaster.refId,
              label: data.medicalCheckupMaster.displayName
            }
          ])
          setInitialValue(`${data.medicalCheckupMaster.refId}`)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [])

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (!value) {
          setInitialValue(undefined)
          setSearchData([])
          setIsOpen(false)

          queryParams.delete('courseId')
          navigate(`${pathname}?${queryParams.toString()}`)
          return
        }

        try {
          const fetchData = await getMedicalCheckupMastersByName(value)
          setSearchData(
            fetchData.map((item: MedicalCheckupMasterDetail) => ({
              value: item.medicalCheckupMaster.refId,
              label: item.medicalCheckupMaster.displayName
            }))
          )
          setIsOpen(true)
        } catch (e) {
          console.error(e)
        }
      }, 500),
    []
  )

  const handleChange = (value: string) => {
    setInitialValue(value)

    queryParams.set('courseId', value)
    navigate(`${pathname}?${queryParams.toString()}`)
  }

  return (
    <Select
      notFoundContent={
        isOpen ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null
      }
      value={initialValue}
      onChange={handleChange}
      options={searchData}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      onInputKeyDown={preventEnterKeyDefault}
      menuItemSelectedIcon={false}
      suffixIcon={<SearchOutlined style={{ pointerEvents: 'none' }} />}
      placeholder={placeholder}
      className="ml-2 w-full min-w-[200px] max-w-[424px]"
    />
  )
})

export default CourseSelect
