import { useEffect, useState } from 'react'

import { SetterOrUpdater } from 'recoil'

import { MODAL_TYPE, STEP_SCENARIO } from 'configs/constant'
import {
  initialDataScenarioCreate,
  stepEnd
} from 'pages/Scenario/ScenarioCreate/data/data'
import { Flow, ScenarioCreate } from 'pages/Scenario/types/types'

const initialDataModal = {
  deletedBlock: false,
  deleteBlock: false,
  chooseTypeScenario: false
}

const useModal = (setScenarioCreate?: SetterOrUpdater<ScenarioCreate>) => {
  const [modal, setModal] = useState(initialDataModal)

  useEffect(() => {
    return () => {
      setModal(initialDataModal)
    }
  }, [])

  const onCloseModal = (type: MODAL_TYPE) => {
    setModal((prev) => ({ ...prev, [type]: false }))
  }

  const onOpenModal = (type: string) => {
    setModal((prev) => ({ ...prev, [type]: true }))
  }

  const onCloseAllModal = () => {
    setModal(initialDataModal)
  }

  const onConfirmModal = (type: MODAL_TYPE, modalOrder?: STEP_SCENARIO) => {
    onCloseAllModal()

    if (type === MODAL_TYPE.DELETE_BLOCK) {
      onOpenModal(MODAL_TYPE.DELETED_BLOCK)
    } else if (
      type === MODAL_TYPE.DELETED_BLOCK &&
      modalOrder === STEP_SCENARIO.FIRST_STEP
    ) {
      setScenarioCreate?.(() => {
        return initialDataScenarioCreate
      })
    } else if (
      type === MODAL_TYPE.DELETED_BLOCK &&
      modalOrder === STEP_SCENARIO.LAST_STEP
    ) {
      setScenarioCreate?.((prev) => ({
        ...prev,
        flows: updateStep(prev.flows, STEP_SCENARIO.LAST_STEP)
      }))
    }
  }

  const updateStep = (flows: Flow[], order: STEP_SCENARIO) => {
    const flowsTemp = [...flows]

    return flowsTemp.map((flow) => {
      if (flow.order === order && flow.order === STEP_SCENARIO.LAST_STEP) {
        return {
          ...stepEnd,
          isActivated: false
        }
      }

      return flow
    })
  }

  return {
    modal,
    onCloseModal,
    onOpenModal,
    onCloseAllModal,
    onConfirmModal
  }
}

export default useModal
