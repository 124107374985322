import { DocumentNode, gql } from '@apollo/client'

export const FILTER_CONSULTATION_REPORT: DocumentNode = gql`
  query MyQuery($year: Int!, $month: Int, $sortBy: String) {
    getClaimsBills(year: $year, month: $month, sortBy: $sortBy) {
      payload {
        amount
        price
        year
        month
      }
    }
  }
`
