import dayjs from 'dayjs'
import i18n, { TFunction } from 'i18next'
import { v4 as uuidv4 } from 'uuid'

import {
  DEFAULT_DATE_FORMAT,
  DateFormat,
  RESERVATION_STATUS,
  STORAGE_TENANT_KEY,
  STORAGE_TOKEN_CLIENT_KEY,
  STORAGE_TOKEN_KEY,
  statusRoom,
  typeReferenceValue
} from '../configs/constant'
import { Customer } from '../hooks/useCustomer'
import { format } from 'date-fns'
import weekday from 'dayjs/plugin/weekday'
import { Attachment } from 'hooks/chat/types'
import { jwtDecode } from 'jwt-decode'
import { COUNTRY } from 'models/setting'
import { ChatMessageType } from 'pages/CustomerDetail/components/controlPanel/ChatContainer'
import { CourseResponse } from 'pages/CustomerDetail/types/types'
import short from 'short-uuid'
import { StringKeyObject } from 'types/common'

dayjs.extend(weekday)
dayjs.locale('ja') // Set locale to Japanese

// bind url with params
export const bindParams = (str: string, params: any = {}) => {
  let result = str
  for (let key in params) {
    result = result.replace(new RegExp(`:${key}`, 'g'), params[key])
  }
  return result
}

export const isNumber = (value: any) => {
  return typeof value === 'number' && !isNaN(value)
}

export const getEnvVar = (envKey: string) => {
  const envVar = getAttr(window?.env, envKey)

  if (!envVar || envVar === '') {
    return getAttr(process.env, envKey)
  }

  return envVar
}

export const getCurrentDateFormatted = (
  formatStr: string = "yyyy-MM-dd'T'HH:mm:ss"
) => {
  const now = new Date()
  return format(now, formatStr)
}

export const formatDateTimeJa = (datestr: string) => {
  const date = new Date(datestr)

  const dayOfWeek = new Intl.DateTimeFormat('ja-JP', {
    weekday: 'short'
  }).format(date)

  const formattedDate = new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(date)

  const time = date.toLocaleTimeString('ja-JP', {
    hour: '2-digit',
    minute: '2-digit'
  })

  return `${formattedDate}（${dayOfWeek}）${time}`
}

export const groupByKey = (array: any[], key: string) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj)
    })
  }, {})
}

export const scrollToErrorField = ({ errorFields }: any) => {
  const el = document.getElementById(errorFields[0].name[0]) as HTMLElement
  el.scrollIntoView()
  window.scrollTo(0, window.scrollY - el.offsetHeight - 300)
}

export const str2json = (str: string) => {
  if (!str) return ''

  try {
    return JSON.parse(str)
  } catch (e) {
    console.error('Invalid JSON string:', e)
  }
}

export const json2str = (json: any) => {
  if (!json) return ''

  try {
    return JSON.stringify(json)
  } catch (e) {
    console.error('Invalid JSON string:', e)
  }
}

export const isArrayEmpty = (array: any) => {
  return Array.isArray(array) && array.length === 0
}

export const getAttributes = (
  attributes: any,
  key: string,
  defaultValue: any = ''
) => {
  return attributes?.[key] ? attributes?.[key]?.[0] : defaultValue
}

export const getAttr = (object: any, key: string, defaultValue: any = '') => {
  if (!object) {
    return defaultValue
  }

  return object[key] ? object[key] : defaultValue
}

export const resolvePath = (path: any, obj = {}, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev: any, curr: any) => prev?.[curr], obj)
}

export const validPerPage = (size: any) => {
  if (!size) return 100 // default size
  if (isNaN(size)) return 0
  if (size <= 0) return 1
  if (size > 500) return 500

  return size
}

export const validPage = (page: any) => {
  if (!page) return 0 // default page
  if (isNaN(page)) return 0
  if (page < 0) return 0
  if (page > 500) return 500

  return page
}

export default function isArrayEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1 === arr2) return true

  if (!arr1 || !arr2 || (typeof arr1 !== 'object' && typeof arr2 !== 'object'))
    return arr1 === arr2

  // This means the elements are objects
  // If they are not the same type of objects
  // @ts-expect-error required
  if (arr1.prototype !== arr2.prototype) return false

  const keys = Object.keys(arr1)
  if (keys.length !== Object.keys(arr2).length) return false

  // Check recursively for every key in both
  return keys.every((k: any) => isArrayEqual(arr1[k], arr2[k]))
}

export const uuid = (dashes = true) => {
  if (!dashes) return uuidv4().replace(/-/gi, '')
  return uuidv4()
}

export const shortUuid = () => {
  return short.generate()
}

export const isObjectEmpty = (objectName: any = {}) => {
  return Object.keys(objectName).length === 0
}

export const getValidToken = (token: string) => {
  if (token === '') return null
  let decodedToken = jwtDecode(token)
  let exp = decodedToken?.exp ?? 0
  let currentDate = new Date()

  // JWT exp is in seconds
  if (exp * 1000 < currentDate.getTime()) {
    console.error('Token expired.')
    return null
  }

  return token
}

export const decodedToken = (
  token: string | null | undefined,
  key: string = 'sub'
): string => {
  if (!token || token === '' || token === 'undefined') return ''
  let _decodedToken: any
  try {
    _decodedToken = jwtDecode(token)
  } catch (e) {
    removeAccessToken()
  }

  return _decodedToken && _decodedToken[key]
    ? (_decodedToken[key] as string)
    : ''
}

// sleep time expects milliseconds
export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const forceTypeBoolean = (
  value: string | null | boolean
): boolean | null => {
  if (typeof value === 'boolean') {
    return value
  }

  if (typeof value === 'string') {
    return value === 'true' || value === '1'
  }

  return Boolean(value)
}

export const isTruthy = (value: string | null | boolean): boolean => {
  value = forceTypeBoolean(value)
  return !!value
}

export const removeLineBreaks = (
  value: any,
  replaceStr: string = ' '
): string => {
  return value.replace(/(\r\n|\n|\r)/gm, replaceStr)
}

export const isLoggedIn = () => {
  return !!getValidToken(getAccessToken() || '')
}

export const getAccessToken = () => {
  return localStorage.getItem(STORAGE_TOKEN_KEY)
}
export const getTenantLocal = () => {
  const jsonValue = localStorage.getItem(STORAGE_TENANT_KEY)
  if (jsonValue != null) return JSON.parse(jsonValue)
  return {}
}

export const getAccessTokenClient = () => {
  return localStorage.getItem(STORAGE_TOKEN_CLIENT_KEY)
}

export const clearLocalStorage = () => {
  return localStorage.clear()
}

export const removeAccessToken = () => {
  return localStorage.removeItem(STORAGE_TOKEN_KEY)
}

export const removeClientAccessToken = () => {
  return localStorage.removeItem(STORAGE_TOKEN_CLIENT_KEY)
}

export const setAccessToken = (payload: { access_token: string }) => {
  localStorage.setItem(STORAGE_TOKEN_KEY, payload.access_token)
}

export const setClientAccessToken = (access_token: string) => {
  localStorage.setItem(STORAGE_TOKEN_CLIENT_KEY, access_token)
}

export const getUserByToken = () => {
  const token = getAccessToken()
  return token ? jwtDecode(token) : null
}

export const mergeItemMaster = (data: any) => {
  return Object.values(
    data.reduce((acc: any, obj: any) => {
      if (!acc[obj.refId] || acc[obj.refId].createdDate < obj.createdDate) {
        acc[obj.refId] = obj
      }
      return acc
    }, {})
  )
}

export const filterNewItemList = (
  data: any,
  key: any,
  compare: any,
  dataByKey = false
) => {
  let result = data.reduce((acc: any, obj: any) => {
    if (!acc[obj[key]] || acc[obj[key]][compare] < obj[compare]) {
      acc[obj[key]] = obj
    }
    return acc
  }, {})

  return dataByKey ? result : Object.values(result)
}

export const preventEnterKeyDefault = (event: any) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    event.stopPropagation()
  }
}

export const getTenantAddress = (tenant: any) => {
  const zipCode = tenant?.additionalInfo?.zipCode
  const zipCodePart1 = zipCode ? zipCode.slice(0, 3) : ''
  const zipCodePart2 = zipCode ? zipCode.slice(3, 7) : ''
  const province = tenant?.additionalInfo?.province ?? ''
  const district = tenant?.additionalInfo?.district ?? ''
  const address = tenant?.additionalInfo?.address ?? ''
  const apartment = tenant?.additionalInfo?.apartment ?? ''
  const stateName = tenant?.additionalInfo?.stateName ?? ''

  if (tenant?.additionalInfo?.country === COUNTRY.JAPAN) {
    return `〒${zipCodePart1}-${zipCodePart2}${province}${district}${address}${apartment}`
  }

  return `〒${zipCodePart1}-${zipCodePart2} ${apartment} ${address} ${district} ${stateName}`
}

export const getStrKeyByData = (data: Array<any>, key: string) => {
  let result = data.reduce((acc: any, item: any) => {
    if (item?.[key]) {
      acc = acc === '' ? acc + item?.[key] : acc + ',' + item?.[key]
    }

    return acc
  }, '')

  return result
}

export const getLanguage = () => {
  return i18n.language
}

export const downloadCSV = (data: any, filename: string) => {
  // Create a Blob with the CSV data and type
  const blob = new Blob([data], { type: 'text/csv' })

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob)

  // Create an anchor tag for downloading
  const a = document.createElement('a')

  // Set the URL and download attribute of the anchor tag
  a.href = url
  a.download = filename

  a.click()
}

export const getWeekRange = (date?: dayjs.Dayjs) => {
  const today = date || dayjs()
  const dayOfWeek = today.day()

  const startOfWeek = today
    .subtract(dayOfWeek === 0 ? 6 : dayOfWeek - 1, 'day')
    .format('YYYY-MM-DD')
  const endOfWeek = today
    .add(dayOfWeek === 0 ? 0 : 7 - dayOfWeek, 'day')
    .format('YYYY-MM-DD')

  return { startDate: startOfWeek, endDate: endOfWeek }
}

export const generateDateRange = (dateRange: {
  startDate: string
  endDate: string
}) => {
  const startDate = dayjs(dateRange.startDate)
  const endDate = dayjs(dateRange.endDate)

  const fullRange = []

  let currentDay = startDate
  while (currentDay.isBefore(endDate) || currentDay.isSame(endDate)) {
    fullRange.push(currentDay.format('YYYY-MM-DD'))
    currentDay = currentDay.add(1, 'day')
  }

  return fullRange
}

export const generateFullWeekRange = (dateRange: {
  startDate: string
  endDate: string
}) => {
  const start = new Date(dateRange.startDate)
  const end = new Date(dateRange.endDate)

  const startDay = start.getDay()
  const startOfWeek = new Date(start)
  startOfWeek.setDate(start.getDate() - (startDay === 0 ? 6 : startDay - 1))

  const endDay = end.getDay()
  const endOfWeek = new Date(end)
  endOfWeek.setDate(end.getDate() + (endDay === 0 ? 0 : 7 - endDay))

  const dates = []
  let currentDate = new Date(startOfWeek)

  while (currentDate <= endOfWeek) {
    const formattedDate = currentDate.toISOString().split('T')[0]
    dates.push(formattedDate)
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export const renderActionStyle = (reservationStatus: RESERVATION_STATUS) => {
  const btnChangeStyle = ' m-w-[60px] bg-[#30758F] text-white'
  const btnCancelStyle = ' m-w-[60px] bg-transparent text-[#BDCBD5] border-none'

  switch (reservationStatus) {
    case RESERVATION_STATUS.COLLECTED_DESIRED_DATE:
    case RESERVATION_STATUS.UNCONFIRMED:
    case RESERVATION_STATUS.RESERVED:
      return {
        style: btnChangeStyle,
        text: 'dashboard.change'
      }
    case 'CANCELLED':
      return {
        style: btnCancelStyle,
        text: 'dashboard.cancel'
      }

    default:
      return {
        style: '',
        text: ''
      }
  }
}

export const getInfoByStatus = (status: statusRoom) => {
  if (status === statusRoom.OPEN) {
    return {
      styles: 'text-[#137695] bg-[#13769533]',
      text: '進行中'
    }
  } else if (status === statusRoom.END) {
    return {
      styles: 'text-[#BDCBD5] bg-[#F0F3F7]',
      text: '終了'
    }
  } else {
    return {
      styles: '',
      text: ''
    }
  }
}

export const getDaysInMonth = (year: number, month: number): number => {
  const date = dayjs(`${year}-${month}-01`)
  return date.daysInMonth()
}

export const formatJapaneseDate = (
  date: string,
  fmt: string = 'YYYY/MM/DD (ddd)',
  location?: string
): string => {
  if (location) {
    return dayjs(date).locale(location).format(fmt)
  }

  return dayjs(date).format(fmt)
}

export const getTimeRange = (start: string = '08:00', end?: string): string => {
  const formattedStartTime = dayjs(start, 'HH:mm').format('HH:mm')
  const formattedEndTime = end ? dayjs(end, 'HH:mm').format('HH:mm') : ''

  return formattedEndTime
    ? `${formattedStartTime}~${formattedEndTime}`
    : `${formattedStartTime}~`
}

export const formatTimeConditional = (dateString?: string) => {
  if (!dateString) return ''

  const inputDateTime = dayjs(isNumber(+dateString) ? +dateString : dateString)
  const currentDateTime = dayjs()

  return inputDateTime.isSame(currentDateTime, 'day')
    ? inputDateTime.format('HH:mm')
    : inputDateTime.format(`YYYY/MM/DD  HH:mm:ss`)
}

export const formatDate = (
  dateString?: string,
  dateFormat: string = DEFAULT_DATE_FORMAT
) => {
  if (!dateString) return ''

  return format(new Date(dateString), dateFormat)
}

export const getFirstAndLastDayOfMonth = (month: number, year: number) => {
  const firstDay = dayjs(`${year}-${month}-01`).startOf('month')
  const lastDay = dayjs(`${year}-${month}-01`).endOf('month')

  return {
    firstDay: firstDay.format('YYYY-MM-DD'),
    lastDay: lastDay.format('YYYY-MM-DD')
  }
}

/**
 * Generates an array of ten years starting from the current year.
 */
export const generateTenYearOptions = (): number[] => {
  const currentYear = dayjs().year()
  const tenYearOptions = Array.from(
    { length: 10 },
    (_, index) => currentYear + index
  )

  return tenYearOptions
}

export const getLastPart = (str: any, separator: any) => {
  const parts = str?.split(separator)

  return parts[parts.length - 1]
}

export const getLabelByValue = (
  value: any,
  options: any,
  isArray: boolean = false
) => {
  if (!value) return ''

  if (isArray) {
    if (!Array.isArray(value) || value.length < 1) return ''

    const labels: any = []
    value.forEach((item: any) => {
      const findLabel = options.find(
        (option: any) => option.value === item || option.key === item
      )

      if (findLabel) labels.push(findLabel?.label)
    })
    return labels.join(',')
  }

  const findLabel = options.find((item: any) => item.value === value)
  return findLabel?.label
}

export const formatInternationalPhone = (
  countryCode: any,
  phoneNumber: any
) => {
  if (!countryCode || !phoneNumber) return ''

  const dialCode = countryCode.split('-')[0]

  const formattedPhone = phoneNumber.startsWith('0')
    ? phoneNumber.slice(1)
    : phoneNumber

  return `+${dialCode}${formattedPhone}`
}

export const handleReferenceValue = (referenceValue: any, t: any) => {
  switch (referenceValue.type) {
    case typeReferenceValue.RANGE_NUMBER:
      return referenceValue.lowerLimit + '-' + referenceValue.upperLimit
    case typeReferenceValue.CONDITIONAL_NUMBER:
      return referenceValue?.upperLimit
        ? referenceValue?.upperLimit + t('lable.comeDown')
        : referenceValue?.lowerLimit + t('lable.above')
    case typeReferenceValue.STRING:
      return referenceValue.textValue
    default:
      return ''
  }
}

export const createProvinceList = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '', label: t('questionnaire.placeholder.pleaseSelect') },
    { value: '北海道', label: t('prefectures.hokkaido') },
    { value: '青森県', label: t('prefectures.aomori') },
    { value: '岩手県', label: t('prefectures.iwate') },
    { value: '宮城県', label: t('prefectures.miyagi') },
    { value: '秋田県', label: t('prefectures.akita') },
    { value: '山形県', label: t('prefectures.yamagata') },
    { value: '福島県', label: t('prefectures.fukushima') },
    { value: '茨城県', label: t('prefectures.ibaraki') },
    { value: '栃木県', label: t('prefectures.tochigi') },
    { value: '群馬県', label: t('prefectures.gunma') },
    { value: '埼玉県', label: t('prefectures.saitama') },
    { value: '千葉県', label: t('prefectures.chiba') },
    { value: '東京都', label: t('prefectures.tokyo') },
    { value: '神奈川県', label: t('prefectures.kanagawa') },
    { value: '新潟県', label: t('prefectures.niigata') },
    { value: '富山県', label: t('prefectures.toyama') },
    { value: '石川県', label: t('prefectures.ishikawa') },
    { value: '福井県', label: t('prefectures.fukui') },
    { value: '山梨県', label: t('prefectures.yamanashi') },
    { value: '長野県', label: t('prefectures.nagano') },
    { value: '岐阜県', label: t('prefectures.gifu') },
    { value: '静岡県', label: t('prefectures.shizuoka') },
    { value: '愛知県', label: t('prefectures.aichi') },
    { value: '三重県', label: t('prefectures.mie') },
    { value: '滋賀県', label: t('prefectures.shiga') },
    { value: '京都府', label: t('prefectures.kyoto') },
    { value: '大阪府', label: t('prefectures.osaka') },
    { value: '兵庫県', label: t('prefectures.hyogo') },
    { value: '奈良県', label: t('prefectures.nara') },
    { value: '和歌山県', label: t('prefectures.wakayama') },
    { value: '鳥取県', label: t('prefectures.tottori') },
    { value: '島根県', label: t('prefectures.shimane') },
    { value: '岡山県', label: t('prefectures.okayama') },
    { value: '広島県', label: t('prefectures.hiroshima') },
    { value: '山口県', label: t('prefectures.yamaguchi') },
    { value: '徳島県', label: t('prefectures.tokushima') },
    { value: '香川県', label: t('prefectures.kagawa') },
    { value: '愛媛県', label: t('prefectures.ehime') },
    { value: '高知県', label: t('prefectures.kochi') },
    { value: '福岡県', label: t('prefectures.fukuoka') },
    { value: '佐賀県', label: t('prefectures.saga') },
    { value: '長崎県', label: t('prefectures.nagasaki') },
    { value: '熊本県', label: t('prefectures.kumamoto') },
    { value: '大分県', label: t('prefectures.oita') },
    { value: '宮崎県', label: t('prefectures.miyazaki') },
    { value: '鹿児島県', label: t('prefectures.kagoshima') },
    { value: '沖縄県', label: t('prefectures.okinawa') }
  ]
}

export const findProvinceLabel = (
  key: string | null | undefined,
  list: { value: string; label: string }[]
): string => {
  if (!key) return ''

  const province = list.find((item) => item.value === key)
  return province ? province.label : ''
}
export const scrollToEnd = () => {
  const element = document.getElementById('scenario-create-content')
  if (element) {
    element.scroll({
      left: element.scrollWidth,
      behavior: 'smooth'
    })
  }
}
export const groupMessagesByDate = (messages: ChatMessageType[]) => {
  return messages.reduce(
    (groups, message) => {
      const date = new Date(+message.timestamp).toLocaleDateString()
      return {
        ...groups,
        [date]: [...(groups[date] || []), message]
      }
    },
    {} as { [key: string]: ChatMessageType[] }
  )
}

export const onPressEnterKey = (
  e: React.KeyboardEvent<HTMLTextAreaElement>,
  cb: () => Promise<void>
) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    e.preventDefault()
    cb()
  }
}

export const sortListByUpdateAt = <T extends StringKeyObject>(
  list: T[],
  key: string
) => {
  return list.sort((a, b) => {
    const bValue = isNumber(+b[key])
      ? new Date(+b[key])
      : new Date(b[key]).getTime()
    const aValue = isNumber(+a[key])
      ? new Date(+a[key])
      : new Date(a[key]).getTime()

    return Number(bValue) - Number(aValue)
  })
}

/**
 * Get the type of message
 * Chat have 2 types of message:
 * - Normal message: text,image or file
 * - Chatbot message: view by chatbot (selectionType exist)
 * @param attachments - The attachments of the message
 * @returns The type of message
 */
export const getMessageType = (attachments: Attachment[] | undefined) => {
  if (attachments?.length) {
    const firstAttachment = attachments[0]

    const isMessageInChatBotType = Boolean(firstAttachment.selectionType)

    if (isMessageInChatBotType) {
      return 'chatbot'
    }

    if (firstAttachment?.image_type?.includes('image')) {
      return 'image'
    } else {
      return 'file'
    }
  }

  return 'text'
}

export const getLastMsgForRoom = (lastMessage?: {
  msg?: string
  content?: string
  attachments?: any[]
}) => {
  if (!lastMessage) return ''

  const [firstAttachment] = lastMessage.attachments || []

  if (firstAttachment?.title) return firstAttachment.title
  if (lastMessage.msg) return lastMessage.msg
  if (firstAttachment?.description) return firstAttachment.description
  return lastMessage.msg
}

export const convertStatusRoom = (status: string): string => {
  const statusMap: Record<string, string> = {
    [statusRoom.OPEN]: 'chat.tabs.reservation',
    [statusRoom.END]: 'chat.tabs.support'
  }
  return statusMap[status] || ''
}

export const getNameCourseOrOptionByIds = (
  list: CourseResponse[],
  ids: string[]
) => {
  if (!list.length || !ids.length) return ''

  const medicalCheckupMasterFiltered =
    list.filter((item) =>
      ids.includes(item.medicalCheckupMaster.refId || '')
    ) || []

  return medicalCheckupMasterFiltered
    .map((item) => item.medicalCheckupMaster.displayName)
    .join(', ')
}

export const checkIsTrue = (value: any) => {
  return value === 'true' || value === '1' || value === true || value === 1
}

export const filterCourseResponseInValid = (
  courses: CourseResponse[],
  userInfo: {
    sex: string
    age: string
  }
) => {
  const newCourses = courses.filter(({ medicalCheckupMaster }) => {
    const {
      additionalInfo: { metadata }
    } = medicalCheckupMaster

    // if metadata is empty, return valid
    if (!metadata || !metadata.length) return true

    // find metadata need to check
    const metadataNeedCheck = metadata.filter((metadataItem) => {
      return (
        metadataItem?.name === 'sex' ||
        metadataItem?.name === 'startAge' ||
        metadataItem?.name === 'endAge'
      )
    })

    let isValid = true
    // loop through metadataNeedCheck to check valid for userInfo
    metadataNeedCheck.forEach((metadataItem) => {
      if (!metadataItem?.value) return

      switch (metadataItem?.name) {
        case 'sex':
          // NONE value is valid for all (male and female)
          if (
            userInfo.sex !== metadataItem.value &&
            metadataItem.value !== 'NONE'
          ) {
            isValid = false
          }
          break
        case 'startAge':
          if (Number(userInfo.age) < Number(metadataItem.value)) {
            isValid = false
          }
          break
        case 'endAge':
          if (Number(userInfo.age) > Number(metadataItem.value)) {
            isValid = false
          }
          break
        default:
          break
      }
    })

    return isValid
  })

  return newCourses
}

export const getAge = (birthdate: string) => {
  if (!birthdate) return 0

  const birth = dayjs(birthdate)
  const today = dayjs()

  return (
    today.diff(birth, 'year') -
    (today.isBefore(birth.add(today.diff(birth, 'year'), 'year')) ? 1 : 0)
  )
}

export const isErrorKeycloak = (action: any) => {
  return action?.status && !(action.status >= 200 && action.status < 300)
}

export const timestampInSeconds = () => {
  return Math.floor(Date.now() / 1000)
}

export const filterUserInValid = (
  users: Customer[],
  courseSetting: {
    sex: 'MALE' | 'FEMALE' | 'NONE'
    startAge: number | undefined
    endAge: number | undefined
  }
) => {
  return users.filter(({ gender, age = 0 }) => {
    const isSexValid =
      courseSetting.sex === 'NONE' || gender === courseSetting.sex

    const isAgeValid =
      (courseSetting.startAge == null && courseSetting.endAge == null) ||
      (courseSetting.startAge == null &&
        age <= (courseSetting.endAge ?? Infinity)) ||
      (courseSetting.endAge == null && age >= (courseSetting.startAge ?? 0)) ||
      (age >= (courseSetting.startAge ?? 0) &&
        age <= (courseSetting.endAge ?? Infinity))

    return isSexValid && isAgeValid
  })
}

export const getMonthRange = (currentMonth?: string) => {
  const date = currentMonth ? dayjs(currentMonth) : dayjs()
  return {
    startDate: date.startOf('month').format(DateFormat),
    endDate: date.endOf('month').format(DateFormat)
  }
}
